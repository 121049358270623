import { applyMetricWeightTarget, applyWeightTarget, weightToKg } from "./Anthropometry";
import { AnthropometrySnapshot } from "./types/AnthropometryTypes";
import { CalorieBudget, MacroProtocol } from "./types/MacroProtocolTypes";
import {
  gPerKgToCalories,
  gPerKgToGrams,
  getMacroCaloriesPerGram,
  macroGPerKgCaloriesTotal,
  macroGPerKgToGramsMacrosRounded,
  macroMathLeftOverRatioTotal,
  roundMacros,
  macroGPerKgToGramsMetricMacrosRounded,
  macroGPerKgCaloriesMetricTotal,
  metricGPerKgToCalories,
} from "./Macros";
import { MacroMath, MacroMaths, MacroTarget, MacroTargets, MacroType, Macros } from "./types/MacrosTypes";
import { getMetricRmrCalories, getRmrCalories } from "./Rmr";
import { MacrosAndKcals } from "@notemeal/graphql/types";

/* TODO: metric update
 * getMacroProtocolMacros() in libs/shared/ui/src/lib/MacroProtocol/utils.ts is essentially a wrapper for getMacroProtocolMacros() in this file
 * and is utilized heavily by the front end (along with many of the functions in this file)
 * when doing front end transition it should be much easier to switch function inside wrapper function
 * to this one - getMetricMacroProtocolMacros() - to avoid the original somewhat deeply nested imperial => metric weight conversions
 */
export const getMetricMacroProtocolMacros = ({
  cho,
  pro,
  fat,
  weightTargetInKg,
  calorieBudget,
  anthropometrySnapshot,
}: MacroProtocol): Macros => {
  const targetAnthroSnapshot = applyMetricWeightTarget(anthropometrySnapshot, weightTargetInKg);
  const { weightInKg } = targetAnthroSnapshot;

  if (calorieBudget === null) {
    return macroGPerKgToGramsMetricMacrosRounded(cho, pro, fat, weightInKg);
  } else {
    const leftOverRatioTotal = macroMathLeftOverRatioTotal(cho, pro, fat);
    const leftOutCalories = macroGPerKgCaloriesMetricTotal(cho, pro, fat, weightInKg);
    return roundMacros({
      cho: getMetricMacroAmount(cho, "cho", calorieBudget, targetAnthroSnapshot, leftOverRatioTotal, leftOutCalories),
      pro: getMetricMacroAmount(pro, "pro", calorieBudget, targetAnthroSnapshot, leftOverRatioTotal, leftOutCalories),
      fat: getMetricMacroAmount(fat, "fat", calorieBudget, targetAnthroSnapshot, leftOverRatioTotal, leftOutCalories),
      kcal: getTotalMetricCalories(targetAnthroSnapshot, calorieBudget),
    });
  }
};

// TODO: metric update - deprecate? - see note above
export const getMacroProtocolMacros = ({ cho, pro, fat, weightTarget, calorieBudget, anthropometrySnapshot }: MacroProtocol): Macros => {
  const targetAnthroSnapshot = applyWeightTarget(anthropometrySnapshot, weightTarget);
  const { weight } = targetAnthroSnapshot;

  if (calorieBudget === null) {
    return macroGPerKgToGramsMacrosRounded(cho, pro, fat, weight);
  } else {
    const leftOverRatioTotal = macroMathLeftOverRatioTotal(cho, pro, fat);
    const leftOutCalories = macroGPerKgCaloriesTotal(cho, pro, fat, weight);
    return roundMacros({
      cho: getMacroAmount(cho, "cho", calorieBudget, targetAnthroSnapshot, leftOverRatioTotal, leftOutCalories),
      pro: getMacroAmount(pro, "pro", calorieBudget, targetAnthroSnapshot, leftOverRatioTotal, leftOutCalories),
      fat: getMacroAmount(fat, "fat", calorieBudget, targetAnthroSnapshot, leftOverRatioTotal, leftOutCalories),
      kcal: getTotalCalories(targetAnthroSnapshot, calorieBudget),
    });
  }
};

export const getMetricMacroAmount = (
  macroMath: MacroMath,
  macro: MacroType,
  calorieBudget: CalorieBudget,
  anthropometrySnapshot: AnthropometrySnapshot,
  leftOverRatioTotal: number,
  leftOutCalories: number
): number => {
  const gPerKg = metricMacroMathToGPerKg(macro, macroMath, calorieBudget, anthropometrySnapshot, leftOverRatioTotal, leftOutCalories);
  // TODO: Metric Hotfix is this always right
  return gPerKg * anthropometrySnapshot.weightInKg;
};

export const getMacroAmount = (
  macroMath: MacroMath,
  macro: MacroType,
  calorieBudget: CalorieBudget,
  anthropometrySnapshot: AnthropometrySnapshot,
  leftOverRatioTotal: number,
  leftOutCalories: number
): number => {
  const gPerKg = macroMathToGPerKg(macro, macroMath, calorieBudget, anthropometrySnapshot, leftOverRatioTotal, leftOutCalories);
  return gPerKgToGrams(gPerKg, anthropometrySnapshot.weight);
};

// TODO: metric update - deprecate and replace with metricMacroMathsToMacroTargets?
export const macroMathsToMacroTargets = (
  { cho, pro, fat }: MacroMaths,
  calorieBudget: CalorieBudget,
  anthropometrySnapshot: AnthropometrySnapshot
): MacroTargets => {
  const { weight } = anthropometrySnapshot;
  const leftOverRatioTotal = macroMathLeftOverRatioTotal(cho, pro, fat);
  const leftOutCalories = macroGPerKgCaloriesTotal(cho, pro, fat, weight);
  return {
    cho: macroMathToMacroTarget("cho", cho, calorieBudget, anthropometrySnapshot, leftOverRatioTotal, leftOutCalories),
    pro: macroMathToMacroTarget("pro", pro, calorieBudget, anthropometrySnapshot, leftOverRatioTotal, leftOutCalories),
    fat: macroMathToMacroTarget("fat", fat, calorieBudget, anthropometrySnapshot, leftOverRatioTotal, leftOutCalories),
  };
};

export const metricMacroMathsToMacroTargets = (
  { cho, pro, fat }: MacroMaths,
  calorieBudget: CalorieBudget,
  anthropometrySnapshot: AnthropometrySnapshot
): MacroTargets => {
  const { weightInKg } = anthropometrySnapshot;
  const leftOverRatioTotal = macroMathLeftOverRatioTotal(cho, pro, fat);
  const leftOutCalories = macroGPerKgCaloriesMetricTotal(cho, pro, fat, weightInKg);
  return {
    cho: metricMacroMathToMacroTarget("cho", cho, calorieBudget, anthropometrySnapshot, leftOverRatioTotal, leftOutCalories),
    pro: metricMacroMathToMacroTarget("pro", pro, calorieBudget, anthropometrySnapshot, leftOverRatioTotal, leftOutCalories),
    fat: metricMacroMathToMacroTarget("fat", fat, calorieBudget, anthropometrySnapshot, leftOverRatioTotal, leftOutCalories),
  };
};

export const metricMacroMathToMacroTarget = (
  macro: MacroType,
  macroMath: MacroMath,
  calorieBudget: CalorieBudget,
  anthropometrySnapshot: AnthropometrySnapshot,
  leftOverRatioTotal: number,
  leftOutCalories: number
): MacroTarget => {
  const gPerKg = metricMacroMathToGPerKg(macro, macroMath, calorieBudget, anthropometrySnapshot, leftOverRatioTotal, leftOutCalories);
  const percent = metricGPerKgToPercent(gPerKg, macro, anthropometrySnapshot, calorieBudget);
  const leftOverPercent = (macroMath.leftOverRatio / leftOverRatioTotal) * 100;
  return {
    gPerKg: gPerKg,
    percent: isNaN(percent) ? leftOverPercent : percent,
    usesPercent: macroMath.gPerKg === 0,
  };
};

// TODO: metric update - deprecate and replace with metricMacroMathToMacroTarget()?
export const macroMathToMacroTarget = (
  macro: MacroType,
  macroMath: MacroMath,
  calorieBudget: CalorieBudget,
  anthropometrySnapshot: AnthropometrySnapshot,
  leftOverRatioTotal: number,
  leftOutCalories: number
): MacroTarget => {
  const gPerKg = macroMathToGPerKg(macro, macroMath, calorieBudget, anthropometrySnapshot, leftOverRatioTotal, leftOutCalories);
  const percent = gPerKgToPercent(gPerKg, macro, anthropometrySnapshot, calorieBudget);
  const leftOverPercent = (macroMath.leftOverRatio / leftOverRatioTotal) * 100;
  return {
    gPerKg: gPerKg,
    percent: isNaN(percent) ? leftOverPercent : percent,
    usesPercent: macroMath.gPerKg === 0,
  };
};

export const metricMacroMathToGPerKg = (
  macro: MacroType,
  { gPerKg, leftOverRatio }: MacroMath,
  calorieBudget: CalorieBudget,
  anthropometrySnapshot: AnthropometrySnapshot,
  leftOverRatioTotal: number,
  leftOutCalories: number
): number =>
  gPerKg !== 0
    ? gPerKg
    : percentToMetricGPerKg((leftOverRatio / leftOverRatioTotal) * 100, macro, anthropometrySnapshot, calorieBudget, leftOutCalories);

// TODO: metric update - deprecate and use percentToMetricGPerKg()?
export const macroMathToGPerKg = (
  macro: MacroType,
  { gPerKg, leftOverRatio }: MacroMath,
  calorieBudget: CalorieBudget,
  anthropometrySnapshot: AnthropometrySnapshot,
  leftOverRatioTotal: number,
  leftOutCalories: number
): number =>
  gPerKg !== 0
    ? gPerKg
    : percentToGPerKg((leftOverRatio / leftOverRatioTotal) * 100, macro, anthropometrySnapshot, calorieBudget, leftOutCalories);

export const percentToMetricGPerKg = (
  percent: number,
  macro: MacroType,
  anthropometrySnapshot: AnthropometrySnapshot,
  calorieBudget: CalorieBudget,
  leftOutCalories = 0
): number => {
  const totalCalories = getTotalMetricCalories(anthropometrySnapshot, calorieBudget);
  const leftOverCalories = totalCalories - leftOutCalories;
  const macroCalories = (leftOverCalories * percent) / 100;
  const macroGrams = macroCalories / getMacroCaloriesPerGram(macro);
  return Math.round((100 * macroGrams) / anthropometrySnapshot.weightInKg) / 100;
};

// TODO: metric update - deprecate and use percentToMetricGPerKg()?
export const percentToGPerKg = (
  percent: number,
  macro: MacroType,
  anthropometrySnapshot: AnthropometrySnapshot,
  calorieBudget: CalorieBudget,
  leftOutCalories = 0
): number => {
  const totalCalories = getTotalCalories(anthropometrySnapshot, calorieBudget);
  const leftOverCalories = totalCalories - leftOutCalories;
  const macroCalories = (leftOverCalories * percent) / 100;
  const macroGrams = macroCalories / getMacroCaloriesPerGram(macro);
  return Math.round((100 * macroGrams) / weightToKg(anthropometrySnapshot.weight)) / 100;
};

// TODO: metric update - deprecate and replace with metricGPerKgToPercent()?
export const gPerKgToPercent = (
  gPerKg: number,
  macro: MacroType,
  anthropometrySnapshot: AnthropometrySnapshot,
  calorieBudget: CalorieBudget
): number => {
  const totalCalories = getTotalCalories(anthropometrySnapshot, calorieBudget);
  const macroCalories = gPerKgToCalories(gPerKg, anthropometrySnapshot.weight, macro);
  return Math.round((macroCalories / totalCalories) * 100);
};

export const metricGPerKgToPercent = (
  gPerKg: number,
  macro: MacroType,
  anthropometrySnapshot: AnthropometrySnapshot,
  calorieBudget: CalorieBudget
): number => {
  const totalCalories = getTotalMetricCalories(anthropometrySnapshot, calorieBudget);
  const macroCalories = metricGPerKgToCalories(gPerKg, anthropometrySnapshot.weightInKg, macro);
  return Math.round((macroCalories / totalCalories) * 100);
};

export const getTotalMetricCalories = (anthropometrySnapshot: AnthropometrySnapshot, calorieBudget: CalorieBudget): number => {
  const {
    rmrMethod,
    rmrCalories: customCalories,
    activityFactor,
    goalSnapshot: { kcalOffset: goalKcalOffset },
    kcalOffset,
  } = calorieBudget;

  const rmrCalories = rmrMethod
    ? getMetricRmrCalories({
        rmrMethod,
        weight: anthropometrySnapshot.weightInKg,
        height: anthropometrySnapshot.heightInCm,
        leanBodyMass: anthropometrySnapshot.leanBodyMassInKg,
        percentBodyFat: anthropometrySnapshot.percentBodyFat,
        age: anthropometrySnapshot.age,
        sex: anthropometrySnapshot.sex,
      })
    : customCalories || 0;
  return rmrCalories * activityFactor + goalKcalOffset + kcalOffset;
};

export const getTotalCalories = (anthropometrySnapshot: AnthropometrySnapshot, calorieBudget: CalorieBudget): number => {
  const {
    rmrMethod,
    rmrCalories: customCalories,
    activityFactor,
    goalSnapshot: { kcalOffset: goalKcalOffset },
    kcalOffset,
  } = calorieBudget;

  const rmrCalories = rmrMethod ? getRmrCalories({ rmrMethod, ...anthropometrySnapshot }) : customCalories || 0;
  return rmrCalories * activityFactor + goalKcalOffset + kcalOffset;
};

export const convertMacrosTypeToMacrosAndKcals = (macros: Macros): MacrosAndKcals => {
  return {
    ...macros,
    choKcal: macros.cho * 4,
    proKcal: macros.pro * 4,
    fatKcal: macros.fat * 9,
  };
};
