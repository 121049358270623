import { DayOfWeek, PlannedMenuDisabledDaysInput, User } from "@notemeal/graphql/types";
import { AthletesBirthdaysFragment, usePlannedMenuHeaderLazyQuery, useUnlockPlannedMenuMutation } from "apps/web/src/types";
import { useUser } from "apps/web/src/utils/tokens";
import React, { ReactNode, createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { MenuBuilderMealRowItemType } from "./Meal/MenuBuilderMealSchema";
import { DAY_CELL_WIDTH, LEFT_PANEL_CELL_WIDTH } from "./Meal/constants";
import { MenuBuilderWeek, getDays, transformWeeks } from "./utils";

type Clipboard =
  | {
      type: "cell";
      formPath: string;
      items: MenuBuilderMealRowItemType[];
    }
  | {
      type: "row";
      formPath: string;
      items: MenuBuilderMealRowItemType[];
      diningStationName: string;
      foodType: string | null;
    };
type MenuBuilderGridContextValue = {
  loading: boolean;
  menuId: string;
  menuName: string;
  menuLastEditedAt?: string;
  selectedWeekIndex: number;
  setSelectedWeekIndex: (index: number) => void;
  weeks: readonly MenuBuilderWeek[];
  days: DayOfWeek[];
  mealGridWidth: string;
  mealsToDelete: string[];
  setMealsToDelete: (mealIds: string[]) => void;
  rowsToDelete: string[];
  setRowsToDelete: (menuItems: string[]) => void;
  rowItemsToDelete: string[];
  setRowItemsToDelete: (menuItems: string[]) => void;
  clipboard?: Clipboard;
  setClipboard: (clipboard?: Clipboard) => void;
  disabledDays: PlannedMenuDisabledDaysInput[];
  setDisabledDays: (days: PlannedMenuDisabledDaysInput[]) => void;
  selectedDay?: DayOfWeek;
  setSelectedDay: (day?: DayOfWeek) => void;
  athleteBirthdays: readonly AthletesBirthdaysFragment[];
  // track meals that get edited, to workaround rhf not correctly identifying dirty fields
  updatedFormMeals: `weeks.${number}.meals.${number}`[];
  markMealAsUpdated: (formId: `weeks.${number}.meals.${number}`) => void;
  clearUpdatedMeals: () => void;
  // menu locking
  isEditable: boolean;
  setIsEditable: (isEditable: boolean) => void;
  currentUserHasLock: boolean;
  setCurrentUserHasLock: (currentUserHasLock: boolean) => void;
  lockCreatedAt?: string;
  lockCreatedBy?: Pick<User, "firstName" | "lastName">;
};

const defaultState = {
  loading: true,
  menuId: "",
  menuName: "",
  menuLastEditedAt: undefined,
  selectedWeekIndex: 0,
  setSelectedWeekIndex: () => {},
  weeks: [],
  days: [],
  mealGridWidth: `calc(${LEFT_PANEL_CELL_WIDTH} + 5 * ${DAY_CELL_WIDTH} + 2px)`,
  mealsToDelete: [],
  setMealsToDelete: () => {},
  rowsToDelete: [],
  setRowsToDelete: () => {},
  rowItemsToDelete: [],
  setRowItemsToDelete: () => {},
  clipboard: undefined,
  setClipboard: () => {},
  disabledDays: [],
  setDisabledDays: () => {},
  selectedDay: undefined,
  setSelectedDay: () => {},
  athleteBirthdays: [],
  updatedFormMeals: [],
  markMealAsUpdated: () => {},
  clearUpdatedMeals: () => {},
  isEditable: true,
  setIsEditable: () => {},
  currentUserHasLock: false,
  setCurrentUserHasLock: () => {},
  lockCreatedMinutesAgo: undefined,
  lockCreatedBy: undefined,
};
const MenuBuilderGridPageContext = createContext<MenuBuilderGridContextValue>(defaultState);

export const MenuBuilderProvider: React.FC<{ plannedMenuId: string; children: ReactNode }> = ({ plannedMenuId, children }) => {
  // on first render get the menu, and any time the menu is fetched refetch its meals
  const [getPlannedMenu, { data: menu, loading: loadingMenu }] = usePlannedMenuHeaderLazyQuery({
    variables: { plannedMenuId },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    getPlannedMenu();
  }, [getPlannedMenu]);

  const [unlockPlannedMenu] = useUnlockPlannedMenuMutation({ variables: { input: { plannedMenuId } } });
  const history = useHistory();
  const currentUser = useUser();

  const [selectedWeekIndex, setSelectedWeekIndex] = useState(0);
  const [mealsToDelete, setMealsToDelete] = useState<string[]>([]);
  const [rowsToDelete, setRowsToDelete] = useState<string[]>([]);
  const [rowItemsToDelete, setRowItemsToDelete] = useState<string[]>([]);
  const [clipboard, setClipboard] = useState<Clipboard | undefined>(undefined);
  const [disabledDays, setDisabledDays] = useState<PlannedMenuDisabledDaysInput[] | undefined>(undefined);
  const [selectedDay, setSelectedDay] = useState<DayOfWeek | undefined>(undefined);
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [currentUserHasLock, setCurrentUserHasLock] = useState<boolean>(false);
  const [updatedFormMeals, setUpdatedFormMeals] = useState<`weeks.${number}.meals.${number}`[]>([]);

  // dedupe all the duplicates we get from onChange
  const markMealAsUpdated = (formId: `weeks.${number}.meals.${number}`) => {
    setUpdatedFormMeals([...new Set(updatedFormMeals).add(formId)]);
  };

  // unlock menu when user navigates away
  useEffect(() => {
    if (currentUserHasLock) {
      const removeListener = history.listen(() => {
        unlockPlannedMenu();
      });
      return removeListener;
    }
  }, [history, currentUserHasLock, unlockPlannedMenu]);

  // assertion to avoid null checks everywhere
  if (!menu?.plannedMenu || menu.plannedMenu.weeks.length === 0) {
    return <MenuBuilderGridPageContext.Provider value={defaultState}>{children}</MenuBuilderGridPageContext.Provider>;
  }

  const days = getDays(menu?.plannedMenu.occurrence);
  // keeps same width when meal is collapsed as when its expanded
  const mealGridWidth = `calc(${LEFT_PANEL_CELL_WIDTH} + ${days.length} * ${DAY_CELL_WIDTH} + 2px)`;
  if (disabledDays === undefined) {
    setDisabledDays(
      menu.plannedMenu.weeks.map(week => {
        return {
          plannedMenuWeekId: week.id,
          disabledDays: week.disabledDays as DayOfWeek[],
        };
      })
    );
  }

  // currently locks are specific to the user, instead of specific to a user session. If multi-tab stomping happens a lot we may need to change this
  if (currentUserHasLock !== !!(menu.plannedMenu.lockCreatedBy?.id && menu.plannedMenu.lockCreatedBy.id === currentUser?.id)) {
    setCurrentUserHasLock(!!(menu.plannedMenu.lockCreatedBy?.id && menu.plannedMenu.lockCreatedBy.id === currentUser?.id));
  }

  if (isEditable !== (menu.plannedMenu.lockCreatedAt === null || currentUserHasLock)) {
    setIsEditable(menu.plannedMenu.lockCreatedAt === null || currentUserHasLock);
  }

  return (
    <MenuBuilderGridPageContext.Provider
      value={{
        loading: loadingMenu,
        menuId: plannedMenuId,
        menuName: menu.plannedMenu.name,
        menuLastEditedAt: menu.plannedMenu.lastEditedAt ?? undefined,
        selectedWeekIndex,
        setSelectedWeekIndex,
        weeks: transformWeeks(menu.plannedMenu.weeks, menu.plannedMenu),
        days,
        mealGridWidth,
        mealsToDelete,
        setMealsToDelete,
        rowsToDelete,
        setRowsToDelete,
        rowItemsToDelete,
        setRowItemsToDelete,
        clipboard,
        setClipboard,
        disabledDays: disabledDays ?? [],
        setDisabledDays,
        selectedDay,
        setSelectedDay,
        athleteBirthdays: menu.plannedMenu.athletesWithBirthdaysInRange || [],
        updatedFormMeals,
        markMealAsUpdated,
        clearUpdatedMeals: () => setUpdatedFormMeals([]),
        isEditable,
        setIsEditable,
        currentUserHasLock,
        setCurrentUserHasLock,
        lockCreatedAt: menu.plannedMenu.lockCreatedAt ?? undefined,
        lockCreatedBy: menu.plannedMenu.lockCreatedBy ?? undefined,
      }}
    >
      {children}
    </MenuBuilderGridPageContext.Provider>
  );
};

export const useMenuBuilderContext = () => {
  const state = useContext(MenuBuilderGridPageContext);
  return state;
};
