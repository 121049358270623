import React from "react";
import { Location, Route, Routes, useLocation } from "react-router-dom-v5-compat";
import { getLink } from "../../../NavUtils";
import { RoleRoute } from "../../ProtectedRoutes";
import { NavLink } from "../../SecondaryNav/BreadCrumbs";
import { SecondaryNavLayout, SecondaryNavLayoutProps } from "../../SecondaryNav/SecondaryNavLayout";

import { NAV_ORG_RECIPE, orgFoodManagementLink, orgFoodManagementRecipeLink } from "../FoodManagement/FoodManagementPaths";
import RecipeCreatePage from "./RecipeCreatePage";
import RecipeEditPage from "./RecipeEditPage";

const getNavOrgRecipeLink = (location?: Location) => {
  if (!location?.pathname.includes("/create")) {
    const id = location?.pathname.replace(`${NAV_ORG_RECIPE}/`, "");
    return getLink("Edit Recipe", `${NAV_ORG_RECIPE}/${id}`);
  }

  return getLink("Create Recipe", NAV_ORG_RECIPE);
};

const getSecondaryNavLayoutProps = (current: NavLink): SecondaryNavLayoutProps => ({
  name: "Recipe",
  current,
  breadCrumbs: [orgFoodManagementLink, orgFoodManagementRecipeLink],
  tabs: [],
  enableAthleteSearch: true,
});

export const RecipeRouter = () => {
  const location = useLocation();
  const current = getNavOrgRecipeLink(location);

  const secondaryNavLayoutProps = getSecondaryNavLayoutProps(current);

  return (
    <Routes>
      <Route element={<SecondaryNavLayout {...secondaryNavLayoutProps} />}>
        <Route element={<RoleRoute roles={["dietitian"]} />}>
          <Route index element={<RecipeCreatePage />} />
          <Route path={"/create"} element={<RecipeCreatePage />} />
          <Route path={"/:id"} element={<RecipeEditPage />} />
        </Route>
      </Route>
    </Routes>
  );
};
