import { Box, useTheme } from "@mui/material";
import { NutritionWhiteIcon } from "@notemeal/shared/ui/global/Icons";
import { PrimaryNav } from "@teamworksdev/react";
import { useReportingUserIdentify } from "apps/web/src/utils/reporting/useReportingUserIdentiy";
import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom-v5-compat";
import { AgreementFormModal } from "../../../components/AgreementForm/AgreementFormModal";
import {
  AgreementFormFragment,
  useAddAgreementFormSubmissionMutation,
  useMyFeatureFlagsQuery,
  usePublishedNotificationSubscription,
  useUnsubmittedAgreementFormForUserQuery,
} from "../../../types";
import { useLogout, useUser } from "../../../utils/tokens";
import { DevRouter } from "../Dev/DevRouter";
import { getFeatureFlagsDetails, getOrgUserDetails } from "../OrgUtils";
import {
  DEFAULT_ORG_ID,
  DEFAULT_USER_NAME,
  OrgAndStaffRouterProps,
  getOrgPrimaryNavLinks,
  orgAdditionalSettingsLinks,
} from "../PrimaryNavUtils";
import { AuthRoute } from "../ProtectedRoutes";
import { addTimelineMealNotificationToCache } from "../TimelineNotificationUtils";
import { AthleteRouter } from "./Athlete/AthleteRouter";
import { DataRouter } from "./Data/DataRouter";
import { RecipeRouter } from "./Food/RecipeRouter";
import { FoodManagementRouter } from "./FoodManagement/FoodManagementRouter";
import { KitchenRouter } from "./Kitchen/KitchenRouter";
import { MealPlansRouter } from "./MealPlans/MealPlansRouter";
import { MenuAttendanceRouter } from "./MenuAttendance/MenuAttendanceRouter";
import { MenuBuilderRouter } from "./MenuBuilder/MenuBuilderRouter";
import { MessagePage } from "./Message/MessagePage";
import { SettingsRouter } from "./Settings/SettingsRouter";
import { TeamRouter } from "./Team/TeamRouter";
import { TeamManagementRouter } from "./TeamManagement/TeamManagementRouter";
import { ViewAttendanceRouter } from "./ViewAttendance/ViewAttendanceRouter";

export const OrgRouter = ({ orgs, onOrgChange }: OrgAndStaffRouterProps) => {
  useReportingUserIdentify({ forStaff: false });
  const {
    palette: { primary },
  } = useTheme();
  const user = useUser();
  const logout = useLogout();
  const orgUserDetails = getOrgUserDetails(user);
  const { userName, orgId, orgName } = orgUserDetails;
  const { data: agreementFormData } = useUnsubmittedAgreementFormForUserQuery({ variables: { orgId: orgId || "" }, skip: orgId === "" });
  const [agreementForm, setAgreementForm] = useState<AgreementFormFragment | null>(null);
  const { data: featureFlagsData } = useMyFeatureFlagsQuery();
  const featureFlagsDetails = getFeatureFlagsDetails(featureFlagsData);

  useEffect(() => {
    if (agreementFormData?.unsubmittedAgreementFormForUser) {
      setAgreementForm(agreementFormData.unsubmittedAgreementFormForUser);
    }
  }, [agreementFormData]);

  const [addAgreementFormSubmission] = useAddAgreementFormSubmissionMutation();
  const handleAddAgreementFormSubmission = (agreementFormId: string) => {
    addAgreementFormSubmission({ variables: { input: { agreementFormId } } });
    setAgreementForm(null);
  };

  usePublishedNotificationSubscription({
    onSubscriptionData: ({ subscriptionData, client: { cache } }) => {
      if (subscriptionData.data) {
        const {
          payload: { notification },
        } = subscriptionData.data.notification;
        switch (notification.__typename) {
          case "FoodLogMealCommentNotification":
          case "FoodLogMealEditNotification":
          case "FoodLogMealImageNotification":
          case "MenuOrderCommentNotification":
          case "MenuOrderImageNotification":
          case "TimelineMealCommentNotification":
          case "TimelineMealEditNotification":
          case "TimelineMealImageNotification": {
            if (notification.timelineMeal) {
              addTimelineMealNotificationToCache({ cache, timelineMeal: notification.timelineMeal, notification });
            }
          }
        }
      } else if (subscriptionData.error) {
        console.error("Notification Sub Error!", subscriptionData.error);
      }
    },
  });

  const handleOrgChange = (orgId: string) => {
    onOrgChange(orgId);
  };

  const logo = <NutritionWhiteIcon sx={{ alignSelf: "center", width: 40, height: 40 }} viewBox="0 0 20 20" />;

  return (
    <Box sx={{ flex: 1, display: "flex" }}>
      <PrimaryNav
        colors={{ bottom: primary.main, top: "black" }}
        links={getOrgPrimaryNavLinks(orgUserDetails, featureFlagsDetails)}
        app="nutrition"
        logo={logo}
        team={{ id: orgId || DEFAULT_ORG_ID, name: orgName }}
        teams={orgs}
        user={{ name: userName || DEFAULT_USER_NAME }}
        additionalSettingsLinks={orgAdditionalSettingsLinks}
        onTeamChange={handleOrgChange}
        onLogout={logout}
      />
      <Box sx={{ flex: 1, overflowY: "hidden", backgroundColor: "black", pt: 1 }}>
        <Box
          sx={{
            flex: 1,
            height: "100%",
            backgroundColor: ({ palette: { mode } }) => (mode === "light" ? "white" : "black"),
            borderTopLeftRadius: 12,
            p: 3,
          }}
        >
          <Routes>
            <Route element={<AuthRoute />}>
              <Route
                path="/*"
                index
                element={<TeamManagementRouter />} />
              <Route path="team-management/*" element={<TeamManagementRouter />} />
              <Route path="kitchen/*" element={<KitchenRouter />} />
              <Route path="food-management/*" element={<FoodManagementRouter />} />
              <Route path="recipe/*" element={<RecipeRouter />} />
              <Route path="meal-plans/*" element={<MealPlansRouter />} />
              <Route path="messaging/*" element={<MessagePage />} />
              <Route path="data/*" element={<DataRouter />} />
              <Route path="teams/:teamId/*" element={<TeamRouter />} />
              <Route path="athletes/:athleteId/*" element={<AthleteRouter />} />
              <Route path="menu-builder/:plannedMenuId/*" element={<MenuBuilderRouter />} />
              <Route path="menu-attendance/:mealMenuId/*" element={<MenuAttendanceRouter />} />
              <Route path="view-attendance*" element={<ViewAttendanceRouter />} />
              <Route path="settings/*" element={<SettingsRouter />} />
              <Route path="dev/*" element={<DevRouter org={true} />} />
            </Route>
          </Routes>
        </Box>
      </Box>
      {agreementForm && (
        <AgreementFormModal
          open={!!agreementForm}
          agreementForm={agreementForm}
          onAddAggrementFormSubmission={() => handleAddAgreementFormSubmission(agreementForm.id)}
        />
      )}
    </Box>
  );
};
