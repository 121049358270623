import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton, Typography, styled, useTheme } from "@mui/material";
import React, { useState } from "react";
import { useUploadPhotoForAiQuery } from "../../../universal/Image/uploadPhoto";
import { ImageFileDragAndDrop } from "./ImageUploader";
import { TranscribeError } from "./utils";
import { ErrorBox } from "../../../universal/ErrorBox";
import { trackEvent } from "apps/web/src/reporting/reporting";

export const Subtext = styled(Typography)(({ theme: { palette } }) => ({
  color: palette.mediumEmphasisText,
}));

export const ColumnFlexBox = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const RowFlexBox = styled(Box)(({ theme: { spacing } }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  padding: spacing(1, 2),
}));

interface TranscribeWithGPTContentProps {
  presignedGetImageUrl: string;
  setPresignedGetImageUrl: (imageBe64String: string) => void;
  transcriberError: TranscribeError | null;
  setTranscriberError: (e: TranscribeError | null) => void;
  onError: (error: any) => void;
}

export const TranscribeWithGPTContent = ({
  presignedGetImageUrl,
  setPresignedGetImageUrl,
  transcriberError,
  setTranscriberError,
  onError,
}: TranscribeWithGPTContentProps) => {
  const [filename, setFilename] = useState("");

  const [uploadPhoto, imageUploading] = useUploadPhotoForAiQuery({
    onComplete: data => {
      setPresignedGetImageUrl(data);
    },
  });

  const handleUpload = async (imageUrl: string, filename: string) => {
    try {
      await uploadPhoto(imageUrl);
      trackEvent("Nutrition | Food Management | Recipe | AI Recipe Transcribe | Upload Image", {});
      setFilename(filename);
    } catch (e) {
      if (e instanceof TranscribeError) {
        setTranscriberError(e);
      } else {
        onError(e);
      }
    }
  };

  const deleteFile = () => {
    setFilename("");
    setPresignedGetImageUrl("");
  };

  const handleCloseError = () => {
    // uploader is disabled based on this error, so we do not want to reset it
    // if that is the error
    if (transcriberError && transcriberError.name === "UPLOAD_INIT_ERROR") {
      return;
    }
    setTranscriberError(null);
  };

  const disableImageUploader = transcriberError && transcriberError.name === "UPLOAD_INIT_ERROR";

  const theme = useTheme();
  return (
    <ColumnFlexBox gap={3}>
      {transcriberError && <ErrorBox errorMessage={transcriberError.message} onClose={handleCloseError} />}
      <ColumnFlexBox gap={0.5}>
        <Typography variant="body1Semibold">Image Upload</Typography>
        <Subtext variant="body2">
          Upload an image of a recipe for us to scan and add the recipe details for you. Please make sure to include the recipe yield,
          ingredients, and instructions.
        </Subtext>
      </ColumnFlexBox>
      <ColumnFlexBox gap={2}>
        <ImageFileDragAndDrop
          onUpload={handleUpload}
          loading={!!imageUploading}
          disabled={!!disableImageUploader}
          sx={{ minHeight: "114px" }}
        />
        {presignedGetImageUrl && // only show once image is uploaded
          filename && (
            <RowFlexBox sx={{ borderRadius: "4px", backgroundColor: theme.palette.greyscale[100] }}>
              <Typography variant="body2" fontWeight={400}>
                {filename}
              </Typography>
              <IconButton onClick={deleteFile} sx={{ padding: 0 }}>
                <DeleteIcon sx={{ color: theme.palette.greyscale[500] }} />
              </IconButton>
            </RowFlexBox>
          )}
      </ColumnFlexBox>
    </ColumnFlexBox>
  );
};
