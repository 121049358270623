import { faker } from "@faker-js/faker";
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
  RRuleSet: string;
  Time: string;
  Timezone: string;
};




export type AccountVerificationMethod =
  | 'email'
  | 'txt'
  | 'selfServiceLink'
  | 'selfServiceQr'
  | '%future added value';

export type ActiveAthleteTransferOffsetConnection = {
  readonly edges: ReadonlyArray<AthleteTransfer>;
  readonly pageInfo: OffsetPageInfo;
};

export type Activity = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly type: ActivityType;
  readonly start: Scalars['Time'];
  readonly end: Scalars['Time'];
};

export type ActivityTemplate = {
  readonly id: Scalars['ID'];
  readonly activity: Activity;
  readonly hasDateModifications: Scalars['Boolean'];
};


export type ActivityTemplateHasDateModificationsArgs = {
  after: Scalars['Date'];
};

/**
 * Modification of an ActivityTemplate, tied to either an individual date or a day of the week
 * Currently only time edits (start and end) are supported
 */
export type ActivityTemplateModification = {
  readonly activityTemplate: ActivityTemplate;
  readonly start: Scalars['Time'];
  readonly end: Scalars['Time'];
};

export type ActivityType =
  | 'practice'
  | 'lift'
  | 'conditioning'
  | 'competition'
  | 'class'
  | '%future added value';

export type AddActivityInput = {
  readonly name: Scalars['String'];
  readonly start: Scalars['Time'];
  readonly end: Scalars['Time'];
  readonly type: ActivityType;
};

export type AddActivityTemplateCalendarInput = {
  readonly activity: AddActivityInput;
  readonly dateModifications: ReadonlyArray<MealPlanDateModificationInput>;
  readonly dayOfWeekModifications: ReadonlyArray<MealPlanDayOfWeekModificationInput>;
};

export type AddAgreementFormInput = {
  readonly title: Scalars['String'];
  readonly url: Scalars['String'];
  readonly acceptAgreementText: Scalars['String'];
};

export type AddAgreementFormPayload = {
  readonly agreementForm: AgreementForm;
};

export type AddAgreementFormSubmissionInput = {
  readonly agreementFormId: Scalars['ID'];
};

export type AddAgreementFormSubmissionPayload = {
  readonly agreementForm: AgreementForm;
};

export type AddAndLinkTeamworksTeamsInput = {
  readonly id: Scalars['ID'];
  readonly linkedTeams: ReadonlyArray<LinkTeamworksTeamInput>;
  readonly newTeams: ReadonlyArray<CreateTeamworksTeamInput>;
  readonly addedSportsMappings: ReadonlyArray<SportsMappingInput>;
  readonly addPositionsMappings: ReadonlyArray<PositionsMappingInput>;
};

export type AddAndLinkTeamworksTeamsPayload = {
  readonly org: Org;
};

export type AddAnthropometryEntryInput = {
  readonly athleteId: Scalars['ID'];
  readonly anthropometryEntry: AnthropometryEntryFormInput;
};

export type AddAthleteDislikedFoodGroupPayload = {
  readonly athlete: Athlete;
};

export type AddAthleteDislikedFoodPayload = {
  readonly athlete: Athlete;
};

export type AddAthleteEmailInput = {
  readonly athleteId: Scalars['ID'];
  readonly email: Scalars['String'];
};

export type AddAthleteEmailPayload = {
  readonly athlete: Athlete;
};

export type AddAthleteLikedFoodGroupPayload = {
  readonly athlete: Athlete;
};

export type AddAthleteLikedFoodPayload = {
  readonly athlete: Athlete;
};

export type AddAthletePhoneNumberInput = {
  readonly athleteId: Scalars['ID'];
  readonly phoneNumber: Scalars['String'];
};

export type AddAthletePhoneNumberPayload = {
  readonly athlete: Athlete;
};

export type AddBulkOrderInput = {
  readonly mealMenuId: Scalars['ID'];
  readonly pickupTime: Scalars['DateTime'];
  readonly teamId: Scalars['ID'];
  readonly deliveryLocation: Scalars['String'];
  readonly childMealMenuPlates: ReadonlyArray<AddMealMenuPlateInput>;
};

export type AddBulkOrderPayload = {
  readonly bulkOrder: BulkOrder;
  readonly skippedUsers: ReadonlyArray<SkippedUserPayload>;
  readonly skippedPlates: ReadonlyArray<SkippedPlatePayload>;
};

export type AddDeliveryLocationInput = {
  readonly name: Scalars['String'];
  readonly deliveryInstructions: Maybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
  readonly teamLabelIds: ReadonlyArray<Scalars['ID']>;
  readonly googlePlaceId: Scalars['String'];
  readonly lat: Scalars['Float'];
  readonly long: Scalars['Float'];
};

export type AddDeliveryLocationPayload = {
  readonly deliveryLocation: DeliveryLocation;
};

export type AddDeviceInput = {
  readonly deviceType: DeviceType;
  readonly deviceId: Scalars['String'];
};

export type AddDevicePayload = {
  readonly result: Scalars['Boolean'];
};

export type AddEventInput = {
  readonly entityId: Scalars['ID'];
  readonly entityType: Scalars['String'];
  readonly datetime: Scalars['DateTime'];
  readonly type: Scalars['String'];
};

export type AddEventPayload = {
  readonly event: Event;
};

export type AddFoodItemToGroceryListInput = {
  readonly groceryListId: Scalars['ID'];
  readonly servingId: Scalars['ID'];
  readonly amount: Scalars['Int'];
};

export type AddFoodLogActivityWithTemplateForAthleteInput = {
  readonly date: Scalars['Date'];
  readonly athleteId: Scalars['ID'];
  readonly timezone: Scalars['Timezone'];
  readonly rpe: Maybe<Scalars['Int']>;
  readonly activityTemplateId: Scalars['ID'];
  readonly activity: AddActivityInput;
};

export type AddFoodLogActivityWithTemplateForAthletePayload = {
  readonly foodLogActivity: FoodLogActivity;
};

export type AddFoodLogActivityWithTemplateInput = {
  readonly date: Scalars['Date'];
  readonly timezone: Scalars['Timezone'];
  readonly rpe: Maybe<Scalars['Int']>;
  readonly activityTemplateId: Scalars['ID'];
  readonly activity: AddActivityInput;
};

export type AddFoodLogActivityWithTemplatePayload = {
  readonly foodLogActivity: FoodLogActivity;
};

export type AddFoodLogActivityWithoutTemplateForAthleteInput = {
  readonly date: Scalars['Date'];
  readonly athleteId: Scalars['ID'];
  readonly timezone: Scalars['Timezone'];
  readonly rpe: Maybe<Scalars['Int']>;
  readonly activity: AddActivityInput;
};

export type AddFoodLogActivityWithoutTemplateForAthletePayload = {
  readonly foodLogActivity: FoodLogActivity;
};

export type AddFoodLogActivityWithoutTemplateInput = {
  readonly date: Scalars['Date'];
  readonly timezone: Scalars['Timezone'];
  readonly rpe: Maybe<Scalars['Int']>;
  readonly activity: AddActivityInput;
};

export type AddFoodLogActivityWithoutTemplatePayload = {
  readonly foodLogActivity: FoodLogActivity;
};

export type AddFoodLogMealCommentInput = {
  readonly foodLogMealId: Scalars['ID'];
  readonly comment: CommentInput;
};

export type AddFoodLogMealCommentPayload = {
  readonly foodLogMeal: FoodLogMeal;
  readonly comment: Comment;
};

export type AddFoodLogMealImageInput = {
  readonly foodLogMealId: Scalars['ID'];
  readonly imageUrl: Scalars['String'];
  readonly position: Scalars['Int'];
};

export type AddFoodLogMealImagePayload = {
  readonly foodLogMeal: FoodLogMeal;
};

export type AddFoodLogMealPayload = {
  readonly foodLogMeal: FoodLogMeal;
};

export type AddFoodLogMealWithTemplateForAthleteInput = {
  readonly date: Scalars['Date'];
  readonly athleteId: Scalars['ID'];
  readonly timezone: Scalars['Timezone'];
  readonly mealTemplateId: Scalars['ID'];
  readonly meal: AddMealInput;
};

export type AddFoodLogMealWithTemplateForAthletePayload = {
  readonly foodLogMeal: FoodLogMeal;
};

export type AddFoodLogMealWithTemplateInput = {
  readonly date: Scalars['Date'];
  readonly timezone: Scalars['Timezone'];
  readonly mealTemplateId: Scalars['ID'];
  readonly meal: AddMealInput;
};

export type AddFoodLogMealWithTemplatePayload = {
  readonly foodLogMeal: FoodLogMeal;
};

export type AddFoodLogMealWithoutTemplateForAthleteInput = {
  readonly date: Scalars['Date'];
  readonly athleteId: Scalars['ID'];
  readonly timezone: Scalars['Timezone'];
  readonly meal: AddMealInput;
};

export type AddFoodLogMealWithoutTemplateForAthletePayload = {
  readonly foodLogMeal: FoodLogMeal;
};

export type AddFoodLogMealWithoutTemplateInput = {
  readonly date: Scalars['Date'];
  readonly timezone: Scalars['Timezone'];
  readonly meal: AddMealInput;
};

export type AddFoodLogMealWithoutTemplatePayload = {
  readonly foodLogMeal: FoodLogMeal;
};

export type AddMealInput = {
  readonly name: Scalars['String'];
  readonly type: MealType;
  readonly start: Scalars['Time'];
  readonly end: Scalars['Time'];
};

export type AddMealMenuDiningStationInput = {
  readonly name: Scalars['String'];
  readonly position: Scalars['Int'];
  readonly maxAmount: Maybe<Scalars['Int']>;
  readonly diningStationTemplateId: Maybe<Scalars['ID']>;
  readonly createMenuItems: ReadonlyArray<CreateMenuItemInput>;
  readonly addMenuItems: ReadonlyArray<AddMenuItemInput>;
  readonly editMenuItems: ReadonlyArray<EditMenuItemInput>;
};

export type AddMealMenuLogItemForAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly mealMenuId: Scalars['ID'];
  readonly timelineMeal: TimelineMealInput;
  readonly item: MealMenuLogItemInput;
};

export type AddMealMenuLogItemInput = {
  readonly mealMenuId: Scalars['ID'];
  readonly timelineMeal: TimelineMealInput;
  readonly item: MealMenuLogItemInput;
};

export type AddMealMenuLogItemPayload = {
  readonly timelineMeal: TimelineMeal;
  readonly mealMenuLogItem: MealMenuLogItem;
};

export type AddMealMenuLogItemsForAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly mealMenuId: Scalars['ID'];
  readonly timelineMeal: TimelineMealInput;
  readonly items: ReadonlyArray<MealMenuLogItemInput>;
};

export type AddMealMenuLogItemsInput = {
  readonly mealMenuId: Scalars['ID'];
  readonly timelineMeal: TimelineMealInput;
  readonly items: ReadonlyArray<MealMenuLogItemInput>;
};

export type AddMealMenuLogItemsPayload = {
  readonly timelineMeal: TimelineMeal;
  readonly mealMenuLogItems: ReadonlyArray<MealMenuLogItem>;
};

export type AddMealMenuPlateInput = {
  readonly name: Scalars['String'];
  readonly athleteIds: ReadonlyArray<Scalars['ID']>;
  readonly items: ReadonlyArray<AddMealMenuPlateItemInput>;
};

export type AddMealMenuPlateItemInput = {
  readonly menuItemId: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly options: ReadonlyArray<MealMenuPlateItemOptionInput>;
  readonly specialRequests: Maybe<Scalars['String']>;
};

export type AddMealOptionInput = {
  readonly mealTemplateId: Scalars['ID'];
  readonly mealOption: MealOptionInput;
};

export type AddMealOptionPayload = {
  readonly mealOption: MealOption;
};

export type AddMealTemplateCalendarInput = {
  readonly meal: AddMealInput;
  readonly dateModifications: ReadonlyArray<MealPlanDateModificationInput>;
  readonly dayOfWeekModifications: ReadonlyArray<MealPlanDayOfWeekModificationInput>;
};

export type AddMenuItemChoiceInput = {
  readonly name: Scalars['String'];
  readonly position: Scalars['Int'];
  readonly required: Scalars['Boolean'];
  readonly maxOptionsCount: Maybe<Scalars['Int']>;
  readonly options: ReadonlyArray<AddMenuItemChoiceOptionInput>;
};

export type AddMenuItemChoiceOptionInput = {
  readonly name: Scalars['String'];
  readonly position: Scalars['Int'];
  readonly canEditAmount: Scalars['Boolean'];
  readonly defaultAmount: Scalars['Float'];
  readonly maxAmount: Scalars['Float'];
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
};

export type AddMenuItemHistoryMissingIngredientsInput = {
  readonly menuItemHistoryId: Scalars['ID'];
  readonly menuItems: ReadonlyArray<MenuItemMissingIngredientsInput>;
};

export type AddMenuItemHistoryMissingIngredientsPayload = {
  readonly menuItemHistory: MenuItemHistory;
};

export type AddMenuItemInput = {
  readonly menuItemId: Scalars['ID'];
  readonly appearance: MenuItemAppearanceInput;
};

export type AddMenuOrderCommentInput = {
  readonly menuOrderId: Scalars['ID'];
  readonly comment: CommentInput;
};

export type AddMenuOrderCommentPayload = {
  readonly menuOrder: MenuOrder;
  readonly comment: Comment;
};

export type AddMenuOrderForAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly mealMenuId: Scalars['ID'];
  readonly mealTemplateId: Maybe<Scalars['ID']>;
  readonly pickupTime: Scalars['DateTime'];
  readonly items: Maybe<ReadonlyArray<MenuOrderItemInput>>;
  readonly orderItems: Maybe<ReadonlyArray<MenuOrderItemInput>>;
  readonly logItems: Maybe<ReadonlyArray<MenuOrderItemInput>>;
  readonly images: Maybe<ReadonlyArray<ImageInput>>;
  readonly timelineMeal: Maybe<TimelineMealInput>;
  readonly expectedOrderCount: Maybe<Scalars['Int']>;
};

export type AddMenuOrderForAthletePayload = {
  readonly menuOrder: MenuOrder;
};

export type AddMenuOrderImageInput = {
  readonly menuOrderId: Scalars['ID'];
  readonly imageUrl: Scalars['String'];
  readonly position: Scalars['Int'];
};

export type AddMenuOrderImagePayload = {
  readonly menuOrder: MenuOrder;
};

export type AddMenuOrderItemRatingInput = {
  readonly menuItemId: Scalars['ID'];
  readonly menuOrderItemId: Scalars['ID'];
  readonly value: RatingValue;
  readonly review: Maybe<Scalars['String']>;
};

export type AddMenuOrderItemRatingsInput = {
  readonly ratings: ReadonlyArray<AddMenuOrderItemRatingInput>;
};

export type AddMenuOrderItemRatingsPayload = {
  readonly menuOrderItems: ReadonlyArray<MenuOrderItem>;
};

export type AddMenuOrderPayload = {
  readonly menuOrder: MenuOrder;
};

export type AddMenuOrderWithTemplateInput = {
  readonly mealMenuId: Scalars['ID'];
  readonly mealTemplateId: Scalars['ID'];
  readonly pickupTime: Scalars['DateTime'];
  readonly items: Maybe<ReadonlyArray<MenuOrderItemInput>>;
  readonly orderItems: Maybe<ReadonlyArray<MenuOrderItemInput>>;
  readonly logItems: Maybe<ReadonlyArray<MenuOrderItemInput>>;
  readonly images: Maybe<ReadonlyArray<ImageInput>>;
  readonly timelineMeal: Maybe<TimelineMealInput>;
  readonly expectedOrderCount: Maybe<Scalars['Int']>;
};

export type AddMenuOrderWithTemplatePayload = {
  readonly menuOrder: MenuOrder;
};

export type AddMenuOrderWithoutTemplateInput = {
  readonly mealMenuId: Scalars['ID'];
  readonly pickupTime: Scalars['DateTime'];
  readonly items: Maybe<ReadonlyArray<MenuOrderItemInput>>;
  readonly orderItems: Maybe<ReadonlyArray<MenuOrderItemInput>>;
  readonly logItems: Maybe<ReadonlyArray<MenuOrderItemInput>>;
  readonly images: Maybe<ReadonlyArray<ImageInput>>;
  readonly timelineMeal: Maybe<TimelineMealInput>;
  readonly expectedOrderCount: Maybe<Scalars['Int']>;
};

export type AddMenuOrderWithoutTemplatePayload = {
  readonly menuOrder: MenuOrder;
};

export type AddNixBrandedFoodByUpcInput = {
  readonly upc: Scalars['String'];
};

export type AddNixBrandedFoodByUpcPayload = {
  readonly food: Maybe<Food>;
};

export type AddNixBrandedFoodInput = {
  readonly nixBrandType: Scalars['Int'];
  readonly nixItemId: Scalars['ID'];
};

export type AddNixBrandedFoodPayload = {
  readonly food: Maybe<Food>;
};

export type AddNixCommonFoodInput = {
  readonly name: Scalars['String'];
  readonly nixTagId: Scalars['String'];
};

export type AddNixCommonFoodPayload = {
  readonly food: Maybe<Food>;
};

export type AddPositionInput = {
  readonly sportId: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type AddPositionPayload = {
  readonly position: Position;
};

export type AddRecipeImageInput = {
  readonly recipeId: Scalars['ID'];
  readonly image: ImageInput;
};

export type AddRecipeImagePayload = {
  readonly recipe: Recipe;
};

export type AddRestaurantLocationContactInput = {
  readonly locationId: Scalars['ID'];
  readonly restaurantLocationContact: RestaurantLocationContactInput;
};

export type AddRestaurantLocationContactPayload = {
  readonly restaurantLocationContact: RestaurantLocationContact;
};

export type AddRestaurantLocationInput = {
  readonly restaurantId: Scalars['String'];
  readonly lat: Scalars['Float'];
  readonly long: Scalars['Float'];
  readonly googlePlaceId: Scalars['String'];
  readonly notes: Maybe<Scalars['String']>;
  readonly email: Maybe<Scalars['String']>;
  readonly phoneNumber: Maybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
  readonly deliveryLocationIds: ReadonlyArray<Scalars['ID']>;
};

export type AddRestaurantLocationPayload = {
  readonly restaurantLocation: RestaurantLocation;
};

export type AddRestaurantMenuLinkInput = {
  readonly mealMenuId: Scalars['ID'];
  readonly restaurantId: Scalars['ID'];
  readonly restaurantMenuId: Scalars['ID'];
  readonly deliveryLocationId: Maybe<Scalars['ID']>;
  readonly restaurantLocationId: Maybe<Scalars['ID']>;
  readonly allowCustomOrders: Scalars['Boolean'];
  readonly allowPlateOrders: Scalars['Boolean'];
  readonly sections: ReadonlyArray<RestaurantMenuSectionLinkInput>;
  readonly plates: ReadonlyArray<AddRestaurantMenuLinkPlateInput>;
};

export type AddRestaurantMenuLinkOrderForAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly restaurantMenuLinkId: Scalars['ID'];
  readonly mealTemplateId: Maybe<Scalars['ID']>;
  readonly items: ReadonlyArray<RestaurantMenuLinkOrderItemInput>;
  readonly timelineMeal: Maybe<TimelineMealInput>;
};

export type AddRestaurantMenuLinkOrderForAthletePayload = {
  readonly restaurantMenuLinkOrder: RestaurantMenuLinkOrder;
  readonly timelineMeal: TimelineMeal;
};

export type AddRestaurantMenuLinkOrderInput = {
  readonly restaurantMenuLinkId: Scalars['ID'];
  readonly mealTemplateId: Maybe<Scalars['ID']>;
  readonly items: ReadonlyArray<RestaurantMenuLinkOrderItemInput>;
  readonly timelineMeal: Maybe<TimelineMealInput>;
};

export type AddRestaurantMenuLinkOrderPayload = {
  readonly restaurantMenuLinkOrder: RestaurantMenuLinkOrder;
  readonly timelineMeal: TimelineMeal;
};

export type AddRestaurantMenuLinkPayload = {
  readonly restaurantMenuLink: RestaurantMenuLink;
};

export type AddRestaurantMenuLinkPlateInput = {
  readonly position: Scalars['Int'];
  readonly bulkOrderAmount: Maybe<Scalars['Int']>;
  readonly items: ReadonlyArray<AddRestaurantMenuLinkPlateItemInput>;
  readonly isDefault: Maybe<Scalars['Boolean']>;
};

export type AddRestaurantMenuLinkPlateItemInput = {
  readonly menuItemId: Scalars['ID'];
  readonly position: Scalars['Int'];
  readonly amount: Scalars['Float'];
  readonly options: ReadonlyArray<RestaurantMenuLinkPlateItemOptionInput>;
  readonly specialRequests: Maybe<Scalars['String']>;
};

export type AddRestaurantMenuLinkPlateOrderForAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly restaurantMenuLinkPlateId: Scalars['ID'];
  readonly mealTemplateId: Maybe<Scalars['ID']>;
  readonly timelineMeal: Maybe<TimelineMealInput>;
};

export type AddRestaurantMenuLinkPlateOrderForAthletePayload = {
  readonly restaurantMenuLinkPlateOrder: RestaurantMenuLinkPlateOrder;
  readonly timelineMeal: TimelineMeal;
};

export type AddRestaurantMenuLinkPlateOrderInput = {
  readonly restaurantMenuLinkPlateId: Scalars['ID'];
  readonly mealTemplateId: Maybe<Scalars['ID']>;
  readonly timelineMeal: Maybe<TimelineMealInput>;
};

export type AddRestaurantMenuLinkPlateOrderPayload = {
  readonly restaurantMenuLinkPlateOrder: RestaurantMenuLinkPlateOrder;
  readonly timelineMeal: TimelineMeal;
};

export type AddRestaurantMenuLogForAthleteInput = {
  readonly dateTime: Scalars['DateTime'];
  readonly timezone: Scalars['Timezone'];
  readonly athleteId: Scalars['ID'];
  readonly restaurantMenuId: Scalars['ID'];
  readonly restaurantLocationId: Maybe<Scalars['ID']>;
  readonly mealTemplateId: Maybe<Scalars['ID']>;
  readonly items: ReadonlyArray<RestaurantMenuLogItemInput>;
  readonly type: MealType;
};

export type AddRestaurantMenuLogForAthletePayload = {
  readonly restaurantMenuLog: RestaurantMenuLog;
};

export type AddRestaurantMenuLogInput = {
  readonly dateTime: Scalars['DateTime'];
  readonly timezone: Scalars['Timezone'];
  readonly restaurantMenuId: Scalars['ID'];
  readonly mealTemplateId: Maybe<Scalars['ID']>;
  readonly restaurantLocationId: Maybe<Scalars['ID']>;
  readonly items: ReadonlyArray<RestaurantMenuLogItemInput>;
  readonly type: MealType;
};

export type AddRestaurantMenuLogItemForAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly restaurantMenuId: Scalars['ID'];
  readonly restaurantLocationId: Maybe<Scalars['ID']>;
  readonly timelineMeal: TimelineMealInput;
  readonly item: RestaurantMenuLogItemInput;
};

export type AddRestaurantMenuLogItemInput = {
  readonly restaurantMenuId: Scalars['ID'];
  readonly restaurantLocationId: Maybe<Scalars['ID']>;
  readonly timelineMeal: TimelineMealInput;
  readonly item: RestaurantMenuLogItemInput;
};

export type AddRestaurantMenuLogItemPayload = {
  readonly timelineMeal: TimelineMeal;
  readonly restaurantMenuLogItem: RestaurantMenuLogItem;
};

export type AddRestaurantMenuLogItemsForAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly restaurantMenuId: Scalars['ID'];
  readonly restaurantLocationId: Maybe<Scalars['ID']>;
  readonly timelineMeal: TimelineMealInput;
  readonly items: ReadonlyArray<RestaurantMenuLogItemInput>;
};

export type AddRestaurantMenuLogItemsInput = {
  readonly restaurantMenuId: Scalars['ID'];
  readonly restaurantLocationId: Maybe<Scalars['ID']>;
  readonly timelineMeal: TimelineMealInput;
  readonly items: ReadonlyArray<RestaurantMenuLogItemInput>;
};

export type AddRestaurantMenuLogItemsPayload = {
  readonly timelineMeal: TimelineMeal;
  readonly restaurantMenuLogItems: ReadonlyArray<RestaurantMenuLogItem>;
};

export type AddRestaurantMenuLogPayload = {
  readonly restaurantMenuLog: RestaurantMenuLog;
};

export type AddRestaurantMenuSectionInput = {
  readonly name: Scalars['String'];
  readonly position: Scalars['Int'];
  readonly createMenuItems: ReadonlyArray<CreateMenuItemInput>;
  readonly addMenuItems: ReadonlyArray<AddMenuItemInput>;
  readonly editMenuItems: ReadonlyArray<EditMenuItemInput>;
};

export type AddSportInput = {
  readonly name: Scalars['String'];
};

export type AddSportPayload = {
  readonly sport: Sport;
};

export type AddTeamLabelInput = {
  readonly name: Scalars['String'];
  readonly isActive: Scalars['Boolean'];
};

export type AddTeamLabelPayload = {
  readonly teamLabel: TeamLabel;
};

export type AddTimelineMealCommentForAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly timelineMeal: TimelineMealInput;
  readonly text: Scalars['String'];
};

export type AddTimelineMealCommentForMeInput = {
  readonly timelineMeal: TimelineMealInput;
  readonly text: Scalars['String'];
};

export type AddTimelineMealCommentPayload = {
  readonly timelineMeal: TimelineMeal;
  readonly comment: Comment;
};

export type AddTimelineMealForAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly start: Scalars['DateTime'];
  readonly timezone: Scalars['Timezone'];
  readonly durationInMinutes: Scalars['Int'];
  readonly type: MealType;
  readonly mealTemplateId: Maybe<Scalars['ID']>;
};

export type AddTimelineMealImageForAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly timelineMeal: TimelineMealInput;
  readonly url: Scalars['String'];
  readonly position: Scalars['Int'];
};

export type AddTimelineMealImageInput = {
  readonly timelineMeal: TimelineMealInput;
  readonly url: Scalars['String'];
  readonly position: Scalars['Int'];
};

export type AddTimelineMealImagePayload = {
  readonly timelineMeal: TimelineMeal;
};

export type AddTimelineMealInput = {
  readonly name: Scalars['String'];
  readonly start: Scalars['DateTime'];
  readonly timezone: Scalars['Timezone'];
  readonly durationInMinutes: Scalars['Int'];
  readonly type: MealType;
  readonly mealTemplateId: Maybe<Scalars['ID']>;
};

export type AddTimelineMealPayload = {
  readonly timelineMeal: TimelineMeal;
};

export type AddTimelineMealServingAmountForAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly timelineMeal: TimelineMealInput;
  readonly servingAmount: CreateServingAmountInput;
};

export type AddTimelineMealServingAmountInput = {
  readonly timelineMeal: TimelineMealInput;
  readonly servingAmount: CreateServingAmountInput;
};

export type AddTimelineMealServingAmountPayload = {
  readonly timelineMeal: TimelineMeal;
};

export type Address = {
  readonly displayName: Scalars['String'];
  readonly lat: Scalars['Float'];
  readonly long: Scalars['Float'];
};

export type AdvancedSelectionGroup = {
  readonly conditions: ReadonlyArray<TagCondition>;
};

export type AdvancedSelectionGroupInput = {
  readonly conditions: ReadonlyArray<AssignTagConditionInput>;
};

export type AdvancedSelectionInput = {
  readonly groups: ReadonlyArray<AdvancedSelectionGroupInput>;
  readonly includeAthleteIds: ReadonlyArray<Scalars['ID']>;
  readonly excludeAthleteIds: ReadonlyArray<Scalars['ID']>;
};

export type AdvancedSelectionType = {
  readonly id: Scalars['ID'];
  readonly groups: ReadonlyArray<AdvancedSelectionGroup>;
  readonly includeAthletes: ReadonlyArray<Athlete>;
  readonly excludeAthletes: ReadonlyArray<Athlete>;
};

export type AgreementForm = {
  readonly id: Scalars['ID'];
  readonly title: Scalars['String'];
  readonly url: Scalars['String'];
  readonly acceptAgreementText: Scalars['String'];
};

export type AgreementFormOffsetConnection = {
  readonly edges: ReadonlyArray<AgreementForm>;
  readonly pageInfo: OffsetPageInfo;
};

export type AiAugmented = {
  readonly isAiGenerated: Scalars['Boolean'];
  readonly isAiTranscribed: Scalars['Boolean'];
};

export type AiType =
  | 'generated'
  | 'transcribed'
  | '%future added value';

export type AnthropometryEntry = {
  readonly id: Scalars['ID'];
  readonly heightInCm: Maybe<Scalars['Float']>;
  readonly weightInKg: Scalars['Float'];
  readonly leanBodyMassInKg: Maybe<Scalars['Float']>;
  readonly bodyFatMassInKg: Maybe<Scalars['Float']>;
  readonly skeletalMuscleMassInKg: Maybe<Scalars['Float']>;
  readonly trunkFatInKg: Maybe<Scalars['Float']>;
  readonly dryLeanMassInKg: Maybe<Scalars['Float']>;
  readonly visceralFatArea: Maybe<Scalars['Float']>;
  readonly percentBodyFat: Maybe<Scalars['Float']>;
  readonly datetime: Scalars['DateTime'];
  readonly sex: SexType;
  readonly type: AnthropometryEntryType;
  readonly comment: Maybe<Scalars['String']>;
  readonly boneMineralDensity: Maybe<Scalars['Float']>;
  readonly boneMineralContent: Maybe<Scalars['Float']>;
  readonly boneArea: Maybe<Scalars['Float']>;
  readonly boneMass: Maybe<Scalars['Float']>;
  readonly boneMineralDensityZScore: Maybe<Scalars['Float']>;
  readonly boneMineralDensityTScore: Maybe<Scalars['Float']>;
  readonly height: Maybe<Scalars['Float']>;
  readonly weight: Scalars['Float'];
  readonly leanBodyMass: Maybe<Scalars['Float']>;
  readonly bodyFatMass: Maybe<Scalars['Float']>;
  readonly dryLeanMass: Maybe<Scalars['Float']>;
  readonly skeletalMuscleMass: Maybe<Scalars['Float']>;
  readonly trunkFat: Maybe<Scalars['Float']>;
};

export type AnthropometryEntryField =
  | 'percentBodyFat'
  | 'height'
  | 'weight'
  | 'leanBodyMass'
  | '%future added value';

export type AnthropometryEntryFormInput = {
  readonly height: Maybe<Scalars['Float']>;
  readonly weight: Scalars['Float'];
  readonly leanBodyMass: Maybe<Scalars['Float']>;
  readonly bodyFatMass: Maybe<Scalars['Float']>;
  readonly percentBodyFat: Maybe<Scalars['Float']>;
  readonly datetime: Scalars['DateTime'];
  readonly sex: SexType;
  readonly type: AnthropometryEntryType;
  readonly comment: Maybe<Scalars['String']>;
  readonly boneMineralDensity: Maybe<Scalars['Float']>;
  readonly boneMass: Maybe<Scalars['Float']>;
  readonly boneMineralContent: Maybe<Scalars['Float']>;
  readonly boneArea: Maybe<Scalars['Float']>;
  readonly boneMineralDensityZScore: Maybe<Scalars['Float']>;
  readonly boneMineralDensityTScore: Maybe<Scalars['Float']>;
  readonly dryLeanMass: Maybe<Scalars['Float']>;
  readonly skeletalMuscleMass: Maybe<Scalars['Float']>;
  readonly trunkFat: Maybe<Scalars['Float']>;
  readonly visceralFatArea: Maybe<Scalars['Float']>;
};

export type AnthropometryEntryMetricField =
  | 'percentBodyFat'
  | 'heightInCm'
  | 'weightInKg'
  | 'leanBodyMass'
  | '%future added value';

export type AnthropometryEntryType =
  | 'estimate'
  | 'dexa'
  | 'bodpod'
  | 'bioelectricalImpedance'
  | 'isak'
  | 'weighIn'
  | '%future added value';

export type AnthropometryForm = {
  readonly questions: ReadonlyArray<AnthropometryFormQuestion>;
};

export type AnthropometryFormField =
  | 'height'
  | 'weight'
  | 'percentBodyFat'
  | '%future added value';

export type AnthropometryFormInput = {
  readonly height: Maybe<Scalars['Float']>;
  readonly weight: Scalars['Float'];
  readonly percentBodyFat: Maybe<Scalars['Float']>;
};

export type AnthropometryFormQuestion = {
  readonly field: AnthropometryFormField;
};

export type AnthropometryTemplate = {
  readonly id: Scalars['ID'];
  readonly height: Maybe<Scalars['Float']>;
  readonly weight: Scalars['Float'];
  readonly heightInCm: Maybe<Scalars['Float']>;
  readonly weightInKg: Maybe<Scalars['Float']>;
  readonly percentBodyFat: Maybe<Scalars['Float']>;
};

export type AnthropometryTemplateInput = {
  readonly height: Maybe<Scalars['Float']>;
  readonly weight: Scalars['Float'];
  readonly percentBodyFat: Maybe<Scalars['Float']>;
};

export type AppModeRoles = {
  readonly athlete: ReadonlyArray<Role>;
  readonly dietitian: ReadonlyArray<Role>;
  readonly chef: ReadonlyArray<Role>;
  readonly nonRdAdmin: ReadonlyArray<Role>;
};

export type ArchiveAthleteInput = {
  readonly id: Scalars['ID'];
};

export type ArchiveAthletePayload = {
  readonly athlete: Athlete;
};

export type ArchiveGoalConfigurationInput = {
  readonly id: Scalars['ID'];
};

export type ArchiveGoalConfigurationPayload = {
  readonly goalConfigurationId: Scalars['ID'];
};

export type AssignMealPlanTemplateToAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly dateAssignment: OverrideMealPlanDateAssignmentInput;
  readonly mealPlanTemplateId: Scalars['ID'];
  readonly anthropometry: MealPlanFromTemplateAnthropometryInput;
  readonly foodPreferences: Maybe<FoodPreferenceInput>;
  readonly mealPlanTemplateGoalId: Maybe<Scalars['ID']>;
  readonly birthDate: Maybe<Scalars['Date']>;
  readonly isAutoSuggestionsEnabled: Scalars['Boolean'];
};

export type AssignMealPlanTemplateToAthletePayload = {
  readonly mealPlan: MealPlan;
};

export type AssignMetricMealPlanTemplateToAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly dateAssignment: OverrideMealPlanDateAssignmentInput;
  readonly mealPlanTemplateId: Scalars['ID'];
  readonly anthropometry: MealPlanFromTemplateMetricAnthropometryInput;
  readonly foodPreferences: Maybe<FoodPreferenceInput>;
  readonly mealPlanTemplateGoalId: Maybe<Scalars['ID']>;
  readonly birthDate: Maybe<Scalars['Date']>;
  readonly isAutoSuggestionsEnabled: Scalars['Boolean'];
};

export type AssignTagConditionInput = {
  readonly isComplement: Scalars['Boolean'];
  readonly type: TagConditionType;
  readonly entityIds: ReadonlyArray<Scalars['ID']>;
};

export type Athlete = {
  readonly id: Scalars['ID'];
  readonly activationLinkSent: Maybe<Scalars['DateTime']>;
  readonly isNotemealOnly: Scalars['Boolean'];
  readonly isProfileNotemealOnly: Scalars['Boolean'];
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly sex: SexType;
  readonly birthDate: Maybe<Scalars['Date']>;
  readonly phoneNumber: Maybe<Scalars['String']>;
  readonly email: Maybe<Scalars['String']>;
  readonly headshotUrl: Maybe<Scalars['String']>;
  readonly secaUid: Maybe<Scalars['String']>;
  readonly inbodyUid: Maybe<Scalars['String']>;
  readonly jerseyNumber: Maybe<Scalars['String']>;
  readonly team: Team;
  readonly position: Maybe<Position>;
  readonly likedFoods: ReadonlyArray<Food>;
  readonly dislikedFoods: ReadonlyArray<Food>;
  readonly likedFoodGroups: ReadonlyArray<FoodGroup>;
  readonly dislikedFoodGroups: ReadonlyArray<FoodGroup>;
  readonly currentGoal: Maybe<Goal>;
  readonly goals: ReadonlyArray<Goal>;
  readonly mostRecentNote: Maybe<Note>;
  readonly notes: ReadonlyArray<Note>;
  readonly mealPlans: ReadonlyArray<MealPlan>;
  readonly sharedMealPlans: ReadonlyArray<MealPlan>;
  readonly sharedMealPlanTemplates: ReadonlyArray<MealPlanTemplate>;
  readonly priorityMealPlanDaysOfWeekInRange: ReadonlyArray<MealPlanDayOfWeekAssignment>;
  readonly mealPlanIndividualDatesInRange: ReadonlyArray<MealPlanIndividualDateAssignment>;
  /** @deprecated Pending web notifications overhaul */
  readonly mealPlanNotifications: ReadonlyArray<Notification>;
  /** @deprecated Pending web notifications overhaul */
  readonly foodLogNotifications: ReadonlyArray<Notification>;
  readonly timelineMealNotifications: ReadonlyArray<Notification>;
  readonly defaultMacroDisplaySettings: Maybe<MacroMealPlanDisplaySettings>;
  readonly defaultExchangeDisplaySettings: Maybe<ExchangeMealPlanDisplaySettings>;
  readonly defaultNoTargetsDisplaySettings: Maybe<NoTargetsDisplaySettings>;
  readonly defaultHideAnthropometry: Maybe<Scalars['Boolean']>;
  readonly macroDisplaySettings: MacroMealPlanDisplaySettings;
  readonly exchangeDisplaySettings: ExchangeMealPlanDisplaySettings;
  readonly noTargetsDisplaySettings: NoTargetsDisplaySettings;
  readonly hideAnthropometry: Scalars['Boolean'];
  readonly mostRecentAnthropometryEntry: Maybe<AnthropometryEntry>;
  readonly mostRecentAnthropometryEntryWith: Maybe<AnthropometryEntry>;
  readonly mostRecentAnthropometryEntryForRmrMethod: Maybe<AnthropometryEntry>;
  readonly anthropometryEntries: ReadonlyArray<AnthropometryEntry>;
  readonly isArchived: Scalars['Boolean'];
  readonly foodLogNotificationSettings: Maybe<FoodLogNotificationSettings>;
  /** @deprecated Remove when getting rid of FoodLogMeal */
  readonly foodLogMealCount: Scalars['Int'];
  readonly imageUploadCount: Scalars['Int'];
  readonly mealPlanViewCount: Scalars['Int'];
  readonly timelineMealCount: Scalars['Int'];
  readonly user: User;
  readonly orgMembership: Maybe<OrgMembership>;
  readonly timelineItemsInRange: ReadonlyArray<TimelineItem>;
  /** @deprecated pending forced update for multiple MenuOrders per menu */
  readonly orderForMenu: Maybe<MenuOrder>;
  readonly ordersForMenu: ReadonlyArray<MenuOrder>;
  readonly ordersForMenus: ReadonlyArray<MenuOrder>;
  readonly kioskPreviewOrderInfo: ReadonlyArray<MenuOrderInfo>;
  readonly checkInsForMealMenus: ReadonlyArray<MealMenuCheckIn>;
  readonly myNamedTagsPreview: MyNamedTagsPreviewPayload;
  readonly myNamedTagsOffsetConnection: NamedTagOffsetConnection;
  readonly allOrderedNamedTags: ReadonlyArray<NamedTag>;
  readonly printableTagsForAthlete: ReadonlyArray<NamedTag>;
  readonly timelineMealsInRange: ReadonlyArray<TimelineMeal>;
  readonly timelineDate: TimelineDate;
  readonly timelineDatesInRange: ReadonlyArray<TimelineDate>;
  readonly recentTimelineCalendarDatesWithActivity: ReadonlyArray<Scalars['String']>;
  readonly mealMenusOnDate: ReadonlyArray<MealMenu>;
  readonly mealPlanDateAssignmentsInRange: ReadonlyArray<MealPlanDateAssignment>;
  readonly upcomingMealMenus: ReadonlyArray<MealMenu>;
  readonly mealMenusCursorConnection: MealMenusCursorConnection;
  readonly activeTransfer: Maybe<AthleteTransfer>;
  readonly hasEverHadGoalTrackerGoals: Scalars['Boolean'];
  readonly goalTrackerGoals: ReadonlyArray<GoalTrackerGoal>;
  readonly assignedToMealMenu: Scalars['Boolean'];
};


export type AthletePriorityMealPlanDaysOfWeekInRangeArgs = {
  start: Scalars['Date'];
  end: Maybe<Scalars['Date']>;
};


export type AthleteMealPlanIndividualDatesInRangeArgs = {
  start: Scalars['Date'];
  end: Maybe<Scalars['Date']>;
};


export type AthleteMostRecentAnthropometryEntryWithArgs = {
  fields: ReadonlyArray<AnthropometryEntryField>;
};


export type AthleteMostRecentAnthropometryEntryForRmrMethodArgs = {
  rmrMethod: Maybe<RmrMethod>;
};


export type AthleteFoodLogMealCountArgs = {
  inLastDays: Maybe<Scalars['Int']>;
};


export type AthleteImageUploadCountArgs = {
  inLastDays: Maybe<Scalars['Int']>;
};


export type AthleteMealPlanViewCountArgs = {
  inLastDays: Maybe<Scalars['Int']>;
};


export type AthleteTimelineMealCountArgs = {
  inLastDays: Maybe<Scalars['Int']>;
  requireOrder: Maybe<Scalars['Boolean']>;
  requireLog: Maybe<Scalars['Boolean']>;
};


export type AthleteTimelineItemsInRangeArgs = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};


export type AthleteOrderForMenuArgs = {
  mealMenuId: Scalars['ID'];
};


export type AthleteOrdersForMenuArgs = {
  mealMenuId: Scalars['ID'];
};


export type AthleteOrdersForMenusArgs = {
  mealMenuIds: ReadonlyArray<Scalars['ID']>;
};


export type AthleteKioskPreviewOrderInfoArgs = {
  mealMenuIds: ReadonlyArray<Scalars['ID']>;
};


export type AthleteCheckInsForMealMenusArgs = {
  mealMenuIds: ReadonlyArray<Scalars['ID']>;
};


export type AthleteMyNamedTagsPreviewArgs = {
  limit: Scalars['Int'];
  priorityTagIds: ReadonlyArray<Scalars['ID']>;
  smartTagsFirst: Maybe<Scalars['Boolean']>;
};


export type AthleteMyNamedTagsOffsetConnectionArgs = {
  input: OffsetPaginationInput;
};


export type AthleteTimelineMealsInRangeArgs = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};


export type AthleteTimelineDateArgs = {
  date: Scalars['Date'];
  timezone: Scalars['Timezone'];
};


export type AthleteTimelineDatesInRangeArgs = {
  start: Scalars['Date'];
  end: Scalars['Date'];
  timezone: Scalars['Timezone'];
};


export type AthleteRecentTimelineCalendarDatesWithActivityArgs = {
  limit: Scalars['Int'];
  timezone: Scalars['Timezone'];
};


export type AthleteMealMenusOnDateArgs = {
  date: Scalars['Date'];
  timezone: Scalars['Timezone'];
};


export type AthleteMealPlanDateAssignmentsInRangeArgs = {
  start: Scalars['Date'];
  end: Scalars['Date'];
};


export type AthleteUpcomingMealMenusArgs = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};


export type AthleteMealMenusCursorConnectionArgs = {
  anchorDate: Scalars['DateTime'];
  input: CursorPaginationInput;
};


export type AthleteGoalTrackerGoalsArgs = {
  date: Scalars['Date'];
};


export type AthleteAssignedToMealMenuArgs = {
  mealMenuId: Scalars['ID'];
};

export type AthleteAttendeePayload = {
  readonly athlete: Athlete;
  readonly checkedIn: Scalars['Boolean'];
};

export type AthleteCursorConnection = {
  readonly edges: ReadonlyArray<Athlete>;
  readonly pageInfo: CursorPageInfo;
};

export type AthleteCursorConnectionWithSelectAll = {
  readonly edges: ReadonlyArray<Athlete>;
  readonly pageInfo: CursorPageInfo;
  readonly selectAllInfo: SelectAllInfo;
};

export type AthleteFilter =
  | 'active'
  | 'no_link_sent'
  | 'link_expired'
  | 'archived'
  | '%future added value';

export type AthleteFoodGroupPreferenceInput = {
  readonly athleteId: Scalars['ID'];
  readonly foodGroupId: Scalars['ID'];
};

export type AthleteFoodPreferenceInput = {
  readonly athleteId: Scalars['ID'];
  readonly foodId: Scalars['ID'];
};

export type AthleteFormInput = {
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly sex: SexType;
  readonly birthDate: Maybe<Scalars['Date']>;
  readonly phoneNumber: Maybe<Scalars['String']>;
  readonly email: Maybe<Scalars['String']>;
  readonly positionId: Maybe<Scalars['ID']>;
  readonly inbodyUid: Maybe<Scalars['String']>;
  readonly secaUid: Maybe<Scalars['String']>;
  readonly jerseyNumber: Maybe<Scalars['String']>;
  readonly removedTagIds: ReadonlyArray<Scalars['ID']>;
  readonly addedTagIds: ReadonlyArray<Scalars['ID']>;
};

export type AthleteGoalConfigurationAndTracker = {
  readonly id: Scalars['ID'];
  readonly goalTitle: Scalars['String'];
  readonly goalCategory: Scalars['String'];
  readonly goalCompletionType: Scalars['String'];
  readonly goalTarget: Maybe<Scalars['Int']>;
  readonly goalTargetUnit: Maybe<Scalars['String']>;
  readonly startDate: Maybe<Scalars['DateTime']>;
  readonly endDate: Maybe<Scalars['DateTime']>;
  readonly goalCompletionSummary: GoalCompletionSummary;
  readonly dailyGoalTracker: ReadonlyArray<DailyGoalTrackerGoal>;
};

export type AthleteOffsetConnection = {
  readonly edges: ReadonlyArray<Athlete>;
  readonly pageInfo: OffsetPageInfo;
};

export type AthleteSelfOnboardedNotification = Notification & {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
};

export type AthleteTag = Tag & {
  readonly id: Scalars['ID'];
  readonly tagName: Maybe<TagName>;
  readonly athleteCount: Scalars['Int'];
  readonly isComplement: Scalars['Boolean'];
  readonly createdBy: Maybe<User>;
  readonly athletes: ReadonlyArray<Athlete>;
};

export type AthleteTransfer = {
  readonly note: Scalars['String'];
  readonly id: Scalars['ID'];
  readonly createdAt: Scalars['DateTime'];
  readonly athlete: Athlete;
  readonly releasedByOrg: Org;
  readonly receivedByOrg: Maybe<Org>;
  readonly orgGroup: OrgGroup;
};

export type AuthInput = {
  readonly allow: ReadonlyArray<Role>;
  readonly allowStaff: Maybe<Scalars['Boolean']>;
};

export type BonAppetitCafe = {
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
};

export type BonAppetitMenuIdentity = MealMenuIdentity & {
  readonly id: Scalars['ID'];
  readonly isOverridden: Scalars['Boolean'];
};

export type BonAppetitMenuInput = {
  readonly date: Scalars['Date'];
  readonly mealId: Scalars['String'];
};

export type BonAppetitMenusPreview = {
  readonly cafeId: Scalars['String'];
  readonly name: Scalars['String'];
  readonly days: ReadonlyArray<BonAppetitMenusPreviewDay>;
};

export type BonAppetitMenusPreviewDay = {
  readonly date: Scalars['String'];
  readonly dayParts: ReadonlyArray<BonAppetitMenusPreviewDayPart>;
};

export type BonAppetitMenusPreviewDayPart = {
  readonly id: Scalars['String'];
  readonly label: Scalars['String'];
  readonly startTime: Scalars['String'];
  readonly endTime: Scalars['String'];
};

export type Brand = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly usdaManufacturerName: Maybe<Scalars['String']>;
  readonly foodCount: Scalars['Int'];
};

export type BrandInputForm = {
  readonly name: Scalars['String'];
  readonly usdaManufacturerName: Maybe<Scalars['String']>;
};

export type BrandedFood = Food & {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly choPer100g: Scalars['Float'];
  readonly proPer100g: Scalars['Float'];
  readonly fatPer100g: Scalars['Float'];
  readonly kcalPer100g: Scalars['Float'];
  readonly exchangeTypes: Maybe<ReadonlyArray<ExchangeType>>;
  readonly nixItemId: Maybe<Scalars['String']>;
  readonly nixTagId: Maybe<Scalars['String']>;
  readonly usdaFdcId: Maybe<Scalars['Int']>;
  readonly usdaFdcDescription: Maybe<Scalars['String']>;
  readonly usdaFdcFoodCategoryId: Maybe<Scalars['Int']>;
  readonly usdaFdcDataType: Maybe<Scalars['String']>;
  readonly isRecipeIngredientOnly: Scalars['Boolean'];
  readonly excludeFromSuggestions: Scalars['Boolean'];
  readonly thumbnailUrl: Maybe<Scalars['String']>;
  readonly hasFullAccess: Scalars['Boolean'];
  readonly org: Maybe<Org>;
  readonly groceryListCategory: Maybe<GroceryListCategory>;
  readonly servings: ReadonlyArray<Serving>;
  readonly defaultServing: Serving;
  readonly foodGroups: ReadonlyArray<FoodGroup>;
  readonly source: FoodDataSource;
  readonly locales: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly nutrientAmounts: ReadonlyArray<NutrientAmount>;
  /** @deprecated Field no longer supported */
  readonly genericFood: Maybe<GenericFood>;
  /** @deprecated Field no longer supported */
  readonly genericFoodId: Maybe<Scalars['String']>;
  readonly brand: Maybe<Brand>;
  readonly createdById: Maybe<Scalars['ID']>;
  readonly createdBy: Maybe<User>;
  readonly updatedAt: Scalars['DateTime'];
  readonly showGramServing: Scalars['Boolean'];
  readonly showOunceServing: Scalars['Boolean'];
};

export type BrandedFoodCursorConnection = {
  readonly edges: ReadonlyArray<BrandedFood>;
  readonly pageInfo: CursorPageInfo;
};

export type BrandedFoodFormInput = {
  readonly name: Scalars['String'];
  readonly choPer100g: Scalars['Float'];
  readonly proPer100g: Scalars['Float'];
  readonly fatPer100g: Scalars['Float'];
  readonly exchangeTypes: ReadonlyArray<ExchangeType>;
  readonly isRecipeIngredientOnly: Scalars['Boolean'];
  readonly excludeFromSuggestions: Scalars['Boolean'];
  readonly thumbnailUrl: Maybe<Scalars['String']>;
  readonly usdaFdcId: Maybe<Scalars['Int']>;
  readonly usdaFdcDescription: Maybe<Scalars['String']>;
  readonly usdaFdcFoodCategoryId: Maybe<Scalars['Int']>;
  readonly usdaFdcDataType: Maybe<Scalars['String']>;
  readonly groceryListCategoryId: Maybe<Scalars['String']>;
  readonly brandId: Maybe<Scalars['String']>;
  readonly source: FoodDataSource;
  readonly locales: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly foodGroupIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly mccanceId: Maybe<Scalars['String']>;
  readonly mccanceName: Maybe<Scalars['String']>;
};

export type BrandedFoodOffsetConnection = {
  readonly edges: ReadonlyArray<BrandedFood>;
  readonly pageInfo: OffsetPageInfo;
};

export type BulkCopyMealPlanInput = {
  readonly athleteIds: ReadonlyArray<Scalars['ID']>;
  readonly mealPlanId: Scalars['ID'];
  readonly mealPlan: MealPlanEditFormInput;
  readonly copyFoodPreferences: Scalars['Boolean'];
  readonly copyPickListServingIds: Scalars['Boolean'];
};

export type BulkCopyMealPlanNewInput = {
  readonly athleteIds: ReadonlyArray<Scalars['ID']>;
  readonly mealPlanId: Scalars['ID'];
  readonly dateAssignment: OverrideMealPlanDateAssignmentInput;
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly copyFoodPreferences: Scalars['Boolean'];
  readonly copyPickListServingIds: Scalars['Boolean'];
  readonly isShared: Scalars['Boolean'];
  readonly isAutoSuggestionsEnabled: Scalars['Boolean'];
};

export type BulkCopyMealPlanPayload = {
  readonly mealPlans: ReadonlyArray<MealPlan>;
};

export type BulkDeleteMealMenusInput = {
  readonly mealMenuIds: ReadonlyArray<Scalars['ID']>;
};

export type BulkDeleteMealMenusPayload = {
  readonly mealMenuIds: ReadonlyArray<Scalars['ID']>;
};

export type BulkEditMealMenusInput = {
  readonly mealMenuIds: ReadonlyArray<Scalars['ID']>;
  readonly hasTimingEdit: Maybe<Scalars['Boolean']>;
  readonly hasNotificationEdit: Maybe<Scalars['Boolean']>;
  readonly hasSharingEdit: Maybe<Scalars['Boolean']>;
  readonly teamIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly advancedSelectionInput: Maybe<AdvancedSelectionInput>;
  readonly notificationSentBeforeOrderDueInMinutes: Maybe<Scalars['Int']>;
  readonly startTime: Maybe<Scalars['Time']>;
  readonly timezone: Maybe<Scalars['Timezone']>;
  readonly durationInMinutes: Maybe<Scalars['Int']>;
  readonly lastOrderTimeBeforeEndInMinutes: Maybe<Scalars['Int']>;
  readonly prepTimeInMinutes: Maybe<Scalars['Int']>;
};

export type BulkEditMealMenusPayload = {
  readonly mealMenus: ReadonlyArray<StandaloneMealMenu>;
};

export type BulkImportCreateAndDeprecateGenericFoodInput = {
  readonly id: Scalars['ID'];
  readonly food: GenericFoodFormInput;
  readonly servings: ReadonlyArray<FoodServingInput>;
  readonly nutrientAmounts: ReadonlyArray<FoodNutrientAmountFormInput>;
  readonly nutrientAmountsManualEntry: Maybe<ReadonlyArray<CreateFoodNutrientAmountFormInput>>;
};

export type BulkImportCreateAndDeprecateGenericFoodsInput = {
  readonly foods: ReadonlyArray<BulkImportCreateAndDeprecateGenericFoodInput>;
};

export type BulkImportCreateAndDeprecateGenericFoodsPayload = {
  readonly genericFoods: ReadonlyArray<GenericFood>;
};

export type BulkImportCreateGenericFoodsInput = {
  readonly foods: ReadonlyArray<CreateGenericFoodInput>;
};

export type BulkImportCreateGenericFoodsPayload = {
  readonly genericFoods: ReadonlyArray<GenericFood>;
};

export type BulkImportCreateRestautantBrandedFoodsInput = {
  readonly foods: ReadonlyArray<CreateRestaurantBrandedFoodInput>;
};

export type BulkImportCreateRestautantBrandedFoodsPayload = {
  readonly brandedFoods: ReadonlyArray<BrandedFood>;
};

export type BulkImportEditRestautantBrandedFoodsInput = {
  readonly foods: ReadonlyArray<EditRestaurantBrandedFoodInput>;
};

export type BulkImportEditRestautantBrandedFoodsPayload = {
  readonly brandedFoods: ReadonlyArray<BrandedFood>;
};

export type BulkOrder = {
  readonly id: Scalars['ID'];
  readonly createdBy: User;
  readonly createdAt: Scalars['DateTime'];
  readonly pickupTime: Scalars['DateTime'];
  readonly deliveryLocation: Scalars['String'];
  readonly team: Team;
  readonly code: Scalars['String'];
  readonly mealMenu: MealMenu;
  readonly childMealMenuPlates: ReadonlyArray<MealMenuPlate>;
  readonly mealMenuPlateOrders: ReadonlyArray<MealMenuPlateOrder>;
  readonly updateCount: Scalars['Int'];
  readonly updatedAt: Scalars['DateTime'];
  readonly status: MenuOrderItemStatus;
};

export type BulkOrderPublication = {
  readonly scope: BulkOrderPublisherScope;
  readonly payload: BulkOrderPublicationPayload;
};

export type BulkOrderPublicationPayload = AddBulkOrderPayload | EditBulkOrderPayload | RemoveBulkOrderPayload | { readonly __typename?: "%other" };

export type BulkOrderPublisherScope = {
  readonly mealMenuId: Scalars['ID'];
};

export type BulkOrderStatusNotification = Notification & {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly entityId: Scalars['ID'];
  readonly bulkOrder: BulkOrder;
};

export type BulkOrderStatusPublication = {
  readonly scope: BulkOrderPublisherScope;
  readonly payload: UpdateBulkOrderStatusPayload;
};

export type BulkOrderSubscriberScope = {
  readonly mealMenuIds: Maybe<ReadonlyArray<Scalars['ID']>>;
};

export type CalorieBudget = {
  readonly id: Scalars['ID'];
  readonly rmrMethod: Maybe<RmrMethod>;
  readonly rmrCalories: Maybe<Scalars['Int']>;
  readonly activityFactor: Scalars['Float'];
  readonly kcalOffset: Scalars['Int'];
  readonly goalSnapshot: Goal;
};

export type CalorieBudgetInput = {
  readonly rmrMethod: Maybe<RmrMethod>;
  readonly rmrCalories: Maybe<Scalars['Int']>;
  readonly activityFactor: Scalars['Float'];
  readonly kcalOffset: Scalars['Int'];
};

export type CbordBusinessUnit = {
  readonly oid: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type CbordFacility = CbordBusinessUnit & {
  readonly oid: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly businessUnits: Maybe<ReadonlyArray<CbordBusinessUnit>>;
};

export type CbordMenuGroupInput = {
  readonly serviceMenuOids: ReadonlyArray<Scalars['Int']>;
  readonly baseServiceMenuOid: Maybe<Scalars['Int']>;
};

export type CbordMenuIdentity = MealMenuIdentity & {
  readonly id: Scalars['ID'];
  readonly isOverridden: Scalars['Boolean'];
};

export type CbordMenuItem = {
  readonly itemOid: Scalars['Int'];
  readonly serviceName: Scalars['String'];
  readonly servedUofmName: Scalars['String'];
  readonly serviceStationOid: Maybe<Scalars['Int']>;
  readonly serviceStationSort: Maybe<Scalars['String']>;
  readonly serviceStationName: Maybe<Scalars['String']>;
};

export type CbordServiceMenu = {
  readonly oid: Scalars['Int'];
  readonly serviceDate: Scalars['DateTime'];
  readonly meal: Scalars['String'];
  readonly menuItems: ReadonlyArray<CbordMenuItem>;
};

export type CbordServiceUnit = CbordBusinessUnit & {
  readonly oid: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly facilityOid: Maybe<Scalars['Int']>;
};

export type CheckGroceryListCustomItemInput = {
  readonly id: Scalars['ID'];
};

export type CheckGroceryListCustomItemPayload = {
  readonly id: Scalars['ID'];
};

export type CheckGroceryListFoodItemInput = {
  readonly id: Scalars['ID'];
};

export type CheckGroceryListFoodItemPayload = {
  readonly id: Scalars['ID'];
};

export type ClientCredential = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly clientId: Scalars['String'];
  readonly isActive: Scalars['Boolean'];
};

export type ClientCredentialOffsetConnection = {
  readonly edges: ReadonlyArray<ClientCredential>;
  readonly pageInfo: OffsetPageInfo;
};

export type ComboTag = Tag & {
  readonly id: Scalars['ID'];
  readonly tagName: Maybe<TagName>;
  readonly athleteCount: Scalars['Int'];
  readonly isComplement: Scalars['Boolean'];
  readonly createdBy: Maybe<User>;
  readonly combinationMethod: TagCombinationMethod;
  readonly childTags: ReadonlyArray<Tag>;
  readonly namedChildTags: ReadonlyArray<NamedTag>;
};

export type Comment = {
  readonly id: Scalars['ID'];
  readonly text: Scalars['String'];
  readonly by: User;
  readonly at: Scalars['DateTime'];
  readonly notification: Maybe<Notification>;
};

export type CommentInput = {
  readonly text: Scalars['String'];
};

export type CompassMenuIdentity = MealMenuIdentity & {
  readonly id: Scalars['ID'];
  readonly isOverridden: Scalars['Boolean'];
};

export type CompassMenuInput = {
  readonly date: Scalars['Date'];
  readonly mealPeriodId: Scalars['Int'];
};

export type CompassLocation = {
  readonly locationId: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly mealPeriods: ReadonlyArray<CompassMealPeriod>;
};

export type CompassMealPeriod = {
  readonly displayOrder: Scalars['Int'];
  readonly mealPeriodId: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly stations: ReadonlyArray<CompassStation>;
};

export type CompassMenuItem = {
  readonly id: Scalars['Int'];
  readonly date: Scalars['String'];
  readonly displayOrder: Scalars['Int'];
  readonly stationId: Scalars['Int'];
  readonly mealPeriodId: Scalars['Int'];
  readonly locationId: Scalars['Int'];
  readonly portion: Scalars['String'];
  readonly name: Scalars['String'];
};

export type CompassRecipe = {
  readonly name: Scalars['String'];
  readonly shortName: Scalars['String'];
  readonly masterRefNumber: Scalars['String'];
};

export type CompassStation = {
  readonly displayOrder: Scalars['Int'];
  readonly stationId: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type ConvertMenuItemInput = {
  readonly menuItemId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly servingName: Scalars['String'];
  readonly isOneOff: Scalars['Boolean'];
  readonly description: Maybe<Scalars['String']>;
  readonly appearance: MenuItemAppearanceInput;
  readonly suggestionCategory: Maybe<MenuItemSuggestionCategory>;
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
  readonly addChoices: ReadonlyArray<AddMenuItemChoiceInput>;
  readonly editChoices: ReadonlyArray<EditMenuItemChoiceInput>;
  readonly removeChoices: ReadonlyArray<RemoveMenuItemChoiceInput>;
  readonly imageUrl: Maybe<Scalars['String']>;
  readonly scoreValue: Maybe<Scalars['Float']>;
  readonly foodCategory: Maybe<FoodCategoryType>;
};

export type ConvertedServingInput = {
  readonly servingId: Scalars['ID'];
  readonly toUnitId: Scalars['ID'];
};

export type CopyExchangeSetInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type CopyExchangeSetPayload = {
  readonly exchangeSet: ExchangeSet;
};

export type CopyMacroMealPlanTemplateInput = {
  readonly macroMealPlanTemplateId: Scalars['ID'];
};

export type CopyMacroMealPlanTemplatePayload = {
  readonly macroMealPlanTemplate: MacroMealPlanTemplate;
};

export type CopyMealMenuInput = {
  readonly mealMenuId: Scalars['ID'];
  readonly newStartDay: Scalars['Date'];
};

export type CopyMealMenusInput = {
  readonly mealMenuInputs: ReadonlyArray<CopyMealMenuInput>;
};

export type CopyMealMenusPayload = {
  readonly newMealMenus: ReadonlyArray<MealMenu>;
};

export type CopyMealPlanInput = {
  readonly athleteId: Scalars['ID'];
  readonly mealPlanId: Scalars['ID'];
  readonly mealPlan: MealPlanEditFormInput;
  readonly copyFoodPreferences: Scalars['Boolean'];
  readonly copyPickListServingIds: Scalars['Boolean'];
};

export type CopyMealPlanNewInput = {
  readonly athleteId: Scalars['ID'];
  readonly mealPlanId: Scalars['ID'];
  readonly dateAssignment: OverrideMealPlanDateAssignmentInput;
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly copyFoodPreferences: Scalars['Boolean'];
  readonly copyPickListServingIds: Scalars['Boolean'];
  readonly isShared: Scalars['Boolean'];
  readonly isAutoSuggestionsEnabled: Scalars['Boolean'];
};

export type CopyMealPlanPayload = {
  readonly mealPlan: MealPlan;
};

export type CopyPrepPlanInput = {
  readonly newStart: Scalars['Date'];
  readonly newEnd: Scalars['Date'];
  readonly prepPlanId: Scalars['ID'];
};

export type CopyPrepPlanPayload = {
  readonly prepPlan: PrepPlan;
};

export type CreateAnthropometryEntriesPayload = {
  readonly anthropometryEntries: ReadonlyArray<AnthropometryEntry>;
};

export type CreateAnthropometryEntryInput = {
  readonly athleteId: Scalars['ID'];
  readonly anthropometryEntry: AnthropometryEntryFormInput;
};

export type CreateAnthropometryEntryPayload = {
  readonly anthropometryEntry: AnthropometryEntry;
};

export type CreateAthleteInput = {
  readonly teamId: Scalars['ID'];
  readonly athlete: AthleteFormInput;
  readonly goal: Maybe<GoalFormInput>;
  readonly anthropometryEntries: Maybe<ReadonlyArray<AnthropometryEntryFormInput>>;
  readonly accountVerificationMethod: Maybe<AccountVerificationMethod>;
  readonly isNotemealOnly: Scalars['Boolean'];
};

export type CreateAthletePayload = {
  readonly athlete: Athlete;
};

export type CreateAthleteTransferInput = {
  readonly athleteId: Scalars['ID'];
  readonly orgGroupId: Scalars['ID'];
  readonly note: Scalars['String'];
};

export type CreateAthleteTransferPayload = {
  readonly athleteTransfer: AthleteTransfer;
};

export type CreateAthletesPayload = {
  readonly athletes: ReadonlyArray<Athlete>;
};

export type CreateBrandInput = {
  readonly name: Scalars['String'];
  readonly usdaManufacturerName: Maybe<Scalars['String']>;
};

export type CreateBrandPayload = {
  readonly brand: Brand;
};

export type CreateBrandedFoodInput = {
  readonly food: BrandedFoodFormInput;
  readonly servings: ReadonlyArray<FoodServingInput>;
  readonly nutrientAmounts: ReadonlyArray<FoodNutrientAmountFormInput>;
  readonly nutrientAmountsManualEntry: Maybe<ReadonlyArray<CreateFoodNutrientAmountFormInput>>;
};

export type CreateBrandedFoodPayload = {
  readonly brandedFood: BrandedFood;
};

export type CreateClientCredentialInput = {
  readonly name: Scalars['String'];
};

export type CreateClientCredentialPayload = {
  readonly clientCredential: ClientCredential;
  readonly clientSecret: Scalars['String'];
};

export type CreateCuisineInput = {
  readonly name: Scalars['String'];
};

export type CreateCuisinePayload = {
  readonly cuisine: Cuisine;
};

export type CreateCustomTagInput = {
  readonly name: Scalars['String'];
  readonly description: Scalars['String'];
  readonly athleteIds: ReadonlyArray<Scalars['ID']>;
  readonly isPrintable: Maybe<Scalars['Boolean']>;
};

export type CreateDietInput = {
  readonly name: Scalars['String'];
};

export type CreateDietPayload = {
  readonly diet: Diet;
};

export type CreateDiningStationTemplateInput = {
  readonly name: Scalars['String'];
  readonly mealTypes: ReadonlyArray<MealType>;
  readonly createMenuItems: ReadonlyArray<CreateMenuItemInput>;
  readonly addMenuItems: ReadonlyArray<AddMenuItemInput>;
  readonly editMenuItems: ReadonlyArray<EditMenuItemInput>;
  readonly defaultMaxAmount: Maybe<Scalars['Int']>;
};

export type CreateDiningStationTemplatePayload = {
  readonly diningStationTemplate: DiningStationTemplate;
};

export type CreateExchangeInput = {
  readonly exchange: ExchangeInput;
  readonly exchangeServingList: ExchangeServingListInput;
};

export type CreateExchangeSetInput = {
  readonly name: Scalars['String'];
  readonly exchanges: ReadonlyArray<CreateExchangeInput>;
};

export type CreateExchangeSetPayload = {
  readonly exchangeSet: ExchangeSet;
};

export type CreateFoodGroupInput = {
  readonly name: Scalars['String'];
  readonly source: Maybe<Scalars['String']>;
  readonly foodIds: ReadonlyArray<Scalars['ID']>;
};

export type CreateFoodGroupPayload = {
  readonly foodGroup: FoodGroup;
};

export type CreateFoodNutrientAmountFormInput = {
  readonly nutrientName: Scalars['String'];
  readonly amount: Scalars['Float'];
};

export type CreateFoodServingsInput = {
  readonly foodId: Scalars['ID'];
  readonly servings: ReadonlyArray<FoodServingInput>;
};

export type CreateFoodServingsPayload = {
  readonly servings: ReadonlyArray<Serving>;
};

export type CreateGenericFoodInput = {
  readonly food: GenericFoodFormInput;
  readonly servings: ReadonlyArray<FoodServingInput>;
  readonly nutrientAmounts: ReadonlyArray<FoodNutrientAmountFormInput>;
  readonly nutrientAmountsManualEntry: Maybe<ReadonlyArray<CreateFoodNutrientAmountFormInput>>;
};

export type CreateGenericFoodPayload = {
  readonly genericFood: GenericFood;
};

export type CreateGoalConfigurationBaseInput = {
  readonly athleteId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly emoji: Scalars['String'];
  readonly categoryId: Scalars['ID'];
  readonly timezone: Scalars['String'];
  readonly reminders: ReadonlyArray<SetGoalReminderConfigurationInput>;
  readonly target: Maybe<CreateGoalQuantityInput>;
};

export type CreateGoalConfigurationPayload = {
  readonly goalConfiguration: GoalConfiguration;
};

export type CreateGoalConfigurationWithDaysOfWeekInput = {
  readonly input: CreateGoalConfigurationBaseInput;
  readonly startDate: Scalars['Date'];
  readonly endDate: Scalars['Date'];
  readonly dayOfWeekAssignments: ReadonlyArray<DayOfWeek>;
};

export type CreateGoalConfigurationWithIndividualDatesInput = {
  readonly input: CreateGoalConfigurationBaseInput;
  readonly individualDates: ReadonlyArray<Scalars['Date']>;
};

export type CreateGoalInput = {
  readonly athleteId: Scalars['ID'];
  readonly goal: GoalFormInput;
};

export type CreateGoalPayload = {
  readonly goal: Goal;
};

export type CreateGoalQuantityInput = {
  readonly value: Scalars['Int'];
  readonly unit: Scalars['String'];
};

export type CreateGroceryListCustomItemInput = {
  readonly name: Scalars['String'];
  readonly groceryListId: Scalars['String'];
};

export type CreateGroceryListCustomItemPayload = {
  readonly groceryListId: Scalars['String'];
};

export type CreateMacroMealPlanTemplateInput = {
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly additionalState: Maybe<Scalars['String']>;
  readonly macroProtocol: MealPlanTemplateMacroProtocolInput;
  readonly meals: ReadonlyArray<MacroMealPlanTemplateMealInput>;
  readonly foodPreferences: FoodPreferenceInput;
  readonly activities: ReadonlyArray<MealPlanTemplateActivityInput>;
};

export type CreateMacroMealPlanTemplatePayload = {
  readonly macroMealPlanTemplate: MacroMealPlanTemplate;
};

export type CreateMealMenusInput = {
  readonly starts: ReadonlyArray<Scalars['DateTime']>;
  readonly timezone: Scalars['Timezone'];
  readonly teamIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly advancedSelectionInput: Maybe<AdvancedSelectionInput>;
  readonly name: Scalars['String'];
  readonly type: MealType;
  readonly durationInMinutes: Scalars['Int'];
  readonly prepTimeInMinutes: Scalars['Int'];
  readonly lastOrderTimeBeforeEndInMinutes: Scalars['Int'];
  readonly notificationSentBeforeOrderDueInMinutes: Maybe<Scalars['Int']>;
  readonly orderRateLimit: Maybe<MenuOrderRateLimitInput>;
  readonly diningStations: ReadonlyArray<AddMealMenuDiningStationInput>;
  readonly restaurantMenuLinks: ReadonlyArray<RestaurantMenuLinkInput>;
  readonly userOrderLimit: Maybe<MealMenuUserOrderLimitInput>;
  readonly isHubCheckInEnabled: Maybe<Scalars['Boolean']>;
  readonly isOrderAndLogRestricted: Maybe<Scalars['Boolean']>;
  readonly theme: Maybe<Scalars['String']>;
};

export type CreateMealMenusPayload = {
  readonly mealMenus: ReadonlyArray<StandaloneMealMenu>;
};

export type CreateMealPlanCalendarInput = {
  readonly scheduleId: Scalars['ID'];
  readonly isAutoSuggestionsEnabled: Scalars['Boolean'];
  readonly individualDates: Maybe<ReadonlyArray<Scalars['Date']>>;
  readonly startDate: Maybe<Scalars['Date']>;
  readonly endDate: Maybe<Scalars['Date']>;
  readonly removeConflictingIndividualDates: Maybe<ReadonlyArray<Scalars['Date']>>;
  readonly dayOfWeekPriorities: Maybe<ReadonlyArray<DayOfWeekPriorityInput>>;
  readonly mealTemplates: ReadonlyArray<AddMealTemplateCalendarInput>;
  readonly activityTemplates: ReadonlyArray<AddActivityTemplateCalendarInput>;
};

export type CreateMealPlanFromFoodLogsNewInput = {
  readonly athleteId: Scalars['ID'];
  readonly foodLogMealTemplates: ReadonlyArray<FoodLogMealTemplateInput>;
  readonly foodLogActivityTemplates: ReadonlyArray<AddActivityInput>;
  readonly dateAssignment: OverrideMealPlanDateAssignmentInput;
  readonly type: MealPlanType;
  readonly exchangeSetId: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly isShared: Scalars['Boolean'];
  readonly isAutoSuggestionsEnabled: Scalars['Boolean'];
  readonly macroProtocol: MacroProtocolNewInput;
  readonly foodPreferences: FoodPreferenceInput;
};

export type CreateMealPlanFromFoodLogsPayload = {
  readonly mealPlan: MealPlan;
};

export type CreateMealPlanFromFoodLogsWithNewGoalPayload = {
  readonly mealPlan: MealPlan;
};

export type CreateMealPlanFromFoodLogsWithOldGoalPayload = {
  readonly mealPlan: MealPlan;
};

export type CreateMealPlanFromMealPlanTemplateInput = {
  readonly mealPlanTemplateId: Scalars['ID'];
  readonly anthropometry: MealPlanFromTemplateAnthropometryInput;
  readonly foodPreferences: Maybe<FoodPreferenceInput>;
  readonly mealPlanTemplateGoalId: Maybe<Scalars['ID']>;
  readonly birthDate: Maybe<Scalars['Date']>;
};

export type CreateMealPlanFromMealPlanTemplatePayload = {
  readonly mealPlan: MealPlan;
};

export type CreateMealPlanNewInput = {
  readonly athleteId: Scalars['ID'];
  readonly calendar: CreateMealPlanCalendarInput;
  readonly type: MealPlanType;
  readonly exchangeSetId: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly isShared: Scalars['Boolean'];
  readonly isAutoSuggestionsEnabled: Scalars['Boolean'];
  readonly macroProtocol: MacroProtocolNewInput;
  readonly foodPreferences: FoodPreferenceInput;
};

export type CreateMealPlanPayload = {
  readonly mealPlan: MealPlan;
};

export type CreateMealPlanWithNewGoalPayload = {
  readonly mealPlan: MealPlan;
};

export type CreateMealPlanWithOldGoalPayload = {
  readonly mealPlan: MealPlan;
};

export type CreateMenuItemInput = {
  readonly isOneOff: Scalars['Boolean'];
  readonly name: Scalars['String'];
  readonly servingName: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly appearance: MenuItemAppearanceInput;
  readonly suggestionCategory: Maybe<MenuItemSuggestionCategory>;
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
  readonly choices: ReadonlyArray<AddMenuItemChoiceInput>;
  readonly imageUrl: Maybe<Scalars['String']>;
  readonly scoreValue: Maybe<Scalars['Float']>;
  readonly foodCategory: Maybe<FoodCategoryType>;
};

export type CreateMetricAnthropometryEntryInput = {
  readonly athleteId: Scalars['ID'];
  readonly anthropometryEntry: MetricAnthropometryEntryFormInput;
};

export type CreateMetricAthleteInput = {
  readonly teamId: Scalars['ID'];
  readonly athlete: AthleteFormInput;
  readonly goal: Maybe<GoalFormInput>;
  readonly anthropometryEntries: Maybe<ReadonlyArray<MetricAnthropometryEntryFormInput>>;
  readonly accountVerificationMethod: Maybe<AccountVerificationMethod>;
  readonly isNotemealOnly: Scalars['Boolean'];
};

export type CreateMetricMealPlanFromFoodLogsInput = {
  readonly athleteId: Scalars['ID'];
  readonly foodLogMealTemplates: ReadonlyArray<FoodLogMealTemplateInput>;
  readonly foodLogActivityTemplates: ReadonlyArray<AddActivityInput>;
  readonly dateAssignment: OverrideMealPlanDateAssignmentInput;
  readonly type: MealPlanType;
  readonly exchangeSetId: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly isShared: Scalars['Boolean'];
  readonly isAutoSuggestionsEnabled: Scalars['Boolean'];
  readonly macroProtocol: MetricMacroProtocolInput;
  readonly foodPreferences: FoodPreferenceInput;
};

export type CreateMetricMealPlanFromMealPlanTemplateInput = {
  readonly mealPlanTemplateId: Scalars['ID'];
  readonly anthropometry: MealPlanFromTemplateMetricAnthropometryInput;
  readonly foodPreferences: Maybe<FoodPreferenceInput>;
  readonly mealPlanTemplateGoalId: Maybe<Scalars['ID']>;
  readonly birthDate: Maybe<Scalars['Date']>;
};

export type CreateMetricMealPlanInput = {
  readonly athleteId: Scalars['ID'];
  readonly calendar: CreateMealPlanCalendarInput;
  readonly type: MealPlanType;
  readonly exchangeSetId: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly isShared: Scalars['Boolean'];
  readonly isAutoSuggestionsEnabled: Scalars['Boolean'];
  readonly macroProtocol: MetricMacroProtocolInput;
  readonly foodPreferences: FoodPreferenceInput;
};

export type CreateNoteInput = {
  readonly athleteId: Scalars['ID'];
  readonly note: NoteFormInput;
};

export type CreateNotePayload = {
  readonly note: Note;
};

export type CreateNoteTypeInput = {
  readonly name: Scalars['String'];
};

export type CreateNoteTypePayload = {
  readonly noteType: NoteType;
};

export type CreateOrgFromTeamworksOrgInput = {
  readonly teamworksId: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type CreateOrgFromTeamworksOrgPayload = {
  readonly org: Org;
};

export type CreateOrgGroupInput = {
  readonly name: Scalars['String'];
  readonly allowsResourceSharing: Scalars['Boolean'];
  readonly allowsAthleteTransfer: Scalars['Boolean'];
  readonly parentOrgGroupId: Maybe<Scalars['ID']>;
  readonly orgIds: ReadonlyArray<Scalars['ID']>;
};

export type CreateOrgGroupPayload = {
  readonly orgGroup: OrgGroup;
};

export type CreateOrgInput = {
  readonly name: Scalars['String'];
  readonly isActive: Scalars['Boolean'];
  readonly allowsPublicAccess: Scalars['Boolean'];
  readonly memberships: ReadonlyArray<CreateOrgMembershipAsStaffInput>;
  readonly localeCode: Scalars['String'];
  readonly territoryName: Maybe<Scalars['String']>;
};

export type CreateOrgMembershipAsStaffInput = {
  readonly method: AccountVerificationMethod;
  readonly phoneNumber: Maybe<Scalars['String']>;
  readonly email: Maybe<Scalars['String']>;
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly teamName: Scalars['String'];
  readonly roles: ReadonlyArray<Role>;
  readonly isAdmin: Scalars['Boolean'];
  readonly isNotemealOnly: Scalars['Boolean'];
};

export type CreateOrgMembershipForAthleteInput = {
  readonly athlete: CreateOrgMembershipForAthleteObjectInput;
};

export type CreateOrgMembershipForAthleteObjectInput = {
  readonly athleteId: Scalars['ID'];
  readonly teamName: Scalars['String'];
  readonly method: AccountVerificationMethod;
  readonly roles: ReadonlyArray<Role>;
};

export type CreateOrgMembershipForAthletePayload = {
  readonly athlete: Athlete;
  readonly generatedSelfServiceActivationLink: Maybe<Scalars['String']>;
};

export type CreateOrgMembershipInput = {
  readonly method: AccountVerificationMethod;
  readonly phoneNumber: Maybe<Scalars['String']>;
  readonly email: Maybe<Scalars['String']>;
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly teamName: Scalars['String'];
  readonly roles: ReadonlyArray<Role>;
};

export type CreateOrgMembershipsAsStaffInput = {
  readonly memberships: ReadonlyArray<CreateOrgMembershipAsStaffInput>;
  readonly orgId: Scalars['ID'];
};

export type CreateOrgMembershipsInput = {
  readonly memberships: ReadonlyArray<CreateOrgMembershipInput>;
};

export type CreateOrgMembershipsPayload = {
  readonly memberships: ReadonlyArray<OrgMembership>;
};

export type CreateOrgPayload = {
  readonly org: Org;
};

export type CreatePaymentMethodInput = {
  readonly name: Scalars['String'];
};

export type CreatePaymentMethodPayload = {
  readonly paymentMethod: PaymentMethod;
};

export type CreatePlannedMenuInput = {
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly occurrence: PlannedMenuOccurrence;
  readonly timezone: Scalars['String'];
  readonly startDate: Scalars['Date'];
  readonly endDate: Scalars['Date'];
};

export type CreatePlannedMenuPayload = {
  readonly plannedMenu: PlannedMenu;
};

export type CreatePrepPlanInput = {
  readonly start: Scalars['Date'];
  readonly end: Scalars['Date'];
};

export type CreatePrepPlanMealInput = {
  readonly prepPlanId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly priority: Scalars['Int'];
  readonly mealTypes: Maybe<ReadonlyArray<MealType>>;
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
  readonly recipeId: Maybe<Scalars['ID']>;
  readonly mealOptionId: Maybe<Scalars['ID']>;
  readonly edited: Maybe<Scalars['Boolean']>;
  readonly prepPlanMealAssignments: Maybe<ReadonlyArray<PrepPlanMealAssignmentInput>>;
  readonly prepPlanMealSlotAssignments: Maybe<ReadonlyArray<PrepPlanMealSlotAssignmentInput>>;
};

export type CreatePrepPlanMealPayload = {
  readonly prepPlanMeal: PrepPlanMeal;
};

export type CreatePrepPlanPayload = {
  readonly prepPlan: PrepPlan;
};

export type CreateRecipeFullInput = {
  readonly recipe: RecipeFullInput;
  readonly servings: ReadonlyArray<RecipeServingFullInput>;
  readonly copiedFromId: Maybe<Scalars['ID']>;
};

export type CreateRecipeFullPayload = {
  readonly recipe: Recipe;
};

export type CreateRecipeSimpleInput = {
  readonly recipe: RecipeSimpleInput;
  readonly serving: RecipeServingSimpleInput;
};

export type CreateRecipeSimplePayload = {
  readonly recipe: Recipe;
};

export type CreateRestaurantBrandedFoodInput = {
  readonly food: BrandedFoodFormInput;
  readonly servings: ReadonlyArray<FoodServingInput>;
  readonly nutrientAmounts: ReadonlyArray<FoodNutrientAmountFormInput>;
  readonly nutrientAmountsManualEntry: Maybe<ReadonlyArray<CreateFoodNutrientAmountFormInput>>;
  readonly restaurantId: Scalars['ID'];
};

export type CreateRestaurantBrandedFoodPayload = {
  readonly brandedFood: BrandedFood;
};

export type CreateRestaurantInput = {
  readonly name: Scalars['String'];
  readonly nutritionixId: Maybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
  readonly website: Maybe<Scalars['String']>;
  readonly proteinPortionSizeInOunces: Maybe<Scalars['Int']>;
  readonly firstPartyDelivery: Scalars['Boolean'];
  readonly athleteVisible: Scalars['Boolean'];
  readonly refundPolicy: Maybe<RefundPolicyType>;
  readonly refundDueBeforeInMinutes: Maybe<Scalars['Int']>;
  readonly restaurantTypeId: Maybe<Scalars['ID']>;
  readonly paymentMethodIds: ReadonlyArray<Scalars['ID']>;
  readonly cuisineIds: ReadonlyArray<Scalars['ID']>;
};

export type CreateRestaurantMenuInput = {
  readonly restaurantId: Scalars['ID'];
  readonly sections: ReadonlyArray<AddRestaurantMenuSectionInput>;
  readonly supportedDietIds: ReadonlyArray<Scalars['ID']>;
};

export type CreateRestaurantMenuPayload = {
  readonly restaurantMenu: RestaurantMenu;
};

export type CreateRestaurantPayload = {
  readonly restaurant: Restaurant;
};

export type CreateRestaurantTypeInput = {
  readonly name: Scalars['String'];
};

export type CreateRestaurantTypePayload = {
  readonly restaurantType: RestaurantType;
};

export type CreateReusableMenuItemInput = {
  readonly name: Scalars['String'];
  readonly servingName: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly defaultAvailableForOrder: Scalars['Boolean'];
  readonly defaultMaxAmount: Maybe<Scalars['Int']>;
  readonly suggestionCategory: Maybe<MenuItemSuggestionCategory>;
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
  readonly choices: ReadonlyArray<AddMenuItemChoiceInput>;
  readonly imageUrl: Maybe<Scalars['String']>;
  readonly defaultAllowSpecialRequests: Scalars['Boolean'];
  readonly scoreValue: Maybe<Scalars['Float']>;
  readonly foodCategory: Maybe<FoodCategoryType>;
};

export type CreateReusableMenuItemPayload = {
  readonly menuItem: MenuItem;
};

export type CreateScheduleInput = {
  readonly teamId: Scalars['ID'];
  readonly meals: ReadonlyArray<AddMealInput>;
  readonly activities: ReadonlyArray<AddActivityInput>;
  readonly activityFactor: Maybe<Scalars['Float']>;
  readonly name: Scalars['String'];
};

export type CreateSchedulePayload = {
  readonly schedule: Schedule;
};

export type CreateServingAmountInput = {
  readonly servingId: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly position: Scalars['Int'];
};

export type CreateSimpleBrandedFoodInput = {
  readonly name: Scalars['String'];
  readonly cho: Scalars['Float'];
  readonly pro: Scalars['Float'];
  readonly fat: Scalars['Float'];
  readonly defaultAmount: Scalars['Float'];
  readonly units: ServingUnitsInput;
  readonly weight: Scalars['Float'];
  readonly exchangeTypes: ReadonlyArray<ExchangeType>;
  readonly isRecipeIngredientOnly: Scalars['Boolean'];
  readonly usdaFdcId: Maybe<Scalars['Int']>;
  readonly usdaFdcDescription: Maybe<Scalars['String']>;
  readonly usdaFdcFoodCategoryId: Maybe<Scalars['Int']>;
  readonly usdaFdcDataType: Maybe<Scalars['String']>;
  readonly nutrientAmounts: Maybe<ReadonlyArray<FoodNutrientAmountFormInput>>;
  readonly foodGroupIds: Maybe<ReadonlyArray<Scalars['ID']>>;
};

export type CreateSimpleBrandedFoodPayload = {
  readonly brandedFood: BrandedFood;
};

export type CreateSmartTagInput = {
  readonly name: Scalars['String'];
  readonly description: Scalars['String'];
  readonly condition: AssignTagConditionInput;
  readonly isPrintable: Maybe<Scalars['Boolean']>;
};

export type CreateTeamInput = {
  readonly sportId: Maybe<Scalars['ID']>;
  readonly gender: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
};

export type CreateTeamPayload = {
  readonly team: Team;
};

export type CreateTeamworksPositionInput = {
  readonly teamworksId: Scalars['Int'];
  readonly teamworksTeamId: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type CreateTeamworksSportInput = {
  readonly name: Scalars['String'];
  readonly newPositions: ReadonlyArray<CreateTeamworksPositionInput>;
};

export type CreateTeamworksTeamInput = {
  readonly teamworksId: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly gender: Maybe<Scalars['String']>;
  readonly linkedSport: Maybe<LinkedSportInput>;
  readonly newSport: Maybe<CreateTeamworksSportInput>;
};

export type CreateTerritoryInput = {
  readonly name: Scalars['String'];
  readonly orgIds: ReadonlyArray<Scalars['ID']>;
};

export type CreateUserBrandedFoodFormInput = {
  readonly name: Scalars['String'];
  readonly cho: Scalars['Float'];
  readonly pro: Scalars['Float'];
  readonly fat: Scalars['Float'];
  readonly defaultAmount: Scalars['Float'];
  readonly units: ServingUnitsInput;
  readonly nutrientAmounts: ReadonlyArray<CreateFoodNutrientAmountFormInput>;
};

export type CreateWeightUnitServingForFoodInput = {
  readonly foodId: Scalars['ID'];
  readonly unitId: Scalars['ID'];
};

export type CreateWeightUnitServingForFoodPayload = {
  readonly serving: Serving;
  readonly amount: Scalars['Float'];
};

export type Cuisine = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type CuisineCursorConnection = {
  readonly edges: ReadonlyArray<Cuisine>;
  readonly pageInfo: CursorPageInfo;
};

export type CuisineOffsetConnection = {
  readonly edges: ReadonlyArray<Cuisine>;
  readonly pageInfo: OffsetPageInfo;
};

export type CursorPageInfo = {
  readonly hasNextPage: Scalars['Boolean'];
  readonly endCursor: Scalars['String'];
};

export type CursorPaginationInput = {
  readonly cursor: Maybe<Scalars['String']>;
  readonly limit: Maybe<Scalars['Int']>;
};

export type DailyGoalTrackerGoal = {
  readonly id: Scalars['ID'];
  readonly date: Scalars['DateTime'];
  readonly completed: Scalars['Boolean'];
  readonly completedAtInUTC: Maybe<Scalars['DateTime']>;
  readonly loggedValue: Maybe<Scalars['Int']>;
};



export type DayOfWeek =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday'
  | '%future added value';

export type DayOfWeekPriority = {
  readonly dayOfWeek: DayOfWeek;
  readonly priority: Scalars['Int'];
};

export type DayOfWeekPriorityInput = {
  readonly dayOfWeek: DayOfWeek;
  readonly priority: Scalars['Int'];
};

export type DaySummary = {
  readonly datetime: Scalars['DateTime'];
  readonly targetMacrosForDay: MacrosAndKcals;
  readonly totalMacrosLogged: MacrosAndKcals;
};

export type DeleteAnthropometryEntryInput = {
  readonly id: Scalars['ID'];
};

export type DeleteAnthropometryEntryPayload = {
  readonly anthropometryEntryId: Scalars['ID'];
};

export type DeleteAthleteInput = {
  readonly id: Scalars['ID'];
};

export type DeleteAthletePayload = {
  readonly athleteId: Scalars['ID'];
};

export type DeleteCuisineInput = {
  readonly id: Scalars['ID'];
};

export type DeleteCuisinePayload = {
  readonly id: Scalars['ID'];
};

export type DeleteDietInput = {
  readonly id: Scalars['ID'];
};

export type DeleteDietPayload = {
  readonly id: Scalars['ID'];
};

export type DeleteDiningStationTemplateInput = {
  readonly diningStationTemplateId: Scalars['ID'];
};

export type DeleteDiningStationTemplatePayload = {
  readonly diningStationTemplateId: Scalars['ID'];
};

export type DeleteExchangeSetInput = {
  readonly id: Scalars['ID'];
};

export type DeleteExchangeSetPayload = {
  readonly id: Scalars['ID'];
};

export type DeleteFoodInput = {
  readonly id: Scalars['ID'];
};

export type DeleteFoodPayload = {
  readonly foodId: Scalars['ID'];
};

export type DeleteGoalConfigurationInput = {
  readonly id: Scalars['ID'];
};

export type DeleteGoalConfigurationPayload = {
  readonly goalConfigurationId: Scalars['ID'];
};

export type DeleteGroceryListCustomItemInput = {
  readonly id: Scalars['ID'];
};

export type DeleteGroceryListCustomItemPayload = {
  readonly id: Scalars['ID'];
};

export type DeleteGroceryListFoodItemInput = {
  readonly id: Scalars['ID'];
};

export type DeleteInProgressPrepPlanInput = {
  readonly prepPlanId: Scalars['ID'];
};

export type DeleteInProgressPrepPlanPayload = {
  readonly prepPlanId: Scalars['ID'];
};

export type DeleteMacroMealPlanTemplateInput = {
  readonly macroMealPlanTemplateId: Scalars['ID'];
};

export type DeleteMacroMealPlanTemplatePayload = {
  readonly macroMealPlanTemplateId: Scalars['ID'];
};

export type DeleteMacroMealPlanTemplatePendingStateInput = {
  readonly macroMealPlanTemplateId: Scalars['ID'];
};

export type DeleteMacroMealPlanTemplatePendingStatePayload = {
  readonly macroMealPlanTemplate: MealPlanTemplate;
};

export type DeleteMealMenuInput = {
  readonly mealMenuId: Scalars['ID'];
};

export type DeleteMealMenuPayload = {
  readonly mealMenuId: Scalars['ID'];
};

export type DeleteMealPlanInput = {
  readonly id: Scalars['ID'];
};

export type DeleteMealPlanPayload = {
  readonly mealPlanId: Scalars['ID'];
  readonly athlete: Athlete;
};

export type DeleteMealPlansInput = {
  readonly ids: ReadonlyArray<Scalars['ID']>;
};

export type DeleteMealPlansPayload = {
  readonly mealPlanIds: ReadonlyArray<Scalars['ID']>;
};

export type DeleteNoteInput = {
  readonly id: Scalars['ID'];
};

export type DeleteNotePayload = {
  readonly noteId: Scalars['ID'];
};

export type DeleteNoteTypeInput = {
  readonly id: Scalars['ID'];
};

export type DeleteNoteTypePayload = {
  readonly noteTypeId: Scalars['ID'];
};

export type DeletePaymentMethodInput = {
  readonly id: Scalars['ID'];
};

export type DeletePaymentMethodPayload = {
  readonly id: Scalars['ID'];
};

export type DeletePlannedMenuInput = {
  readonly id: Scalars['ID'];
};

export type DeletePlannedMenuPayload = {
  readonly plannedMenuId: Scalars['ID'];
};

export type DeleteRecipeInput = {
  readonly recipeId: Scalars['ID'];
};

export type DeleteRecipePayload = {
  readonly recipeId: Scalars['ID'];
};

export type DeleteRestaurantTypeInput = {
  readonly id: Scalars['ID'];
};

export type DeleteRestaurantTypePayload = {
  readonly id: Scalars['ID'];
};

export type DeleteReusableMenuItemInput = {
  readonly after: Scalars['DateTime'];
  readonly menuItemId: Scalars['ID'];
};

export type DeleteReusableMenuItemPayload = {
  readonly menuItemId: Scalars['ID'];
};

export type DeleteTagPayload = {
  readonly tagId: Scalars['ID'];
};

export type DeleteTeamInput = {
  readonly teamId: Scalars['ID'];
};

export type DeleteTeamPayload = {
  readonly teamId: Scalars['ID'];
};

export type DeleteUserBrandedFoodInput = {
  readonly id: Scalars['ID'];
};

export type DeleteUserBrandedFoodPayload = {
  readonly id: Scalars['ID'];
};

export type DeliveryLocation = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly deliveryInstructions: Maybe<Scalars['String']>;
  readonly googlePlaceId: Scalars['String'];
  readonly isActive: Scalars['Boolean'];
  readonly cityInfo: Maybe<Scalars['String']>;
  readonly address: Maybe<Address>;
  readonly teamLabels: ReadonlyArray<TeamLabel>;
};

export type DeliveryLocationCursorConnection = {
  readonly edges: ReadonlyArray<DeliveryLocation>;
  readonly pageInfo: CursorPageInfo;
};

export type DeliveryLocationOffsetConnection = {
  readonly edges: ReadonlyArray<DeliveryLocation>;
  readonly pageInfo: OffsetPageInfo;
};

export type DeprecateFoodInput = {
  readonly id: Scalars['ID'];
};

export type DeprecateFoodPayload = {
  readonly foodId: Scalars['ID'];
};

export type DeprecateRestaurantBrandedFoodInput = {
  readonly id: Scalars['ID'];
  readonly restaurantId: Scalars['ID'];
};

export type DeprecateRestaurantBrandedFoodPayload = {
  readonly foodId: Scalars['ID'];
};

export type DeviceType =
  | 'IOS'
  | 'ANDROID'
  | '%future added value';

export type Diet = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type DietCursorConnection = {
  readonly edges: ReadonlyArray<Diet>;
  readonly pageInfo: CursorPageInfo;
};

export type DietOffsetConnection = {
  readonly edges: ReadonlyArray<Diet>;
  readonly pageInfo: OffsetPageInfo;
};

export type DiningStationTemplate = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly mealTypes: ReadonlyArray<MealType>;
  readonly menuItemAppearances: ReadonlyArray<MenuItemAppearance>;
  readonly defaultMaxAmount: Maybe<Scalars['Int']>;
};

export type DiningStationTemplateCursorConnection = {
  readonly edges: ReadonlyArray<DiningStationTemplate>;
  readonly pageInfo: CursorPageInfo;
};

export type DiningStationTemplateOffsetConnection = {
  readonly edges: ReadonlyArray<DiningStationTemplate>;
  readonly pageInfo: OffsetPageInfo;
};

export type DuplicatePlannedMenuInput = {
  readonly originalMenuId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly startDate: Scalars['Date'];
  readonly endDate: Scalars['Date'];
  readonly timezone: Scalars['String'];
};

export type EditActivityInput = {
  readonly activity: AddActivityInput;
  readonly activityId: Scalars['ID'];
};

export type EditActivityTemplateCalendarInput = {
  readonly activityTemplateId: Scalars['ID'];
  readonly activity: Maybe<AddActivityInput>;
  readonly resetModifications: Scalars['Boolean'];
  readonly dateModifications: ReadonlyArray<MealPlanDateModificationInput>;
  readonly dayOfWeekModifications: ReadonlyArray<MealPlanDayOfWeekModificationInput>;
};

export type EditAgreementFormInput = {
  readonly id: Scalars['ID'];
  readonly title: Scalars['String'];
  readonly url: Scalars['String'];
  readonly acceptAgreementText: Scalars['String'];
};

export type EditAgreementFormPayload = {
  readonly agreementForm: AgreementForm;
};

export type EditAnthropometryEntriesPayload = {
  readonly anthropometryEntries: ReadonlyArray<AnthropometryEntry>;
};

export type EditAnthropometryEntryCommentInput = {
  readonly id: Scalars['ID'];
  readonly comment: Scalars['String'];
};

export type EditAnthropometryEntryCommentPayload = {
  readonly anthropometryEntry: AnthropometryEntry;
};

export type EditAnthropometryEntryInput = {
  readonly id: Scalars['ID'];
  readonly anthropometryEntry: AnthropometryEntryFormInput;
};

export type EditAnthropometryEntryPayload = {
  readonly anthropometryEntry: AnthropometryEntry;
};

export type EditAnthropometryEntryWeighInForDateForAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly weight: Scalars['Float'];
  readonly date: Scalars['Date'];
};

export type EditAnthropometryEntryWeighInForDateForAthletePayload = {
  readonly anthropometryEntry: AnthropometryEntry;
};

export type EditAnthropometryEntryWeighInForDateInput = {
  readonly weight: Scalars['Float'];
  readonly date: Scalars['Date'];
};

export type EditAnthropometryEntryWeighInForDatePayload = {
  readonly anthropometryEntry: AnthropometryEntry;
};

export type EditAthleteAndAnthroInput = {
  readonly athleteId: Scalars['ID'];
  readonly teamId: Scalars['ID'];
  readonly athlete: AthleteFormInput;
  readonly anthropometryEntry: Maybe<AnthropometryEntryFormInput>;
};

export type EditAthleteAndMetricAnthroInput = {
  readonly athleteId: Scalars['ID'];
  readonly teamId: Scalars['ID'];
  readonly athlete: AthleteFormInput;
  readonly anthropometryEntry: Maybe<MetricAnthropometryEntryFormInput>;
};

export type EditAthleteDisplaySettingsInput = {
  readonly athleteId: Scalars['ID'];
  readonly macroDisplaySettings: Maybe<MacroMealPlanDisplaySettingsInput>;
  readonly exchangeDisplaySettings: Maybe<ExchangeMealPlanDisplaySettingsInput>;
  readonly noTargetsDisplaySettings: Maybe<NoTargetsDisplaySettingsInput>;
  readonly hideAnthropometry: Maybe<Scalars['Boolean']>;
};

export type EditAthleteDisplaySettingsPayload = {
  readonly athlete: Athlete;
};

export type EditAthleteFoodLogNotificationSettingsInput = {
  readonly athleteId: Scalars['ID'];
  readonly settings: Maybe<FoodLogNotificationSettingsInput>;
};

export type EditAthleteFoodLogNotificationSettingsPayload = {
  readonly athlete: Athlete;
};

export type EditAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly teamId: Scalars['ID'];
  readonly athlete: AthleteFormInput;
};

export type EditAthletePayload = {
  readonly athlete: Athlete;
};

export type EditAthletesPayload = {
  readonly athletes: ReadonlyArray<Athlete>;
};

export type EditBrandInput = {
  readonly id: Scalars['ID'];
  readonly brand: BrandInputForm;
};

export type EditBrandPayload = {
  readonly brand: Brand;
};

export type EditBrandedFoodInput = {
  readonly id: Scalars['ID'];
  readonly food: BrandedFoodFormInput;
  readonly nutrientAmounts: Maybe<ReadonlyArray<FoodNutrientAmountFormInput>>;
  readonly nutrientAmountsManualEntry: Maybe<ReadonlyArray<CreateFoodNutrientAmountFormInput>>;
};

export type EditBrandedFoodPayload = {
  readonly brandedFood: BrandedFood;
};

export type EditBulkOrderInput = {
  readonly bulkOrderId: Scalars['ID'];
  readonly pickupTime: Scalars['DateTime'];
  readonly teamId: Scalars['ID'];
  readonly deliveryLocation: Scalars['String'];
  readonly addPlates: Maybe<ReadonlyArray<AddMealMenuPlateInput>>;
  readonly editPlates: Maybe<ReadonlyArray<EditMealMenuPlateInput>>;
  readonly removePlateIds: Maybe<ReadonlyArray<Scalars['ID']>>;
};

export type EditBulkOrderPayload = {
  readonly bulkOrder: BulkOrder;
  readonly addedMealMenuPlateOrders: ReadonlyArray<MealMenuPlateOrder>;
  readonly editedMealMenuPlateOrders: ReadonlyArray<MealMenuPlateOrder>;
  readonly removedMealMenuPlateOrderIds: ReadonlyArray<Scalars['ID']>;
};

export type EditClientCredentialInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly isActive: Scalars['Boolean'];
};

export type EditClientCredentialPayload = {
  readonly clientCredential: ClientCredential;
};

export type EditCuisineInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type EditCuisinePayload = {
  readonly cuisine: Cuisine;
};

export type EditCustomTagInput = {
  readonly id: Scalars['ID'];
  readonly tagInput: CreateCustomTagInput;
};

export type EditCustomizedPrepPlanMealInput = {
  readonly prepPlanMealId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly mealTypes: ReadonlyArray<MealType>;
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
};

export type EditCustomizedPrepPlanMealPayload = {
  readonly prepPlanMeal: PrepPlanMeal;
};

export type EditDeliveryLocationInput = {
  readonly deliveryLocationId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly deliveryInstructions: Maybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
  readonly teamLabelIds: ReadonlyArray<Scalars['ID']>;
  readonly cityInfo: Maybe<Scalars['String']>;
};

export type EditDeliveryLocationPayload = {
  readonly deliveryLocation: DeliveryLocation;
};

export type EditDietInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type EditDietPayload = {
  readonly diet: Diet;
};

export type EditDiningStationTemplateInput = {
  readonly after: Scalars['DateTime'];
  readonly diningStationTemplateId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly mealTypes: ReadonlyArray<MealType>;
  readonly createMenuItems: ReadonlyArray<CreateMenuItemInput>;
  readonly addMenuItems: ReadonlyArray<AddMenuItemInput>;
  readonly editMenuItems: ReadonlyArray<EditMenuItemInput>;
  readonly moveMenuItems: ReadonlyArray<MoveMenuItemInput>;
  readonly convertMenuItems: ReadonlyArray<ConvertMenuItemInput>;
  readonly removeMenuItems: ReadonlyArray<RemoveMenuItemInput>;
  readonly defaultMaxAmount: Maybe<Scalars['Int']>;
};

export type EditDiningStationTemplatePayload = {
  readonly diningStationTemplate: DiningStationTemplate;
};

export type EditExchangeInput = {
  readonly id: Scalars['ID'];
  readonly exchange: ExchangeInput;
  readonly exchangeServingList: Maybe<ExchangeServingListInput>;
};

export type EditExchangeMealTemplateInput = {
  readonly mealTemplateId: Scalars['ID'];
  readonly exchangeTargets: ReadonlyArray<ExchangeAmountInput>;
};

export type EditExchangeMealTemplatePayload = {
  readonly exchangeMealTemplate: ExchangeMealTemplate;
};

export type EditExchangeMealTemplatesPayload = {
  readonly exchangeMealTemplates: ReadonlyArray<ExchangeMealTemplate>;
};

export type EditExchangeServingListInput = {
  readonly id: Scalars['ID'];
  readonly exchangeServingList: ExchangeServingListInput;
};

export type EditExchangeSetInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly addExchanges: ReadonlyArray<CreateExchangeInput>;
  readonly editExchanges: ReadonlyArray<EditExchangeInput>;
  readonly removeExchanges: ReadonlyArray<Scalars['ID']>;
};

export type EditExchangeSetPayload = {
  readonly exchangeSet: ExchangeSet;
};

export type EditFoodGroupInput = {
  readonly id: Scalars['ID'];
  readonly foodGroup: CreateFoodGroupInput;
};

export type EditFoodGroupPayload = {
  readonly foodGroup: FoodGroup;
};

export type EditFoodLogActivityRpeInput = {
  readonly foodLogActivityId: Scalars['String'];
  readonly rpe: Scalars['Int'];
};

export type EditFoodLogActivityRpePayload = {
  readonly foodLogActivity: FoodLogActivity;
};

export type EditFoodLogActivityTemplateInput = {
  readonly foodLogActivityId: Scalars['ID'];
  readonly activityTemplateId: Scalars['ID'];
};

export type EditFoodLogActivityTemplatePayload = {
  readonly foodLogActivity: FoodLogActivity;
};

export type EditFoodLogActivityTimeWithTimezoneInput = {
  readonly foodLogActivityId: Scalars['String'];
  readonly activity: AddActivityInput;
  readonly timezone: Scalars['Timezone'];
};

export type EditFoodLogActivityTimeWithTimezonePayload = {
  readonly foodLogActivity: FoodLogActivity;
};

export type EditFoodLogMealImageInput = {
  readonly foodLogMealId: Scalars['ID'];
  readonly imageUrl: Scalars['String'];
};

export type EditFoodLogMealImagePayload = {
  readonly foodLogMeal: FoodLogMeal;
};

export type EditFoodLogMealServingAmountsInput = {
  readonly foodLogMealId: Scalars['ID'];
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
};

export type EditFoodLogMealServingAmountsPayload = {
  readonly foodLogMeal: FoodLogMeal;
};

export type EditFoodLogMealTemplateInput = {
  readonly foodLogMealId: Scalars['ID'];
  readonly mealTemplateId: Scalars['ID'];
};

export type EditFoodLogMealTemplatePayload = {
  readonly foodLogMeal: FoodLogMeal;
};

export type EditFoodLogMealTimePayload = {
  readonly foodLogMeal: FoodLogMeal;
};

export type EditFoodLogMealTimeWithTimezoneInput = {
  readonly foodLogMealId: Scalars['String'];
  readonly meal: AddMealInput;
  readonly timezone: Scalars['Timezone'];
};

export type EditFoodLogMealTimeWithTimezonePayload = {
  readonly foodLogMeal: FoodLogMeal;
};

export type EditFoodServingInput = {
  readonly id: Scalars['ID'];
  readonly serving: FoodServingInput;
};

export type EditFoodServingPayload = {
  readonly serving: Serving;
};

export type EditGenericFoodInput = {
  readonly id: Scalars['ID'];
  readonly food: GenericFoodFormInput;
  readonly nutrientAmounts: Maybe<ReadonlyArray<FoodNutrientAmountFormInput>>;
  readonly nutrientAmountsManualEntry: Maybe<ReadonlyArray<CreateFoodNutrientAmountFormInput>>;
};

export type EditGenericFoodPayload = {
  readonly genericFood: GenericFood;
};

export type EditGoalConfigurationBaseInput = {
  readonly id: Scalars['ID'];
  readonly name: Maybe<Scalars['String']>;
  readonly target: Maybe<CreateGoalQuantityInput>;
  readonly removeTarget: Scalars['Boolean'];
  readonly timezone: Scalars['String'];
  readonly reminders: ReadonlyArray<SetGoalReminderConfigurationInput>;
};

export type EditGoalConfigurationPayload = {
  readonly goalConfiguration: GoalConfiguration;
};

export type EditGoalConfigurationWithDaysOfWeekInput = {
  readonly input: EditGoalConfigurationBaseInput;
  readonly startDate: Scalars['Date'];
  readonly endDate: Scalars['Date'];
  readonly dayOfWeekAssignments: ReadonlyArray<DayOfWeek>;
};

export type EditGoalConfigurationWithIndividualDatesInput = {
  readonly input: EditGoalConfigurationBaseInput;
  readonly addIndividualDates: ReadonlyArray<Scalars['Date']>;
  readonly removeIndividualDates: ReadonlyArray<Scalars['Date']>;
};

export type EditGroceryListCustomItemInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type EditGroceryListCustomItemPayload = {
  readonly id: Scalars['ID'];
};

export type EditGroceryListFoodItemInput = {
  readonly id: Scalars['ID'];
  readonly groceryListCategoryId: Maybe<Scalars['ID']>;
  readonly amount: Maybe<Scalars['Int']>;
  readonly adjustment: Maybe<Scalars['Float']>;
  readonly servingId: Scalars['ID'];
};

export type EditGroceryListFoodItemPayload = {
  readonly id: Scalars['ID'];
};

export type EditGroceryListInput = {
  readonly id: Scalars['ID'];
  readonly notes: Maybe<Scalars['String']>;
};

export type EditGroceryListPayload = {
  readonly id: Scalars['ID'];
};

export type EditMacroMealPlanTemplateInput = {
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly additionalState: Maybe<Scalars['String']>;
  readonly macroProtocol: MealPlanTemplateMacroProtocolInput;
  readonly meals: ReadonlyArray<MacroMealPlanTemplateMealInput>;
  readonly foodPreferences: FoodPreferenceInput;
  readonly activities: ReadonlyArray<MealPlanTemplateActivityInput>;
};

export type EditMacroMealPlanTemplatePayload = {
  readonly macroMealPlanTemplate: MacroMealPlanTemplate;
};

export type EditMacroMealTemplateInput = {
  readonly mealTemplateId: Scalars['ID'];
  readonly cho: Maybe<Scalars['Float']>;
  readonly pro: Maybe<Scalars['Float']>;
  readonly fat: Maybe<Scalars['Float']>;
};

export type EditMacroMealTemplatePayload = {
  readonly macroMealTemplate: MacroMealTemplate;
};

export type EditMacroMealTemplatesPayload = {
  readonly macroMealTemplates: ReadonlyArray<MacroMealTemplate>;
};

export type EditMacroProtocolInput = {
  readonly macroProtocolId: Scalars['String'];
  readonly macroProtocol: MacroProtocolInput;
};

export type EditMacroProtocolPayload = {
  readonly macroProtocol: MacroProtocol;
};

export type EditMacroProtocolWithNewGoalInput = {
  readonly macroProtocolId: Scalars['String'];
  readonly macroProtocol: MacroProtocolWithNewGoalInput;
};

export type EditMacroProtocolWithNewGoalPayload = {
  readonly macroProtocol: MacroProtocol;
};

export type EditMacroProtocolWithOldGoalInput = {
  readonly macroProtocolId: Scalars['String'];
  readonly macroProtocol: MacroProtocolWithOldGoalInput;
};

export type EditMacroProtocolWithOldGoalPayload = {
  readonly macroProtocol: MacroProtocol;
};

export type EditMealInput = {
  readonly mealId: Scalars['ID'];
  readonly meal: AddMealInput;
};

export type EditMealMenuDiningStationInput = {
  readonly mealMenuDiningStationId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly position: Scalars['Int'];
  readonly maxAmount: Maybe<Scalars['Int']>;
  readonly createMenuItems: ReadonlyArray<CreateMenuItemInput>;
  readonly addMenuItems: ReadonlyArray<AddMenuItemInput>;
  readonly editMenuItems: ReadonlyArray<EditMenuItemInput>;
  readonly moveMenuItems: ReadonlyArray<MoveMenuItemInput>;
  readonly convertMenuItems: ReadonlyArray<ConvertMenuItemInput>;
  readonly removeMenuItems: ReadonlyArray<RemoveMenuItemInput>;
};

export type EditMealMenuInput = {
  readonly mealMenuId: Scalars['ID'];
  readonly teamIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly advancedSelectionInput: Maybe<AdvancedSelectionInput>;
  readonly name: Scalars['String'];
  readonly type: MealType;
  readonly start: Scalars['DateTime'];
  readonly timezone: Scalars['Timezone'];
  readonly durationInMinutes: Scalars['Int'];
  readonly prepTimeInMinutes: Scalars['Int'];
  readonly lastOrderTimeBeforeEndInMinutes: Scalars['Int'];
  readonly notificationSentBeforeOrderDueInMinutes: Maybe<Scalars['Int']>;
  readonly orderRateLimit: Maybe<MenuOrderRateLimitInput>;
  readonly userOrderLimit: Maybe<MealMenuUserOrderLimitInput>;
  readonly isHubCheckInEnabled: Maybe<Scalars['Boolean']>;
  readonly isOrderAndLogRestricted: Maybe<Scalars['Boolean']>;
  readonly theme: Maybe<Scalars['String']>;
  readonly addDiningStations: ReadonlyArray<AddMealMenuDiningStationInput>;
  readonly editDiningStations: ReadonlyArray<EditMealMenuDiningStationInput>;
  readonly removeDiningStations: ReadonlyArray<RemoveMealMenuDiningStationInput>;
  readonly addRestaurantMenuLinks: ReadonlyArray<RestaurantMenuLinkInput>;
  readonly editRestaurantMenuLinks: ReadonlyArray<EditRestaurantMenuLinkInput>;
  readonly removeRestaurantMenuLinks: ReadonlyArray<RemoveRestaurantMenuLinkInput>;
};

export type EditMealMenuLogItemInput = {
  readonly mealMenuLogItemId: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly options: ReadonlyArray<MealMenuLogItemOptionInput>;
};

export type EditMealMenuLogItemPayload = {
  readonly mealMenuLogItem: MealMenuLogItem;
};

export type EditMealMenuPayload = {
  readonly mealMenu: StandaloneMealMenu;
};

export type EditMealMenuPlateInput = {
  readonly mealMenuPlateId: Scalars['ID'];
  readonly parentBulkOrderId: Scalars['ID'];
  readonly addItems: Maybe<ReadonlyArray<AddMealMenuPlateItemInput>>;
  readonly editItems: Maybe<ReadonlyArray<EditMealMenuPlateItemInput>>;
  readonly removePlateItemIds: ReadonlyArray<Scalars['ID']>;
};

export type EditMealMenuPlateItemInput = {
  readonly mealMenuPlateItemId: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly editMealMenuPlate: EditMealMenuPlateInput;
  readonly options: ReadonlyArray<MealMenuPlateItemOptionInput>;
  readonly specialRequests: Maybe<Scalars['String']>;
};

export type EditMealMenuWithIdentityInput = {
  readonly mealMenuId: Scalars['ID'];
  readonly teamIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly advancedSelectionInput: Maybe<AdvancedSelectionInput>;
  readonly name: Scalars['String'];
  readonly type: MealType;
  readonly start: Scalars['DateTime'];
  readonly timezone: Scalars['Timezone'];
  readonly durationInMinutes: Scalars['Int'];
  readonly prepTimeInMinutes: Scalars['Int'];
  readonly lastOrderTimeBeforeEndInMinutes: Scalars['Int'];
  readonly notificationSentBeforeOrderDueInMinutes: Maybe<Scalars['Int']>;
  readonly orderRateLimit: Maybe<MenuOrderRateLimitInput>;
  readonly userOrderLimit: Maybe<MealMenuUserOrderLimitInput>;
  readonly isHubCheckInEnabled: Maybe<Scalars['Boolean']>;
  readonly isOrderAndLogRestricted: Maybe<Scalars['Boolean']>;
  readonly availableForOrder: Scalars['Boolean'];
  readonly allowSpecialRequests: Scalars['Boolean'];
  readonly theme: Maybe<Scalars['String']>;
};

export type EditMealMenuWithIdentityPayload = {
  readonly mealMenu: StandaloneMealMenu;
};

export type EditMealOptionInput = {
  readonly mealOptionId: Scalars['ID'];
  readonly mealOption: MealOptionInput;
};

export type EditMealOptionLocalInput = {
  readonly mealOptionId: Scalars['ID'];
  readonly servingAmounts: ReadonlyArray<ServingAmountLocalInput>;
  readonly position: Scalars['Int'];
  readonly note: Scalars['String'];
  readonly name: Scalars['String'];
};

export type EditMealOptionPayload = {
  readonly mealOption: MealOption;
};

export type EditMealOptionPendingInput = {
  readonly mealOptionPendingId: Scalars['ID'];
  readonly mealOptionPending: MealOptionPendingInput;
};

export type EditMealOptionPendingLocalInput = {
  readonly mealOptionPendingId: Scalars['ID'];
  readonly servingAmounts: ReadonlyArray<ServingAmountLocalInput>;
  readonly note: Scalars['String'];
  readonly name: Scalars['String'];
};

export type EditMealOptionPendingPayload = {
  readonly mealOptionPending: MealOptionPending;
};

export type EditMealPlanCalendarInput = {
  readonly mealPlanId: Scalars['ID'];
  readonly isAutoSuggestionsEnabled: Scalars['Boolean'];
  readonly addIndividualDates: Maybe<ReadonlyArray<Scalars['Date']>>;
  readonly removeIndividualDates: Maybe<ReadonlyArray<Scalars['Date']>>;
  readonly startDate: Maybe<Scalars['Date']>;
  readonly endDate: Maybe<Scalars['Date']>;
  readonly dayOfWeekPriorities: Maybe<ReadonlyArray<DayOfWeekPriorityInput>>;
  readonly removeConflictingIndividualDates: Maybe<ReadonlyArray<Scalars['Date']>>;
  readonly addMealTemplates: ReadonlyArray<AddMealTemplateCalendarInput>;
  readonly editMealTemplates: ReadonlyArray<EditMealTemplateCalendarInput>;
  readonly removeMealTemplateIds: ReadonlyArray<Scalars['ID']>;
  readonly addActivityTemplates: ReadonlyArray<AddActivityTemplateCalendarInput>;
  readonly editActivityTemplates: ReadonlyArray<EditActivityTemplateCalendarInput>;
  readonly removeActivityTemplateIds: ReadonlyArray<Scalars['ID']>;
};

export type EditMealPlanCalendarPayload = {
  readonly mealPlan: MealPlan;
};

export type EditMealPlanDateForAthleteInput = {
  readonly date: Scalars['Date'];
  readonly athleteId: Scalars['ID'];
  readonly timezone: Scalars['Timezone'];
  readonly mealPlanId: Scalars['ID'];
};

export type EditMealPlanDateForAthletePayload = {
  readonly mealPlanDate: MealPlanDate;
};

export type EditMealPlanDateInput = {
  readonly date: Scalars['Date'];
  readonly timezone: Scalars['Timezone'];
  readonly mealPlanId: Scalars['ID'];
};

export type EditMealPlanDatePayload = {
  readonly mealPlanDate: MealPlanDate;
};

export type EditMealPlanExchangeTargetsInput = {
  readonly mealPlanId: Scalars['ID'];
  readonly exchangeTargets: ReadonlyArray<ExchangeAmountInput>;
};

export type EditMealPlanExchangeTargetsPayload = {
  readonly mealPlan: MealPlan;
};

export type EditMealPlanInput = {
  readonly mealPlanId: Scalars['ID'];
  readonly mealPlan: MealPlanEditFormInput;
  readonly foodPreferences: FoodPreferenceInput;
  readonly exchangeSetId: Maybe<Scalars['ID']>;
};

export type EditMealPlanIsSharedInput = {
  readonly mealPlanId: Scalars['ID'];
  readonly isShared: Scalars['Boolean'];
};

export type EditMealPlanIsSharedPayload = {
  readonly mealPlan: MealPlan;
};

export type EditMealPlanNewInput = {
  readonly mealPlanId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly isShared: Scalars['Boolean'];
  readonly exchangeSetId: Maybe<Scalars['String']>;
  readonly foodPreferences: FoodPreferenceInput;
};

export type EditMealPlanPayload = {
  readonly mealPlan: MealPlan;
};

export type EditMealPlanScheduleInput = {
  readonly mealPlanId: Scalars['ID'];
  readonly addMeals: ReadonlyArray<AddMealInput>;
  readonly editMeals: ReadonlyArray<EditMealInput>;
  readonly removeMeals: ReadonlyArray<RemoveMealInput>;
  readonly addActivities: ReadonlyArray<AddActivityInput>;
  readonly editActivities: ReadonlyArray<EditActivityInput>;
  readonly removeActivities: ReadonlyArray<RemoveActivityInput>;
};

export type EditMealPlanSchedulePayload = {
  readonly mealPlan: MealPlan;
};

export type EditMealPlansIsSharedInput = {
  readonly mealPlanIds: ReadonlyArray<Scalars['ID']>;
  readonly isShared: Scalars['Boolean'];
};

export type EditMealPlansIsSharedPayload = {
  readonly mealPlans: ReadonlyArray<MealPlan>;
};

export type EditMealTemplateCalendarInput = {
  readonly mealTemplateId: Scalars['ID'];
  readonly meal: Maybe<AddMealInput>;
  readonly resetModifications: Scalars['Boolean'];
  readonly dateModifications: ReadonlyArray<MealPlanDateModificationInput>;
  readonly dayOfWeekModifications: ReadonlyArray<MealPlanDayOfWeekModificationInput>;
};

export type EditMenuItemChoiceInput = {
  readonly menuItemChoiceId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly position: Scalars['Int'];
  readonly required: Scalars['Boolean'];
  readonly maxOptionsCount: Maybe<Scalars['Int']>;
  readonly addOptions: ReadonlyArray<AddMenuItemChoiceOptionInput>;
  readonly editOptions: ReadonlyArray<EditMenuItemChoiceOptionInput>;
  readonly removeOptions: ReadonlyArray<RemoveMenuItemChoiceOptionInput>;
};

export type EditMenuItemChoiceOptionInput = {
  readonly menuItemChoiceOptionId: Scalars['ID'];
  readonly menuItemChoiceOption: AddMenuItemChoiceOptionInput;
};

export type EditMenuItemImageInput = {
  readonly menuItemId: Scalars['ID'];
  readonly imageUrl: Maybe<Scalars['String']>;
};

export type EditMenuItemImagePayload = {
  readonly menuItem: MenuItem;
};

export type EditMenuItemInput = {
  readonly menuItemId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly servingName: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly appearance: MenuItemAppearanceInput;
  readonly suggestionCategory: Maybe<MenuItemSuggestionCategory>;
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
  readonly addChoices: ReadonlyArray<AddMenuItemChoiceInput>;
  readonly editChoices: ReadonlyArray<EditMenuItemChoiceInput>;
  readonly removeChoices: ReadonlyArray<RemoveMenuItemChoiceInput>;
  readonly imageUrl: Maybe<Scalars['String']>;
  readonly scoreValue: Maybe<Scalars['Float']>;
  readonly foodCategory: Maybe<FoodCategoryType>;
};

export type EditMenuOrderInput = {
  readonly menuOrderId: Scalars['ID'];
  readonly pickupTime: Scalars['DateTime'];
  readonly items: Maybe<ReadonlyArray<MenuOrderItemInput>>;
  readonly addOrderItems: Maybe<ReadonlyArray<MenuOrderItemInput>>;
  readonly addLogItems: Maybe<ReadonlyArray<MenuOrderItemInput>>;
  readonly editItems: Maybe<ReadonlyArray<EditMenuOrderItemInput>>;
  readonly removeItemIds: Maybe<ReadonlyArray<Scalars['ID']>>;
};

export type EditMenuOrderItemInput = {
  readonly menuOrderItemId: Scalars['ID'];
  readonly forOrder: Scalars['Boolean'];
  readonly amount: Scalars['Float'];
  readonly specialRequests: Maybe<Scalars['String']>;
  readonly options: ReadonlyArray<MenuOrderItemOptionInput>;
};

export type EditMenuOrderMealTemplateInput = {
  readonly menuOrderId: Scalars['ID'];
  readonly mealTemplateId: Scalars['ID'];
};

export type EditMenuOrderMealTemplatePayload = {
  readonly menuOrder: MenuOrder;
};

export type EditMenuOrderPayload = {
  readonly menuOrder: MenuOrder;
};

export type EditMetricAnthropometryEntryInput = {
  readonly id: Scalars['ID'];
  readonly anthropometryEntry: MetricAnthropometryEntryFormInput;
};

export type EditMetricAnthropometryEntryWeighInForDateForAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly weightInKg: Scalars['Float'];
  readonly date: Scalars['Date'];
};

export type EditMetricAnthropometryEntryWeighInForDateInput = {
  readonly weightInKg: Scalars['Float'];
  readonly date: Scalars['Date'];
};

export type EditMetricMacroProtocolInput = {
  readonly macroProtocolId: Scalars['String'];
  readonly macroProtocol: MetricMacroProtocolInput;
};

export type EditNoteInput = {
  readonly id: Scalars['ID'];
  readonly note: NoteFormInput;
};

export type EditNotePayload = {
  readonly note: Note;
};

export type EditNoteTypeInput = {
  readonly id: Scalars['ID'];
  readonly noteType: NoteTypeFormInput;
};

export type EditNoteTypePayload = {
  readonly noteType: NoteType;
};

export type EditOrgAutoShareNewNotemealRecipesInput = {
  readonly autoShareNewNotemealRecipes: Scalars['Boolean'];
  readonly shareCurrent: Scalars['Boolean'];
};

export type EditOrgAutoShareNewNotemealRecipesPayload = {
  readonly org: Org;
};

export type EditOrgFeatureFlagsInput = {
  readonly orgId: Scalars['ID'];
  readonly featureFlags: FeatureFlagsInput;
};

export type EditOrgFeatureFlagsPayload = {
  readonly featureFlags: FeatureFlags;
};

export type EditOrgGroupInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly allowsResourceSharing: Scalars['Boolean'];
  readonly allowsAthleteTransfer: Scalars['Boolean'];
  readonly parentOrgGroupId: Maybe<Scalars['ID']>;
  readonly orgIds: ReadonlyArray<Scalars['ID']>;
};

export type EditOrgGroupPayload = {
  readonly orgGroup: OrgGroup;
};

export type EditOrgInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly isActive: Scalars['Boolean'];
  readonly allowsPublicAccess: Scalars['Boolean'];
  readonly editMemberships: ReadonlyArray<EditOrgMembershipAsStaffInput>;
  readonly createMemberships: ReadonlyArray<CreateOrgMembershipAsStaffInput>;
  readonly teamworksId: Maybe<Scalars['Int']>;
  readonly integrations: ReadonlyArray<IntegrationInput>;
  readonly agreementFormId: Maybe<Scalars['ID']>;
  readonly scoringSystemId: Maybe<Scalars['ID']>;
  readonly localeCode: Scalars['String'];
  readonly territoryName: Maybe<Scalars['String']>;
};

export type EditOrgMembershipAsStaffInput = {
  readonly id: Scalars['ID'];
  readonly isActive: Scalars['Boolean'];
  readonly roles: ReadonlyArray<Role>;
  readonly isAdmin: Scalars['Boolean'];
  readonly phoneNumber: Maybe<Scalars['String']>;
  readonly email: Maybe<Scalars['String']>;
};

export type EditOrgMembershipInput = {
  readonly id: Scalars['ID'];
  readonly isActive: Scalars['Boolean'];
  readonly roles: ReadonlyArray<Role>;
};

export type EditOrgMembershipSettingsInput = {
  readonly isSubscribedToDigest: Scalars['Boolean'];
};

export type EditOrgMembershipSettingsPayload = {
  readonly orgMembership: OrgMembership;
};

export type EditOrgMembershipsAsStaffInput = {
  readonly memberships: ReadonlyArray<EditOrgMembershipAsStaffInput>;
};

export type EditOrgMembershipsInput = {
  readonly memberships: ReadonlyArray<EditOrgMembershipInput>;
};

export type EditOrgMembershipsPayload = {
  readonly memberships: ReadonlyArray<OrgMembership>;
};

export type EditOrgPayload = {
  readonly org: Org;
};

export type EditOrgTeamworksProfilesInput = {
  readonly orgId: Scalars['ID'];
  readonly profileSyncRules: ReadonlyArray<TeamworksProfileSyncRuleInput>;
  readonly profileLinks: ReadonlyArray<TeamworksProfileLinkInput>;
  readonly deactivateTeamworksProfileIds: ReadonlyArray<Scalars['Int']>;
  readonly deactivateOrgMembershipIds: ReadonlyArray<Scalars['ID']>;
  readonly notemealOnlyOrgMembershipIds: ReadonlyArray<Scalars['ID']>;
  readonly removeNotemealOnlyOrgMembershipIds: ReadonlyArray<Scalars['ID']>;
  readonly archiveAthleteIds: ReadonlyArray<Scalars['ID']>;
  readonly notemealOnlyAthleteIds: ReadonlyArray<Scalars['ID']>;
  readonly removeNotemealOnlyAthleteIds: ReadonlyArray<Scalars['ID']>;
};

export type EditOrgTeamworksProfilesPayload = {
  readonly org: Org;
  readonly skippedProfiles: ReadonlyArray<EditOrgTeamworksSkippedProfile>;
};

export type EditOrgTeamworksSkippedProfile = {
  readonly teamworksUserId: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly reason: Scalars['String'];
  readonly conflictedUserIds: ReadonlyArray<Scalars['String']>;
};

export type EditPaymentMethodInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type EditPaymentMethodPayload = {
  readonly paymentMethod: PaymentMethod;
};

export type EditPlannedMenuInput = {
  readonly plannedMenuId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly timezone: Scalars['String'];
  readonly startDate: Scalars['Date'];
  readonly endDate: Scalars['Date'];
};

export type EditPlannedMenuPayload = {
  readonly plannedMenu: PlannedMenu;
};

export type EditPrepPlanInput = {
  readonly prepPlanId: Scalars['ID'];
  readonly start: Scalars['Date'];
  readonly end: Scalars['Date'];
};

export type EditPrepPlanMealInput = {
  readonly prepPlanMealId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly priority: Scalars['Int'];
  readonly prepPlanMealAssignments: Maybe<ReadonlyArray<PrepPlanMealAssignmentInput>>;
  readonly prepPlanMealSlotAssignments: Maybe<ReadonlyArray<PrepPlanMealSlotAssignmentInput>>;
};

export type EditPrepPlanMealPayload = {
  readonly prepPlanMeal: PrepPlanMeal;
};

export type EditPrepPlanPayload = {
  readonly prepPlan: PrepPlan;
};

export type EditRecipeFullInput = {
  readonly id: Scalars['ID'];
  readonly recipe: RecipeFullInput;
  readonly createServings: ReadonlyArray<RecipeServingFullInput>;
  readonly editServings: ReadonlyArray<EditRecipeServingFullInput>;
};

export type EditRecipeFullPayload = {
  readonly recipe: Recipe;
};

export type EditRecipeIsSharedInput = {
  readonly id: Scalars['ID'];
  readonly isShared: Scalars['Boolean'];
};

export type EditRecipeIsSharedPayload = {
  readonly recipe: Recipe;
};

export type EditRecipePartialInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly prepTimeInMinutes: Maybe<Scalars['Int']>;
  readonly cookTimeInMinutes: Maybe<Scalars['Int']>;
  readonly steps: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly note: Maybe<Scalars['String']>;
  readonly images: ReadonlyArray<ImageInput>;
  readonly isShared: Maybe<Scalars['Boolean']>;
  readonly mealTypes: Maybe<ReadonlyArray<MealType>>;
  readonly scoreValue: Maybe<Scalars['Float']>;
  readonly foodCategory: Maybe<FoodCategoryType>;
};

export type EditRecipePartialPayload = {
  readonly recipe: Recipe;
};

export type EditRecipeServingFullInput = {
  readonly id: Scalars['ID'];
  readonly serving: RecipeServingFullInput;
};

export type EditRecipeServingInput = {
  readonly servingId: Scalars['ID'];
  readonly serving: RecipeServingFormInput;
};

export type EditRecipeSimpleInput = {
  readonly id: Scalars['ID'];
  readonly recipe: RecipeSimpleInput;
  readonly serving: RecipeServingSimpleInput;
};

export type EditRecipeSimplePayload = {
  readonly recipe: Recipe;
};

export type EditRestaurantBrandedFoodInput = {
  readonly id: Scalars['ID'];
  readonly food: BrandedFoodFormInput;
  readonly nutrientAmounts: Maybe<ReadonlyArray<FoodNutrientAmountFormInput>>;
  readonly nutrientAmountsManualEntry: Maybe<ReadonlyArray<CreateFoodNutrientAmountFormInput>>;
  readonly restaurantId: Scalars['ID'];
};

export type EditRestaurantBrandedFoodPayload = {
  readonly brandedFood: BrandedFood;
};

export type EditRestaurantInput = {
  readonly restaurantId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly nutritionixId: Maybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
  readonly website: Maybe<Scalars['String']>;
  readonly proteinPortionSizeInOunces: Maybe<Scalars['Int']>;
  readonly firstPartyDelivery: Scalars['Boolean'];
  readonly athleteVisible: Scalars['Boolean'];
  readonly refundPolicy: Maybe<RefundPolicyType>;
  readonly refundDueBeforeInMinutes: Maybe<Scalars['Int']>;
  readonly restaurantTypeId: Maybe<Scalars['ID']>;
  readonly paymentMethodIds: ReadonlyArray<Scalars['ID']>;
  readonly cuisineIds: ReadonlyArray<Scalars['ID']>;
};

export type EditRestaurantLocationContactInput = {
  readonly id: Scalars['ID'];
  readonly restaurantLocationContact: RestaurantLocationContactInput;
};

export type EditRestaurantLocationContactPayload = {
  readonly restaurantLocationContact: RestaurantLocationContact;
};

export type EditRestaurantLocationInput = {
  readonly restaurantLocationId: Scalars['ID'];
  readonly cityInfo: Maybe<Scalars['String']>;
  readonly notes: Maybe<Scalars['String']>;
  readonly email: Maybe<Scalars['String']>;
  readonly phoneNumber: Maybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
  readonly deliveryLocationIds: ReadonlyArray<Scalars['ID']>;
};

export type EditRestaurantLocationPayload = {
  readonly restaurantLocation: RestaurantLocation;
};

export type EditRestaurantMenuInput = {
  readonly restaurantMenuId: Scalars['ID'];
  readonly addSections: ReadonlyArray<AddRestaurantMenuSectionInput>;
  readonly editSections: ReadonlyArray<EditRestaurantMenuSectionInput>;
  readonly removeSections: ReadonlyArray<RemoveRestaurantMenuSectionInput>;
  readonly supportedDietIds: ReadonlyArray<Scalars['ID']>;
};

export type EditRestaurantMenuLinkInput = {
  readonly restaurantMenuLinkId: Scalars['ID'];
  readonly sections: ReadonlyArray<RestaurantMenuSectionLinkInput>;
  readonly addPlates: ReadonlyArray<AddRestaurantMenuLinkPlateInput>;
  readonly editPlates: ReadonlyArray<EditRestaurantMenuLinkPlateInput>;
  readonly removePlateIds: ReadonlyArray<Scalars['ID']>;
  readonly allowCustomOrders: Scalars['Boolean'];
  readonly allowPlateOrders: Scalars['Boolean'];
};

export type EditRestaurantMenuLinkOrderInput = {
  readonly restaurantMenuLinkOrderId: Scalars['ID'];
  readonly addItems: ReadonlyArray<RestaurantMenuLinkOrderItemInput>;
  readonly editItems: ReadonlyArray<EditRestaurantMenuLinkOrderItemInput>;
  readonly removeItemIds: ReadonlyArray<Scalars['ID']>;
};

export type EditRestaurantMenuLinkOrderItemInput = {
  readonly restaurantMenuLinkOrderItemId: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly specialRequests: Maybe<Scalars['String']>;
  readonly options: ReadonlyArray<RestaurantMenuLinkOrderItemOptionInput>;
};

export type EditRestaurantMenuLinkOrderMealTemplateInput = {
  readonly restaurantMenuLinkOrderId: Scalars['ID'];
  readonly mealTemplateId: Scalars['ID'];
};

export type EditRestaurantMenuLinkOrderMealTemplatePayload = {
  readonly restaurantMenuLinkOrder: RestaurantMenuLinkOrder;
};

export type EditRestaurantMenuLinkOrderPayload = {
  readonly restaurantMenuLinkOrder: RestaurantMenuLinkOrder;
};

export type EditRestaurantMenuLinkPayload = {
  readonly restaurantMenuLink: RestaurantMenuLink;
};

export type EditRestaurantMenuLinkPlateInput = {
  readonly restaurantMenuLinkPlateId: Scalars['ID'];
  readonly position: Scalars['Int'];
  readonly bulkOrderAmount: Maybe<Scalars['Int']>;
  readonly addItems: ReadonlyArray<AddRestaurantMenuLinkPlateItemInput>;
  readonly editItems: ReadonlyArray<EditRestaurantMenuLinkPlateItemInput>;
  readonly removeItemIds: ReadonlyArray<Scalars['ID']>;
  readonly isDefault: Maybe<Scalars['Boolean']>;
};

export type EditRestaurantMenuLinkPlateItemInput = {
  readonly restaurantMenuLinkPlateItemId: Scalars['ID'];
  readonly position: Scalars['Int'];
  readonly amount: Scalars['Float'];
  readonly options: ReadonlyArray<RestaurantMenuLinkPlateItemOptionInput>;
  readonly specialRequests: Maybe<Scalars['String']>;
};

export type EditRestaurantMenuLinkPlateOrderMealTemplateInput = {
  readonly restaurantMenuLinkPlateOrderId: Scalars['ID'];
  readonly mealTemplateId: Scalars['ID'];
};

export type EditRestaurantMenuLinkPlateOrderMealTemplatePayload = {
  readonly restaurantMenuLinkPlateOrder: RestaurantMenuLinkPlateOrder;
};

export type EditRestaurantMenuLogInput = {
  readonly dateTime: Scalars['DateTime'];
  readonly timezone: Scalars['Timezone'];
  readonly restaurantMenuLogId: Scalars['ID'];
  readonly addItems: ReadonlyArray<RestaurantMenuLogItemInput>;
  readonly editItems: ReadonlyArray<EditRestaurantMenuLogItemInput>;
  readonly removeItemIds: ReadonlyArray<Scalars['ID']>;
  readonly type: MealType;
};

export type EditRestaurantMenuLogItemInput = {
  readonly restaurantMenuLogItemId: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly options: ReadonlyArray<RestaurantMenuLogItemOptionInput>;
};

export type EditRestaurantMenuLogItemPayload = {
  readonly restaurantMenuLogItem: RestaurantMenuLogItem;
};

export type EditRestaurantMenuLogMealTemplateInput = {
  readonly restaurantMenuLogId: Scalars['ID'];
  readonly mealTemplateId: Scalars['ID'];
};

export type EditRestaurantMenuLogMealTemplatePayload = {
  readonly restaurantMenuLog: RestaurantMenuLog;
};

export type EditRestaurantMenuLogPayload = {
  readonly restaurantMenuLog: RestaurantMenuLog;
};

export type EditRestaurantMenuPayload = {
  readonly restaurantMenu: RestaurantMenu;
};

export type EditRestaurantMenuSectionInput = {
  readonly restaurantMenuSectionId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly position: Scalars['Int'];
  readonly createMenuItems: ReadonlyArray<CreateMenuItemInput>;
  readonly addMenuItems: ReadonlyArray<AddMenuItemInput>;
  readonly editMenuItems: ReadonlyArray<EditMenuItemInput>;
  readonly moveMenuItems: ReadonlyArray<MoveMenuItemInput>;
  readonly convertMenuItems: ReadonlyArray<ConvertMenuItemInput>;
  readonly removeMenuItems: ReadonlyArray<RemoveMenuItemInput>;
};

export type EditRestaurantPayload = {
  readonly restaurant: Restaurant;
};

export type EditRestaurantTypeInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type EditRestaurantTypePayload = {
  readonly restaurantType: RestaurantType;
};

export type EditReusableMenuItemInput = {
  readonly after: Scalars['DateTime'];
  readonly menuItemId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly servingName: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly defaultAvailableForOrder: Scalars['Boolean'];
  readonly defaultMaxAmount: Maybe<Scalars['Int']>;
  readonly suggestionCategory: Maybe<MenuItemSuggestionCategory>;
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
  readonly addChoices: ReadonlyArray<AddMenuItemChoiceInput>;
  readonly editChoices: ReadonlyArray<EditMenuItemChoiceInput>;
  readonly removeChoices: ReadonlyArray<RemoveMenuItemChoiceInput>;
  readonly imageUrl: Maybe<Scalars['String']>;
  readonly defaultAllowSpecialRequests: Scalars['Boolean'];
  readonly scoreValue: Maybe<Scalars['Float']>;
  readonly foodCategory: Maybe<FoodCategoryType>;
};

export type EditReusableMenuItemPartialInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly servingName: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly defaultAvailableForOrder: Scalars['Boolean'];
  readonly defaultMaxAmount: Maybe<Scalars['Int']>;
  readonly suggestionCategory: Maybe<MenuItemSuggestionCategory>;
  readonly imageUrl: Maybe<Scalars['String']>;
  readonly defaultAllowSpecialRequests: Scalars['Boolean'];
  readonly scoreValue: Maybe<Scalars['Float']>;
  readonly foodCategory: Maybe<FoodCategoryType>;
};

export type EditReusableMenuItemPartialPayload = {
  readonly menuItem: MenuItem;
};

export type EditReusableMenuItemPayload = {
  readonly menuItem: MenuItem;
};

export type EditScheduleInput = {
  readonly scheduleId: Scalars['ID'];
  readonly addMeals: ReadonlyArray<AddMealInput>;
  readonly editMeals: ReadonlyArray<EditMealInput>;
  readonly removeMeals: ReadonlyArray<RemoveMealInput>;
  readonly addActivities: ReadonlyArray<AddActivityInput>;
  readonly editActivities: ReadonlyArray<EditActivityInput>;
  readonly removeActivities: ReadonlyArray<RemoveActivityInput>;
  readonly activityFactor: Maybe<Scalars['Float']>;
  readonly name: Maybe<Scalars['String']>;
};

export type EditSchedulePayload = {
  readonly schedule: Schedule;
};

export type EditSmartTagInput = {
  readonly id: Scalars['ID'];
  readonly tagInput: CreateSmartTagInput;
};

export type EditSyncedAthleteAndAnthroInput = {
  readonly athleteId: Scalars['ID'];
  readonly athlete: SyncedAthleteFormInput;
  readonly anthropometryEntry: Maybe<AnthropometryEntryFormInput>;
};

export type EditSyncedAthleteAndMetricAnthroInput = {
  readonly athleteId: Scalars['ID'];
  readonly athlete: SyncedAthleteFormInput;
  readonly anthropometryEntry: Maybe<MetricAnthropometryEntryFormInput>;
};

export type EditSyncedAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly athlete: SyncedAthleteFormInput;
};

export type EditTeamDisplaySettingsInput = {
  readonly teamId: Scalars['ID'];
  readonly macroDisplaySettings: MacroMealPlanDisplaySettingsInput;
  readonly exchangeDisplaySettings: ExchangeMealPlanDisplaySettingsInput;
  readonly noTargetsDisplaySettings: NoTargetsDisplaySettingsInput;
  readonly hideAnthropometry: Scalars['Boolean'];
};

export type EditTeamDisplaySettingsPayload = {
  readonly team: Team;
};

export type EditTeamFoodLogNotificationSettingsInput = {
  readonly teamId: Scalars['ID'];
  readonly settings: Maybe<FoodLogNotificationSettingsInput>;
};

export type EditTeamFoodLogNotificationSettingsPayload = {
  readonly team: Team;
};

export type EditTeamForStaffInput = {
  readonly id: Scalars['ID'];
  readonly orgId: Scalars['ID'];
  readonly team: CreateTeamInput;
};

export type EditTeamInput = {
  readonly id: Scalars['ID'];
  readonly team: CreateTeamInput;
};

export type EditTeamLabelInput = {
  readonly teamLabelId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly isActive: Scalars['Boolean'];
};

export type EditTeamLabelPayload = {
  readonly teamLabel: TeamLabel;
};

export type EditTeamPayload = {
  readonly team: Team;
};

export type EditTerritoryInput = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly orgIds: ReadonlyArray<Scalars['ID']>;
  readonly removeOrgIds: ReadonlyArray<Scalars['ID']>;
};

export type EditTimelineMealDetailsInput = {
  readonly timelineMealId: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly start: Scalars['DateTime'];
  readonly timezone: Scalars['Timezone'];
  readonly durationInMinutes: Scalars['Int'];
  readonly type: MealType;
};

export type EditTimelineMealDetailsPayload = {
  readonly timelineMeal: TimelineMeal;
};

export type EditTimelineMealMealTemplateInput = {
  readonly timelineMealId: Scalars['ID'];
  readonly mealTemplateId: Scalars['ID'];
};

export type EditTimelineMealMealTemplatePayload = {
  readonly timelineMeal: TimelineMeal;
};

export type EditTimelineMealServingAmountInput = {
  readonly timelineMealId: Scalars['ID'];
  readonly removeServingAmountId: Scalars['ID'];
  readonly servingAmount: CreateServingAmountInput;
};

export type EditTimelineMealServingAmountPayload = {
  readonly timelineMeal: TimelineMeal;
};

export type EditUserBrandedFoodFormInput = {
  readonly id: Scalars['ID'];
  readonly input: CreateUserBrandedFoodFormInput;
};

export type EditUserBrandedFoodPayload = {
  readonly brandedFood: BrandedFood;
};

export type EditUserTeamworksIdToOverwriteInput = {
  readonly userIds: ReadonlyArray<Scalars['ID']>;
  readonly teamworksIdToOverwrite: Scalars['Int'];
};

export type EditUserTeamworksIdToOverwritePayload = {
  readonly userIds: ReadonlyArray<Scalars['ID']>;
};

export type EmailMealPlanToAthletePayload = {
  readonly result: Scalars['Boolean'];
};

export type Event = {
  readonly datetime: Scalars['DateTime'];
  readonly type: Scalars['String'];
};

export type Exchange = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly shortName: Scalars['String'];
  readonly type: ExchangeType;
  readonly cho: Scalars['Float'];
  readonly pro: Scalars['Float'];
  readonly fat: Scalars['Float'];
  readonly kcal: Scalars['Float'];
  readonly exchangeServingList: ExchangeServingList;
};

export type ExchangeAmount = {
  readonly amount: Scalars['Float'];
  readonly amountInput: Scalars['String'];
  readonly exchange: Exchange;
  readonly id: Scalars['ID'];
  readonly pickListServingIds: Maybe<ReadonlyArray<Scalars['ID']>>;
};

export type ExchangeAmountInput = {
  readonly exchangeId: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly pickListServingIds: Maybe<ReadonlyArray<Scalars['ID']>>;
};

export type ExchangeAmountTemplate = {
  readonly id: Scalars['ID'];
  readonly pickListServingIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly exchange: Exchange;
};

export type ExchangeInput = {
  readonly name: Scalars['String'];
  readonly shortName: Scalars['String'];
  readonly type: ExchangeType;
  readonly cho: Scalars['Float'];
  readonly pro: Scalars['Float'];
  readonly fat: Scalars['Float'];
};

export type ExchangeMealPlanDisplaySettings = {
  readonly dailyProgressViewsForExchanges: ExchangeProgressViews;
  readonly mealProgressViewsForExchanges: ExchangeProgressViews;
  readonly dailyProgressViewsForMacros: MacroProgressViews;
  readonly mealProgressViewsForMacros: MacroProgressViews;
};

export type ExchangeMealPlanDisplaySettingsInput = {
  readonly dailyProgressViewsForExchanges: ExchangeProgressViews;
  readonly mealProgressViewsForExchanges: ExchangeProgressViews;
  readonly dailyProgressViewsForMacros: MacroProgressViews;
  readonly mealProgressViewsForMacros: MacroProgressViews;
};

export type ExchangeMealPlanTemplate = MealPlanTemplate & {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['DateTime'];
  readonly owner: User;
  /** @deprecated Field no longer supported */
  readonly orgId: Maybe<Scalars['ID']>;
  /** @deprecated Field no longer supported */
  readonly orgGroupId: Maybe<Scalars['ID']>;
  readonly teams: Maybe<ReadonlyArray<Team>>;
  readonly advancedSelection: Maybe<AdvancedSelectionType>;
  readonly hasAdvancedSelection: Scalars['Boolean'];
  readonly isShared: Scalars['Boolean'];
  readonly copiedCount: Scalars['Int'];
  readonly avoidedFoodGroups: ReadonlyArray<FoodGroup>;
  readonly promotedFoodGroups: ReadonlyArray<FoodGroup>;
  readonly avoidedFoods: ReadonlyArray<Food>;
  readonly promotedFoods: ReadonlyArray<Food>;
  readonly macroProtocol: MealPlanTemplateMacroProtocol;
  readonly meals: ReadonlyArray<ExchangeMealPlanTemplateMeal>;
  readonly activities: ReadonlyArray<MealPlanTemplateActivity>;
  readonly exchangeTargets: Maybe<ReadonlyArray<ExchangeAmountTemplate>>;
  readonly exchangeSet: Maybe<ExchangeSet>;
  readonly pendingState: Maybe<Scalars['String']>;
  readonly additionalState: Maybe<Scalars['String']>;
};

export type ExchangeMealPlanTemplateMeal = MealPlanTemplateMeal & {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly type: MealType;
  readonly start: Scalars['Time'];
  readonly end: Scalars['Time'];
  readonly mealOptions: ReadonlyArray<MealPlanTemplateMealOption>;
  readonly exchangeTargets: ReadonlyArray<ExchangeAmount>;
};

export type ExchangeMealTemplate = MealTemplate & {
  readonly exchangeTargets: ReadonlyArray<ExchangeAmount>;
  readonly hasDateModifications: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly isAutosaving: Scalars['Boolean'];
  readonly meal: Meal;
  readonly mealOptionCount: Scalars['Int'];
  readonly mealOptions: ReadonlyArray<MealOption>;
  readonly mealPlan: MealPlan;
  readonly newMealOptionIds: ReadonlyArray<Scalars['ID']>;
  readonly pendingMealOptions: ReadonlyArray<MealOptionPending>;
  readonly selectedMealOptionId: Maybe<Scalars['ID']>;
};


export type ExchangeMealTemplateHasDateModificationsArgs = {
  after: Scalars['Date'];
};

export type ExchangeProgressViews =
  | 'all'
  | 'none'
  | '%future added value';

export type ExchangeRatio = {
  readonly exchange: Exchange;
  readonly ratio: Scalars['Float'];
};

export type ExchangeServingList = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly servingAmounts: ReadonlyArray<ServingAmount>;
};

export type ExchangeServingListInput = {
  readonly name: Scalars['String'];
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
};

export type ExchangeSet = {
  readonly createdBy: Maybe<User>;
  readonly exchanges: ReadonlyArray<Exchange>;
  readonly foodOrRecipeExchangeRatios: ReadonlyArray<FoodOrRecipeExchangeRatios>;
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type ExchangeSetOffsetConnection = {
  readonly edges: ReadonlyArray<ExchangeSet>;
  readonly pageInfo: OffsetPageInfo;
};

export type ExchangeType =
  | 'starch'
  | 'fruit'
  | 'vegetable'
  | 'protein'
  | 'dairy'
  | 'fat'
  | '%future added value';

export type ExternalEvent = {
  readonly id: Scalars['String'];
  readonly name: Scalars['String'];
  readonly timezone: Scalars['Timezone'];
  readonly startDate: Scalars['DateTime'];
  readonly endDate: Scalars['DateTime'];
  readonly isAllDay: Scalars['Boolean'];
  readonly eventType: Scalars['String'];
  readonly location: Maybe<Scalars['String']>;
};

export type FeatureFlag =
  | 'dietitianMenuSuggestions'
  | 'athleteMenuSuggestions'
  | '%future added value';

export type FeatureFlags = {
  readonly athleteMenuSuggestions: Scalars['Boolean'];
  readonly clientCredentials: Scalars['Boolean'];
  readonly hasRestaurantMenus: Scalars['Boolean'];
  readonly restaurantLogging: Scalars['Boolean'];
  readonly foodCategory: Scalars['Boolean'];
  readonly orgGroupRecipes: Scalars['Boolean'];
  readonly orgGroupFoods: Scalars['Boolean'];
  readonly performanceKitchen: Scalars['Boolean'];
  readonly printableTags: Scalars['Boolean'];
  readonly hubMenuCheckIn: Scalars['Boolean'];
  readonly dietitianOnlyMenus: Scalars['Boolean'];
  readonly bulkOrdering: Scalars['Boolean'];
  readonly ukMealPlanOptionSuggestions: Scalars['Boolean'];
  readonly digitalDisplays: Scalars['Boolean'];
  readonly selfServiceOnboarding: Scalars['Boolean'];
  readonly messaging: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  readonly recipeScaling: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  readonly selfServiceActivationLink: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  readonly goalTracker: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  readonly pk2MenuBuilder: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  readonly aiRecipes: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  readonly aiTranscriber: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  readonly rebranding: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  readonly teamworksSchedules: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  readonly mealPlanSuggestions: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  readonly mealPlanTemplates: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  readonly tags: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  readonly multipleMenuOrders: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  readonly prepPlan: Scalars['Boolean'];
};

export type FeatureFlagsInput = {
  readonly athleteMenuSuggestions: Maybe<Scalars['Boolean']>;
  readonly clientCredentials: Maybe<Scalars['Boolean']>;
  readonly hasRestaurantMenus: Maybe<Scalars['Boolean']>;
  readonly restaurantLogging: Maybe<Scalars['Boolean']>;
  readonly foodCategory: Maybe<Scalars['Boolean']>;
  readonly orgGroupRecipes: Maybe<Scalars['Boolean']>;
  readonly orgGroupFoods: Maybe<Scalars['Boolean']>;
  readonly performanceKitchen: Maybe<Scalars['Boolean']>;
  readonly printableTags: Maybe<Scalars['Boolean']>;
  readonly hubMenuCheckIn: Maybe<Scalars['Boolean']>;
  readonly dietitianOnlyMenus: Maybe<Scalars['Boolean']>;
  readonly bulkOrdering: Maybe<Scalars['Boolean']>;
  readonly ukMealPlanOptionSuggestions: Maybe<Scalars['Boolean']>;
  readonly digitalDisplays: Maybe<Scalars['Boolean']>;
  readonly selfServiceOnboarding: Maybe<Scalars['Boolean']>;
  readonly messaging: Maybe<Scalars['Boolean']>;
};

export type FinalizePrepPlanInput = {
  readonly prepPlanId: Scalars['ID'];
};

export type FinalizePrepPlanPayload = {
  readonly prepPlan: PrepPlan;
};

export type Food = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly choPer100g: Scalars['Float'];
  readonly proPer100g: Scalars['Float'];
  readonly fatPer100g: Scalars['Float'];
  readonly kcalPer100g: Scalars['Float'];
  readonly exchangeTypes: Maybe<ReadonlyArray<ExchangeType>>;
  readonly nixItemId: Maybe<Scalars['String']>;
  readonly nixTagId: Maybe<Scalars['String']>;
  readonly usdaFdcId: Maybe<Scalars['Int']>;
  readonly usdaFdcDescription: Maybe<Scalars['String']>;
  readonly usdaFdcFoodCategoryId: Maybe<Scalars['Int']>;
  readonly usdaFdcDataType: Maybe<Scalars['String']>;
  readonly isRecipeIngredientOnly: Scalars['Boolean'];
  readonly excludeFromSuggestions: Scalars['Boolean'];
  readonly thumbnailUrl: Maybe<Scalars['String']>;
  readonly hasFullAccess: Scalars['Boolean'];
  readonly org: Maybe<Org>;
  readonly groceryListCategory: Maybe<GroceryListCategory>;
  readonly servings: ReadonlyArray<Serving>;
  readonly defaultServing: Serving;
  readonly foodGroups: ReadonlyArray<FoodGroup>;
  readonly source: FoodDataSource;
  readonly locales: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly nutrientAmounts: ReadonlyArray<NutrientAmount>;
  readonly createdById: Maybe<Scalars['ID']>;
  readonly createdBy: Maybe<User>;
  readonly updatedAt: Scalars['DateTime'];
  readonly showGramServing: Scalars['Boolean'];
  readonly showOunceServing: Scalars['Boolean'];
};

export type FoodCategoryType =
  | 'carbohydrate'
  | 'protein'
  | 'fats'
  | 'combination'
  | '%future added value';

export type FoodCursorConnection = {
  readonly edges: ReadonlyArray<Food>;
  readonly pageInfo: CursorPageInfo;
};

export type FoodDataSource =
  | 'manual'
  | 'usda'
  | 'nutritionix'
  | 'bonAppetit'
  | 'compass'
  | 'sodexo'
  | 'cbord'
  | 'restaurant'
  | 'mccance'
  | '%future added value';

export type FoodGroup = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly source: Maybe<Scalars['String']>;
  readonly foodsCount: Scalars['Int'];
  readonly foods: ReadonlyArray<Food>;
};

export type FoodGroupsCursorConnection = {
  readonly edges: ReadonlyArray<FoodGroup>;
  readonly pageInfo: CursorPageInfo;
};

export type FoodGroupsOffsetConnection = {
  readonly edges: ReadonlyArray<FoodGroup>;
  readonly pageInfo: OffsetPageInfo;
};

export type FoodLogActivity = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly type: ActivityType;
  readonly date: Scalars['Date'];
  readonly startTime: Scalars['Time'];
  readonly endTime: Scalars['Time'];
  readonly timezone: Scalars['Timezone'];
  readonly rpe: Maybe<Scalars['Int']>;
  readonly activityTemplate: Maybe<ActivityTemplate>;
};

export type FoodLogMeal = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly type: MealType;
  readonly date: Scalars['Date'];
  readonly startTime: Scalars['Time'];
  readonly endTime: Scalars['Time'];
  readonly timezone: Scalars['Timezone'];
  readonly images: ReadonlyArray<Image>;
  readonly athlete: Maybe<Athlete>;
  readonly mealTemplate: Maybe<MealTemplate>;
  readonly servingAmounts: ReadonlyArray<ServingAmount>;
  readonly comments: ReadonlyArray<Comment>;
  readonly notifications: ReadonlyArray<Notification>;
};

export type FoodLogMealCommentNotification = Notification & {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
  readonly foodLogMeal: Maybe<FoodLogMeal>;
  readonly timelineMeal: Maybe<TimelineMeal>;
};

export type FoodLogMealEditNotification = Notification & {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
  readonly foodLogMeal: Maybe<FoodLogMeal>;
  readonly timelineMeal: Maybe<TimelineMeal>;
};

export type FoodLogMealImageNotification = Notification & {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
  readonly foodLogMeal: Maybe<FoodLogMeal>;
  readonly timelineMeal: Maybe<TimelineMeal>;
  readonly image: Maybe<Image>;
};

export type FoodLogMealPublication = {
  readonly payload: FoodLogMealPublicationPayload;
};

export type FoodLogMealPublicationPayload = AddFoodLogMealPayload | EditFoodLogMealTimePayload | RemoveFoodLogMealPayload | EditFoodLogMealServingAmountsPayload | EditFoodLogMealImagePayload | AddFoodLogMealCommentPayload | AddFoodLogMealImagePayload | { readonly __typename?: "%other" };

export type FoodLogMealSubscriberScope = {
  readonly foodLogMealId: Maybe<Scalars['ID']>;
};

export type FoodLogMealTemplateInput = {
  readonly timelineMealIds: ReadonlyArray<Scalars['ID']>;
  readonly meal: AddMealInput;
};

export type FoodLogNotificationSettings = {
  readonly edits: Scalars['Boolean'];
  readonly imageUploads: Scalars['Boolean'];
  readonly messages: Scalars['Boolean'];
};

export type FoodLogNotificationSettingsInput = {
  readonly edits: Scalars['Boolean'];
  readonly imageUploads: Scalars['Boolean'];
  readonly messages: Scalars['Boolean'];
};

export type FoodNutrientAmountFormInput = {
  readonly fdcNutrientId: Scalars['Int'];
  readonly amount: Scalars['Float'];
  readonly footnote: Maybe<Scalars['String']>;
  readonly dataPoints: Maybe<Scalars['Int']>;
  readonly min: Maybe<Scalars['Float']>;
  readonly max: Maybe<Scalars['Float']>;
  readonly median: Maybe<Scalars['Float']>;
};

export type FoodOffsetConnection = {
  readonly edges: ReadonlyArray<Food>;
  readonly pageInfo: OffsetPageInfo;
};

export type FoodOrRecipe = BrandedFood | GenericFood | Recipe | { readonly __typename?: "%other" };

export type FoodOrRecipeExchangeRatios = {
  readonly id: Scalars['ID'];
  readonly foodOrRecipeId: Scalars['ID'];
  readonly exchangeRatios: ReadonlyArray<ExchangeRatio>;
};

export type FoodPreferenceInput = {
  readonly avoidedFoodGroupIds: ReadonlyArray<Scalars['ID']>;
  readonly promotedFoodGroupIds: ReadonlyArray<Scalars['ID']>;
  readonly avoidedFoodIds: ReadonlyArray<Scalars['ID']>;
  readonly promotedFoodIds: ReadonlyArray<Scalars['ID']>;
};

export type FoodServingInput = {
  readonly units: ServingUnitsInput;
  readonly weight: Scalars['Float'];
  readonly isDefault: Scalars['Boolean'];
  readonly isRecipeServingOnly: Scalars['Boolean'];
  readonly defaultAmount: Scalars['Float'];
  readonly usdaWeightSeq: Maybe<Scalars['String']>;
};

export type ForceLockPlannedMenuInput = {
  readonly plannedMenuId: Scalars['ID'];
};

export type ForceLockPlannedMenuPayload = {
  readonly plannedMenuId: Scalars['ID'];
};

export type GptRecipeByNameInput = {
  readonly name: Scalars['String'];
  readonly servingYield: Scalars['Float'];
};

export type GptRecipeFromImageInput = {
  readonly presignedGetImageUrl: Scalars['String'];
};

export type GptRecipeFromUrlInput = {
  readonly url: Scalars['String'];
};

export type GptRecipePayload = {
  readonly name: Scalars['String'];
  readonly servingYield: Maybe<GptServingYield>;
  readonly steps: ReadonlyArray<Scalars['String']>;
  readonly ingredients: ReadonlyArray<GptServingAmount>;
  readonly unmatchedIngredients: UnmatchedIngredients;
  readonly aiType: AiType;
};

export type GptServingAmount = {
  readonly serving: Serving;
  readonly position: Scalars['Int'];
  readonly amount: Scalars['Float'];
};

export type GptServingYield = {
  readonly amount: Scalars['Float'];
  readonly name: Scalars['String'];
};

export type GenericFood = Food & {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly choPer100g: Scalars['Float'];
  readonly proPer100g: Scalars['Float'];
  readonly fatPer100g: Scalars['Float'];
  readonly kcalPer100g: Scalars['Float'];
  readonly exchangeTypes: Maybe<ReadonlyArray<ExchangeType>>;
  readonly nixItemId: Maybe<Scalars['String']>;
  readonly nixTagId: Maybe<Scalars['String']>;
  readonly usdaFdcDescription: Maybe<Scalars['String']>;
  readonly usdaFdcId: Maybe<Scalars['Int']>;
  readonly usdaFdcFoodCategoryId: Maybe<Scalars['Int']>;
  readonly usdaFdcDataType: Maybe<Scalars['String']>;
  readonly isRecipeIngredientOnly: Scalars['Boolean'];
  readonly excludeFromSuggestions: Scalars['Boolean'];
  readonly thumbnailUrl: Maybe<Scalars['String']>;
  readonly hasFullAccess: Scalars['Boolean'];
  readonly org: Maybe<Org>;
  readonly orgGroup: Maybe<OrgGroup>;
  readonly groceryListCategory: Maybe<GroceryListCategory>;
  readonly servings: ReadonlyArray<Serving>;
  readonly defaultServing: Serving;
  readonly foodGroups: ReadonlyArray<FoodGroup>;
  readonly nutrientAmounts: ReadonlyArray<NutrientAmount>;
  /** @deprecated Field no longer supported */
  readonly brandedFoods: ReadonlyArray<BrandedFood>;
  readonly createdById: Maybe<Scalars['ID']>;
  readonly createdBy: Maybe<User>;
  readonly owner: Maybe<User>;
  readonly updatedAt: Scalars['DateTime'];
  readonly source: FoodDataSource;
  readonly locales: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly showGramServing: Scalars['Boolean'];
  readonly showOunceServing: Scalars['Boolean'];
};

export type GenericFoodFormInput = {
  readonly name: Scalars['String'];
  readonly thumbnailUrl: Maybe<Scalars['String']>;
  readonly choPer100g: Scalars['Float'];
  readonly proPer100g: Scalars['Float'];
  readonly fatPer100g: Scalars['Float'];
  readonly exchangeTypes: ReadonlyArray<ExchangeType>;
  readonly isRecipeIngredientOnly: Scalars['Boolean'];
  readonly excludeFromSuggestions: Scalars['Boolean'];
  readonly groceryListCategoryId: Maybe<Scalars['String']>;
  readonly source: FoodDataSource;
  readonly locales: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly foodGroupIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly usdaFdcDescription: Maybe<Scalars['String']>;
  readonly usdaFdcId: Maybe<Scalars['Int']>;
  readonly usdaFdcFoodCategoryId: Maybe<Scalars['Int']>;
  readonly usdaFdcDataType: Maybe<Scalars['String']>;
  readonly mccanceId: Maybe<Scalars['String']>;
  readonly mccanceName: Maybe<Scalars['String']>;
};

export type Goal = {
  readonly id: Scalars['ID'];
  readonly kcalOffset: Scalars['Int'];
  readonly start: Scalars['Date'];
  readonly end: Maybe<Scalars['Date']>;
  readonly type: GoalType;
};

export type GoalCategory = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly defaultEmoji: Scalars['String'];
};

export type GoalCompletionSummary = {
  readonly goalCompletedDayCount: Scalars['Int'];
  readonly totalGoalDayCount: Scalars['Int'];
};

export type GoalConfiguration = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly emoji: Scalars['String'];
  readonly category: GoalCategory;
  readonly isArchived: Scalars['Boolean'];
  readonly timezone: Scalars['String'];
  readonly reminders: ReadonlyArray<GoalReminderConfiguration>;
  readonly startDate: Maybe<Scalars['Date']>;
  readonly endDate: Maybe<Scalars['Date']>;
  readonly dayOfWeekAssignments: ReadonlyArray<DayOfWeek>;
  readonly individualDates: ReadonlyArray<Scalars['Date']>;
  readonly target: Maybe<GoalQuantity>;
  readonly athlete: Maybe<Athlete>;
  readonly goals: ReadonlyArray<GoalTrackerGoal>;
  readonly completedGoalCount: Scalars['Int'];
  readonly totalGoalCount: Scalars['Int'];
};


export type GoalConfigurationCompletedGoalCountArgs = {
  startDate: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
};


export type GoalConfigurationTotalGoalCountArgs = {
  startDate: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
};

export type GoalConfigurationHistory = {
  readonly id: Scalars['ID'];
  readonly current: GoalConfiguration;
  readonly all: ReadonlyArray<GoalConfiguration>;
};

export type GoalConfigurationOffsetConnection = {
  readonly edges: ReadonlyArray<GoalConfiguration>;
  readonly pageInfo: OffsetPageInfo;
};

export type GoalDayOfWeekAssignment = {
  readonly id: Scalars['ID'];
  readonly dayOfWeek: DayOfWeek;
};

export type GoalFormInput = {
  readonly goalTypeId: Scalars['ID'];
  readonly kcalOffset: Scalars['Int'];
  readonly start: Scalars['Date'];
  readonly end: Maybe<Scalars['Date']>;
};

export type GoalQuantity = {
  readonly id: Scalars['ID'];
  readonly value: Scalars['Int'];
  readonly unit: Scalars['String'];
};

export type GoalReminderConfiguration = {
  readonly id: Scalars['ID'];
  readonly time: Scalars['Time'];
  readonly message: Scalars['String'];
};

export type GoalReminderNotification = Notification & {
  readonly createdAt: Scalars['DateTime'];
  readonly id: Scalars['ID'];
  readonly message: Scalars['String'];
  readonly title: Scalars['String'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly goalTrackerGoal: Maybe<GoalTrackerGoal>;
};

export type GoalTrackerGoal = {
  readonly id: Scalars['ID'];
  readonly goalConfiguration: GoalConfiguration;
  readonly date: Scalars['Date'];
  readonly completedAtInUTC: Maybe<Scalars['String']>;
  readonly athlete: Athlete;
  readonly value: Maybe<Scalars['Int']>;
};

export type GoalTrackerGoalOffsetConnection = {
  readonly edges: ReadonlyArray<GoalTrackerGoal>;
  readonly pageInfo: OffsetPageInfo;
};

export type GoalType = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly defaultKcalOffset: Scalars['Int'];
};

export type GroceryList = {
  readonly id: Scalars['ID'];
  readonly prepPlan: PrepPlan;
  readonly notes: Maybe<Scalars['String']>;
  readonly foodItems: ReadonlyArray<GroceryListFoodItem>;
  readonly customItems: ReadonlyArray<GroceryListCustomItem>;
};

export type GroceryListCategory = {
  readonly id: Scalars['ID'];
  readonly position: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type GroceryListCustomItem = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly groceryList: GroceryList;
  readonly checked: Scalars['Boolean'];
};

export type GroceryListFoodItem = {
  readonly id: Scalars['ID'];
  readonly checked: Scalars['Boolean'];
  readonly serving: Serving;
  readonly amount: Scalars['Float'];
  readonly adjustment: Scalars['Float'];
  readonly groceryList: GroceryList;
  readonly groceryListCategory: Maybe<GroceryListCategory>;
};

export type Image = {
  readonly id: Scalars['ID'];
  readonly url: Scalars['String'];
  readonly position: Scalars['Int'];
};

export type ImageInput = {
  readonly url: Scalars['String'];
  readonly position: Scalars['Int'];
};

export type ImportBonAppetitMenusInput = {
  readonly cafeId: Scalars['String'];
  readonly menus: ReadonlyArray<BonAppetitMenuInput>;
  readonly options: ImportMenuOptionsInput;
};

export type ImportBonAppetitMenusPayload = {
  readonly mealMenus: ReadonlyArray<MealMenu>;
  readonly skippedMealMenus: ReadonlyArray<MealMenu>;
};

export type ImportCbordMenusInput = {
  readonly serviceUnitOid: Scalars['Int'];
  readonly facilityOid: Maybe<Scalars['Int']>;
  readonly menuGroups: ReadonlyArray<CbordMenuGroupInput>;
  readonly options: ImportMenuOptionsInput;
};

export type ImportCbordMenusPayload = {
  readonly mealMenus: ReadonlyArray<MealMenu>;
  readonly skippedMealMenus: ReadonlyArray<MealMenu>;
};

export type ImportCompassMenusInput = {
  readonly locationId: Scalars['Int'];
  readonly menus: ReadonlyArray<CompassMenuInput>;
  readonly options: ImportMenuOptionsInput;
};

export type ImportCompassMenusPayload = {
  readonly mealMenus: ReadonlyArray<MealMenu>;
  readonly skippedMealMenus: ReadonlyArray<MealMenu>;
};

export type ImportCompassRecipeInput = {
  readonly masterRefNumber: Scalars['String'];
};

export type ImportCompassRecipeInputPayload = {
  readonly recipe: Recipe;
};

export type ImportDiningStationInput = {
  readonly name: Scalars['String'];
  readonly position: Scalars['Int'];
  readonly menuItems: ReadonlyArray<ImportMenuItemInput>;
};

export type ImportMealMenuInput = {
  readonly name: Scalars['String'];
  readonly timezone: Scalars['Timezone'];
  readonly startDateTime: Scalars['DateTime'];
  readonly durationInMinutes: Scalars['Int'];
  readonly type: MealType;
  readonly lastOrderTimeBeforeEndInMinutes: Scalars['Int'];
  readonly prepTimeInMinutes: Scalars['Int'];
  readonly diningStations: ReadonlyArray<ImportDiningStationInput>;
  readonly notificationSentBeforeOrderDueInMinutes: Maybe<Scalars['Int']>;
  readonly orderRateLimit: Maybe<MenuOrderRateLimitInput>;
  readonly userOrderLimit: Maybe<MealMenuUserOrderLimitInput>;
  readonly theme: Maybe<Scalars['String']>;
  readonly isHubCheckInEnabled: Maybe<Scalars['Boolean']>;
};

export type ImportMenuItemInput = {
  readonly menuItemId: Scalars['ID'];
  readonly position: Scalars['Int'];
  readonly availableForOrder: Scalars['Boolean'];
  readonly maxAmount: Maybe<Scalars['Int']>;
  readonly allowSpecialRequests: Scalars['Boolean'];
};

export type ImportMenuOptionsInput = {
  readonly menuTimes: ReadonlyArray<ImportMenuTimeInput>;
  readonly timezone: Scalars['Timezone'];
  readonly teamIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly advancedSelectionInput: Maybe<AdvancedSelectionInput>;
  readonly prepTimeInMinutes: Scalars['Int'];
  readonly lastOrderTimeBeforeEndInMinutes: Scalars['Int'];
  readonly notificationSentBeforeOrderDueInMinutes: Maybe<Scalars['Int']>;
  readonly orderRateLimit: Maybe<MenuOrderRateLimitInput>;
  readonly availableForOrder: Scalars['Boolean'];
  readonly allowSpecialRequests: Scalars['Boolean'];
  readonly overrideIdentity: Scalars['Boolean'];
  readonly userOrderLimit: Maybe<MealMenuUserOrderLimitInput>;
  readonly isHubCheckInEnabled: Maybe<Scalars['Boolean']>;
  readonly isOrderAndLogRestricted: Maybe<Scalars['Boolean']>;
  readonly theme: Maybe<Scalars['String']>;
};

export type ImportMenuTimeInput = {
  readonly menuName: Scalars['String'];
  readonly mealType: MealType;
  readonly startTime: Scalars['Time'];
  readonly durationInMinutes: Scalars['Int'];
};

export type ImportPlannedMenuInput = {
  readonly teamIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly advancedSelectionInput: Maybe<AdvancedSelectionInput>;
  readonly meals: ReadonlyArray<ImportMealMenuInput>;
};

export type ImportPlannedMenuPayload = {
  readonly mealMenus: ReadonlyArray<StandaloneMealMenu>;
};

export type ImportSodexoMenusInput = {
  readonly locationId: Scalars['String'];
  readonly menuSourceKey: Scalars['String'];
  readonly startDate: Scalars['Date'];
  readonly endDate: Scalars['Date'];
  readonly mealTimes: ReadonlyArray<SodexoMealTimeInput>;
  readonly options: ImportMenuOptionsInput;
};

export type ImportSodexoMenusPayload = {
  readonly mealMenus: ReadonlyArray<MealMenu>;
  readonly skippedMealMenus: ReadonlyArray<MealMenu>;
};

export type Integration = {
  readonly type: IntegrationType;
  readonly externalOrgId: Scalars['String'];
};

export type IntegrationInput = {
  readonly externalOrgId: Scalars['String'];
  readonly type: IntegrationType;
};

export type IntegrationType =
  | 'compass'
  | 'cbord'
  | 'bonAppetit'
  | 'sodexo'
  | '%future added value';

export type IsTagNameUniquePayload = {
  readonly isUnique: Scalars['Boolean'];
};

export type LinkTeamworksOrgInput = {
  readonly id: Scalars['ID'];
  readonly teamworksId: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type LinkTeamworksOrgPayload = {
  readonly org: Org;
};

export type LinkTeamworksPositionInput = {
  readonly teamworksId: Scalars['Int'];
  readonly teamworksTeamId: Scalars['Int'];
  readonly positionId: Scalars['ID'];
};

export type LinkTeamworksTeamInput = {
  readonly id: Scalars['ID'];
  readonly teamworksId: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly gender: Maybe<Scalars['String']>;
  readonly linkedSport: Maybe<LinkedSportInput>;
  readonly newSport: Maybe<CreateTeamworksSportInput>;
};

export type LinkedSportInput = {
  readonly sportId: Scalars['ID'];
  readonly newPositions: ReadonlyArray<CreateTeamworksPositionInput>;
  readonly linkedPositions: ReadonlyArray<LinkTeamworksPositionInput>;
};

export type Locale = {
  readonly id: Scalars['ID'];
  readonly code: Scalars['String'];
};

export type LockPlannedMenuInput = {
  readonly plannedMenuId: Scalars['ID'];
};

export type LockPlannedMenuPayload = {
  readonly lockAcquired: Scalars['Boolean'];
  readonly plannedMenu: PlannedMenu;
};

export type MacroMath = {
  readonly gPerKg: Scalars['Float'];
  readonly leftOverRatio: Scalars['Float'];
};

export type MacroMathInput = {
  readonly gPerKg: Scalars['Float'];
  readonly leftOverRatio: Scalars['Float'];
};

export type MacroMealPlanDisplaySettings = {
  readonly dailyProgressViews: MacroProgressViews;
  readonly mealProgressViews: MacroProgressViews;
};

export type MacroMealPlanDisplaySettingsInput = {
  readonly dailyProgressViews: MacroProgressViews;
  readonly mealProgressViews: MacroProgressViews;
};

export type MacroMealPlanTemplate = MealPlanTemplate & {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['DateTime'];
  readonly owner: User;
  /** @deprecated Field no longer supported */
  readonly orgId: Maybe<Scalars['ID']>;
  /** @deprecated Field no longer supported */
  readonly orgGroupId: Maybe<Scalars['ID']>;
  readonly teams: Maybe<ReadonlyArray<Team>>;
  readonly advancedSelection: Maybe<AdvancedSelectionType>;
  readonly hasAdvancedSelection: Scalars['Boolean'];
  readonly isShared: Scalars['Boolean'];
  readonly copiedCount: Scalars['Int'];
  readonly avoidedFoodGroups: ReadonlyArray<FoodGroup>;
  readonly promotedFoodGroups: ReadonlyArray<FoodGroup>;
  readonly avoidedFoods: ReadonlyArray<Food>;
  readonly promotedFoods: ReadonlyArray<Food>;
  readonly macroProtocol: MealPlanTemplateMacroProtocol;
  readonly meals: ReadonlyArray<MacroMealPlanTemplateMeal>;
  readonly activities: ReadonlyArray<MealPlanTemplateActivity>;
  readonly pendingState: Maybe<Scalars['String']>;
  readonly additionalState: Maybe<Scalars['String']>;
};

export type MacroMealPlanTemplateMeal = MealPlanTemplateMeal & {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly type: MealType;
  readonly start: Scalars['Time'];
  readonly end: Scalars['Time'];
  readonly mealOptions: ReadonlyArray<MealPlanTemplateMealOption>;
  readonly choRatio: Scalars['Float'];
  readonly proRatio: Scalars['Float'];
  readonly fatRatio: Scalars['Float'];
};

export type MacroMealPlanTemplateMealInput = {
  readonly meal: AddMealInput;
  readonly mealOptions: ReadonlyArray<MealOptionInput>;
  readonly choRatio: Scalars['Float'];
  readonly proRatio: Scalars['Float'];
  readonly fatRatio: Scalars['Float'];
};

export type MacroMealPlanTemplatePendingStateInput = {
  readonly macroMealPlanTemplateId: Scalars['ID'];
  readonly pendingState: Scalars['String'];
};

export type MacroMealTemplate = MealTemplate & {
  readonly cho: Maybe<Scalars['Float']>;
  readonly choInput: Scalars['String'];
  readonly fat: Maybe<Scalars['Float']>;
  readonly fatInput: Scalars['String'];
  readonly hasDateModifications: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly isAutosaving: Scalars['Boolean'];
  readonly kcal: Maybe<Scalars['Float']>;
  readonly meal: Meal;
  readonly mealOptionCount: Scalars['Int'];
  readonly mealOptions: ReadonlyArray<MealOption>;
  readonly mealPlan: MealPlan;
  readonly newMealOptionIds: ReadonlyArray<Scalars['ID']>;
  readonly pendingMealOptions: ReadonlyArray<MealOptionPending>;
  readonly pro: Maybe<Scalars['Float']>;
  readonly proInput: Scalars['String'];
  readonly selectedMealOptionId: Maybe<Scalars['ID']>;
};


export type MacroMealTemplateHasDateModificationsArgs = {
  after: Scalars['Date'];
};

export type MacroProgressViews =
  | 'all'
  | 'hideNumbers'
  | 'none'
  | '%future added value';

export type MacroProtocol = {
  readonly id: Scalars['ID'];
  readonly cho: MacroMath;
  readonly pro: MacroMath;
  readonly fat: MacroMath;
  readonly weightTarget: Maybe<Scalars['Float']>;
  readonly weightTargetInKg: Maybe<Scalars['Float']>;
  readonly anthropometrySnapshot: AnthropometryEntry;
  readonly calorieBudget: Maybe<CalorieBudget>;
};

export type MacroProtocolInput = {
  readonly cho: MacroMathInput;
  readonly pro: MacroMathInput;
  readonly fat: MacroMathInput;
  readonly weightTarget: Maybe<Scalars['Float']>;
  readonly anthropometryEntryId: Scalars['ID'];
};

export type MacroProtocolNewInput = {
  readonly cho: MacroMathInput;
  readonly pro: MacroMathInput;
  readonly fat: MacroMathInput;
  readonly weightTarget: Maybe<Scalars['Float']>;
  readonly anthropometryEntryId: Scalars['ID'];
  readonly calorieBudget: Maybe<CalorieBudgetInput>;
  readonly goalId: Maybe<Scalars['ID']>;
  readonly goal: Maybe<GoalFormInput>;
};

export type MacroProtocolWithNewGoalInput = {
  readonly cho: MacroMathInput;
  readonly pro: MacroMathInput;
  readonly fat: MacroMathInput;
  readonly weightTarget: Maybe<Scalars['Float']>;
  readonly anthropometryEntryId: Scalars['ID'];
  readonly calorieBudget: CalorieBudgetInput;
  readonly goal: GoalFormInput;
};

export type MacroProtocolWithOldGoalInput = {
  readonly cho: MacroMathInput;
  readonly pro: MacroMathInput;
  readonly fat: MacroMathInput;
  readonly weightTarget: Maybe<Scalars['Float']>;
  readonly anthropometryEntryId: Scalars['ID'];
  readonly calorieBudget: CalorieBudgetInput;
  readonly goalId: Scalars['ID'];
};

export type MacroType =
  | 'cho'
  | 'fat'
  | 'pro'
  | '%future added value';

export type Macros = {
  readonly cho: Scalars['Float'];
  readonly pro: Scalars['Float'];
  readonly fat: Scalars['Float'];
  readonly kcal: Scalars['Float'];
};

export type MacrosAndKcals = {
  readonly cho: Scalars['Float'];
  readonly choKcal: Scalars['Float'];
  readonly pro: Scalars['Float'];
  readonly proKcal: Scalars['Float'];
  readonly fat: Scalars['Float'];
  readonly fatKcal: Scalars['Float'];
  readonly kcal: Scalars['Float'];
};

export type MacrosInput = {
  readonly cho: Scalars['Float'];
  readonly pro: Scalars['Float'];
  readonly fat: Scalars['Float'];
};

export type MarkAllNotificationsAsViewedPayload = {
  readonly notifications: ReadonlyArray<Notification>;
};

export type MarkNotificationsAsViewedBeforeInput = {
  readonly before: Scalars['DateTime'];
};

export type MarkNotificationsAsViewedBeforePayload = {
  readonly notifications: ReadonlyArray<Notification>;
};

export type MarkNotificationsAsViewedInput = {
  readonly notificationIds: ReadonlyArray<Scalars['ID']>;
};

export type MarkNotificationsAsViewedPayload = {
  readonly notifications: ReadonlyArray<Notification>;
};

export type Meal = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly type: MealType;
  readonly start: Scalars['Time'];
  readonly end: Scalars['Time'];
};

export type MealEvent = {
  readonly id: Scalars['ID'];
  readonly datetime: Scalars['DateTime'];
  readonly timezone: Scalars['String'];
  readonly mealType: MealType;
  readonly totalMacros: Macros;
  readonly targetMacros: Macros;
  readonly nutrientAmounts: ReadonlyArray<NutrientAmount>;
  readonly mealMenuInfo: ReadonlyArray<MealMenuInfo>;
};

export type MealMenu = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly type: MealType;
  readonly start: Scalars['DateTime'];
  readonly timezone: Scalars['Timezone'];
  readonly durationInMinutes: Scalars['Int'];
  readonly prepTimeInMinutes: Scalars['Int'];
  readonly lastOrderTimeBeforeEndInMinutes: Scalars['Int'];
  readonly notificationSentBeforeOrderDueInMinutes: Maybe<Scalars['Int']>;
  readonly mealMenuDiningStations: ReadonlyArray<MealMenuDiningStation>;
  readonly orderRateLimit: Maybe<MenuOrderRateLimit>;
  readonly userOrderLimit: Maybe<Scalars['Int']>;
  readonly isLogOnly: Scalars['Boolean'];
  readonly isInHouseMenuOrderable: Scalars['Boolean'];
  readonly isRestaurantMenuOrderable: Scalars['Boolean'];
  readonly isBulkOrderable: Scalars['Boolean'];
  readonly isOrderable: Scalars['Boolean'];
  readonly identity: Maybe<MealMenuIdentity>;
  readonly restaurantMenuLinks: ReadonlyArray<RestaurantMenuLink>;
  readonly isHubCheckInEnabled: Scalars['Boolean'];
  readonly hubEventUrl: Maybe<Scalars['String']>;
  readonly isOrderAndLogRestricted: Scalars['Boolean'];
  readonly isViewOnlyForMe: Scalars['Boolean'];
  readonly teams: ReadonlyArray<Team>;
  readonly advancedSelection: Maybe<AdvancedSelectionType>;
  readonly hasAdvancedSelection: Scalars['Boolean'];
  readonly athleteCount: Scalars['Int'];
  readonly athletes: ReadonlyArray<Athlete>;
  readonly allOrders: ReadonlyArray<MenuOrder>;
  readonly allMealMenuPlateOrders: ReadonlyArray<MealMenuPlateOrder>;
  /** @deprecated pending forced update for multiple MenuOrders per menu */
  readonly order: Maybe<MenuOrder>;
  readonly orders: ReadonlyArray<MenuOrder>;
  readonly ordersForUser: ReadonlyArray<MenuOrder>;
  readonly ordersForAthlete: ReadonlyArray<MenuOrder>;
  readonly logs: ReadonlyArray<MealMenuLog>;
  readonly logsForAthlete: ReadonlyArray<MealMenuLog>;
  readonly theme: Maybe<Scalars['String']>;
};


export type MealMenuOrdersForUserArgs = {
  userId: Scalars['ID'];
};


export type MealMenuOrdersForAthleteArgs = {
  athleteId: Scalars['ID'];
};


export type MealMenuLogsForAthleteArgs = {
  athleteId: Scalars['ID'];
};

export type MealMenuAttendeeNonNotemealAthlete = {
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly checkedIn: Scalars['Boolean'];
  readonly checkInDisplayDate: Maybe<Scalars['String']>;
  readonly hasAccess: Maybe<Scalars['Boolean']>;
};

export type MealMenuAttendeeNotemealAthlete = {
  readonly athlete: Athlete;
  readonly checkedIn: Scalars['Boolean'];
  readonly checkInDisplayDate: Maybe<Scalars['String']>;
  readonly hasAccess: Maybe<Scalars['Boolean']>;
};

export type MealMenuAttendeePayload = {
  readonly notemealAttendees: ReadonlyArray<MealMenuAttendeeNotemealAthlete>;
  readonly nonNotemealAttendees: ReadonlyArray<MealMenuAttendeeNonNotemealAthlete>;
  readonly queryCompletedAt: Scalars['DateTime'];
};

export type MealMenuAvailableNotification = Notification & {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly mealMenu: Maybe<StandaloneMealMenu>;
};

export type MealMenuCheckIn = {
  readonly id: Scalars['ID'];
  readonly mealMenuId: Scalars['ID'];
  readonly athleteId: Scalars['ID'];
};

export type MealMenuDiningStation = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly position: Scalars['Int'];
  readonly maxAmount: Maybe<Scalars['Int']>;
  readonly diningStationTemplate: Maybe<DiningStationTemplate>;
  /** @deprecated includeLogOnlyItems not used on back-end anymore, can remove on next mobile force update */
  readonly menuItemAppearances: ReadonlyArray<MenuItemAppearance>;
};


export type MealMenuDiningStationMenuItemAppearancesArgs = {
  includeLogOnlyItems: Maybe<Scalars['Boolean']>;
};

export type MealMenuIdentity = {
  readonly id: Scalars['ID'];
  readonly isOverridden: Scalars['Boolean'];
};

export type MealMenuInfo = {
  readonly name: Maybe<Scalars['String']>;
  readonly usedToOrder: Scalars['Boolean'];
};

export type MealMenuIsSharedNotification = Notification & {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly mealPlan: Maybe<MealPlan>;
};

export type MealMenuLog = MenuSelection & {
  readonly id: Scalars['ID'];
  readonly user: User;
  readonly mealMenu: MealMenu;
  readonly items: ReadonlyArray<MealMenuLogItem>;
};

export type MealMenuLogItem = MenuSelectionItem & {
  readonly id: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly percentConsumed: Maybe<Scalars['Float']>;
  readonly menuItem: MenuItem;
  readonly options: ReadonlyArray<MealMenuLogItemOption>;
  readonly log: MealMenuLog;
};

export type MealMenuLogItemInput = {
  readonly menuItemId: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly options: ReadonlyArray<MealMenuLogItemOptionInput>;
};

export type MealMenuLogItemOption = MenuSelectionItemOption & {
  readonly amount: Scalars['Float'];
  readonly percentConsumed: Maybe<Scalars['Float']>;
  readonly menuItemChoiceOption: MenuItemChoiceOption;
};

export type MealMenuLogItemOptionInput = {
  readonly amount: Scalars['Float'];
  readonly menuItemChoiceOptionId: Scalars['ID'];
};

export type MealMenuOrderDueNotification = Notification & {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly mealMenu: Maybe<StandaloneMealMenu>;
};

export type MealMenuPlate = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly parentBulkOrder: BulkOrder;
  readonly mealMenuPlateOrders: ReadonlyArray<MealMenuPlateOrder>;
  readonly orderCount: Scalars['Int'];
  readonly plateItems: ReadonlyArray<MealMenuPlateItem>;
};

export type MealMenuPlateItem = {
  readonly id: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly menuItem: MenuItem;
  readonly options: ReadonlyArray<MealMenuPlateItemOption>;
  readonly mealMenuPlate: MealMenuPlate;
  readonly specialRequests: Maybe<Scalars['String']>;
};

export type MealMenuPlateItemOption = {
  readonly amount: Scalars['Float'];
  readonly mealMenuPlateItem: MealMenuPlateItem;
  readonly menuItemChoiceOption: MenuItemChoiceOption;
};

export type MealMenuPlateItemOptionInput = {
  readonly amount: Scalars['Float'];
  readonly menuItemChoiceOptionId: Scalars['ID'];
};

export type MealMenuPlateItemOrderStatus = {
  readonly status: MenuOrderItemStatus;
  readonly mealMenuPlateOrder: MealMenuPlateOrder;
  readonly mealMenuPlateItem: MealMenuPlateItem;
};

export type MealMenuPlateItemOrderStatusPublication = {
  readonly scope: MealMenuPlateItemOrderStatusPublisherScope;
  readonly payload: UpdateMultipleMealMenuPlateItemOrderStatusesPayload;
};

export type MealMenuPlateItemOrderStatusPublisherScope = {
  readonly mealMenuId: Scalars['ID'];
};

export type MealMenuPlateItemOrderStatusSubscriberScope = {
  readonly mealMenuIds: ReadonlyArray<Scalars['ID']>;
};

export type MealMenuPlateOrder = MenuSelection & {
  readonly id: Scalars['ID'];
  readonly user: User;
  readonly userFullName: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly code: Scalars['String'];
  readonly parentBulkOrder: BulkOrder;
  readonly plate: MealMenuPlate;
  readonly items: ReadonlyArray<MealMenuPlateOrderItem>;
  readonly itemCount: Scalars['Int'];
  readonly itemsByDiningStation: ReadonlyArray<MealMenuPlateOrderItem>;
  readonly timelineMeal: Maybe<TimelineMeal>;
  readonly updateCount: Scalars['Int'];
  readonly updatedAt: Scalars['DateTime'];
  readonly status: Scalars['String'];
  readonly athlete: Maybe<Athlete>;
  readonly mealMenu: MealMenu;
};


export type MealMenuPlateOrderItemsByDiningStationArgs = {
  diningStationIds: ReadonlyArray<Scalars['ID']>;
};

export type MealMenuPlateOrderCursorConnection = {
  readonly edges: ReadonlyArray<MealMenuPlateOrder>;
  readonly pageInfo: CursorPageInfo;
};

export type MealMenuPlateOrderItem = MenuSelectionItem & {
  readonly id: Scalars['String'];
  readonly amount: Scalars['Float'];
  readonly percentConsumed: Maybe<Scalars['Float']>;
  readonly menuItem: MenuItem;
  readonly options: ReadonlyArray<MealMenuPlateOrderItemOption>;
  readonly mealMenuPlate: MealMenuPlate;
  readonly specialRequests: Maybe<Scalars['String']>;
  readonly updateCount: Scalars['Int'];
  readonly updatedAt: Scalars['DateTime'];
  readonly status: MenuOrderItemStatus;
  readonly rating: Maybe<Rating>;
};

export type MealMenuPlateOrderItemOption = MenuSelectionItemOption & {
  readonly amount: Scalars['Float'];
  readonly percentConsumed: Maybe<Scalars['Float']>;
  readonly mealMenuPlateItem: MealMenuPlateItem;
  readonly menuItemChoiceOption: MenuItemChoiceOption;
};

export type MealMenuUserOrderLimitInput = {
  readonly limit: Maybe<Scalars['Int']>;
};

export type MealMenusCursorConnection = {
  readonly edges: ReadonlyArray<MealMenu>;
  readonly pageInfo: CursorPageInfo;
};

export type MealMenusWithCheckinPayload = {
  readonly mealMenu: StandaloneMealMenu;
  readonly mealMenuExpectedAttendeeCount: Scalars['Int'];
  readonly mealMenuActualAttendeeCount: Scalars['Int'];
  readonly attendeeUnlistedCount: Scalars['Int'];
  readonly attendeeListedCount: Scalars['Int'];
};

export type MealOption = {
  readonly id: Scalars['ID'];
  readonly ingredientText: Scalars['String'];
  readonly isAutosaving: Scalars['Boolean'];
  readonly mealTemplate: Maybe<MealTemplate>;
  readonly name: Maybe<Scalars['String']>;
  readonly note: Maybe<Scalars['String']>;
  readonly position: Scalars['Int'];
  readonly servingAmounts: ReadonlyArray<ServingAmount>;
};

export type MealOptionCursorConnection = {
  readonly edges: ReadonlyArray<MealOption>;
  readonly pageInfo: CursorPageInfo;
};

export type MealOptionInput = {
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
  readonly position: Scalars['Int'];
  readonly note: Maybe<Scalars['String']>;
  readonly name: Maybe<Scalars['String']>;
};

export type MealOptionPending = {
  readonly id: Scalars['ID'];
  readonly ingredientText: Scalars['String'];
  readonly isAutosaving: Scalars['Boolean'];
  readonly mealTemplate: Maybe<MealTemplate>;
  readonly name: Maybe<Scalars['String']>;
  readonly note: Maybe<Scalars['String']>;
  readonly position: Scalars['Int'];
  readonly servingAmounts: ReadonlyArray<ServingAmount>;
};

export type MealOptionPendingInput = {
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
  readonly note: Maybe<Scalars['String']>;
  readonly name: Maybe<Scalars['String']>;
};

export type MealPlan = {
  readonly activityTemplates: ReadonlyArray<ActivityTemplate>;
  readonly athlete: Athlete;
  readonly avoidedFoodGroups: ReadonlyArray<FoodGroup>;
  readonly avoidedFoods: ReadonlyArray<Food>;
  readonly createdAt: Scalars['DateTime'];
  /** @deprecated Field no longer supported */
  readonly dates: Maybe<ReadonlyArray<Scalars['Date']>>;
  readonly daysOfWeek: ReadonlyArray<MealPlanDayOfWeekAssignment>;
  readonly description: Maybe<Scalars['String']>;
  readonly endDate: Maybe<Scalars['Date']>;
  readonly events: ReadonlyArray<Event>;
  readonly exchangeSet: Maybe<ExchangeSet>;
  readonly exchangeTargets: Maybe<ReadonlyArray<ExchangeAmount>>;
  readonly hasIndividualDateAssignments: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly individualDatesInRange: ReadonlyArray<Scalars['Date']>;
  readonly isAutoSuggestionsEnabled: Scalars['Boolean'];
  readonly isAutosaving: Scalars['Boolean'];
  readonly isShared: Scalars['Boolean'];
  readonly macroProtocol: MacroProtocol;
  readonly mealTemplates: ReadonlyArray<MealTemplate>;
  readonly modificationsInRange: ReadonlyArray<MealPlanDateModifications>;
  readonly mostRecentEvent: Maybe<Event>;
  readonly name: Scalars['String'];
  readonly promotedFoodGroups: ReadonlyArray<FoodGroup>;
  readonly promotedFoods: ReadonlyArray<Food>;
  readonly schedule: Maybe<Schedule>;
  readonly startDate: Maybe<Scalars['Date']>;
  readonly type: MealPlanType;
  readonly updatedAt: Scalars['DateTime'];
  /** @deprecated Field no longer supported */
  readonly usesDateRange: Scalars['Boolean'];
  readonly wasCreatedFromMealPlanTemplate: Scalars['Boolean'];
};


export type MealPlanIndividualDatesInRangeArgs = {
  start: Scalars['Date'];
  end: Scalars['Date'];
};


export type MealPlanModificationsInRangeArgs = {
  start: Scalars['Date'];
  end: Scalars['Date'];
};

export type MealPlanCreateFormInput = {
  readonly type: MealPlanType;
  readonly exchangeSetId: Maybe<Scalars['String']>;
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly usesDateRange: Scalars['Boolean'];
  readonly dates: Maybe<ReadonlyArray<Scalars['Date']>>;
  readonly startDate: Maybe<Scalars['Date']>;
  readonly endDate: Maybe<Scalars['Date']>;
  readonly handleDatesConflict: Scalars['Boolean'];
  readonly isShared: Scalars['Boolean'];
};

export type MealPlanCursorConnection = {
  readonly edges: ReadonlyArray<MealPlan>;
  readonly pageInfo: CursorPageInfo;
};

export type MealPlanDate = {
  readonly mealPlan: MealPlan;
  readonly date: Scalars['Date'];
  readonly timezone: Scalars['Timezone'];
};

/**
 * Entity that combines individual date and day of week assignments to answer questions
 * 1. What MealPlan is assigned to a date?
 * 2. How is the MealPlan assigned to a date (via individual date or day of week)?
 * Without performing all of the resolution logic in on the frontend
 */
export type MealPlanDateAssignment = {
  readonly date: Scalars['Date'];
  readonly mealPlan: MealPlan;
  readonly dayOfWeekPriority: Maybe<DayOfWeekPriority>;
  readonly mealTemplateModifications: ReadonlyArray<MealTemplateModification>;
  readonly activityTemplateModifications: ReadonlyArray<ActivityTemplateModification>;
};

export type MealPlanDateModificationInput = {
  readonly date: Scalars['Date'];
  readonly start: Scalars['Time'];
  readonly end: Scalars['Time'];
};

export type MealPlanDateModifications = {
  readonly date: Scalars['Date'];
  readonly mealTemplateModifications: ReadonlyArray<MealTemplateModification>;
  readonly activityTemplateModifications: ReadonlyArray<ActivityTemplateModification>;
};

export type MealPlanDayOfWeekAssignment = {
  readonly mealPlan: MealPlan;
  readonly dayOfWeekPriority: DayOfWeekPriority;
  readonly mealTemplateModifications: ReadonlyArray<MealTemplateModification>;
  readonly activityTemplateModifications: ReadonlyArray<ActivityTemplateModification>;
};

export type MealPlanDayOfWeekModificationInput = {
  readonly dayOfWeek: DayOfWeek;
  readonly start: Scalars['Time'];
  readonly end: Scalars['Time'];
};

export type MealPlanEditFormInput = {
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly usesDateRange: Scalars['Boolean'];
  readonly dates: Maybe<ReadonlyArray<Scalars['Date']>>;
  readonly startDate: Maybe<Scalars['Date']>;
  readonly endDate: Maybe<Scalars['Date']>;
  readonly handleDatesConflict: Scalars['Boolean'];
  readonly isShared: Scalars['Boolean'];
  readonly type: MealPlanType;
};

export type MealPlanFromTemplateAnthropometryInput = {
  readonly existingAnthropometryEntryId: Maybe<Scalars['ID']>;
  readonly anthropometryEntryInput: Maybe<AnthropometryEntryFormInput>;
};

export type MealPlanFromTemplateMetricAnthropometryInput = {
  readonly existingAnthropometryEntryId: Maybe<Scalars['ID']>;
  readonly anthropometryEntryInput: Maybe<MetricAnthropometryEntryFormInput>;
};

export type MealPlanIndividualDateAssignment = {
  readonly date: Scalars['Date'];
  readonly mealPlan: MealPlan;
};

export type MealPlanOffsetConnection = {
  readonly edges: ReadonlyArray<MealPlan>;
  readonly pageInfo: OffsetPageInfo;
};

export type MealPlanSharedNotification = Notification & {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly mealPlan: Maybe<MealPlan>;
};

export type MealPlanTemplate = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly updatedAt: Scalars['DateTime'];
  readonly owner: User;
  /** @deprecated Field no longer supported */
  readonly orgId: Maybe<Scalars['ID']>;
  /** @deprecated Field no longer supported */
  readonly orgGroupId: Maybe<Scalars['ID']>;
  readonly teams: Maybe<ReadonlyArray<Team>>;
  readonly advancedSelection: Maybe<AdvancedSelectionType>;
  readonly hasAdvancedSelection: Scalars['Boolean'];
  readonly isShared: Scalars['Boolean'];
  readonly copiedCount: Scalars['Int'];
  readonly avoidedFoodGroups: ReadonlyArray<FoodGroup>;
  readonly promotedFoodGroups: ReadonlyArray<FoodGroup>;
  readonly avoidedFoods: ReadonlyArray<Food>;
  readonly promotedFoods: ReadonlyArray<Food>;
  readonly macroProtocol: MealPlanTemplateMacroProtocol;
  readonly meals: ReadonlyArray<MealPlanTemplateMeal>;
  readonly activities: ReadonlyArray<MealPlanTemplateActivity>;
  readonly pendingState: Maybe<Scalars['String']>;
  /**
   * This field stores values the user entered in the UI but not required for creating a meal plan from a meal plan template.
   * The sample data the user used is stored.  When the user turns off calorie budget we also save it here so things feel familiar
   * if they decide to turn it back on.
   */
  readonly additionalState: Maybe<Scalars['String']>;
};

export type MealPlanTemplateActivity = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly type: ActivityType;
  readonly start: Scalars['Time'];
  readonly end: Scalars['Time'];
};

export type MealPlanTemplateActivityInput = {
  readonly name: Scalars['String'];
  readonly type: ActivityType;
  readonly start: Scalars['Time'];
  readonly end: Scalars['Time'];
};

export type MealPlanTemplateAnthropometry = AnthropometryTemplate | AnthropometryForm | { readonly __typename?: "%other" };

export type MealPlanTemplateAnthropometryInput = {
  readonly formFields: Maybe<ReadonlyArray<AnthropometryFormField>>;
  readonly template: Maybe<AnthropometryTemplateInput>;
};

export type MealPlanTemplateCalorieBudget = {
  readonly id: Scalars['ID'];
  readonly rmrMethod: RmrMethod;
  readonly activityFactor: Scalars['Float'];
  readonly goals: ReadonlyArray<MealPlanTemplateGoal>;
};

export type MealPlanTemplateCalorieBudgetInput = {
  readonly rmrMethod: RmrMethod;
  readonly activityFactor: Scalars['Float'];
  readonly goals: ReadonlyArray<MealPlanTemplateGoalInput>;
};

export type MealPlanTemplateCursorConnection = {
  readonly edges: ReadonlyArray<MealPlanTemplate>;
  readonly pageInfo: CursorPageInfo;
};

export type MealPlanTemplateGoal = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly kcalOffset: Scalars['Int'];
  readonly goalType: GoalType;
};

export type MealPlanTemplateGoalInput = {
  readonly name: Scalars['String'];
  readonly kcalOffset: Scalars['Int'];
  readonly goalTypeId: Scalars['ID'];
};

export type MealPlanTemplateMacroProtocol = {
  readonly id: Scalars['ID'];
  readonly cho: MacroMath;
  readonly pro: MacroMath;
  readonly fat: MacroMath;
  readonly useExistingAnthropometry: Scalars['Boolean'];
  readonly anthropometry: MealPlanTemplateAnthropometry;
  readonly calorieBudget: Maybe<MealPlanTemplateCalorieBudget>;
};

export type MealPlanTemplateMacroProtocolInput = {
  readonly cho: MacroMathInput;
  readonly pro: MacroMathInput;
  readonly fat: MacroMathInput;
  readonly useExistingAnthropometry: Scalars['Boolean'];
  readonly anthropometry: MealPlanTemplateAnthropometryInput;
  readonly calorieBudget: Maybe<MealPlanTemplateCalorieBudgetInput>;
};

export type MealPlanTemplateMeal = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly type: MealType;
  readonly start: Scalars['Time'];
  readonly end: Scalars['Time'];
  readonly mealOptions: ReadonlyArray<MealPlanTemplateMealOption>;
};

export type MealPlanTemplateMealOption = {
  readonly id: Scalars['ID'];
  readonly position: Scalars['Int'];
  readonly note: Maybe<Scalars['String']>;
  readonly servingAmounts: ReadonlyArray<ServingAmount>;
  readonly name: Maybe<Scalars['String']>;
};

export type MealPlanTemplateMealOptionInput = {
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
  readonly position: Scalars['Int'];
  readonly note: Maybe<Scalars['String']>;
  readonly name: Maybe<Scalars['String']>;
};

export type MealPlanTemplateOffsetConnection = {
  readonly edges: ReadonlyArray<MealPlanTemplate>;
  readonly pageInfo: OffsetPageInfo;
};

export type MealPlanType =
  | 'macro'
  | 'exchange'
  | '%future added value';

export type MealTemplate = {
  readonly hasDateModifications: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly isAutosaving: Scalars['Boolean'];
  readonly meal: Meal;
  readonly mealOptionCount: Scalars['Int'];
  readonly mealOptions: ReadonlyArray<MealOption>;
  readonly mealPlan: MealPlan;
  readonly newMealOptionIds: ReadonlyArray<Scalars['ID']>;
  readonly pendingMealOptions: ReadonlyArray<MealOptionPending>;
  readonly selectedMealOptionId: Maybe<Scalars['ID']>;
};


export type MealTemplateHasDateModificationsArgs = {
  after: Scalars['Date'];
};

/**
 * Modification of a MealTemplate, tied to either an individual date or a day of the week
 * Currently only time edits (start and end) are supported
 */
export type MealTemplateModification = {
  readonly mealTemplate: MealTemplate;
  readonly start: Scalars['Time'];
  readonly end: Scalars['Time'];
};

export type MealType =
  | 'breakfast'
  | 'lunch'
  | 'dinner'
  | 'snack'
  | 'meal'
  | '%future added value';

export type MenuItem = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly servingName: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly isOneOff: Scalars['Boolean'];
  readonly createdAt: Scalars['DateTime'];
  readonly defaultAvailableForOrder: Scalars['Boolean'];
  readonly defaultMaxAmount: Maybe<Scalars['Int']>;
  readonly suggestionCategory: Maybe<MenuItemSuggestionCategory>;
  readonly ratingCounts: RatingCounts;
  readonly isCurrent: Scalars['Boolean'];
  readonly isDeleted: Scalars['Boolean'];
  readonly orderItemCount: Scalars['Int'];
  readonly isMissingIngredients: Scalars['Boolean'];
  readonly servingAmounts: ReadonlyArray<ServingAmount>;
  readonly servingAmountsCount: Scalars['Int'];
  readonly choices: ReadonlyArray<MenuItemChoice>;
  readonly history: MenuItemHistory;
  readonly imageUrl: Maybe<Scalars['String']>;
  readonly defaultAllowSpecialRequests: Scalars['Boolean'];
  readonly score: Maybe<Score>;
  readonly foodCategory: Maybe<MenuItemFoodCategory>;
  /** @deprecated Field no longer supported */
  readonly isAiGenerated: Scalars['Boolean'];
  readonly aiAugmented: AiAugmented;
};

export type MenuItemAmount = MenuSelectionItem & {
  readonly menuItem: MenuItem;
  readonly amount: Scalars['Float'];
  readonly options: ReadonlyArray<MenuItemChoiceOptionAmount>;
  readonly percentConsumed: Maybe<Scalars['Float']>;
};

export type MenuItemAmountInput = {
  readonly menuItemId: Scalars['ID'];
  readonly amount: Scalars['Int'];
  readonly options: ReadonlyArray<MenuItemChoiceOptionAmountInput>;
};

export type MenuItemAppearance = {
  readonly id: Scalars['ID'];
  readonly menuItem: MenuItem;
  readonly position: Scalars['Int'];
  readonly availableForOrder: Scalars['Boolean'];
  readonly maxAmount: Maybe<Scalars['Int']>;
  readonly allowSpecialRequests: Scalars['Boolean'];
};

export type MenuItemAppearanceInput = {
  readonly position: Scalars['Int'];
  readonly availableForOrder: Scalars['Boolean'];
  readonly maxAmount: Maybe<Scalars['Int']>;
  readonly allowSpecialRequests: Scalars['Boolean'];
};

export type MenuItemChoice = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly position: Scalars['Int'];
  readonly maxOptionsCount: Maybe<Scalars['Int']>;
  readonly required: Scalars['Boolean'];
  readonly options: ReadonlyArray<MenuItemChoiceOption>;
};

export type MenuItemChoiceOption = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly position: Scalars['Int'];
  readonly canEditAmount: Scalars['Boolean'];
  readonly defaultAmount: Scalars['Float'];
  readonly maxAmount: Scalars['Float'];
  readonly servingAmounts: ReadonlyArray<ServingAmount>;
};

export type MenuItemChoiceOptionAmount = MenuSelectionItemOption & {
  readonly menuItemChoiceOption: MenuItemChoiceOption;
  readonly amount: Scalars['Float'];
  readonly percentConsumed: Maybe<Scalars['Float']>;
};

export type MenuItemChoiceOptionAmountInput = {
  readonly menuItemChoiceOptionId: Scalars['ID'];
  readonly amount: Scalars['Int'];
};

export type MenuItemChoiceOptionMissingIngredientsInput = {
  readonly menuItemChoiceOptionId: Scalars['ID'];
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
};

export type MenuItemCursorConnection = {
  readonly edges: ReadonlyArray<MenuItem>;
  readonly pageInfo: CursorPageInfo;
};

export type MenuItemFoodCategory = {
  readonly category: FoodCategoryType;
};

export type MenuItemHistory = {
  readonly id: Scalars['ID'];
  readonly current: MenuItem;
  readonly all: ReadonlyArray<MenuItem>;
  readonly ratingCounts: RatingCounts;
};

export type MenuItemMissingIngredientsInput = {
  readonly menuItemId: Scalars['ID'];
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
  readonly menuItemChoiceOptions: ReadonlyArray<MenuItemChoiceOptionMissingIngredientsInput>;
};

export type MenuItemOffsetConnection = {
  readonly edges: ReadonlyArray<MenuItem>;
  readonly pageInfo: OffsetPageInfo;
};

export type MenuItemRating = Rating & {
  readonly id: Scalars['ID'];
  readonly createdAt: Scalars['DateTime'];
  readonly value: RatingValue;
  readonly review: Maybe<Scalars['String']>;
  readonly createdBy: Maybe<User>;
  readonly menuOrderItem: Maybe<MenuOrderItem>;
  readonly menuItem: MenuItem;
};

export type MenuItemRatingOffsetConnection = {
  readonly edges: ReadonlyArray<Rating>;
  readonly pageInfo: OffsetPageInfo;
};

export type MenuItemSuggestionCategory =
  | 'combo'
  | 'main'
  | 'protein'
  | 'starch'
  | 'vegetable'
  | 'fruit'
  | 'other'
  | '%future added value';

export type MenuOrder = MenuSelection & {
  readonly id: Scalars['ID'];
  readonly user: User;
  readonly pickupTime: Scalars['DateTime'];
  readonly code: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly mealMenu: MealMenu;
  readonly items: ReadonlyArray<MenuOrderItem>;
  readonly itemCount: Scalars['Int'];
  readonly itemsByDiningStation: ReadonlyArray<MenuOrderItem>;
  readonly athlete: Maybe<Athlete>;
  readonly userFullName: Scalars['String'];
  readonly mealTemplate: Maybe<MealTemplate>;
  readonly comments: ReadonlyArray<Comment>;
  readonly images: ReadonlyArray<Image>;
  readonly updateCount: Scalars['Int'];
  readonly updatedAt: Scalars['DateTime'];
  readonly timelineMeal: Maybe<TimelineMeal>;
};


export type MenuOrderItemsByDiningStationArgs = {
  diningStationIds: ReadonlyArray<Scalars['ID']>;
};

export type MenuOrderCommentNotification = Notification & {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
  readonly menuOrder: Maybe<MenuOrder>;
  readonly timelineMeal: Maybe<TimelineMeal>;
};

export type MenuOrderCursorConnection = {
  readonly edges: ReadonlyArray<MenuOrder>;
  readonly pageInfo: CursorPageInfo;
};

export type MenuOrderImageNotification = Notification & {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
  readonly menuOrder: Maybe<MenuOrder>;
  readonly timelineMeal: Maybe<TimelineMeal>;
  readonly image: Maybe<Image>;
};

export type MenuOrderInfo = {
  readonly mealMenuId: Scalars['ID'];
  readonly menuOrderCount: Scalars['Int'];
  readonly mostRecentEditableMenuOrderId: Maybe<Scalars['ID']>;
};

export type MenuOrderItem = MenuSelectionItem & {
  readonly id: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly percentConsumed: Maybe<Scalars['Float']>;
  readonly forOrder: Scalars['Boolean'];
  readonly excludeLogItems: Maybe<Scalars['Boolean']>;
  readonly status: MenuOrderItemStatus;
  readonly menuItem: MenuItem;
  readonly options: ReadonlyArray<MenuOrderItemOption>;
  readonly order: MenuOrder;
  readonly updateCount: Scalars['Int'];
  readonly updatedAt: Scalars['DateTime'];
  readonly rating: Maybe<Rating>;
  readonly specialRequests: Maybe<Scalars['String']>;
};

export type MenuOrderItemInput = {
  readonly menuItemId: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly specialRequests: Maybe<Scalars['String']>;
  readonly options: ReadonlyArray<MenuOrderItemOptionInput>;
};

export type MenuOrderItemOption = MenuSelectionItemOption & {
  readonly amount: Scalars['Float'];
  readonly percentConsumed: Maybe<Scalars['Float']>;
  readonly menuItemChoiceOption: MenuItemChoiceOption;
};

export type MenuOrderItemOptionInput = {
  readonly amount: Scalars['Float'];
  readonly menuItemChoiceOptionId: Scalars['ID'];
};

export type MenuOrderItemStatus =
  | 'new'
  | 'cooking'
  | 'done'
  | 'cancelled'
  | '%future added value';

export type MenuOrderItemStatusesPublication = {
  readonly scope: MenuOrderItemStatusesPublisherScope;
  readonly payload: UpdateMenuOrderItemStatusesPayload;
};

export type MenuOrderItemStatusesPublisherScope = {
  readonly mealMenuId: Scalars['ID'];
};

export type MenuOrderItemStatusesSubscriberScope = {
  readonly mealMenuIds: Maybe<ReadonlyArray<Scalars['ID']>>;
};

export type MenuOrderPublication = {
  readonly scope: MenuOrderPublisherScope;
  readonly payload: MenuOrderPublicationPayload;
};

export type MenuOrderPublicationPayload = AddMenuOrderPayload | EditMenuOrderPayload | RemoveMenuOrderPayload | AddMenuOrderCommentPayload | { readonly __typename?: "%other" };

export type MenuOrderPublisherScope = {
  readonly mealMenuId: Scalars['ID'];
};

export type MenuOrderRateLimit = {
  readonly limit: Scalars['Int'];
  readonly excludedTeamIds: ReadonlyArray<Scalars['ID']>;
};

export type MenuOrderRateLimitInput = {
  readonly limit: Scalars['Int'];
  readonly excludedTeamIds: ReadonlyArray<Scalars['ID']>;
};

export type MenuOrderRateLimitRemainder = {
  readonly remaining: Scalars['Int'];
  readonly start: Scalars['DateTime'];
};

export type MenuOrderStatusNotification = Notification & {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly menuOrder: Maybe<MenuOrder>;
  readonly timelineMeal: Maybe<TimelineMeal>;
};

export type MenuOrderSubscriberScope = {
  readonly mealMenuIds: Maybe<ReadonlyArray<Scalars['ID']>>;
};

export type MenuOrderSuggestion = MenuSelection & {
  readonly items: ReadonlyArray<MenuItemAmount>;
};

export type MenuOrderSuggestionInput = {
  readonly items: ReadonlyArray<MenuItemAmountInput>;
};

export type MenuSelection = {
  readonly items: ReadonlyArray<MenuSelectionItem>;
};

export type MenuSelectionItem = {
  readonly amount: Scalars['Float'];
  readonly percentConsumed: Maybe<Scalars['Float']>;
  readonly menuItem: MenuItem;
  readonly options: ReadonlyArray<MenuSelectionItemOption>;
};

export type MenuSelectionItemOption = {
  readonly amount: Scalars['Float'];
  readonly percentConsumed: Maybe<Scalars['Float']>;
  readonly menuItemChoiceOption: MenuItemChoiceOption;
};

export type MessageOption = {
  readonly id: Scalars['ID'];
  readonly userId: Scalars['ID'];
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly teamName: Scalars['String'];
};

export type MessageOptionCursorConnection = {
  readonly edges: ReadonlyArray<MessageOption>;
  readonly pageInfo: CursorPageInfo;
};

export type MetricAnthropometryEntryFormInput = {
  readonly heightInCm: Maybe<Scalars['Float']>;
  readonly weightInKg: Scalars['Float'];
  readonly leanBodyMassInKg: Maybe<Scalars['Float']>;
  readonly bodyFatMassInKg: Maybe<Scalars['Float']>;
  readonly skeletalMuscleMassInKg: Maybe<Scalars['Float']>;
  readonly trunkFatInKg: Maybe<Scalars['Float']>;
  readonly dryLeanMassInKg: Maybe<Scalars['Float']>;
  readonly percentBodyFat: Maybe<Scalars['Float']>;
  readonly visceralFatArea: Maybe<Scalars['Float']>;
  readonly datetime: Scalars['DateTime'];
  readonly sex: SexType;
  readonly type: AnthropometryEntryType;
  readonly comment: Maybe<Scalars['String']>;
  readonly boneMineralDensity: Maybe<Scalars['Float']>;
  readonly boneMass: Maybe<Scalars['Float']>;
  readonly boneMineralContent: Maybe<Scalars['Float']>;
  readonly boneArea: Maybe<Scalars['Float']>;
  readonly boneMineralDensityZScore: Maybe<Scalars['Float']>;
  readonly boneMineralDensityTScore: Maybe<Scalars['Float']>;
};

export type MetricMacroProtocolInput = {
  readonly cho: MacroMathInput;
  readonly pro: MacroMathInput;
  readonly fat: MacroMathInput;
  readonly weightTargetInKg: Maybe<Scalars['Float']>;
  readonly anthropometryEntryId: Scalars['ID'];
  readonly calorieBudget: Maybe<CalorieBudgetInput>;
  readonly goalId: Maybe<Scalars['ID']>;
  readonly goal: Maybe<GoalFormInput>;
};

export type MobileUpdateType =
  | 'force'
  | 'suggest'
  | '%future added value';

export type MoveMacroMealPlanTemplateToOrgGroupInput = {
  readonly macroMealPlanTemplateId: Scalars['ID'];
  readonly orgGroupId: Scalars['ID'];
};

export type MoveMacroMealPlanTemplateToOrgGroupPayload = {
  readonly macroMealPlanTemplate: MacroMealPlanTemplate;
};

export type MoveMenuItemInput = {
  readonly menuItemId: Scalars['ID'];
  readonly appearance: MenuItemAppearanceInput;
};

export type MoveOrgFoodToOrgGroupInput = {
  readonly id: Scalars['ID'];
  readonly orgGroupId: Scalars['ID'];
};

export type MoveOrgFoodToOrgGroupPayload = {
  readonly food: Food;
};

export type MoveOrgGroupFoodToMyOrgInput = {
  readonly id: Scalars['ID'];
};

export type MoveOrgGroupFoodToMyOrgPayload = {
  readonly food: Food;
};

export type MoveOrgGroupMacroMealPlanTemplateToOrgInput = {
  readonly macroMealPlanTemplateId: Scalars['ID'];
};

export type MoveOrgGroupMacroMealPlanTemplateToOrgPayload = {
  readonly macroMealPlanTemplate: MacroMealPlanTemplate;
};

export type MoveOrgRecipeToOrgGroupInput = {
  readonly id: Scalars['ID'];
  readonly orgGroupId: Scalars['ID'];
};

export type MoveOrgRecipeToOrgGroupPayload = {
  readonly recipe: Recipe;
};

export type Mutation = {
  readonly addAgreementForm: AddAgreementFormPayload;
  readonly addAgreementFormSubmission: AddAgreementFormSubmissionPayload;
  readonly addAndLinkTeamworksTeams: AddAndLinkTeamworksTeamsPayload;
  readonly addAthleteDislikedFood: AddAthleteDislikedFoodPayload;
  readonly addAthleteDislikedFoodGroup: AddAthleteDislikedFoodGroupPayload;
  readonly addAthleteEmail: AddAthleteEmailPayload;
  readonly addAthleteLikedFood: AddAthleteLikedFoodPayload;
  readonly addAthleteLikedFoodGroup: AddAthleteLikedFoodGroupPayload;
  readonly addAthletePhoneNumber: AddAthletePhoneNumberPayload;
  readonly addBulkOrder: AddBulkOrderPayload;
  readonly addDeliveryLocation: AddDeliveryLocationPayload;
  readonly addDevice: AddDevicePayload;
  readonly addEvent: AddEventPayload;
  readonly addFoodItemToGroceryList: EditGroceryListFoodItemPayload;
  readonly addFoodLogActivityWithTemplate: AddFoodLogActivityWithTemplatePayload;
  readonly addFoodLogActivityWithTemplateForAthlete: AddFoodLogActivityWithTemplateForAthletePayload;
  readonly addFoodLogActivityWithoutTemplate: AddFoodLogActivityWithoutTemplatePayload;
  readonly addFoodLogActivityWithoutTemplateForAthlete: AddFoodLogActivityWithoutTemplateForAthletePayload;
  readonly addFoodLogMealComment: AddFoodLogMealCommentPayload;
  readonly addFoodLogMealImage: AddFoodLogMealImagePayload;
  readonly addFoodLogMealWithTemplate: AddFoodLogMealWithTemplatePayload;
  readonly addFoodLogMealWithTemplateForAthlete: AddFoodLogMealWithTemplateForAthletePayload;
  readonly addFoodLogMealWithoutTemplate: AddFoodLogMealWithoutTemplatePayload;
  readonly addFoodLogMealWithoutTemplateForAthlete: AddFoodLogMealWithoutTemplateForAthletePayload;
  readonly addMealMenuLogItem: AddMealMenuLogItemPayload;
  readonly addMealMenuLogItemForAthlete: AddMealMenuLogItemPayload;
  readonly addMealMenuLogItems: AddMealMenuLogItemsPayload;
  readonly addMealMenuLogItemsForAthlete: AddMealMenuLogItemsPayload;
  readonly addMealOption: AddMealOptionPayload;
  readonly addMealOptionLocal: Maybe<Scalars['ID']>;
  readonly addMenuItemHistoryMissingIngredients: AddMenuItemHistoryMissingIngredientsPayload;
  readonly addMenuOrderComment: AddMenuOrderCommentPayload;
  readonly addMenuOrderForAthlete: AddMenuOrderForAthletePayload;
  readonly addMenuOrderImage: AddMenuOrderImagePayload;
  readonly addMenuOrderItemRatings: AddMenuOrderItemRatingsPayload;
  readonly addMenuOrderWithTemplate: AddMenuOrderWithTemplatePayload;
  readonly addMenuOrderWithoutTemplate: AddMenuOrderWithoutTemplatePayload;
  readonly addMyDislikedFood: AddAthleteDislikedFoodPayload;
  readonly addMyDislikedFoodGroup: AddAthleteDislikedFoodGroupPayload;
  readonly addMyLikedFood: AddAthleteLikedFoodPayload;
  readonly addMyLikedFoodGroup: AddAthleteLikedFoodGroupPayload;
  readonly addNixBrandedFood: AddNixBrandedFoodPayload;
  readonly addNixBrandedFoodByUpc: AddNixBrandedFoodByUpcPayload;
  readonly addNixCommonFood: AddNixCommonFoodPayload;
  readonly addPosition: AddPositionPayload;
  readonly addRecipeImage: AddRecipeImagePayload;
  readonly addRestaurantLocation: AddRestaurantLocationPayload;
  readonly addRestaurantLocationContact: AddRestaurantLocationContactPayload;
  readonly addRestaurantMenuLink: AddRestaurantMenuLinkPayload;
  readonly addRestaurantMenuLinkOrder: AddRestaurantMenuLinkOrderPayload;
  readonly addRestaurantMenuLinkOrderForAthlete: AddRestaurantMenuLinkOrderForAthletePayload;
  readonly addRestaurantMenuLinkPlateOrder: AddRestaurantMenuLinkPlateOrderPayload;
  readonly addRestaurantMenuLinkPlateOrderForAthlete: AddRestaurantMenuLinkPlateOrderForAthletePayload;
  readonly addRestaurantMenuLog: AddRestaurantMenuLogPayload;
  readonly addRestaurantMenuLogForAthlete: AddRestaurantMenuLogForAthletePayload;
  readonly addRestaurantMenuLogItem: AddRestaurantMenuLogItemPayload;
  readonly addRestaurantMenuLogItemForAthlete: AddRestaurantMenuLogItemPayload;
  readonly addRestaurantMenuLogItems: AddRestaurantMenuLogItemsPayload;
  readonly addRestaurantMenuLogItemsForAthlete: AddRestaurantMenuLogItemsPayload;
  readonly addSport: AddSportPayload;
  readonly addTeamLabel: AddTeamLabelPayload;
  readonly addTimelineMealCommentForAthlete: AddTimelineMealCommentPayload;
  readonly addTimelineMealCommentForMe: AddTimelineMealCommentPayload;
  readonly addTimelineMealImageForAthlete: AddTimelineMealImagePayload;
  readonly addTimelineMealImageForMe: AddTimelineMealImagePayload;
  readonly addTimelineMealServingAmountForAthlete: AddTimelineMealServingAmountPayload;
  readonly addTimelineMealServingAmountForMe: AddTimelineMealServingAmountPayload;
  readonly archiveAthlete: ArchiveAthletePayload;
  readonly archiveGoalConfiguration: ArchiveGoalConfigurationPayload;
  /** @deprecated Field no longer supported */
  readonly assignMealPlanTemplateToAthlete: AssignMealPlanTemplateToAthletePayload;
  readonly assignMetricMealPlanTemplateToAthlete: AssignMealPlanTemplateToAthletePayload;
  readonly bulkCopyMealPlanNew: BulkCopyMealPlanPayload;
  readonly bulkDeleteMealMenus: BulkDeleteMealMenusPayload;
  readonly bulkEditMealMenus: BulkEditMealMenusPayload;
  readonly bulkImportCreateAndDeprecateGenericFoods: BulkImportCreateAndDeprecateGenericFoodsPayload;
  readonly bulkImportCreateGenericFoods: BulkImportCreateGenericFoodsPayload;
  readonly bulkImportCreateRestaurantBrandedFood: BulkImportCreateRestautantBrandedFoodsPayload;
  readonly bulkImportEditRestaurantBrandedFood: BulkImportEditRestautantBrandedFoodsPayload;
  readonly calculateExchangeTotals: Maybe<Scalars['ID']>;
  readonly changeExchangeTarget: Maybe<Scalars['ID']>;
  readonly changeExchangeTargetTotal: Maybe<Scalars['ID']>;
  readonly changeMacroTarget: Maybe<Scalars['ID']>;
  readonly checkGroceryListCustomItem: CheckGroceryListCustomItemPayload;
  readonly checkGroceryListFoodItem: CheckGroceryListFoodItemPayload;
  readonly copyExchangeSet: CopyExchangeSetPayload;
  readonly copyMacroMealPlanTemplate: CopyMacroMealPlanTemplatePayload;
  readonly copyMealMenus: CopyMealMenusPayload;
  readonly copyMealPlanNew: CopyMealPlanPayload;
  readonly copyPrepPlan: CopyPrepPlanPayload;
  readonly copySharedExchangeSet: CopyExchangeSetPayload;
  readonly copySharedMacroMealPlanTemplate: CopyMacroMealPlanTemplatePayload;
  /** @deprecated Field no longer supported */
  readonly createAthlete: CreateAthletePayload;
  readonly createAthleteTransfer: CreateAthleteTransferPayload;
  /** @deprecated Field no longer supported */
  readonly createAthletes: CreateAthletesPayload;
  readonly createBrand: CreateBrandPayload;
  readonly createBrandedFood: CreateBrandedFoodPayload;
  readonly createClientCredential: CreateClientCredentialPayload;
  readonly createCuisine: CreateCuisinePayload;
  readonly createCustomTag: NamedTag;
  readonly createDiet: CreateDietPayload;
  readonly createDiningStationTemplate: CreateDiningStationTemplatePayload;
  readonly createExchangeSet: CreateExchangeSetPayload;
  readonly createFoodServings: CreateFoodServingsPayload;
  readonly createGenericFood: CreateGenericFoodPayload;
  readonly createGoal: CreateGoalPayload;
  readonly createGoalConfigurationWithDaysOfWeek: CreateGoalConfigurationPayload;
  readonly createGoalConfigurationWithIndividualDates: CreateGoalConfigurationPayload;
  readonly createGroceryListCustomItem: CreateGroceryListCustomItemPayload;
  readonly createMacroMealPlanTemplate: CreateMacroMealPlanTemplatePayload;
  readonly createMealMenus: CreateMealMenusPayload;
  /** @deprecated Field no longer supported */
  readonly createMealPlanFromFoodLogsNew: CreateMealPlanFromFoodLogsPayload;
  /** @deprecated Field no longer supported */
  readonly createMealPlanFromMealPlanTemplate: CreateMealPlanFromMealPlanTemplatePayload;
  /** @deprecated Field no longer supported */
  readonly createMealPlanNew: CreateMealPlanPayload;
  readonly createMemberships: CreateOrgMembershipsPayload;
  readonly createMetricAnthropometryEntries: CreateAnthropometryEntriesPayload;
  readonly createMetricAnthropometryEntry: CreateAnthropometryEntryPayload;
  readonly createMetricAthlete: CreateAthletePayload;
  readonly createMetricAthletes: CreateAthletesPayload;
  readonly createMetricMealPlan: CreateMealPlanPayload;
  readonly createMetricMealPlanFromFoodLogs: CreateMealPlanFromFoodLogsPayload;
  readonly createMetricMealPlanFromMealPlanTemplate: CreateMealPlanFromMealPlanTemplatePayload;
  readonly createNote: CreateNotePayload;
  readonly createNoteType: CreateNoteTypePayload;
  readonly createOrg: CreateOrgPayload;
  readonly createOrgFoodGroup: CreateFoodGroupPayload;
  readonly createOrgFoodServings: CreateFoodServingsPayload;
  readonly createOrgFromTeamworksOrg: CreateOrgFromTeamworksOrgPayload;
  readonly createOrgGenericFood: CreateGenericFoodPayload;
  readonly createOrgGroup: CreateOrgGroupPayload;
  readonly createOrgMembershipForAthlete: CreateOrgMembershipForAthletePayload;
  readonly createPaymentMethod: CreatePaymentMethodPayload;
  readonly createPlannedMenu: CreatePlannedMenuPayload;
  readonly createPrepPlan: CreatePrepPlanPayload;
  readonly createPrepPlanMeal: CreatePrepPlanMealPayload;
  readonly createRecipeFull: CreateRecipeFullPayload;
  readonly createRecipeSimple: CreateRecipeSimplePayload;
  readonly createRestaurant: CreateRestaurantPayload;
  readonly createRestaurantBrandedFood: CreateRestaurantBrandedFoodPayload;
  readonly createRestaurantMenu: CreateRestaurantMenuPayload;
  readonly createRestaurantType: CreateRestaurantTypePayload;
  readonly createReusableMenuItem: CreateReusableMenuItemPayload;
  readonly createSchedule: CreateSchedulePayload;
  readonly createSharedExchangeSet: CreateExchangeSetPayload;
  readonly createSharedFoodGroup: CreateFoodGroupPayload;
  readonly createSharedMacroMealPlanTemplate: CreateMacroMealPlanTemplatePayload;
  readonly createSmartTag: NamedTag;
  readonly createTeam: CreateTeamPayload;
  readonly createTerritory: Territory;
  readonly createUserBrandedFood: CreateBrandedFoodPayload;
  readonly createWeightUnitServingForFood: Maybe<CreateWeightUnitServingForFoodPayload>;
  readonly deleteAnthropometryEntry: DeleteAnthropometryEntryPayload;
  readonly deleteAthlete: DeleteAthletePayload;
  readonly deleteCuisine: DeleteCuisinePayload;
  readonly deleteDiet: DeleteDietPayload;
  readonly deleteDiningStationTemplate: DeleteDiningStationTemplatePayload;
  readonly deleteExchangeSet: DeleteExchangeSetPayload;
  readonly deleteGoalConfiguration: DeleteGoalConfigurationPayload;
  readonly deleteGroceryListCustomItem: DeleteGroceryListCustomItemPayload;
  readonly deleteGroceryListFoodItem: EditGroceryListFoodItemPayload;
  readonly deleteInProgressPrepPlan: DeleteInProgressPrepPlanPayload;
  readonly deleteMacroMealPlanTemplate: DeleteMacroMealPlanTemplatePayload;
  readonly deleteMacroMealPlanTemplatePendingState: DeleteMacroMealPlanTemplatePendingStatePayload;
  readonly deleteMealMenu: DeleteMealMenuPayload;
  readonly deleteMealPlan: DeleteMealPlanPayload;
  readonly deleteMealPlans: DeleteMealPlansPayload;
  readonly deleteNote: DeleteNotePayload;
  readonly deleteNoteType: DeleteNoteTypePayload;
  readonly deleteOrgFood: DeleteFoodPayload;
  readonly deleteOrgGroupMacroMealPlanTemplate: DeleteMacroMealPlanTemplatePayload;
  readonly deleteOrgGroupMacroMealPlanTemplatePendingState: DeleteMacroMealPlanTemplatePendingStatePayload;
  readonly deletePaymentMethod: DeletePaymentMethodPayload;
  readonly deletePlannedMenu: DeletePlannedMenuPayload;
  readonly deleteRecipe: DeleteRecipePayload;
  readonly deleteRestaurantType: DeleteRestaurantTypePayload;
  readonly deleteReusableMenuItem: DeleteReusableMenuItemPayload;
  readonly deleteSharedExchangeSet: DeleteExchangeSetPayload;
  readonly deleteSharedMacroMealPlanTemplate: DeleteMacroMealPlanTemplatePayload;
  readonly deleteSharedMacroMealPlanTemplatePendingState: DeleteMacroMealPlanTemplatePendingStatePayload;
  readonly deleteTag: DeleteTagPayload;
  readonly deleteTeam: DeleteTeamPayload;
  readonly deleteUserBrandedFood: DeleteUserBrandedFoodPayload;
  readonly deprecateRestaurantBrandedFood: DeprecateRestaurantBrandedFoodPayload;
  readonly deprecateSharedFood: DeprecateFoodPayload;
  readonly deselectNegativeFoods: Maybe<Scalars['ID']>;
  readonly distributeExchangeTargets: Maybe<Scalars['ID']>;
  readonly distributeMacroTargets: Maybe<Scalars['ID']>;
  readonly duplicatePlannedMenu: CreatePlannedMenuPayload;
  readonly editAgreementForm: EditAgreementFormPayload;
  /** @deprecated Field no longer supported */
  readonly editAnthropometryEntry: EditAnthropometryEntryPayload;
  readonly editAnthropometryEntryComment: EditAnthropometryEntryCommentPayload;
  readonly editAthlete: EditAthletePayload;
  readonly editAthleteDisplaySettings: EditAthleteDisplaySettingsPayload;
  readonly editAthleteFoodLogNotificationSettings: EditAthleteFoodLogNotificationSettingsPayload;
  /** @deprecated Field no longer supported */
  readonly editAthletes: EditAthletesPayload;
  readonly editBrand: EditBrandPayload;
  readonly editBrandedFood: EditBrandedFoodPayload;
  readonly editBulkOrder: EditBulkOrderPayload;
  readonly editClientCredential: EditClientCredentialPayload;
  readonly editCuisine: EditCuisinePayload;
  readonly editCustomTag: NamedTag;
  readonly editCustomizedPrepPlanMeal: EditCustomizedPrepPlanMealPayload;
  readonly editDeliveryLocation: EditDeliveryLocationPayload;
  readonly editDiet: EditDietPayload;
  readonly editDiningStationTemplate: EditDiningStationTemplatePayload;
  readonly editExchangeMealTemplate: EditExchangeMealTemplatePayload;
  readonly editExchangeMealTemplates: EditExchangeMealTemplatesPayload;
  readonly editExchangeSet: EditExchangeSetPayload;
  readonly editFoodLogActivityRpe: EditFoodLogActivityRpePayload;
  readonly editFoodLogActivityTemplate: EditFoodLogActivityTemplatePayload;
  readonly editFoodLogActivityTimeWithTimezone: EditFoodLogActivityTimeWithTimezonePayload;
  readonly editFoodLogMealServingAmounts: EditFoodLogMealServingAmountsPayload;
  readonly editFoodLogMealTemplate: EditFoodLogMealTemplatePayload;
  readonly editFoodLogMealTimeWithTimezone: EditFoodLogMealTimeWithTimezonePayload;
  readonly editFoodServing: EditFoodServingPayload;
  readonly editGenericFood: EditGenericFoodPayload;
  readonly editGoalConfigurationWithDaysOfWeek: EditGoalConfigurationPayload;
  readonly editGoalConfigurationWithIndividualDates: EditGoalConfigurationPayload;
  readonly editGroceryList: Maybe<EditGroceryListPayload>;
  readonly editGroceryListCustomItem: EditGroceryListCustomItemPayload;
  readonly editGroceryListFoodItem: EditGroceryListFoodItemPayload;
  readonly editMacroMealPlanTemplate: EditMacroMealPlanTemplatePayload;
  readonly editMacroMealTemplate: EditMacroMealTemplatePayload;
  readonly editMacroMealTemplates: EditMacroMealTemplatesPayload;
  readonly editMacroProtocol: EditMacroProtocolPayload;
  readonly editMacroProtocolWithNewGoal: EditMacroProtocolWithNewGoalPayload;
  readonly editMacroProtocolWithOldGoal: EditMacroProtocolWithOldGoalPayload;
  readonly editMealMenu: EditMealMenuPayload;
  readonly editMealMenuLogItem: EditMealMenuLogItemPayload;
  readonly editMealMenuWithIdentity: EditMealMenuWithIdentityPayload;
  readonly editMealOption: EditMealOptionPayload;
  readonly editMealOptionLocal: Maybe<Scalars['ID']>;
  readonly editMealOptionPending: EditMealOptionPendingPayload;
  readonly editMealOptionPendingLocal: Maybe<Scalars['ID']>;
  readonly editMealPlanCalendar: EditMealPlanCalendarPayload;
  readonly editMealPlanDate: EditMealPlanDatePayload;
  readonly editMealPlanDateForAthlete: EditMealPlanDateForAthletePayload;
  readonly editMealPlanExchangeTargets: EditMealPlanExchangeTargetsPayload;
  readonly editMealPlanIsShared: EditMealPlanIsSharedPayload;
  readonly editMealPlanNew: EditMealPlanPayload;
  readonly editMealPlansIsShared: EditMealPlansIsSharedPayload;
  readonly editMemberships: EditOrgMembershipsPayload;
  readonly editMenuItemImage: EditMenuItemImagePayload;
  readonly editMenuOrder: EditMenuOrderPayload;
  readonly editMenuOrderMealTemplate: EditMenuOrderMealTemplatePayload;
  readonly editMetricAnthropometryEntries: EditAnthropometryEntriesPayload;
  readonly editMetricAnthropometryEntry: EditAnthropometryEntryPayload;
  readonly editMetricAnthropometryEntryWeighInForDate: EditAnthropometryEntryWeighInForDatePayload;
  readonly editMetricAnthropometryEntryWeighInForDateForAthlete: EditAnthropometryEntryWeighInForDateForAthletePayload;
  readonly editMetricAthletes: EditAthletesPayload;
  readonly editMetricMacroProtocol: EditMacroProtocolPayload;
  readonly editNote: EditNotePayload;
  readonly editNoteType: EditNoteTypePayload;
  readonly editOrg: EditOrgPayload;
  readonly editOrgAutoShareNewNotemealRecipes: EditOrgAutoShareNewNotemealRecipesPayload;
  readonly editOrgFeatureFlags: EditOrgFeatureFlagsPayload;
  readonly editOrgFoodGroup: EditFoodGroupPayload;
  readonly editOrgFoodServing: EditFoodServingPayload;
  readonly editOrgGenericFood: EditGenericFoodPayload;
  readonly editOrgGroup: EditOrgGroupPayload;
  readonly editOrgGroupMacroMealPlanTemplate: EditMacroMealPlanTemplatePayload;
  readonly editOrgTeamworksProfiles: EditOrgTeamworksProfilesPayload;
  readonly editPaymentMethod: EditPaymentMethodPayload;
  readonly editPlannedMenu: EditPlannedMenuPayload;
  readonly editPrepPlan: EditPrepPlanPayload;
  readonly editPrepPlanMeal: EditPrepPlanMealPayload;
  readonly editRecipeFull: EditRecipeFullPayload;
  readonly editRecipeIsShared: EditRecipeIsSharedPayload;
  readonly editRecipePartial: EditRecipePartialPayload;
  readonly editRecipeSimple: EditRecipeSimplePayload;
  readonly editRestaurant: EditRestaurantPayload;
  readonly editRestaurantBrandedFood: EditRestaurantBrandedFoodPayload;
  readonly editRestaurantLocation: EditRestaurantLocationPayload;
  readonly editRestaurantLocationContact: EditRestaurantLocationContactPayload;
  readonly editRestaurantMenu: EditRestaurantMenuPayload;
  readonly editRestaurantMenuLink: EditRestaurantMenuLinkPayload;
  readonly editRestaurantMenuLinkOrder: EditRestaurantMenuLinkOrderPayload;
  readonly editRestaurantMenuLinkOrderMealTemplate: EditRestaurantMenuLinkOrderMealTemplatePayload;
  readonly editRestaurantMenuLinkPlateOrderMealTemplate: EditRestaurantMenuLinkPlateOrderMealTemplatePayload;
  readonly editRestaurantMenuLog: EditRestaurantMenuLogPayload;
  readonly editRestaurantMenuLogItem: EditRestaurantMenuLogItemPayload;
  readonly editRestaurantMenuLogMealTemplate: EditRestaurantMenuLogMealTemplatePayload;
  readonly editRestaurantType: EditRestaurantTypePayload;
  readonly editReusableMenuItem: EditReusableMenuItemPayload;
  readonly editReusableMenuItemPartial: EditReusableMenuItemPartialPayload;
  readonly editSchedule: EditSchedulePayload;
  readonly editSharedExchangeSet: EditExchangeSetPayload;
  readonly editSharedFoodGroup: EditFoodGroupPayload;
  readonly editSharedMacroMealPlanTemplate: EditMacroMealPlanTemplatePayload;
  readonly editSmartTag: NamedTag;
  readonly editSyncedAthlete: EditAthletePayload;
  /** @deprecated Field no longer supported */
  readonly editSyncedAthletes: EditAthletesPayload;
  readonly editSyncedMetricAthletes: EditAthletesPayload;
  readonly editTeam: EditTeamPayload;
  readonly editTeamDisplaySettings: EditTeamDisplaySettingsPayload;
  readonly editTeamFoodLogNotificationSettings: EditTeamFoodLogNotificationSettingsPayload;
  readonly editTeamForStaff: EditTeamPayload;
  readonly editTeamLabel: EditTeamLabelPayload;
  readonly editTerritory: Territory;
  readonly editTimelineMealDetails: EditTimelineMealDetailsPayload;
  readonly editTimelineMealMealTemplate: EditTimelineMealMealTemplatePayload;
  readonly editTimelineMealServingAmount: EditTimelineMealServingAmountPayload;
  readonly editUserBrandedFood: EditUserBrandedFoodPayload;
  readonly editUserTeamworksIdToOverwrite: EditUserTeamworksIdToOverwritePayload;
  readonly finalizePrepPlan: FinalizePrepPlanPayload;
  readonly forceLockPlannedMenu: ForceLockPlannedMenuPayload;
  readonly getPossibleGPTRecipeByName: GptRecipePayload;
  readonly getPossibleGPTRecipeFromImage: GptRecipePayload;
  /** @deprecated mobile-use only; remove once force-update available in react-native app */
  readonly getPossibleGPTRecipeFromUrl: GptRecipePayload;
  readonly importBonAppetitMenus: ImportBonAppetitMenusPayload;
  readonly importCbordMenus: ImportCbordMenusPayload;
  readonly importCompassMenus: ImportCompassMenusPayload;
  readonly importCompassRecipe: ImportCompassRecipeInputPayload;
  readonly importPlannedMenu: ImportPlannedMenuPayload;
  readonly importSodexoMenus: ImportSodexoMenusPayload;
  readonly linkTeamworksOrg: LinkTeamworksOrgPayload;
  readonly lockPlannedMenu: LockPlannedMenuPayload;
  readonly markAllNotificationsAsViewed: MarkAllNotificationsAsViewedPayload;
  readonly markNotificationsAsViewed: MarkNotificationsAsViewedPayload;
  readonly markNotificationsAsViewedBefore: MarkNotificationsAsViewedBeforePayload;
  readonly moveMacroMealPlanTemplateToOrgGroup: MoveMacroMealPlanTemplateToOrgGroupPayload;
  readonly moveOrgFoodToOrgGroup: MoveOrgFoodToOrgGroupPayload;
  readonly moveOrgGroupFoodToMyOrg: MoveOrgGroupFoodToMyOrgPayload;
  readonly moveOrgGroupMacroMealPlanTemplateToOrg: MoveOrgGroupMacroMealPlanTemplateToOrgPayload;
  readonly moveOrgRecipeToOrgGroup: MoveOrgRecipeToOrgGroupPayload;
  readonly overrideMealMenuIdentity: OverrideMealMenuIdentityPayload;
  readonly publishMacroMealPlanTemplate: PublishMacroMealPlanTemplatePayload;
  readonly publishOrgGroupMacroMealPlanTemplate: PublishMacroMealPlanTemplatePayload;
  readonly publishSharedMacroMealPlanTemplate: PublishMacroMealPlanTemplatePayload;
  readonly recieveAthleteTransfer: RecieveAthleteTransferPayload;
  readonly reclaimAthleteTransfer: ReclaimAthleteTransferPayload;
  readonly recordMenuOrderSuggestionDecision: Scalars['Boolean'];
  readonly recordMenuOrderSuggestionsView: Scalars['Boolean'];
  readonly refreshClientCredentialSecret: RefreshClientCredentialSecretPayload;
  readonly refreshTeamworksSyncStatus: RefreshTeamworksSyncStatusPayload;
  readonly removeAthleteDislikedFood: RemoveAthleteDislikedFoodPayload;
  readonly removeAthleteDislikedFoodGroup: RemoveAthleteDislikedFoodGroupPayload;
  readonly removeAthleteLikedFood: RemoveAthleteLikedFoodPayload;
  readonly removeAthleteLikedFoodGroup: RemoveAthleteLikedFoodGroupPayload;
  readonly removeBulkOrder: RemoveBulkOrderPayload;
  readonly removeFoodLogActivity: RemoveFoodLogActivityPayload;
  readonly removeFoodLogActivityTemplate: RemoveFoodLogActivityTemplatePayload;
  readonly removeFoodLogMeal: RemoveFoodLogMealPayload;
  readonly removeFoodLogMealImage: RemoveFoodLogMealImagePayload;
  readonly removeFoodLogMealTemplate: RemoveFoodLogMealTemplatePayload;
  readonly removeMealMenuLogItem: RemoveMealMenuLogItemPayload;
  readonly removeMealOption: RemoveMealOptionPayload;
  readonly removeMealOptionLocal: Maybe<Scalars['ID']>;
  readonly removeMealOptionPending: RemoveMealOptionPendingPayload;
  readonly removeMealOptionPendingLocal: Maybe<Scalars['ID']>;
  readonly removeMealPlanDate: RemoveMealPlanDatePayload;
  readonly removeMealPlanDateForAthlete: RemoveMealPlanDateForAthletePayload;
  readonly removeMenuOrder: RemoveMenuOrderPayload;
  readonly removeMenuOrderImage: RemoveMenuOrderImagePayload;
  readonly removeMenuOrderMealTemplate: RemoveMenuOrderMealTemplatePayload;
  readonly removeMyDislikedFood: RemoveAthleteDislikedFoodPayload;
  readonly removeMyDislikedFoodGroup: RemoveAthleteDislikedFoodGroupPayload;
  readonly removeMyLikedFood: RemoveAthleteLikedFoodPayload;
  readonly removeMyLikedFoodGroup: RemoveAthleteLikedFoodGroupPayload;
  readonly removePrepPlanMeal: RemovePrepPlanMealPayload;
  readonly removeRecipeImage: RemoveRecipeImagePayload;
  readonly removeRestaurantMenuLink: RemoveRestaurantMenuLinkPayload;
  readonly removeRestaurantMenuLinkOrder: RemoveRestaurantMenuLinkOrderPayload;
  readonly removeRestaurantMenuLinkOrderMealTemplate: RemoveRestaurantMenuLinkOrderMealTemplatePayload;
  readonly removeRestaurantMenuLinkPlateOrder: RemoveRestaurantMenuLinkPlateOrderPayload;
  readonly removeRestaurantMenuLinkPlateOrderMealTemplate: RemoveRestaurantMenuLinkPlateOrderMealTemplatePayload;
  readonly removeRestaurantMenuLog: RemoveRestaurantMenuLogPayload;
  readonly removeRestaurantMenuLogItem: RemoveRestaurantMenuLogItemPayload;
  readonly removeRestaurantMenuLogMealTemplate: RemoveRestaurantMenuLogMealTemplatePayload;
  readonly removeTimelineMealImage: RemoveTimelineMealImagePayload;
  readonly removeTimelineMealServingAmount: RemoveTimelineMealServingAmountPayload;
  readonly runMaintainTeamworksFullySyncedJob: RefreshTeamworksSyncStatusPayload;
  readonly savePlannedMeals: SavePlannedMealsPayload;
  readonly selectMealOption: Maybe<Scalars['ID']>;
  readonly sendMealMenuNotification: Maybe<Scalars['Boolean']>;
  readonly setGoalTrackerGoal: SetGoalTrackerGoalPayload;
  readonly setMealMenuCheckInsForAthlete: SetMealMenuCheckInsPayload;
  readonly setMealMenuLogItemPercentConsumed: SetMealMenuLogItemPercentConsumedPayload;
  readonly setMealOptionsForMealTemplates: SetMealOptionsForMealTemplatesPayload;
  readonly setMenuOrderItemPercentConsumed: SetMenuOrderItemPercentConsumedPayload;
  readonly setPrepPlanGuidedBuilder: SetPrepPlanGuidedBuilderPayload;
  readonly setPrepPlanMealAssignmentSlots: SetPrepPlanMealAssignmentSlotsPayload;
  readonly setRestaurantMenuLinkOrderItemPercentConsumed: SetRestaurantMenuLinkOrderItemPercentConsumedPayload;
  readonly setRestaurantMenuLinkPlateOrderItemPercentConsumed: SetRestaurantMenuLinkPlateOrderItemPercentConsumedPayload;
  readonly setRestaurantMenuLogItemPercentConsumed: SetRestaurantMenuLogItemPercentConsumedPayload;
  readonly setTimelineMealServingAmountsForAthlete: SetTimelineMealServingAmountsPayload;
  readonly setTimelineMealServingAmountsForMe: SetTimelineMealServingAmountsPayload;
  readonly shareMealOptionPending: ShareMealOptionPendingPayload;
  readonly shareMealOptionPendingLocal: Maybe<Scalars['ID']>;
  readonly syncStreamChatDevices: Maybe<Scalars['Boolean']>;
  readonly textMealPlanToAthlete: TextMealPlanToAthletePayload;
  readonly textMealPlansToAthletes: TextMealPlansToAthletesPayload;
  readonly unarchiveAthlete: UnarchiveAthletePayload;
  readonly unlockPlannedMenu: UnlockPlannedMenuPayload;
  readonly updateApnCountForUser: UpdateApnCountForUserPayload;
  readonly updateBulkOrderStatus: UpdateBulkOrderStatusPayload;
  readonly updateMacroMealPlanTemplateAssignScope: UpdateMealPlanTemplateAssignScopePayload;
  readonly updateMacroMealPlanTemplateSharingScope: UpdateMacroMealPlanTemplateSharingScopePayload;
  readonly updateMealMenuPlateItemOrderStatus: UpdateMealMenuPlateItemOrderStatusPayload;
  readonly updateMealMenuPlateOrderStatus: UpdateMealMenuPlateOrderStatusPayload;
  readonly updateMealMenuPlateOrderStatuses: ReadonlyArray<UpdateMealMenuPlateOrderStatusPayload>;
  readonly updateMenuOrderItemStatuses: UpdateMenuOrderItemStatusesPayload;
  readonly updateMultipleMealMenuPlateItemOrderStatuses: UpdateMultipleMealMenuPlateItemOrderStatusesPayload;
  readonly updateMultipleMenuOrderItemStatuses: UpdateMultipleMenuOrderItemStatusesPayload;
  readonly updateSharedMacroMealPlanTemplateSharingScope: UpdateMacroMealPlanTemplateSharingScopePayload;
};


export type MutationAddAgreementFormArgs = {
  input: AddAgreementFormInput;
};


export type MutationAddAgreementFormSubmissionArgs = {
  input: AddAgreementFormSubmissionInput;
};


export type MutationAddAndLinkTeamworksTeamsArgs = {
  input: AddAndLinkTeamworksTeamsInput;
};


export type MutationAddAthleteDislikedFoodArgs = {
  input: AthleteFoodPreferenceInput;
};


export type MutationAddAthleteDislikedFoodGroupArgs = {
  input: AthleteFoodGroupPreferenceInput;
};


export type MutationAddAthleteEmailArgs = {
  input: AddAthleteEmailInput;
};


export type MutationAddAthleteLikedFoodArgs = {
  input: AthleteFoodPreferenceInput;
};


export type MutationAddAthleteLikedFoodGroupArgs = {
  input: AthleteFoodGroupPreferenceInput;
};


export type MutationAddAthletePhoneNumberArgs = {
  input: AddAthletePhoneNumberInput;
};


export type MutationAddBulkOrderArgs = {
  input: AddBulkOrderInput;
};


export type MutationAddDeliveryLocationArgs = {
  input: AddDeliveryLocationInput;
};


export type MutationAddDeviceArgs = {
  input: AddDeviceInput;
};


export type MutationAddEventArgs = {
  input: AddEventInput;
};


export type MutationAddFoodItemToGroceryListArgs = {
  input: AddFoodItemToGroceryListInput;
};


export type MutationAddFoodLogActivityWithTemplateArgs = {
  input: AddFoodLogActivityWithTemplateInput;
};


export type MutationAddFoodLogActivityWithTemplateForAthleteArgs = {
  input: AddFoodLogActivityWithTemplateForAthleteInput;
};


export type MutationAddFoodLogActivityWithoutTemplateArgs = {
  input: AddFoodLogActivityWithoutTemplateInput;
};


export type MutationAddFoodLogActivityWithoutTemplateForAthleteArgs = {
  input: AddFoodLogActivityWithoutTemplateForAthleteInput;
};


export type MutationAddFoodLogMealCommentArgs = {
  input: AddFoodLogMealCommentInput;
};


export type MutationAddFoodLogMealImageArgs = {
  input: AddFoodLogMealImageInput;
};


export type MutationAddFoodLogMealWithTemplateArgs = {
  input: AddFoodLogMealWithTemplateInput;
};


export type MutationAddFoodLogMealWithTemplateForAthleteArgs = {
  input: AddFoodLogMealWithTemplateForAthleteInput;
};


export type MutationAddFoodLogMealWithoutTemplateArgs = {
  input: AddFoodLogMealWithoutTemplateInput;
};


export type MutationAddFoodLogMealWithoutTemplateForAthleteArgs = {
  input: AddFoodLogMealWithoutTemplateForAthleteInput;
};


export type MutationAddMealMenuLogItemArgs = {
  input: AddMealMenuLogItemInput;
};


export type MutationAddMealMenuLogItemForAthleteArgs = {
  input: AddMealMenuLogItemForAthleteInput;
};


export type MutationAddMealMenuLogItemsArgs = {
  input: AddMealMenuLogItemsInput;
};


export type MutationAddMealMenuLogItemsForAthleteArgs = {
  input: AddMealMenuLogItemsForAthleteInput;
};


export type MutationAddMealOptionArgs = {
  input: AddMealOptionInput;
};


export type MutationAddMealOptionLocalArgs = {
  mealTemplateId: Scalars['ID'];
  type: Scalars['String'];
};


export type MutationAddMenuItemHistoryMissingIngredientsArgs = {
  input: AddMenuItemHistoryMissingIngredientsInput;
};


export type MutationAddMenuOrderCommentArgs = {
  input: AddMenuOrderCommentInput;
};


export type MutationAddMenuOrderForAthleteArgs = {
  input: AddMenuOrderForAthleteInput;
};


export type MutationAddMenuOrderImageArgs = {
  input: AddMenuOrderImageInput;
};


export type MutationAddMenuOrderItemRatingsArgs = {
  input: AddMenuOrderItemRatingsInput;
};


export type MutationAddMenuOrderWithTemplateArgs = {
  input: AddMenuOrderWithTemplateInput;
};


export type MutationAddMenuOrderWithoutTemplateArgs = {
  input: AddMenuOrderWithoutTemplateInput;
};


export type MutationAddMyDislikedFoodArgs = {
  input: MyFoodPreferenceInput;
};


export type MutationAddMyDislikedFoodGroupArgs = {
  input: MyFoodGroupPreferenceInput;
};


export type MutationAddMyLikedFoodArgs = {
  input: MyFoodPreferenceInput;
};


export type MutationAddMyLikedFoodGroupArgs = {
  input: MyFoodGroupPreferenceInput;
};


export type MutationAddNixBrandedFoodArgs = {
  input: AddNixBrandedFoodInput;
};


export type MutationAddNixBrandedFoodByUpcArgs = {
  input: AddNixBrandedFoodByUpcInput;
};


export type MutationAddNixCommonFoodArgs = {
  input: AddNixCommonFoodInput;
};


export type MutationAddPositionArgs = {
  input: AddPositionInput;
};


export type MutationAddRecipeImageArgs = {
  input: AddRecipeImageInput;
};


export type MutationAddRestaurantLocationArgs = {
  input: AddRestaurantLocationInput;
};


export type MutationAddRestaurantLocationContactArgs = {
  input: AddRestaurantLocationContactInput;
};


export type MutationAddRestaurantMenuLinkArgs = {
  input: AddRestaurantMenuLinkInput;
};


export type MutationAddRestaurantMenuLinkOrderArgs = {
  input: AddRestaurantMenuLinkOrderInput;
};


export type MutationAddRestaurantMenuLinkOrderForAthleteArgs = {
  input: AddRestaurantMenuLinkOrderForAthleteInput;
};


export type MutationAddRestaurantMenuLinkPlateOrderArgs = {
  input: AddRestaurantMenuLinkPlateOrderInput;
};


export type MutationAddRestaurantMenuLinkPlateOrderForAthleteArgs = {
  input: AddRestaurantMenuLinkPlateOrderForAthleteInput;
};


export type MutationAddRestaurantMenuLogArgs = {
  input: AddRestaurantMenuLogInput;
};


export type MutationAddRestaurantMenuLogForAthleteArgs = {
  input: AddRestaurantMenuLogForAthleteInput;
};


export type MutationAddRestaurantMenuLogItemArgs = {
  input: AddRestaurantMenuLogItemInput;
};


export type MutationAddRestaurantMenuLogItemForAthleteArgs = {
  input: AddRestaurantMenuLogItemForAthleteInput;
};


export type MutationAddRestaurantMenuLogItemsArgs = {
  input: AddRestaurantMenuLogItemsInput;
};


export type MutationAddRestaurantMenuLogItemsForAthleteArgs = {
  input: AddRestaurantMenuLogItemsForAthleteInput;
};


export type MutationAddSportArgs = {
  input: AddSportInput;
};


export type MutationAddTeamLabelArgs = {
  input: AddTeamLabelInput;
};


export type MutationAddTimelineMealCommentForAthleteArgs = {
  input: AddTimelineMealCommentForAthleteInput;
};


export type MutationAddTimelineMealCommentForMeArgs = {
  input: AddTimelineMealCommentForMeInput;
};


export type MutationAddTimelineMealImageForAthleteArgs = {
  input: AddTimelineMealImageForAthleteInput;
};


export type MutationAddTimelineMealImageForMeArgs = {
  input: AddTimelineMealImageInput;
};


export type MutationAddTimelineMealServingAmountForAthleteArgs = {
  input: AddTimelineMealServingAmountForAthleteInput;
};


export type MutationAddTimelineMealServingAmountForMeArgs = {
  input: AddTimelineMealServingAmountInput;
};


export type MutationArchiveAthleteArgs = {
  input: ArchiveAthleteInput;
};


export type MutationArchiveGoalConfigurationArgs = {
  input: ArchiveGoalConfigurationInput;
};


export type MutationAssignMealPlanTemplateToAthleteArgs = {
  input: AssignMealPlanTemplateToAthleteInput;
};


export type MutationAssignMetricMealPlanTemplateToAthleteArgs = {
  input: AssignMetricMealPlanTemplateToAthleteInput;
};


export type MutationBulkCopyMealPlanNewArgs = {
  input: BulkCopyMealPlanNewInput;
};


export type MutationBulkDeleteMealMenusArgs = {
  input: BulkDeleteMealMenusInput;
};


export type MutationBulkEditMealMenusArgs = {
  input: BulkEditMealMenusInput;
};


export type MutationBulkImportCreateAndDeprecateGenericFoodsArgs = {
  input: BulkImportCreateAndDeprecateGenericFoodsInput;
};


export type MutationBulkImportCreateGenericFoodsArgs = {
  input: BulkImportCreateGenericFoodsInput;
};


export type MutationBulkImportCreateRestaurantBrandedFoodArgs = {
  input: BulkImportCreateRestautantBrandedFoodsInput;
};


export type MutationBulkImportEditRestaurantBrandedFoodArgs = {
  input: BulkImportEditRestautantBrandedFoodsInput;
};


export type MutationCalculateExchangeTotalsArgs = {
  mealPlanId: Scalars['ID'];
};


export type MutationChangeExchangeTargetArgs = {
  mealTemplateId: Scalars['ID'];
  exchangeId: Scalars['ID'];
  target: Scalars['String'];
  pickListServingIds: Maybe<ReadonlyArray<Scalars['ID']>>;
};


export type MutationChangeExchangeTargetTotalArgs = {
  mealPlanId: Scalars['ID'];
  exchangeId: Scalars['ID'];
  target: Scalars['String'];
};


export type MutationChangeMacroTargetArgs = {
  mealTemplateId: Scalars['ID'];
  macro: MacroType;
  target: Scalars['String'];
};


export type MutationCheckGroceryListCustomItemArgs = {
  input: CheckGroceryListCustomItemInput;
};


export type MutationCheckGroceryListFoodItemArgs = {
  input: CheckGroceryListFoodItemInput;
};


export type MutationCopyExchangeSetArgs = {
  input: CopyExchangeSetInput;
};


export type MutationCopyMacroMealPlanTemplateArgs = {
  input: CopyMacroMealPlanTemplateInput;
};


export type MutationCopyMealMenusArgs = {
  input: CopyMealMenusInput;
};


export type MutationCopyMealPlanNewArgs = {
  input: CopyMealPlanNewInput;
};


export type MutationCopyPrepPlanArgs = {
  input: CopyPrepPlanInput;
};


export type MutationCopySharedExchangeSetArgs = {
  input: CopyExchangeSetInput;
};


export type MutationCopySharedMacroMealPlanTemplateArgs = {
  input: CopyMacroMealPlanTemplateInput;
};


export type MutationCreateAthleteArgs = {
  input: CreateAthleteInput;
};


export type MutationCreateAthleteTransferArgs = {
  input: CreateAthleteTransferInput;
};


export type MutationCreateAthletesArgs = {
  input: ReadonlyArray<CreateAthleteInput>;
};


export type MutationCreateBrandArgs = {
  input: CreateBrandInput;
};


export type MutationCreateBrandedFoodArgs = {
  input: CreateBrandedFoodInput;
};


export type MutationCreateClientCredentialArgs = {
  input: CreateClientCredentialInput;
};


export type MutationCreateCuisineArgs = {
  input: CreateCuisineInput;
};


export type MutationCreateCustomTagArgs = {
  input: CreateCustomTagInput;
};


export type MutationCreateDietArgs = {
  input: CreateDietInput;
};


export type MutationCreateDiningStationTemplateArgs = {
  input: CreateDiningStationTemplateInput;
};


export type MutationCreateExchangeSetArgs = {
  input: CreateExchangeSetInput;
};


export type MutationCreateFoodServingsArgs = {
  input: CreateFoodServingsInput;
};


export type MutationCreateGenericFoodArgs = {
  input: CreateGenericFoodInput;
};


export type MutationCreateGoalArgs = {
  input: CreateGoalInput;
};


export type MutationCreateGoalConfigurationWithDaysOfWeekArgs = {
  input: CreateGoalConfigurationWithDaysOfWeekInput;
};


export type MutationCreateGoalConfigurationWithIndividualDatesArgs = {
  input: CreateGoalConfigurationWithIndividualDatesInput;
};


export type MutationCreateGroceryListCustomItemArgs = {
  input: CreateGroceryListCustomItemInput;
};


export type MutationCreateMacroMealPlanTemplateArgs = {
  input: CreateMacroMealPlanTemplateInput;
};


export type MutationCreateMealMenusArgs = {
  input: CreateMealMenusInput;
};


export type MutationCreateMealPlanFromFoodLogsNewArgs = {
  input: CreateMealPlanFromFoodLogsNewInput;
};


export type MutationCreateMealPlanFromMealPlanTemplateArgs = {
  input: CreateMealPlanFromMealPlanTemplateInput;
};


export type MutationCreateMealPlanNewArgs = {
  input: CreateMealPlanNewInput;
};


export type MutationCreateMembershipsArgs = {
  input: CreateOrgMembershipsInput;
};


export type MutationCreateMetricAnthropometryEntriesArgs = {
  input: ReadonlyArray<CreateMetricAnthropometryEntryInput>;
};


export type MutationCreateMetricAnthropometryEntryArgs = {
  input: CreateMetricAnthropometryEntryInput;
};


export type MutationCreateMetricAthleteArgs = {
  input: CreateMetricAthleteInput;
};


export type MutationCreateMetricAthletesArgs = {
  input: ReadonlyArray<CreateMetricAthleteInput>;
};


export type MutationCreateMetricMealPlanArgs = {
  input: CreateMetricMealPlanInput;
};


export type MutationCreateMetricMealPlanFromFoodLogsArgs = {
  input: CreateMetricMealPlanFromFoodLogsInput;
};


export type MutationCreateMetricMealPlanFromMealPlanTemplateArgs = {
  input: CreateMetricMealPlanFromMealPlanTemplateInput;
};


export type MutationCreateNoteArgs = {
  input: CreateNoteInput;
};


export type MutationCreateNoteTypeArgs = {
  input: CreateNoteTypeInput;
};


export type MutationCreateOrgArgs = {
  input: CreateOrgInput;
};


export type MutationCreateOrgFoodGroupArgs = {
  input: CreateFoodGroupInput;
};


export type MutationCreateOrgFoodServingsArgs = {
  input: CreateFoodServingsInput;
};


export type MutationCreateOrgFromTeamworksOrgArgs = {
  input: CreateOrgFromTeamworksOrgInput;
};


export type MutationCreateOrgGenericFoodArgs = {
  input: CreateGenericFoodInput;
};


export type MutationCreateOrgGroupArgs = {
  input: CreateOrgGroupInput;
};


export type MutationCreateOrgMembershipForAthleteArgs = {
  input: CreateOrgMembershipForAthleteInput;
};


export type MutationCreatePaymentMethodArgs = {
  input: CreatePaymentMethodInput;
};


export type MutationCreatePlannedMenuArgs = {
  input: CreatePlannedMenuInput;
};


export type MutationCreatePrepPlanArgs = {
  input: CreatePrepPlanInput;
};


export type MutationCreatePrepPlanMealArgs = {
  input: CreatePrepPlanMealInput;
};


export type MutationCreateRecipeFullArgs = {
  input: CreateRecipeFullInput;
};


export type MutationCreateRecipeSimpleArgs = {
  input: CreateRecipeSimpleInput;
};


export type MutationCreateRestaurantArgs = {
  input: CreateRestaurantInput;
};


export type MutationCreateRestaurantBrandedFoodArgs = {
  input: CreateRestaurantBrandedFoodInput;
};


export type MutationCreateRestaurantMenuArgs = {
  input: CreateRestaurantMenuInput;
};


export type MutationCreateRestaurantTypeArgs = {
  input: CreateRestaurantTypeInput;
};


export type MutationCreateReusableMenuItemArgs = {
  input: CreateReusableMenuItemInput;
};


export type MutationCreateScheduleArgs = {
  input: CreateScheduleInput;
};


export type MutationCreateSharedExchangeSetArgs = {
  input: CreateExchangeSetInput;
};


export type MutationCreateSharedFoodGroupArgs = {
  input: CreateFoodGroupInput;
};


export type MutationCreateSharedMacroMealPlanTemplateArgs = {
  input: CreateMacroMealPlanTemplateInput;
};


export type MutationCreateSmartTagArgs = {
  input: CreateSmartTagInput;
};


export type MutationCreateTeamArgs = {
  input: CreateTeamInput;
};


export type MutationCreateTerritoryArgs = {
  input: CreateTerritoryInput;
};


export type MutationCreateUserBrandedFoodArgs = {
  input: CreateUserBrandedFoodFormInput;
};


export type MutationCreateWeightUnitServingForFoodArgs = {
  input: CreateWeightUnitServingForFoodInput;
};


export type MutationDeleteAnthropometryEntryArgs = {
  input: DeleteAnthropometryEntryInput;
};


export type MutationDeleteAthleteArgs = {
  input: DeleteAthleteInput;
};


export type MutationDeleteCuisineArgs = {
  input: DeleteCuisineInput;
};


export type MutationDeleteDietArgs = {
  input: DeleteDietInput;
};


export type MutationDeleteDiningStationTemplateArgs = {
  input: DeleteDiningStationTemplateInput;
};


export type MutationDeleteExchangeSetArgs = {
  input: DeleteExchangeSetInput;
};


export type MutationDeleteGoalConfigurationArgs = {
  input: DeleteGoalConfigurationInput;
};


export type MutationDeleteGroceryListCustomItemArgs = {
  input: DeleteGroceryListCustomItemInput;
};


export type MutationDeleteGroceryListFoodItemArgs = {
  input: DeleteGroceryListFoodItemInput;
};


export type MutationDeleteInProgressPrepPlanArgs = {
  input: DeleteInProgressPrepPlanInput;
};


export type MutationDeleteMacroMealPlanTemplateArgs = {
  input: DeleteMacroMealPlanTemplateInput;
};


export type MutationDeleteMacroMealPlanTemplatePendingStateArgs = {
  input: DeleteMacroMealPlanTemplatePendingStateInput;
};


export type MutationDeleteMealMenuArgs = {
  input: DeleteMealMenuInput;
};


export type MutationDeleteMealPlanArgs = {
  input: DeleteMealPlanInput;
};


export type MutationDeleteMealPlansArgs = {
  input: DeleteMealPlansInput;
};


export type MutationDeleteNoteArgs = {
  input: DeleteNoteInput;
};


export type MutationDeleteNoteTypeArgs = {
  input: DeleteNoteTypeInput;
};


export type MutationDeleteOrgFoodArgs = {
  input: DeleteFoodInput;
};


export type MutationDeleteOrgGroupMacroMealPlanTemplateArgs = {
  input: DeleteMacroMealPlanTemplateInput;
};


export type MutationDeleteOrgGroupMacroMealPlanTemplatePendingStateArgs = {
  input: DeleteMacroMealPlanTemplatePendingStateInput;
};


export type MutationDeletePaymentMethodArgs = {
  input: DeletePaymentMethodInput;
};


export type MutationDeletePlannedMenuArgs = {
  input: DeletePlannedMenuInput;
};


export type MutationDeleteRecipeArgs = {
  input: DeleteRecipeInput;
};


export type MutationDeleteRestaurantTypeArgs = {
  input: DeleteRestaurantTypeInput;
};


export type MutationDeleteReusableMenuItemArgs = {
  input: DeleteReusableMenuItemInput;
};


export type MutationDeleteSharedExchangeSetArgs = {
  input: DeleteExchangeSetInput;
};


export type MutationDeleteSharedMacroMealPlanTemplateArgs = {
  input: DeleteMacroMealPlanTemplateInput;
};


export type MutationDeleteSharedMacroMealPlanTemplatePendingStateArgs = {
  input: DeleteMacroMealPlanTemplatePendingStateInput;
};


export type MutationDeleteTagArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTeamArgs = {
  input: DeleteTeamInput;
};


export type MutationDeleteUserBrandedFoodArgs = {
  input: DeleteUserBrandedFoodInput;
};


export type MutationDeprecateRestaurantBrandedFoodArgs = {
  input: DeprecateRestaurantBrandedFoodInput;
};


export type MutationDeprecateSharedFoodArgs = {
  input: DeprecateFoodInput;
};


export type MutationDeselectNegativeFoodsArgs = {
  mealPlanId: Scalars['ID'];
};


export type MutationDistributeExchangeTargetsArgs = {
  mealPlanId: Scalars['ID'];
};


export type MutationDistributeMacroTargetsArgs = {
  mealPlanId: Scalars['ID'];
};


export type MutationDuplicatePlannedMenuArgs = {
  input: DuplicatePlannedMenuInput;
};


export type MutationEditAgreementFormArgs = {
  input: EditAgreementFormInput;
};


export type MutationEditAnthropometryEntryArgs = {
  input: EditAnthropometryEntryInput;
};


export type MutationEditAnthropometryEntryCommentArgs = {
  input: EditAnthropometryEntryCommentInput;
};


export type MutationEditAthleteArgs = {
  input: EditAthleteInput;
};


export type MutationEditAthleteDisplaySettingsArgs = {
  input: EditAthleteDisplaySettingsInput;
};


export type MutationEditAthleteFoodLogNotificationSettingsArgs = {
  input: EditAthleteFoodLogNotificationSettingsInput;
};


export type MutationEditAthletesArgs = {
  input: ReadonlyArray<EditAthleteAndAnthroInput>;
};


export type MutationEditBrandArgs = {
  input: EditBrandInput;
};


export type MutationEditBrandedFoodArgs = {
  input: EditBrandedFoodInput;
};


export type MutationEditBulkOrderArgs = {
  input: EditBulkOrderInput;
};


export type MutationEditClientCredentialArgs = {
  input: EditClientCredentialInput;
};


export type MutationEditCuisineArgs = {
  input: EditCuisineInput;
};


export type MutationEditCustomTagArgs = {
  input: EditCustomTagInput;
};


export type MutationEditCustomizedPrepPlanMealArgs = {
  input: EditCustomizedPrepPlanMealInput;
};


export type MutationEditDeliveryLocationArgs = {
  input: EditDeliveryLocationInput;
};


export type MutationEditDietArgs = {
  input: EditDietInput;
};


export type MutationEditDiningStationTemplateArgs = {
  input: EditDiningStationTemplateInput;
};


export type MutationEditExchangeMealTemplateArgs = {
  input: EditExchangeMealTemplateInput;
};


export type MutationEditExchangeMealTemplatesArgs = {
  input: ReadonlyArray<EditExchangeMealTemplateInput>;
};


export type MutationEditExchangeSetArgs = {
  input: EditExchangeSetInput;
};


export type MutationEditFoodLogActivityRpeArgs = {
  input: EditFoodLogActivityRpeInput;
};


export type MutationEditFoodLogActivityTemplateArgs = {
  input: EditFoodLogActivityTemplateInput;
};


export type MutationEditFoodLogActivityTimeWithTimezoneArgs = {
  input: EditFoodLogActivityTimeWithTimezoneInput;
};


export type MutationEditFoodLogMealServingAmountsArgs = {
  input: EditFoodLogMealServingAmountsInput;
};


export type MutationEditFoodLogMealTemplateArgs = {
  input: EditFoodLogMealTemplateInput;
};


export type MutationEditFoodLogMealTimeWithTimezoneArgs = {
  input: EditFoodLogMealTimeWithTimezoneInput;
};


export type MutationEditFoodServingArgs = {
  input: EditFoodServingInput;
};


export type MutationEditGenericFoodArgs = {
  input: EditGenericFoodInput;
};


export type MutationEditGoalConfigurationWithDaysOfWeekArgs = {
  input: EditGoalConfigurationWithDaysOfWeekInput;
};


export type MutationEditGoalConfigurationWithIndividualDatesArgs = {
  input: EditGoalConfigurationWithIndividualDatesInput;
};


export type MutationEditGroceryListArgs = {
  input: EditGroceryListInput;
};


export type MutationEditGroceryListCustomItemArgs = {
  input: EditGroceryListCustomItemInput;
};


export type MutationEditGroceryListFoodItemArgs = {
  input: EditGroceryListFoodItemInput;
};


export type MutationEditMacroMealPlanTemplateArgs = {
  input: MacroMealPlanTemplatePendingStateInput;
};


export type MutationEditMacroMealTemplateArgs = {
  input: EditMacroMealTemplateInput;
};


export type MutationEditMacroMealTemplatesArgs = {
  input: ReadonlyArray<EditMacroMealTemplateInput>;
};


export type MutationEditMacroProtocolArgs = {
  input: EditMacroProtocolInput;
};


export type MutationEditMacroProtocolWithNewGoalArgs = {
  input: EditMacroProtocolWithNewGoalInput;
};


export type MutationEditMacroProtocolWithOldGoalArgs = {
  input: EditMacroProtocolWithOldGoalInput;
};


export type MutationEditMealMenuArgs = {
  input: EditMealMenuInput;
};


export type MutationEditMealMenuLogItemArgs = {
  input: EditMealMenuLogItemInput;
};


export type MutationEditMealMenuWithIdentityArgs = {
  input: EditMealMenuWithIdentityInput;
};


export type MutationEditMealOptionArgs = {
  input: EditMealOptionInput;
};


export type MutationEditMealOptionLocalArgs = {
  input: EditMealOptionLocalInput;
};


export type MutationEditMealOptionPendingArgs = {
  input: EditMealOptionPendingInput;
};


export type MutationEditMealOptionPendingLocalArgs = {
  input: EditMealOptionPendingLocalInput;
};


export type MutationEditMealPlanCalendarArgs = {
  input: EditMealPlanCalendarInput;
};


export type MutationEditMealPlanDateArgs = {
  input: EditMealPlanDateInput;
};


export type MutationEditMealPlanDateForAthleteArgs = {
  input: EditMealPlanDateForAthleteInput;
};


export type MutationEditMealPlanExchangeTargetsArgs = {
  input: EditMealPlanExchangeTargetsInput;
};


export type MutationEditMealPlanIsSharedArgs = {
  input: EditMealPlanIsSharedInput;
};


export type MutationEditMealPlanNewArgs = {
  input: EditMealPlanNewInput;
};


export type MutationEditMealPlansIsSharedArgs = {
  input: EditMealPlansIsSharedInput;
};


export type MutationEditMembershipsArgs = {
  input: EditOrgMembershipsInput;
};


export type MutationEditMenuItemImageArgs = {
  input: EditMenuItemImageInput;
};


export type MutationEditMenuOrderArgs = {
  input: EditMenuOrderInput;
};


export type MutationEditMenuOrderMealTemplateArgs = {
  input: EditMenuOrderMealTemplateInput;
};


export type MutationEditMetricAnthropometryEntriesArgs = {
  input: ReadonlyArray<EditMetricAnthropometryEntryInput>;
};


export type MutationEditMetricAnthropometryEntryArgs = {
  input: EditMetricAnthropometryEntryInput;
};


export type MutationEditMetricAnthropometryEntryWeighInForDateArgs = {
  input: EditMetricAnthropometryEntryWeighInForDateInput;
};


export type MutationEditMetricAnthropometryEntryWeighInForDateForAthleteArgs = {
  input: EditMetricAnthropometryEntryWeighInForDateForAthleteInput;
};


export type MutationEditMetricAthletesArgs = {
  input: ReadonlyArray<EditAthleteAndMetricAnthroInput>;
};


export type MutationEditMetricMacroProtocolArgs = {
  input: EditMetricMacroProtocolInput;
};


export type MutationEditNoteArgs = {
  input: EditNoteInput;
};


export type MutationEditNoteTypeArgs = {
  input: EditNoteTypeInput;
};


export type MutationEditOrgArgs = {
  input: EditOrgInput;
};


export type MutationEditOrgAutoShareNewNotemealRecipesArgs = {
  input: EditOrgAutoShareNewNotemealRecipesInput;
};


export type MutationEditOrgFeatureFlagsArgs = {
  input: EditOrgFeatureFlagsInput;
};


export type MutationEditOrgFoodGroupArgs = {
  input: EditFoodGroupInput;
};


export type MutationEditOrgFoodServingArgs = {
  input: EditFoodServingInput;
};


export type MutationEditOrgGenericFoodArgs = {
  input: EditGenericFoodInput;
};


export type MutationEditOrgGroupArgs = {
  input: EditOrgGroupInput;
};


export type MutationEditOrgGroupMacroMealPlanTemplateArgs = {
  input: MacroMealPlanTemplatePendingStateInput;
};


export type MutationEditOrgTeamworksProfilesArgs = {
  input: EditOrgTeamworksProfilesInput;
};


export type MutationEditPaymentMethodArgs = {
  input: EditPaymentMethodInput;
};


export type MutationEditPlannedMenuArgs = {
  input: EditPlannedMenuInput;
};


export type MutationEditPrepPlanArgs = {
  input: EditPrepPlanInput;
};


export type MutationEditPrepPlanMealArgs = {
  input: EditPrepPlanMealInput;
};


export type MutationEditRecipeFullArgs = {
  input: EditRecipeFullInput;
};


export type MutationEditRecipeIsSharedArgs = {
  input: EditRecipeIsSharedInput;
};


export type MutationEditRecipePartialArgs = {
  input: EditRecipePartialInput;
};


export type MutationEditRecipeSimpleArgs = {
  input: EditRecipeSimpleInput;
};


export type MutationEditRestaurantArgs = {
  input: EditRestaurantInput;
};


export type MutationEditRestaurantBrandedFoodArgs = {
  input: EditRestaurantBrandedFoodInput;
};


export type MutationEditRestaurantLocationArgs = {
  input: EditRestaurantLocationInput;
};


export type MutationEditRestaurantLocationContactArgs = {
  input: EditRestaurantLocationContactInput;
};


export type MutationEditRestaurantMenuArgs = {
  input: EditRestaurantMenuInput;
};


export type MutationEditRestaurantMenuLinkArgs = {
  input: EditRestaurantMenuLinkInput;
};


export type MutationEditRestaurantMenuLinkOrderArgs = {
  input: EditRestaurantMenuLinkOrderInput;
};


export type MutationEditRestaurantMenuLinkOrderMealTemplateArgs = {
  input: EditRestaurantMenuLinkOrderMealTemplateInput;
};


export type MutationEditRestaurantMenuLinkPlateOrderMealTemplateArgs = {
  input: EditRestaurantMenuLinkPlateOrderMealTemplateInput;
};


export type MutationEditRestaurantMenuLogArgs = {
  input: EditRestaurantMenuLogInput;
};


export type MutationEditRestaurantMenuLogItemArgs = {
  input: EditRestaurantMenuLogItemInput;
};


export type MutationEditRestaurantMenuLogMealTemplateArgs = {
  input: EditRestaurantMenuLogMealTemplateInput;
};


export type MutationEditRestaurantTypeArgs = {
  input: EditRestaurantTypeInput;
};


export type MutationEditReusableMenuItemArgs = {
  input: EditReusableMenuItemInput;
};


export type MutationEditReusableMenuItemPartialArgs = {
  input: EditReusableMenuItemPartialInput;
};


export type MutationEditScheduleArgs = {
  input: EditScheduleInput;
};


export type MutationEditSharedExchangeSetArgs = {
  input: EditExchangeSetInput;
};


export type MutationEditSharedFoodGroupArgs = {
  input: EditFoodGroupInput;
};


export type MutationEditSharedMacroMealPlanTemplateArgs = {
  input: MacroMealPlanTemplatePendingStateInput;
};


export type MutationEditSmartTagArgs = {
  input: EditSmartTagInput;
};


export type MutationEditSyncedAthleteArgs = {
  input: EditSyncedAthleteInput;
};


export type MutationEditSyncedAthletesArgs = {
  input: ReadonlyArray<EditSyncedAthleteAndAnthroInput>;
};


export type MutationEditSyncedMetricAthletesArgs = {
  input: ReadonlyArray<EditSyncedAthleteAndMetricAnthroInput>;
};


export type MutationEditTeamArgs = {
  input: EditTeamInput;
};


export type MutationEditTeamDisplaySettingsArgs = {
  input: EditTeamDisplaySettingsInput;
};


export type MutationEditTeamFoodLogNotificationSettingsArgs = {
  input: EditTeamFoodLogNotificationSettingsInput;
};


export type MutationEditTeamForStaffArgs = {
  input: EditTeamForStaffInput;
};


export type MutationEditTeamLabelArgs = {
  input: EditTeamLabelInput;
};


export type MutationEditTerritoryArgs = {
  input: EditTerritoryInput;
};


export type MutationEditTimelineMealDetailsArgs = {
  input: EditTimelineMealDetailsInput;
};


export type MutationEditTimelineMealMealTemplateArgs = {
  input: EditTimelineMealMealTemplateInput;
};


export type MutationEditTimelineMealServingAmountArgs = {
  input: EditTimelineMealServingAmountInput;
};


export type MutationEditUserBrandedFoodArgs = {
  input: EditUserBrandedFoodFormInput;
};


export type MutationEditUserTeamworksIdToOverwriteArgs = {
  input: EditUserTeamworksIdToOverwriteInput;
};


export type MutationFinalizePrepPlanArgs = {
  input: FinalizePrepPlanInput;
};


export type MutationForceLockPlannedMenuArgs = {
  input: ForceLockPlannedMenuInput;
};


export type MutationGetPossibleGptRecipeByNameArgs = {
  input: GptRecipeByNameInput;
};


export type MutationGetPossibleGptRecipeFromImageArgs = {
  input: GptRecipeFromImageInput;
};


export type MutationGetPossibleGptRecipeFromUrlArgs = {
  input: GptRecipeFromUrlInput;
};


export type MutationImportBonAppetitMenusArgs = {
  input: ImportBonAppetitMenusInput;
};


export type MutationImportCbordMenusArgs = {
  input: ImportCbordMenusInput;
};


export type MutationImportCompassMenusArgs = {
  input: ImportCompassMenusInput;
};


export type MutationImportCompassRecipeArgs = {
  input: ImportCompassRecipeInput;
};


export type MutationImportPlannedMenuArgs = {
  input: ImportPlannedMenuInput;
};


export type MutationImportSodexoMenusArgs = {
  input: ImportSodexoMenusInput;
};


export type MutationLinkTeamworksOrgArgs = {
  input: LinkTeamworksOrgInput;
};


export type MutationLockPlannedMenuArgs = {
  input: LockPlannedMenuInput;
};


export type MutationMarkNotificationsAsViewedArgs = {
  input: MarkNotificationsAsViewedInput;
};


export type MutationMarkNotificationsAsViewedBeforeArgs = {
  input: MarkNotificationsAsViewedBeforeInput;
};


export type MutationMoveMacroMealPlanTemplateToOrgGroupArgs = {
  input: MoveMacroMealPlanTemplateToOrgGroupInput;
};


export type MutationMoveOrgFoodToOrgGroupArgs = {
  input: MoveOrgFoodToOrgGroupInput;
};


export type MutationMoveOrgGroupFoodToMyOrgArgs = {
  input: MoveOrgGroupFoodToMyOrgInput;
};


export type MutationMoveOrgGroupMacroMealPlanTemplateToOrgArgs = {
  input: MoveOrgGroupMacroMealPlanTemplateToOrgInput;
};


export type MutationMoveOrgRecipeToOrgGroupArgs = {
  input: MoveOrgRecipeToOrgGroupInput;
};


export type MutationOverrideMealMenuIdentityArgs = {
  input: OverrideMealMenuIdentityInput;
};


export type MutationPublishMacroMealPlanTemplateArgs = {
  input: PublishMacroMealPlanTemplateInput;
};


export type MutationPublishOrgGroupMacroMealPlanTemplateArgs = {
  input: PublishMacroMealPlanTemplateInput;
};


export type MutationPublishSharedMacroMealPlanTemplateArgs = {
  input: PublishMacroMealPlanTemplateInput;
};


export type MutationRecieveAthleteTransferArgs = {
  input: RecieveAthleteTransferInput;
};


export type MutationReclaimAthleteTransferArgs = {
  input: ReclaimAthleteTransferInput;
};


export type MutationRecordMenuOrderSuggestionDecisionArgs = {
  input: RecordMenuOrderSuggestionDecisionInput;
};


export type MutationRecordMenuOrderSuggestionsViewArgs = {
  input: RecordMenuOrderSuggestionsViewInput;
};


export type MutationRefreshClientCredentialSecretArgs = {
  input: RefreshClientCredentialSecretInput;
};


export type MutationRefreshTeamworksSyncStatusArgs = {
  input: RefreshTeamworksSyncStatusInput;
};


export type MutationRemoveAthleteDislikedFoodArgs = {
  input: AthleteFoodPreferenceInput;
};


export type MutationRemoveAthleteDislikedFoodGroupArgs = {
  input: AthleteFoodGroupPreferenceInput;
};


export type MutationRemoveAthleteLikedFoodArgs = {
  input: AthleteFoodPreferenceInput;
};


export type MutationRemoveAthleteLikedFoodGroupArgs = {
  input: AthleteFoodGroupPreferenceInput;
};


export type MutationRemoveBulkOrderArgs = {
  input: RemoveBulkOrderInput;
};


export type MutationRemoveFoodLogActivityArgs = {
  input: RemoveFoodLogActivityInput;
};


export type MutationRemoveFoodLogActivityTemplateArgs = {
  input: RemoveFoodLogActivityTemplateInput;
};


export type MutationRemoveFoodLogMealArgs = {
  input: RemoveFoodLogMealInput;
};


export type MutationRemoveFoodLogMealImageArgs = {
  input: RemoveFoodLogMealImageInput;
};


export type MutationRemoveFoodLogMealTemplateArgs = {
  input: RemoveFoodLogMealTemplateInput;
};


export type MutationRemoveMealMenuLogItemArgs = {
  input: RemoveMealMenuLogItemInput;
};


export type MutationRemoveMealOptionArgs = {
  input: RemoveMealOptionInput;
};


export type MutationRemoveMealOptionLocalArgs = {
  mealTemplateId: Scalars['ID'];
  type: Scalars['String'];
  mealOptionId: Scalars['ID'];
};


export type MutationRemoveMealOptionPendingArgs = {
  input: RemoveMealOptionPendingInput;
};


export type MutationRemoveMealOptionPendingLocalArgs = {
  mealTemplateId: Scalars['ID'];
  type: Scalars['String'];
  mealOptionPendingId: Scalars['ID'];
};


export type MutationRemoveMealPlanDateArgs = {
  input: RemoveMealPlanDateInput;
};


export type MutationRemoveMealPlanDateForAthleteArgs = {
  input: RemoveMealPlanDateForAthleteInput;
};


export type MutationRemoveMenuOrderArgs = {
  input: RemoveMenuOrderInput;
};


export type MutationRemoveMenuOrderImageArgs = {
  input: RemoveMenuOrderImageInput;
};


export type MutationRemoveMenuOrderMealTemplateArgs = {
  input: RemoveMenuOrderMealTemplateInput;
};


export type MutationRemoveMyDislikedFoodArgs = {
  input: MyFoodPreferenceInput;
};


export type MutationRemoveMyDislikedFoodGroupArgs = {
  input: MyFoodGroupPreferenceInput;
};


export type MutationRemoveMyLikedFoodArgs = {
  input: MyFoodPreferenceInput;
};


export type MutationRemoveMyLikedFoodGroupArgs = {
  input: MyFoodGroupPreferenceInput;
};


export type MutationRemovePrepPlanMealArgs = {
  input: RemovePrepPlanMealInput;
};


export type MutationRemoveRecipeImageArgs = {
  input: RemoveRecipeImageInput;
};


export type MutationRemoveRestaurantMenuLinkArgs = {
  input: RemoveRestaurantMenuLinkInput;
};


export type MutationRemoveRestaurantMenuLinkOrderArgs = {
  input: RemoveRestaurantMenuLinkOrderInput;
};


export type MutationRemoveRestaurantMenuLinkOrderMealTemplateArgs = {
  input: RemoveRestaurantMenuLinkOrderMealTemplateInput;
};


export type MutationRemoveRestaurantMenuLinkPlateOrderArgs = {
  input: RemoveRestaurantMenuLinkPlateOrderInput;
};


export type MutationRemoveRestaurantMenuLinkPlateOrderMealTemplateArgs = {
  input: RemoveRestaurantMenuLinkPlateOrderMealTemplateInput;
};


export type MutationRemoveRestaurantMenuLogArgs = {
  input: RemoveRestaurantMenuLogInput;
};


export type MutationRemoveRestaurantMenuLogItemArgs = {
  input: RemoveRestaurantMenuLogItemInput;
};


export type MutationRemoveRestaurantMenuLogMealTemplateArgs = {
  input: RemoveRestaurantMenuLogMealTemplateInput;
};


export type MutationRemoveTimelineMealImageArgs = {
  input: RemoveTimelineMealImageInput;
};


export type MutationRemoveTimelineMealServingAmountArgs = {
  input: RemoveTimelineMealServingAmountInput;
};


export type MutationRunMaintainTeamworksFullySyncedJobArgs = {
  input: RefreshTeamworksSyncStatusInput;
};


export type MutationSavePlannedMealsArgs = {
  input: SavePlannedMealsInput;
};


export type MutationSelectMealOptionArgs = {
  mealTemplateId: Scalars['ID'];
  type: Scalars['String'];
  mealOptionId: Scalars['ID'];
};


export type MutationSendMealMenuNotificationArgs = {
  mealMenuId: Scalars['ID'];
};


export type MutationSetGoalTrackerGoalArgs = {
  input: SetGoalTrackerGoalInput;
};


export type MutationSetMealMenuCheckInsForAthleteArgs = {
  input: SetMealMenuCheckInsInput;
};


export type MutationSetMealMenuLogItemPercentConsumedArgs = {
  input: SetMenuSelectionItemPercentConsumedInput;
};


export type MutationSetMealOptionsForMealTemplatesArgs = {
  input: SetMealOptionsForMealTemplatesInput;
};


export type MutationSetMenuOrderItemPercentConsumedArgs = {
  input: SetMenuSelectionItemPercentConsumedInput;
};


export type MutationSetPrepPlanGuidedBuilderArgs = {
  input: SetPrepPlanGuidedBuilderInput;
};


export type MutationSetPrepPlanMealAssignmentSlotsArgs = {
  input: SetPrepPlanMealAssignmentSlotsInput;
};


export type MutationSetRestaurantMenuLinkOrderItemPercentConsumedArgs = {
  input: SetMenuSelectionItemPercentConsumedInput;
};


export type MutationSetRestaurantMenuLinkPlateOrderItemPercentConsumedArgs = {
  input: SetRestaurantMenuLinkPlateOrderItemPercentConsumedInput;
};


export type MutationSetRestaurantMenuLogItemPercentConsumedArgs = {
  input: SetMenuSelectionItemPercentConsumedInput;
};


export type MutationSetTimelineMealServingAmountsForAthleteArgs = {
  input: SetTimelineMealServingAmountsForAthleteInput;
};


export type MutationSetTimelineMealServingAmountsForMeArgs = {
  input: SetTimelineMealServingAmountsInput;
};


export type MutationShareMealOptionPendingArgs = {
  input: ShareMealOptionPendingInput;
};


export type MutationShareMealOptionPendingLocalArgs = {
  mealTemplateId: Scalars['ID'];
  mealOptionPendingId: Scalars['ID'];
};


export type MutationTextMealPlanToAthleteArgs = {
  input: TextMealPlanToAthleteInput;
};


export type MutationTextMealPlansToAthletesArgs = {
  input: TextMealPlansToAthletesInput;
};


export type MutationUnarchiveAthleteArgs = {
  input: UnarchiveAthleteInput;
};


export type MutationUnlockPlannedMenuArgs = {
  input: UnlockPlannedMenuInput;
};


export type MutationUpdateBulkOrderStatusArgs = {
  bulkOrderId: Scalars['ID'];
  status: MenuOrderItemStatus;
};


export type MutationUpdateMacroMealPlanTemplateAssignScopeArgs = {
  input: UpdateMealPlanTemplateAssignScopeInput;
};


export type MutationUpdateMacroMealPlanTemplateSharingScopeArgs = {
  input: UpdateMacroMealPlanTemplateSharingScopeInput;
};


export type MutationUpdateMealMenuPlateItemOrderStatusArgs = {
  input: UpdateMealMenuPlateItemOrderStatusInput;
};


export type MutationUpdateMealMenuPlateOrderStatusArgs = {
  mealMenuPlateOrderId: Scalars['String'];
  status: MenuOrderItemStatus;
};


export type MutationUpdateMealMenuPlateOrderStatusesArgs = {
  mealMenuPlateOrderIds: ReadonlyArray<Scalars['String']>;
  status: MenuOrderItemStatus;
};


export type MutationUpdateMenuOrderItemStatusesArgs = {
  input: UpdateMenuOrderItemStatusesInput;
};


export type MutationUpdateMultipleMealMenuPlateItemOrderStatusesArgs = {
  input: UpdateMultipleMealMenuPlateItemOrderStatusesInput;
};


export type MutationUpdateMultipleMenuOrderItemStatusesArgs = {
  input: UpdateMultipleMenuOrderItemStatusesInput;
};


export type MutationUpdateSharedMacroMealPlanTemplateSharingScopeArgs = {
  input: UpdateMacroMealPlanTemplateSharingScopeInput;
};

export type MyFoodGroupPreferenceInput = {
  readonly foodGroupId: Scalars['ID'];
};

export type MyFoodPreferenceInput = {
  readonly foodId: Scalars['ID'];
};

export type MyNamedTagsPreviewPayload = {
  readonly previewNamedTags: ReadonlyArray<NamedTag>;
  readonly total: Scalars['Int'];
  readonly hiddenPreviewText: Maybe<Scalars['String']>;
};

export type MyRecipeCursorConnection = {
  readonly edges: ReadonlyArray<Recipe>;
  readonly pageInfo: CursorPageInfo;
};

export type NamedTag = {
  readonly tag: Tag;
  readonly tagName: TagName;
};

export type NamedTagCursorConnection = {
  readonly edges: ReadonlyArray<NamedTag>;
  readonly pageInfo: CursorPageInfo;
};

export type NamedTagOffsetConnection = {
  readonly edges: ReadonlyArray<NamedTag>;
  readonly pageInfo: OffsetPageInfo;
};

export type NoTargetsDisplaySettings = {
  readonly dailyProgressViewsForMacros: MacroProgressViews;
  readonly mealProgressViewsForMacros: MacroProgressViews;
};

export type NoTargetsDisplaySettingsInput = {
  readonly dailyProgressViewsForMacros: MacroProgressViews;
  readonly mealProgressViewsForMacros: MacroProgressViews;
};

export type NotSharedRecipeCursorConnection = {
  readonly edges: ReadonlyArray<Recipe>;
  readonly pageInfo: CursorPageInfo;
};

export type Note = {
  readonly id: Scalars['ID'];
  readonly datetime: Scalars['DateTime'];
  readonly content: Scalars['String'];
  readonly type: Maybe<NoteType>;
  readonly athlete: Athlete;
};

export type NoteFormInput = {
  readonly datetime: Scalars['DateTime'];
  readonly content: Scalars['String'];
  readonly noteTypeId: Maybe<Scalars['ID']>;
};

export type NoteType = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type NoteTypeFormInput = {
  readonly name: Scalars['String'];
};

export type NotemealAccountType =
  | 'athlete'
  | 'dietitian'
  | 'chef'
  | '%future added value';

export type Notification = {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
};

export type NotificationCursorConnection = {
  readonly edges: ReadonlyArray<Notification>;
  readonly pageInfo: CursorPageInfo;
};

export type NotificationPublication = {
  readonly payload: NotificationPublicationPayload;
  /** @deprecated Pending web notifications overhaul */
  readonly scope: NotificationPublisherScope;
};

export type NotificationPublicationPayload = {
  readonly notification: Notification;
};

export type NotificationPublisherScope = {
  readonly teamId: Maybe<Scalars['ID']>;
  readonly athleteId: Maybe<Scalars['ID']>;
  readonly foodLogMealId: Maybe<Scalars['ID']>;
};

export type Nutrient = {
  readonly id: Scalars['ID'];
  readonly fdcNutrientId: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly commonName: Maybe<Scalars['String']>;
  readonly unitName: Scalars['String'];
  readonly category: Scalars['String'];
  readonly defaultDietaryReferenceIntake: Maybe<NutrientDietaryReferenceIntake>;
};


export type NutrientDefaultDietaryReferenceIntakeArgs = {
  age: Maybe<Scalars['Int']>;
  sex: Maybe<SexType>;
};

export type NutrientAmount = {
  /** @deprecated Field no longer supported */
  readonly id: Maybe<Scalars['ID']>;
  /** @deprecated Field no longer supported */
  readonly footnote: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  readonly dataPoints: Maybe<Scalars['Int']>;
  /** @deprecated Field no longer supported */
  readonly min: Maybe<Scalars['Float']>;
  /** @deprecated Field no longer supported */
  readonly max: Maybe<Scalars['Float']>;
  /** @deprecated Field no longer supported */
  readonly median: Maybe<Scalars['Float']>;
  /** @deprecated Field no longer supported */
  readonly foodId: Maybe<Scalars['ID']>;
  readonly nutrient: Nutrient;
  readonly amount: Scalars['Float'];
};

export type NutrientDietaryReferenceIntake = {
  readonly id: Scalars['ID'];
  readonly amount: Maybe<Scalars['Float']>;
  readonly type: NutrientDietaryReferenceIntakeType;
};


export type NutrientDietaryReferenceIntakeAmountArgs = {
  kcal: Maybe<Scalars['Float']>;
};

export type NutrientDietaryReferenceIntakeType =
  | 'RDA'
  | 'UL'
  | 'AI'
  | 'DGA'
  | 'unitsPerKcal'
  | '%future added value';

export type NutritionixBrand = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly type: Scalars['Int'];
};

export type NutritionixBrandedFood = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly nixItemId: Scalars['String'];
  readonly brand: NutritionixBrand;
  readonly thumbnailUrl: Maybe<Scalars['String']>;
};

export type NutritionixBrandedInstantSearchPayload = {
  readonly branded: ReadonlyArray<NutritionixBrandedFood>;
};

export type NutritionixCommonFood = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly nixTagId: Scalars['String'];
  readonly thumbnailUrl: Maybe<Scalars['String']>;
};

export type NutritionixCommonInstantSearchPayload = {
  readonly common: ReadonlyArray<NutritionixCommonFood>;
};

export type NutritionixRestaurantLocation = {
  readonly id: Scalars['ID'];
  readonly brandId: Scalars['ID'];
  readonly address: Scalars['String'];
  readonly address2: Maybe<Scalars['String']>;
  readonly city: Scalars['String'];
  readonly state: Scalars['String'];
  readonly zip: Scalars['String'];
  readonly country: Scalars['String'];
  readonly lat: Maybe<Scalars['String']>;
  readonly long: Maybe<Scalars['String']>;
  readonly phoneNumber: Maybe<Scalars['String']>;
};

export type NutritionixRestaurantLocationSearchData = {
  readonly ll: Scalars['String'];
  readonly distance: Scalars['Int'];
  readonly brandId: Maybe<Scalars['String']>;
};

export type NutritionixRestaurantLocationSearchInput = {
  readonly appId: Scalars['String'];
  readonly appKey: Scalars['String'];
  readonly data: NutritionixRestaurantLocationSearchData;
};

export type NutritionixRestaurantLocationSearchPayload = {
  readonly locations: ReadonlyArray<NutritionixRestaurantLocation>;
};

export type NutritionixV1RestaurantSearchFilter = {
  readonly brand_id: Scalars['String'];
  readonly item_type: Scalars['Int'];
};

export type NutritionixV1RestaurantSearchInput = {
  readonly appId: Scalars['String'];
  readonly appKey: Scalars['String'];
  readonly fields: ReadonlyArray<Scalars['String']>;
  readonly filters: NutritionixV1RestaurantSearchFilter;
  readonly offset: Scalars['Int'];
  readonly limit: Scalars['Int'];
  readonly sort: NutritionixV1RestaurantSearchSort;
};

export type NutritionixV1RestaurantSearchPayload = {
  readonly total: Scalars['Int'];
  readonly branded: ReadonlyArray<NutritionixBrandedFood>;
};

export type NutritionixV1RestaurantSearchSort = {
  readonly field: Scalars['String'];
  readonly order: Scalars['String'];
};

export type OffsetPageInfo = {
  readonly hasNextPage: Scalars['Boolean'];
  readonly endOffset: Scalars['Int'];
  readonly total: Scalars['Int'];
};

export type OffsetPaginationInput = {
  readonly offset: Scalars['Int'];
  readonly limit: Scalars['Int'];
};

export type Org = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly isActive: Scalars['Boolean'];
  readonly allowsPublicAccess: Scalars['Boolean'];
  readonly memberships: ReadonlyArray<OrgMembership>;
  readonly featureFlags: FeatureFlags;
  readonly teamworksId: Maybe<Scalars['Int']>;
  readonly teams: ReadonlyArray<Team>;
  readonly teamworksProfileSyncRules: ReadonlyArray<TeamworksProfileSyncRule>;
  readonly teamworksSyncStatus: Maybe<TeamworksSyncStatus>;
  readonly athletes: ReadonlyArray<Athlete>;
  readonly integrations: ReadonlyArray<Integration>;
  readonly isFullySynced: Scalars['Boolean'];
  readonly isTeamworksLinked: Scalars['Boolean'];
  readonly isNotemealLinked: Scalars['Boolean'];
  readonly agreementForm: Maybe<AgreementForm>;
  readonly scoringSystem: Maybe<ScoringSystem>;
  readonly autoShareNewNotemealRecipes: Scalars['Boolean'];
  readonly localeCode: Scalars['String'];
  readonly hasTwSuperuserPersonId: Maybe<Scalars['Boolean']>;
  readonly territoryName: Maybe<Scalars['String']>;
};


export type OrgMembershipsArgs = {
  filterInactive: Maybe<Scalars['Boolean']>;
};


export type OrgAthletesArgs = {
  isArchived: Maybe<Scalars['Boolean']>;
};

export type OrgForReporting = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly teamworksId: Maybe<Scalars['Int']>;
  readonly territoryName: Maybe<Scalars['String']>;
  readonly performanceKitchen: Maybe<Scalars['Boolean']>;
};

export type OrgGroup = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly allowsResourceSharing: Scalars['Boolean'];
  readonly allowsAthleteTransfer: Scalars['Boolean'];
  readonly orgs: ReadonlyArray<Org>;
  readonly orgsCount: Scalars['Int'];
  readonly parentOrgGroup: Maybe<OrgGroup>;
  readonly childrenOrgGroups: ReadonlyArray<OrgGroup>;
  readonly createdBy: Maybe<User>;
};

export type OrgInfo = {
  readonly org: OrgResult;
  readonly orgMembership: Maybe<OrgMembership>;
  readonly athlete: Maybe<Athlete>;
};

export type OrgMembership = {
  readonly id: Scalars['ID'];
  readonly teamworksId: Maybe<Scalars['Int']>;
  readonly athlete: Maybe<Athlete>;
  readonly isActive: Scalars['Boolean'];
  readonly isAdmin: Scalars['Boolean'];
  readonly isNotemealOnly: Scalars['Boolean'];
  readonly lastRefresh: Maybe<Scalars['DateTime']>;
  readonly isSubscribedToDigest: Scalars['Boolean'];
  readonly user: User;
  readonly roles: ReadonlyArray<Role>;
};

export type OrgResult = {
  readonly name: Scalars['String'];
  readonly id: Scalars['ID'];
};

export type OverrideMealMenuIdentityInput = {
  readonly mealMenuIdentityId: Scalars['ID'];
};

export type OverrideMealMenuIdentityPayload = {
  readonly mealMenuIdentity: MealMenuIdentity;
};

export type OverrideMealPlanDateAssignmentInput = {
  readonly individualDates: Maybe<ReadonlyArray<Scalars['Date']>>;
  readonly startDate: Maybe<Scalars['Date']>;
  readonly endDate: Maybe<Scalars['Date']>;
  readonly daysOfWeek: Maybe<ReadonlyArray<DayOfWeek>>;
};

export type PaymentMethod = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type PaymentMethodCursorConnection = {
  readonly edges: ReadonlyArray<PaymentMethod>;
  readonly pageInfo: CursorPageInfo;
};

export type PaymentMethodOffsetConnection = {
  readonly edges: ReadonlyArray<PaymentMethod>;
  readonly pageInfo: OffsetPageInfo;
};

export type PlannedMenu = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly description: Maybe<Scalars['String']>;
  readonly firstOccurrence: Scalars['Date'];
  readonly lastOccurrence: Scalars['Date'];
  readonly occurrence: PlannedMenuOccurrence;
  readonly timezone: Scalars['String'];
  readonly startDate: Scalars['Date'];
  readonly endDate: Scalars['Date'];
  readonly weeks: ReadonlyArray<PlannedMenuWeek>;
  readonly mealTypes: ReadonlyArray<MealType>;
  readonly createdById: Maybe<Scalars['ID']>;
  readonly createdBy: Maybe<User>;
  readonly lastEditedAt: Maybe<Scalars['DateTime']>;
  readonly lastEditedBy: Maybe<User>;
  readonly athletesWithBirthdaysInRange: ReadonlyArray<Athlete>;
  readonly lockCreatedAt: Maybe<Scalars['DateTime']>;
  readonly lockCreatedBy: Maybe<User>;
};

export type PlannedMenuDisabledDaysInput = {
  readonly plannedMenuWeekId: Scalars['ID'];
  readonly disabledDays: ReadonlyArray<DayOfWeek>;
};

export type PlannedMenuLockTakenPayload = {
  readonly plannedMenu: PlannedMenu;
};

export type PlannedMenuLockTakenPublication = {
  readonly scope: PlannedMenuLockTakenPublisherScope;
  readonly payload: PlannedMenuLockTakenPayload;
};

export type PlannedMenuLockTakenPublisherScope = {
  readonly plannedMenuId: Scalars['ID'];
};

export type PlannedMenuLockTakenSubscriberScope = {
  readonly plannedMenuId: Scalars['ID'];
};

export type PlannedMenuMeal = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly type: MealType;
  readonly startTime: Scalars['String'];
  readonly endTime: Scalars['String'];
  readonly plannedMenuMealRows: ReadonlyArray<PlannedMenuMealRow>;
  readonly themes: ReadonlyArray<Scalars['String']>;
};

export type PlannedMenuMealInput = {
  readonly id: Maybe<Scalars['ID']>;
  readonly name: Scalars['String'];
  readonly type: MealType;
  readonly startTime: Scalars['String'];
  readonly endTime: Scalars['String'];
  readonly plannedMenuWeekId: Scalars['ID'];
  readonly plannedMenuMealRows: ReadonlyArray<PlannedMenuMealRowInput>;
  readonly themes: Maybe<ReadonlyArray<Scalars['String']>>;
};

export type PlannedMenuMealRow = {
  readonly id: Scalars['ID'];
  readonly diningStationName: Scalars['String'];
  readonly position: Scalars['Int'];
  readonly foodType: Maybe<Scalars['String']>;
  readonly plannedMenuMealRowItems: ReadonlyArray<PlannedMenuMealRowItem>;
};

export type PlannedMenuMealRowInput = {
  readonly id: Maybe<Scalars['ID']>;
  readonly diningStationName: Scalars['String'];
  readonly position: Scalars['Int'];
  readonly foodType: Maybe<Scalars['String']>;
  readonly plannedMenuMealRowItems: ReadonlyArray<PlannedMenuMealRowItemInput>;
};

export type PlannedMenuMealRowItem = {
  readonly id: Scalars['ID'];
  readonly dayOfWeek: DayOfWeek;
  readonly position: Scalars['Int'];
  readonly menuItem: MenuItem;
};

export type PlannedMenuMealRowItemInput = {
  readonly id: Maybe<Scalars['ID']>;
  readonly dayOfWeek: DayOfWeek;
  readonly position: Scalars['Int'];
  readonly menuItemId: Scalars['ID'];
};

export type PlannedMenuOccurrence =
  | 'daily'
  | 'weekdays'
  | '%future added value';

export type PlannedMenuOffsetConnection = {
  readonly edges: ReadonlyArray<PlannedMenu>;
  readonly pageInfo: OffsetPageInfo;
};

export type PlannedMenuWeek = {
  readonly id: Scalars['ID'];
  readonly week: Scalars['Int'];
  readonly meals: ReadonlyArray<PlannedMenuMeal>;
  readonly disabledDays: ReadonlyArray<DayOfWeek>;
};

export type PlateOrderIdItemIdTuple = {
  readonly mealMenuPlateOrderId: Scalars['ID'];
  readonly mealMenuPlateItemId: Scalars['ID'];
};

export type Position = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly teamworksMappings: ReadonlyArray<TeamworksPositionMapping>;
  readonly sport: Sport;
};

export type PositionBySportCursorConnection = {
  readonly edges: ReadonlyArray<Position>;
  readonly pageInfo: CursorPageInfo;
};

export type PositionTag = Tag & {
  readonly id: Scalars['ID'];
  readonly tagName: Maybe<TagName>;
  readonly athleteCount: Scalars['Int'];
  readonly isComplement: Scalars['Boolean'];
  readonly createdBy: Maybe<User>;
  readonly positions: ReadonlyArray<Position>;
};

export type PositionsMappingInput = {
  readonly teamworksName: Scalars['String'];
  readonly positionId: Scalars['ID'];
};

export type PreferredContactMethodType =
  | 'email'
  | 'phoneNumber'
  | '%future added value';

export type PrepPlan = {
  readonly id: Scalars['ID'];
  readonly start: Scalars['Date'];
  readonly end: Scalars['Date'];
  readonly inProgress: Scalars['Boolean'];
  readonly meals: ReadonlyArray<PrepPlanMeal>;
  readonly mealSlots: ReadonlyArray<PrepPlanMealAssignment>;
  readonly groceryList: Maybe<GroceryList>;
  readonly seedValue: Scalars['Int'];
};

export type PrepPlanCursorConnection = {
  readonly edges: ReadonlyArray<PrepPlan>;
  readonly pageInfo: CursorPageInfo;
};

export type PrepPlanMeal = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly priority: Scalars['Int'];
  readonly servingAmounts: ReadonlyArray<ServingAmount>;
  readonly prepPlanMealAssignments: ReadonlyArray<PrepPlanMealAssignment>;
  readonly edited: Scalars['Boolean'];
  readonly mealTypes: Maybe<ReadonlyArray<MealType>>;
  readonly guidedBuilder: Scalars['Boolean'];
  readonly recipe: Maybe<Recipe>;
  readonly mealOption: Maybe<MealOption>;
  readonly primaryImageUrl: Maybe<Scalars['String']>;
  readonly ingredientText: Scalars['String'];
};

export type PrepPlanMealAssignment = {
  readonly id: Scalars['ID'];
  readonly amount: Scalars['Int'];
  readonly position: Scalars['Int'];
  readonly date: Maybe<Scalars['Date']>;
  readonly type: Maybe<MealType>;
  readonly mealTemplate: Maybe<MealTemplate>;
  readonly prepPlanMeal: Maybe<PrepPlanMeal>;
};

export type PrepPlanMealAssignmentInput = {
  readonly amount: Scalars['Int'];
  readonly position: Scalars['Int'];
  readonly type: Maybe<MealType>;
  readonly date: Maybe<Scalars['Date']>;
  readonly mealTemplateId: Maybe<Scalars['ID']>;
};

export type PrepPlanMealSlotAssignmentInput = {
  readonly id: Scalars['ID'];
  readonly amount: Scalars['Int'];
};

export type PrepPlanMealsForTimelineMealInput = {
  readonly mealType: Maybe<MealType>;
  readonly date: Scalars['Date'];
};

export type PrepPlanMealsForTimelineMealPayload = {
  readonly scheduledMeals: ReadonlyArray<PrepPlanMeal>;
  readonly otherMeals: ReadonlyArray<PrepPlanMeal>;
};

export type PublishMacroMealPlanTemplateInput = {
  readonly macroMealPlanTemplateId: Scalars['ID'];
  readonly macroMealPlanTemplate: EditMacroMealPlanTemplateInput;
};

export type PublishMacroMealPlanTemplatePayload = {
  readonly macroMealPlanTemplate: MacroMealPlanTemplate;
};

export type Query = {
  readonly activeAthleteTransferByAthleteId: Maybe<AthleteTransfer>;
  readonly activeAthleteTransferOffsetConnection: ActiveAthleteTransferOffsetConnection;
  readonly activeAthleteTransfers: ReadonlyArray<AthleteTransfer>;
  readonly agreementFormOffsetConnection: AgreementFormOffsetConnection;
  readonly agreementForms: ReadonlyArray<AgreementForm>;
  readonly allDeliveryLocations: DeliveryLocationOffsetConnection;
  readonly allMealPlanTemplatesForDietitianCursorConnection: MealPlanTemplateCursorConnection;
  readonly allRestaurantLocations: RestaurantLocationOffsetConnection;
  readonly allRestaurants: RestaurantOffsetConnection;
  readonly allSchedules: ReadonlyArray<Schedule>;
  readonly allTeamLabels: TeamLabelOffsetConnection;
  readonly appModeRoles: AppModeRoles;
  readonly athlete: Athlete;
  readonly athleteGoalsInRange: ReadonlyArray<AthleteGoalConfigurationAndTracker>;
  readonly athleteSearch: ReadonlyArray<Athlete>;
  readonly athleteSearchCursorConnection: AthleteCursorConnection;
  readonly athleteTransferOrgGroups: ReadonlyArray<OrgGroup>;
  readonly athletes: ReadonlyArray<Athlete>;
  readonly athletesForBulkOrderCursorConnection: AthleteCursorConnectionWithSelectAll;
  readonly athletesForCustomTagCreation: ReadonlyArray<Athlete>;
  readonly athletesForCustomTagCreationCursorConnection: AthleteCursorConnection;
  readonly athletesForMealMenus: ReadonlyArray<Athlete>;
  readonly athletesForPendingAdvancedSelection: ReadonlyArray<Athlete>;
  readonly athletesForTag: ReadonlyArray<Athlete>;
  readonly athletesForTagCursorConnection: AthleteCursorConnection;
  readonly athletesForTagOffsetConnection: AthleteOffsetConnection;
  readonly athletesForTeam: ReadonlyArray<Athlete>;
  readonly athletesForTeamOffsetConnection: AthleteOffsetConnection;
  readonly bonAppetitCafes: ReadonlyArray<BonAppetitCafe>;
  readonly bonAppetitMenusPreview: BonAppetitMenusPreview;
  readonly brandWithUsdaManufacturerName: Maybe<Brand>;
  readonly brands: ReadonlyArray<Brand>;
  readonly bulkOrder: BulkOrder;
  readonly bulkOrderStatus: MenuOrderItemStatus;
  readonly bulkOrdersForMealMenu: ReadonlyArray<BulkOrder>;
  readonly bulkOrdersForTeam: ReadonlyArray<BulkOrder>;
  readonly bulkOrdersForUser: ReadonlyArray<BulkOrder>;
  readonly canBeStaff: Scalars['Boolean'];
  readonly cateringRestaurantMenuSearch: RestaurantMenuSearchCursorConnection;
  readonly cbordBusinessUnits: ReadonlyArray<CbordBusinessUnit>;
  readonly cbordServiceMenus: ReadonlyArray<CbordServiceMenu>;
  readonly clientCredentialOffsetConnection: ClientCredentialOffsetConnection;
  readonly compass_locations: ReadonlyArray<CompassLocation>;
  readonly compass_menuItems: ReadonlyArray<CompassMenuItem>;
  readonly compass_mrn_recipe: Maybe<CompassRecipe>;
  readonly compass_recipes: ReadonlyArray<CompassRecipe>;
  readonly convertedServing: Serving;
  readonly cuisineCursorConnection: CuisineCursorConnection;
  readonly cuisineOffsetConnection: CuisineOffsetConnection;
  readonly currentMenuItem: MenuItem;
  readonly daySummaries: ReadonlyArray<DaySummary>;
  readonly deliveryLocationCursorConnection: DeliveryLocationCursorConnection;
  readonly dietCursorConnection: DietCursorConnection;
  readonly dietOffsetConnection: DietOffsetConnection;
  readonly dietitiansICanMessageCursorConnection: MessageOptionCursorConnection;
  readonly diningStationTemplate: DiningStationTemplate;
  readonly diningStationTemplateCursorConnection: DiningStationTemplateCursorConnection;
  readonly diningStationTemplateOffsetConnection: DiningStationTemplateOffsetConnection;
  readonly exchangeSet: ExchangeSet;
  readonly exchangeSets: ReadonlyArray<ExchangeSet>;
  readonly food: Food;
  readonly foodGroup: FoodGroup;
  readonly foodGroups: ReadonlyArray<FoodGroup>;
  readonly foodGroupsById: ReadonlyArray<FoodGroup>;
  readonly foodItem: Maybe<GroceryListFoodItem>;
  readonly foodLogMeal: FoodLogMeal;
  readonly foodOffsetConnection: FoodOffsetConnection;
  readonly foodOrRecipe: FoodOrRecipe;
  readonly foods: ReadonlyArray<Food>;
  readonly foodsById: ReadonlyArray<Food>;
  readonly genericFood: GenericFood;
  /** @deprecated Use general foods query instead */
  readonly genericFoods: ReadonlyArray<GenericFood>;
  readonly getOpenAIImage: Maybe<Scalars['String']>;
  readonly goalCategories: ReadonlyArray<GoalCategory>;
  readonly goalConfiguration: GoalConfiguration;
  readonly goalConfigurationsForAthleteOffsetConnection: GoalConfigurationOffsetConnection;
  readonly goalConfigurationsForTeamOffsetConnection: GoalConfigurationOffsetConnection;
  readonly goalTrackerGoalOffsetConnection: GoalTrackerGoalOffsetConnection;
  readonly goalTrackerGoals: ReadonlyArray<GoalTrackerGoal>;
  readonly goalTypes: ReadonlyArray<GoalType>;
  readonly googleMapsApiKey: Scalars['String'];
  readonly gramUnit: Unit;
  readonly groceryList: Maybe<GroceryList>;
  readonly groceryListCategories: ReadonlyArray<GroceryListCategory>;
  readonly integrations: ReadonlyArray<IntegrationType>;
  readonly isTagNameUnique: IsTagNameUniquePayload;
  readonly locales: ReadonlyArray<Locale>;
  readonly loggingRestaurantMenuSearch: RestaurantMenuSearchCursorConnection;
  readonly me: Athlete;
  readonly mealEvents: ReadonlyArray<MealEvent>;
  readonly mealMenu: MealMenu;
  readonly mealMenuAttendees: MealMenuAttendeePayload;
  readonly mealMenuPlate: MealMenuPlate;
  readonly mealMenuPlateOrder: MealMenuPlateOrder;
  readonly mealMenuPlateOrders: ReadonlyArray<MealMenuPlateOrder>;
  readonly mealMenuPlateOrdersForUser: Maybe<ReadonlyArray<MealMenuPlateOrder>>;
  readonly mealMenus: ReadonlyArray<MealMenu>;
  readonly mealMenusCursorConnection: MealMenusCursorConnection;
  readonly mealMenusInDateRange: ReadonlyArray<MealMenu>;
  readonly mealMenusInRange: ReadonlyArray<MealMenu>;
  readonly mealMenusWithCheckInInRange: ReadonlyArray<MealMenusWithCheckinPayload>;
  readonly mealOptionCursorConnectionForMealTemplate: MealOptionCursorConnection;
  readonly mealOptionSuggestionCursorConnection: ServingAmountGroupCursorConnection;
  readonly mealOptionsForMealPlansCursorConnection: MealOptionCursorConnection;
  readonly mealOptionsForMealPlansCursorConnectionWithRandomSeededSort: MealOptionCursorConnection;
  readonly mealOptionsForMealPlansPreview: MealOptionCursorConnection;
  readonly mealOptionsForMealPlansPreviewWithRandomSeededSort: MealOptionCursorConnection;
  readonly mealPlan: MealPlan;
  readonly mealPlanForCopyCursorConnection: MealPlanCursorConnection;
  readonly mealPlanOffsetConnection: MealPlanOffsetConnection;
  readonly mealPlanTemplate: MealPlanTemplate;
  readonly mealPlanTemplateOffsetConnection: MealPlanTemplateOffsetConnection;
  readonly menuItem: MenuItem;
  readonly menuItemCursorConnection: MenuItemCursorConnection;
  readonly menuItemOffsetConnection: MenuItemOffsetConnection;
  readonly menuItemRatingOffsetConnection: MenuItemRatingOffsetConnection;
  readonly menuItems: ReadonlyArray<MenuItem>;
  readonly menuOrder: MenuOrder;
  readonly menuOrderCursorConnectionForItems: MenuOrderCursorConnection;
  readonly menuOrderCursorConnectionForItemsForAthlete: MenuOrderCursorConnection;
  readonly menuOrderRateLimitRemainders: ReadonlyArray<MenuOrderRateLimitRemainder>;
  readonly menuOrderSuggestions: Maybe<ReadonlyArray<MenuOrderSuggestion>>;
  readonly menuOrders: ReadonlyArray<MenuOrder>;
  readonly mobileUpdateForVersion: Maybe<MobileUpdateType>;
  readonly myCurrentGroceryList: Maybe<GroceryList>;
  readonly myCurrentPrepPlan: Maybe<PrepPlan>;
  readonly myFeatureFlags: FeatureFlags;
  readonly myGoalTrackerGoalsForDate: ReadonlyArray<GoalTrackerGoal>;
  readonly myOrg: Org;
  readonly myOrgForReporting: Maybe<OrgForReporting>;
  readonly myPastPrepPlanCursorConnection: PrepPlanCursorConnection;
  readonly myRecipeCursorConnection: MyRecipeCursorConnection;
  readonly myRecipePreview: MyRecipeCursorConnection;
  readonly myScoringSystem: Maybe<ScoringSystem>;
  readonly myStreamChatToken: Scalars['String'];
  readonly myTimelineMealsInRange: ReadonlyArray<TimelineMeal>;
  readonly myUnfinishedPrepPlan: Maybe<PrepPlan>;
  readonly myUser: User;
  readonly namedTag: NamedTag;
  readonly namedTagCursorConnection: NamedTagCursorConnection;
  readonly namedTagOffsetConnection: NamedTagOffsetConnection;
  readonly namedTagSearch: ReadonlyArray<NamedTag>;
  readonly nixAppId: Scalars['String'];
  readonly nixAppKey: Scalars['String'];
  readonly notSharedRecipeCursorConnection: NotSharedRecipeCursorConnection;
  readonly noteTypes: ReadonlyArray<NoteType>;
  readonly notesForTeam: ReadonlyArray<Note>;
  readonly notification: Maybe<Notification>;
  readonly notificationCount: Scalars['Int'];
  readonly notificationCursorConnection: NotificationCursorConnection;
  /** @deprecated Field no longer supported */
  readonly nutrientAmountsForFoods: ReadonlyArray<NutrientAmount>;
  readonly nutrients: ReadonlyArray<Nutrient>;
  readonly nutritionixBrandedInstantSearch: NutritionixBrandedInstantSearchPayload;
  readonly nutritionixCommonInstantSearch: NutritionixCommonInstantSearchPayload;
  readonly nutritionixRestaurantLocationSearch: NutritionixRestaurantLocationSearchPayload;
  readonly nutritionixV1RestaurantSearch: NutritionixV1RestaurantSearchPayload;
  readonly org: Org;
  readonly orgFoodGroups: ReadonlyArray<FoodGroup>;
  readonly orgFoodGroupsOffsetConnection: FoodGroupsOffsetConnection;
  readonly orgFoodOffsetConnection: FoodOffsetConnection;
  readonly orgFoods: ReadonlyArray<Food>;
  readonly orgGroup: OrgGroup;
  readonly orgGroupFoodOffsetConnection: FoodOffsetConnection;
  readonly orgGroupFoodOwnerCursorConnection: UserCursorConnection;
  readonly orgGroupFoods: ReadonlyArray<Food>;
  readonly orgGroupMealPlanTemplateOffsetConnection: MealPlanTemplateOffsetConnection;
  readonly orgGroupMealPlanTemplateOwnerCursorConnection: UserCursorConnection;
  readonly orgGroupRecipeOffsetConnection: RecipeOffsetConnection;
  readonly orgGroupRecipeOwnerCursorConnection: UserCursorConnection;
  readonly orgGroupRecipes: ReadonlyArray<Recipe>;
  readonly orgGroups: ReadonlyArray<OrgGroup>;
  readonly orgs: ReadonlyArray<Org>;
  readonly ounceUnit: Unit;
  readonly paymentMethodCursorConnection: PaymentMethodCursorConnection;
  readonly paymentMethodOffsetConnection: PaymentMethodOffsetConnection;
  readonly ping: Scalars['Boolean'];
  readonly plannedMenu: PlannedMenu;
  readonly plannedMenuMealsForWeek: ReadonlyArray<PlannedMenuMeal>;
  readonly plannedMenuOffsetConnection: PlannedMenuOffsetConnection;
  readonly plannedMenuWeeks: ReadonlyArray<PlannedMenuWeek>;
  readonly positionBySportCursorConnection: PositionBySportCursorConnection;
  readonly prepPlan: PrepPlan;
  readonly prepPlanMeal: PrepPlanMeal;
  readonly prepPlanMealAssignments: ReadonlyArray<PrepPlanMealAssignment>;
  readonly prepPlanMealsForTimelineMeal: PrepPlanMealsForTimelineMealPayload;
  readonly profileUsers: ReadonlyArray<User>;
  readonly recentMealCursorConnectionForMealTypes: RecentTimelineMealCursorConnection;
  readonly recentMealsForMealTypesPreview: RecentTimelineMealCursorConnection;
  readonly recipe: Recipe;
  readonly recipeCursorConnection: RecipeCursorConnection;
  readonly recipeOffsetConnection: RecipeOffsetConnection;
  readonly recipes: ReadonlyArray<Recipe>;
  readonly recipesById: ReadonlyArray<Recipe>;
  readonly resourceSharingOrgGroups: ReadonlyArray<OrgGroup>;
  readonly restauranMenuLinkSuggestions: Maybe<ReadonlyArray<MenuOrderSuggestion>>;
  readonly restaurant: Restaurant;
  readonly restaurantFoodCursorConnection: BrandedFoodCursorConnection;
  readonly restaurantFoodOffsetConnection: BrandedFoodOffsetConnection;
  readonly restaurantFoods: ReadonlyArray<BrandedFood>;
  readonly restaurantGeoLocationSearch: RestaurantGeoLocationSearchCursorConnection;
  readonly restaurantLocationSearch: RestaurantLocationSearchCursorConnection;
  readonly restaurantMenu: RestaurantMenu;
  readonly restaurantMenuLink: RestaurantMenuLink;
  readonly restaurantMenuLinkOrder: RestaurantMenuLinkOrder;
  readonly restaurantMenuLinkPlateOrder: RestaurantMenuLinkPlateOrder;
  readonly restaurantMenuLog: RestaurantMenuLog;
  readonly restaurantMenuSuggestions: Maybe<ReadonlyArray<MenuOrderSuggestion>>;
  readonly restaurantTypeCursorConnection: RestaurantTypeCursorConnection;
  readonly restaurantTypeOffsetConnection: RestaurantTypeOffsetConnection;
  readonly schedules: ReadonlyArray<Schedule>;
  readonly schedulesForTeam: ReadonlyArray<Schedule>;
  /** @deprecated Field no longer supported */
  readonly schemaVersion: Scalars['String'];
  readonly scoringSystemOffsetConnection: ScoringSystemOffsetConnection;
  readonly scoringSystems: ReadonlyArray<ScoringSystem>;
  readonly selectedAthletesCursorConnection: AthleteCursorConnection;
  /** @deprecated Field no longer supported */
  readonly servingAmountGroupCursorConnectionForFoodLogMeal: ServingAmountGroupCursorConnection;
  readonly servingAmountGroupCursorConnectionForMealType: ServingAmountGroupCursorConnection;
  readonly servings: ReadonlyArray<Serving>;
  readonly sharedExchangeSetOffsetConnection: ExchangeSetOffsetConnection;
  readonly sharedFoodGroups: ReadonlyArray<FoodGroup>;
  readonly sharedFoodGroupsCursorConnection: FoodGroupsCursorConnection;
  readonly sharedFoodGroupsOffsetConnection: FoodGroupsOffsetConnection;
  readonly sharedMealPlanTemplate: MealPlanTemplate;
  readonly sharedMealPlanTemplateOffsetConnection: MealPlanTemplateOffsetConnection;
  readonly sharedRecipeCursorConnection: SharedRecipeCursorConnection;
  readonly sharedRecipePreview: SharedRecipeCursorConnection;
  readonly slackFoodRequestsUrl: Scalars['String'];
  readonly sodexoMealTimesByDay: SodexoMealTimesByDay;
  readonly sodexoMenuSources: ReadonlyArray<SodexoMenuSource>;
  readonly sport: Sport;
  readonly sports: ReadonlyArray<Sport>;
  readonly staffUserSearchOffsetConnection: UserOffsetConnection;
  readonly tag: Tag;
  readonly team: Team;
  readonly teamCursorConnection: TeamCursorConnection;
  readonly teamGoalConfiguration: ReadonlyArray<GoalConfiguration>;
  readonly teamLabelCursorConnection: TeamLabelCursorConnection;
  readonly teamSearch: ReadonlyArray<Team>;
  readonly teams: ReadonlyArray<Team>;
  readonly teamsById: ReadonlyArray<Team>;
  readonly teamworksCalendarForAthleteInRange: TeamworksCalendarPayload;
  readonly teamworksDisabledProfileIds: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly teamworksOrg: TeamworksOrg;
  readonly teamworksOrgCursorConnection: TeamworksOrgCursorConnection;
  readonly teamworksOrgGroups: ReadonlyArray<TeamworksGroup>;
  readonly teamworksPositionLinks: ReadonlyArray<TeamworksPositionLink>;
  readonly teamworksProfileCursorConnection: TeamworksProfileCursorConnection;
  /** @deprecated Use teamworksScheduleForAthleteInRangeNew instead due to new return type */
  readonly teamworksScheduleForAthleteInRange: ReadonlyArray<ExternalEvent>;
  readonly teamworksSportMappings: ReadonlyArray<TeamworksSportMapping>;
  readonly territories: ReadonlyArray<Territory>;
  readonly territoryOffsetConnection: TerritoryOffsetConnection;
  readonly timelineMeal: TimelineMeal;
  readonly unit: Unit;
  readonly unitConversions: ReadonlyArray<UnitConversion>;
  readonly units: ReadonlyArray<Unit>;
  readonly unsubmittedAgreementFormForUser: Maybe<AgreementForm>;
  /** @deprecated Field no longer supported */
  readonly upcomingMealMenus: ReadonlyArray<MealMenu>;
  readonly usdaFoodDetail: UsdaFoodDetail;
  readonly usdaFoods: ReadonlyArray<UsdaFood>;
  readonly userFoodCursorConnection: FoodCursorConnection;
  readonly userFoods: ReadonlyArray<Food>;
  readonly usersICanMessageCursorConnection: MessageOptionCursorConnection;
  readonly webVersion: Scalars['String'];
};


export type QueryActiveAthleteTransferByAthleteIdArgs = {
  athleteId: Scalars['String'];
};


export type QueryActiveAthleteTransferOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
};


export type QueryAgreementFormOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryAllDeliveryLocationsArgs = {
  query: Maybe<Scalars['String']>;
  teamLabelId: Maybe<Scalars['ID']>;
  input: OffsetPaginationInput;
};


export type QueryAllMealPlanTemplatesForDietitianCursorConnectionArgs = {
  pagination: CursorPaginationInput;
  searchText: Scalars['String'];
  sortAscending: Scalars['Boolean'];
};


export type QueryAllRestaurantLocationsArgs = {
  restaurantId: Scalars['ID'];
  query: Maybe<Scalars['String']>;
  input: OffsetPaginationInput;
};


export type QueryAllRestaurantsArgs = {
  query: Maybe<Scalars['String']>;
  input: OffsetPaginationInput;
};


export type QueryAllTeamLabelsArgs = {
  query: Maybe<Scalars['String']>;
  input: OffsetPaginationInput;
};


export type QueryAthleteArgs = {
  id: Scalars['ID'];
};


export type QueryAthleteGoalsInRangeArgs = {
  athleteId: Scalars['ID'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};


export type QueryAthleteSearchArgs = {
  query: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
};


export type QueryAthleteSearchCursorConnectionArgs = {
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryAthletesForBulkOrderCursorConnectionArgs = {
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
  tagIds: ReadonlyArray<Scalars['ID']>;
  teamId: Scalars['ID'];
  excludeAthleteIds: ReadonlyArray<Scalars['ID']>;
  mealMenuId: Scalars['ID'];
};


export type QueryAthletesForCustomTagCreationArgs = {
  query: Maybe<Scalars['String']>;
  tagIds: ReadonlyArray<Scalars['ID']>;
  teamIds: ReadonlyArray<Scalars['ID']>;
};


export type QueryAthletesForCustomTagCreationCursorConnectionArgs = {
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
  tagIds: ReadonlyArray<Scalars['ID']>;
  teamIds: ReadonlyArray<Scalars['ID']>;
};


export type QueryAthletesForMealMenusArgs = {
  mealMenuIds: ReadonlyArray<Scalars['ID']>;
};


export type QueryAthletesForPendingAdvancedSelectionArgs = {
  input: AdvancedSelectionInput;
};


export type QueryAthletesForTagArgs = {
  tagId: Scalars['ID'];
  query: Maybe<Scalars['String']>;
};


export type QueryAthletesForTagCursorConnectionArgs = {
  tagId: Scalars['ID'];
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryAthletesForTagOffsetConnectionArgs = {
  tagId: Scalars['ID'];
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryAthletesForTeamArgs = {
  teamId: Scalars['ID'];
  tagIds: ReadonlyArray<Scalars['ID']>;
  query: Maybe<Scalars['String']>;
  filter: Maybe<AthleteFilter>;
};


export type QueryAthletesForTeamOffsetConnectionArgs = {
  teamId: Scalars['ID'];
  input: OffsetPaginationInput;
  tagIds: ReadonlyArray<Scalars['ID']>;
  query: Maybe<Scalars['String']>;
  filter: Maybe<AthleteFilter>;
};


export type QueryBonAppetitMenusPreviewArgs = {
  cafeId: Scalars['String'];
  date: Scalars['Date'];
};


export type QueryBrandWithUsdaManufacturerNameArgs = {
  usdaManufacturerName: Scalars['String'];
};


export type QueryBrandsArgs = {
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
};


export type QueryBulkOrderArgs = {
  bulkOrderId: Scalars['ID'];
};


export type QueryBulkOrderStatusArgs = {
  bulkOrderId: Scalars['ID'];
};


export type QueryBulkOrdersForMealMenuArgs = {
  mealMenuId: Scalars['ID'];
};


export type QueryBulkOrdersForTeamArgs = {
  teamId: Scalars['ID'];
};


export type QueryBulkOrdersForUserArgs = {
  userId: Scalars['ID'];
};


export type QueryCateringRestaurantMenuSearchArgs = {
  query: Maybe<Scalars['String']>;
  restaurantTypeIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  paymentMethodIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  cuisineIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  dietIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  pagination: Maybe<CursorPaginationInput>;
};


export type QueryCbordServiceMenusArgs = {
  serviceUnitOid: Scalars['Int'];
  start: Scalars['Date'];
  end: Scalars['Date'];
  facilityOid: Maybe<Scalars['Int']>;
};


export type QueryClientCredentialOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
};


export type QueryCompassMenuItemsArgs = {
  locationId: Scalars['Int'];
  startDate: Scalars['Date'];
  days: Scalars['Int'];
};


export type QueryCompassMrnRecipeArgs = {
  query: Scalars['String'];
};


export type QueryCompassRecipesArgs = {
  query: Scalars['String'];
};


export type QueryConvertedServingArgs = {
  input: ConvertedServingInput;
};


export type QueryCuisineCursorConnectionArgs = {
  query: Maybe<Scalars['String']>;
  input: CursorPaginationInput;
};


export type QueryCuisineOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryCurrentMenuItemArgs = {
  menuItemId: Scalars['ID'];
};


export type QueryDaySummariesArgs = {
  athleteId: Scalars['ID'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};


export type QueryDeliveryLocationCursorConnectionArgs = {
  query: Maybe<Scalars['String']>;
  teamLabelId: Maybe<Scalars['ID']>;
  input: CursorPaginationInput;
};


export type QueryDietCursorConnectionArgs = {
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryDietOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryDietitiansICanMessageCursorConnectionArgs = {
  query: Scalars['String'];
  input: CursorPaginationInput;
};


export type QueryDiningStationTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryDiningStationTemplateCursorConnectionArgs = {
  query: Maybe<Scalars['String']>;
  input: CursorPaginationInput;
};


export type QueryDiningStationTemplateOffsetConnectionArgs = {
  query: Maybe<Scalars['String']>;
  input: OffsetPaginationInput;
};


export type QueryExchangeSetArgs = {
  id: Scalars['ID'];
};


export type QueryFoodArgs = {
  id: Scalars['ID'];
};


export type QueryFoodGroupArgs = {
  id: Scalars['ID'];
};


export type QueryFoodGroupsArgs = {
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
};


export type QueryFoodGroupsByIdArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
};


export type QueryFoodItemArgs = {
  id: Scalars['ID'];
};


export type QueryFoodLogMealArgs = {
  id: Scalars['ID'];
};


export type QueryFoodOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
  localeCodes: Maybe<ReadonlyArray<Scalars['String']>>;
  dataSources: Maybe<ReadonlyArray<FoodDataSource>>;
};


export type QueryFoodOrRecipeArgs = {
  id: Scalars['ID'];
};


export type QueryFoodsArgs = {
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
  localeCodes: Maybe<ReadonlyArray<Scalars['String']>>;
  dataSources: Maybe<ReadonlyArray<FoodDataSource>>;
};


export type QueryFoodsByIdArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
};


export type QueryGenericFoodArgs = {
  id: Scalars['ID'];
};


export type QueryGenericFoodsArgs = {
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
};


export type QueryGetOpenAiImageArgs = {
  servingAmount: Scalars['Float'];
  servingUnit: Scalars['String'];
  foodName: Scalars['String'];
};


export type QueryGoalConfigurationArgs = {
  id: Scalars['ID'];
};


export type QueryGoalConfigurationsForAthleteOffsetConnectionArgs = {
  athleteId: Scalars['ID'];
  input: OffsetPaginationInput;
  current: Scalars['Boolean'];
};


export type QueryGoalConfigurationsForTeamOffsetConnectionArgs = {
  teamId: Scalars['ID'];
  input: OffsetPaginationInput;
  tagIds: ReadonlyArray<Scalars['ID']>;
  query: Maybe<Scalars['String']>;
  categoryId: Maybe<Scalars['ID']>;
};


export type QueryGoalTrackerGoalOffsetConnectionArgs = {
  goalConfigurationId: Scalars['ID'];
  input: OffsetPaginationInput;
};


export type QueryGoalTrackerGoalsArgs = {
  goalConfigurationId: Scalars['ID'];
};


export type QueryGroceryListArgs = {
  id: Scalars['ID'];
};


export type QueryIsTagNameUniqueArgs = {
  name: Scalars['String'];
};


export type QueryLoggingRestaurantMenuSearchArgs = {
  query: Maybe<Scalars['String']>;
  restaurantTypeIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  paymentMethodIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  cuisineIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  dietIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  pagination: Maybe<CursorPaginationInput>;
};


export type QueryMealEventsArgs = {
  athleteId: Scalars['ID'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};


export type QueryMealMenuArgs = {
  mealMenuId: Scalars['ID'];
};


export type QueryMealMenuAttendeesArgs = {
  mealMenuId: Scalars['ID'];
  tagIds: ReadonlyArray<Scalars['ID']>;
};


export type QueryMealMenuPlateArgs = {
  id: Scalars['ID'];
};


export type QueryMealMenuPlateOrderArgs = {
  id: Scalars['ID'];
};


export type QueryMealMenuPlateOrdersArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
};


export type QueryMealMenuPlateOrdersForUserArgs = {
  userId: Scalars['ID'];
};


export type QueryMealMenusArgs = {
  mealMenuIds: ReadonlyArray<Scalars['ID']>;
};


export type QueryMealMenusCursorConnectionArgs = {
  anchorDate: Scalars['DateTime'];
  input: CursorPaginationInput;
};


export type QueryMealMenusInDateRangeArgs = {
  after: Scalars['Date'];
  before: Scalars['Date'];
  timezone: Scalars['Timezone'];
};


export type QueryMealMenusInRangeArgs = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};


export type QueryMealMenusWithCheckInInRangeArgs = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  selectedTeamIds: ReadonlyArray<Scalars['ID']>;
};


export type QueryMealOptionCursorConnectionForMealTemplateArgs = {
  mealTemplateId: Scalars['ID'];
  input: Maybe<CursorPaginationInput>;
};


export type QueryMealOptionSuggestionCursorConnectionArgs = {
  macros: MacrosInput;
  mealType: MealType;
  maxErrorPercent: Scalars['Int'];
  minErrorPercent: Maybe<Scalars['Int']>;
  pagination: Maybe<CursorPaginationInput>;
  avoidFoodGroupIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  avoidFoodIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  localeCode: Maybe<Scalars['String']>;
};


export type QueryMealOptionsForMealPlansCursorConnectionArgs = {
  mealPlanIds: ReadonlyArray<Scalars['ID']>;
  mealTypes: Maybe<ReadonlyArray<MealType>>;
  query: Maybe<Scalars['String']>;
  input: Maybe<CursorPaginationInput>;
};


export type QueryMealOptionsForMealPlansCursorConnectionWithRandomSeededSortArgs = {
  mealPlanIds: ReadonlyArray<Scalars['ID']>;
  mealTypes: Maybe<ReadonlyArray<MealType>>;
  query: Maybe<Scalars['String']>;
  input: Maybe<CursorPaginationInput>;
  sortSeedValue: Scalars['Int'];
};


export type QueryMealOptionsForMealPlansPreviewArgs = {
  mealPlanIds: ReadonlyArray<Scalars['ID']>;
  mealTypes: Maybe<ReadonlyArray<MealType>>;
  query: Maybe<Scalars['String']>;
};


export type QueryMealOptionsForMealPlansPreviewWithRandomSeededSortArgs = {
  mealPlanIds: ReadonlyArray<Scalars['ID']>;
  mealTypes: Maybe<ReadonlyArray<MealType>>;
  query: Maybe<Scalars['String']>;
  sortSeedValue: Scalars['Int'];
};


export type QueryMealPlanArgs = {
  id: Scalars['ID'];
};


export type QueryMealPlanForCopyCursorConnectionArgs = {
  input: CursorPaginationInput;
  athleteName: Maybe<Scalars['String']>;
  mealPlanName: Maybe<Scalars['String']>;
};


export type QueryMealPlanOffsetConnectionArgs = {
  teamId: Scalars['ID'];
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryMealPlanTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryMealPlanTemplateOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  searchText: Scalars['String'];
  sortAscending: Scalars['Boolean'];
};


export type QueryMenuItemArgs = {
  menuItemId: Scalars['ID'];
};


export type QueryMenuItemCursorConnectionArgs = {
  query: Maybe<Scalars['String']>;
  input: CursorPaginationInput;
};


export type QueryMenuItemOffsetConnectionArgs = {
  query: Maybe<Scalars['String']>;
  input: OffsetPaginationInput;
};


export type QueryMenuItemRatingOffsetConnectionArgs = {
  menuItemHistoryId: Scalars['ID'];
  currentOnly: Scalars['Boolean'];
  input: OffsetPaginationInput;
};


export type QueryMenuItemsArgs = {
  menuItemIds: ReadonlyArray<Scalars['ID']>;
};


export type QueryMenuOrderArgs = {
  id: Scalars['ID'];
};


export type QueryMenuOrderCursorConnectionForItemsArgs = {
  menuItemIds: ReadonlyArray<Scalars['ID']>;
  input: Maybe<CursorPaginationInput>;
};


export type QueryMenuOrderCursorConnectionForItemsForAthleteArgs = {
  menuItemIds: ReadonlyArray<Scalars['ID']>;
  athleteId: Scalars['ID'];
  input: Maybe<CursorPaginationInput>;
};


export type QueryMenuOrderRateLimitRemaindersArgs = {
  mealMenuId: Scalars['ID'];
};


export type QueryMenuOrderSuggestionsArgs = {
  mealMenuId: Scalars['ID'];
  targetMacros: MacrosInput;
  avoidFoodGroupIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  limit: Maybe<Scalars['Int']>;
};


export type QueryMenuOrdersArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
};


export type QueryMobileUpdateForVersionArgs = {
  version: Scalars['Int'];
};


export type QueryMyCurrentGroceryListArgs = {
  date: Scalars['Date'];
};


export type QueryMyCurrentPrepPlanArgs = {
  date: Scalars['Date'];
};


export type QueryMyGoalTrackerGoalsForDateArgs = {
  date: Scalars['String'];
};


export type QueryMyPastPrepPlanCursorConnectionArgs = {
  input: CursorPaginationInput;
  date: Scalars['Date'];
};


export type QueryMyRecipeCursorConnectionArgs = {
  input: Maybe<CursorPaginationInput>;
  query: Maybe<Scalars['String']>;
  mealTypes: Maybe<ReadonlyArray<MealType>>;
  prepTimeInMinutes: Maybe<Scalars['Int']>;
  cookTimeInMinutes: Maybe<Scalars['Int']>;
};


export type QueryMyRecipePreviewArgs = {
  query: Maybe<Scalars['String']>;
  mealTypes: Maybe<ReadonlyArray<MealType>>;
  prepTimeInMinutes: Maybe<Scalars['Int']>;
  cookTimeInMinutes: Maybe<Scalars['Int']>;
};


export type QueryMyTimelineMealsInRangeArgs = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};


export type QueryNamedTagArgs = {
  id: Scalars['ID'];
};


export type QueryNamedTagCursorConnectionArgs = {
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryNamedTagOffsetConnectionArgs = {
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryNamedTagSearchArgs = {
  query: Maybe<Scalars['String']>;
};


export type QueryNotSharedRecipeCursorConnectionArgs = {
  input: Maybe<CursorPaginationInput>;
  query: Maybe<Scalars['String']>;
  mealTypes: Maybe<ReadonlyArray<MealType>>;
};


export type QueryNotesForTeamArgs = {
  teamId: Scalars['ID'];
  noteTypeId: Maybe<Scalars['ID']>;
};


export type QueryNotificationArgs = {
  id: Scalars['ID'];
};


export type QueryNotificationCursorConnectionArgs = {
  input: Maybe<CursorPaginationInput>;
};


export type QueryNutrientAmountsForFoodsArgs = {
  foodIds: ReadonlyArray<Scalars['ID']>;
};


export type QueryNutritionixBrandedInstantSearchArgs = {
  query: Scalars['String'];
  region: Scalars['Int'];
};


export type QueryNutritionixCommonInstantSearchArgs = {
  query: Scalars['String'];
  localeCode: Scalars['String'];
};


export type QueryNutritionixRestaurantLocationSearchArgs = {
  input: NutritionixRestaurantLocationSearchInput;
};


export type QueryNutritionixV1RestaurantSearchArgs = {
  input: NutritionixV1RestaurantSearchInput;
};


export type QueryOrgArgs = {
  id: Scalars['ID'];
};


export type QueryOrgFoodGroupsArgs = {
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
};


export type QueryOrgFoodGroupsOffsetConnectionArgs = {
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
  sortOrder: SortOrder;
};


export type QueryOrgFoodOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryOrgFoodsArgs = {
  searchTerm: Maybe<Scalars['String']>;
  limit: Maybe<Scalars['Int']>;
  isRecipeIngredientOnly: Maybe<Scalars['Boolean']>;
};


export type QueryOrgGroupArgs = {
  id: Scalars['ID'];
};


export type QueryOrgGroupFoodOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
  ownerIds: Maybe<ReadonlyArray<Scalars['String']>>;
};


export type QueryOrgGroupFoodOwnerCursorConnectionArgs = {
  pagination: CursorPaginationInput;
  searchText: Scalars['String'];
};


export type QueryOrgGroupFoodsArgs = {
  searchTerm: Maybe<Scalars['String']>;
  limit: Maybe<Scalars['Int']>;
  isRecipeIngredientOnly: Maybe<Scalars['Boolean']>;
};


export type QueryOrgGroupMealPlanTemplateOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  searchText: Scalars['String'];
  ownerIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  sortAscending: Scalars['Boolean'];
};


export type QueryOrgGroupMealPlanTemplateOwnerCursorConnectionArgs = {
  pagination: CursorPaginationInput;
  searchText: Scalars['String'];
};


export type QueryOrgGroupRecipeOffsetConnectionArgs = {
  input: OffsetPaginationInput;
  sort: Maybe<RecipeSort>;
  query: Maybe<Scalars['String']>;
  createdById: Maybe<Scalars['ID']>;
  ownerIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  mealTypes: Maybe<ReadonlyArray<MealType>>;
  orgId: Maybe<Scalars['ID']>;
};


export type QueryOrgGroupRecipeOwnerCursorConnectionArgs = {
  pagination: CursorPaginationInput;
  searchText: Scalars['String'];
};


export type QueryOrgGroupRecipesArgs = {
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
  createdById: Maybe<Scalars['ID']>;
};


export type QueryPaymentMethodCursorConnectionArgs = {
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryPaymentMethodOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryPlannedMenuArgs = {
  id: Scalars['ID'];
};


export type QueryPlannedMenuMealsForWeekArgs = {
  plannedMenuWeekId: Scalars['ID'];
};


export type QueryPlannedMenuOffsetConnectionArgs = {
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryPlannedMenuWeeksArgs = {
  plannedMenuWeekIds: ReadonlyArray<Scalars['ID']>;
};


export type QueryPositionBySportCursorConnectionArgs = {
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryPrepPlanArgs = {
  id: Scalars['ID'];
};


export type QueryPrepPlanMealArgs = {
  id: Scalars['ID'];
};


export type QueryPrepPlanMealAssignmentsArgs = {
  prepPlanId: Scalars['ID'];
};


export type QueryPrepPlanMealsForTimelineMealArgs = {
  input: PrepPlanMealsForTimelineMealInput;
};


export type QueryProfileUsersArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
};


export type QueryRecentMealCursorConnectionForMealTypesArgs = {
  mealTypes: Maybe<ReadonlyArray<MealType>>;
  query: Maybe<Scalars['String']>;
  input: Maybe<CursorPaginationInput>;
};


export type QueryRecentMealsForMealTypesPreviewArgs = {
  mealTypes: Maybe<ReadonlyArray<MealType>>;
  query: Maybe<Scalars['String']>;
};


export type QueryRecipeArgs = {
  id: Scalars['ID'];
};


export type QueryRecipeCursorConnectionArgs = {
  input: Maybe<CursorPaginationInput>;
  createdById: Maybe<Scalars['ID']>;
};


export type QueryRecipeOffsetConnectionArgs = {
  input: OffsetPaginationInput;
  sort: Maybe<RecipeSort>;
  query: Maybe<Scalars['String']>;
  createdById: Maybe<Scalars['ID']>;
  mealTypes: Maybe<ReadonlyArray<MealType>>;
  orgId: Maybe<Scalars['ID']>;
};


export type QueryRecipesArgs = {
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
  createdById: Maybe<Scalars['ID']>;
};


export type QueryRecipesByIdArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
};


export type QueryRestauranMenuLinkSuggestionsArgs = {
  restaurantMenuLinkId: Scalars['ID'];
  targetMacros: MacrosInput;
  avoidFoodGroupIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  limit: Maybe<Scalars['Int']>;
};


export type QueryRestaurantArgs = {
  id: Scalars['ID'];
};


export type QueryRestaurantFoodCursorConnectionArgs = {
  restaurantId: Scalars['ID'];
  input: Maybe<CursorPaginationInput>;
  query: Maybe<Scalars['String']>;
};


export type QueryRestaurantFoodOffsetConnectionArgs = {
  restaurantId: Scalars['ID'];
  pagination: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryRestaurantFoodsArgs = {
  restaurantId: Scalars['ID'];
  searchTerm: Maybe<Scalars['String']>;
  limit: Maybe<Scalars['Int']>;
};


export type QueryRestaurantGeoLocationSearchArgs = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  pagination: Maybe<CursorPaginationInput>;
};


export type QueryRestaurantLocationSearchArgs = {
  deliveryLocationId: Scalars['ID'];
  restaurantIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  restaurantTypeIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  paymentMethodIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  cuisineIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  dietIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  query: Maybe<Scalars['String']>;
  pagination: Maybe<CursorPaginationInput>;
};


export type QueryRestaurantMenuArgs = {
  id: Scalars['ID'];
};


export type QueryRestaurantMenuLinkArgs = {
  id: Scalars['ID'];
};


export type QueryRestaurantMenuLinkOrderArgs = {
  id: Scalars['ID'];
};


export type QueryRestaurantMenuLinkPlateOrderArgs = {
  id: Scalars['ID'];
};


export type QueryRestaurantMenuLogArgs = {
  id: Scalars['ID'];
};


export type QueryRestaurantMenuSuggestionsArgs = {
  restaurantMenuId: Scalars['ID'];
  targetMacros: MacrosInput;
  avoidFoodGroupIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  limit: Maybe<Scalars['Int']>;
};


export type QueryRestaurantTypeCursorConnectionArgs = {
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryRestaurantTypeOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QuerySchedulesForTeamArgs = {
  teamId: Scalars['ID'];
};


export type QueryScoringSystemOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  searchText: Scalars['String'];
};


export type QuerySelectedAthletesCursorConnectionArgs = {
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
  athleteIds: ReadonlyArray<Scalars['ID']>;
};


export type QueryServingAmountGroupCursorConnectionForFoodLogMealArgs = {
  foodLogMealId: Scalars['ID'];
  input: Maybe<CursorPaginationInput>;
};


export type QueryServingAmountGroupCursorConnectionForMealTypeArgs = {
  mealType: MealType;
  input: Maybe<CursorPaginationInput>;
  athleteId: Maybe<Scalars['ID']>;
};


export type QueryServingsArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
};


export type QuerySharedExchangeSetOffsetConnectionArgs = {
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QuerySharedFoodGroupsArgs = {
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
};


export type QuerySharedFoodGroupsCursorConnectionArgs = {
  pagination: CursorPaginationInput;
  searchText: Maybe<Scalars['String']>;
};


export type QuerySharedFoodGroupsOffsetConnectionArgs = {
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
  sortOrder: SortOrder;
};


export type QuerySharedMealPlanTemplateArgs = {
  id: Scalars['ID'];
};


export type QuerySharedMealPlanTemplateOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  searchText: Scalars['String'];
  sortAscending: Scalars['Boolean'];
};


export type QuerySharedRecipeCursorConnectionArgs = {
  input: Maybe<CursorPaginationInput>;
  query: Maybe<Scalars['String']>;
  mealTypes: Maybe<ReadonlyArray<MealType>>;
  prepTimeInMinutes: Maybe<Scalars['Int']>;
  cookTimeInMinutes: Maybe<Scalars['Int']>;
};


export type QuerySharedRecipePreviewArgs = {
  query: Maybe<Scalars['String']>;
  mealTypes: Maybe<ReadonlyArray<MealType>>;
  prepTimeInMinutes: Maybe<Scalars['Int']>;
  cookTimeInMinutes: Maybe<Scalars['Int']>;
};


export type QuerySodexoMealTimesByDayArgs = {
  locationId: Scalars['String'];
  menuSourceKey: Scalars['String'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};


export type QuerySportArgs = {
  id: Scalars['ID'];
};


export type QueryStaffUserSearchOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  searchParams: Maybe<Scalars['String']>;
};


export type QueryTagArgs = {
  id: Scalars['ID'];
};


export type QueryTeamArgs = {
  id: Scalars['ID'];
};


export type QueryTeamCursorConnectionArgs = {
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryTeamGoalConfigurationArgs = {
  teamId: Scalars['ID'];
};


export type QueryTeamLabelCursorConnectionArgs = {
  query: Maybe<Scalars['String']>;
  input: CursorPaginationInput;
};


export type QueryTeamSearchArgs = {
  query: Maybe<Scalars['String']>;
};


export type QueryTeamsByIdArgs = {
  ids: ReadonlyArray<Scalars['ID']>;
};


export type QueryTeamworksCalendarForAthleteInRangeArgs = {
  athleteId: Scalars['ID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};


export type QueryTeamworksDisabledProfileIdsArgs = {
  teamworksOrgId: Scalars['Int'];
};


export type QueryTeamworksOrgArgs = {
  teamworksId: Scalars['Int'];
};


export type QueryTeamworksOrgCursorConnectionArgs = {
  pagination: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryTeamworksOrgGroupsArgs = {
  teamworksOrgId: Scalars['Int'];
  type: Maybe<TeamworksGroupType>;
  teamId: Maybe<Scalars['Int']>;
};


export type QueryTeamworksProfileCursorConnectionArgs = {
  teamworksOrgId: Scalars['Int'];
  pagination: CursorPaginationInput;
};


export type QueryTeamworksScheduleForAthleteInRangeArgs = {
  athleteId: Scalars['ID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};


export type QueryTeamworksSportMappingsArgs = {
  query: Maybe<Scalars['String']>;
};


export type QueryTerritoryOffsetConnectionArgs = {
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryTimelineMealArgs = {
  id: Scalars['ID'];
};


export type QueryUnitArgs = {
  id: Scalars['ID'];
};


export type QueryUnsubmittedAgreementFormForUserArgs = {
  orgId: Scalars['ID'];
};


export type QueryUpcomingMealMenusArgs = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};


export type QueryUsdaFoodDetailArgs = {
  usdaFdcId: Scalars['ID'];
  apiKey: Scalars['String'];
};


export type QueryUsdaFoodsArgs = {
  searchTerm: Scalars['String'];
  apiKey: Scalars['String'];
  brandOwner: Scalars['String'];
  includedDataTypes: Scalars['String'];
};


export type QueryUserFoodCursorConnectionArgs = {
  input: Maybe<CursorPaginationInput>;
};


export type QueryUserFoodsArgs = {
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
};


export type QueryUsersICanMessageCursorConnectionArgs = {
  role: Role;
  teamId: Maybe<Scalars['String']>;
  query: Scalars['String'];
  input: CursorPaginationInput;
};

export type RmrMethod =
  | 'cunningham'
  | 'mifflin'
  | 'harrisBenedict'
  | 'average'
  | '%future added value';


export type Rating = {
  readonly id: Scalars['ID'];
  readonly createdAt: Scalars['DateTime'];
  readonly value: RatingValue;
  readonly review: Maybe<Scalars['String']>;
};

export type RatingCounts = {
  readonly one: Scalars['Int'];
  readonly two: Scalars['Int'];
  readonly three: Scalars['Int'];
  readonly four: Scalars['Int'];
  readonly five: Scalars['Int'];
};

export type RatingValue =
  | 'one'
  | 'two'
  | 'three'
  | 'four'
  | 'five'
  | '%future added value';

export type RecentTimelineMeal = {
  readonly type: MealType;
  readonly servingAmounts: ReadonlyArray<ServingAmount>;
};

export type RecentTimelineMealCursorConnection = {
  readonly edges: ReadonlyArray<RecentTimelineMeal>;
  readonly pageInfo: CursorPageInfo;
};

export type RecieveAthleteTransferInput = {
  readonly id: Scalars['ID'];
  readonly teamId: Scalars['ID'];
};

export type RecieveAthleteTransferPayload = {
  readonly athleteTransfer: AthleteTransfer;
};

export type Recipe = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly exchangeTypes: Maybe<ReadonlyArray<ExchangeType>>;
  readonly prepTimeInMinutes: Maybe<Scalars['Int']>;
  readonly cookTimeInMinutes: Maybe<Scalars['Int']>;
  readonly steps: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly note: Maybe<Scalars['String']>;
  readonly cho: Maybe<Scalars['Float']>;
  readonly pro: Maybe<Scalars['Float']>;
  readonly fat: Maybe<Scalars['Float']>;
  readonly createdAt: Scalars['DateTime'];
  readonly isCurrent: Scalars['Boolean'];
  readonly hasFullAccess: Scalars['Boolean'];
  readonly org: Maybe<Org>;
  readonly orgGroup: Maybe<OrgGroup>;
  readonly mealTypes: Maybe<ReadonlyArray<MealType>>;
  readonly ingredients: ReadonlyArray<ServingAmount>;
  readonly servings: ReadonlyArray<Serving>;
  readonly images: ReadonlyArray<Image>;
  readonly isAiGenerated: Scalars['Boolean'];
  readonly isAiTranscribed: Scalars['Boolean'];
  readonly descendantFoods: ReadonlyArray<Food>;
  readonly descendantRecipes: ReadonlyArray<Recipe>;
  readonly createdBy: User;
  readonly owner: User;
  readonly history: RecipeHistory;
  readonly isShared: Scalars['Boolean'];
  readonly score: Maybe<Score>;
  readonly foodCategory: Maybe<RecipeFoodCategory>;
  readonly copiedCount: Scalars['Int'];
};

export type RecipeCursorConnection = {
  readonly edges: ReadonlyArray<Recipe>;
  readonly pageInfo: CursorPageInfo;
};

export type RecipeFoodCategory = {
  readonly category: FoodCategoryType;
};

export type RecipeFormInput = {
  readonly name: Scalars['String'];
  readonly ingredients: ReadonlyArray<CreateServingAmountInput>;
  readonly exchangeTypes: Maybe<ReadonlyArray<ExchangeType>>;
  readonly steps: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly note: Maybe<Scalars['String']>;
  readonly macros: Maybe<MacrosInput>;
};

export type RecipeFullInput = {
  readonly name: Scalars['String'];
  readonly ingredients: ReadonlyArray<CreateServingAmountInput>;
  readonly exchangeTypes: Maybe<ReadonlyArray<ExchangeType>>;
  readonly prepTimeInMinutes: Maybe<Scalars['Int']>;
  readonly cookTimeInMinutes: Maybe<Scalars['Int']>;
  readonly steps: Maybe<ReadonlyArray<Scalars['String']>>;
  readonly note: Maybe<Scalars['String']>;
  readonly macros: Maybe<MacrosInput>;
  readonly images: ReadonlyArray<ImageInput>;
  readonly isShared: Scalars['Boolean'];
  readonly mealTypes: Maybe<ReadonlyArray<MealType>>;
  readonly scoreValue: Maybe<Scalars['Float']>;
  readonly foodCategory: Maybe<FoodCategoryType>;
  readonly isAiGenerated: Maybe<Scalars['Boolean']>;
  readonly isAiTranscribed: Maybe<Scalars['Boolean']>;
};

export type RecipeHistory = {
  readonly id: Scalars['ID'];
  readonly current: Recipe;
  readonly all: ReadonlyArray<Recipe>;
};

export type RecipeOffsetConnection = {
  readonly edges: ReadonlyArray<Recipe>;
  readonly pageInfo: OffsetPageInfo;
};

export type RecipeServingFormInput = {
  readonly units: Scalars['String'];
  readonly perRecipeYield: Scalars['Float'];
  readonly isDefault: Scalars['Boolean'];
  readonly defaultAmount: Scalars['Float'];
};

export type RecipeServingFullInput = {
  readonly units: ServingUnitsInput;
  readonly perRecipeYield: Scalars['Float'];
  readonly isDefault: Scalars['Boolean'];
  readonly defaultAmount: Scalars['Float'];
};

export type RecipeServingSimpleInput = {
  readonly units: ServingUnitsInput;
  readonly perRecipeYield: Scalars['Float'];
};

export type RecipeSimpleInput = {
  readonly name: Scalars['String'];
  readonly ingredients: ReadonlyArray<CreateServingAmountInput>;
};

export type RecipeSort = {
  readonly key: RecipeSortKey;
  readonly direction: RecipeSortDirection;
};

export type RecipeSortDirection =
  | 'ASC'
  | 'DESC'
  | '%future added value';

export type RecipeSortKey =
  | 'createdAt'
  | '%future added value';

export type ReclaimAthleteTransferInput = {
  readonly id: Scalars['ID'];
};

export type ReclaimAthleteTransferPayload = {
  readonly athleteTransfer: AthleteTransfer;
};

export type RecordMenuOrderSuggestionDecisionInput = {
  readonly userId: Scalars['ID'];
  readonly mealMenuId: Maybe<Scalars['ID']>;
  readonly restaurantMenuLinkId: Maybe<Scalars['ID']>;
  readonly restaurantMenuId: Maybe<Scalars['ID']>;
  readonly targetMacros: MacrosInput;
  readonly suggestion: MenuOrderSuggestionInput;
};

export type RecordMenuOrderSuggestionsViewInput = {
  readonly userId: Scalars['ID'];
  readonly mealMenuId: Maybe<Scalars['ID']>;
  readonly restaurantMenuLinkId: Maybe<Scalars['ID']>;
  readonly restaurantMenuId: Maybe<Scalars['ID']>;
  readonly targetMacros: MacrosInput;
  readonly suggestions: ReadonlyArray<MenuOrderSuggestionInput>;
};

export type RefreshClientCredentialSecretInput = {
  readonly id: Scalars['ID'];
};

export type RefreshClientCredentialSecretPayload = {
  readonly clientCredential: ClientCredential;
  readonly clientSecret: Scalars['String'];
};

export type RefreshTeamworksSyncStatusInput = {
  readonly orgId: Scalars['ID'];
};

export type RefreshTeamworksSyncStatusPayload = {
  readonly org: Org;
};

export type RefundPolicyType =
  | 'full'
  | 'labor'
  | '%future added value';

export type RemoveActivityInput = {
  readonly activityId: Scalars['ID'];
};

export type RemoveAthleteDislikedFoodGroupPayload = {
  readonly athlete: Athlete;
};

export type RemoveAthleteDislikedFoodPayload = {
  readonly athlete: Athlete;
};

export type RemoveAthleteLikedFoodGroupPayload = {
  readonly athlete: Athlete;
};

export type RemoveAthleteLikedFoodPayload = {
  readonly athlete: Athlete;
};

export type RemoveBulkOrderInput = {
  readonly bulkOrderId: Scalars['ID'];
};

export type RemoveBulkOrderPayload = {
  readonly bulkOrderId: Scalars['ID'];
  readonly teamId: Scalars['ID'];
  readonly deliveryLocation: Scalars['String'];
  readonly mealMenuId: Scalars['ID'];
  readonly affectedMealMenuPlateOrderIds: ReadonlyArray<Scalars['ID']>;
};

export type RemoveFoodLogActivityInput = {
  readonly foodLogActivityId: Scalars['ID'];
};

export type RemoveFoodLogActivityPayload = {
  readonly foodLogActivityId: Scalars['ID'];
};

export type RemoveFoodLogActivityTemplateInput = {
  readonly foodLogActivityId: Scalars['ID'];
};

export type RemoveFoodLogActivityTemplatePayload = {
  readonly foodLogActivity: FoodLogActivity;
};

export type RemoveFoodLogMealImageInput = {
  readonly foodLogMealId: Scalars['ID'];
  readonly imageId: Scalars['ID'];
};

export type RemoveFoodLogMealImagePayload = {
  readonly foodLogMeal: FoodLogMeal;
};

export type RemoveFoodLogMealInput = {
  readonly foodLogMealId: Scalars['ID'];
};

export type RemoveFoodLogMealPayload = {
  readonly foodLogMealId: Scalars['ID'];
};

export type RemoveFoodLogMealTemplateInput = {
  readonly foodLogMealId: Scalars['ID'];
};

export type RemoveFoodLogMealTemplatePayload = {
  readonly foodLogMeal: FoodLogMeal;
};

export type RemoveMealInput = {
  readonly mealId: Scalars['ID'];
};

export type RemoveMealMenuDiningStationInput = {
  readonly mealMenuDiningStationId: Scalars['ID'];
};

export type RemoveMealMenuLogItemInput = {
  readonly mealMenuLogItemId: Scalars['ID'];
};

export type RemoveMealMenuLogItemPayload = {
  readonly timelineMeal: Maybe<TimelineMeal>;
  readonly removedTimelineMealId: Maybe<Scalars['ID']>;
  readonly mealMenuLogItemId: Scalars['ID'];
};

export type RemoveMealOptionInput = {
  readonly mealOptionId: Scalars['ID'];
};

export type RemoveMealOptionPayload = {
  readonly mealOptionId: Scalars['ID'];
};

export type RemoveMealOptionPendingInput = {
  readonly mealOptionPendingId: Scalars['ID'];
};

export type RemoveMealOptionPendingPayload = {
  readonly mealOptionPendingId: Scalars['ID'];
};

export type RemoveMealPlanDateForAthleteInput = {
  readonly date: Scalars['Date'];
  readonly athleteId: Scalars['ID'];
};

export type RemoveMealPlanDateForAthletePayload = {
  readonly date: Scalars['Date'];
};

export type RemoveMealPlanDateInput = {
  readonly date: Scalars['Date'];
};

export type RemoveMealPlanDatePayload = {
  readonly date: Scalars['Date'];
};

export type RemoveMenuItemChoiceInput = {
  readonly menuItemChoiceId: Scalars['ID'];
};

export type RemoveMenuItemChoiceOptionInput = {
  readonly menuItemChoiceOptionId: Scalars['ID'];
};

export type RemoveMenuItemInput = {
  readonly menuItemId: Scalars['ID'];
};

export type RemoveMenuOrderImageInput = {
  readonly menuOrderId: Scalars['ID'];
  readonly imageId: Scalars['ID'];
};

export type RemoveMenuOrderImagePayload = {
  readonly menuOrder: MenuOrder;
};

export type RemoveMenuOrderInput = {
  readonly menuOrderId: Scalars['ID'];
};

export type RemoveMenuOrderMealTemplateInput = {
  readonly menuOrderId: Scalars['ID'];
};

export type RemoveMenuOrderMealTemplatePayload = {
  readonly menuOrder: MenuOrder;
};

export type RemoveMenuOrderPayload = {
  readonly menuOrderId: Scalars['ID'];
  readonly removedTimelineMealId: Maybe<Scalars['ID']>;
  readonly timelineMeal: Maybe<TimelineMeal>;
};

export type RemovePrepPlanMealInput = {
  readonly prepPlanMealId: Scalars['ID'];
  readonly guidedFlow: Maybe<Scalars['Boolean']>;
};

export type RemovePrepPlanMealPayload = {
  readonly removedPrepPlanMealId: Scalars['ID'];
};

export type RemoveRecipeImageInput = {
  readonly recipeId: Scalars['ID'];
  readonly imageId: Scalars['ID'];
};

export type RemoveRecipeImagePayload = {
  readonly recipe: Recipe;
};

export type RemoveRestaurantMenuLinkInput = {
  readonly restaurantMenuLinkId: Scalars['ID'];
};

export type RemoveRestaurantMenuLinkOrderInput = {
  readonly restaurantMenuLinkOrderId: Scalars['ID'];
};

export type RemoveRestaurantMenuLinkOrderMealTemplateInput = {
  readonly restaurantMenuLinkOrderId: Scalars['ID'];
};

export type RemoveRestaurantMenuLinkOrderMealTemplatePayload = {
  readonly restaurantMenuLinkOrder: RestaurantMenuLinkOrder;
};

export type RemoveRestaurantMenuLinkOrderPayload = {
  readonly restaurantMenuLinkOrderId: Scalars['ID'];
  readonly timelineMeal: Maybe<TimelineMeal>;
  readonly removedTimelineMealId: Maybe<Scalars['ID']>;
};

export type RemoveRestaurantMenuLinkPayload = {
  readonly restaurantMenuLinkId: Scalars['ID'];
};

export type RemoveRestaurantMenuLinkPlateOrderInput = {
  readonly restaurantMenuLinkPlateOrderId: Scalars['ID'];
};

export type RemoveRestaurantMenuLinkPlateOrderMealTemplateInput = {
  readonly restaurantMenuLinkPlateOrderId: Scalars['ID'];
};

export type RemoveRestaurantMenuLinkPlateOrderMealTemplatePayload = {
  readonly restaurantMenuLinkPlateOrder: RestaurantMenuLinkPlateOrder;
};

export type RemoveRestaurantMenuLinkPlateOrderPayload = {
  readonly restaurantMenuLinkPlateOrderId: Scalars['ID'];
  readonly timelineMeal: Maybe<TimelineMeal>;
  readonly removedTimelineMealId: Maybe<Scalars['ID']>;
};

export type RemoveRestaurantMenuLogInput = {
  readonly restaurantMenuLogId: Scalars['ID'];
};

export type RemoveRestaurantMenuLogItemInput = {
  readonly restaurantMenuLogItemId: Scalars['ID'];
};

export type RemoveRestaurantMenuLogItemPayload = {
  readonly timelineMeal: Maybe<TimelineMeal>;
  readonly removedTimelineMealId: Maybe<Scalars['ID']>;
  readonly restaurantMenuLogItemId: Scalars['ID'];
};

export type RemoveRestaurantMenuLogMealTemplateInput = {
  readonly restaurantMenuLogId: Scalars['ID'];
};

export type RemoveRestaurantMenuLogMealTemplatePayload = {
  readonly restaurantMenuLog: RestaurantMenuLog;
};

export type RemoveRestaurantMenuLogPayload = {
  readonly restaurantMenuLogId: Scalars['ID'];
  readonly timelineMeal: Maybe<TimelineMeal>;
  readonly removedTimelineMealId: Maybe<Scalars['ID']>;
};

export type RemoveRestaurantMenuSectionInput = {
  readonly restaurantMenuSectionId: Scalars['ID'];
};

export type RemoveTimelineMealImageInput = {
  readonly timelineMealId: Scalars['ID'];
  readonly imageId: Scalars['ID'];
};

export type RemoveTimelineMealImagePayload = {
  readonly imageId: Scalars['ID'];
  readonly timelineMeal: Maybe<TimelineMeal>;
  readonly removedTimelineMealId: Maybe<Scalars['ID']>;
};

export type RemoveTimelineMealServingAmountInput = {
  readonly timelineMealId: Scalars['ID'];
  readonly removeServingAmountId: Scalars['ID'];
};

export type RemoveTimelineMealServingAmountPayload = {
  readonly timelineMeal: Maybe<TimelineMeal>;
  readonly removedTimelineMealId: Maybe<Scalars['ID']>;
};

export type Restaurant = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly nutritionixId: Maybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
  readonly website: Maybe<Scalars['String']>;
  readonly proteinPortionSizeInOunces: Maybe<Scalars['Int']>;
  readonly firstPartyDelivery: Scalars['Boolean'];
  readonly athleteVisible: Scalars['Boolean'];
  readonly refundPolicy: Maybe<RefundPolicyType>;
  readonly refundDueBeforeInMinutes: Maybe<Scalars['Int']>;
  readonly menu: Maybe<RestaurantMenu>;
  readonly restaurantType: Maybe<RestaurantType>;
  readonly paymentMethods: ReadonlyArray<PaymentMethod>;
  readonly cuisines: ReadonlyArray<Cuisine>;
};

export type RestaurantGeoLocationInfo = {
  readonly lat: Scalars['Float'];
  readonly lng: Scalars['Float'];
  readonly googlePlaceId: Scalars['String'];
  readonly icon: Maybe<Scalars['String']>;
};

export type RestaurantGeoLocationSearchCursorConnection = {
  readonly edges: ReadonlyArray<RestaurantGeoLocationSearchResult>;
  readonly pageInfo: CursorPageInfo;
};

export type RestaurantGeoLocationSearchResult = {
  readonly restaurant: Restaurant;
  readonly restaurantMenu: RestaurantMenu;
  readonly restaurantLocation: Maybe<RestaurantLocation>;
  readonly locationInfo: RestaurantGeoLocationInfo;
};

export type RestaurantLocation = {
  readonly id: Scalars['ID'];
  readonly restaurant: Maybe<Restaurant>;
  readonly address: Maybe<Address>;
  readonly contacts: ReadonlyArray<RestaurantLocationContact>;
  readonly googlePlaceId: Scalars['String'];
  readonly notes: Maybe<Scalars['String']>;
  readonly email: Maybe<Scalars['String']>;
  readonly phoneNumber: Maybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
  readonly cityInfo: Maybe<Scalars['String']>;
  readonly deliveryLocations: ReadonlyArray<DeliveryLocation>;
};

export type RestaurantLocationContact = {
  readonly id: Scalars['ID'];
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly title: Maybe<Scalars['String']>;
  readonly email: Maybe<Scalars['String']>;
  readonly phoneNumber: Maybe<Scalars['String']>;
  readonly preferredContactMethod: PreferredContactMethodType;
};

export type RestaurantLocationContactInput = {
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly title: Maybe<Scalars['String']>;
  readonly email: Maybe<Scalars['String']>;
  readonly phoneNumber: Maybe<Scalars['String']>;
  readonly preferredContactMethod: PreferredContactMethodType;
};

export type RestaurantLocationOffsetConnection = {
  readonly edges: ReadonlyArray<RestaurantLocation>;
  readonly pageInfo: OffsetPageInfo;
};

export type RestaurantLocationSearchCursorConnection = {
  readonly edges: ReadonlyArray<RestaurantLocationSearchResult>;
  readonly pageInfo: CursorPageInfo;
};

export type RestaurantLocationSearchResult = {
  readonly restaurant: Restaurant;
  readonly restaurantMenu: RestaurantMenu;
  readonly restaurantLocation: RestaurantLocation;
};

export type RestaurantMenu = {
  readonly id: Scalars['ID'];
  readonly sections: ReadonlyArray<RestaurantMenuSection>;
  readonly supportedDiets: ReadonlyArray<Diet>;
};

export type RestaurantMenuLink = {
  readonly id: Scalars['ID'];
  readonly deliveryLocation: Maybe<DeliveryLocation>;
  readonly restaurant: Restaurant;
  readonly restaurantMenu: RestaurantMenu;
  readonly restaurantLocation: Maybe<RestaurantLocation>;
  readonly sections: ReadonlyArray<RestaurantMenuSectionLink>;
  readonly plates: ReadonlyArray<RestaurantMenuLinkPlate>;
  readonly allowCustomOrders: Scalars['Boolean'];
  readonly allowPlateOrders: Scalars['Boolean'];
  readonly orders: ReadonlyArray<RestaurantMenuLinkOrder>;
  readonly myOrder: Maybe<RestaurantMenuLinkOrder>;
  readonly orderForAthlete: Maybe<RestaurantMenuLinkOrder>;
  readonly myPlateOrder: Maybe<RestaurantMenuLinkPlateOrder>;
  readonly plateOrderForAthlete: Maybe<RestaurantMenuLinkPlateOrder>;
};


export type RestaurantMenuLinkOrderForAthleteArgs = {
  athleteId: Scalars['ID'];
};


export type RestaurantMenuLinkPlateOrderForAthleteArgs = {
  athleteId: Scalars['ID'];
};

export type RestaurantMenuLinkInput = {
  readonly restaurantId: Scalars['ID'];
  readonly restaurantMenuId: Scalars['ID'];
  readonly deliveryLocationId: Maybe<Scalars['ID']>;
  readonly restaurantLocationId: Maybe<Scalars['ID']>;
  readonly sections: ReadonlyArray<RestaurantMenuSectionLinkInput>;
  readonly plates: ReadonlyArray<AddRestaurantMenuLinkPlateInput>;
  readonly allowCustomOrders: Scalars['Boolean'];
  readonly allowPlateOrders: Scalars['Boolean'];
};

export type RestaurantMenuLinkOrder = MenuSelection & {
  readonly id: Scalars['ID'];
  readonly user: User;
  readonly createdAt: Scalars['DateTime'];
  readonly restaurantMenuLink: RestaurantMenuLink;
  readonly items: ReadonlyArray<RestaurantMenuLinkOrderItem>;
  readonly itemCount: Scalars['Int'];
  readonly athlete: Maybe<Athlete>;
  readonly userFullName: Scalars['String'];
  readonly mealTemplate: Maybe<MealTemplate>;
  readonly mealMenu: MealMenu;
  readonly timelineMeal: Maybe<TimelineMeal>;
};

export type RestaurantMenuLinkOrderItem = MenuSelectionItem & {
  readonly id: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly percentConsumed: Maybe<Scalars['Float']>;
  readonly menuItem: MenuItem;
  readonly options: ReadonlyArray<RestaurantMenuLinkOrderItemOption>;
  readonly order: RestaurantMenuLinkOrder;
  readonly specialRequests: Maybe<Scalars['String']>;
};

export type RestaurantMenuLinkOrderItemInput = {
  readonly menuItemId: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly specialRequests: Maybe<Scalars['String']>;
  readonly options: ReadonlyArray<RestaurantMenuLinkOrderItemOptionInput>;
};

export type RestaurantMenuLinkOrderItemOption = MenuSelectionItemOption & {
  readonly amount: Scalars['Float'];
  readonly menuItemChoiceOption: MenuItemChoiceOption;
  readonly percentConsumed: Maybe<Scalars['Float']>;
};

export type RestaurantMenuLinkOrderItemOptionInput = {
  readonly amount: Scalars['Float'];
  readonly menuItemChoiceOptionId: Scalars['ID'];
};

export type RestaurantMenuLinkPlate = MenuSelection & {
  readonly id: Scalars['ID'];
  readonly position: Scalars['Int'];
  readonly bulkOrderAmount: Maybe<Scalars['Int']>;
  readonly items: ReadonlyArray<RestaurantMenuLinkPlateItem>;
  readonly orders: ReadonlyArray<RestaurantMenuLinkPlateOrder>;
  readonly isDefault: Scalars['Boolean'];
};

export type RestaurantMenuLinkPlateItem = MenuSelectionItem & {
  readonly id: Scalars['ID'];
  readonly position: Scalars['Int'];
  readonly amount: Scalars['Float'];
  readonly menuItem: MenuItem;
  readonly options: ReadonlyArray<RestaurantMenuLinkPlateItemOption>;
  readonly specialRequests: Maybe<Scalars['String']>;
  readonly percentConsumed: Maybe<Scalars['Float']>;
};

export type RestaurantMenuLinkPlateItemOption = MenuSelectionItemOption & {
  readonly amount: Scalars['Float'];
  readonly menuItemChoiceOption: MenuItemChoiceOption;
  readonly percentConsumed: Maybe<Scalars['Float']>;
};

export type RestaurantMenuLinkPlateItemOptionInput = {
  readonly amount: Scalars['Float'];
  readonly menuItemChoiceOptionId: Scalars['ID'];
};

export type RestaurantMenuLinkPlateOrder = MenuSelection & {
  readonly id: Scalars['ID'];
  readonly user: User;
  readonly createdAt: Scalars['DateTime'];
  readonly restaurantMenuLink: RestaurantMenuLink;
  readonly plate: RestaurantMenuLinkPlate;
  readonly items: ReadonlyArray<RestaurantMenuLinkPlateOrderItem>;
  readonly athlete: Maybe<Athlete>;
  readonly userFullName: Scalars['String'];
  readonly mealTemplate: Maybe<MealTemplate>;
  readonly mealMenu: MealMenu;
  readonly timelineMeal: Maybe<TimelineMeal>;
};

export type RestaurantMenuLinkPlateOrderItem = MenuSelectionItem & {
  readonly plateItem: RestaurantMenuLinkPlateItem;
  readonly position: Scalars['Int'];
  readonly amount: Scalars['Float'];
  readonly percentConsumed: Maybe<Scalars['Float']>;
  readonly menuItem: MenuItem;
  readonly options: ReadonlyArray<RestaurantMenuLinkPlateOrderItemOption>;
};

export type RestaurantMenuLinkPlateOrderItemOption = MenuSelectionItemOption & {
  readonly amount: Scalars['Float'];
  readonly percentConsumed: Maybe<Scalars['Float']>;
  readonly menuItemChoiceOption: MenuItemChoiceOption;
};

export type RestaurantMenuLog = MenuSelection & {
  readonly id: Scalars['ID'];
  readonly restaurantMenu: RestaurantMenu;
  readonly items: ReadonlyArray<RestaurantMenuLogItem>;
  readonly athlete: Maybe<Athlete>;
  readonly mealTemplate: Maybe<MealTemplate>;
  readonly dateTime: Scalars['DateTime'];
  readonly timezone: Scalars['Timezone'];
  readonly type: MealType;
  readonly restaurant: Restaurant;
};

export type RestaurantMenuLogItem = MenuSelectionItem & {
  readonly id: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly percentConsumed: Maybe<Scalars['Float']>;
  readonly menuItem: MenuItem;
  readonly options: ReadonlyArray<RestaurantMenuLogItemOption>;
};

export type RestaurantMenuLogItemInput = {
  readonly menuItemId: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly options: ReadonlyArray<RestaurantMenuLogItemOptionInput>;
};

export type RestaurantMenuLogItemOption = MenuSelectionItemOption & {
  readonly amount: Scalars['Float'];
  readonly percentConsumed: Maybe<Scalars['Float']>;
  readonly menuItemChoiceOption: MenuItemChoiceOption;
};

export type RestaurantMenuLogItemOptionInput = {
  readonly amount: Scalars['Float'];
  readonly menuItemChoiceOptionId: Scalars['ID'];
};

export type RestaurantMenuSearchCursorConnection = {
  readonly edges: ReadonlyArray<RestaurantMenuSearchResult>;
  readonly pageInfo: CursorPageInfo;
};

export type RestaurantMenuSearchResult = {
  readonly restaurant: Restaurant;
  readonly restaurantMenu: RestaurantMenu;
};

export type RestaurantMenuSection = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly position: Scalars['Int'];
  readonly menuItemAppearances: ReadonlyArray<MenuItemAppearance>;
};

export type RestaurantMenuSectionLink = {
  readonly restaurantMenuSection: RestaurantMenuSection;
  readonly maxAmount: Maybe<Scalars['Int']>;
  readonly menuItemAppearances: ReadonlyArray<MenuItemAppearance>;
};

export type RestaurantMenuSectionLinkInput = {
  readonly restaurantMenuSectionId: Scalars['ID'];
  readonly maxAmount: Maybe<Scalars['Int']>;
  readonly menuItems: ReadonlyArray<AddMenuItemInput>;
};

export type RestaurantOffsetConnection = {
  readonly edges: ReadonlyArray<Restaurant>;
  readonly pageInfo: OffsetPageInfo;
};

export type RestaurantType = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type RestaurantTypeCursorConnection = {
  readonly edges: ReadonlyArray<RestaurantType>;
  readonly pageInfo: CursorPageInfo;
};

export type RestaurantTypeOffsetConnection = {
  readonly edges: ReadonlyArray<RestaurantType>;
  readonly pageInfo: OffsetPageInfo;
};

export type Role =
  | 'athlete'
  | 'dietitian'
  | 'chef'
  | 'foodmanager'
  | 'kiosk'
  | 'nonRdAdmin'
  | '%future added value';

export type SavePlannedMealsInput = {
  readonly plannedMenuId: Scalars['ID'];
  readonly rowItemsToDelete: ReadonlyArray<Scalars['ID']>;
  readonly rowsToDelete: ReadonlyArray<Scalars['ID']>;
  readonly mealsToDelete: ReadonlyArray<Scalars['ID']>;
  readonly mealsToUpsert: ReadonlyArray<PlannedMenuMealInput>;
  readonly disabledDays: Maybe<ReadonlyArray<PlannedMenuDisabledDaysInput>>;
};

export type SavePlannedMealsPayload = {
  readonly plannedMenu: PlannedMenu;
};

export type Schedule = {
  readonly id: Scalars['ID'];
  readonly activityFactor: Maybe<Scalars['Float']>;
  readonly name: Scalars['String'];
  readonly meals: ReadonlyArray<Meal>;
  readonly activities: ReadonlyArray<Activity>;
  readonly mealPlans: ReadonlyArray<MealPlan>;
  readonly team: Maybe<Team>;
};

export type Score = {
  readonly value: Scalars['Float'];
  readonly scoringSystem: ScoringSystem;
};

export type ScoringSystem = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
};

export type ScoringSystemOffsetConnection = {
  readonly edges: ReadonlyArray<ScoringSystem>;
  readonly pageInfo: OffsetPageInfo;
};

export type SelectAllInfo = {
  readonly allIds: ReadonlyArray<Scalars['ID']>;
  readonly total: Scalars['Int'];
};

export type Serving = {
  readonly cho: Scalars['Float'];
  readonly customUnits: Maybe<Scalars['String']>;
  readonly defaultAmount: Scalars['Float'];
  readonly fat: Scalars['Float'];
  readonly foodOrRecipe: FoodOrRecipe;
  readonly hasOwnNutrients: Scalars['Boolean'];
  readonly id: Scalars['ID'];
  readonly isDefault: Scalars['Boolean'];
  readonly isRecipeServingOnly: Scalars['Boolean'];
  readonly macros: Macros;
  readonly nutrientAmounts: ReadonlyArray<NutrientAmount>;
  readonly perRecipeYield: Maybe<Scalars['Float']>;
  readonly pro: Scalars['Float'];
  readonly unit: Maybe<Unit>;
  readonly unitPrefix: Maybe<Scalars['String']>;
  readonly unitSuffix: Maybe<Scalars['String']>;
  readonly units: Scalars['String'];
  readonly usdaWeightSeq: Maybe<Scalars['String']>;
  readonly weight: Maybe<Scalars['Float']>;
};

export type ServingAmount = {
  readonly id: Scalars['ID'];
  readonly serving: Serving;
  readonly amount: Scalars['Float'];
  readonly position: Scalars['Int'];
};

export type ServingAmountGroup = {
  readonly servingAmounts: ReadonlyArray<ServingAmount>;
};

export type ServingAmountGroupCursorConnection = {
  readonly edges: ReadonlyArray<ServingAmountGroup>;
  readonly pageInfo: CursorPageInfo;
};

export type ServingAmountLocalInput = {
  readonly servingId: Scalars['String'];
  readonly amount: Scalars['Int'];
  readonly position: Scalars['Int'];
};

export type ServingUnitsInput = {
  readonly customUnits: Maybe<Scalars['String']>;
  readonly unitId: Maybe<Scalars['ID']>;
  readonly unitPrefix: Maybe<Scalars['String']>;
  readonly unitSuffix: Maybe<Scalars['String']>;
};

export type SetGoalReminderConfigurationInput = {
  readonly time: Scalars['Time'];
  readonly message: Scalars['String'];
};

export type SetGoalTrackerGoalInput = {
  readonly goalId: Scalars['ID'];
  readonly completed: Scalars['Boolean'];
  readonly value: Maybe<Scalars['Int']>;
};

export type SetGoalTrackerGoalPayload = {
  readonly goalTrackerGoal: GoalTrackerGoal;
};

export type SetMealMenuCheckInsInput = {
  readonly athleteId: Scalars['ID'];
  readonly removeMealMenuIds: ReadonlyArray<Scalars['ID']>;
  readonly addMealMenuIds: ReadonlyArray<Scalars['ID']>;
};

export type SetMealMenuCheckInsPayload = {
  readonly addedMealMenuCheckIns: ReadonlyArray<MealMenuCheckIn>;
  readonly removedMealMenuCheckInIds: ReadonlyArray<Scalars['ID']>;
};

export type SetMealMenuLogItemPercentConsumedPayload = {
  readonly mealMenuLogItem: MealMenuLogItem;
};

export type SetMealOptionsForMealTemplateInput = {
  readonly mealTemplateId: Scalars['ID'];
  readonly mealOptions: ReadonlyArray<MealOptionInput>;
};

export type SetMealOptionsForMealTemplatesInput = {
  readonly removeExistingMealOptions: Scalars['Boolean'];
  readonly mealOptionsForMealTemplates: ReadonlyArray<SetMealOptionsForMealTemplateInput>;
};

export type SetMealOptionsForMealTemplatesPayload = {
  readonly mealTemplates: ReadonlyArray<MealTemplate>;
};

export type SetMenuOrderItemPercentConsumedPayload = {
  readonly menuOrderItem: MenuOrderItem;
};

export type SetMenuSelectionItemOptionPercentConsumedInput = {
  readonly menuItemChoiceOptionId: Scalars['ID'];
  readonly percentConsumed: Scalars['Float'];
};

export type SetMenuSelectionItemPercentConsumedInput = {
  readonly menuSelectionItemId: Scalars['ID'];
  readonly percentConsumed: Scalars['Float'];
  readonly options: ReadonlyArray<SetMenuSelectionItemOptionPercentConsumedInput>;
};

export type SetPrepPlanGuidedBuilderInput = {
  readonly prepPlanId: Scalars['ID'];
};

export type SetPrepPlanGuidedBuilderPayload = {
  readonly prepPlan: PrepPlan;
};

export type SetPrepPlanMealAssignmentSlotsInput = {
  readonly prepPlanId: Scalars['ID'];
  readonly assignmentSlots: ReadonlyArray<PrepPlanMealAssignmentInput>;
};

export type SetPrepPlanMealAssignmentSlotsPayload = {
  readonly prepPlanMealAssignments: ReadonlyArray<PrepPlanMealAssignment>;
};

export type SetRestaurantMenuLinkOrderItemPercentConsumedPayload = {
  readonly restaurantMenuLinkOrderItem: RestaurantMenuLinkOrderItem;
};

export type SetRestaurantMenuLinkPlateOrderItemPercentConsumedInput = {
  readonly restaurantMenuLinkPlateOrderId: Scalars['ID'];
  readonly restaurantMenuLinkPlateItemId: Scalars['ID'];
  readonly percentConsumed: Scalars['Float'];
  readonly options: ReadonlyArray<SetMenuSelectionItemOptionPercentConsumedInput>;
};

export type SetRestaurantMenuLinkPlateOrderItemPercentConsumedPayload = {
  readonly restaurantMenuLinkPlateOrderItem: RestaurantMenuLinkPlateOrderItem;
  readonly restaurantMenuLinkPlateOrder: RestaurantMenuLinkPlateOrder;
};

export type SetRestaurantMenuLogItemPercentConsumedPayload = {
  readonly restaurantMenuLogItem: RestaurantMenuLogItem;
};

export type SetTimelineMealServingAmountsForAthleteInput = {
  readonly athleteId: Scalars['ID'];
  readonly timelineMeal: TimelineMealInput;
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
};

export type SetTimelineMealServingAmountsInput = {
  readonly timelineMeal: TimelineMealInput;
  readonly servingAmounts: ReadonlyArray<CreateServingAmountInput>;
};

export type SetTimelineMealServingAmountsPayload = {
  readonly timelineMeal: Maybe<TimelineMeal>;
  readonly removedTimelineMealId: Maybe<Scalars['ID']>;
};

export type SexType =
  | 'male'
  | 'female'
  | '%future added value';

export type ShareMealOptionPendingInput = {
  readonly mealOptionPendingId: Scalars['ID'];
};

export type ShareMealOptionPendingPayload = {
  readonly mealOption: MealOption;
};

export type SharedRecipeCursorConnection = {
  readonly edges: ReadonlyArray<Recipe>;
  readonly pageInfo: CursorPageInfo;
};

export type SkippedPlatePayload = {
  readonly name: Scalars['String'];
  readonly reason: Scalars['String'];
};

export type SkippedUserPayload = {
  readonly name: Scalars['String'];
  readonly userId: Scalars['ID'];
};

export type SodexoDayMealTimes = {
  readonly date: Scalars['String'];
  readonly mealTimes: ReadonlyArray<SodexoMealTime>;
};

export type SodexoMealTime = {
  readonly name: Scalars['String'];
  readonly start: Scalars['String'];
  readonly end: Scalars['String'];
  readonly sortOrder: Scalars['String'];
};

export type SodexoMealTimeInput = {
  readonly name: Scalars['String'];
  readonly start: Scalars['String'];
  readonly end: Scalars['String'];
};

export type SodexoMealTimesByDay = {
  readonly locationId: Scalars['String'];
  readonly menuSourceKey: Scalars['String'];
  readonly mealTimesByDay: ReadonlyArray<SodexoDayMealTimes>;
};

export type SodexoMenuIdentity = MealMenuIdentity & {
  readonly id: Scalars['ID'];
  readonly isOverridden: Scalars['Boolean'];
};

export type SodexoMenuSource = {
  readonly locationId: Scalars['String'];
  readonly locationName: Scalars['String'];
  readonly menuSourceKey: Scalars['String'];
  readonly menuSourceName: Scalars['String'];
};

export type SortOrder =
  | 'ASC'
  | 'DESC'
  | '%future added value';

export type Sport = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly positions: ReadonlyArray<Position>;
};

export type SportsMappingInput = {
  readonly teamworksName: Scalars['String'];
  readonly sportId: Scalars['ID'];
};

export type StandaloneMealMenu = MealMenu & {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly type: MealType;
  readonly start: Scalars['DateTime'];
  readonly timezone: Scalars['Timezone'];
  readonly durationInMinutes: Scalars['Int'];
  readonly prepTimeInMinutes: Scalars['Int'];
  readonly lastOrderTimeBeforeEndInMinutes: Scalars['Int'];
  readonly notificationSentBeforeOrderDueInMinutes: Maybe<Scalars['Int']>;
  readonly mealMenuDiningStations: ReadonlyArray<MealMenuDiningStation>;
  readonly orderRateLimit: Maybe<MenuOrderRateLimit>;
  readonly userOrderLimit: Maybe<Scalars['Int']>;
  readonly isLogOnly: Scalars['Boolean'];
  readonly isInHouseMenuOrderable: Scalars['Boolean'];
  readonly isRestaurantMenuOrderable: Scalars['Boolean'];
  readonly isOrderable: Scalars['Boolean'];
  readonly isHubCheckInEnabled: Scalars['Boolean'];
  readonly hubEventUrl: Maybe<Scalars['String']>;
  readonly isOrderAndLogRestricted: Scalars['Boolean'];
  readonly isBulkOrderable: Scalars['Boolean'];
  readonly isViewOnlyForMe: Scalars['Boolean'];
  readonly identity: Maybe<MealMenuIdentity>;
  readonly restaurantMenuLinks: ReadonlyArray<RestaurantMenuLink>;
  readonly teams: ReadonlyArray<Team>;
  readonly advancedSelection: Maybe<AdvancedSelectionType>;
  readonly hasAdvancedSelection: Scalars['Boolean'];
  readonly athleteCount: Scalars['Int'];
  readonly athletes: ReadonlyArray<Athlete>;
  readonly allOrders: ReadonlyArray<MenuOrder>;
  readonly allMealMenuPlateOrders: ReadonlyArray<MealMenuPlateOrder>;
  /** @deprecated pending forced update for multiple MenuOrders per menu */
  readonly order: Maybe<MenuOrder>;
  readonly orders: ReadonlyArray<MenuOrder>;
  readonly ordersForUser: ReadonlyArray<MenuOrder>;
  readonly ordersForAthlete: ReadonlyArray<MenuOrder>;
  readonly logs: ReadonlyArray<MealMenuLog>;
  readonly logsForAthlete: ReadonlyArray<MealMenuLog>;
  readonly theme: Maybe<Scalars['String']>;
};


export type StandaloneMealMenuOrdersForUserArgs = {
  userId: Scalars['ID'];
};


export type StandaloneMealMenuOrdersForAthleteArgs = {
  athleteId: Scalars['ID'];
};


export type StandaloneMealMenuLogsForAthleteArgs = {
  athleteId: Scalars['ID'];
};

export type Subscription = {
  readonly bulkOrder: BulkOrderPublication;
  readonly bulkOrderStatus: BulkOrderStatusPublication;
  /** @deprecated Remove pending timeline meal cleanup and mobile force update */
  readonly foodLogMeal: FoodLogMealPublication;
  readonly mealMenuPlateItemOrderStatuses: MealMenuPlateItemOrderStatusPublication;
  /** @deprecated Remove 'AddMenuOrderCommentPayload' from 'union MenuOrderPublicationPayload' pending timeline meal cleanup and mobile force update */
  readonly menuOrder: MenuOrderPublication;
  readonly menuOrderItemStatuses: MenuOrderItemStatusesPublication;
  readonly notification: NotificationPublication;
  readonly plannedMenuLockTaken: PlannedMenuLockTakenPublication;
  readonly timelineMeal: TimelineMealPublication;
};


export type SubscriptionBulkOrderArgs = {
  scope: Maybe<BulkOrderSubscriberScope>;
};


export type SubscriptionBulkOrderStatusArgs = {
  scope: Maybe<BulkOrderSubscriberScope>;
};


export type SubscriptionFoodLogMealArgs = {
  scope: Maybe<FoodLogMealSubscriberScope>;
};


export type SubscriptionMealMenuPlateItemOrderStatusesArgs = {
  scope: Maybe<MealMenuPlateItemOrderStatusSubscriberScope>;
};


export type SubscriptionMenuOrderArgs = {
  scope: Maybe<MenuOrderSubscriberScope>;
};


export type SubscriptionMenuOrderItemStatusesArgs = {
  scope: Maybe<MenuOrderItemStatusesSubscriberScope>;
};


export type SubscriptionPlannedMenuLockTakenArgs = {
  scope: PlannedMenuLockTakenSubscriberScope;
};


export type SubscriptionTimelineMealArgs = {
  timelineMealId: Maybe<Scalars['ID']>;
};

export type SyncedAthleteFormInput = {
  readonly sex: Maybe<SexType>;
  readonly inbodyUid: Maybe<Scalars['String']>;
  readonly secaUid: Maybe<Scalars['String']>;
  readonly jerseyNumber: Maybe<Scalars['String']>;
  readonly removedTagIds: ReadonlyArray<Scalars['ID']>;
  readonly addedTagIds: ReadonlyArray<Scalars['ID']>;
  readonly positionId: Maybe<Scalars['ID']>;
  readonly birthDate: Maybe<Scalars['Date']>;
};

export type Tag = {
  readonly id: Scalars['ID'];
  readonly tagName: Maybe<TagName>;
  readonly athleteCount: Scalars['Int'];
  readonly isComplement: Scalars['Boolean'];
  readonly createdBy: Maybe<User>;
};

export type TagCombinationMethod =
  | 'union'
  | 'intersection'
  | '%future added value';

export type TagCondition = ComboTag | PositionTag | TeamTag | { readonly __typename?: "%other" };

export type TagConditionType =
  | 'position'
  | 'tag'
  | 'team'
  | '%future added value';

export type TagName = {
  readonly name: Scalars['String'];
  readonly description: Scalars['String'];
  readonly color: Scalars['String'];
  readonly isPrintable: Maybe<Scalars['Boolean']>;
};

export type Team = {
  readonly id: Scalars['ID'];
  readonly athletes: ReadonlyArray<Athlete>;
  /** @deprecated Pending web notifications overhaul */
  readonly recentNotifications: ReadonlyArray<Notification>;
  readonly foodLogNotificationSettings: Maybe<FoodLogNotificationSettings>;
  readonly defaultMacroDisplaySettings: Maybe<MacroMealPlanDisplaySettings>;
  readonly defaultExchangeDisplaySettings: Maybe<ExchangeMealPlanDisplaySettings>;
  readonly defaultNoTargetsDisplaySettings: Maybe<NoTargetsDisplaySettings>;
  readonly defaultHideAnthropometry: Maybe<Scalars['Boolean']>;
  readonly schedules: ReadonlyArray<Schedule>;
  readonly gender: Maybe<Scalars['String']>;
  readonly sport: Maybe<Sport>;
  readonly name: Scalars['String'];
  readonly canBeDeleted: Scalars['Boolean'];
  readonly teamworksId: Maybe<Scalars['Int']>;
};

export type TeamCursorConnection = {
  readonly edges: ReadonlyArray<Team>;
  readonly pageInfo: CursorPageInfo;
};

export type TeamLabel = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly isActive: Scalars['Boolean'];
};

export type TeamLabelCursorConnection = {
  readonly edges: ReadonlyArray<TeamLabel>;
  readonly pageInfo: CursorPageInfo;
};

export type TeamLabelOffsetConnection = {
  readonly edges: ReadonlyArray<TeamLabel>;
  readonly pageInfo: OffsetPageInfo;
};

export type TeamTag = Tag & {
  readonly id: Scalars['ID'];
  readonly tagName: Maybe<TagName>;
  readonly athleteCount: Scalars['Int'];
  readonly isComplement: Scalars['Boolean'];
  readonly createdBy: Maybe<User>;
  readonly teams: ReadonlyArray<Team>;
};

export type TeamworksAthleteStatus = {
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly active: Maybe<Scalars['Boolean']>;
};

export type TeamworksCalendarPayload = {
  readonly events: ReadonlyArray<ExternalEvent>;
  readonly isPartialSuccess: Scalars['Boolean'];
};

export type TeamworksGender =
  | 'M'
  | 'F'
  | '%future added value';

export type TeamworksGroup = {
  readonly id: Scalars['Int'];
  readonly type: TeamworksGroupType;
  readonly label: Scalars['String'];
  readonly teamId: Maybe<Scalars['Int']>;
};

export type TeamworksGroupType =
  | 'team'
  | 'user_type'
  | 'athlete_status'
  | 'position'
  | 'academic_year'
  | '%future added value';

export type TeamworksMembership = {
  readonly teamId: Scalars['Int'];
  readonly teamName: Scalars['String'];
  readonly athleteStatus: Maybe<TeamworksAthleteStatus>;
  readonly userTypes: Maybe<ReadonlyArray<TeamworksUserType>>;
  readonly positions: Maybe<ReadonlyArray<TeamworksPosition>>;
};

export type TeamworksOrg = {
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly shortName: Scalars['String'];
  readonly teams: ReadonlyArray<TeamworksTeam>;
};

export type TeamworksOrgCursorConnection = {
  readonly edges: ReadonlyArray<TeamworksOrg>;
  readonly pageInfo: CursorPageInfo;
};

export type TeamworksPosition = {
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
};

export type TeamworksPositionLink = {
  readonly teamworksId: Scalars['Int'];
  readonly teamworksTeamId: Scalars['Int'];
  readonly position: Position;
};

export type TeamworksPositionMapping = {
  readonly teamworksName: Scalars['String'];
  readonly positionId: Scalars['ID'];
};

export type TeamworksProfile = {
  readonly id: Scalars['Int'];
  readonly userId: Maybe<Scalars['Int']>;
  readonly firstName: Maybe<Scalars['String']>;
  readonly lastName: Maybe<Scalars['String']>;
  readonly email: Maybe<Scalars['String']>;
  readonly cellPhone: Maybe<Scalars['String']>;
  readonly birthDate: Maybe<Scalars['Date']>;
  readonly gender: Maybe<TeamworksGender>;
  readonly hasLoginAccess: Maybe<Scalars['Boolean']>;
  readonly active: Maybe<Scalars['Boolean']>;
  readonly memberships: Maybe<ReadonlyArray<TeamworksMembership>>;
};

export type TeamworksProfileCursorConnection = {
  readonly edges: ReadonlyArray<TeamworksProfile>;
  readonly pageInfo: CursorPageInfo;
};

export type TeamworksProfileLinkInput = {
  readonly orgMembershipId: Maybe<Scalars['ID']>;
  readonly athleteId: Maybe<Scalars['ID']>;
  readonly teamId: Maybe<Scalars['ID']>;
  readonly positionId: Maybe<Scalars['ID']>;
  readonly teamworksUserId: Scalars['Int'];
  readonly teamworksProfileId: Scalars['Int'];
  readonly notemealAccountType: NotemealAccountType;
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly email: Maybe<Scalars['String']>;
  readonly cellPhone: Maybe<Scalars['String']>;
  readonly sex: Maybe<SexType>;
  readonly birthDate: Maybe<Scalars['Date']>;
  readonly hasLoginAccess: Maybe<Scalars['Boolean']>;
  readonly active: Maybe<Scalars['Boolean']>;
};

export type TeamworksProfileSyncRule = {
  readonly id: Scalars['ID'];
  readonly athleteStatusIds: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly userTypeIds: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly teamIds: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly profileIds: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly notemealAccountType: NotemealAccountType;
  readonly priority: Scalars['Int'];
  readonly positionIds: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly genders: Maybe<ReadonlyArray<TeamworksGender>>;
  readonly matchNotemealTeamIds: Maybe<ReadonlyArray<Scalars['ID']>>;
};

export type TeamworksProfileSyncRuleInput = {
  readonly athleteStatusIds: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly userTypeIds: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly teamIds: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly profileIds: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly notemealAccountType: NotemealAccountType;
  readonly priority: Scalars['Int'];
  readonly positionIds: Maybe<ReadonlyArray<Scalars['Int']>>;
  readonly genders: Maybe<ReadonlyArray<TeamworksGender>>;
  readonly matchNotemealTeamIds: Maybe<ReadonlyArray<Scalars['ID']>>;
};

export type TeamworksSportMapping = {
  readonly teamworksName: Scalars['String'];
  readonly sportId: Scalars['ID'];
  readonly sport: Sport;
};

export type TeamworksSyncStatus = {
  readonly linkedProfileCount: Scalars['Int'];
  readonly unlinkedTeamworksProfileCount: Scalars['Int'];
  readonly unlinkedNotemealProfileCount: Scalars['Int'];
  readonly toDeactivateProfileCount: Scalars['Int'];
  readonly updatedAt: Scalars['DateTime'];
  readonly isFullySynced: Scalars['Boolean'];
  readonly isTeamworksLinked: Scalars['Boolean'];
  readonly isNotemealLinked: Scalars['Boolean'];
};

export type TeamworksTeam = {
  readonly id: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly sport: Scalars['String'];
  readonly sportMappings: ReadonlyArray<TeamworksSportMapping>;
  readonly notemealTeams: ReadonlyArray<Team>;
};

export type TeamworksUserType = {
  readonly userTypeId: Scalars['Int'];
  readonly userTypeName: Scalars['String'];
};

export type Territory = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly orgs: ReadonlyArray<Org>;
};

export type TerritoryOffsetConnection = {
  readonly edges: ReadonlyArray<Territory>;
  readonly pageInfo: OffsetPageInfo;
};

export type TextMealPlanToAthleteInput = {
  readonly mealPlanId: Scalars['ID'];
};

export type TextMealPlanToAthletePayload = {
  readonly result: Scalars['Boolean'];
};

export type TextMealPlansToAthletesInput = {
  readonly mealPlanIds: ReadonlyArray<Scalars['ID']>;
};

export type TextMealPlansToAthletesPayload = {
  readonly result: Scalars['Boolean'];
};


export type TimelineActivityOrTemplate = FoodLogActivity | TimelineActivityTemplate | { readonly __typename?: "%other" };

export type TimelineActivityTemplate = {
  readonly start: Scalars['DateTime'];
  readonly durationInMinutes: Scalars['Int'];
  readonly timezone: Scalars['Timezone'];
  readonly template: ActivityTemplate;
};

export type TimelineDate = {
  readonly date: Scalars['Date'];
  readonly timezone: Scalars['Timezone'];
  readonly isMealPlanSelected: Scalars['Boolean'];
  readonly mealPlan: Maybe<MealPlan>;
  readonly activities: ReadonlyArray<TimelineActivityOrTemplate>;
  readonly meals: ReadonlyArray<TimelineMealOrTemplate>;
};

export type TimelineItem = FoodLogMeal | FoodLogActivity | MenuOrder | MealPlanDate | StandaloneMealMenu | RestaurantMenuLog | { readonly __typename?: "%other" };

export type TimelineMeal = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly start: Scalars['DateTime'];
  readonly durationInMinutes: Scalars['Int'];
  readonly timezone: Scalars['Timezone'];
  readonly type: MealType;
  readonly mealTemplate: Maybe<MealTemplate>;
  readonly athlete: Maybe<Athlete>;
  readonly mealMenus: ReadonlyArray<MealMenu>;
  readonly restaurantMenuLogs: ReadonlyArray<RestaurantMenuLog>;
  readonly restaurantMenuLinkOrders: ReadonlyArray<RestaurantMenuLinkOrder>;
  readonly restaurantMenuLinkPlateOrders: ReadonlyArray<RestaurantMenuLinkPlateOrder>;
  readonly menuOrders: ReadonlyArray<MenuOrder>;
  readonly mealMenuLogs: ReadonlyArray<MealMenuLog>;
  readonly servingAmounts: ReadonlyArray<ServingAmount>;
  readonly comments: ReadonlyArray<Comment>;
  readonly images: ReadonlyArray<Image>;
  readonly notifications: ReadonlyArray<Notification>;
};

export type TimelineMealCommentNotification = Notification & {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
  readonly timelineMeal: Maybe<TimelineMeal>;
};

export type TimelineMealEditNotification = Notification & {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
  readonly timelineMeal: Maybe<TimelineMeal>;
};

export type TimelineMealImageNotification = Notification & {
  readonly id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  readonly type: Scalars['String'];
  readonly createdAt: Scalars['DateTime'];
  readonly viewedAt: Maybe<Scalars['DateTime']>;
  readonly title: Scalars['String'];
  readonly message: Scalars['String'];
  readonly timelineMeal: Maybe<TimelineMeal>;
  readonly image: Maybe<Image>;
};

export type TimelineMealInput = {
  readonly timelineMealId: Scalars['ID'];
  readonly addTimelineMeal: Maybe<AddTimelineMealInput>;
};

export type TimelineMealOrTemplate = TimelineMeal | TimelineMealTemplate | { readonly __typename?: "%other" };

export type TimelineMealPublication = {
  readonly payload: TimelineMealPublicationPayload;
};

export type TimelineMealPublicationPayload = AddTimelineMealCommentPayload | { readonly __typename?: "%other" };

export type TimelineMealTemplate = {
  readonly start: Scalars['DateTime'];
  readonly durationInMinutes: Scalars['Int'];
  readonly timezone: Scalars['Timezone'];
  readonly template: MealTemplate;
  readonly mealMenus: ReadonlyArray<MealMenu>;
};


export type UsdaFood = {
  readonly id: Scalars['ID'];
  readonly allHighlightFields: ReadonlyArray<Scalars['String']>;
  readonly brandOwner: Scalars['String'];
  readonly dataType: UsdaFoodDataType;
  readonly description: Scalars['String'];
  readonly gtinUpc: Scalars['String'];
  readonly ingredients: ReadonlyArray<Scalars['String']>;
  readonly publishedDate: Scalars['Date'];
  readonly score: Scalars['Float'];
};

export type UsdaFoodCategory = {
  readonly code: Scalars['String'];
  readonly description: Scalars['String'];
  readonly id: Scalars['ID'];
};

export type UsdaFoodDataType =
  | 'Branded'
  | 'Survey'
  | 'Legacy'
  | 'Foundation'
  | '%future added value';

export type UsdaFoodDetail = {
  readonly id: Scalars['ID'];
  readonly usdaFdcFoodCategoryId: Scalars['Int'];
  readonly usdaFdcDataType: Scalars['String'];
  readonly cho: UsdaFoodNutrient;
  readonly pro: UsdaFoodNutrient;
  readonly fat: UsdaFoodNutrient;
  readonly foodNutrients: ReadonlyArray<UsdaFoodNutrient>;
  readonly servings: ReadonlyArray<UsdaFoodServing>;
};

export type UsdaFoodNutrient = {
  readonly id: Scalars['ID'];
  readonly amount: Scalars['Float'];
  readonly min: Scalars['Float'];
  readonly max: Scalars['Float'];
  readonly median: Scalars['Float'];
  readonly dataPoints: Scalars['Int'];
  readonly name: Scalars['String'];
  readonly unitName: Scalars['String'];
  readonly footnote: Scalars['String'];
};

export type UsdaFoodServing = {
  readonly idx: Scalars['Int'];
  readonly cho: Scalars['Float'];
  readonly pro: Scalars['Float'];
  readonly fat: Scalars['Float'];
  readonly weight: Scalars['Float'];
  readonly defaultAmount: Scalars['Float'];
  readonly units: Scalars['String'];
  readonly isDefault: Scalars['Boolean'];
  readonly usdaWeightSeq: Scalars['String'];
};

export type UnarchiveAthleteInput = {
  readonly id: Scalars['ID'];
};

export type UnarchiveAthletePayload = {
  readonly athlete: Athlete;
};

export type Unit = {
  readonly id: Scalars['ID'];
  readonly name: Scalars['String'];
  readonly isProductionOnly: Scalars['Boolean'];
  readonly conversionsFrom: ReadonlyArray<UnitConversion>;
  readonly conversionsTo: ReadonlyArray<UnitConversion>;
};

export type UnitConversion = {
  readonly id: Scalars['ID'];
  readonly fromUnit: Unit;
  readonly fromQuantity: Scalars['Float'];
  readonly toUnit: Unit;
  readonly toQuantity: Scalars['Float'];
};

export type UnlockPlannedMenuInput = {
  readonly plannedMenuId: Scalars['ID'];
};

export type UnlockPlannedMenuPayload = {
  readonly lockReleased: Scalars['Boolean'];
  readonly plannedMenu: PlannedMenu;
};

export type UnmatchedIngredient = {
  readonly foodName: Scalars['String'];
  readonly amount: Scalars['Float'];
  readonly servingName: Scalars['String'];
};

export type UnmatchedIngredients = {
  readonly unmatchedName: ReadonlyArray<UnmatchedIngredient>;
  readonly unmatchedServings: ReadonlyArray<UnmatchedIngredient>;
};

export type UpdateApnCountForUserPayload = {
  readonly notificationCount: Scalars['Int'];
};

export type UpdateBulkOrderStatusPayload = {
  readonly bulkOrder: BulkOrder;
  readonly status: Scalars['String'];
};

export type UpdateMacroMealPlanTemplateSharingScopeInput = {
  readonly macroMealPlanTemplateId: Scalars['ID'];
  readonly isShared: Scalars['Boolean'];
};

export type UpdateMacroMealPlanTemplateSharingScopePayload = {
  readonly macroMealPlanTemplate: MealPlanTemplate;
};

export type UpdateMealMenuPlateItemOrderStatusInput = {
  readonly keyTuple: PlateOrderIdItemIdTuple;
  readonly status: MenuOrderItemStatus;
};

export type UpdateMealMenuPlateItemOrderStatusPayload = {
  readonly mealMenuPlateItemOrderStatus: MealMenuPlateItemOrderStatus;
};

export type UpdateMealMenuPlateOrderStatusPayload = {
  readonly mealMenuPlateOrderId: Scalars['String'];
  readonly status: MenuOrderItemStatus;
};

export type UpdateMealPlanTemplateAssignScopeInput = {
  readonly mealPlanTemplateId: Scalars['ID'];
  readonly teamIds: Maybe<ReadonlyArray<Scalars['ID']>>;
  readonly advancedSelectionInput: Maybe<AdvancedSelectionInput>;
};

export type UpdateMealPlanTemplateAssignScopePayload = {
  readonly mealPlanTemplate: MealPlanTemplate;
};

export type UpdateMenuOrderItemStatusesInput = {
  readonly menuOrderId: Scalars['ID'];
  readonly ids: ReadonlyArray<Scalars['ID']>;
  readonly status: MenuOrderItemStatus;
};

export type UpdateMenuOrderItemStatusesPayload = {
  readonly menuOrderItems: ReadonlyArray<MenuOrderItem>;
};

export type UpdateMultipleMealMenuPlateItemOrderStatusesInput = {
  readonly keyTuples: ReadonlyArray<PlateOrderIdItemIdTuple>;
  readonly status: MenuOrderItemStatus;
};

export type UpdateMultipleMealMenuPlateItemOrderStatusesPayload = {
  readonly mealMenuPlateItemOrderStatuses: ReadonlyArray<MealMenuPlateItemOrderStatus>;
};

export type UpdateMultipleMenuOrderItemStatusesInput = {
  readonly menuOrderIds: ReadonlyArray<Scalars['ID']>;
  readonly ids: ReadonlyArray<Scalars['ID']>;
  readonly status: MenuOrderItemStatus;
};

export type UpdateMultipleMenuOrderItemStatusesPayload = {
  readonly menuOrderItems: ReadonlyArray<MenuOrderItem>;
};

export type User = {
  readonly id: Scalars['ID'];
  readonly teamworksId: Maybe<Scalars['Int']>;
  readonly firstName: Scalars['String'];
  readonly lastName: Scalars['String'];
  readonly username: Maybe<Scalars['String']>;
  readonly isActive: Scalars['Boolean'];
  readonly email: Maybe<Scalars['String']>;
  readonly phoneNumber: Maybe<Scalars['String']>;
  readonly orgInfo: ReadonlyArray<OrgInfo>;
  readonly usesKg: Scalars['Boolean'];
  readonly lastLogin: Maybe<Scalars['DateTime']>;
  readonly dateJoined: Scalars['DateTime'];
  readonly locale: Locale;
};

export type UserCursorConnection = {
  readonly edges: ReadonlyArray<User>;
  readonly pageInfo: CursorPageInfo;
};

export type UserOffsetConnection = {
  readonly edges: ReadonlyArray<User>;
  readonly pageInfo: OffsetPageInfo;
};

export type UnsubmittedAgreementFormForUserQueryVariables = Exact<{
  orgId: Scalars['ID'];
}>;


export type UnsubmittedAgreementFormForUserQuery = { readonly unsubmittedAgreementFormForUser: Maybe<AgreementFormFragment> };

export type AddAgreementFormSubmissionMutationVariables = Exact<{
  input: AddAgreementFormSubmissionInput;
}>;


export type AddAgreementFormSubmissionMutation = { readonly addAgreementFormSubmission: { readonly agreementForm: AgreementFormFragment } };

export type AgreementFormFragment = Pick<AgreementForm, 'id' | 'title' | 'url' | 'acceptAgreementText'>;

export type CommentWithNotificationFragment = (
  Pick<Comment, 'id'>
  & { readonly notification: Maybe<Pick<AthleteSelfOnboardedNotification, 'id' | 'viewedAt'> | Pick<BulkOrderStatusNotification, 'id' | 'viewedAt'> | Pick<FoodLogMealCommentNotification, 'id' | 'viewedAt'> | Pick<FoodLogMealEditNotification, 'id' | 'viewedAt'> | Pick<FoodLogMealImageNotification, 'id' | 'viewedAt'> | Pick<GoalReminderNotification, 'id' | 'viewedAt'> | Pick<MealMenuAvailableNotification, 'id' | 'viewedAt'> | Pick<MealMenuIsSharedNotification, 'id' | 'viewedAt'> | Pick<MealMenuOrderDueNotification, 'id' | 'viewedAt'> | Pick<MealPlanSharedNotification, 'id' | 'viewedAt'> | Pick<MenuOrderCommentNotification, 'id' | 'viewedAt'> | Pick<MenuOrderImageNotification, 'id' | 'viewedAt'> | Pick<MenuOrderStatusNotification, 'id' | 'viewedAt'> | Pick<TimelineMealCommentNotification, 'id' | 'viewedAt'> | Pick<TimelineMealEditNotification, 'id' | 'viewedAt'> | Pick<TimelineMealImageNotification, 'id' | 'viewedAt'>> }
);

export type MacroMealPlanDisplaySettingsFragment = (
  { readonly __typename: 'MacroMealPlanDisplaySettings' }
  & Pick<MacroMealPlanDisplaySettings, 'dailyProgressViews' | 'mealProgressViews'>
);

export type ExchangeMealPlanDisplaySettingsFragment = (
  { readonly __typename: 'ExchangeMealPlanDisplaySettings' }
  & Pick<ExchangeMealPlanDisplaySettings, 'dailyProgressViewsForExchanges' | 'mealProgressViewsForExchanges' | 'dailyProgressViewsForMacros' | 'mealProgressViewsForMacros'>
);

export type NoTargetsDisplaySettingsFragment = Pick<NoTargetsDisplaySettings, 'dailyProgressViewsForMacros' | 'mealProgressViewsForMacros'>;

export type ExchangeSummaryLabelFragment = (
  { readonly __typename: 'ExchangeAmount' }
  & Pick<ExchangeAmount, 'id' | 'amount'>
  & { readonly exchange: (
    { readonly __typename: 'Exchange' }
    & Pick<Exchange, 'id' | 'name' | 'shortName' | 'type' | 'cho' | 'pro' | 'fat' | 'kcal'>
  ) }
);

export type RecipesByIdWithIngredientsQueryVariables = Exact<{
  ids: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;


export type RecipesByIdWithIngredientsQuery = { readonly recipesById: ReadonlyArray<RecipeWithServingsFragment> };

export type MyFeatureFlagsQueryVariables = Exact<{ [key: string]: never; }>;


export type MyFeatureFlagsQuery = { readonly myFeatureFlags: FeatureFlagsFragment };

export type FeatureFlagsFragment = Pick<FeatureFlags, 'athleteMenuSuggestions' | 'clientCredentials' | 'hasRestaurantMenus' | 'restaurantLogging' | 'teamworksSchedules' | 'mealPlanSuggestions' | 'prepPlan' | 'foodCategory' | 'pk2MenuBuilder' | 'orgGroupRecipes' | 'orgGroupFoods' | 'performanceKitchen' | 'printableTags' | 'hubMenuCheckIn' | 'goalTracker' | 'dietitianOnlyMenus' | 'bulkOrdering' | 'ukMealPlanOptionSuggestions' | 'digitalDisplays' | 'selfServiceOnboarding' | 'messaging'>;

export type RecipeFoodCategoryFragment = Pick<RecipeFoodCategory, 'category'>;

export type MenuItemFoodCategoryFragment = Pick<MenuItemFoodCategory, 'category'>;

export type MealMenuWithTimesFragment = Pick<StandaloneMealMenu, 'id' | 'timezone' | 'name' | 'start' | 'durationInMinutes' | 'prepTimeInMinutes' | 'lastOrderTimeBeforeEndInMinutes' | 'isViewOnlyForMe' | 'theme'>;

export type MealMenuDiningStationWithItemPreviewsFragment = (
  Pick<MealMenuDiningStation, 'id' | 'name' | 'maxAmount' | 'position'>
  & { readonly menuItemAppearances: ReadonlyArray<MenuItemAppearancePreviewFragment> }
);

export type MenuItemAppearancePreviewFragment = (
  Pick<MenuItemAppearance, 'id' | 'position' | 'maxAmount' | 'availableForOrder' | 'allowSpecialRequests'>
  & { readonly menuItem: MenuItemPreviewFragment }
);

export type MenuItemPreviewFragment = (
  { readonly __typename: 'MenuItem' }
  & Pick<MenuItem, 'id' | 'name' | 'servingName' | 'description' | 'imageUrl' | 'isOneOff' | 'defaultAvailableForOrder' | 'defaultAllowSpecialRequests' | 'defaultMaxAmount' | 'isDeleted'>
  & { readonly servingAmounts: ReadonlyArray<(
    Pick<ServingAmount, 'id'>
    & { readonly serving: (
      Pick<Serving, 'id'>
      & { readonly foodOrRecipe: FoodOrRecipePreferencesBrandedFoodFragment | FoodOrRecipePreferencesGenericFoodFragment | FoodOrRecipePreferencesRecipeFragment }
    ) }
  )>, readonly score: Maybe<ScoreAssignmentFragment>, readonly foodCategory: Maybe<FoodCategoryNameFragment> }
);

export type MealMenuOrderRateLimitQueryVariables = Exact<{
  mealMenuId: Scalars['ID'];
}>;


export type MealMenuOrderRateLimitQuery = { readonly mealMenu: (
    Pick<StandaloneMealMenu, 'id'>
    & { readonly orderRateLimit: Maybe<Pick<MenuOrderRateLimit, 'limit' | 'excludedTeamIds'>> }
  ), readonly menuOrderRateLimitRemainders: ReadonlyArray<MenuOrderRateLimitRemainderFragment> };

export type MenuOrderRateLimitRemainderFragment = Pick<MenuOrderRateLimitRemainder, 'start' | 'remaining'>;

export type MealMenuLogItemFormFragment = (
  Pick<MealMenuLogItem, 'id'>
  & MenuSelectionItemFormMealMenuLogItemFragment
);

export type ViewMealOptionFragment = (
  { readonly __typename: 'MealOption' }
  & Pick<MealOption, 'id' | 'note' | 'position'>
  & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
);

type MealTemplateForTargetsExchangeMealTemplateFragment = (
  { readonly __typename: 'ExchangeMealTemplate' }
  & Pick<ExchangeMealTemplate, 'id'>
  & { readonly exchangeTargets: ReadonlyArray<ExchangeAmountFragment> }
);

type MealTemplateForTargetsMacroMealTemplateFragment = (
  { readonly __typename: 'MacroMealTemplate' }
  & Pick<MacroMealTemplate, 'cho' | 'pro' | 'fat' | 'id'>
);

export type MealTemplateForTargetsFragment = MealTemplateForTargetsExchangeMealTemplateFragment | MealTemplateForTargetsMacroMealTemplateFragment;

export type ExchangeMealTemplateViewFragment = (
  { readonly __typename: 'ExchangeMealTemplate' }
  & Pick<ExchangeMealTemplate, 'id'>
  & { readonly mealOptions: ReadonlyArray<ViewMealOptionFragment>, readonly exchangeTargets: ReadonlyArray<ExchangeAmountFragment>, readonly meal: MealFragment }
);

export type MacroMealTemplateViewFragment = (
  { readonly __typename: 'MacroMealTemplate' }
  & Pick<MacroMealTemplate, 'id' | 'cho' | 'pro' | 'fat' | 'kcal'>
  & { readonly mealOptions: ReadonlyArray<ViewMealOptionFragment>, readonly meal: MealFragment }
);

export type MenuItemWithIdsFragment = (
  Pick<MenuItem, 'id'>
  & { readonly choices: ReadonlyArray<(
    Pick<MenuItemChoice, 'id'>
    & { readonly options: ReadonlyArray<Pick<MenuItemChoiceOption, 'id'>> }
  )> }
);

type MenuSelectionItemOptionWithIdsMealMenuLogItemOptionFragment = (
  Pick<MealMenuLogItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: Pick<MenuItemChoiceOption, 'id'> }
);

type MenuSelectionItemOptionWithIdsMealMenuPlateOrderItemOptionFragment = (
  Pick<MealMenuPlateOrderItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: Pick<MenuItemChoiceOption, 'id'> }
);

type MenuSelectionItemOptionWithIdsMenuItemChoiceOptionAmountFragment = (
  Pick<MenuItemChoiceOptionAmount, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: Pick<MenuItemChoiceOption, 'id'> }
);

type MenuSelectionItemOptionWithIdsMenuOrderItemOptionFragment = (
  Pick<MenuOrderItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: Pick<MenuItemChoiceOption, 'id'> }
);

type MenuSelectionItemOptionWithIdsRestaurantMenuLinkOrderItemOptionFragment = (
  Pick<RestaurantMenuLinkOrderItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: Pick<MenuItemChoiceOption, 'id'> }
);

type MenuSelectionItemOptionWithIdsRestaurantMenuLinkPlateItemOptionFragment = (
  Pick<RestaurantMenuLinkPlateItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: Pick<MenuItemChoiceOption, 'id'> }
);

type MenuSelectionItemOptionWithIdsRestaurantMenuLinkPlateOrderItemOptionFragment = (
  Pick<RestaurantMenuLinkPlateOrderItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: Pick<MenuItemChoiceOption, 'id'> }
);

type MenuSelectionItemOptionWithIdsRestaurantMenuLogItemOptionFragment = (
  Pick<RestaurantMenuLogItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: Pick<MenuItemChoiceOption, 'id'> }
);

export type MenuSelectionItemOptionWithIdsFragment = MenuSelectionItemOptionWithIdsMealMenuLogItemOptionFragment | MenuSelectionItemOptionWithIdsMealMenuPlateOrderItemOptionFragment | MenuSelectionItemOptionWithIdsMenuItemChoiceOptionAmountFragment | MenuSelectionItemOptionWithIdsMenuOrderItemOptionFragment | MenuSelectionItemOptionWithIdsRestaurantMenuLinkOrderItemOptionFragment | MenuSelectionItemOptionWithIdsRestaurantMenuLinkPlateItemOptionFragment | MenuSelectionItemOptionWithIdsRestaurantMenuLinkPlateOrderItemOptionFragment | MenuSelectionItemOptionWithIdsRestaurantMenuLogItemOptionFragment;

export type MenuItemFormFragment = (
  { readonly __typename: 'MenuItem' }
  & Pick<MenuItem, 'id' | 'name' | 'servingName' | 'description' | 'isOneOff' | 'imageUrl' | 'suggestionCategory' | 'defaultMaxAmount' | 'defaultAvailableForOrder' | 'defaultAllowSpecialRequests' | 'isDeleted'>
  & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment>, readonly choices: ReadonlyArray<MenuItemChoiceFormFragment>, readonly score: Maybe<ScoreAssignmentFragment>, readonly foodCategory: Maybe<FoodCategoryNameFragment> }
);

export type MenuItemChoiceFormFragment = (
  Pick<MenuItemChoice, 'id' | 'name' | 'position' | 'required' | 'maxOptionsCount'>
  & { readonly options: ReadonlyArray<MenuItemChoiceOptionFormFragment> }
);

export type MenuItemChoiceOptionFormFragment = (
  Pick<MenuItemChoiceOption, 'id' | 'name' | 'position' | 'canEditAmount' | 'defaultAmount' | 'maxAmount'>
  & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
);

export type FoodCategoryNameFragment = Pick<MenuItemFoodCategory, 'category'>;

export type MenuOrderItemFormFragment = (
  Pick<MenuOrderItem, 'id' | 'forOrder' | 'status' | 'specialRequests'>
  & { readonly rating: Maybe<RatingFragment> }
  & MenuSelectionItemFormMenuOrderItemFragment
);

export type RatingFragment = Pick<MenuItemRating, 'id' | 'review' | 'value'>;

type MenuSelectionFormMealMenuLogFragment = { readonly items: ReadonlyArray<MenuSelectionItemFormMealMenuLogItemFragment> };

type MenuSelectionFormMealMenuPlateOrderFragment = { readonly items: ReadonlyArray<MenuSelectionItemFormMealMenuPlateOrderItemFragment> };

type MenuSelectionFormMenuOrderFragment = { readonly items: ReadonlyArray<MenuSelectionItemFormMenuOrderItemFragment> };

type MenuSelectionFormMenuOrderSuggestionFragment = { readonly items: ReadonlyArray<MenuSelectionItemFormMenuItemAmountFragment> };

type MenuSelectionFormRestaurantMenuLinkOrderFragment = { readonly items: ReadonlyArray<MenuSelectionItemFormRestaurantMenuLinkOrderItemFragment> };

type MenuSelectionFormRestaurantMenuLinkPlateFragment = { readonly items: ReadonlyArray<MenuSelectionItemFormRestaurantMenuLinkPlateItemFragment> };

type MenuSelectionFormRestaurantMenuLinkPlateOrderFragment = { readonly items: ReadonlyArray<MenuSelectionItemFormRestaurantMenuLinkPlateOrderItemFragment> };

type MenuSelectionFormRestaurantMenuLogFragment = { readonly items: ReadonlyArray<MenuSelectionItemFormRestaurantMenuLogItemFragment> };

export type MenuSelectionFormFragment = MenuSelectionFormMealMenuLogFragment | MenuSelectionFormMealMenuPlateOrderFragment | MenuSelectionFormMenuOrderFragment | MenuSelectionFormMenuOrderSuggestionFragment | MenuSelectionFormRestaurantMenuLinkOrderFragment | MenuSelectionFormRestaurantMenuLinkPlateFragment | MenuSelectionFormRestaurantMenuLinkPlateOrderFragment | MenuSelectionFormRestaurantMenuLogFragment;

type MenuSelectionItemWithMacrosMealMenuLogItemFragment = (
  Pick<MealMenuLogItem, 'amount' | 'percentConsumed'>
  & { readonly menuItem: (
    Pick<MenuItem, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ), readonly options: ReadonlyArray<MenuSelectItemOptionWithMacrosMealMenuLogItemOptionFragment> }
);

type MenuSelectionItemWithMacrosMealMenuPlateOrderItemFragment = (
  Pick<MealMenuPlateOrderItem, 'amount' | 'percentConsumed'>
  & { readonly menuItem: (
    Pick<MenuItem, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ), readonly options: ReadonlyArray<MenuSelectItemOptionWithMacrosMealMenuPlateOrderItemOptionFragment> }
);

type MenuSelectionItemWithMacrosMenuItemAmountFragment = (
  Pick<MenuItemAmount, 'amount' | 'percentConsumed'>
  & { readonly menuItem: (
    Pick<MenuItem, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ), readonly options: ReadonlyArray<MenuSelectItemOptionWithMacrosMenuItemChoiceOptionAmountFragment> }
);

type MenuSelectionItemWithMacrosMenuOrderItemFragment = (
  Pick<MenuOrderItem, 'amount' | 'percentConsumed'>
  & { readonly menuItem: (
    Pick<MenuItem, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ), readonly options: ReadonlyArray<MenuSelectItemOptionWithMacrosMenuOrderItemOptionFragment> }
);

type MenuSelectionItemWithMacrosRestaurantMenuLinkOrderItemFragment = (
  Pick<RestaurantMenuLinkOrderItem, 'amount' | 'percentConsumed'>
  & { readonly menuItem: (
    Pick<MenuItem, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ), readonly options: ReadonlyArray<MenuSelectItemOptionWithMacrosRestaurantMenuLinkOrderItemOptionFragment> }
);

type MenuSelectionItemWithMacrosRestaurantMenuLinkPlateItemFragment = (
  Pick<RestaurantMenuLinkPlateItem, 'amount' | 'percentConsumed'>
  & { readonly menuItem: (
    Pick<MenuItem, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ), readonly options: ReadonlyArray<MenuSelectItemOptionWithMacrosRestaurantMenuLinkPlateItemOptionFragment> }
);

type MenuSelectionItemWithMacrosRestaurantMenuLinkPlateOrderItemFragment = (
  Pick<RestaurantMenuLinkPlateOrderItem, 'amount' | 'percentConsumed'>
  & { readonly menuItem: (
    Pick<MenuItem, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ), readonly options: ReadonlyArray<MenuSelectItemOptionWithMacrosRestaurantMenuLinkPlateOrderItemOptionFragment> }
);

type MenuSelectionItemWithMacrosRestaurantMenuLogItemFragment = (
  Pick<RestaurantMenuLogItem, 'amount' | 'percentConsumed'>
  & { readonly menuItem: (
    Pick<MenuItem, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ), readonly options: ReadonlyArray<MenuSelectItemOptionWithMacrosRestaurantMenuLogItemOptionFragment> }
);

export type MenuSelectionItemWithMacrosFragment = MenuSelectionItemWithMacrosMealMenuLogItemFragment | MenuSelectionItemWithMacrosMealMenuPlateOrderItemFragment | MenuSelectionItemWithMacrosMenuItemAmountFragment | MenuSelectionItemWithMacrosMenuOrderItemFragment | MenuSelectionItemWithMacrosRestaurantMenuLinkOrderItemFragment | MenuSelectionItemWithMacrosRestaurantMenuLinkPlateItemFragment | MenuSelectionItemWithMacrosRestaurantMenuLinkPlateOrderItemFragment | MenuSelectionItemWithMacrosRestaurantMenuLogItemFragment;

type MenuSelectItemOptionWithMacrosMealMenuLogItemOptionFragment = (
  Pick<MealMenuLogItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: (
    Pick<MenuItemChoiceOption, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ) }
);

type MenuSelectItemOptionWithMacrosMealMenuPlateOrderItemOptionFragment = (
  Pick<MealMenuPlateOrderItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: (
    Pick<MenuItemChoiceOption, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ) }
);

type MenuSelectItemOptionWithMacrosMenuItemChoiceOptionAmountFragment = (
  Pick<MenuItemChoiceOptionAmount, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: (
    Pick<MenuItemChoiceOption, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ) }
);

type MenuSelectItemOptionWithMacrosMenuOrderItemOptionFragment = (
  Pick<MenuOrderItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: (
    Pick<MenuItemChoiceOption, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ) }
);

type MenuSelectItemOptionWithMacrosRestaurantMenuLinkOrderItemOptionFragment = (
  Pick<RestaurantMenuLinkOrderItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: (
    Pick<MenuItemChoiceOption, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ) }
);

type MenuSelectItemOptionWithMacrosRestaurantMenuLinkPlateItemOptionFragment = (
  Pick<RestaurantMenuLinkPlateItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: (
    Pick<MenuItemChoiceOption, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ) }
);

type MenuSelectItemOptionWithMacrosRestaurantMenuLinkPlateOrderItemOptionFragment = (
  Pick<RestaurantMenuLinkPlateOrderItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: (
    Pick<MenuItemChoiceOption, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ) }
);

type MenuSelectItemOptionWithMacrosRestaurantMenuLogItemOptionFragment = (
  Pick<RestaurantMenuLogItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: (
    Pick<MenuItemChoiceOption, 'id'>
    & { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  ) }
);

export type MenuSelectItemOptionWithMacrosFragment = MenuSelectItemOptionWithMacrosMealMenuLogItemOptionFragment | MenuSelectItemOptionWithMacrosMealMenuPlateOrderItemOptionFragment | MenuSelectItemOptionWithMacrosMenuItemChoiceOptionAmountFragment | MenuSelectItemOptionWithMacrosMenuOrderItemOptionFragment | MenuSelectItemOptionWithMacrosRestaurantMenuLinkOrderItemOptionFragment | MenuSelectItemOptionWithMacrosRestaurantMenuLinkPlateItemOptionFragment | MenuSelectItemOptionWithMacrosRestaurantMenuLinkPlateOrderItemOptionFragment | MenuSelectItemOptionWithMacrosRestaurantMenuLogItemOptionFragment;

type MenuSelectionItemFormMealMenuLogItemFragment = (
  Pick<MealMenuLogItem, 'amount' | 'percentConsumed'>
  & { readonly menuItem: MenuItemFormFragment, readonly options: ReadonlyArray<MenuSelectionItemOptionFormMealMenuLogItemOptionFragment> }
);

type MenuSelectionItemFormMealMenuPlateOrderItemFragment = (
  Pick<MealMenuPlateOrderItem, 'amount' | 'percentConsumed'>
  & { readonly menuItem: MenuItemFormFragment, readonly options: ReadonlyArray<MenuSelectionItemOptionFormMealMenuPlateOrderItemOptionFragment> }
);

type MenuSelectionItemFormMenuItemAmountFragment = (
  Pick<MenuItemAmount, 'amount' | 'percentConsumed'>
  & { readonly menuItem: MenuItemFormFragment, readonly options: ReadonlyArray<MenuSelectionItemOptionFormMenuItemChoiceOptionAmountFragment> }
);

type MenuSelectionItemFormMenuOrderItemFragment = (
  Pick<MenuOrderItem, 'amount' | 'percentConsumed'>
  & { readonly menuItem: MenuItemFormFragment, readonly options: ReadonlyArray<MenuSelectionItemOptionFormMenuOrderItemOptionFragment> }
);

type MenuSelectionItemFormRestaurantMenuLinkOrderItemFragment = (
  Pick<RestaurantMenuLinkOrderItem, 'amount' | 'percentConsumed'>
  & { readonly menuItem: MenuItemFormFragment, readonly options: ReadonlyArray<MenuSelectionItemOptionFormRestaurantMenuLinkOrderItemOptionFragment> }
);

type MenuSelectionItemFormRestaurantMenuLinkPlateItemFragment = (
  Pick<RestaurantMenuLinkPlateItem, 'amount' | 'percentConsumed'>
  & { readonly menuItem: MenuItemFormFragment, readonly options: ReadonlyArray<MenuSelectionItemOptionFormRestaurantMenuLinkPlateItemOptionFragment> }
);

type MenuSelectionItemFormRestaurantMenuLinkPlateOrderItemFragment = (
  Pick<RestaurantMenuLinkPlateOrderItem, 'amount' | 'percentConsumed'>
  & { readonly menuItem: MenuItemFormFragment, readonly options: ReadonlyArray<MenuSelectionItemOptionFormRestaurantMenuLinkPlateOrderItemOptionFragment> }
);

type MenuSelectionItemFormRestaurantMenuLogItemFragment = (
  Pick<RestaurantMenuLogItem, 'amount' | 'percentConsumed'>
  & { readonly menuItem: MenuItemFormFragment, readonly options: ReadonlyArray<MenuSelectionItemOptionFormRestaurantMenuLogItemOptionFragment> }
);

export type MenuSelectionItemFormFragment = MenuSelectionItemFormMealMenuLogItemFragment | MenuSelectionItemFormMealMenuPlateOrderItemFragment | MenuSelectionItemFormMenuItemAmountFragment | MenuSelectionItemFormMenuOrderItemFragment | MenuSelectionItemFormRestaurantMenuLinkOrderItemFragment | MenuSelectionItemFormRestaurantMenuLinkPlateItemFragment | MenuSelectionItemFormRestaurantMenuLinkPlateOrderItemFragment | MenuSelectionItemFormRestaurantMenuLogItemFragment;

type MenuSelectionItemOptionFormMealMenuLogItemOptionFragment = (
  Pick<MealMenuLogItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: MenuItemChoiceOptionFormFragment }
);

type MenuSelectionItemOptionFormMealMenuPlateOrderItemOptionFragment = (
  Pick<MealMenuPlateOrderItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: MenuItemChoiceOptionFormFragment }
);

type MenuSelectionItemOptionFormMenuItemChoiceOptionAmountFragment = (
  Pick<MenuItemChoiceOptionAmount, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: MenuItemChoiceOptionFormFragment }
);

type MenuSelectionItemOptionFormMenuOrderItemOptionFragment = (
  Pick<MenuOrderItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: MenuItemChoiceOptionFormFragment }
);

type MenuSelectionItemOptionFormRestaurantMenuLinkOrderItemOptionFragment = (
  Pick<RestaurantMenuLinkOrderItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: MenuItemChoiceOptionFormFragment }
);

type MenuSelectionItemOptionFormRestaurantMenuLinkPlateItemOptionFragment = (
  Pick<RestaurantMenuLinkPlateItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: MenuItemChoiceOptionFormFragment }
);

type MenuSelectionItemOptionFormRestaurantMenuLinkPlateOrderItemOptionFragment = (
  Pick<RestaurantMenuLinkPlateOrderItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: MenuItemChoiceOptionFormFragment }
);

type MenuSelectionItemOptionFormRestaurantMenuLogItemOptionFragment = (
  Pick<RestaurantMenuLogItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: MenuItemChoiceOptionFormFragment }
);

export type MenuSelectionItemOptionFormFragment = MenuSelectionItemOptionFormMealMenuLogItemOptionFragment | MenuSelectionItemOptionFormMealMenuPlateOrderItemOptionFragment | MenuSelectionItemOptionFormMenuItemChoiceOptionAmountFragment | MenuSelectionItemOptionFormMenuOrderItemOptionFragment | MenuSelectionItemOptionFormRestaurantMenuLinkOrderItemOptionFragment | MenuSelectionItemOptionFormRestaurantMenuLinkPlateItemOptionFragment | MenuSelectionItemOptionFormRestaurantMenuLinkPlateOrderItemOptionFragment | MenuSelectionItemOptionFormRestaurantMenuLogItemOptionFragment;

export type MenuOrderSuggestionsQueryVariables = Exact<{
  targetMacros: MacrosInput;
  mealMenuId: Scalars['ID'];
  avoidFoodGroupIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  limit: Maybe<Scalars['Int']>;
}>;


export type MenuOrderSuggestionsQuery = { readonly menuOrderSuggestions: Maybe<ReadonlyArray<MenuOrderSuggestionFragment>> };

export type RestaurantMenuLinkSuggestionsQueryVariables = Exact<{
  targetMacros: MacrosInput;
  restaurantMenuLinkId: Scalars['ID'];
  avoidFoodGroupIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  limit: Maybe<Scalars['Int']>;
}>;


export type RestaurantMenuLinkSuggestionsQuery = { readonly restauranMenuLinkSuggestions: Maybe<ReadonlyArray<MenuOrderSuggestionFragment>> };

export type RestaurantMenuSuggestionsQueryVariables = Exact<{
  targetMacros: MacrosInput;
  restaurantMenuId: Scalars['ID'];
  avoidFoodGroupIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
  limit: Maybe<Scalars['Int']>;
}>;


export type RestaurantMenuSuggestionsQuery = { readonly restaurantMenuSuggestions: Maybe<ReadonlyArray<MenuOrderSuggestionFragment>> };

export type MenuOrderSuggestionFragment = { readonly items: ReadonlyArray<(
    Pick<MenuItemAmount, 'amount' | 'percentConsumed'>
    & { readonly menuItem: MenuItemFormFragment, readonly options: ReadonlyArray<(
      Pick<MenuItemChoiceOptionAmount, 'amount' | 'percentConsumed'>
      & { readonly menuItemChoiceOption: MenuItemChoiceOptionFormFragment }
    )> }
  )> };

export type RecordMenuOrderSuggestionsViewMutationVariables = Exact<{
  input: RecordMenuOrderSuggestionsViewInput;
}>;


export type RecordMenuOrderSuggestionsViewMutation = Pick<Mutation, 'recordMenuOrderSuggestionsView'>;

export type RecordMenuOrderSuggestionDecisionMutationVariables = Exact<{
  input: RecordMenuOrderSuggestionDecisionInput;
}>;


export type RecordMenuOrderSuggestionDecisionMutation = Pick<Mutation, 'recordMenuOrderSuggestionDecision'>;

export type NutrientsWithDriQueryVariables = Exact<{
  age: Maybe<Scalars['Int']>;
  sex: Maybe<SexType>;
  kcal: Maybe<Scalars['Float']>;
}>;


export type NutrientsWithDriQuery = { readonly nutrients: ReadonlyArray<NutrientWithDriFragment> };

export type ServingsWithNutrientAmountsQueryVariables = Exact<{
  servingIds: ReadonlyArray<Scalars['ID']> | Scalars['ID'];
}>;


export type ServingsWithNutrientAmountsQuery = { readonly servings: ReadonlyArray<ServingWithNutrientAmountsFragment> };

export type ServingWithNutrientAmountsFragment = (
  Pick<Serving, 'id'>
  & { readonly nutrientAmounts: ReadonlyArray<NutrientAmountFragment>, readonly foodOrRecipe: Pick<BrandedFood, 'id' | 'name'> | Pick<GenericFood, 'id' | 'name'> | Pick<Recipe, 'id' | 'name'> }
);

export type NutrientAmountFragment = (
  Pick<NutrientAmount, 'amount'>
  & { readonly nutrient: Pick<Nutrient, 'id' | 'name'> }
);

export type NutrientWithDriFragment = (
  Pick<Nutrient, 'id' | 'name' | 'unitName'>
  & { readonly defaultDietaryReferenceIntake: Maybe<Pick<NutrientDietaryReferenceIntake, 'id' | 'type' | 'amount'>> }
);

export type RestaurantMenuWithItemPreviewsFragment = (
  Pick<RestaurantMenu, 'id'>
  & { readonly sections: ReadonlyArray<(
    Pick<RestaurantMenuSection, 'id' | 'name' | 'position'>
    & { readonly menuItemAppearances: ReadonlyArray<MenuItemAppearancePreviewFragment> }
  )> }
);

export type RestaurantMenuLinkWithMyOrdersFragment = (
  Pick<RestaurantMenuLink, 'id' | 'allowCustomOrders' | 'allowPlateOrders'>
  & { readonly restaurant: Pick<Restaurant, 'id' | 'name'>, readonly order: Maybe<RestaurantMenuLinkOrderFragment>, readonly plateOrder: Maybe<RestaurantMenuLinkPlateOrderFragment> }
);

export type RestaurantMenuLinkWithOrdersForAthleteFragment = (
  Pick<RestaurantMenuLink, 'id' | 'allowCustomOrders' | 'allowPlateOrders'>
  & { readonly restaurant: Pick<Restaurant, 'id' | 'name'>, readonly order: Maybe<RestaurantMenuLinkOrderFragment>, readonly plateOrder: Maybe<RestaurantMenuLinkPlateOrderFragment> }
);

export type RestaurantMenuLinkWithItemPreviewsFragment = (
  Pick<RestaurantMenuLink, 'id' | 'allowCustomOrders' | 'allowPlateOrders'>
  & { readonly restaurant: Pick<Restaurant, 'id' | 'name'>, readonly sections: ReadonlyArray<(
    Pick<RestaurantMenuSectionLink, 'maxAmount'>
    & { readonly restaurantMenuSection: Pick<RestaurantMenuSection, 'id' | 'name' | 'position'>, readonly menuItemAppearances: ReadonlyArray<MenuItemAppearancePreviewFragment> }
  )>, readonly plates: ReadonlyArray<RestaurantMenuLinkPlateFragment> }
);

export type RestaurantMenuLinkPlateFragment = (
  Pick<RestaurantMenuLinkPlate, 'id' | 'position'>
  & { readonly items: ReadonlyArray<RestaurantMenuLinkPlateItemFormFragment> }
);

export type RestaurantMenuLinkOrderFragment = (
  Pick<RestaurantMenuLinkOrder, 'id'>
  & { readonly items: ReadonlyArray<RestaurantMenuLinkOrderItemFormFragment> }
);

export type RestaurantMenuLinkOrderItemFormFragment = (
  Pick<RestaurantMenuLinkOrderItem, 'id' | 'specialRequests'>
  & MenuSelectionItemFormRestaurantMenuLinkOrderItemFragment
);

export type RestaurantMenuLinkPlateItemFormFragment = (
  Pick<RestaurantMenuLinkPlateItem, 'id' | 'position' | 'specialRequests'>
  & MenuSelectionItemFormRestaurantMenuLinkPlateItemFragment
);

export type RestaurantMenuLinkPlateOrderFragment = (
  Pick<RestaurantMenuLinkPlateOrder, 'id' | 'userFullName'>
  & { readonly items: ReadonlyArray<RestaurantMenuLinkPlateOrderItemFormFragment>, readonly plate: (
    Pick<RestaurantMenuLinkPlate, 'id' | 'position'>
    & { readonly items: ReadonlyArray<RestaurantMenuLinkPlateItemFormFragment> }
  ), readonly restaurantMenuLink: Pick<RestaurantMenuLink, 'id'> }
);

export type RestaurantMenuLinkPlateOrderItemFormFragment = (
  Pick<RestaurantMenuLinkPlateOrderItem, 'amount' | 'percentConsumed'>
  & { readonly plateItem: Pick<RestaurantMenuLinkPlateItem, 'id'>, readonly menuItem: MenuItemFormFragment, readonly options: ReadonlyArray<RestaurantMenuLinkPlateOrderItemOptionFormFragment> }
);

export type RestaurantMenuLinkPlateOrderItemOptionFormFragment = (
  Pick<RestaurantMenuLinkPlateOrderItemOption, 'amount' | 'percentConsumed'>
  & { readonly menuItemChoiceOption: MenuItemChoiceOptionFormFragment }
);

export type RestaurantMenuLogFragment = (
  Pick<RestaurantMenuLog, 'id' | 'dateTime' | 'timezone' | 'type'>
  & { readonly mealTemplate: Maybe<Pick<ExchangeMealTemplate, 'id'> | Pick<MacroMealTemplate, 'id'>>, readonly items: ReadonlyArray<RestaurantMenuLogItemFormFragment>, readonly restaurantMenu: Pick<RestaurantMenu, 'id'> }
);

export type RestaurantMenuLogItemFormFragment = (
  Pick<RestaurantMenuLogItem, 'id'>
  & MenuSelectionItemFormRestaurantMenuLogItemFragment
);

export type ScoreAssignmentFragment = (
  Pick<Score, 'value'>
  & { readonly scoringSystem: ScoringSystemAssignmentFragment }
);

export type ScoringSystemAssignmentFragment = Pick<ScoringSystem, 'name' | 'id'>;

export type ScoringSystemFragment = Pick<ScoringSystem, 'id' | 'name'>;

export type ConvertedServingQueryVariables = Exact<{
  input: ConvertedServingInput;
}>;


export type ConvertedServingQuery = { readonly convertedServing: ServingWithFoodOrRecipeFragment };

export type ServingWithFoodOrRecipeMacrosFragment = (
  { readonly __typename: 'Serving' }
  & Pick<Serving, 'id'>
  & { readonly macros: Pick<Macros, 'cho' | 'pro' | 'fat' | 'kcal'>, readonly foodOrRecipe: (
    { readonly __typename: 'BrandedFood' }
    & Pick<BrandedFood, 'id' | 'name' | 'usdaFdcDescription'>
  ) | (
    { readonly __typename: 'GenericFood' }
    & Pick<GenericFood, 'id' | 'name' | 'usdaFdcDescription'>
  ) | (
    { readonly __typename: 'Recipe' }
    & Pick<Recipe, 'id' | 'name'>
    & { readonly createdBy: Pick<User, 'firstName' | 'lastName'> }
  ) }
);

export type RecipeIngredientServingAmountFragment = (
  Pick<ServingAmount, 'id' | 'amount' | 'position'>
  & { readonly serving: RecipeIngredientServingFragment }
);

type TimelineItemForGroupByDateFoodLogMealFragment = (
  { readonly __typename: 'FoodLogMeal' }
  & Pick<FoodLogMeal, 'id'>
);

type TimelineItemForGroupByDateFoodLogActivityFragment = (
  { readonly __typename: 'FoodLogActivity' }
  & Pick<FoodLogActivity, 'id' | 'date' | 'startTime' | 'timezone'>
);

type TimelineItemForGroupByDateMenuOrderFragment = (
  { readonly __typename: 'MenuOrder' }
  & Pick<MenuOrder, 'id'>
);

type TimelineItemForGroupByDateMealPlanDateFragment = (
  { readonly __typename: 'MealPlanDate' }
  & Pick<MealPlanDate, 'date' | 'timezone'>
);

type TimelineItemForGroupByDateStandaloneMealMenuFragment = (
  { readonly __typename: 'StandaloneMealMenu' }
  & Pick<StandaloneMealMenu, 'id'>
);

type TimelineItemForGroupByDateRestaurantMenuLogFragment = (
  { readonly __typename: 'RestaurantMenuLog' }
  & Pick<RestaurantMenuLog, 'id'>
);

export type TimelineItemForGroupByDateFragment = TimelineItemForGroupByDateFoodLogMealFragment | TimelineItemForGroupByDateFoodLogActivityFragment | TimelineItemForGroupByDateMenuOrderFragment | TimelineItemForGroupByDateMealPlanDateFragment | TimelineItemForGroupByDateStandaloneMealMenuFragment | TimelineItemForGroupByDateRestaurantMenuLogFragment;

export type TimelineMealForGroupByDateFragment = (
  Pick<TimelineMeal, 'id' | 'start' | 'timezone'>
  & { readonly notifications: ReadonlyArray<NotificationTimelineAthleteSelfOnboardedNotificationFragment | NotificationTimelineBulkOrderStatusNotificationFragment | NotificationTimelineFoodLogMealCommentNotificationFragment | NotificationTimelineFoodLogMealEditNotificationFragment | NotificationTimelineFoodLogMealImageNotificationFragment | NotificationTimelineGoalReminderNotificationFragment | NotificationTimelineMealMenuAvailableNotificationFragment | NotificationTimelineMealMenuIsSharedNotificationFragment | NotificationTimelineMealMenuOrderDueNotificationFragment | NotificationTimelineMealPlanSharedNotificationFragment | NotificationTimelineMenuOrderCommentNotificationFragment | NotificationTimelineMenuOrderImageNotificationFragment | NotificationTimelineMenuOrderStatusNotificationFragment | NotificationTimelineTimelineMealCommentNotificationFragment | NotificationTimelineTimelineMealEditNotificationFragment | NotificationTimelineTimelineMealImageNotificationFragment> }
);

type NotificationTimelineAthleteSelfOnboardedNotificationFragment = (
  { readonly __typename: 'AthleteSelfOnboardedNotification' }
  & Pick<AthleteSelfOnboardedNotification, 'id' | 'viewedAt'>
);

type NotificationTimelineBulkOrderStatusNotificationFragment = (
  { readonly __typename: 'BulkOrderStatusNotification' }
  & Pick<BulkOrderStatusNotification, 'id' | 'viewedAt'>
);

type NotificationTimelineFoodLogMealCommentNotificationFragment = (
  { readonly __typename: 'FoodLogMealCommentNotification' }
  & Pick<FoodLogMealCommentNotification, 'id' | 'viewedAt'>
);

type NotificationTimelineFoodLogMealEditNotificationFragment = (
  { readonly __typename: 'FoodLogMealEditNotification' }
  & Pick<FoodLogMealEditNotification, 'id' | 'viewedAt'>
);

type NotificationTimelineFoodLogMealImageNotificationFragment = (
  { readonly __typename: 'FoodLogMealImageNotification' }
  & Pick<FoodLogMealImageNotification, 'id' | 'viewedAt'>
);

type NotificationTimelineGoalReminderNotificationFragment = (
  { readonly __typename: 'GoalReminderNotification' }
  & Pick<GoalReminderNotification, 'id' | 'viewedAt'>
);

type NotificationTimelineMealMenuAvailableNotificationFragment = (
  { readonly __typename: 'MealMenuAvailableNotification' }
  & Pick<MealMenuAvailableNotification, 'id' | 'viewedAt'>
);

type NotificationTimelineMealMenuIsSharedNotificationFragment = (
  { readonly __typename: 'MealMenuIsSharedNotification' }
  & Pick<MealMenuIsSharedNotification, 'id' | 'viewedAt'>
);

type NotificationTimelineMealMenuOrderDueNotificationFragment = (
  { readonly __typename: 'MealMenuOrderDueNotification' }
  & Pick<MealMenuOrderDueNotification, 'id' | 'viewedAt'>
);

type NotificationTimelineMealPlanSharedNotificationFragment = (
  { readonly __typename: 'MealPlanSharedNotification' }
  & Pick<MealPlanSharedNotification, 'id' | 'viewedAt'>
);

type NotificationTimelineMenuOrderCommentNotificationFragment = (
  { readonly __typename: 'MenuOrderCommentNotification' }
  & Pick<MenuOrderCommentNotification, 'id' | 'viewedAt'>
);

type NotificationTimelineMenuOrderImageNotificationFragment = (
  { readonly __typename: 'MenuOrderImageNotification' }
  & Pick<MenuOrderImageNotification, 'id' | 'viewedAt'>
);

type NotificationTimelineMenuOrderStatusNotificationFragment = (
  { readonly __typename: 'MenuOrderStatusNotification' }
  & Pick<MenuOrderStatusNotification, 'id' | 'viewedAt'>
);

type NotificationTimelineTimelineMealCommentNotificationFragment = (
  { readonly __typename: 'TimelineMealCommentNotification' }
  & Pick<TimelineMealCommentNotification, 'id' | 'viewedAt'>
);

type NotificationTimelineTimelineMealEditNotificationFragment = (
  { readonly __typename: 'TimelineMealEditNotification' }
  & Pick<TimelineMealEditNotification, 'id' | 'viewedAt'>
);

type NotificationTimelineTimelineMealImageNotificationFragment = (
  { readonly __typename: 'TimelineMealImageNotification' }
  & Pick<TimelineMealImageNotification, 'id' | 'viewedAt'>
);

export type NotificationTimelineFragment = NotificationTimelineAthleteSelfOnboardedNotificationFragment | NotificationTimelineBulkOrderStatusNotificationFragment | NotificationTimelineFoodLogMealCommentNotificationFragment | NotificationTimelineFoodLogMealEditNotificationFragment | NotificationTimelineFoodLogMealImageNotificationFragment | NotificationTimelineGoalReminderNotificationFragment | NotificationTimelineMealMenuAvailableNotificationFragment | NotificationTimelineMealMenuIsSharedNotificationFragment | NotificationTimelineMealMenuOrderDueNotificationFragment | NotificationTimelineMealPlanSharedNotificationFragment | NotificationTimelineMenuOrderCommentNotificationFragment | NotificationTimelineMenuOrderImageNotificationFragment | NotificationTimelineMenuOrderStatusNotificationFragment | NotificationTimelineTimelineMealCommentNotificationFragment | NotificationTimelineTimelineMealEditNotificationFragment | NotificationTimelineTimelineMealImageNotificationFragment;

export type MealMenuSharedTimelineFragment = (
  { readonly __typename: 'StandaloneMealMenu' }
  & Pick<StandaloneMealMenu, 'id' | 'timezone' | 'name' | 'start' | 'durationInMinutes' | 'prepTimeInMinutes' | 'lastOrderTimeBeforeEndInMinutes' | 'isLogOnly' | 'isInHouseMenuOrderable' | 'isRestaurantMenuOrderable' | 'isViewOnlyForMe' | 'isBulkOrderable'>
  & { mealType: StandaloneMealMenu['type'] }
  & { readonly restaurantMenuLinks: ReadonlyArray<(
    Pick<RestaurantMenuLink, 'id' | 'allowCustomOrders' | 'allowPlateOrders'>
    & { readonly restaurant: Pick<Restaurant, 'id' | 'name'> }
  )> }
);

export type RestaurantMenuLinkOrderSharedTimelineFragment = (
  { readonly mealMenu: Pick<StandaloneMealMenu, 'id'>, readonly restaurantMenuLink: (
    Pick<RestaurantMenuLink, 'id'>
    & { readonly restaurant: Pick<Restaurant, 'id' | 'name'> }
  ) }
  & RestaurantMenuLinkOrderFragment
);

export type RestaurantMenuLinkPlateOrderSharedTimelineFragment = (
  { readonly mealMenu: MealMenuWithTimesFragment, readonly restaurantMenuLink: (
    Pick<RestaurantMenuLink, 'id'>
    & { readonly restaurant: Pick<Restaurant, 'id' | 'name'> }
  ) }
  & RestaurantMenuLinkPlateOrderFragment
);

export type MenuOrderSharedTimelineFragment = (
  { readonly __typename: 'MenuOrder' }
  & Pick<MenuOrder, 'id'>
  & { readonly mealMenu: Pick<StandaloneMealMenu, 'id'> }
);

export type MealMenuLogSharedTimelineFragment = (
  { readonly __typename: 'MealMenuLog' }
  & Pick<MealMenuLog, 'id'>
  & { readonly mealMenu: Pick<StandaloneMealMenu, 'id'> }
);

export type MealPlanDateSharedTimelineFragment = (
  { readonly __typename: 'MealPlanDate' }
  & Pick<MealPlanDate, 'date' | 'timezone'>
  & { readonly mealPlan: MealPlanSharedTimelineFragment }
);

export type MealPlanSharedTimelineFragment = (
  Pick<MealPlan, 'id'>
  & { readonly mealTemplates: ReadonlyArray<MealTemplateSharedTimelineExchangeMealTemplateFragment | MealTemplateSharedTimelineMacroMealTemplateFragment>, readonly activityTemplates: ReadonlyArray<ActivityTemplateSharedTimelineFragment> }
);

type MealTemplateSharedTimelineExchangeMealTemplateFragment = (
  { readonly __typename: 'ExchangeMealTemplate' }
  & Pick<ExchangeMealTemplate, 'id'>
  & { readonly meal: Pick<Meal, 'id' | 'name' | 'start' | 'end' | 'type'> }
);

type MealTemplateSharedTimelineMacroMealTemplateFragment = (
  { readonly __typename: 'MacroMealTemplate' }
  & Pick<MacroMealTemplate, 'id'>
  & { readonly meal: Pick<Meal, 'id' | 'name' | 'start' | 'end' | 'type'> }
);

export type MealTemplateSharedTimelineFragment = MealTemplateSharedTimelineExchangeMealTemplateFragment | MealTemplateSharedTimelineMacroMealTemplateFragment;

export type ActivityTemplateSharedTimelineFragment = (
  { readonly __typename: 'ActivityTemplate' }
  & Pick<ActivityTemplate, 'id'>
  & { readonly activity: Pick<Activity, 'id' | 'name' | 'start' | 'end' | 'type'> }
);

export type TimelineMealSharedTimelineFragment = (
  { readonly __typename: 'TimelineMeal' }
  & Pick<TimelineMeal, 'id' | 'name' | 'type' | 'timezone' | 'start' | 'durationInMinutes'>
  & { readonly mealTemplate: Maybe<Pick<ExchangeMealTemplate, 'id'> | Pick<MacroMealTemplate, 'id'>>, readonly mealMenus: ReadonlyArray<MealMenuSharedTimelineFragment>, readonly servingAmounts: ReadonlyArray<FullServingAmountFragment>, readonly restaurantMenuLogs: ReadonlyArray<RestaurantMenuLogSharedTimelineFragment>, readonly restaurantMenuLinkPlateOrders: ReadonlyArray<RestaurantMenuLinkPlateOrderSharedTimelineFragment>, readonly restaurantMenuLinkOrders: ReadonlyArray<RestaurantMenuLinkOrderSharedTimelineFragment>, readonly menuOrders: ReadonlyArray<MenuOrderSharedTimelineFragment>, readonly mealMenuLogs: ReadonlyArray<MealMenuLogSharedTimelineFragment> }
);

export type FoodLogActivitySharedTimelineFragment = (
  { readonly __typename: 'FoodLogActivity' }
  & Pick<FoodLogActivity, 'id' | 'name' | 'startTime' | 'endTime' | 'timezone'>
  & { activityType: FoodLogActivity['type'] }
  & { readonly activityTemplate: Maybe<Pick<ActivityTemplate, 'id'>> }
);

export type RestaurantMenuLogSharedTimelineFragment = (
  { readonly __typename: 'RestaurantMenuLog' }
  & Pick<RestaurantMenuLog, 'id' | 'dateTime' | 'timezone'>
  & { mealType: RestaurantMenuLog['type'] }
  & { readonly items: ReadonlyArray<(
    Pick<RestaurantMenuLogItem, 'id'>
    & MenuSelectionItemFormRestaurantMenuLogItemFragment
  )> }
);

export type UnitsQueryVariables = Exact<{ [key: string]: never; }>;


export type UnitsQuery = { readonly units: ReadonlyArray<UnitFragment> };

export type UnitFragment = Pick<Unit, 'id' | 'name'>;

export type UnitConversionsFromQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnitConversionsFromQuery = { readonly unit: UnitWithConversionsFragment };

export type UnitsWithConversionsQueryVariables = Exact<{ [key: string]: never; }>;


export type UnitsWithConversionsQuery = { readonly units: ReadonlyArray<UnitWithConversionsFragment> };

export type UnitWithConversionsFragment = (
  Pick<Unit, 'id' | 'name'>
  & { readonly conversionsFrom: ReadonlyArray<(
    Pick<UnitConversion, 'id' | 'fromQuantity' | 'toQuantity'>
    & { readonly toUnit: (
      Pick<Unit, 'isProductionOnly'>
      & UnitFragment
    ) }
  )> }
);

export type GetUserLocaleQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserLocaleQuery = { readonly myUser: (
    Pick<User, 'id'>
    & { readonly locale: Pick<Locale, 'id' | 'code'> }
  ) };

export type ActivityFragment = (
  { readonly __typename: 'Activity' }
  & Pick<Activity, 'id' | 'name' | 'start' | 'end' | 'type'>
);

export type ActivityTemplateFragment = (
  { readonly __typename: 'ActivityTemplate' }
  & Pick<ActivityTemplate, 'id'>
  & { readonly activity: ActivityFragment }
);

export type AnthropometryEntryFragment = (
  { readonly __typename: 'AnthropometryEntry' }
  & Pick<AnthropometryEntry, 'id' | 'height' | 'weight' | 'leanBodyMass' | 'bodyFatMass' | 'percentBodyFat' | 'datetime' | 'type' | 'sex' | 'comment' | 'boneMineralDensity' | 'boneMineralDensityZScore' | 'boneMineralDensityTScore' | 'boneMineralContent' | 'boneArea' | 'boneMass' | 'dryLeanMass' | 'skeletalMuscleMass' | 'trunkFat' | 'visceralFatArea' | 'heightInCm' | 'weightInKg' | 'leanBodyMassInKg' | 'bodyFatMassInKg' | 'skeletalMuscleMassInKg' | 'trunkFatInKg' | 'dryLeanMassInKg'>
);

export type AthleteFragment = (
  { readonly __typename: 'Athlete' }
  & Pick<Athlete, 'id' | 'firstName' | 'lastName' | 'sex' | 'birthDate' | 'phoneNumber' | 'email' | 'headshotUrl' | 'secaUid' | 'inbodyUid' | 'isArchived' | 'activationLinkSent' | 'jerseyNumber' | 'isProfileNotemealOnly'>
);

export type AthleteWithMealPlansFragment = (
  { readonly mealPlans: ReadonlyArray<MealPlanSearchFormFragment> }
  & AthleteFragment
);

export type AthleteWithGoalAndAnthroFragment = (
  { readonly position: Maybe<PositionFragment>, readonly mostRecentAnthropometryEntry: Maybe<AnthropometryEntryFragment>, readonly currentGoal: Maybe<FullGoalFragment> }
  & AthleteFragment
);

export type AthleteWithFoodPrefsFragment = (
  { readonly likedFoods: ReadonlyArray<FoodBrandedFoodFragment | FoodGenericFoodFragment>, readonly dislikedFoods: ReadonlyArray<FoodBrandedFoodFragment | FoodGenericFoodFragment>, readonly likedFoodGroups: ReadonlyArray<FullFoodGroupFragment>, readonly dislikedFoodGroups: ReadonlyArray<FullFoodGroupFragment> }
  & AthleteFragment
);

export type BrandFragment = (
  { readonly __typename: 'Brand' }
  & Pick<Brand, 'id' | 'name' | 'usdaManufacturerName' | 'foodCount'>
);

export type CalorieBudgetFragment = (
  { readonly __typename: 'CalorieBudget' }
  & Pick<CalorieBudget, 'id' | 'rmrMethod' | 'rmrCalories' | 'activityFactor' | 'kcalOffset'>
);

export type FullCalorieBudgetFragment = (
  { readonly goalSnapshot: FullGoalFragment }
  & CalorieBudgetFragment
);

export type ExchangeFragment = (
  { readonly __typename: 'Exchange' }
  & Pick<Exchange, 'id' | 'name' | 'shortName' | 'type' | 'cho' | 'pro' | 'fat' | 'kcal'>
);

export type FullExchangeFragment = (
  { readonly exchangeServingList: FullExchangeServingListFragment }
  & ExchangeFragment
);

export type ExchangeAmountFragment = (
  { readonly __typename: 'ExchangeAmount' }
  & Pick<ExchangeAmount, 'id' | 'amount' | 'pickListServingIds'>
  & { readonly exchange: ExchangeFragment }
);

export type EditExchangeAmountFragment = (
  Pick<ExchangeAmount, 'amountInput'>
  & ExchangeAmountFragment
);

export type ExchangeMealTemplateFragment = (
  { readonly __typename: 'ExchangeMealTemplate' }
  & MealTemplateExchangeMealTemplateFragment
);

export type FullExchangeMealTemplateFragment = (
  { readonly exchangeTargets: ReadonlyArray<ExchangeAmountFragment> }
  & FullMealTemplateExchangeMealTemplateFragment
  & ExchangeMealTemplateFragment
);

export type EditExchangeMealTemplateFragment = (
  { readonly exchangeTargets: ReadonlyArray<EditExchangeAmountFragment> }
  & EditMealTemplateExchangeMealTemplateFragment
);

export type EditFullExchangeMealTemplateFragment = (
  EditFullMealTemplateExchangeMealTemplateFragment
  & ExchangeMealTemplateFragment
  & EditExchangeMealTemplateFragment
);

export type ExchangeRatioFragment = (
  { readonly __typename: 'ExchangeRatio' }
  & Pick<ExchangeRatio, 'ratio'>
  & { readonly exchange: ExchangeFragment }
);

export type FoodOrRecipeExchangeRatiosFragment = (
  { readonly __typename: 'FoodOrRecipeExchangeRatios' }
  & Pick<FoodOrRecipeExchangeRatios, 'id' | 'foodOrRecipeId'>
  & { readonly exchangeRatios: ReadonlyArray<ExchangeRatioFragment> }
);

export type ExchangeServingListFragment = (
  { readonly __typename: 'ExchangeServingList' }
  & Pick<ExchangeServingList, 'id' | 'name'>
);

export type FullExchangeServingListFragment = (
  { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  & ExchangeServingListFragment
);

export type ExchangeSetFragment = (
  { readonly __typename: 'ExchangeSet' }
  & Pick<ExchangeSet, 'id' | 'name'>
  & { readonly createdBy: Maybe<Pick<User, 'id' | 'firstName' | 'lastName'>> }
);

export type ExchangeSetWithExchangesFragment = (
  { readonly exchanges: ReadonlyArray<ExchangeFragment> }
  & ExchangeSetFragment
);

export type FullExchangeSetWithoutExchangeRatiosFragment = (
  { readonly exchanges: ReadonlyArray<FullExchangeFragment> }
  & ExchangeSetFragment
);

export type FullExchangeSetFragment = (
  { readonly foodOrRecipeExchangeRatios: ReadonlyArray<(
    { readonly __typename: 'FoodOrRecipeExchangeRatios' }
    & Pick<FoodOrRecipeExchangeRatios, 'id' | 'foodOrRecipeId'>
    & { readonly exchangeRatios: ReadonlyArray<(
      { readonly __typename: 'ExchangeRatio' }
      & Pick<ExchangeRatio, 'ratio'>
      & { readonly exchange: ExchangeFragment }
    )> }
  )> }
  & FullExchangeSetWithoutExchangeRatiosFragment
);

type FoodBrandedFoodFragment = (
  { readonly __typename: 'BrandedFood' }
  & Pick<BrandedFood, 'id' | 'name' | 'exchangeTypes' | 'nixItemId' | 'nixTagId' | 'usdaFdcDescription' | 'usdaFdcFoodCategoryId' | 'usdaFdcDataType' | 'usdaFdcId' | 'isRecipeIngredientOnly' | 'choPer100g' | 'proPer100g' | 'fatPer100g' | 'kcalPer100g' | 'thumbnailUrl' | 'locales'>
);

type FoodGenericFoodFragment = (
  { readonly __typename: 'GenericFood' }
  & Pick<GenericFood, 'id' | 'name' | 'exchangeTypes' | 'nixItemId' | 'nixTagId' | 'usdaFdcDescription' | 'usdaFdcFoodCategoryId' | 'usdaFdcDataType' | 'usdaFdcId' | 'isRecipeIngredientOnly' | 'choPer100g' | 'proPer100g' | 'fatPer100g' | 'kcalPer100g' | 'thumbnailUrl' | 'locales'>
);

export type FoodFragment = FoodBrandedFoodFragment | FoodGenericFoodFragment;

type FoodLimitedAccessBrandedFoodFragment = (
  { readonly __typename: 'BrandedFood' }
  & Pick<BrandedFood, 'id' | 'name' | 'usdaFdcDescription' | 'exchangeTypes' | 'thumbnailUrl' | 'hasFullAccess' | 'nixItemId' | 'nixTagId'>
  & { readonly brand: Maybe<Pick<Brand, 'id' | 'name'>>, readonly org: Maybe<Pick<Org, 'id' | 'name'>> }
);

type FoodLimitedAccessGenericFoodFragment = (
  { readonly __typename: 'GenericFood' }
  & Pick<GenericFood, 'id' | 'name' | 'usdaFdcDescription' | 'exchangeTypes' | 'thumbnailUrl' | 'hasFullAccess' | 'nixItemId' | 'nixTagId'>
  & { readonly org: Maybe<Pick<Org, 'id' | 'name'>> }
);

export type FoodLimitedAccessFragment = FoodLimitedAccessBrandedFoodFragment | FoodLimitedAccessGenericFoodFragment;

type FoodPreviewBrandedFoodFragment = Pick<BrandedFood, 'id' | 'name'>;

type FoodPreviewGenericFoodFragment = Pick<GenericFood, 'id' | 'name'>;

export type FoodPreviewFragment = FoodPreviewBrandedFoodFragment | FoodPreviewGenericFoodFragment;

export type BrandedFoodFragment = (
  { readonly __typename: 'BrandedFood' }
  & Pick<BrandedFood, 'id' | 'name' | 'exchangeTypes' | 'usdaFdcDescription' | 'usdaFdcFoodCategoryId' | 'usdaFdcDataType' | 'usdaFdcId' | 'isRecipeIngredientOnly' | 'nixItemId' | 'nixTagId' | 'choPer100g' | 'proPer100g' | 'fatPer100g' | 'kcalPer100g' | 'thumbnailUrl'>
  & { readonly groceryListCategory: Maybe<Pick<GroceryListCategory, 'id' | 'name'>> }
);

export type GenericFoodFragment = (
  { readonly __typename: 'GenericFood' }
  & Pick<GenericFood, 'id' | 'name' | 'exchangeTypes' | 'nixItemId' | 'nixTagId' | 'usdaFdcDescription' | 'usdaFdcFoodCategoryId' | 'usdaFdcDataType' | 'usdaFdcId' | 'isRecipeIngredientOnly' | 'choPer100g' | 'proPer100g' | 'fatPer100g' | 'kcalPer100g' | 'thumbnailUrl'>
  & { readonly groceryListCategory: Maybe<Pick<GroceryListCategory, 'id' | 'name'>>, readonly owner: Maybe<Pick<User, 'id' | 'firstName' | 'lastName'>>, readonly orgGroup: Maybe<Pick<OrgGroup, 'id' | 'name'>> }
);

type FoodWithDefaultServingBrandedFoodFragment = (
  { readonly __typename: 'BrandedFood' }
  & Pick<BrandedFood, 'hasFullAccess'>
  & { readonly brand: Maybe<BrandFragment>, readonly defaultServing: FullServingFragment, readonly org: Maybe<Pick<Org, 'id' | 'name'>> }
  & FoodBrandedFoodFragment
);

type FoodWithDefaultServingGenericFoodFragment = (
  { readonly __typename: 'GenericFood' }
  & Pick<GenericFood, 'hasFullAccess'>
  & { readonly orgGroup: Maybe<Pick<OrgGroup, 'id' | 'name'>>, readonly defaultServing: FullServingFragment, readonly org: Maybe<Pick<Org, 'id' | 'name'>> }
  & FoodGenericFoodFragment
);

export type FoodWithDefaultServingFragment = FoodWithDefaultServingBrandedFoodFragment | FoodWithDefaultServingGenericFoodFragment;

type FoodWithServingsBrandedFoodFragment = (
  Pick<BrandedFood, 'showGramServing' | 'showOunceServing'>
  & { readonly servings: ReadonlyArray<ServingWithFoodOrRecipeFragment> }
  & FoodBrandedFoodFragment
);

type FoodWithServingsGenericFoodFragment = (
  Pick<GenericFood, 'showGramServing' | 'showOunceServing'>
  & { readonly servings: ReadonlyArray<ServingWithFoodOrRecipeFragment> }
  & FoodGenericFoodFragment
);

export type FoodWithServingsFragment = FoodWithServingsBrandedFoodFragment | FoodWithServingsGenericFoodFragment;

export type FullFoodGroupFragment = (
  { readonly __typename: 'FoodGroup' }
  & Pick<FoodGroup, 'id' | 'name' | 'source' | 'foodsCount'>
);

export type FullFoodGroupWithFoodsFragment = (
  { readonly __typename: 'FoodGroup' }
  & Pick<FoodGroup, 'id' | 'name' | 'source' | 'foodsCount'>
  & { readonly foods: ReadonlyArray<FoodLimitedAccessBrandedFoodFragment | FoodLimitedAccessGenericFoodFragment> }
);

export type FoodLogNotificationSettingsFragment = Pick<FoodLogNotificationSettings, 'edits' | 'imageUploads' | 'messages'>;

export type GoalFragment = (
  { readonly __typename: 'Goal' }
  & Pick<Goal, 'id' | 'kcalOffset' | 'start' | 'end'>
);

export type FullGoalFragment = (
  { readonly type: GoalTypeFragment }
  & GoalFragment
);

export type GoalTypeFragment = (
  { readonly __typename: 'GoalType' }
  & Pick<GoalType, 'id' | 'name' | 'defaultKcalOffset'>
);

export type ImageFragment = Pick<Image, 'id' | 'position' | 'url'>;

export type MacroMathFragment = Pick<MacroMath, 'gPerKg' | 'leftOverRatio'>;

export type MacroMealTemplateFragment = (
  { readonly __typename: 'MacroMealTemplate' }
  & Pick<MacroMealTemplate, 'cho' | 'pro' | 'fat' | 'kcal'>
  & MealTemplateMacroMealTemplateFragment
);

export type FullMacroMealTemplateFragment = (
  FullMealTemplateMacroMealTemplateFragment
  & MacroMealTemplateFragment
);

export type EditMacroMealTemplateFragment = (
  Pick<MacroMealTemplate, 'choInput' | 'proInput' | 'fatInput'>
  & EditMealTemplateMacroMealTemplateFragment
);

export type EditFullMacroMealTemplateFragment = (
  EditFullMealTemplateMacroMealTemplateFragment
  & MacroMealTemplateFragment
  & EditMacroMealTemplateFragment
);

export type MacroProtocolFragment = (
  { readonly __typename: 'MacroProtocol' }
  & Pick<MacroProtocol, 'id' | 'weightTarget' | 'weightTargetInKg'>
  & { readonly cho: MacroMathFragment, readonly pro: MacroMathFragment, readonly fat: MacroMathFragment }
);

export type FullMacroProtocolFragment = (
  { readonly anthropometrySnapshot: AnthropometryEntryFragment, readonly calorieBudget: Maybe<FullCalorieBudgetFragment> }
  & MacroProtocolFragment
);

export type MealFragment = (
  { readonly __typename: 'Meal' }
  & Pick<Meal, 'id' | 'name' | 'start' | 'end' | 'type'>
);

export type MealOptionFragment = (
  { readonly __typename: 'MealOption' }
  & Pick<MealOption, 'id' | 'note' | 'position' | 'name'>
);

export type EditMealOptionFragment = Pick<MealOption, 'id' | 'isAutosaving'>;

export type FullMealOptionFragment = (
  { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  & MealOptionFragment
);

export type EditFullMealOptionFragment = (
  FullMealOptionFragment
  & EditMealOptionFragment
);

export type MealOptionPendingFragment = (
  { readonly __typename: 'MealOptionPending' }
  & Pick<MealOptionPending, 'id' | 'note' | 'name'>
);

export type EditMealOptionPendingFragment = Pick<MealOptionPending, 'id' | 'isAutosaving'>;

export type FullMealOptionPendingFragment = (
  { readonly servingAmounts: ReadonlyArray<FullServingAmountFragment> }
  & MealOptionPendingFragment
);

export type EditFullMealOptionPendingFragment = (
  FullMealOptionPendingFragment
  & EditMealOptionPendingFragment
);

export type MealPlanFragment = (
  { readonly __typename: 'MealPlan' }
  & Pick<MealPlan, 'id' | 'name' | 'isShared' | 'isAutoSuggestionsEnabled' | 'description' | 'usesDateRange' | 'dates' | 'startDate' | 'endDate' | 'type' | 'createdAt' | 'updatedAt'>
  & { readonly exchangeSet: Maybe<Pick<ExchangeSet, 'id'>> }
);

export type EditMealPlanFragment = (
  Pick<MealPlan, 'id' | 'isAutosaving'>
  & { readonly exchangeTargets: Maybe<ReadonlyArray<EditExchangeAmountFragment>> }
);

export type MealPlanSearchFormFragment = (
  { readonly macroProtocol: FullMacroProtocolFragment }
  & MealPlanFragment
);

export type BasicMealPlanFragment = (
  { readonly exchangeSet: Maybe<ExchangeSetWithExchangesFragment> }
  & MealPlanFragment
);

export type PreviewMealPlanFragment = (
  { readonly macroProtocol: FullMacroProtocolFragment, readonly schedule: Maybe<ScheduleFragment>, readonly avoidedFoodGroups: ReadonlyArray<FullFoodGroupWithFoodsFragment>, readonly promotedFoodGroups: ReadonlyArray<FullFoodGroupWithFoodsFragment>, readonly avoidedFoods: ReadonlyArray<FoodLimitedAccessBrandedFoodFragment | FoodLimitedAccessGenericFoodFragment>, readonly promotedFoods: ReadonlyArray<FoodLimitedAccessBrandedFoodFragment | FoodLimitedAccessGenericFoodFragment>, readonly mostRecentEvent: Maybe<EventFragment>, readonly exchangeTargets: Maybe<ReadonlyArray<EditExchangeAmountFragment>> }
  & BasicMealPlanFragment
);

export type CopyableMealPlanFragment = (
  { readonly macroProtocol: (
    { readonly calorieBudget: Maybe<CalorieBudgetFragment> }
    & MacroProtocolFragment
  ) }
  & BasicMealPlanFragment
);

export type EditFullMealPlanFragment = (
  { readonly athlete: (
    { readonly macroDisplaySettings: MacroMealPlanDisplaySettingsFragment, readonly exchangeDisplaySettings: ExchangeMealPlanDisplaySettingsFragment }
    & AthleteWithFoodPrefsFragment
  ), readonly exchangeSet: Maybe<FullExchangeSetFragment>, readonly activityTemplates: ReadonlyArray<ActivityTemplateFragment>, readonly events: ReadonlyArray<EventFragment>, readonly mealTemplates: ReadonlyArray<EditFullExchangeMealTemplateFragment | EditFullMacroMealTemplateFragment> }
  & PreviewMealPlanFragment
  & EditMealPlanFragment
);

export type FullMealPlanFragment = (
  { readonly athlete: (
    { readonly macroDisplaySettings: MacroMealPlanDisplaySettingsFragment, readonly exchangeDisplaySettings: ExchangeMealPlanDisplaySettingsFragment }
    & AthleteWithFoodPrefsFragment
  ), readonly exchangeSet: Maybe<FullExchangeSetFragment>, readonly activityTemplates: ReadonlyArray<ActivityTemplateFragment>, readonly events: ReadonlyArray<EventFragment>, readonly mealTemplates: ReadonlyArray<FullExchangeMealTemplateFragment | FullMacroMealTemplateFragment> }
  & PreviewMealPlanFragment
);

export type EventFragment = (
  { readonly __typename: 'Event' }
  & Pick<Event, 'type' | 'datetime'>
);

type MealTemplateExchangeMealTemplateFragment = (
  { readonly __typename: 'ExchangeMealTemplate' }
  & Pick<ExchangeMealTemplate, 'id'>
  & { readonly meal: MealFragment }
);

type MealTemplateMacroMealTemplateFragment = (
  { readonly __typename: 'MacroMealTemplate' }
  & Pick<MacroMealTemplate, 'id'>
  & { readonly meal: MealFragment }
);

export type MealTemplateFragment = MealTemplateExchangeMealTemplateFragment | MealTemplateMacroMealTemplateFragment;

type EditMealTemplateExchangeMealTemplateFragment = Pick<ExchangeMealTemplate, 'id' | 'isAutosaving' | 'selectedMealOptionId' | 'newMealOptionIds'>;

type EditMealTemplateMacroMealTemplateFragment = Pick<MacroMealTemplate, 'id' | 'isAutosaving' | 'selectedMealOptionId' | 'newMealOptionIds'>;

export type EditMealTemplateFragment = EditMealTemplateExchangeMealTemplateFragment | EditMealTemplateMacroMealTemplateFragment;

type FullMealTemplateExchangeMealTemplateFragment = (
  { readonly mealOptions: ReadonlyArray<FullMealOptionFragment>, readonly pendingMealOptions: ReadonlyArray<FullMealOptionPendingFragment> }
  & MealTemplateExchangeMealTemplateFragment
);

type FullMealTemplateMacroMealTemplateFragment = (
  { readonly mealOptions: ReadonlyArray<FullMealOptionFragment>, readonly pendingMealOptions: ReadonlyArray<FullMealOptionPendingFragment> }
  & MealTemplateMacroMealTemplateFragment
);

export type FullMealTemplateFragment = FullMealTemplateExchangeMealTemplateFragment | FullMealTemplateMacroMealTemplateFragment;

type ExportMealTemplateExchangeMealTemplateFragment = (
  { readonly mealOptions: ReadonlyArray<ViewMealOptionFragment> }
  & MealTemplateExchangeMealTemplateFragment
);

type ExportMealTemplateMacroMealTemplateFragment = (
  { readonly mealOptions: ReadonlyArray<ViewMealOptionFragment> }
  & MealTemplateMacroMealTemplateFragment
);

export type ExportMealTemplateFragment = ExportMealTemplateExchangeMealTemplateFragment | ExportMealTemplateMacroMealTemplateFragment;

type EditFullMealTemplateExchangeMealTemplateFragment = (
  { readonly mealOptions: ReadonlyArray<EditFullMealOptionFragment>, readonly pendingMealOptions: ReadonlyArray<EditFullMealOptionPendingFragment> }
  & MealTemplateExchangeMealTemplateFragment
  & EditMealTemplateExchangeMealTemplateFragment
);

type EditFullMealTemplateMacroMealTemplateFragment = (
  { readonly mealOptions: ReadonlyArray<EditFullMealOptionFragment>, readonly pendingMealOptions: ReadonlyArray<EditFullMealOptionPendingFragment> }
  & MealTemplateMacroMealTemplateFragment
  & EditMealTemplateMacroMealTemplateFragment
);

export type EditFullMealTemplateFragment = EditFullMealTemplateExchangeMealTemplateFragment | EditFullMealTemplateMacroMealTemplateFragment;

export type NoteFragment = (
  { readonly __typename: 'Note' }
  & Pick<Note, 'id' | 'datetime' | 'content'>
);

export type NoteWithTypeFragment = (
  { readonly type: Maybe<NoteTypeFragment> }
  & NoteFragment
);

export type FullNoteFragment = (
  { readonly athlete: AthleteFragment }
  & NoteWithTypeFragment
);

export type NoteTypeFragment = (
  { readonly __typename: 'NoteType' }
  & Pick<NoteType, 'id' | 'name'>
);

export type NutrientDietaryReferenceIntakeFragment = Pick<NutrientDietaryReferenceIntake, 'id' | 'type'>;

export type PositionFragment = (
  { readonly __typename: 'Position' }
  & Pick<Position, 'id' | 'name'>
);

export type RecipeFragment = (
  { readonly __typename: 'Recipe' }
  & Pick<Recipe, 'id' | 'name' | 'steps' | 'note' | 'exchangeTypes' | 'cho' | 'pro' | 'fat' | 'prepTimeInMinutes' | 'cookTimeInMinutes' | 'isCurrent' | 'isShared' | 'mealTypes'>
  & { readonly images: ReadonlyArray<ImageFragment>, readonly createdBy: Pick<User, 'id' | 'firstName' | 'lastName'>, readonly score: Maybe<ScoreAssignmentFragment>, readonly foodCategory: Maybe<RecipeFoodCategoryFragment> }
);

export type RecipeLimitedAccessFragment = (
  { readonly __typename: 'Recipe' }
  & Pick<Recipe, 'id' | 'name' | 'exchangeTypes' | 'isCurrent' | 'hasFullAccess'>
  & { readonly images: ReadonlyArray<ImageFragment>, readonly descendantFoods: ReadonlyArray<Pick<BrandedFood, 'id' | 'name' | 'nixItemId' | 'nixTagId'> | Pick<GenericFood, 'id' | 'name' | 'nixItemId' | 'nixTagId'>>, readonly createdBy: Pick<User, 'id' | 'firstName' | 'lastName'>, readonly org: Maybe<Pick<Org, 'id' | 'name'>>, readonly score: Maybe<ScoreAssignmentFragment>, readonly ingredients: ReadonlyArray<RecipeIngredientServingAmountFragment> }
);

export type RecipeWithIngredientsFragment = (
  { readonly ingredients: ReadonlyArray<ServingAmountWithFoodOrRecipeFragment> }
  & RecipeFragment
);

export type RecipeWithServingsFragment = (
  Pick<Recipe, 'hasFullAccess'>
  & { readonly descendantRecipes: ReadonlyArray<RecipeWithIngredientsFragment>, readonly servings: ReadonlyArray<ServingWithFoodOrRecipeFragment> }
  & RecipeWithIngredientsFragment
);

export type RecipeWithFullIngredientsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RecipeWithFullIngredientsQuery = { readonly recipe: (
    Pick<Recipe, 'id'>
    & { readonly ingredients: ReadonlyArray<FullServingAmountFragment> }
  ) };

export type ScheduleFragment = (
  { readonly __typename: 'Schedule' }
  & Pick<Schedule, 'id' | 'name' | 'activityFactor'>
);

export type FullScheduleFragment = (
  { readonly meals: ReadonlyArray<MealFragment>, readonly activities: ReadonlyArray<ActivityFragment>, readonly mealPlans: ReadonlyArray<MealPlanFragment> }
  & ScheduleFragment
);

export type ServingFragment = (
  { readonly __typename: 'Serving' }
  & Pick<Serving, 'id' | 'units' | 'defaultAmount' | 'isDefault' | 'weight' | 'usdaWeightSeq' | 'perRecipeYield' | 'isRecipeServingOnly' | 'hasOwnNutrients'>
);

export type ServingWithMacrosFragment = (
  { readonly macros: Pick<Macros, 'cho' | 'pro' | 'fat' | 'kcal'> }
  & ServingFragment
);

export type ServingWithFoodOrRecipeFragment = (
  Pick<Serving, 'customUnits' | 'unitPrefix' | 'unitSuffix'>
  & { readonly macros: Pick<Macros, 'cho' | 'pro' | 'fat' | 'kcal'>, readonly unit: Maybe<Pick<Unit, 'id' | 'name'>>, readonly foodOrRecipe: (
    Pick<BrandedFood, 'hasFullAccess'>
    & { readonly org: Maybe<Pick<Org, 'id' | 'name'>>, readonly brand: Maybe<Pick<Brand, 'id' | 'name'>> }
    & FoodBrandedFoodFragment
  ) | (
    Pick<GenericFood, 'hasFullAccess'>
    & { readonly org: Maybe<Pick<Org, 'id' | 'name'>> }
    & FoodGenericFoodFragment
  ) | RecipeLimitedAccessFragment }
  & ServingFragment
);

export type RecipeIngredientServingFragment = (
  { readonly __typename: 'Serving' }
  & Pick<Serving, 'id' | 'defaultAmount' | 'isDefault' | 'weight' | 'perRecipeYield' | 'hasOwnNutrients' | 'units'>
  & { readonly macros: Pick<Macros, 'cho' | 'pro' | 'fat' | 'kcal'>, readonly unit: Maybe<Pick<Unit, 'id' | 'name'>>, readonly foodOrRecipe: FoodLimitedAccessBrandedFoodFragment | FoodLimitedAccessGenericFoodFragment | (
    { readonly __typename: 'Recipe' }
    & Pick<Recipe, 'id' | 'name' | 'exchangeTypes' | 'isCurrent' | 'hasFullAccess'>
    & { readonly images: ReadonlyArray<ImageFragment>, readonly descendantFoods: ReadonlyArray<Pick<BrandedFood, 'id'> | Pick<GenericFood, 'id'>>, readonly createdBy: Pick<User, 'id' | 'firstName' | 'lastName'>, readonly org: Maybe<Pick<Org, 'id' | 'name'>>, readonly score: Maybe<ScoreAssignmentFragment> }
  ) }
);

export type FullServingFragment = (
  { readonly __typename: 'Serving' }
  & Pick<Serving, 'id' | 'units' | 'defaultAmount' | 'isDefault' | 'weight' | 'perRecipeYield' | 'hasOwnNutrients'>
  & { readonly macros: Pick<Macros, 'cho' | 'pro' | 'fat' | 'kcal'>, readonly unit: Maybe<Pick<Unit, 'id' | 'name'>>, readonly foodOrRecipe: FoodLimitedAccessBrandedFoodFragment | FoodLimitedAccessGenericFoodFragment | RecipeLimitedAccessFragment }
);

export type ExcessiveServingFragment = (
  Pick<Serving, 'customUnits' | 'unitPrefix' | 'unitSuffix'>
  & { readonly unit: Maybe<Pick<Unit, 'id' | 'name'>>, readonly macros: Pick<Macros, 'cho' | 'pro' | 'fat' | 'kcal'>, readonly foodOrRecipe: (
    { readonly brand: Maybe<Pick<Brand, 'id' | 'name'>> }
    & FoodBrandedFoodFragment
  ) | FoodGenericFoodFragment | (
    { readonly descendantFoods: ReadonlyArray<FoodBrandedFoodFragment | FoodGenericFoodFragment>, readonly descendantRecipes: ReadonlyArray<RecipeWithIngredientsFragment> }
    & RecipeWithIngredientsFragment
  ) }
  & ServingFragment
);

export type ServingAmountWithFoodOrRecipeFragment = (
  { readonly __typename: 'ServingAmount' }
  & Pick<ServingAmount, 'id' | 'amount' | 'position'>
  & { readonly serving: ServingWithFoodOrRecipeFragment }
);

export type FullServingAmountFragment = (
  { readonly __typename: 'ServingAmount' }
  & Pick<ServingAmount, 'id' | 'amount' | 'position'>
  & { readonly serving: FullServingFragment }
);

export type ExcessiveServingAmountFragment = (
  { readonly __typename: 'ServingAmount' }
  & Pick<ServingAmount, 'id' | 'amount' | 'position'>
  & { readonly serving: ExcessiveServingFragment }
);

export type SportFragment = (
  { readonly __typename: 'Sport' }
  & Pick<Sport, 'id' | 'name'>
);

export type FullSportFragment = (
  { readonly positions: ReadonlyArray<PositionFragment> }
  & SportFragment
);

export type TeamFragment = (
  { readonly __typename: 'Team' }
  & Pick<Team, 'id' | 'gender' | 'name'>
);

export type TeamWithSportFragment = (
  { readonly sport: Maybe<FullSportFragment> }
  & TeamFragment
);

export type UsdaFoodFragment = (
  { readonly __typename: 'USDAFood' }
  & Pick<UsdaFood, 'id' | 'allHighlightFields' | 'brandOwner' | 'dataType' | 'description' | 'gtinUpc' | 'ingredients' | 'publishedDate' | 'score'>
);

export type UsdaFoodDetailFragment = (
  { readonly __typename: 'USDAFoodDetail' }
  & Pick<UsdaFoodDetail, 'id' | 'usdaFdcFoodCategoryId' | 'usdaFdcDataType'>
  & { readonly cho: UsdaFoodNutrientFragment, readonly pro: UsdaFoodNutrientFragment, readonly fat: UsdaFoodNutrientFragment, readonly foodNutrients: ReadonlyArray<UsdaFoodNutrientFragment>, readonly servings: ReadonlyArray<UsdaFoodServingFragment> }
);

export type UsdaFoodNutrientFragment = (
  { readonly __typename: 'USDAFoodNutrient' }
  & Pick<UsdaFoodNutrient, 'id' | 'amount' | 'dataPoints' | 'unitName' | 'max' | 'min' | 'median' | 'name' | 'footnote'>
);

export type UsdaFoodServingFragment = (
  { readonly __typename: 'USDAFoodServing' }
  & Pick<UsdaFoodServing, 'idx' | 'cho' | 'pro' | 'fat' | 'weight' | 'defaultAmount' | 'units' | 'isDefault' | 'usdaWeightSeq'>
);

export type AddAthleteDislikedFoodMutationVariables = Exact<{
  input: AthleteFoodPreferenceInput;
}>;


export type AddAthleteDislikedFoodMutation = { readonly addAthleteDislikedFood: { readonly athlete: (
      Pick<Athlete, 'id'>
      & { readonly dislikedFoods: ReadonlyArray<FoodBrandedFoodFragment | FoodGenericFoodFragment> }
    ) } };

export type AddAthleteDislikedFoodGroupMutationVariables = Exact<{
  input: AthleteFoodGroupPreferenceInput;
}>;


export type AddAthleteDislikedFoodGroupMutation = { readonly addAthleteDislikedFoodGroup: { readonly athlete: (
      Pick<Athlete, 'id'>
      & { readonly dislikedFoodGroups: ReadonlyArray<FullFoodGroupFragment> }
    ) } };

export type AddAthleteEmailMutationVariables = Exact<{
  input: AddAthleteEmailInput;
}>;


export type AddAthleteEmailMutation = { readonly addAthleteEmail: { readonly athlete: AthleteFragment } };

export type AddAthleteLikedFoodMutationVariables = Exact<{
  input: AthleteFoodPreferenceInput;
}>;


export type AddAthleteLikedFoodMutation = { readonly addAthleteLikedFood: { readonly athlete: (
      Pick<Athlete, 'id'>
      & { readonly likedFoods: ReadonlyArray<FoodBrandedFoodFragment | FoodGenericFoodFragment> }
    ) } };

export type AddAthleteLikedFoodGroupMutationVariables = Exact<{
  input: AthleteFoodGroupPreferenceInput;
}>;


export type AddAthleteLikedFoodGroupMutation = { readonly addAthleteLikedFoodGroup: { readonly athlete: (
      Pick<Athlete, 'id'>
      & { readonly likedFoodGroups: ReadonlyArray<FullFoodGroupFragment> }
    ) } };

export type AddAthletePhoneNumberMutationVariables = Exact<{
  input: AddAthletePhoneNumberInput;
}>;


export type AddAthletePhoneNumberMutation = { readonly addAthletePhoneNumber: { readonly athlete: AthleteFragment } };

export type AddEventMutationVariables = Exact<{
  input: AddEventInput;
}>;


export type AddEventMutation = { readonly addEvent: { readonly event: EventFragment } };

export type AddMealOptionMutationVariables = Exact<{
  input: AddMealOptionInput;
}>;


export type AddMealOptionMutation = { readonly addMealOption: { readonly mealOption: FullMealOptionFragment } };

export type ArchiveAthleteMutationVariables = Exact<{
  input: ArchiveAthleteInput;
}>;


export type ArchiveAthleteMutation = { readonly archiveAthlete: { readonly athlete: Pick<Athlete, 'id'> } };

export type CopyExchangeSetMutationVariables = Exact<{
  input: CopyExchangeSetInput;
}>;


export type CopyExchangeSetMutation = { readonly copyExchangeSet: { readonly exchangeSet: FullExchangeSetFragment } };

export type CreateOrgMembershipForAthleteMutationVariables = Exact<{
  input: CreateOrgMembershipForAthleteInput;
}>;


export type CreateOrgMembershipForAthleteMutation = { readonly createOrgMembershipForAthlete: (
    Pick<CreateOrgMembershipForAthletePayload, 'generatedSelfServiceActivationLink'>
    & { readonly athlete: (
      Pick<Athlete, 'id' | 'activationLinkSent'>
      & { readonly user: Pick<User, 'id' | 'username'>, readonly orgMembership: Maybe<Pick<OrgMembership, 'id' | 'isActive'>> }
    ) }
  ) };

export type CreateAthletesMutationVariables = Exact<{
  input: ReadonlyArray<CreateAthleteInput> | CreateAthleteInput;
}>;


export type CreateAthletesMutation = { readonly createAthletes: { readonly athletes: ReadonlyArray<AthleteFragment> } };

export type CreateBrandMutationVariables = Exact<{
  input: CreateBrandInput;
}>;


export type CreateBrandMutation = { readonly createBrand: { readonly brand: BrandFragment } };

export type CreateFoodServingsMutationVariables = Exact<{
  input: CreateFoodServingsInput;
}>;


export type CreateFoodServingsMutation = { readonly createFoodServings: { readonly servings: ReadonlyArray<ServingFragment> } };

export type CreateGoalMutationVariables = Exact<{
  input: CreateGoalInput;
}>;


export type CreateGoalMutation = { readonly createGoal: { readonly goal: FullGoalFragment } };

export type CreateMetricAnthropometryEntriesMutationVariables = Exact<{
  input: ReadonlyArray<CreateMetricAnthropometryEntryInput> | CreateMetricAnthropometryEntryInput;
}>;


export type CreateMetricAnthropometryEntriesMutation = { readonly createMetricAnthropometryEntries: { readonly anthropometryEntries: ReadonlyArray<AnthropometryEntryFragment> } };

export type CreateMetricAnthropometryEntryMutationVariables = Exact<{
  input: CreateMetricAnthropometryEntryInput;
}>;


export type CreateMetricAnthropometryEntryMutation = { readonly createMetricAnthropometryEntry: { readonly anthropometryEntry: AnthropometryEntryFragment } };

export type CreateMetricAthletesMutationVariables = Exact<{
  input: ReadonlyArray<CreateMetricAthleteInput> | CreateMetricAthleteInput;
}>;


export type CreateMetricAthletesMutation = { readonly createMetricAthletes: { readonly athletes: ReadonlyArray<AthleteFragment> } };

export type CreateNoteMutationVariables = Exact<{
  input: CreateNoteInput;
}>;


export type CreateNoteMutation = { readonly createNote: { readonly note: NoteWithTypeFragment } };

export type CreateNoteTypeMutationVariables = Exact<{
  input: CreateNoteTypeInput;
}>;


export type CreateNoteTypeMutation = { readonly createNoteType: { readonly noteType: NoteTypeFragment } };

export type CreateOrgFoodGroupMutationVariables = Exact<{
  input: CreateFoodGroupInput;
}>;


export type CreateOrgFoodGroupMutation = { readonly createOrgFoodGroup: { readonly foodGroup: FullFoodGroupFragment } };

export type CreateOrgFoodServingsMutationVariables = Exact<{
  input: CreateFoodServingsInput;
}>;


export type CreateOrgFoodServingsMutation = { readonly createOrgFoodServings: { readonly servings: ReadonlyArray<ServingFragment> } };

export type CreateSharedFoodGroupMutationVariables = Exact<{
  input: CreateFoodGroupInput;
}>;


export type CreateSharedFoodGroupMutation = { readonly createSharedFoodGroup: { readonly foodGroup: FullFoodGroupFragment } };

export type DeleteAnthropometryEntryMutationVariables = Exact<{
  input: DeleteAnthropometryEntryInput;
}>;


export type DeleteAnthropometryEntryMutation = { readonly deleteAnthropometryEntry: Pick<DeleteAnthropometryEntryPayload, 'anthropometryEntryId'> };

export type DeleteMealPlansMutationVariables = Exact<{
  input: DeleteMealPlansInput;
}>;


export type DeleteMealPlansMutation = { readonly deleteMealPlans: Pick<DeleteMealPlansPayload, 'mealPlanIds'> };

export type DeleteNoteMutationVariables = Exact<{
  input: DeleteNoteInput;
}>;


export type DeleteNoteMutation = { readonly deleteNote: Pick<DeleteNotePayload, 'noteId'> };

export type DeleteOrgFoodMutationVariables = Exact<{
  input: DeleteFoodInput;
}>;


export type DeleteOrgFoodMutation = { readonly deleteOrgFood: Pick<DeleteFoodPayload, 'foodId'> };

export type DeprecateSharedFoodMutationVariables = Exact<{
  input: DeprecateFoodInput;
}>;


export type DeprecateSharedFoodMutation = { readonly deprecateSharedFood: Pick<DeprecateFoodPayload, 'foodId'> };

export type EditAnthropometryEntryMutationVariables = Exact<{
  input: EditAnthropometryEntryInput;
}>;


export type EditAnthropometryEntryMutation = { readonly editAnthropometryEntry: { readonly anthropometryEntry: AnthropometryEntryFragment } };

export type EditAnthropometryEntryCommentMutationVariables = Exact<{
  input: EditAnthropometryEntryCommentInput;
}>;


export type EditAnthropometryEntryCommentMutation = { readonly editAnthropometryEntryComment: { readonly anthropometryEntry: AnthropometryEntryFragment } };

export type EditBrandMutationVariables = Exact<{
  input: EditBrandInput;
}>;


export type EditBrandMutation = { readonly editBrand: { readonly brand: BrandFragment } };

export type EditExchangeMealTemplateMutationVariables = Exact<{
  input: EditExchangeMealTemplateInput;
}>;


export type EditExchangeMealTemplateMutation = { readonly editExchangeMealTemplate: { readonly exchangeMealTemplate: EditFullExchangeMealTemplateFragment } };

export type EditExchangeMealTemplatesMutationVariables = Exact<{
  input: ReadonlyArray<EditExchangeMealTemplateInput> | EditExchangeMealTemplateInput;
}>;


export type EditExchangeMealTemplatesMutation = { readonly editExchangeMealTemplates: { readonly exchangeMealTemplates: ReadonlyArray<EditFullExchangeMealTemplateFragment> } };

export type EditFoodServingMutationVariables = Exact<{
  input: EditFoodServingInput;
}>;


export type EditFoodServingMutation = { readonly editFoodServing: { readonly serving: ServingFragment } };

export type EditMacroMealTemplateMutationVariables = Exact<{
  input: EditMacroMealTemplateInput;
}>;


export type EditMacroMealTemplateMutation = { readonly editMacroMealTemplate: { readonly macroMealTemplate: MacroMealTemplateFragment } };

export type EditMacroMealTemplatesMutationVariables = Exact<{
  input: ReadonlyArray<EditMacroMealTemplateInput> | EditMacroMealTemplateInput;
}>;


export type EditMacroMealTemplatesMutation = { readonly editMacroMealTemplates: { readonly macroMealTemplates: ReadonlyArray<MacroMealTemplateFragment> } };

export type EditMacroProtocolMutationVariables = Exact<{
  input: EditMacroProtocolInput;
}>;


export type EditMacroProtocolMutation = { readonly editMacroProtocol: { readonly macroProtocol: FullMacroProtocolFragment } };

export type EditMacroProtocolWithNewGoalMutationVariables = Exact<{
  input: EditMacroProtocolWithNewGoalInput;
}>;


export type EditMacroProtocolWithNewGoalMutation = { readonly editMacroProtocolWithNewGoal: { readonly macroProtocol: FullMacroProtocolFragment } };

export type EditMacroProtocolWithOldGoalMutationVariables = Exact<{
  input: EditMacroProtocolWithOldGoalInput;
}>;


export type EditMacroProtocolWithOldGoalMutation = { readonly editMacroProtocolWithOldGoal: { readonly macroProtocol: FullMacroProtocolFragment } };

export type EditMealOptionMutationVariables = Exact<{
  input: EditMealOptionInput;
}>;


export type EditMealOptionMutation = { readonly editMealOption: { readonly mealOption: FullMealOptionFragment } };

export type EditMealOptionPendingMutationVariables = Exact<{
  input: EditMealOptionPendingInput;
}>;


export type EditMealOptionPendingMutation = { readonly editMealOptionPending: { readonly mealOptionPending: FullMealOptionPendingFragment } };

export type EditMealPlanExchangeTargetsMutationVariables = Exact<{
  input: EditMealPlanExchangeTargetsInput;
}>;


export type EditMealPlanExchangeTargetsMutation = { readonly editMealPlanExchangeTargets: { readonly mealPlan: (
      Pick<MealPlan, 'id'>
      & { readonly exchangeTargets: Maybe<ReadonlyArray<EditExchangeAmountFragment>> }
    ) } };

export type EditMealPlanIsSharedMutationVariables = Exact<{
  input: EditMealPlanIsSharedInput;
}>;


export type EditMealPlanIsSharedMutation = { readonly editMealPlanIsShared: { readonly mealPlan: BasicMealPlanFragment } };

export type EditMealPlansIsSharedMutationVariables = Exact<{
  input: EditMealPlansIsSharedInput;
}>;


export type EditMealPlansIsSharedMutation = { readonly editMealPlansIsShared: { readonly mealPlans: ReadonlyArray<Pick<MealPlan, 'id' | 'isShared'>> } };

export type EditMetricAnthropometryEntriesMutationVariables = Exact<{
  input: ReadonlyArray<EditMetricAnthropometryEntryInput> | EditMetricAnthropometryEntryInput;
}>;


export type EditMetricAnthropometryEntriesMutation = { readonly editMetricAnthropometryEntries: { readonly anthropometryEntries: ReadonlyArray<AnthropometryEntryFragment> } };

export type EditMetricAnthropometryEntryMutationVariables = Exact<{
  input: EditMetricAnthropometryEntryInput;
}>;


export type EditMetricAnthropometryEntryMutation = { readonly editMetricAnthropometryEntry: { readonly anthropometryEntry: AnthropometryEntryFragment } };

export type EditMetricAthletesMutationVariables = Exact<{
  input: ReadonlyArray<EditAthleteAndMetricAnthroInput> | EditAthleteAndMetricAnthroInput;
}>;


export type EditMetricAthletesMutation = { readonly editMetricAthletes: { readonly athletes: ReadonlyArray<AthleteFragment> } };

export type EditMetricMacroProtocolMutationVariables = Exact<{
  input: EditMetricMacroProtocolInput;
}>;


export type EditMetricMacroProtocolMutation = { readonly editMetricMacroProtocol: { readonly macroProtocol: FullMacroProtocolFragment } };

export type EditNoteMutationVariables = Exact<{
  input: EditNoteInput;
}>;


export type EditNoteMutation = { readonly editNote: { readonly note: NoteWithTypeFragment } };

export type EditNoteTypeMutationVariables = Exact<{
  input: EditNoteTypeInput;
}>;


export type EditNoteTypeMutation = { readonly editNoteType: { readonly noteType: NoteTypeFragment } };

export type EditOrgFoodGroupMutationVariables = Exact<{
  input: EditFoodGroupInput;
}>;


export type EditOrgFoodGroupMutation = { readonly editOrgFoodGroup: { readonly foodGroup: FullFoodGroupFragment } };

export type EditOrgFoodServingMutationVariables = Exact<{
  input: EditFoodServingInput;
}>;


export type EditOrgFoodServingMutation = { readonly editOrgFoodServing: { readonly serving: ServingFragment } };

export type EditSharedFoodGroupMutationVariables = Exact<{
  input: EditFoodGroupInput;
}>;


export type EditSharedFoodGroupMutation = { readonly editSharedFoodGroup: { readonly foodGroup: FullFoodGroupFragment } };

export type CreateExchangeSetMutationVariables = Exact<{
  input: CreateExchangeSetInput;
}>;


export type CreateExchangeSetMutation = { readonly createExchangeSet: { readonly exchangeSet: FullExchangeSetFragment } };

export type EditExchangeSetMutationVariables = Exact<{
  input: EditExchangeSetInput;
}>;


export type EditExchangeSetMutation = { readonly editExchangeSet: { readonly exchangeSet: FullExchangeSetFragment } };

export type AddNixCommonFoodMutationVariables = Exact<{
  input: AddNixCommonFoodInput;
}>;


export type AddNixCommonFoodMutation = { readonly addNixCommonFood: { readonly food: Maybe<FoodWithDefaultServingBrandedFoodFragment | FoodWithDefaultServingGenericFoodFragment> } };

export type AddNixBrandedFoodMutationVariables = Exact<{
  input: AddNixBrandedFoodInput;
}>;


export type AddNixBrandedFoodMutation = { readonly addNixBrandedFood: { readonly food: Maybe<FoodWithDefaultServingBrandedFoodFragment | FoodWithDefaultServingGenericFoodFragment> } };

export type RemoveAthleteDislikedFoodMutationVariables = Exact<{
  input: AthleteFoodPreferenceInput;
}>;


export type RemoveAthleteDislikedFoodMutation = { readonly removeAthleteDislikedFood: { readonly athlete: (
      Pick<Athlete, 'id'>
      & { readonly dislikedFoods: ReadonlyArray<FoodBrandedFoodFragment | FoodGenericFoodFragment> }
    ) } };

export type RemoveAthleteDislikedFoodGroupMutationVariables = Exact<{
  input: AthleteFoodGroupPreferenceInput;
}>;


export type RemoveAthleteDislikedFoodGroupMutation = { readonly removeAthleteDislikedFoodGroup: { readonly athlete: (
      Pick<Athlete, 'id'>
      & { readonly dislikedFoodGroups: ReadonlyArray<FullFoodGroupFragment> }
    ) } };

export type RemoveAthleteLikedFoodMutationVariables = Exact<{
  input: AthleteFoodPreferenceInput;
}>;


export type RemoveAthleteLikedFoodMutation = { readonly removeAthleteLikedFood: { readonly athlete: (
      Pick<Athlete, 'id'>
      & { readonly likedFoods: ReadonlyArray<FoodBrandedFoodFragment | FoodGenericFoodFragment> }
    ) } };

export type RemoveAthleteLikedFoodGroupMutationVariables = Exact<{
  input: AthleteFoodGroupPreferenceInput;
}>;


export type RemoveAthleteLikedFoodGroupMutation = { readonly removeAthleteLikedFoodGroup: { readonly athlete: (
      Pick<Athlete, 'id'>
      & { readonly likedFoodGroups: ReadonlyArray<FullFoodGroupFragment> }
    ) } };

export type RemoveMealOptionMutationVariables = Exact<{
  input: RemoveMealOptionInput;
}>;


export type RemoveMealOptionMutation = { readonly removeMealOption: Pick<RemoveMealOptionPayload, 'mealOptionId'> };

export type RemoveMealOptionPendingMutationVariables = Exact<{
  input: RemoveMealOptionPendingInput;
}>;


export type RemoveMealOptionPendingMutation = { readonly removeMealOptionPending: Pick<RemoveMealOptionPendingPayload, 'mealOptionPendingId'> };

export type ShareMealOptionPendingMutationVariables = Exact<{
  input: ShareMealOptionPendingInput;
}>;


export type ShareMealOptionPendingMutation = { readonly shareMealOptionPending: { readonly mealOption: FullMealOptionFragment } };

export type TextMealPlanToAthleteMutationVariables = Exact<{
  input: TextMealPlanToAthleteInput;
}>;


export type TextMealPlanToAthleteMutation = { readonly textMealPlanToAthlete: Pick<TextMealPlanToAthletePayload, 'result'> };

export type TextMealPlansToAthletesMutationVariables = Exact<{
  input: TextMealPlansToAthletesInput;
}>;


export type TextMealPlansToAthletesMutation = { readonly textMealPlansToAthletes: Pick<TextMealPlansToAthletesPayload, 'result'> };

export type UnarchiveAthleteMutationVariables = Exact<{
  input: UnarchiveAthleteInput;
}>;


export type UnarchiveAthleteMutation = { readonly unarchiveAthlete: { readonly athlete: (
      { readonly anthropometryEntries: ReadonlyArray<AnthropometryEntryFragment>, readonly orgMembership: Maybe<Pick<OrgMembership, 'id' | 'isActive'>> }
      & AthleteWithGoalAndAnthroFragment
    ) } };

export type AddMealOptionLocalMutationVariables = Exact<{
  mealTemplateId: Scalars['ID'];
  type: Scalars['String'];
}>;


export type AddMealOptionLocalMutation = Pick<Mutation, 'addMealOptionLocal'>;

export type CalculateExchangeTotalsMutationVariables = Exact<{
  mealPlanId: Scalars['ID'];
}>;


export type CalculateExchangeTotalsMutation = Pick<Mutation, 'calculateExchangeTotals'>;

export type ChangeExchangeTargetMutationVariables = Exact<{
  mealTemplateId: Scalars['ID'];
  exchangeId: Scalars['ID'];
  target: Scalars['String'];
  pickListServingIds: Maybe<ReadonlyArray<Scalars['ID']> | Scalars['ID']>;
}>;


export type ChangeExchangeTargetMutation = Pick<Mutation, 'changeExchangeTarget'>;

export type ChangeExchangeTargetTotalMutationVariables = Exact<{
  mealPlanId: Scalars['ID'];
  exchangeId: Scalars['ID'];
  target: Scalars['String'];
}>;


export type ChangeExchangeTargetTotalMutation = Pick<Mutation, 'changeExchangeTargetTotal'>;

export type ChangeMacroTargetMutationVariables = Exact<{
  mealTemplateId: Scalars['ID'];
  macro: MacroType;
  target: Scalars['String'];
}>;


export type ChangeMacroTargetMutation = Pick<Mutation, 'changeMacroTarget'>;

export type DeselectNegativeFoodsMutationVariables = Exact<{
  mealPlanId: Scalars['ID'];
}>;


export type DeselectNegativeFoodsMutation = Pick<Mutation, 'deselectNegativeFoods'>;

export type DistributeExchangeTargetsMutationVariables = Exact<{
  mealPlanId: Scalars['ID'];
}>;


export type DistributeExchangeTargetsMutation = Pick<Mutation, 'distributeExchangeTargets'>;

export type DistributeMacroTargetsMutationVariables = Exact<{
  mealPlanId: Scalars['ID'];
}>;


export type DistributeMacroTargetsMutation = Pick<Mutation, 'distributeMacroTargets'>;

export type EditMealOptionLocalMutationVariables = Exact<{
  input: EditMealOptionLocalInput;
}>;


export type EditMealOptionLocalMutation = Pick<Mutation, 'editMealOptionLocal'>;

export type EditMealOptionPendingLocalMutationVariables = Exact<{
  input: EditMealOptionPendingLocalInput;
}>;


export type EditMealOptionPendingLocalMutation = Pick<Mutation, 'editMealOptionPendingLocal'>;

export type RemoveMealOptionLocalMutationVariables = Exact<{
  mealTemplateId: Scalars['ID'];
  type: Scalars['String'];
  mealOptionId: Scalars['ID'];
}>;


export type RemoveMealOptionLocalMutation = Pick<Mutation, 'removeMealOptionLocal'>;

export type RemoveMealOptionPendingLocalMutationVariables = Exact<{
  mealTemplateId: Scalars['ID'];
  type: Scalars['String'];
  mealOptionPendingId: Scalars['ID'];
}>;


export type RemoveMealOptionPendingLocalMutation = Pick<Mutation, 'removeMealOptionPendingLocal'>;

export type SelectMealOptionMutationVariables = Exact<{
  mealTemplateId: Scalars['ID'];
  type: Scalars['String'];
  mealOptionId: Scalars['ID'];
}>;


export type SelectMealOptionMutation = Pick<Mutation, 'selectMealOption'>;

export type ShareMealOptionPendingLocalMutationVariables = Exact<{
  mealTemplateId: Scalars['ID'];
  mealOptionPendingId: Scalars['ID'];
}>;


export type ShareMealOptionPendingLocalMutation = Pick<Mutation, 'shareMealOptionPendingLocal'>;

export type AllAthletesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllAthletesQuery = { readonly athletes: ReadonlyArray<(
    { readonly team: TeamWithSportFragment, readonly position: Maybe<PositionFragment> }
    & AthleteWithAnthroFragment
  )> };

export type AthleteWithAnthroFragment = (
  { readonly position: Maybe<PositionFragment>, readonly mostRecentAnthropometryEntry: Maybe<AnthropometryEntryFragment> }
  & AthleteFragment
);

export type AllAthletesAndAnthropometryEntriesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllAthletesAndAnthropometryEntriesQuery = { readonly athletes: ReadonlyArray<(
    { readonly mostRecentAnthropometryEntry: Maybe<Pick<AnthropometryEntry, 'id' | 'height'>>, readonly team: TeamWithSportFragment, readonly position: Maybe<PositionFragment>, readonly anthropometryEntries: ReadonlyArray<AnthropometryEntryFragment> }
    & AthleteFragment
  )> };

export type AthleteAnthropometryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AthleteAnthropometryQuery = { readonly athlete: (
    { readonly anthropometryEntries: ReadonlyArray<AnthropometryEntryFragment> }
    & AthleteFragment
  ) };

export type AthleteNotesQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type AthleteNotesQuery = { readonly athlete: (
    { readonly notes: ReadonlyArray<NoteWithTypeFragment> }
    & AthleteFragment
  ) };

export type AthletesMealPlansQueryVariables = Exact<{ [key: string]: never; }>;


export type AthletesMealPlansQuery = { readonly athletes: ReadonlyArray<AthleteWithMealPlansFragment> };

export type MealPlanWithAthleteFragment = (
  { readonly athlete: AthleteFragment }
  & MealPlanSearchFormFragment
);

export type MealPlanForCopyCursorConnectionQueryVariables = Exact<{
  pagination: CursorPaginationInput;
  athleteName: Maybe<Scalars['String']>;
  mealPlanName: Maybe<Scalars['String']>;
}>;


export type MealPlanForCopyCursorConnectionQuery = { readonly mealPlanForCopyCursorConnection: { readonly edges: ReadonlyArray<MealPlanWithAthleteFragment>, readonly pageInfo: Pick<CursorPageInfo, 'endCursor' | 'hasNextPage'> } };

export type AthletesPreviewQueryVariables = Exact<{ [key: string]: never; }>;


export type AthletesPreviewQuery = { readonly athletes: ReadonlyArray<AthleteFragment> };

export type AthleteSearchQueryVariables = Exact<{
  query: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
}>;


export type AthleteSearchQuery = { readonly athleteSearch: ReadonlyArray<AthleteFragment> };

export type TeamAthletesSelectFieldQueryVariables = Exact<{
  teamId: Scalars['ID'];
}>;


export type TeamAthletesSelectFieldQuery = { readonly team: { readonly athletes: ReadonlyArray<AthleteFragment> } };

export type BrandsQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
}>;


export type BrandsQuery = { readonly brands: ReadonlyArray<BrandFragment> };

export type GetEditMealPlanQueryVariables = Exact<{
  mealPlanId: Scalars['ID'];
}>;


export type GetEditMealPlanQuery = { readonly mealPlan: EditFullMealPlanFragment };

export type ExchangeSetQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ExchangeSetQuery = { readonly exchangeSet: FullExchangeSetFragment };

export type ExchangeSetsQueryVariables = Exact<{ [key: string]: never; }>;


export type ExchangeSetsQuery = { readonly exchangeSets: ReadonlyArray<ExchangeSetWithExchangesFragment> };

export type FoodGroupQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FoodGroupQuery = { readonly foodGroup: FullFoodGroupWithFoodsFragment };

export type FoodGroupsQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
}>;


export type FoodGroupsQuery = { readonly foodGroups: ReadonlyArray<SimpleFoodGroupFragment> };

export type SharedFoodGroupsTableQueryVariables = Exact<{
  query: Maybe<Scalars['String']>;
  input: OffsetPaginationInput;
  sortOrder: SortOrder;
}>;


export type SharedFoodGroupsTableQuery = { readonly sharedFoodGroupsOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<FullFoodGroupFragment> } };

export type OrgFoodGroupsTableQueryVariables = Exact<{
  query: Maybe<Scalars['String']>;
  input: OffsetPaginationInput;
  sortOrder: SortOrder;
}>;


export type OrgFoodGroupsTableQuery = { readonly orgFoodGroupsOffsetConnection: { readonly pageInfo: Pick<OffsetPageInfo, 'hasNextPage' | 'total' | 'endOffset'>, readonly edges: ReadonlyArray<FullFoodGroupFragment> } };

export type SharedFoodGroupsCursorConnectionQueryVariables = Exact<{
  searchText: Scalars['String'];
  pagination: CursorPaginationInput;
}>;


export type SharedFoodGroupsCursorConnectionQuery = { readonly sharedFoodGroupsCursorConnection: { readonly pageInfo: Pick<CursorPageInfo, 'hasNextPage' | 'endCursor'>, readonly edges: ReadonlyArray<SimpleFoodGroupFragment> } };

export type SimpleFoodGroupFragment = Pick<FoodGroup, 'id' | 'name'>;

export type FoodOrRecipeQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FoodOrRecipeQuery = { readonly foodOrRecipe: FoodWithServingsBrandedFoodFragment | FoodWithServingsGenericFoodFragment | RecipeWithServingsFragment };

export type FoodsWithDefaultServingOnlyQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
  localeCodes: Maybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  dataSources: Maybe<ReadonlyArray<FoodDataSource> | FoodDataSource>;
}>;


export type FoodsWithDefaultServingOnlyQuery = { readonly foods: ReadonlyArray<FoodWithDefaultServingBrandedFoodFragment | FoodWithDefaultServingGenericFoodFragment> };

export type RestaurantFoodsWithDefaultServingOnlyQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
  restaurantId: Scalars['ID'];
}>;


export type RestaurantFoodsWithDefaultServingOnlyQuery = { readonly restaurantFoods: ReadonlyArray<FoodWithDefaultServingBrandedFoodFragment> };

export type GenericFoodsQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
}>;


export type GenericFoodsQuery = { readonly genericFoods: ReadonlyArray<FoodGenericFoodFragment> };

export type GoalTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type GoalTypesQuery = { readonly goalTypes: ReadonlyArray<GoalTypeFragment> };

export type GroceryListCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GroceryListCategoriesQuery = { readonly groceryListCategories: ReadonlyArray<Pick<GroceryListCategory, 'id' | 'name'>> };

export type MealPlanBulkCopyChooseAthletesContainerQueryVariables = Exact<{
  teamId: Scalars['ID'];
}>;


export type MealPlanBulkCopyChooseAthletesContainerQuery = { readonly team: (
    { readonly athletes: ReadonlyArray<AthleteWithGoalAndAnthroFragment> }
    & TeamFragment
  ), readonly exchangeSets: ReadonlyArray<ExchangeSetFragment> };

export type MealPlanBulkCopyQueryVariables = Exact<{ [key: string]: never; }>;


export type MealPlanBulkCopyQuery = { readonly teams: ReadonlyArray<MealPlanBulkCopyTeamPreviewFragment>, readonly exchangeSets: ReadonlyArray<ExchangeSetFragment> };

export type MealPlanBulkCopyTeamPreviewFragment = Pick<Team, 'name' | 'id'>;

export type NoteTypesQueryVariables = Exact<{ [key: string]: never; }>;


export type NoteTypesQuery = { readonly noteTypes: ReadonlyArray<NoteTypeFragment> };

export type OrgFoodGroupsQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
}>;


export type OrgFoodGroupsQuery = { readonly orgFoodGroups: ReadonlyArray<FullFoodGroupFragment> };

export type PingQueryVariables = Exact<{ [key: string]: never; }>;


export type PingQuery = Pick<Query, 'ping'>;

export type RecipesQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
}>;


export type RecipesQuery = { readonly recipes: ReadonlyArray<(
    Pick<Recipe, 'hasFullAccess'>
    & { readonly org: Maybe<Pick<Org, 'id' | 'name'>>, readonly descendantFoods: ReadonlyArray<FoodBrandedFoodFragment | FoodGenericFoodFragment> }
    & RecipeWithServingsFragment
  )>, readonly orgGroupRecipes: ReadonlyArray<(
    Pick<Recipe, 'hasFullAccess'>
    & { readonly org: Maybe<Pick<Org, 'id' | 'name'>>, readonly descendantFoods: ReadonlyArray<FoodBrandedFoodFragment | FoodGenericFoodFragment>, readonly orgGroup: Maybe<Pick<OrgGroup, 'name'>> }
    & RecipeWithServingsFragment
  )> };

export type SchedulesDefaultAndTeamQueryVariables = Exact<{
  teamId: Scalars['ID'];
}>;


export type SchedulesDefaultAndTeamQuery = { readonly schedules: ReadonlyArray<FullScheduleFragment>, readonly schedulesForTeam: ReadonlyArray<FullScheduleFragment> };

export type SharedFoodGroupsQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
}>;


export type SharedFoodGroupsQuery = { readonly sharedFoodGroups: ReadonlyArray<FullFoodGroupFragment> };

export type SimpleFoodsQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
  localeCodes: Maybe<ReadonlyArray<Scalars['String']> | Scalars['String']>;
  dataSources: Maybe<ReadonlyArray<FoodDataSource> | FoodDataSource>;
}>;


export type SimpleFoodsQuery = { readonly foods: ReadonlyArray<(
    { readonly __typename: 'BrandedFood' }
    & FoodBrandedFoodFragment
  ) | (
    { readonly __typename: 'GenericFood' }
    & FoodGenericFoodFragment
  )> };

export type SlackFoodRequestsUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type SlackFoodRequestsUrlQuery = Pick<Query, 'slackFoodRequestsUrl'>;

export type SportsQueryVariables = Exact<{ [key: string]: never; }>;


export type SportsQuery = { readonly sports: ReadonlyArray<FullSportFragment> };

export type TeamAnthropometryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TeamAnthropometryQuery = { readonly team: (
    { readonly athletes: ReadonlyArray<(
      { readonly position: Maybe<PositionFragment>, readonly anthropometryEntries: ReadonlyArray<AnthropometryEntryFragment> }
      & AthleteFragment
    )> }
    & TeamWithSportFragment
  ) };

export type TeamRosterQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TeamRosterQuery = { readonly team: (
    { readonly athletes: ReadonlyArray<(
      { readonly position: Maybe<PositionFragment> }
      & AthleteFragment
    )> }
    & TeamWithSportFragment
  ) };

export type NutritionixInstantSearchQueryVariables = Exact<{
  query: Scalars['String'];
  region: Scalars['Int'];
  localeCode: Scalars['String'];
}>;


export type NutritionixInstantSearchQuery = { readonly nutritionixBrandedInstantSearch: { readonly branded: ReadonlyArray<NutritionixBrandedFoodFragment> }, readonly nutritionixCommonInstantSearch: { readonly common: ReadonlyArray<NutritionixCommonFoodFragment> } };

export type NutritionixBrandedInstantSearchQueryVariables = Exact<{
  query: Scalars['String'];
  region: Scalars['Int'];
}>;


export type NutritionixBrandedInstantSearchQuery = { readonly nutritionixBrandedInstantSearch: { readonly branded: ReadonlyArray<NutritionixBrandedFoodFragment> } };

export type NutritionixV1RestaurantSearchQueryVariables = Exact<{
  input: NutritionixV1RestaurantSearchInput;
}>;


export type NutritionixV1RestaurantSearchQuery = { readonly nutritionixV1RestaurantSearch: (
    Pick<NutritionixV1RestaurantSearchPayload, 'total'>
    & { readonly branded: ReadonlyArray<NutritionixBrandedFoodFragment> }
  ) };

export type NutritionixCommonFoodFragment = (
  { readonly __typename: 'NutritionixCommonFood' }
  & Pick<NutritionixCommonFood, 'id' | 'name' | 'nixTagId' | 'thumbnailUrl'>
);

export type NutritionixBrandedFoodFragment = (
  { readonly __typename: 'NutritionixBrandedFood' }
  & Pick<NutritionixBrandedFood, 'id' | 'name' | 'nixItemId' | 'thumbnailUrl'>
  & { readonly brand: (
    { readonly __typename: 'NutritionixBrand' }
    & Pick<NutritionixBrand, 'id' | 'name' | 'type'>
  ) }
);

export type UsdaFoodDetailsQueryVariables = Exact<{
  usdaFdcId: Scalars['ID'];
  apiKey: Scalars['String'];
}>;


export type UsdaFoodDetailsQuery = { readonly usdaFoodDetail: UsdaFoodDetailFragment };

export type UsdaFoodsQueryVariables = Exact<{
  searchTerm: Scalars['String'];
  apiKey: Scalars['String'];
  brandOwner: Scalars['String'];
  includedDataTypes: Scalars['String'];
}>;


export type UsdaFoodsQuery = { readonly usdaFoods: ReadonlyArray<UsdaFoodFragment> };

type FoodOrRecipePreferencesBrandedFoodFragment = (
  { readonly __typename: 'BrandedFood' }
  & Pick<BrandedFood, 'id' | 'name'>
);

type FoodOrRecipePreferencesGenericFoodFragment = (
  { readonly __typename: 'GenericFood' }
  & Pick<GenericFood, 'id' | 'name'>
);

type FoodOrRecipePreferencesRecipeFragment = (
  { readonly __typename: 'Recipe' }
  & Pick<Recipe, 'id' | 'name'>
  & { readonly descendantFoods: ReadonlyArray<Pick<BrandedFood, 'id'> | Pick<GenericFood, 'id'>> }
);

export type FoodOrRecipePreferencesFragment = FoodOrRecipePreferencesBrandedFoodFragment | FoodOrRecipePreferencesGenericFoodFragment | FoodOrRecipePreferencesRecipeFragment;

export const AgreementFormFragmentDoc = gql`
    fragment AgreementForm on AgreementForm {
  id
  title
  url
  acceptAgreementText
}
    `;
export const CommentWithNotificationFragmentDoc = gql`
    fragment CommentWithNotification on Comment {
  id
  notification {
    id
    viewedAt
  }
}
    `;
export const NoTargetsDisplaySettingsFragmentDoc = gql`
    fragment NoTargetsDisplaySettings on NoTargetsDisplaySettings {
  dailyProgressViewsForMacros
  mealProgressViewsForMacros
}
    `;
export const ExchangeSummaryLabelFragmentDoc = gql`
    fragment ExchangeSummaryLabel on ExchangeAmount {
  id
  __typename
  amount
  exchange {
    __typename
    id
    name
    shortName
    type
    cho
    pro
    fat
    kcal
  }
}
    `;
export const FeatureFlagsFragmentDoc = gql`
    fragment FeatureFlags on FeatureFlags {
  athleteMenuSuggestions
  clientCredentials
  hasRestaurantMenus
  restaurantLogging
  teamworksSchedules
  mealPlanSuggestions
  prepPlan
  foodCategory
  pk2MenuBuilder
  orgGroupRecipes
  orgGroupFoods
  performanceKitchen
  printableTags
  hubMenuCheckIn
  goalTracker
  dietitianOnlyMenus
  bulkOrdering
  ukMealPlanOptionSuggestions
  digitalDisplays
  selfServiceOnboarding
  messaging
}
    `;
export const MenuItemFoodCategoryFragmentDoc = gql`
    fragment MenuItemFoodCategory on MenuItemFoodCategory {
  category
}
    `;
export const FoodOrRecipePreferencesFragmentDoc = gql`
    fragment FoodOrRecipePreferences on FoodOrRecipe {
  ... on Food {
    __typename
    id
    name
  }
  ... on Recipe {
    __typename
    id
    name
    descendantFoods {
      id
    }
  }
}
    `;
export const ScoringSystemAssignmentFragmentDoc = gql`
    fragment ScoringSystemAssignment on ScoringSystem {
  name
  id
}
    `;
export const ScoreAssignmentFragmentDoc = gql`
    fragment ScoreAssignment on Score {
  value
  scoringSystem {
    ...ScoringSystemAssignment
  }
}
    ${ScoringSystemAssignmentFragmentDoc}`;
export const FoodCategoryNameFragmentDoc = gql`
    fragment FoodCategoryName on MenuItemFoodCategory {
  category
}
    `;
export const MenuItemPreviewFragmentDoc = gql`
    fragment MenuItemPreview on MenuItem {
  id
  __typename
  name
  servingName
  description
  imageUrl
  isOneOff
  defaultAvailableForOrder
  defaultAllowSpecialRequests
  defaultMaxAmount
  isDeleted
  servingAmounts {
    id
    serving {
      id
      foodOrRecipe {
        ...FoodOrRecipePreferences
      }
    }
  }
  score {
    ...ScoreAssignment
  }
  foodCategory {
    ...FoodCategoryName
  }
}
    ${FoodOrRecipePreferencesFragmentDoc}
${ScoreAssignmentFragmentDoc}
${FoodCategoryNameFragmentDoc}`;
export const MenuItemAppearancePreviewFragmentDoc = gql`
    fragment MenuItemAppearancePreview on MenuItemAppearance {
  id
  position
  maxAmount
  availableForOrder
  allowSpecialRequests
  menuItem {
    ...MenuItemPreview
  }
}
    ${MenuItemPreviewFragmentDoc}`;
export const MealMenuDiningStationWithItemPreviewsFragmentDoc = gql`
    fragment MealMenuDiningStationWithItemPreviews on MealMenuDiningStation {
  id
  name
  maxAmount
  position
  menuItemAppearances {
    ...MenuItemAppearancePreview
  }
}
    ${MenuItemAppearancePreviewFragmentDoc}`;
export const MenuOrderRateLimitRemainderFragmentDoc = gql`
    fragment MenuOrderRateLimitRemainder on MenuOrderRateLimitRemainder {
  start
  remaining
}
    `;
export const FoodLimitedAccessFragmentDoc = gql`
    fragment FoodLimitedAccess on Food {
  id
  __typename
  name
  usdaFdcDescription
  exchangeTypes
  thumbnailUrl
  hasFullAccess
  nixItemId
  nixTagId
  org {
    id
    name
  }
  ... on BrandedFood {
    brand {
      id
      name
    }
  }
}
    `;
export const ImageFragmentDoc = gql`
    fragment Image on Image {
  id
  position
  url
}
    `;
export const RecipeIngredientServingFragmentDoc = gql`
    fragment RecipeIngredientServing on Serving {
  id
  __typename
  defaultAmount
  isDefault
  weight
  perRecipeYield
  hasOwnNutrients
  macros {
    cho
    pro
    fat
    kcal
  }
  units
  unit {
    id
    name
  }
  foodOrRecipe {
    ... on Food {
      ...FoodLimitedAccess
    }
    ... on Recipe {
      __typename
      id
      name
      exchangeTypes
      isCurrent
      images {
        ...Image
      }
      descendantFoods {
        id
      }
      createdBy {
        id
        firstName
        lastName
      }
      hasFullAccess
      org {
        id
        name
      }
      score {
        ...ScoreAssignment
      }
    }
  }
}
    ${FoodLimitedAccessFragmentDoc}
${ImageFragmentDoc}
${ScoreAssignmentFragmentDoc}`;
export const RecipeIngredientServingAmountFragmentDoc = gql`
    fragment RecipeIngredientServingAmount on ServingAmount {
  id
  amount
  position
  serving {
    ...RecipeIngredientServing
  }
}
    ${RecipeIngredientServingFragmentDoc}`;
export const RecipeLimitedAccessFragmentDoc = gql`
    fragment RecipeLimitedAccess on Recipe {
  id
  __typename
  name
  exchangeTypes
  isCurrent
  images {
    ...Image
  }
  descendantFoods {
    id
    name
    nixItemId
    nixTagId
  }
  createdBy {
    id
    firstName
    lastName
  }
  hasFullAccess
  org {
    id
    name
  }
  score {
    ...ScoreAssignment
  }
  ingredients {
    ...RecipeIngredientServingAmount
  }
}
    ${ImageFragmentDoc}
${ScoreAssignmentFragmentDoc}
${RecipeIngredientServingAmountFragmentDoc}`;
export const FullServingFragmentDoc = gql`
    fragment FullServing on Serving {
  id
  __typename
  units
  defaultAmount
  isDefault
  weight
  perRecipeYield
  hasOwnNutrients
  macros {
    cho
    pro
    fat
    kcal
  }
  unit {
    id
    name
  }
  foodOrRecipe {
    ... on Food {
      ...FoodLimitedAccess
    }
    ... on Recipe {
      ...RecipeLimitedAccess
    }
  }
}
    ${FoodLimitedAccessFragmentDoc}
${RecipeLimitedAccessFragmentDoc}`;
export const FullServingAmountFragmentDoc = gql`
    fragment FullServingAmount on ServingAmount {
  id
  __typename
  amount
  position
  serving {
    ...FullServing
  }
}
    ${FullServingFragmentDoc}`;
export const MenuItemChoiceOptionFormFragmentDoc = gql`
    fragment MenuItemChoiceOptionForm on MenuItemChoiceOption {
  id
  name
  position
  canEditAmount
  defaultAmount
  maxAmount
  servingAmounts {
    ...FullServingAmount
  }
}
    ${FullServingAmountFragmentDoc}`;
export const MenuItemChoiceFormFragmentDoc = gql`
    fragment MenuItemChoiceForm on MenuItemChoice {
  id
  name
  position
  required
  maxOptionsCount
  options {
    ...MenuItemChoiceOptionForm
  }
}
    ${MenuItemChoiceOptionFormFragmentDoc}`;
export const MenuItemFormFragmentDoc = gql`
    fragment MenuItemForm on MenuItem {
  id
  __typename
  name
  servingName
  description
  isOneOff
  servingAmounts {
    ...FullServingAmount
  }
  choices {
    ...MenuItemChoiceForm
  }
  imageUrl
  suggestionCategory
  defaultMaxAmount
  defaultAvailableForOrder
  defaultAllowSpecialRequests
  isDeleted
  score {
    ...ScoreAssignment
  }
  foodCategory {
    ...FoodCategoryName
  }
}
    ${FullServingAmountFragmentDoc}
${MenuItemChoiceFormFragmentDoc}
${ScoreAssignmentFragmentDoc}
${FoodCategoryNameFragmentDoc}`;
export const MenuSelectionItemOptionFormFragmentDoc = gql`
    fragment MenuSelectionItemOptionForm on MenuSelectionItemOption {
  amount
  percentConsumed
  menuItemChoiceOption {
    ...MenuItemChoiceOptionForm
  }
}
    ${MenuItemChoiceOptionFormFragmentDoc}`;
export const MenuSelectionItemFormFragmentDoc = gql`
    fragment MenuSelectionItemForm on MenuSelectionItem {
  amount
  percentConsumed
  menuItem {
    ...MenuItemForm
  }
  options {
    ...MenuSelectionItemOptionForm
  }
}
    ${MenuItemFormFragmentDoc}
${MenuSelectionItemOptionFormFragmentDoc}`;
export const MealMenuLogItemFormFragmentDoc = gql`
    fragment MealMenuLogItemForm on MealMenuLogItem {
  id
  ...MenuSelectionItemForm
}
    ${MenuSelectionItemFormFragmentDoc}`;
export const ExchangeFragmentDoc = gql`
    fragment Exchange on Exchange {
  id
  __typename
  name
  shortName
  type
  cho
  pro
  fat
  kcal
}
    `;
export const ExchangeAmountFragmentDoc = gql`
    fragment ExchangeAmount on ExchangeAmount {
  id
  __typename
  amount
  pickListServingIds
  exchange {
    ...Exchange
  }
}
    ${ExchangeFragmentDoc}`;
export const MealTemplateForTargetsFragmentDoc = gql`
    fragment MealTemplateForTargets on MealTemplate {
  id
  ... on MacroMealTemplate {
    __typename
    cho
    pro
    fat
  }
  ... on ExchangeMealTemplate {
    __typename
    exchangeTargets {
      ...ExchangeAmount
    }
  }
}
    ${ExchangeAmountFragmentDoc}`;
export const ViewMealOptionFragmentDoc = gql`
    fragment ViewMealOption on MealOption {
  id
  __typename
  note
  position
  servingAmounts {
    ...FullServingAmount
  }
}
    ${FullServingAmountFragmentDoc}`;
export const MealFragmentDoc = gql`
    fragment Meal on Meal {
  id
  __typename
  name
  start
  end
  type
}
    `;
export const ExchangeMealTemplateViewFragmentDoc = gql`
    fragment ExchangeMealTemplateView on ExchangeMealTemplate {
  id
  __typename
  mealOptions {
    ...ViewMealOption
  }
  exchangeTargets {
    ...ExchangeAmount
  }
  meal {
    ...Meal
  }
}
    ${ViewMealOptionFragmentDoc}
${ExchangeAmountFragmentDoc}
${MealFragmentDoc}`;
export const MacroMealTemplateViewFragmentDoc = gql`
    fragment MacroMealTemplateView on MacroMealTemplate {
  id
  __typename
  mealOptions {
    ...ViewMealOption
  }
  meal {
    ...Meal
  }
  cho
  pro
  fat
  kcal
}
    ${ViewMealOptionFragmentDoc}
${MealFragmentDoc}`;
export const MenuItemWithIdsFragmentDoc = gql`
    fragment MenuItemWithIds on MenuItem {
  id
  choices {
    id
    options {
      id
    }
  }
}
    `;
export const MenuSelectionItemOptionWithIdsFragmentDoc = gql`
    fragment MenuSelectionItemOptionWithIds on MenuSelectionItemOption {
  amount
  menuItemChoiceOption {
    id
  }
  percentConsumed
}
    `;
export const RatingFragmentDoc = gql`
    fragment Rating on MenuItemRating {
  id
  review
  value
}
    `;
export const MenuOrderItemFormFragmentDoc = gql`
    fragment MenuOrderItemForm on MenuOrderItem {
  id
  forOrder
  status
  specialRequests
  rating {
    ...Rating
  }
  ...MenuSelectionItemForm
}
    ${RatingFragmentDoc}
${MenuSelectionItemFormFragmentDoc}`;
export const MenuSelectionFormFragmentDoc = gql`
    fragment MenuSelectionForm on MenuSelection {
  items {
    ...MenuSelectionItemForm
  }
}
    ${MenuSelectionItemFormFragmentDoc}`;
export const MenuSelectItemOptionWithMacrosFragmentDoc = gql`
    fragment MenuSelectItemOptionWithMacros on MenuSelectionItemOption {
  amount
  percentConsumed
  menuItemChoiceOption {
    id
    servingAmounts {
      ...FullServingAmount
    }
  }
}
    ${FullServingAmountFragmentDoc}`;
export const MenuSelectionItemWithMacrosFragmentDoc = gql`
    fragment MenuSelectionItemWithMacros on MenuSelectionItem {
  amount
  percentConsumed
  menuItem {
    id
    servingAmounts {
      ...FullServingAmount
    }
  }
  options {
    ...MenuSelectItemOptionWithMacros
  }
}
    ${FullServingAmountFragmentDoc}
${MenuSelectItemOptionWithMacrosFragmentDoc}`;
export const MenuOrderSuggestionFragmentDoc = gql`
    fragment MenuOrderSuggestion on MenuOrderSuggestion {
  items {
    amount
    percentConsumed
    menuItem {
      ...MenuItemForm
    }
    options {
      amount
      percentConsumed
      menuItemChoiceOption {
        ...MenuItemChoiceOptionForm
      }
    }
  }
}
    ${MenuItemFormFragmentDoc}
${MenuItemChoiceOptionFormFragmentDoc}`;
export const NutrientAmountFragmentDoc = gql`
    fragment NutrientAmount on NutrientAmount {
  amount
  nutrient {
    id
    name
  }
}
    `;
export const ServingWithNutrientAmountsFragmentDoc = gql`
    fragment ServingWithNutrientAmounts on Serving {
  id
  nutrientAmounts {
    ...NutrientAmount
  }
  foodOrRecipe {
    ... on Food {
      id
      name
    }
    ... on Recipe {
      id
      name
    }
  }
}
    ${NutrientAmountFragmentDoc}`;
export const NutrientWithDriFragmentDoc = gql`
    fragment NutrientWithDri on Nutrient {
  id
  name
  unitName
  defaultDietaryReferenceIntake(age: $age, sex: $sex) {
    id
    type
    amount(kcal: $kcal)
  }
}
    `;
export const RestaurantMenuWithItemPreviewsFragmentDoc = gql`
    fragment RestaurantMenuWithItemPreviews on RestaurantMenu {
  id
  sections {
    id
    name
    position
    menuItemAppearances {
      ...MenuItemAppearancePreview
    }
  }
}
    ${MenuItemAppearancePreviewFragmentDoc}`;
export const RestaurantMenuLinkOrderItemFormFragmentDoc = gql`
    fragment RestaurantMenuLinkOrderItemForm on RestaurantMenuLinkOrderItem {
  id
  specialRequests
  ...MenuSelectionItemForm
}
    ${MenuSelectionItemFormFragmentDoc}`;
export const RestaurantMenuLinkOrderFragmentDoc = gql`
    fragment RestaurantMenuLinkOrder on RestaurantMenuLinkOrder {
  id
  items {
    ...RestaurantMenuLinkOrderItemForm
  }
}
    ${RestaurantMenuLinkOrderItemFormFragmentDoc}`;
export const RestaurantMenuLinkPlateOrderItemOptionFormFragmentDoc = gql`
    fragment RestaurantMenuLinkPlateOrderItemOptionForm on RestaurantMenuLinkPlateOrderItemOption {
  amount
  percentConsumed
  menuItemChoiceOption {
    ...MenuItemChoiceOptionForm
  }
}
    ${MenuItemChoiceOptionFormFragmentDoc}`;
export const RestaurantMenuLinkPlateOrderItemFormFragmentDoc = gql`
    fragment RestaurantMenuLinkPlateOrderItemForm on RestaurantMenuLinkPlateOrderItem {
  amount
  percentConsumed
  plateItem {
    id
  }
  menuItem {
    ...MenuItemForm
  }
  options {
    ...RestaurantMenuLinkPlateOrderItemOptionForm
  }
}
    ${MenuItemFormFragmentDoc}
${RestaurantMenuLinkPlateOrderItemOptionFormFragmentDoc}`;
export const RestaurantMenuLinkPlateItemFormFragmentDoc = gql`
    fragment RestaurantMenuLinkPlateItemForm on RestaurantMenuLinkPlateItem {
  id
  position
  specialRequests
  ...MenuSelectionItemForm
}
    ${MenuSelectionItemFormFragmentDoc}`;
export const RestaurantMenuLinkPlateOrderFragmentDoc = gql`
    fragment RestaurantMenuLinkPlateOrder on RestaurantMenuLinkPlateOrder {
  id
  userFullName
  items {
    ...RestaurantMenuLinkPlateOrderItemForm
  }
  plate {
    id
    items {
      ...RestaurantMenuLinkPlateItemForm
    }
    position
  }
  restaurantMenuLink {
    id
  }
}
    ${RestaurantMenuLinkPlateOrderItemFormFragmentDoc}
${RestaurantMenuLinkPlateItemFormFragmentDoc}`;
export const RestaurantMenuLinkWithMyOrdersFragmentDoc = gql`
    fragment RestaurantMenuLinkWithMyOrders on RestaurantMenuLink {
  id
  allowCustomOrders
  allowPlateOrders
  restaurant {
    id
    name
  }
  order: myOrder {
    ...RestaurantMenuLinkOrder
  }
  plateOrder: myPlateOrder {
    ...RestaurantMenuLinkPlateOrder
  }
}
    ${RestaurantMenuLinkOrderFragmentDoc}
${RestaurantMenuLinkPlateOrderFragmentDoc}`;
export const RestaurantMenuLinkWithOrdersForAthleteFragmentDoc = gql`
    fragment RestaurantMenuLinkWithOrdersForAthlete on RestaurantMenuLink {
  id
  allowCustomOrders
  allowPlateOrders
  restaurant {
    id
    name
  }
  order: orderForAthlete(athleteId: $athleteId) {
    ...RestaurantMenuLinkOrder
  }
  plateOrder: plateOrderForAthlete(athleteId: $athleteId) {
    ...RestaurantMenuLinkPlateOrder
  }
}
    ${RestaurantMenuLinkOrderFragmentDoc}
${RestaurantMenuLinkPlateOrderFragmentDoc}`;
export const RestaurantMenuLinkPlateFragmentDoc = gql`
    fragment RestaurantMenuLinkPlate on RestaurantMenuLinkPlate {
  id
  items {
    ...RestaurantMenuLinkPlateItemForm
  }
  position
}
    ${RestaurantMenuLinkPlateItemFormFragmentDoc}`;
export const RestaurantMenuLinkWithItemPreviewsFragmentDoc = gql`
    fragment RestaurantMenuLinkWithItemPreviews on RestaurantMenuLink {
  id
  allowCustomOrders
  allowPlateOrders
  restaurant {
    id
    name
  }
  sections {
    restaurantMenuSection {
      id
      name
      position
    }
    maxAmount
    menuItemAppearances {
      ...MenuItemAppearancePreview
    }
  }
  plates {
    ...RestaurantMenuLinkPlate
  }
}
    ${MenuItemAppearancePreviewFragmentDoc}
${RestaurantMenuLinkPlateFragmentDoc}`;
export const RestaurantMenuLogItemFormFragmentDoc = gql`
    fragment RestaurantMenuLogItemForm on RestaurantMenuLogItem {
  id
  ...MenuSelectionItemForm
}
    ${MenuSelectionItemFormFragmentDoc}`;
export const RestaurantMenuLogFragmentDoc = gql`
    fragment RestaurantMenuLog on RestaurantMenuLog {
  id
  mealTemplate {
    id
  }
  items {
    ...RestaurantMenuLogItemForm
  }
  restaurantMenu {
    id
  }
  dateTime
  timezone
  type
}
    ${RestaurantMenuLogItemFormFragmentDoc}`;
export const ScoringSystemFragmentDoc = gql`
    fragment ScoringSystem on ScoringSystem {
  id
  name
}
    `;
export const ServingWithFoodOrRecipeMacrosFragmentDoc = gql`
    fragment ServingWithFoodOrRecipeMacros on Serving {
  id
  __typename
  macros {
    cho
    pro
    fat
    kcal
  }
  foodOrRecipe {
    __typename
    ... on Food {
      id
      name
      usdaFdcDescription
    }
    ... on Recipe {
      id
      name
      createdBy {
        firstName
        lastName
      }
    }
  }
}
    `;
export const TimelineItemForGroupByDateFragmentDoc = gql`
    fragment TimelineItemForGroupByDate on TimelineItem {
  ... on MealPlanDate {
    date
    timezone
    __typename
  }
  ... on FoodLogMeal {
    id
    __typename
  }
  ... on FoodLogActivity {
    id
    __typename
    date
    startTime
    timezone
  }
  ... on MenuOrder {
    id
    __typename
  }
  ... on MealMenu {
    id
    __typename
  }
  ... on RestaurantMenuLog {
    id
    __typename
  }
}
    `;
export const NotificationTimelineFragmentDoc = gql`
    fragment NotificationTimeline on Notification {
  id
  __typename
  viewedAt
}
    `;
export const TimelineMealForGroupByDateFragmentDoc = gql`
    fragment TimelineMealForGroupByDate on TimelineMeal {
  id
  start
  timezone
  notifications {
    ...NotificationTimeline
  }
}
    ${NotificationTimelineFragmentDoc}`;
export const MealTemplateSharedTimelineFragmentDoc = gql`
    fragment MealTemplateSharedTimeline on MealTemplate {
  id
  meal {
    id
    name
    start
    end
    type
  }
  ... on MacroMealTemplate {
    __typename
  }
  ... on ExchangeMealTemplate {
    __typename
  }
}
    `;
export const ActivityTemplateSharedTimelineFragmentDoc = gql`
    fragment ActivityTemplateSharedTimeline on ActivityTemplate {
  id
  __typename
  activity {
    id
    name
    start
    end
    type
  }
}
    `;
export const MealPlanSharedTimelineFragmentDoc = gql`
    fragment MealPlanSharedTimeline on MealPlan {
  id
  mealTemplates {
    ...MealTemplateSharedTimeline
  }
  activityTemplates {
    ...ActivityTemplateSharedTimeline
  }
}
    ${MealTemplateSharedTimelineFragmentDoc}
${ActivityTemplateSharedTimelineFragmentDoc}`;
export const MealPlanDateSharedTimelineFragmentDoc = gql`
    fragment MealPlanDateSharedTimeline on MealPlanDate {
  __typename
  date
  timezone
  mealPlan {
    ...MealPlanSharedTimeline
  }
}
    ${MealPlanSharedTimelineFragmentDoc}`;
export const MealMenuSharedTimelineFragmentDoc = gql`
    fragment MealMenuSharedTimeline on MealMenu {
  id
  __typename
  mealType: type
  timezone
  name
  start
  durationInMinutes
  prepTimeInMinutes
  lastOrderTimeBeforeEndInMinutes
  isLogOnly
  isInHouseMenuOrderable
  isRestaurantMenuOrderable
  isViewOnlyForMe
  isBulkOrderable
  restaurantMenuLinks {
    id
    allowCustomOrders
    allowPlateOrders
    restaurant {
      id
      name
    }
  }
}
    `;
export const RestaurantMenuLogSharedTimelineFragmentDoc = gql`
    fragment RestaurantMenuLogSharedTimeline on RestaurantMenuLog {
  id
  __typename
  mealType: type
  dateTime
  timezone
  items {
    id
    ...MenuSelectionItemForm
  }
}
    ${MenuSelectionItemFormFragmentDoc}`;
export const MealMenuWithTimesFragmentDoc = gql`
    fragment MealMenuWithTimes on MealMenu {
  id
  timezone
  name
  start
  durationInMinutes
  prepTimeInMinutes
  lastOrderTimeBeforeEndInMinutes
  isViewOnlyForMe
  theme
}
    `;
export const RestaurantMenuLinkPlateOrderSharedTimelineFragmentDoc = gql`
    fragment RestaurantMenuLinkPlateOrderSharedTimeline on RestaurantMenuLinkPlateOrder {
  ...RestaurantMenuLinkPlateOrder
  mealMenu {
    ...MealMenuWithTimes
  }
  restaurantMenuLink {
    id
    restaurant {
      id
      name
    }
  }
}
    ${RestaurantMenuLinkPlateOrderFragmentDoc}
${MealMenuWithTimesFragmentDoc}`;
export const RestaurantMenuLinkOrderSharedTimelineFragmentDoc = gql`
    fragment RestaurantMenuLinkOrderSharedTimeline on RestaurantMenuLinkOrder {
  ...RestaurantMenuLinkOrder
  mealMenu {
    id
  }
  restaurantMenuLink {
    id
    restaurant {
      id
      name
    }
  }
}
    ${RestaurantMenuLinkOrderFragmentDoc}`;
export const MenuOrderSharedTimelineFragmentDoc = gql`
    fragment MenuOrderSharedTimeline on MenuOrder {
  id
  __typename
  mealMenu {
    id
  }
}
    `;
export const MealMenuLogSharedTimelineFragmentDoc = gql`
    fragment MealMenuLogSharedTimeline on MealMenuLog {
  id
  __typename
  mealMenu {
    id
  }
}
    `;
export const TimelineMealSharedTimelineFragmentDoc = gql`
    fragment TimelineMealSharedTimeline on TimelineMeal {
  id
  __typename
  name
  type
  timezone
  start
  durationInMinutes
  mealTemplate {
    id
  }
  mealMenus {
    ...MealMenuSharedTimeline
  }
  servingAmounts {
    ...FullServingAmount
  }
  restaurantMenuLogs {
    ...RestaurantMenuLogSharedTimeline
  }
  restaurantMenuLinkPlateOrders {
    ...RestaurantMenuLinkPlateOrderSharedTimeline
  }
  restaurantMenuLinkOrders {
    ...RestaurantMenuLinkOrderSharedTimeline
  }
  menuOrders {
    ...MenuOrderSharedTimeline
  }
  mealMenuLogs {
    ...MealMenuLogSharedTimeline
  }
}
    ${MealMenuSharedTimelineFragmentDoc}
${FullServingAmountFragmentDoc}
${RestaurantMenuLogSharedTimelineFragmentDoc}
${RestaurantMenuLinkPlateOrderSharedTimelineFragmentDoc}
${RestaurantMenuLinkOrderSharedTimelineFragmentDoc}
${MenuOrderSharedTimelineFragmentDoc}
${MealMenuLogSharedTimelineFragmentDoc}`;
export const FoodLogActivitySharedTimelineFragmentDoc = gql`
    fragment FoodLogActivitySharedTimeline on FoodLogActivity {
  id
  __typename
  name
  activityType: type
  startTime
  endTime
  timezone
  activityTemplate {
    id
  }
}
    `;
export const UnitFragmentDoc = gql`
    fragment Unit on Unit {
  id
  name
}
    `;
export const UnitWithConversionsFragmentDoc = gql`
    fragment UnitWithConversions on Unit {
  id
  name
  conversionsFrom {
    id
    fromQuantity
    toQuantity
    toUnit {
      ...Unit
      isProductionOnly
    }
  }
}
    ${UnitFragmentDoc}`;
export const AthleteFragmentDoc = gql`
    fragment Athlete on Athlete {
  id
  __typename
  firstName
  lastName
  sex
  birthDate
  phoneNumber
  email
  headshotUrl
  secaUid
  inbodyUid
  isArchived
  activationLinkSent
  jerseyNumber
  isProfileNotemealOnly
}
    `;
export const MealPlanFragmentDoc = gql`
    fragment MealPlan on MealPlan {
  id
  __typename
  name
  isShared
  isAutoSuggestionsEnabled
  description
  usesDateRange
  dates
  startDate
  endDate
  type
  createdAt
  updatedAt
  exchangeSet {
    id
  }
}
    `;
export const MacroMathFragmentDoc = gql`
    fragment MacroMath on MacroMath {
  gPerKg
  leftOverRatio
}
    `;
export const MacroProtocolFragmentDoc = gql`
    fragment MacroProtocol on MacroProtocol {
  id
  __typename
  cho {
    ...MacroMath
  }
  pro {
    ...MacroMath
  }
  fat {
    ...MacroMath
  }
  weightTarget
  weightTargetInKg
}
    ${MacroMathFragmentDoc}`;
export const AnthropometryEntryFragmentDoc = gql`
    fragment AnthropometryEntry on AnthropometryEntry {
  id
  __typename
  height
  weight
  leanBodyMass
  bodyFatMass
  percentBodyFat
  datetime
  type
  sex
  comment
  boneMineralDensity
  boneMineralDensityZScore
  boneMineralDensityTScore
  boneMineralContent
  boneArea
  boneMass
  dryLeanMass
  skeletalMuscleMass
  trunkFat
  visceralFatArea
  heightInCm
  weightInKg
  leanBodyMassInKg
  bodyFatMassInKg
  skeletalMuscleMassInKg
  trunkFatInKg
  dryLeanMassInKg
}
    `;
export const CalorieBudgetFragmentDoc = gql`
    fragment CalorieBudget on CalorieBudget {
  id
  __typename
  rmrMethod
  rmrCalories
  activityFactor
  kcalOffset
}
    `;
export const GoalFragmentDoc = gql`
    fragment Goal on Goal {
  id
  __typename
  kcalOffset
  start
  end
}
    `;
export const GoalTypeFragmentDoc = gql`
    fragment GoalType on GoalType {
  id
  __typename
  name
  defaultKcalOffset
}
    `;
export const FullGoalFragmentDoc = gql`
    fragment FullGoal on Goal {
  ...Goal
  type {
    ...GoalType
  }
}
    ${GoalFragmentDoc}
${GoalTypeFragmentDoc}`;
export const FullCalorieBudgetFragmentDoc = gql`
    fragment FullCalorieBudget on CalorieBudget {
  ...CalorieBudget
  goalSnapshot {
    ...FullGoal
  }
}
    ${CalorieBudgetFragmentDoc}
${FullGoalFragmentDoc}`;
export const FullMacroProtocolFragmentDoc = gql`
    fragment FullMacroProtocol on MacroProtocol {
  ...MacroProtocol
  anthropometrySnapshot {
    ...AnthropometryEntry
  }
  calorieBudget {
    ...FullCalorieBudget
  }
}
    ${MacroProtocolFragmentDoc}
${AnthropometryEntryFragmentDoc}
${FullCalorieBudgetFragmentDoc}`;
export const MealPlanSearchFormFragmentDoc = gql`
    fragment MealPlanSearchForm on MealPlan {
  ...MealPlan
  macroProtocol {
    ...FullMacroProtocol
  }
}
    ${MealPlanFragmentDoc}
${FullMacroProtocolFragmentDoc}`;
export const AthleteWithMealPlansFragmentDoc = gql`
    fragment AthleteWithMealPlans on Athlete {
  ...Athlete
  mealPlans {
    ...MealPlanSearchForm
  }
}
    ${AthleteFragmentDoc}
${MealPlanSearchFormFragmentDoc}`;
export const PositionFragmentDoc = gql`
    fragment Position on Position {
  id
  __typename
  name
}
    `;
export const AthleteWithGoalAndAnthroFragmentDoc = gql`
    fragment AthleteWithGoalAndAnthro on Athlete {
  ...Athlete
  position {
    ...Position
  }
  mostRecentAnthropometryEntry {
    ...AnthropometryEntry
  }
  currentGoal {
    ...FullGoal
  }
}
    ${AthleteFragmentDoc}
${PositionFragmentDoc}
${AnthropometryEntryFragmentDoc}
${FullGoalFragmentDoc}`;
export const ExchangeRatioFragmentDoc = gql`
    fragment ExchangeRatio on ExchangeRatio {
  __typename
  ratio
  exchange {
    ...Exchange
  }
}
    ${ExchangeFragmentDoc}`;
export const FoodOrRecipeExchangeRatiosFragmentDoc = gql`
    fragment FoodOrRecipeExchangeRatios on FoodOrRecipeExchangeRatios {
  id
  __typename
  foodOrRecipeId
  exchangeRatios {
    ...ExchangeRatio
  }
}
    ${ExchangeRatioFragmentDoc}`;
export const FoodPreviewFragmentDoc = gql`
    fragment FoodPreview on Food {
  id
  name
}
    `;
export const BrandedFoodFragmentDoc = gql`
    fragment BrandedFood on BrandedFood {
  id
  __typename
  name
  exchangeTypes
  usdaFdcDescription
  usdaFdcFoodCategoryId
  usdaFdcDataType
  usdaFdcId
  isRecipeIngredientOnly
  nixItemId
  nixTagId
  choPer100g
  proPer100g
  fatPer100g
  kcalPer100g
  thumbnailUrl
  groceryListCategory {
    id
    name
  }
}
    `;
export const GenericFoodFragmentDoc = gql`
    fragment GenericFood on GenericFood {
  id
  __typename
  name
  exchangeTypes
  nixItemId
  nixTagId
  usdaFdcDescription
  usdaFdcFoodCategoryId
  usdaFdcDataType
  usdaFdcId
  isRecipeIngredientOnly
  choPer100g
  proPer100g
  fatPer100g
  kcalPer100g
  thumbnailUrl
  groceryListCategory {
    id
    name
  }
  owner {
    id
    firstName
    lastName
  }
  orgGroup {
    id
    name
  }
}
    `;
export const FoodFragmentDoc = gql`
    fragment Food on Food {
  id
  __typename
  name
  exchangeTypes
  nixItemId
  nixTagId
  usdaFdcDescription
  usdaFdcFoodCategoryId
  usdaFdcDataType
  usdaFdcId
  isRecipeIngredientOnly
  choPer100g
  proPer100g
  fatPer100g
  kcalPer100g
  thumbnailUrl
  locales
}
    `;
export const BrandFragmentDoc = gql`
    fragment Brand on Brand {
  id
  __typename
  name
  usdaManufacturerName
  foodCount
}
    `;
export const FoodWithDefaultServingFragmentDoc = gql`
    fragment FoodWithDefaultServing on Food {
  ...Food
  defaultServing {
    ...FullServing
  }
  hasFullAccess
  org {
    id
    name
  }
  ... on BrandedFood {
    __typename
    brand {
      ...Brand
    }
  }
  ... on GenericFood {
    __typename
    orgGroup {
      id
      name
    }
  }
}
    ${FoodFragmentDoc}
${FullServingFragmentDoc}
${BrandFragmentDoc}`;
export const ServingFragmentDoc = gql`
    fragment Serving on Serving {
  id
  __typename
  units
  defaultAmount
  isDefault
  weight
  usdaWeightSeq
  perRecipeYield
  isRecipeServingOnly
  hasOwnNutrients
}
    `;
export const ServingWithFoodOrRecipeFragmentDoc = gql`
    fragment ServingWithFoodOrRecipe on Serving {
  ...Serving
  macros {
    cho
    pro
    fat
    kcal
  }
  customUnits
  unitPrefix
  unitSuffix
  unit {
    id
    name
  }
  foodOrRecipe {
    ... on Food {
      ...Food
      hasFullAccess
      org {
        id
        name
      }
      ... on BrandedFood {
        brand {
          id
          name
        }
      }
    }
    ... on Recipe {
      ...RecipeLimitedAccess
    }
  }
}
    ${ServingFragmentDoc}
${FoodFragmentDoc}
${RecipeLimitedAccessFragmentDoc}`;
export const FoodWithServingsFragmentDoc = gql`
    fragment FoodWithServings on Food {
  ...Food
  showGramServing
  showOunceServing
  servings {
    ...ServingWithFoodOrRecipe
  }
}
    ${FoodFragmentDoc}
${ServingWithFoodOrRecipeFragmentDoc}`;
export const FoodLogNotificationSettingsFragmentDoc = gql`
    fragment FoodLogNotificationSettings on FoodLogNotificationSettings {
  edits
  imageUploads
  messages
}
    `;
export const ExchangeSetFragmentDoc = gql`
    fragment ExchangeSet on ExchangeSet {
  id
  __typename
  name
  createdBy {
    id
    firstName
    lastName
  }
}
    `;
export const ExchangeSetWithExchangesFragmentDoc = gql`
    fragment ExchangeSetWithExchanges on ExchangeSet {
  ...ExchangeSet
  exchanges {
    ...Exchange
  }
}
    ${ExchangeSetFragmentDoc}
${ExchangeFragmentDoc}`;
export const BasicMealPlanFragmentDoc = gql`
    fragment BasicMealPlan on MealPlan {
  ...MealPlan
  exchangeSet {
    ...ExchangeSetWithExchanges
  }
}
    ${MealPlanFragmentDoc}
${ExchangeSetWithExchangesFragmentDoc}`;
export const CopyableMealPlanFragmentDoc = gql`
    fragment CopyableMealPlan on MealPlan {
  ...BasicMealPlan
  macroProtocol {
    ...MacroProtocol
    calorieBudget {
      ...CalorieBudget
    }
  }
}
    ${BasicMealPlanFragmentDoc}
${MacroProtocolFragmentDoc}
${CalorieBudgetFragmentDoc}`;
export const ScheduleFragmentDoc = gql`
    fragment Schedule on Schedule {
  id
  __typename
  name
  activityFactor
}
    `;
export const FullFoodGroupWithFoodsFragmentDoc = gql`
    fragment FullFoodGroupWithFoods on FoodGroup {
  id
  __typename
  name
  source
  foodsCount
  foods {
    ...FoodLimitedAccess
  }
}
    ${FoodLimitedAccessFragmentDoc}`;
export const EventFragmentDoc = gql`
    fragment Event on Event {
  __typename
  type
  datetime
}
    `;
export const EditExchangeAmountFragmentDoc = gql`
    fragment EditExchangeAmount on ExchangeAmount {
  ...ExchangeAmount
  amountInput @client
}
    ${ExchangeAmountFragmentDoc}`;
export const PreviewMealPlanFragmentDoc = gql`
    fragment PreviewMealPlan on MealPlan {
  ...BasicMealPlan
  macroProtocol {
    ...FullMacroProtocol
  }
  schedule {
    ...Schedule
  }
  avoidedFoodGroups {
    ...FullFoodGroupWithFoods
  }
  promotedFoodGroups {
    ...FullFoodGroupWithFoods
  }
  avoidedFoods {
    ...FoodLimitedAccess
  }
  promotedFoods {
    ...FoodLimitedAccess
  }
  mostRecentEvent {
    ...Event
  }
  exchangeTargets {
    ...EditExchangeAmount
  }
}
    ${BasicMealPlanFragmentDoc}
${FullMacroProtocolFragmentDoc}
${ScheduleFragmentDoc}
${FullFoodGroupWithFoodsFragmentDoc}
${FoodLimitedAccessFragmentDoc}
${EventFragmentDoc}
${EditExchangeAmountFragmentDoc}`;
export const EditMealPlanFragmentDoc = gql`
    fragment EditMealPlan on MealPlan {
  id
  isAutosaving @client
  exchangeTargets {
    ...EditExchangeAmount
  }
}
    ${EditExchangeAmountFragmentDoc}`;
export const FullFoodGroupFragmentDoc = gql`
    fragment FullFoodGroup on FoodGroup {
  id
  __typename
  name
  source
  foodsCount
}
    `;
export const AthleteWithFoodPrefsFragmentDoc = gql`
    fragment AthleteWithFoodPrefs on Athlete {
  ...Athlete
  likedFoods {
    ...Food
  }
  dislikedFoods {
    ...Food
  }
  likedFoodGroups {
    ...FullFoodGroup
  }
  dislikedFoodGroups {
    ...FullFoodGroup
  }
}
    ${AthleteFragmentDoc}
${FoodFragmentDoc}
${FullFoodGroupFragmentDoc}`;
export const MacroMealPlanDisplaySettingsFragmentDoc = gql`
    fragment MacroMealPlanDisplaySettings on MacroMealPlanDisplaySettings {
  __typename
  dailyProgressViews
  mealProgressViews
}
    `;
export const ExchangeMealPlanDisplaySettingsFragmentDoc = gql`
    fragment ExchangeMealPlanDisplaySettings on ExchangeMealPlanDisplaySettings {
  __typename
  dailyProgressViewsForExchanges
  mealProgressViewsForExchanges
  dailyProgressViewsForMacros
  mealProgressViewsForMacros
}
    `;
export const ExchangeServingListFragmentDoc = gql`
    fragment ExchangeServingList on ExchangeServingList {
  id
  __typename
  name
}
    `;
export const FullExchangeServingListFragmentDoc = gql`
    fragment FullExchangeServingList on ExchangeServingList {
  ...ExchangeServingList
  servingAmounts {
    ...FullServingAmount
  }
}
    ${ExchangeServingListFragmentDoc}
${FullServingAmountFragmentDoc}`;
export const FullExchangeFragmentDoc = gql`
    fragment FullExchange on Exchange {
  ...Exchange
  exchangeServingList {
    ...FullExchangeServingList
  }
}
    ${ExchangeFragmentDoc}
${FullExchangeServingListFragmentDoc}`;
export const FullExchangeSetWithoutExchangeRatiosFragmentDoc = gql`
    fragment FullExchangeSetWithoutExchangeRatios on ExchangeSet {
  ...ExchangeSet
  exchanges {
    ...FullExchange
  }
}
    ${ExchangeSetFragmentDoc}
${FullExchangeFragmentDoc}`;
export const FullExchangeSetFragmentDoc = gql`
    fragment FullExchangeSet on ExchangeSet {
  ...FullExchangeSetWithoutExchangeRatios
  foodOrRecipeExchangeRatios @client {
    id
    foodOrRecipeId
    __typename
    exchangeRatios {
      __typename
      ratio
      exchange {
        ...Exchange
      }
    }
  }
}
    ${FullExchangeSetWithoutExchangeRatiosFragmentDoc}
${ExchangeFragmentDoc}`;
export const ActivityFragmentDoc = gql`
    fragment Activity on Activity {
  id
  __typename
  name
  start
  end
  type
}
    `;
export const ActivityTemplateFragmentDoc = gql`
    fragment ActivityTemplate on ActivityTemplate {
  id
  __typename
  activity {
    ...Activity
  }
}
    ${ActivityFragmentDoc}`;
export const MealTemplateFragmentDoc = gql`
    fragment MealTemplate on MealTemplate {
  id
  __typename
  meal {
    ...Meal
  }
}
    ${MealFragmentDoc}`;
export const EditMealTemplateFragmentDoc = gql`
    fragment EditMealTemplate on MealTemplate {
  id
  isAutosaving @client
  selectedMealOptionId @client
  newMealOptionIds @client
}
    `;
export const MealOptionFragmentDoc = gql`
    fragment MealOption on MealOption {
  id
  __typename
  note
  position
  name
}
    `;
export const FullMealOptionFragmentDoc = gql`
    fragment FullMealOption on MealOption {
  ...MealOption
  servingAmounts {
    ...FullServingAmount
  }
}
    ${MealOptionFragmentDoc}
${FullServingAmountFragmentDoc}`;
export const EditMealOptionFragmentDoc = gql`
    fragment EditMealOption on MealOption {
  id
  isAutosaving @client
}
    `;
export const EditFullMealOptionFragmentDoc = gql`
    fragment EditFullMealOption on MealOption {
  ...FullMealOption
  ...EditMealOption
}
    ${FullMealOptionFragmentDoc}
${EditMealOptionFragmentDoc}`;
export const MealOptionPendingFragmentDoc = gql`
    fragment MealOptionPending on MealOptionPending {
  id
  __typename
  note
  name
}
    `;
export const FullMealOptionPendingFragmentDoc = gql`
    fragment FullMealOptionPending on MealOptionPending {
  ...MealOptionPending
  servingAmounts {
    ...FullServingAmount
  }
}
    ${MealOptionPendingFragmentDoc}
${FullServingAmountFragmentDoc}`;
export const EditMealOptionPendingFragmentDoc = gql`
    fragment EditMealOptionPending on MealOptionPending {
  id
  isAutosaving @client
}
    `;
export const EditFullMealOptionPendingFragmentDoc = gql`
    fragment EditFullMealOptionPending on MealOptionPending {
  ...FullMealOptionPending
  ...EditMealOptionPending
}
    ${FullMealOptionPendingFragmentDoc}
${EditMealOptionPendingFragmentDoc}`;
export const EditFullMealTemplateFragmentDoc = gql`
    fragment EditFullMealTemplate on MealTemplate {
  ...MealTemplate
  ...EditMealTemplate
  mealOptions {
    ...EditFullMealOption
  }
  pendingMealOptions {
    ...EditFullMealOptionPending
  }
}
    ${MealTemplateFragmentDoc}
${EditMealTemplateFragmentDoc}
${EditFullMealOptionFragmentDoc}
${EditFullMealOptionPendingFragmentDoc}`;
export const MacroMealTemplateFragmentDoc = gql`
    fragment MacroMealTemplate on MacroMealTemplate {
  ...MealTemplate
  __typename
  cho
  pro
  fat
  kcal
}
    ${MealTemplateFragmentDoc}`;
export const EditMacroMealTemplateFragmentDoc = gql`
    fragment EditMacroMealTemplate on MacroMealTemplate {
  ...EditMealTemplate
  choInput @client
  proInput @client
  fatInput @client
}
    ${EditMealTemplateFragmentDoc}`;
export const EditFullMacroMealTemplateFragmentDoc = gql`
    fragment EditFullMacroMealTemplate on MacroMealTemplate {
  ...EditFullMealTemplate
  ...MacroMealTemplate
  ...EditMacroMealTemplate
}
    ${EditFullMealTemplateFragmentDoc}
${MacroMealTemplateFragmentDoc}
${EditMacroMealTemplateFragmentDoc}`;
export const ExchangeMealTemplateFragmentDoc = gql`
    fragment ExchangeMealTemplate on ExchangeMealTemplate {
  ...MealTemplate
  __typename
}
    ${MealTemplateFragmentDoc}`;
export const EditExchangeMealTemplateFragmentDoc = gql`
    fragment EditExchangeMealTemplate on ExchangeMealTemplate {
  ...EditMealTemplate
  exchangeTargets {
    ...EditExchangeAmount
  }
}
    ${EditMealTemplateFragmentDoc}
${EditExchangeAmountFragmentDoc}`;
export const EditFullExchangeMealTemplateFragmentDoc = gql`
    fragment EditFullExchangeMealTemplate on ExchangeMealTemplate {
  ...EditFullMealTemplate
  ...ExchangeMealTemplate
  ...EditExchangeMealTemplate
}
    ${EditFullMealTemplateFragmentDoc}
${ExchangeMealTemplateFragmentDoc}
${EditExchangeMealTemplateFragmentDoc}`;
export const EditFullMealPlanFragmentDoc = gql`
    fragment EditFullMealPlan on MealPlan {
  ...PreviewMealPlan
  ...EditMealPlan
  athlete {
    ...AthleteWithFoodPrefs
    macroDisplaySettings {
      ...MacroMealPlanDisplaySettings
    }
    exchangeDisplaySettings {
      ...ExchangeMealPlanDisplaySettings
    }
  }
  exchangeSet {
    ...FullExchangeSet
  }
  activityTemplates {
    ...ActivityTemplate
  }
  events {
    ...Event
  }
  mealTemplates {
    ... on MacroMealTemplate {
      ...EditFullMacroMealTemplate
    }
    ... on ExchangeMealTemplate {
      ...EditFullExchangeMealTemplate
    }
  }
}
    ${PreviewMealPlanFragmentDoc}
${EditMealPlanFragmentDoc}
${AthleteWithFoodPrefsFragmentDoc}
${MacroMealPlanDisplaySettingsFragmentDoc}
${ExchangeMealPlanDisplaySettingsFragmentDoc}
${FullExchangeSetFragmentDoc}
${ActivityTemplateFragmentDoc}
${EventFragmentDoc}
${EditFullMacroMealTemplateFragmentDoc}
${EditFullExchangeMealTemplateFragmentDoc}`;
export const FullMealTemplateFragmentDoc = gql`
    fragment FullMealTemplate on MealTemplate {
  ...MealTemplate
  mealOptions {
    ...FullMealOption
  }
  pendingMealOptions {
    ...FullMealOptionPending
  }
}
    ${MealTemplateFragmentDoc}
${FullMealOptionFragmentDoc}
${FullMealOptionPendingFragmentDoc}`;
export const FullMacroMealTemplateFragmentDoc = gql`
    fragment FullMacroMealTemplate on MacroMealTemplate {
  ...FullMealTemplate
  ...MacroMealTemplate
}
    ${FullMealTemplateFragmentDoc}
${MacroMealTemplateFragmentDoc}`;
export const FullExchangeMealTemplateFragmentDoc = gql`
    fragment FullExchangeMealTemplate on ExchangeMealTemplate {
  ...FullMealTemplate
  ...ExchangeMealTemplate
  exchangeTargets {
    ...ExchangeAmount
  }
}
    ${FullMealTemplateFragmentDoc}
${ExchangeMealTemplateFragmentDoc}
${ExchangeAmountFragmentDoc}`;
export const FullMealPlanFragmentDoc = gql`
    fragment FullMealPlan on MealPlan {
  ...PreviewMealPlan
  athlete {
    ...AthleteWithFoodPrefs
    macroDisplaySettings {
      ...MacroMealPlanDisplaySettings
    }
    exchangeDisplaySettings {
      ...ExchangeMealPlanDisplaySettings
    }
  }
  exchangeSet {
    ...FullExchangeSet
  }
  activityTemplates {
    ...ActivityTemplate
  }
  events {
    ...Event
  }
  mealTemplates {
    ... on MacroMealTemplate {
      ...FullMacroMealTemplate
    }
    ... on ExchangeMealTemplate {
      ...FullExchangeMealTemplate
    }
  }
}
    ${PreviewMealPlanFragmentDoc}
${AthleteWithFoodPrefsFragmentDoc}
${MacroMealPlanDisplaySettingsFragmentDoc}
${ExchangeMealPlanDisplaySettingsFragmentDoc}
${FullExchangeSetFragmentDoc}
${ActivityTemplateFragmentDoc}
${EventFragmentDoc}
${FullMacroMealTemplateFragmentDoc}
${FullExchangeMealTemplateFragmentDoc}`;
export const ExportMealTemplateFragmentDoc = gql`
    fragment ExportMealTemplate on MealTemplate {
  ...MealTemplate
  mealOptions {
    ...ViewMealOption
  }
}
    ${MealTemplateFragmentDoc}
${ViewMealOptionFragmentDoc}`;
export const NoteFragmentDoc = gql`
    fragment Note on Note {
  id
  __typename
  datetime
  content
}
    `;
export const NoteTypeFragmentDoc = gql`
    fragment NoteType on NoteType {
  id
  __typename
  name
}
    `;
export const NoteWithTypeFragmentDoc = gql`
    fragment NoteWithType on Note {
  ...Note
  type {
    ...NoteType
  }
}
    ${NoteFragmentDoc}
${NoteTypeFragmentDoc}`;
export const FullNoteFragmentDoc = gql`
    fragment FullNote on Note {
  ...NoteWithType
  athlete {
    ...Athlete
  }
}
    ${NoteWithTypeFragmentDoc}
${AthleteFragmentDoc}`;
export const NutrientDietaryReferenceIntakeFragmentDoc = gql`
    fragment NutrientDietaryReferenceIntake on NutrientDietaryReferenceIntake {
  id
  type
}
    `;
export const RecipeFoodCategoryFragmentDoc = gql`
    fragment RecipeFoodCategory on RecipeFoodCategory {
  category
}
    `;
export const RecipeFragmentDoc = gql`
    fragment Recipe on Recipe {
  id
  __typename
  name
  steps
  note
  exchangeTypes
  cho
  pro
  fat
  prepTimeInMinutes
  cookTimeInMinutes
  isCurrent
  images {
    ...Image
  }
  isShared
  createdBy {
    id
    firstName
    lastName
  }
  mealTypes
  score {
    ...ScoreAssignment
  }
  foodCategory {
    ...RecipeFoodCategory
  }
}
    ${ImageFragmentDoc}
${ScoreAssignmentFragmentDoc}
${RecipeFoodCategoryFragmentDoc}`;
export const ServingAmountWithFoodOrRecipeFragmentDoc = gql`
    fragment ServingAmountWithFoodOrRecipe on ServingAmount {
  id
  __typename
  amount
  position
  serving {
    ...ServingWithFoodOrRecipe
  }
}
    ${ServingWithFoodOrRecipeFragmentDoc}`;
export const RecipeWithIngredientsFragmentDoc = gql`
    fragment RecipeWithIngredients on Recipe {
  ...Recipe
  ingredients {
    ...ServingAmountWithFoodOrRecipe
  }
}
    ${RecipeFragmentDoc}
${ServingAmountWithFoodOrRecipeFragmentDoc}`;
export const RecipeWithServingsFragmentDoc = gql`
    fragment RecipeWithServings on Recipe {
  ...RecipeWithIngredients
  hasFullAccess
  descendantRecipes {
    ...RecipeWithIngredients
  }
  servings {
    ...ServingWithFoodOrRecipe
  }
}
    ${RecipeWithIngredientsFragmentDoc}
${ServingWithFoodOrRecipeFragmentDoc}`;
export const FullScheduleFragmentDoc = gql`
    fragment FullSchedule on Schedule {
  ...Schedule
  meals {
    ...Meal
  }
  activities {
    ...Activity
  }
  mealPlans {
    ...MealPlan
  }
}
    ${ScheduleFragmentDoc}
${MealFragmentDoc}
${ActivityFragmentDoc}
${MealPlanFragmentDoc}`;
export const ServingWithMacrosFragmentDoc = gql`
    fragment ServingWithMacros on Serving {
  ...Serving
  macros {
    cho
    pro
    fat
    kcal
  }
}
    ${ServingFragmentDoc}`;
export const ExcessiveServingFragmentDoc = gql`
    fragment ExcessiveServing on Serving {
  ...Serving
  customUnits
  unitPrefix
  unitSuffix
  unit {
    id
    name
  }
  macros {
    cho
    pro
    fat
    kcal
  }
  foodOrRecipe {
    ... on Food {
      ...Food
      ... on BrandedFood {
        brand {
          id
          name
        }
      }
    }
    ... on Recipe {
      ...RecipeWithIngredients
      descendantFoods {
        ...Food
      }
      descendantRecipes {
        ...RecipeWithIngredients
      }
    }
  }
}
    ${ServingFragmentDoc}
${FoodFragmentDoc}
${RecipeWithIngredientsFragmentDoc}`;
export const ExcessiveServingAmountFragmentDoc = gql`
    fragment ExcessiveServingAmount on ServingAmount {
  id
  __typename
  amount
  position
  serving {
    ...ExcessiveServing
  }
}
    ${ExcessiveServingFragmentDoc}`;
export const TeamFragmentDoc = gql`
    fragment Team on Team {
  id
  __typename
  gender
  name
}
    `;
export const SportFragmentDoc = gql`
    fragment Sport on Sport {
  id
  __typename
  name
}
    `;
export const FullSportFragmentDoc = gql`
    fragment FullSport on Sport {
  ...Sport
  positions {
    ...Position
  }
}
    ${SportFragmentDoc}
${PositionFragmentDoc}`;
export const TeamWithSportFragmentDoc = gql`
    fragment TeamWithSport on Team {
  ...Team
  sport {
    ...FullSport
  }
}
    ${TeamFragmentDoc}
${FullSportFragmentDoc}`;
export const UsdaFoodFragmentDoc = gql`
    fragment USDAFood on USDAFood {
  id
  __typename
  allHighlightFields
  brandOwner
  dataType
  description
  gtinUpc
  ingredients
  publishedDate
  score
}
    `;
export const UsdaFoodNutrientFragmentDoc = gql`
    fragment USDAFoodNutrient on USDAFoodNutrient {
  id
  amount
  dataPoints
  unitName
  max
  min
  median
  name
  footnote
  __typename
}
    `;
export const UsdaFoodServingFragmentDoc = gql`
    fragment USDAFoodServing on USDAFoodServing {
  __typename
  idx
  cho
  pro
  fat
  weight
  defaultAmount
  units
  isDefault
  usdaWeightSeq
}
    `;
export const UsdaFoodDetailFragmentDoc = gql`
    fragment USDAFoodDetail on USDAFoodDetail {
  __typename
  id
  usdaFdcFoodCategoryId
  usdaFdcDataType
  cho {
    ...USDAFoodNutrient
  }
  pro {
    ...USDAFoodNutrient
  }
  fat {
    ...USDAFoodNutrient
  }
  foodNutrients {
    ...USDAFoodNutrient
  }
  servings {
    ...USDAFoodServing
  }
}
    ${UsdaFoodNutrientFragmentDoc}
${UsdaFoodServingFragmentDoc}`;
export const AthleteWithAnthroFragmentDoc = gql`
    fragment AthleteWithAnthro on Athlete {
  ...Athlete
  position {
    ...Position
  }
  mostRecentAnthropometryEntry {
    ...AnthropometryEntry
  }
}
    ${AthleteFragmentDoc}
${PositionFragmentDoc}
${AnthropometryEntryFragmentDoc}`;
export const MealPlanWithAthleteFragmentDoc = gql`
    fragment MealPlanWithAthlete on MealPlan {
  ...MealPlanSearchForm
  athlete {
    ...Athlete
  }
}
    ${MealPlanSearchFormFragmentDoc}
${AthleteFragmentDoc}`;
export const SimpleFoodGroupFragmentDoc = gql`
    fragment SimpleFoodGroup on FoodGroup {
  id
  name
}
    `;
export const MealPlanBulkCopyTeamPreviewFragmentDoc = gql`
    fragment MealPlanBulkCopyTeamPreview on Team {
  name
  id
}
    `;
export const NutritionixCommonFoodFragmentDoc = gql`
    fragment NutritionixCommonFood on NutritionixCommonFood {
  id
  __typename
  name
  nixTagId
  thumbnailUrl
}
    `;
export const NutritionixBrandedFoodFragmentDoc = gql`
    fragment NutritionixBrandedFood on NutritionixBrandedFood {
  id
  __typename
  name
  nixItemId
  brand {
    id
    __typename
    name
    type
  }
  thumbnailUrl
}
    `;
export const UnsubmittedAgreementFormForUserDocument = gql`
    query UnsubmittedAgreementFormForUser($orgId: ID!) {
  unsubmittedAgreementFormForUser(orgId: $orgId) {
    ...AgreementForm
  }
}
    ${AgreementFormFragmentDoc}`;

/**
 * __useUnsubmittedAgreementFormForUserQuery__
 *
 * To run a query within a React component, call `useUnsubmittedAgreementFormForUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnsubmittedAgreementFormForUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnsubmittedAgreementFormForUserQuery({
 *   variables: {
 *      orgId: // value for 'orgId'
 *   },
 * });
 */
export function useUnsubmittedAgreementFormForUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UnsubmittedAgreementFormForUserQuery, UnsubmittedAgreementFormForUserQueryVariables>) {
        return ApolloReactHooks.useQuery<UnsubmittedAgreementFormForUserQuery, UnsubmittedAgreementFormForUserQueryVariables>(UnsubmittedAgreementFormForUserDocument, baseOptions);
      }
export function useUnsubmittedAgreementFormForUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnsubmittedAgreementFormForUserQuery, UnsubmittedAgreementFormForUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UnsubmittedAgreementFormForUserQuery, UnsubmittedAgreementFormForUserQueryVariables>(UnsubmittedAgreementFormForUserDocument, baseOptions);
        }
export type UnsubmittedAgreementFormForUserQueryHookResult = ReturnType<typeof useUnsubmittedAgreementFormForUserQuery>;
export type UnsubmittedAgreementFormForUserLazyQueryHookResult = ReturnType<typeof useUnsubmittedAgreementFormForUserLazyQuery>;
export type UnsubmittedAgreementFormForUserQueryResult = ApolloReactCommon.QueryResult<UnsubmittedAgreementFormForUserQuery, UnsubmittedAgreementFormForUserQueryVariables>;
export const AddAgreementFormSubmissionDocument = gql`
    mutation AddAgreementFormSubmission($input: AddAgreementFormSubmissionInput!) {
  addAgreementFormSubmission(input: $input) {
    agreementForm {
      ...AgreementForm
    }
  }
}
    ${AgreementFormFragmentDoc}`;
export type AddAgreementFormSubmissionMutationFn = ApolloReactCommon.MutationFunction<AddAgreementFormSubmissionMutation, AddAgreementFormSubmissionMutationVariables>;

/**
 * __useAddAgreementFormSubmissionMutation__
 *
 * To run a mutation, you first call `useAddAgreementFormSubmissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAgreementFormSubmissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAgreementFormSubmissionMutation, { data, loading, error }] = useAddAgreementFormSubmissionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAgreementFormSubmissionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAgreementFormSubmissionMutation, AddAgreementFormSubmissionMutationVariables>) {
        return ApolloReactHooks.useMutation<AddAgreementFormSubmissionMutation, AddAgreementFormSubmissionMutationVariables>(AddAgreementFormSubmissionDocument, baseOptions);
      }
export type AddAgreementFormSubmissionMutationHookResult = ReturnType<typeof useAddAgreementFormSubmissionMutation>;
export type AddAgreementFormSubmissionMutationResult = ApolloReactCommon.MutationResult<AddAgreementFormSubmissionMutation>;
export type AddAgreementFormSubmissionMutationOptions = ApolloReactCommon.BaseMutationOptions<AddAgreementFormSubmissionMutation, AddAgreementFormSubmissionMutationVariables>;
export const RecipesByIdWithIngredientsDocument = gql`
    query RecipesByIdWithIngredients($ids: [ID!]!) {
  recipesById(ids: $ids) {
    ...RecipeWithServings
  }
}
    ${RecipeWithServingsFragmentDoc}`;

/**
 * __useRecipesByIdWithIngredientsQuery__
 *
 * To run a query within a React component, call `useRecipesByIdWithIngredientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipesByIdWithIngredientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipesByIdWithIngredientsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useRecipesByIdWithIngredientsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecipesByIdWithIngredientsQuery, RecipesByIdWithIngredientsQueryVariables>) {
        return ApolloReactHooks.useQuery<RecipesByIdWithIngredientsQuery, RecipesByIdWithIngredientsQueryVariables>(RecipesByIdWithIngredientsDocument, baseOptions);
      }
export function useRecipesByIdWithIngredientsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecipesByIdWithIngredientsQuery, RecipesByIdWithIngredientsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecipesByIdWithIngredientsQuery, RecipesByIdWithIngredientsQueryVariables>(RecipesByIdWithIngredientsDocument, baseOptions);
        }
export type RecipesByIdWithIngredientsQueryHookResult = ReturnType<typeof useRecipesByIdWithIngredientsQuery>;
export type RecipesByIdWithIngredientsLazyQueryHookResult = ReturnType<typeof useRecipesByIdWithIngredientsLazyQuery>;
export type RecipesByIdWithIngredientsQueryResult = ApolloReactCommon.QueryResult<RecipesByIdWithIngredientsQuery, RecipesByIdWithIngredientsQueryVariables>;
export const MyFeatureFlagsDocument = gql`
    query MyFeatureFlags {
  myFeatureFlags {
    ...FeatureFlags
  }
}
    ${FeatureFlagsFragmentDoc}`;

/**
 * __useMyFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useMyFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyFeatureFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyFeatureFlagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MyFeatureFlagsQuery, MyFeatureFlagsQueryVariables>) {
        return ApolloReactHooks.useQuery<MyFeatureFlagsQuery, MyFeatureFlagsQueryVariables>(MyFeatureFlagsDocument, baseOptions);
      }
export function useMyFeatureFlagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MyFeatureFlagsQuery, MyFeatureFlagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MyFeatureFlagsQuery, MyFeatureFlagsQueryVariables>(MyFeatureFlagsDocument, baseOptions);
        }
export type MyFeatureFlagsQueryHookResult = ReturnType<typeof useMyFeatureFlagsQuery>;
export type MyFeatureFlagsLazyQueryHookResult = ReturnType<typeof useMyFeatureFlagsLazyQuery>;
export type MyFeatureFlagsQueryResult = ApolloReactCommon.QueryResult<MyFeatureFlagsQuery, MyFeatureFlagsQueryVariables>;
export const MealMenuOrderRateLimitDocument = gql`
    query MealMenuOrderRateLimit($mealMenuId: ID!) {
  mealMenu(mealMenuId: $mealMenuId) {
    id
    orderRateLimit {
      limit
      excludedTeamIds
    }
  }
  menuOrderRateLimitRemainders(mealMenuId: $mealMenuId) {
    ...MenuOrderRateLimitRemainder
  }
}
    ${MenuOrderRateLimitRemainderFragmentDoc}`;

/**
 * __useMealMenuOrderRateLimitQuery__
 *
 * To run a query within a React component, call `useMealMenuOrderRateLimitQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealMenuOrderRateLimitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealMenuOrderRateLimitQuery({
 *   variables: {
 *      mealMenuId: // value for 'mealMenuId'
 *   },
 * });
 */
export function useMealMenuOrderRateLimitQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealMenuOrderRateLimitQuery, MealMenuOrderRateLimitQueryVariables>) {
        return ApolloReactHooks.useQuery<MealMenuOrderRateLimitQuery, MealMenuOrderRateLimitQueryVariables>(MealMenuOrderRateLimitDocument, baseOptions);
      }
export function useMealMenuOrderRateLimitLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealMenuOrderRateLimitQuery, MealMenuOrderRateLimitQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealMenuOrderRateLimitQuery, MealMenuOrderRateLimitQueryVariables>(MealMenuOrderRateLimitDocument, baseOptions);
        }
export type MealMenuOrderRateLimitQueryHookResult = ReturnType<typeof useMealMenuOrderRateLimitQuery>;
export type MealMenuOrderRateLimitLazyQueryHookResult = ReturnType<typeof useMealMenuOrderRateLimitLazyQuery>;
export type MealMenuOrderRateLimitQueryResult = ApolloReactCommon.QueryResult<MealMenuOrderRateLimitQuery, MealMenuOrderRateLimitQueryVariables>;
export const MenuOrderSuggestionsDocument = gql`
    query MenuOrderSuggestions($targetMacros: MacrosInput!, $mealMenuId: ID!, $avoidFoodGroupIds: [ID!]!, $limit: Int) {
  menuOrderSuggestions(
    targetMacros: $targetMacros
    mealMenuId: $mealMenuId
    limit: $limit
    avoidFoodGroupIds: $avoidFoodGroupIds
  ) {
    ...MenuOrderSuggestion
  }
}
    ${MenuOrderSuggestionFragmentDoc}`;

/**
 * __useMenuOrderSuggestionsQuery__
 *
 * To run a query within a React component, call `useMenuOrderSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useMenuOrderSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMenuOrderSuggestionsQuery({
 *   variables: {
 *      targetMacros: // value for 'targetMacros'
 *      mealMenuId: // value for 'mealMenuId'
 *      avoidFoodGroupIds: // value for 'avoidFoodGroupIds'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useMenuOrderSuggestionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MenuOrderSuggestionsQuery, MenuOrderSuggestionsQueryVariables>) {
        return ApolloReactHooks.useQuery<MenuOrderSuggestionsQuery, MenuOrderSuggestionsQueryVariables>(MenuOrderSuggestionsDocument, baseOptions);
      }
export function useMenuOrderSuggestionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MenuOrderSuggestionsQuery, MenuOrderSuggestionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MenuOrderSuggestionsQuery, MenuOrderSuggestionsQueryVariables>(MenuOrderSuggestionsDocument, baseOptions);
        }
export type MenuOrderSuggestionsQueryHookResult = ReturnType<typeof useMenuOrderSuggestionsQuery>;
export type MenuOrderSuggestionsLazyQueryHookResult = ReturnType<typeof useMenuOrderSuggestionsLazyQuery>;
export type MenuOrderSuggestionsQueryResult = ApolloReactCommon.QueryResult<MenuOrderSuggestionsQuery, MenuOrderSuggestionsQueryVariables>;
export const RestaurantMenuLinkSuggestionsDocument = gql`
    query RestaurantMenuLinkSuggestions($targetMacros: MacrosInput!, $restaurantMenuLinkId: ID!, $avoidFoodGroupIds: [ID!]!, $limit: Int) {
  restauranMenuLinkSuggestions(
    targetMacros: $targetMacros
    restaurantMenuLinkId: $restaurantMenuLinkId
    limit: $limit
    avoidFoodGroupIds: $avoidFoodGroupIds
  ) {
    ...MenuOrderSuggestion
  }
}
    ${MenuOrderSuggestionFragmentDoc}`;

/**
 * __useRestaurantMenuLinkSuggestionsQuery__
 *
 * To run a query within a React component, call `useRestaurantMenuLinkSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestaurantMenuLinkSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestaurantMenuLinkSuggestionsQuery({
 *   variables: {
 *      targetMacros: // value for 'targetMacros'
 *      restaurantMenuLinkId: // value for 'restaurantMenuLinkId'
 *      avoidFoodGroupIds: // value for 'avoidFoodGroupIds'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useRestaurantMenuLinkSuggestionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RestaurantMenuLinkSuggestionsQuery, RestaurantMenuLinkSuggestionsQueryVariables>) {
        return ApolloReactHooks.useQuery<RestaurantMenuLinkSuggestionsQuery, RestaurantMenuLinkSuggestionsQueryVariables>(RestaurantMenuLinkSuggestionsDocument, baseOptions);
      }
export function useRestaurantMenuLinkSuggestionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RestaurantMenuLinkSuggestionsQuery, RestaurantMenuLinkSuggestionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RestaurantMenuLinkSuggestionsQuery, RestaurantMenuLinkSuggestionsQueryVariables>(RestaurantMenuLinkSuggestionsDocument, baseOptions);
        }
export type RestaurantMenuLinkSuggestionsQueryHookResult = ReturnType<typeof useRestaurantMenuLinkSuggestionsQuery>;
export type RestaurantMenuLinkSuggestionsLazyQueryHookResult = ReturnType<typeof useRestaurantMenuLinkSuggestionsLazyQuery>;
export type RestaurantMenuLinkSuggestionsQueryResult = ApolloReactCommon.QueryResult<RestaurantMenuLinkSuggestionsQuery, RestaurantMenuLinkSuggestionsQueryVariables>;
export const RestaurantMenuSuggestionsDocument = gql`
    query RestaurantMenuSuggestions($targetMacros: MacrosInput!, $restaurantMenuId: ID!, $avoidFoodGroupIds: [ID!]!, $limit: Int) {
  restaurantMenuSuggestions(
    targetMacros: $targetMacros
    restaurantMenuId: $restaurantMenuId
    limit: $limit
    avoidFoodGroupIds: $avoidFoodGroupIds
  ) {
    ...MenuOrderSuggestion
  }
}
    ${MenuOrderSuggestionFragmentDoc}`;

/**
 * __useRestaurantMenuSuggestionsQuery__
 *
 * To run a query within a React component, call `useRestaurantMenuSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestaurantMenuSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestaurantMenuSuggestionsQuery({
 *   variables: {
 *      targetMacros: // value for 'targetMacros'
 *      restaurantMenuId: // value for 'restaurantMenuId'
 *      avoidFoodGroupIds: // value for 'avoidFoodGroupIds'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useRestaurantMenuSuggestionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RestaurantMenuSuggestionsQuery, RestaurantMenuSuggestionsQueryVariables>) {
        return ApolloReactHooks.useQuery<RestaurantMenuSuggestionsQuery, RestaurantMenuSuggestionsQueryVariables>(RestaurantMenuSuggestionsDocument, baseOptions);
      }
export function useRestaurantMenuSuggestionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RestaurantMenuSuggestionsQuery, RestaurantMenuSuggestionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RestaurantMenuSuggestionsQuery, RestaurantMenuSuggestionsQueryVariables>(RestaurantMenuSuggestionsDocument, baseOptions);
        }
export type RestaurantMenuSuggestionsQueryHookResult = ReturnType<typeof useRestaurantMenuSuggestionsQuery>;
export type RestaurantMenuSuggestionsLazyQueryHookResult = ReturnType<typeof useRestaurantMenuSuggestionsLazyQuery>;
export type RestaurantMenuSuggestionsQueryResult = ApolloReactCommon.QueryResult<RestaurantMenuSuggestionsQuery, RestaurantMenuSuggestionsQueryVariables>;
export const RecordMenuOrderSuggestionsViewDocument = gql`
    mutation RecordMenuOrderSuggestionsView($input: RecordMenuOrderSuggestionsViewInput!) {
  recordMenuOrderSuggestionsView(input: $input)
}
    `;
export type RecordMenuOrderSuggestionsViewMutationFn = ApolloReactCommon.MutationFunction<RecordMenuOrderSuggestionsViewMutation, RecordMenuOrderSuggestionsViewMutationVariables>;

/**
 * __useRecordMenuOrderSuggestionsViewMutation__
 *
 * To run a mutation, you first call `useRecordMenuOrderSuggestionsViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordMenuOrderSuggestionsViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordMenuOrderSuggestionsViewMutation, { data, loading, error }] = useRecordMenuOrderSuggestionsViewMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecordMenuOrderSuggestionsViewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RecordMenuOrderSuggestionsViewMutation, RecordMenuOrderSuggestionsViewMutationVariables>) {
        return ApolloReactHooks.useMutation<RecordMenuOrderSuggestionsViewMutation, RecordMenuOrderSuggestionsViewMutationVariables>(RecordMenuOrderSuggestionsViewDocument, baseOptions);
      }
export type RecordMenuOrderSuggestionsViewMutationHookResult = ReturnType<typeof useRecordMenuOrderSuggestionsViewMutation>;
export type RecordMenuOrderSuggestionsViewMutationResult = ApolloReactCommon.MutationResult<RecordMenuOrderSuggestionsViewMutation>;
export type RecordMenuOrderSuggestionsViewMutationOptions = ApolloReactCommon.BaseMutationOptions<RecordMenuOrderSuggestionsViewMutation, RecordMenuOrderSuggestionsViewMutationVariables>;
export const RecordMenuOrderSuggestionDecisionDocument = gql`
    mutation RecordMenuOrderSuggestionDecision($input: RecordMenuOrderSuggestionDecisionInput!) {
  recordMenuOrderSuggestionDecision(input: $input)
}
    `;
export type RecordMenuOrderSuggestionDecisionMutationFn = ApolloReactCommon.MutationFunction<RecordMenuOrderSuggestionDecisionMutation, RecordMenuOrderSuggestionDecisionMutationVariables>;

/**
 * __useRecordMenuOrderSuggestionDecisionMutation__
 *
 * To run a mutation, you first call `useRecordMenuOrderSuggestionDecisionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecordMenuOrderSuggestionDecisionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recordMenuOrderSuggestionDecisionMutation, { data, loading, error }] = useRecordMenuOrderSuggestionDecisionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecordMenuOrderSuggestionDecisionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RecordMenuOrderSuggestionDecisionMutation, RecordMenuOrderSuggestionDecisionMutationVariables>) {
        return ApolloReactHooks.useMutation<RecordMenuOrderSuggestionDecisionMutation, RecordMenuOrderSuggestionDecisionMutationVariables>(RecordMenuOrderSuggestionDecisionDocument, baseOptions);
      }
export type RecordMenuOrderSuggestionDecisionMutationHookResult = ReturnType<typeof useRecordMenuOrderSuggestionDecisionMutation>;
export type RecordMenuOrderSuggestionDecisionMutationResult = ApolloReactCommon.MutationResult<RecordMenuOrderSuggestionDecisionMutation>;
export type RecordMenuOrderSuggestionDecisionMutationOptions = ApolloReactCommon.BaseMutationOptions<RecordMenuOrderSuggestionDecisionMutation, RecordMenuOrderSuggestionDecisionMutationVariables>;
export const NutrientsWithDriDocument = gql`
    query NutrientsWithDri($age: Int, $sex: SexType, $kcal: Float) {
  nutrients {
    ...NutrientWithDri
  }
}
    ${NutrientWithDriFragmentDoc}`;

/**
 * __useNutrientsWithDriQuery__
 *
 * To run a query within a React component, call `useNutrientsWithDriQuery` and pass it any options that fit your needs.
 * When your component renders, `useNutrientsWithDriQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNutrientsWithDriQuery({
 *   variables: {
 *      age: // value for 'age'
 *      sex: // value for 'sex'
 *      kcal: // value for 'kcal'
 *   },
 * });
 */
export function useNutrientsWithDriQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NutrientsWithDriQuery, NutrientsWithDriQueryVariables>) {
        return ApolloReactHooks.useQuery<NutrientsWithDriQuery, NutrientsWithDriQueryVariables>(NutrientsWithDriDocument, baseOptions);
      }
export function useNutrientsWithDriLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NutrientsWithDriQuery, NutrientsWithDriQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NutrientsWithDriQuery, NutrientsWithDriQueryVariables>(NutrientsWithDriDocument, baseOptions);
        }
export type NutrientsWithDriQueryHookResult = ReturnType<typeof useNutrientsWithDriQuery>;
export type NutrientsWithDriLazyQueryHookResult = ReturnType<typeof useNutrientsWithDriLazyQuery>;
export type NutrientsWithDriQueryResult = ApolloReactCommon.QueryResult<NutrientsWithDriQuery, NutrientsWithDriQueryVariables>;
export const ServingsWithNutrientAmountsDocument = gql`
    query ServingsWithNutrientAmounts($servingIds: [ID!]!) {
  servings(ids: $servingIds) {
    ...ServingWithNutrientAmounts
  }
}
    ${ServingWithNutrientAmountsFragmentDoc}`;

/**
 * __useServingsWithNutrientAmountsQuery__
 *
 * To run a query within a React component, call `useServingsWithNutrientAmountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useServingsWithNutrientAmountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServingsWithNutrientAmountsQuery({
 *   variables: {
 *      servingIds: // value for 'servingIds'
 *   },
 * });
 */
export function useServingsWithNutrientAmountsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ServingsWithNutrientAmountsQuery, ServingsWithNutrientAmountsQueryVariables>) {
        return ApolloReactHooks.useQuery<ServingsWithNutrientAmountsQuery, ServingsWithNutrientAmountsQueryVariables>(ServingsWithNutrientAmountsDocument, baseOptions);
      }
export function useServingsWithNutrientAmountsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ServingsWithNutrientAmountsQuery, ServingsWithNutrientAmountsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ServingsWithNutrientAmountsQuery, ServingsWithNutrientAmountsQueryVariables>(ServingsWithNutrientAmountsDocument, baseOptions);
        }
export type ServingsWithNutrientAmountsQueryHookResult = ReturnType<typeof useServingsWithNutrientAmountsQuery>;
export type ServingsWithNutrientAmountsLazyQueryHookResult = ReturnType<typeof useServingsWithNutrientAmountsLazyQuery>;
export type ServingsWithNutrientAmountsQueryResult = ApolloReactCommon.QueryResult<ServingsWithNutrientAmountsQuery, ServingsWithNutrientAmountsQueryVariables>;
export const ConvertedServingDocument = gql`
    query ConvertedServing($input: ConvertedServingInput!) {
  convertedServing(input: $input) {
    ...ServingWithFoodOrRecipe
  }
}
    ${ServingWithFoodOrRecipeFragmentDoc}`;

/**
 * __useConvertedServingQuery__
 *
 * To run a query within a React component, call `useConvertedServingQuery` and pass it any options that fit your needs.
 * When your component renders, `useConvertedServingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConvertedServingQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useConvertedServingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ConvertedServingQuery, ConvertedServingQueryVariables>) {
        return ApolloReactHooks.useQuery<ConvertedServingQuery, ConvertedServingQueryVariables>(ConvertedServingDocument, baseOptions);
      }
export function useConvertedServingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConvertedServingQuery, ConvertedServingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ConvertedServingQuery, ConvertedServingQueryVariables>(ConvertedServingDocument, baseOptions);
        }
export type ConvertedServingQueryHookResult = ReturnType<typeof useConvertedServingQuery>;
export type ConvertedServingLazyQueryHookResult = ReturnType<typeof useConvertedServingLazyQuery>;
export type ConvertedServingQueryResult = ApolloReactCommon.QueryResult<ConvertedServingQuery, ConvertedServingQueryVariables>;
export const UnitsDocument = gql`
    query Units {
  units {
    ...Unit
  }
}
    ${UnitFragmentDoc}`;

/**
 * __useUnitsQuery__
 *
 * To run a query within a React component, call `useUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnitsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UnitsQuery, UnitsQueryVariables>) {
        return ApolloReactHooks.useQuery<UnitsQuery, UnitsQueryVariables>(UnitsDocument, baseOptions);
      }
export function useUnitsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnitsQuery, UnitsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UnitsQuery, UnitsQueryVariables>(UnitsDocument, baseOptions);
        }
export type UnitsQueryHookResult = ReturnType<typeof useUnitsQuery>;
export type UnitsLazyQueryHookResult = ReturnType<typeof useUnitsLazyQuery>;
export type UnitsQueryResult = ApolloReactCommon.QueryResult<UnitsQuery, UnitsQueryVariables>;
export const UnitConversionsFromDocument = gql`
    query UnitConversionsFrom($id: ID!) {
  unit(id: $id) {
    ...UnitWithConversions
  }
}
    ${UnitWithConversionsFragmentDoc}`;

/**
 * __useUnitConversionsFromQuery__
 *
 * To run a query within a React component, call `useUnitConversionsFromQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitConversionsFromQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitConversionsFromQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnitConversionsFromQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UnitConversionsFromQuery, UnitConversionsFromQueryVariables>) {
        return ApolloReactHooks.useQuery<UnitConversionsFromQuery, UnitConversionsFromQueryVariables>(UnitConversionsFromDocument, baseOptions);
      }
export function useUnitConversionsFromLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnitConversionsFromQuery, UnitConversionsFromQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UnitConversionsFromQuery, UnitConversionsFromQueryVariables>(UnitConversionsFromDocument, baseOptions);
        }
export type UnitConversionsFromQueryHookResult = ReturnType<typeof useUnitConversionsFromQuery>;
export type UnitConversionsFromLazyQueryHookResult = ReturnType<typeof useUnitConversionsFromLazyQuery>;
export type UnitConversionsFromQueryResult = ApolloReactCommon.QueryResult<UnitConversionsFromQuery, UnitConversionsFromQueryVariables>;
export const UnitsWithConversionsDocument = gql`
    query UnitsWithConversions {
  units {
    ...UnitWithConversions
  }
}
    ${UnitWithConversionsFragmentDoc}`;

/**
 * __useUnitsWithConversionsQuery__
 *
 * To run a query within a React component, call `useUnitsWithConversionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitsWithConversionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnitsWithConversionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnitsWithConversionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UnitsWithConversionsQuery, UnitsWithConversionsQueryVariables>) {
        return ApolloReactHooks.useQuery<UnitsWithConversionsQuery, UnitsWithConversionsQueryVariables>(UnitsWithConversionsDocument, baseOptions);
      }
export function useUnitsWithConversionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UnitsWithConversionsQuery, UnitsWithConversionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UnitsWithConversionsQuery, UnitsWithConversionsQueryVariables>(UnitsWithConversionsDocument, baseOptions);
        }
export type UnitsWithConversionsQueryHookResult = ReturnType<typeof useUnitsWithConversionsQuery>;
export type UnitsWithConversionsLazyQueryHookResult = ReturnType<typeof useUnitsWithConversionsLazyQuery>;
export type UnitsWithConversionsQueryResult = ApolloReactCommon.QueryResult<UnitsWithConversionsQuery, UnitsWithConversionsQueryVariables>;
export const GetUserLocaleDocument = gql`
    query GetUserLocale {
  myUser {
    id
    locale {
      id
      code
    }
  }
}
    `;

/**
 * __useGetUserLocaleQuery__
 *
 * To run a query within a React component, call `useGetUserLocaleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserLocaleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserLocaleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserLocaleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUserLocaleQuery, GetUserLocaleQueryVariables>) {
        return ApolloReactHooks.useQuery<GetUserLocaleQuery, GetUserLocaleQueryVariables>(GetUserLocaleDocument, baseOptions);
      }
export function useGetUserLocaleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserLocaleQuery, GetUserLocaleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetUserLocaleQuery, GetUserLocaleQueryVariables>(GetUserLocaleDocument, baseOptions);
        }
export type GetUserLocaleQueryHookResult = ReturnType<typeof useGetUserLocaleQuery>;
export type GetUserLocaleLazyQueryHookResult = ReturnType<typeof useGetUserLocaleLazyQuery>;
export type GetUserLocaleQueryResult = ApolloReactCommon.QueryResult<GetUserLocaleQuery, GetUserLocaleQueryVariables>;
export const RecipeWithFullIngredientsDocument = gql`
    query RecipeWithFullIngredients($id: ID!) {
  recipe(id: $id) {
    id
    ingredients {
      ...FullServingAmount
    }
  }
}
    ${FullServingAmountFragmentDoc}`;

/**
 * __useRecipeWithFullIngredientsQuery__
 *
 * To run a query within a React component, call `useRecipeWithFullIngredientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipeWithFullIngredientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipeWithFullIngredientsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRecipeWithFullIngredientsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecipeWithFullIngredientsQuery, RecipeWithFullIngredientsQueryVariables>) {
        return ApolloReactHooks.useQuery<RecipeWithFullIngredientsQuery, RecipeWithFullIngredientsQueryVariables>(RecipeWithFullIngredientsDocument, baseOptions);
      }
export function useRecipeWithFullIngredientsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecipeWithFullIngredientsQuery, RecipeWithFullIngredientsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecipeWithFullIngredientsQuery, RecipeWithFullIngredientsQueryVariables>(RecipeWithFullIngredientsDocument, baseOptions);
        }
export type RecipeWithFullIngredientsQueryHookResult = ReturnType<typeof useRecipeWithFullIngredientsQuery>;
export type RecipeWithFullIngredientsLazyQueryHookResult = ReturnType<typeof useRecipeWithFullIngredientsLazyQuery>;
export type RecipeWithFullIngredientsQueryResult = ApolloReactCommon.QueryResult<RecipeWithFullIngredientsQuery, RecipeWithFullIngredientsQueryVariables>;
export const AddAthleteDislikedFoodDocument = gql`
    mutation AddAthleteDislikedFood($input: AthleteFoodPreferenceInput!) {
  addAthleteDislikedFood(input: $input) {
    athlete {
      id
      dislikedFoods {
        ...Food
      }
    }
  }
}
    ${FoodFragmentDoc}`;
export type AddAthleteDislikedFoodMutationFn = ApolloReactCommon.MutationFunction<AddAthleteDislikedFoodMutation, AddAthleteDislikedFoodMutationVariables>;

/**
 * __useAddAthleteDislikedFoodMutation__
 *
 * To run a mutation, you first call `useAddAthleteDislikedFoodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAthleteDislikedFoodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAthleteDislikedFoodMutation, { data, loading, error }] = useAddAthleteDislikedFoodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAthleteDislikedFoodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAthleteDislikedFoodMutation, AddAthleteDislikedFoodMutationVariables>) {
        return ApolloReactHooks.useMutation<AddAthleteDislikedFoodMutation, AddAthleteDislikedFoodMutationVariables>(AddAthleteDislikedFoodDocument, baseOptions);
      }
export type AddAthleteDislikedFoodMutationHookResult = ReturnType<typeof useAddAthleteDislikedFoodMutation>;
export type AddAthleteDislikedFoodMutationResult = ApolloReactCommon.MutationResult<AddAthleteDislikedFoodMutation>;
export type AddAthleteDislikedFoodMutationOptions = ApolloReactCommon.BaseMutationOptions<AddAthleteDislikedFoodMutation, AddAthleteDislikedFoodMutationVariables>;
export const AddAthleteDislikedFoodGroupDocument = gql`
    mutation AddAthleteDislikedFoodGroup($input: AthleteFoodGroupPreferenceInput!) {
  addAthleteDislikedFoodGroup(input: $input) {
    athlete {
      id
      dislikedFoodGroups {
        ...FullFoodGroup
      }
    }
  }
}
    ${FullFoodGroupFragmentDoc}`;
export type AddAthleteDislikedFoodGroupMutationFn = ApolloReactCommon.MutationFunction<AddAthleteDislikedFoodGroupMutation, AddAthleteDislikedFoodGroupMutationVariables>;

/**
 * __useAddAthleteDislikedFoodGroupMutation__
 *
 * To run a mutation, you first call `useAddAthleteDislikedFoodGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAthleteDislikedFoodGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAthleteDislikedFoodGroupMutation, { data, loading, error }] = useAddAthleteDislikedFoodGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAthleteDislikedFoodGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAthleteDislikedFoodGroupMutation, AddAthleteDislikedFoodGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<AddAthleteDislikedFoodGroupMutation, AddAthleteDislikedFoodGroupMutationVariables>(AddAthleteDislikedFoodGroupDocument, baseOptions);
      }
export type AddAthleteDislikedFoodGroupMutationHookResult = ReturnType<typeof useAddAthleteDislikedFoodGroupMutation>;
export type AddAthleteDislikedFoodGroupMutationResult = ApolloReactCommon.MutationResult<AddAthleteDislikedFoodGroupMutation>;
export type AddAthleteDislikedFoodGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<AddAthleteDislikedFoodGroupMutation, AddAthleteDislikedFoodGroupMutationVariables>;
export const AddAthleteEmailDocument = gql`
    mutation AddAthleteEmail($input: AddAthleteEmailInput!) {
  addAthleteEmail(input: $input) {
    athlete {
      ...Athlete
    }
  }
}
    ${AthleteFragmentDoc}`;
export type AddAthleteEmailMutationFn = ApolloReactCommon.MutationFunction<AddAthleteEmailMutation, AddAthleteEmailMutationVariables>;

/**
 * __useAddAthleteEmailMutation__
 *
 * To run a mutation, you first call `useAddAthleteEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAthleteEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAthleteEmailMutation, { data, loading, error }] = useAddAthleteEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAthleteEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAthleteEmailMutation, AddAthleteEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<AddAthleteEmailMutation, AddAthleteEmailMutationVariables>(AddAthleteEmailDocument, baseOptions);
      }
export type AddAthleteEmailMutationHookResult = ReturnType<typeof useAddAthleteEmailMutation>;
export type AddAthleteEmailMutationResult = ApolloReactCommon.MutationResult<AddAthleteEmailMutation>;
export type AddAthleteEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<AddAthleteEmailMutation, AddAthleteEmailMutationVariables>;
export const AddAthleteLikedFoodDocument = gql`
    mutation AddAthleteLikedFood($input: AthleteFoodPreferenceInput!) {
  addAthleteLikedFood(input: $input) {
    athlete {
      id
      likedFoods {
        ...Food
      }
    }
  }
}
    ${FoodFragmentDoc}`;
export type AddAthleteLikedFoodMutationFn = ApolloReactCommon.MutationFunction<AddAthleteLikedFoodMutation, AddAthleteLikedFoodMutationVariables>;

/**
 * __useAddAthleteLikedFoodMutation__
 *
 * To run a mutation, you first call `useAddAthleteLikedFoodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAthleteLikedFoodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAthleteLikedFoodMutation, { data, loading, error }] = useAddAthleteLikedFoodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAthleteLikedFoodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAthleteLikedFoodMutation, AddAthleteLikedFoodMutationVariables>) {
        return ApolloReactHooks.useMutation<AddAthleteLikedFoodMutation, AddAthleteLikedFoodMutationVariables>(AddAthleteLikedFoodDocument, baseOptions);
      }
export type AddAthleteLikedFoodMutationHookResult = ReturnType<typeof useAddAthleteLikedFoodMutation>;
export type AddAthleteLikedFoodMutationResult = ApolloReactCommon.MutationResult<AddAthleteLikedFoodMutation>;
export type AddAthleteLikedFoodMutationOptions = ApolloReactCommon.BaseMutationOptions<AddAthleteLikedFoodMutation, AddAthleteLikedFoodMutationVariables>;
export const AddAthleteLikedFoodGroupDocument = gql`
    mutation AddAthleteLikedFoodGroup($input: AthleteFoodGroupPreferenceInput!) {
  addAthleteLikedFoodGroup(input: $input) {
    athlete {
      id
      likedFoodGroups {
        ...FullFoodGroup
      }
    }
  }
}
    ${FullFoodGroupFragmentDoc}`;
export type AddAthleteLikedFoodGroupMutationFn = ApolloReactCommon.MutationFunction<AddAthleteLikedFoodGroupMutation, AddAthleteLikedFoodGroupMutationVariables>;

/**
 * __useAddAthleteLikedFoodGroupMutation__
 *
 * To run a mutation, you first call `useAddAthleteLikedFoodGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAthleteLikedFoodGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAthleteLikedFoodGroupMutation, { data, loading, error }] = useAddAthleteLikedFoodGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAthleteLikedFoodGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAthleteLikedFoodGroupMutation, AddAthleteLikedFoodGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<AddAthleteLikedFoodGroupMutation, AddAthleteLikedFoodGroupMutationVariables>(AddAthleteLikedFoodGroupDocument, baseOptions);
      }
export type AddAthleteLikedFoodGroupMutationHookResult = ReturnType<typeof useAddAthleteLikedFoodGroupMutation>;
export type AddAthleteLikedFoodGroupMutationResult = ApolloReactCommon.MutationResult<AddAthleteLikedFoodGroupMutation>;
export type AddAthleteLikedFoodGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<AddAthleteLikedFoodGroupMutation, AddAthleteLikedFoodGroupMutationVariables>;
export const AddAthletePhoneNumberDocument = gql`
    mutation AddAthletePhoneNumber($input: AddAthletePhoneNumberInput!) {
  addAthletePhoneNumber(input: $input) {
    athlete {
      ...Athlete
    }
  }
}
    ${AthleteFragmentDoc}`;
export type AddAthletePhoneNumberMutationFn = ApolloReactCommon.MutationFunction<AddAthletePhoneNumberMutation, AddAthletePhoneNumberMutationVariables>;

/**
 * __useAddAthletePhoneNumberMutation__
 *
 * To run a mutation, you first call `useAddAthletePhoneNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAthletePhoneNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAthletePhoneNumberMutation, { data, loading, error }] = useAddAthletePhoneNumberMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAthletePhoneNumberMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAthletePhoneNumberMutation, AddAthletePhoneNumberMutationVariables>) {
        return ApolloReactHooks.useMutation<AddAthletePhoneNumberMutation, AddAthletePhoneNumberMutationVariables>(AddAthletePhoneNumberDocument, baseOptions);
      }
export type AddAthletePhoneNumberMutationHookResult = ReturnType<typeof useAddAthletePhoneNumberMutation>;
export type AddAthletePhoneNumberMutationResult = ApolloReactCommon.MutationResult<AddAthletePhoneNumberMutation>;
export type AddAthletePhoneNumberMutationOptions = ApolloReactCommon.BaseMutationOptions<AddAthletePhoneNumberMutation, AddAthletePhoneNumberMutationVariables>;
export const AddEventDocument = gql`
    mutation AddEvent($input: AddEventInput!) {
  addEvent(input: $input) {
    event {
      ...Event
    }
  }
}
    ${EventFragmentDoc}`;
export type AddEventMutationFn = ApolloReactCommon.MutationFunction<AddEventMutation, AddEventMutationVariables>;

/**
 * __useAddEventMutation__
 *
 * To run a mutation, you first call `useAddEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEventMutation, { data, loading, error }] = useAddEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddEventMutation, AddEventMutationVariables>) {
        return ApolloReactHooks.useMutation<AddEventMutation, AddEventMutationVariables>(AddEventDocument, baseOptions);
      }
export type AddEventMutationHookResult = ReturnType<typeof useAddEventMutation>;
export type AddEventMutationResult = ApolloReactCommon.MutationResult<AddEventMutation>;
export type AddEventMutationOptions = ApolloReactCommon.BaseMutationOptions<AddEventMutation, AddEventMutationVariables>;
export const AddMealOptionDocument = gql`
    mutation AddMealOption($input: AddMealOptionInput!) {
  addMealOption(input: $input) {
    mealOption {
      ...FullMealOption
    }
  }
}
    ${FullMealOptionFragmentDoc}`;
export type AddMealOptionMutationFn = ApolloReactCommon.MutationFunction<AddMealOptionMutation, AddMealOptionMutationVariables>;

/**
 * __useAddMealOptionMutation__
 *
 * To run a mutation, you first call `useAddMealOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMealOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMealOptionMutation, { data, loading, error }] = useAddMealOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddMealOptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddMealOptionMutation, AddMealOptionMutationVariables>) {
        return ApolloReactHooks.useMutation<AddMealOptionMutation, AddMealOptionMutationVariables>(AddMealOptionDocument, baseOptions);
      }
export type AddMealOptionMutationHookResult = ReturnType<typeof useAddMealOptionMutation>;
export type AddMealOptionMutationResult = ApolloReactCommon.MutationResult<AddMealOptionMutation>;
export type AddMealOptionMutationOptions = ApolloReactCommon.BaseMutationOptions<AddMealOptionMutation, AddMealOptionMutationVariables>;
export const ArchiveAthleteDocument = gql`
    mutation ArchiveAthlete($input: ArchiveAthleteInput!) {
  archiveAthlete(input: $input) {
    athlete {
      id
    }
  }
}
    `;
export type ArchiveAthleteMutationFn = ApolloReactCommon.MutationFunction<ArchiveAthleteMutation, ArchiveAthleteMutationVariables>;

/**
 * __useArchiveAthleteMutation__
 *
 * To run a mutation, you first call `useArchiveAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveAthleteMutation, { data, loading, error }] = useArchiveAthleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useArchiveAthleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ArchiveAthleteMutation, ArchiveAthleteMutationVariables>) {
        return ApolloReactHooks.useMutation<ArchiveAthleteMutation, ArchiveAthleteMutationVariables>(ArchiveAthleteDocument, baseOptions);
      }
export type ArchiveAthleteMutationHookResult = ReturnType<typeof useArchiveAthleteMutation>;
export type ArchiveAthleteMutationResult = ApolloReactCommon.MutationResult<ArchiveAthleteMutation>;
export type ArchiveAthleteMutationOptions = ApolloReactCommon.BaseMutationOptions<ArchiveAthleteMutation, ArchiveAthleteMutationVariables>;
export const CopyExchangeSetDocument = gql`
    mutation CopyExchangeSet($input: CopyExchangeSetInput!) {
  copyExchangeSet(input: $input) {
    exchangeSet {
      ...FullExchangeSet
    }
  }
}
    ${FullExchangeSetFragmentDoc}`;
export type CopyExchangeSetMutationFn = ApolloReactCommon.MutationFunction<CopyExchangeSetMutation, CopyExchangeSetMutationVariables>;

/**
 * __useCopyExchangeSetMutation__
 *
 * To run a mutation, you first call `useCopyExchangeSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyExchangeSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyExchangeSetMutation, { data, loading, error }] = useCopyExchangeSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCopyExchangeSetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CopyExchangeSetMutation, CopyExchangeSetMutationVariables>) {
        return ApolloReactHooks.useMutation<CopyExchangeSetMutation, CopyExchangeSetMutationVariables>(CopyExchangeSetDocument, baseOptions);
      }
export type CopyExchangeSetMutationHookResult = ReturnType<typeof useCopyExchangeSetMutation>;
export type CopyExchangeSetMutationResult = ApolloReactCommon.MutationResult<CopyExchangeSetMutation>;
export type CopyExchangeSetMutationOptions = ApolloReactCommon.BaseMutationOptions<CopyExchangeSetMutation, CopyExchangeSetMutationVariables>;
export const CreateOrgMembershipForAthleteDocument = gql`
    mutation CreateOrgMembershipForAthlete($input: CreateOrgMembershipForAthleteInput!) {
  createOrgMembershipForAthlete(input: $input) {
    athlete {
      id
      activationLinkSent
      user {
        id
        username
      }
      orgMembership {
        id
        isActive
      }
    }
    generatedSelfServiceActivationLink
  }
}
    `;
export type CreateOrgMembershipForAthleteMutationFn = ApolloReactCommon.MutationFunction<CreateOrgMembershipForAthleteMutation, CreateOrgMembershipForAthleteMutationVariables>;

/**
 * __useCreateOrgMembershipForAthleteMutation__
 *
 * To run a mutation, you first call `useCreateOrgMembershipForAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrgMembershipForAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrgMembershipForAthleteMutation, { data, loading, error }] = useCreateOrgMembershipForAthleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrgMembershipForAthleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrgMembershipForAthleteMutation, CreateOrgMembershipForAthleteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrgMembershipForAthleteMutation, CreateOrgMembershipForAthleteMutationVariables>(CreateOrgMembershipForAthleteDocument, baseOptions);
      }
export type CreateOrgMembershipForAthleteMutationHookResult = ReturnType<typeof useCreateOrgMembershipForAthleteMutation>;
export type CreateOrgMembershipForAthleteMutationResult = ApolloReactCommon.MutationResult<CreateOrgMembershipForAthleteMutation>;
export type CreateOrgMembershipForAthleteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrgMembershipForAthleteMutation, CreateOrgMembershipForAthleteMutationVariables>;
export const CreateAthletesDocument = gql`
    mutation CreateAthletes($input: [CreateAthleteInput!]!) {
  createAthletes(input: $input) {
    athletes {
      ...Athlete
    }
  }
}
    ${AthleteFragmentDoc}`;
export type CreateAthletesMutationFn = ApolloReactCommon.MutationFunction<CreateAthletesMutation, CreateAthletesMutationVariables>;

/**
 * __useCreateAthletesMutation__
 *
 * To run a mutation, you first call `useCreateAthletesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAthletesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAthletesMutation, { data, loading, error }] = useCreateAthletesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateAthletesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAthletesMutation, CreateAthletesMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAthletesMutation, CreateAthletesMutationVariables>(CreateAthletesDocument, baseOptions);
      }
export type CreateAthletesMutationHookResult = ReturnType<typeof useCreateAthletesMutation>;
export type CreateAthletesMutationResult = ApolloReactCommon.MutationResult<CreateAthletesMutation>;
export type CreateAthletesMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAthletesMutation, CreateAthletesMutationVariables>;
export const CreateBrandDocument = gql`
    mutation CreateBrand($input: CreateBrandInput!) {
  createBrand(input: $input) {
    brand {
      ...Brand
    }
  }
}
    ${BrandFragmentDoc}`;
export type CreateBrandMutationFn = ApolloReactCommon.MutationFunction<CreateBrandMutation, CreateBrandMutationVariables>;

/**
 * __useCreateBrandMutation__
 *
 * To run a mutation, you first call `useCreateBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBrandMutation, { data, loading, error }] = useCreateBrandMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateBrandMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBrandMutation, CreateBrandMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBrandMutation, CreateBrandMutationVariables>(CreateBrandDocument, baseOptions);
      }
export type CreateBrandMutationHookResult = ReturnType<typeof useCreateBrandMutation>;
export type CreateBrandMutationResult = ApolloReactCommon.MutationResult<CreateBrandMutation>;
export type CreateBrandMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBrandMutation, CreateBrandMutationVariables>;
export const CreateFoodServingsDocument = gql`
    mutation CreateFoodServings($input: CreateFoodServingsInput!) {
  createFoodServings(input: $input) {
    servings {
      ...Serving
    }
  }
}
    ${ServingFragmentDoc}`;
export type CreateFoodServingsMutationFn = ApolloReactCommon.MutationFunction<CreateFoodServingsMutation, CreateFoodServingsMutationVariables>;

/**
 * __useCreateFoodServingsMutation__
 *
 * To run a mutation, you first call `useCreateFoodServingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFoodServingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFoodServingsMutation, { data, loading, error }] = useCreateFoodServingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFoodServingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFoodServingsMutation, CreateFoodServingsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFoodServingsMutation, CreateFoodServingsMutationVariables>(CreateFoodServingsDocument, baseOptions);
      }
export type CreateFoodServingsMutationHookResult = ReturnType<typeof useCreateFoodServingsMutation>;
export type CreateFoodServingsMutationResult = ApolloReactCommon.MutationResult<CreateFoodServingsMutation>;
export type CreateFoodServingsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFoodServingsMutation, CreateFoodServingsMutationVariables>;
export const CreateGoalDocument = gql`
    mutation CreateGoal($input: CreateGoalInput!) {
  createGoal(input: $input) {
    goal {
      ...FullGoal
    }
  }
}
    ${FullGoalFragmentDoc}`;
export type CreateGoalMutationFn = ApolloReactCommon.MutationFunction<CreateGoalMutation, CreateGoalMutationVariables>;

/**
 * __useCreateGoalMutation__
 *
 * To run a mutation, you first call `useCreateGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGoalMutation, { data, loading, error }] = useCreateGoalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGoalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGoalMutation, CreateGoalMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGoalMutation, CreateGoalMutationVariables>(CreateGoalDocument, baseOptions);
      }
export type CreateGoalMutationHookResult = ReturnType<typeof useCreateGoalMutation>;
export type CreateGoalMutationResult = ApolloReactCommon.MutationResult<CreateGoalMutation>;
export type CreateGoalMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGoalMutation, CreateGoalMutationVariables>;
export const CreateMetricAnthropometryEntriesDocument = gql`
    mutation CreateMetricAnthropometryEntries($input: [CreateMetricAnthropometryEntryInput!]!) {
  createMetricAnthropometryEntries(input: $input) {
    anthropometryEntries {
      ...AnthropometryEntry
    }
  }
}
    ${AnthropometryEntryFragmentDoc}`;
export type CreateMetricAnthropometryEntriesMutationFn = ApolloReactCommon.MutationFunction<CreateMetricAnthropometryEntriesMutation, CreateMetricAnthropometryEntriesMutationVariables>;

/**
 * __useCreateMetricAnthropometryEntriesMutation__
 *
 * To run a mutation, you first call `useCreateMetricAnthropometryEntriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMetricAnthropometryEntriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMetricAnthropometryEntriesMutation, { data, loading, error }] = useCreateMetricAnthropometryEntriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMetricAnthropometryEntriesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMetricAnthropometryEntriesMutation, CreateMetricAnthropometryEntriesMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMetricAnthropometryEntriesMutation, CreateMetricAnthropometryEntriesMutationVariables>(CreateMetricAnthropometryEntriesDocument, baseOptions);
      }
export type CreateMetricAnthropometryEntriesMutationHookResult = ReturnType<typeof useCreateMetricAnthropometryEntriesMutation>;
export type CreateMetricAnthropometryEntriesMutationResult = ApolloReactCommon.MutationResult<CreateMetricAnthropometryEntriesMutation>;
export type CreateMetricAnthropometryEntriesMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMetricAnthropometryEntriesMutation, CreateMetricAnthropometryEntriesMutationVariables>;
export const CreateMetricAnthropometryEntryDocument = gql`
    mutation CreateMetricAnthropometryEntry($input: CreateMetricAnthropometryEntryInput!) {
  createMetricAnthropometryEntry(input: $input) {
    anthropometryEntry {
      ...AnthropometryEntry
    }
  }
}
    ${AnthropometryEntryFragmentDoc}`;
export type CreateMetricAnthropometryEntryMutationFn = ApolloReactCommon.MutationFunction<CreateMetricAnthropometryEntryMutation, CreateMetricAnthropometryEntryMutationVariables>;

/**
 * __useCreateMetricAnthropometryEntryMutation__
 *
 * To run a mutation, you first call `useCreateMetricAnthropometryEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMetricAnthropometryEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMetricAnthropometryEntryMutation, { data, loading, error }] = useCreateMetricAnthropometryEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMetricAnthropometryEntryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMetricAnthropometryEntryMutation, CreateMetricAnthropometryEntryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMetricAnthropometryEntryMutation, CreateMetricAnthropometryEntryMutationVariables>(CreateMetricAnthropometryEntryDocument, baseOptions);
      }
export type CreateMetricAnthropometryEntryMutationHookResult = ReturnType<typeof useCreateMetricAnthropometryEntryMutation>;
export type CreateMetricAnthropometryEntryMutationResult = ApolloReactCommon.MutationResult<CreateMetricAnthropometryEntryMutation>;
export type CreateMetricAnthropometryEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMetricAnthropometryEntryMutation, CreateMetricAnthropometryEntryMutationVariables>;
export const CreateMetricAthletesDocument = gql`
    mutation CreateMetricAthletes($input: [CreateMetricAthleteInput!]!) {
  createMetricAthletes(input: $input) {
    athletes {
      ...Athlete
    }
  }
}
    ${AthleteFragmentDoc}`;
export type CreateMetricAthletesMutationFn = ApolloReactCommon.MutationFunction<CreateMetricAthletesMutation, CreateMetricAthletesMutationVariables>;

/**
 * __useCreateMetricAthletesMutation__
 *
 * To run a mutation, you first call `useCreateMetricAthletesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMetricAthletesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMetricAthletesMutation, { data, loading, error }] = useCreateMetricAthletesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMetricAthletesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateMetricAthletesMutation, CreateMetricAthletesMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateMetricAthletesMutation, CreateMetricAthletesMutationVariables>(CreateMetricAthletesDocument, baseOptions);
      }
export type CreateMetricAthletesMutationHookResult = ReturnType<typeof useCreateMetricAthletesMutation>;
export type CreateMetricAthletesMutationResult = ApolloReactCommon.MutationResult<CreateMetricAthletesMutation>;
export type CreateMetricAthletesMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateMetricAthletesMutation, CreateMetricAthletesMutationVariables>;
export const CreateNoteDocument = gql`
    mutation CreateNote($input: CreateNoteInput!) {
  createNote(input: $input) {
    note {
      ...NoteWithType
    }
  }
}
    ${NoteWithTypeFragmentDoc}`;
export type CreateNoteMutationFn = ApolloReactCommon.MutationFunction<CreateNoteMutation, CreateNoteMutationVariables>;

/**
 * __useCreateNoteMutation__
 *
 * To run a mutation, you first call `useCreateNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteMutation, { data, loading, error }] = useCreateNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateNoteMutation, CreateNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateNoteMutation, CreateNoteMutationVariables>(CreateNoteDocument, baseOptions);
      }
export type CreateNoteMutationHookResult = ReturnType<typeof useCreateNoteMutation>;
export type CreateNoteMutationResult = ApolloReactCommon.MutationResult<CreateNoteMutation>;
export type CreateNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateNoteMutation, CreateNoteMutationVariables>;
export const CreateNoteTypeDocument = gql`
    mutation CreateNoteType($input: CreateNoteTypeInput!) {
  createNoteType(input: $input) {
    noteType {
      ...NoteType
    }
  }
}
    ${NoteTypeFragmentDoc}`;
export type CreateNoteTypeMutationFn = ApolloReactCommon.MutationFunction<CreateNoteTypeMutation, CreateNoteTypeMutationVariables>;

/**
 * __useCreateNoteTypeMutation__
 *
 * To run a mutation, you first call `useCreateNoteTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNoteTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNoteTypeMutation, { data, loading, error }] = useCreateNoteTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNoteTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateNoteTypeMutation, CreateNoteTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateNoteTypeMutation, CreateNoteTypeMutationVariables>(CreateNoteTypeDocument, baseOptions);
      }
export type CreateNoteTypeMutationHookResult = ReturnType<typeof useCreateNoteTypeMutation>;
export type CreateNoteTypeMutationResult = ApolloReactCommon.MutationResult<CreateNoteTypeMutation>;
export type CreateNoteTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateNoteTypeMutation, CreateNoteTypeMutationVariables>;
export const CreateOrgFoodGroupDocument = gql`
    mutation CreateOrgFoodGroup($input: CreateFoodGroupInput!) {
  createOrgFoodGroup(input: $input) {
    foodGroup {
      ...FullFoodGroup
    }
  }
}
    ${FullFoodGroupFragmentDoc}`;
export type CreateOrgFoodGroupMutationFn = ApolloReactCommon.MutationFunction<CreateOrgFoodGroupMutation, CreateOrgFoodGroupMutationVariables>;

/**
 * __useCreateOrgFoodGroupMutation__
 *
 * To run a mutation, you first call `useCreateOrgFoodGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrgFoodGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrgFoodGroupMutation, { data, loading, error }] = useCreateOrgFoodGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrgFoodGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrgFoodGroupMutation, CreateOrgFoodGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrgFoodGroupMutation, CreateOrgFoodGroupMutationVariables>(CreateOrgFoodGroupDocument, baseOptions);
      }
export type CreateOrgFoodGroupMutationHookResult = ReturnType<typeof useCreateOrgFoodGroupMutation>;
export type CreateOrgFoodGroupMutationResult = ApolloReactCommon.MutationResult<CreateOrgFoodGroupMutation>;
export type CreateOrgFoodGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrgFoodGroupMutation, CreateOrgFoodGroupMutationVariables>;
export const CreateOrgFoodServingsDocument = gql`
    mutation CreateOrgFoodServings($input: CreateFoodServingsInput!) {
  createOrgFoodServings(input: $input) {
    servings {
      ...Serving
    }
  }
}
    ${ServingFragmentDoc}`;
export type CreateOrgFoodServingsMutationFn = ApolloReactCommon.MutationFunction<CreateOrgFoodServingsMutation, CreateOrgFoodServingsMutationVariables>;

/**
 * __useCreateOrgFoodServingsMutation__
 *
 * To run a mutation, you first call `useCreateOrgFoodServingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrgFoodServingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrgFoodServingsMutation, { data, loading, error }] = useCreateOrgFoodServingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateOrgFoodServingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrgFoodServingsMutation, CreateOrgFoodServingsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrgFoodServingsMutation, CreateOrgFoodServingsMutationVariables>(CreateOrgFoodServingsDocument, baseOptions);
      }
export type CreateOrgFoodServingsMutationHookResult = ReturnType<typeof useCreateOrgFoodServingsMutation>;
export type CreateOrgFoodServingsMutationResult = ApolloReactCommon.MutationResult<CreateOrgFoodServingsMutation>;
export type CreateOrgFoodServingsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrgFoodServingsMutation, CreateOrgFoodServingsMutationVariables>;
export const CreateSharedFoodGroupDocument = gql`
    mutation CreateSharedFoodGroup($input: CreateFoodGroupInput!) {
  createSharedFoodGroup(input: $input) {
    foodGroup {
      ...FullFoodGroup
    }
  }
}
    ${FullFoodGroupFragmentDoc}`;
export type CreateSharedFoodGroupMutationFn = ApolloReactCommon.MutationFunction<CreateSharedFoodGroupMutation, CreateSharedFoodGroupMutationVariables>;

/**
 * __useCreateSharedFoodGroupMutation__
 *
 * To run a mutation, you first call `useCreateSharedFoodGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSharedFoodGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSharedFoodGroupMutation, { data, loading, error }] = useCreateSharedFoodGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSharedFoodGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSharedFoodGroupMutation, CreateSharedFoodGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSharedFoodGroupMutation, CreateSharedFoodGroupMutationVariables>(CreateSharedFoodGroupDocument, baseOptions);
      }
export type CreateSharedFoodGroupMutationHookResult = ReturnType<typeof useCreateSharedFoodGroupMutation>;
export type CreateSharedFoodGroupMutationResult = ApolloReactCommon.MutationResult<CreateSharedFoodGroupMutation>;
export type CreateSharedFoodGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSharedFoodGroupMutation, CreateSharedFoodGroupMutationVariables>;
export const DeleteAnthropometryEntryDocument = gql`
    mutation DeleteAnthropometryEntry($input: DeleteAnthropometryEntryInput!) {
  deleteAnthropometryEntry(input: $input) {
    anthropometryEntryId
  }
}
    `;
export type DeleteAnthropometryEntryMutationFn = ApolloReactCommon.MutationFunction<DeleteAnthropometryEntryMutation, DeleteAnthropometryEntryMutationVariables>;

/**
 * __useDeleteAnthropometryEntryMutation__
 *
 * To run a mutation, you first call `useDeleteAnthropometryEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAnthropometryEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAnthropometryEntryMutation, { data, loading, error }] = useDeleteAnthropometryEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAnthropometryEntryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAnthropometryEntryMutation, DeleteAnthropometryEntryMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAnthropometryEntryMutation, DeleteAnthropometryEntryMutationVariables>(DeleteAnthropometryEntryDocument, baseOptions);
      }
export type DeleteAnthropometryEntryMutationHookResult = ReturnType<typeof useDeleteAnthropometryEntryMutation>;
export type DeleteAnthropometryEntryMutationResult = ApolloReactCommon.MutationResult<DeleteAnthropometryEntryMutation>;
export type DeleteAnthropometryEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAnthropometryEntryMutation, DeleteAnthropometryEntryMutationVariables>;
export const DeleteMealPlansDocument = gql`
    mutation DeleteMealPlans($input: DeleteMealPlansInput!) {
  deleteMealPlans(input: $input) {
    mealPlanIds
  }
}
    `;
export type DeleteMealPlansMutationFn = ApolloReactCommon.MutationFunction<DeleteMealPlansMutation, DeleteMealPlansMutationVariables>;

/**
 * __useDeleteMealPlansMutation__
 *
 * To run a mutation, you first call `useDeleteMealPlansMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMealPlansMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMealPlansMutation, { data, loading, error }] = useDeleteMealPlansMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteMealPlansMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteMealPlansMutation, DeleteMealPlansMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteMealPlansMutation, DeleteMealPlansMutationVariables>(DeleteMealPlansDocument, baseOptions);
      }
export type DeleteMealPlansMutationHookResult = ReturnType<typeof useDeleteMealPlansMutation>;
export type DeleteMealPlansMutationResult = ApolloReactCommon.MutationResult<DeleteMealPlansMutation>;
export type DeleteMealPlansMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteMealPlansMutation, DeleteMealPlansMutationVariables>;
export const DeleteNoteDocument = gql`
    mutation DeleteNote($input: DeleteNoteInput!) {
  deleteNote(input: $input) {
    noteId
  }
}
    `;
export type DeleteNoteMutationFn = ApolloReactCommon.MutationFunction<DeleteNoteMutation, DeleteNoteMutationVariables>;

/**
 * __useDeleteNoteMutation__
 *
 * To run a mutation, you first call `useDeleteNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNoteMutation, { data, loading, error }] = useDeleteNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteNoteMutation, DeleteNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteNoteMutation, DeleteNoteMutationVariables>(DeleteNoteDocument, baseOptions);
      }
export type DeleteNoteMutationHookResult = ReturnType<typeof useDeleteNoteMutation>;
export type DeleteNoteMutationResult = ApolloReactCommon.MutationResult<DeleteNoteMutation>;
export type DeleteNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteNoteMutation, DeleteNoteMutationVariables>;
export const DeleteOrgFoodDocument = gql`
    mutation DeleteOrgFood($input: DeleteFoodInput!) {
  deleteOrgFood(input: $input) {
    foodId
  }
}
    `;
export type DeleteOrgFoodMutationFn = ApolloReactCommon.MutationFunction<DeleteOrgFoodMutation, DeleteOrgFoodMutationVariables>;

/**
 * __useDeleteOrgFoodMutation__
 *
 * To run a mutation, you first call `useDeleteOrgFoodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrgFoodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrgFoodMutation, { data, loading, error }] = useDeleteOrgFoodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteOrgFoodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOrgFoodMutation, DeleteOrgFoodMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOrgFoodMutation, DeleteOrgFoodMutationVariables>(DeleteOrgFoodDocument, baseOptions);
      }
export type DeleteOrgFoodMutationHookResult = ReturnType<typeof useDeleteOrgFoodMutation>;
export type DeleteOrgFoodMutationResult = ApolloReactCommon.MutationResult<DeleteOrgFoodMutation>;
export type DeleteOrgFoodMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOrgFoodMutation, DeleteOrgFoodMutationVariables>;
export const DeprecateSharedFoodDocument = gql`
    mutation DeprecateSharedFood($input: DeprecateFoodInput!) {
  deprecateSharedFood(input: $input) {
    foodId
  }
}
    `;
export type DeprecateSharedFoodMutationFn = ApolloReactCommon.MutationFunction<DeprecateSharedFoodMutation, DeprecateSharedFoodMutationVariables>;

/**
 * __useDeprecateSharedFoodMutation__
 *
 * To run a mutation, you first call `useDeprecateSharedFoodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeprecateSharedFoodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deprecateSharedFoodMutation, { data, loading, error }] = useDeprecateSharedFoodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeprecateSharedFoodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeprecateSharedFoodMutation, DeprecateSharedFoodMutationVariables>) {
        return ApolloReactHooks.useMutation<DeprecateSharedFoodMutation, DeprecateSharedFoodMutationVariables>(DeprecateSharedFoodDocument, baseOptions);
      }
export type DeprecateSharedFoodMutationHookResult = ReturnType<typeof useDeprecateSharedFoodMutation>;
export type DeprecateSharedFoodMutationResult = ApolloReactCommon.MutationResult<DeprecateSharedFoodMutation>;
export type DeprecateSharedFoodMutationOptions = ApolloReactCommon.BaseMutationOptions<DeprecateSharedFoodMutation, DeprecateSharedFoodMutationVariables>;
export const EditAnthropometryEntryDocument = gql`
    mutation EditAnthropometryEntry($input: EditAnthropometryEntryInput!) {
  editAnthropometryEntry(input: $input) {
    anthropometryEntry {
      ...AnthropometryEntry
    }
  }
}
    ${AnthropometryEntryFragmentDoc}`;
export type EditAnthropometryEntryMutationFn = ApolloReactCommon.MutationFunction<EditAnthropometryEntryMutation, EditAnthropometryEntryMutationVariables>;

/**
 * __useEditAnthropometryEntryMutation__
 *
 * To run a mutation, you first call `useEditAnthropometryEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAnthropometryEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAnthropometryEntryMutation, { data, loading, error }] = useEditAnthropometryEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAnthropometryEntryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditAnthropometryEntryMutation, EditAnthropometryEntryMutationVariables>) {
        return ApolloReactHooks.useMutation<EditAnthropometryEntryMutation, EditAnthropometryEntryMutationVariables>(EditAnthropometryEntryDocument, baseOptions);
      }
export type EditAnthropometryEntryMutationHookResult = ReturnType<typeof useEditAnthropometryEntryMutation>;
export type EditAnthropometryEntryMutationResult = ApolloReactCommon.MutationResult<EditAnthropometryEntryMutation>;
export type EditAnthropometryEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<EditAnthropometryEntryMutation, EditAnthropometryEntryMutationVariables>;
export const EditAnthropometryEntryCommentDocument = gql`
    mutation EditAnthropometryEntryComment($input: EditAnthropometryEntryCommentInput!) {
  editAnthropometryEntryComment(input: $input) {
    anthropometryEntry {
      ...AnthropometryEntry
    }
  }
}
    ${AnthropometryEntryFragmentDoc}`;
export type EditAnthropometryEntryCommentMutationFn = ApolloReactCommon.MutationFunction<EditAnthropometryEntryCommentMutation, EditAnthropometryEntryCommentMutationVariables>;

/**
 * __useEditAnthropometryEntryCommentMutation__
 *
 * To run a mutation, you first call `useEditAnthropometryEntryCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAnthropometryEntryCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAnthropometryEntryCommentMutation, { data, loading, error }] = useEditAnthropometryEntryCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditAnthropometryEntryCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditAnthropometryEntryCommentMutation, EditAnthropometryEntryCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<EditAnthropometryEntryCommentMutation, EditAnthropometryEntryCommentMutationVariables>(EditAnthropometryEntryCommentDocument, baseOptions);
      }
export type EditAnthropometryEntryCommentMutationHookResult = ReturnType<typeof useEditAnthropometryEntryCommentMutation>;
export type EditAnthropometryEntryCommentMutationResult = ApolloReactCommon.MutationResult<EditAnthropometryEntryCommentMutation>;
export type EditAnthropometryEntryCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<EditAnthropometryEntryCommentMutation, EditAnthropometryEntryCommentMutationVariables>;
export const EditBrandDocument = gql`
    mutation EditBrand($input: EditBrandInput!) {
  editBrand(input: $input) {
    brand {
      ...Brand
    }
  }
}
    ${BrandFragmentDoc}`;
export type EditBrandMutationFn = ApolloReactCommon.MutationFunction<EditBrandMutation, EditBrandMutationVariables>;

/**
 * __useEditBrandMutation__
 *
 * To run a mutation, you first call `useEditBrandMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditBrandMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editBrandMutation, { data, loading, error }] = useEditBrandMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditBrandMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditBrandMutation, EditBrandMutationVariables>) {
        return ApolloReactHooks.useMutation<EditBrandMutation, EditBrandMutationVariables>(EditBrandDocument, baseOptions);
      }
export type EditBrandMutationHookResult = ReturnType<typeof useEditBrandMutation>;
export type EditBrandMutationResult = ApolloReactCommon.MutationResult<EditBrandMutation>;
export type EditBrandMutationOptions = ApolloReactCommon.BaseMutationOptions<EditBrandMutation, EditBrandMutationVariables>;
export const EditExchangeMealTemplateDocument = gql`
    mutation EditExchangeMealTemplate($input: EditExchangeMealTemplateInput!) {
  editExchangeMealTemplate(input: $input) {
    exchangeMealTemplate {
      ...EditFullExchangeMealTemplate
    }
  }
}
    ${EditFullExchangeMealTemplateFragmentDoc}`;
export type EditExchangeMealTemplateMutationFn = ApolloReactCommon.MutationFunction<EditExchangeMealTemplateMutation, EditExchangeMealTemplateMutationVariables>;

/**
 * __useEditExchangeMealTemplateMutation__
 *
 * To run a mutation, you first call `useEditExchangeMealTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditExchangeMealTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editExchangeMealTemplateMutation, { data, loading, error }] = useEditExchangeMealTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditExchangeMealTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditExchangeMealTemplateMutation, EditExchangeMealTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<EditExchangeMealTemplateMutation, EditExchangeMealTemplateMutationVariables>(EditExchangeMealTemplateDocument, baseOptions);
      }
export type EditExchangeMealTemplateMutationHookResult = ReturnType<typeof useEditExchangeMealTemplateMutation>;
export type EditExchangeMealTemplateMutationResult = ApolloReactCommon.MutationResult<EditExchangeMealTemplateMutation>;
export type EditExchangeMealTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<EditExchangeMealTemplateMutation, EditExchangeMealTemplateMutationVariables>;
export const EditExchangeMealTemplatesDocument = gql`
    mutation EditExchangeMealTemplates($input: [EditExchangeMealTemplateInput!]!) {
  editExchangeMealTemplates(input: $input) {
    exchangeMealTemplates {
      ...EditFullExchangeMealTemplate
    }
  }
}
    ${EditFullExchangeMealTemplateFragmentDoc}`;
export type EditExchangeMealTemplatesMutationFn = ApolloReactCommon.MutationFunction<EditExchangeMealTemplatesMutation, EditExchangeMealTemplatesMutationVariables>;

/**
 * __useEditExchangeMealTemplatesMutation__
 *
 * To run a mutation, you first call `useEditExchangeMealTemplatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditExchangeMealTemplatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editExchangeMealTemplatesMutation, { data, loading, error }] = useEditExchangeMealTemplatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditExchangeMealTemplatesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditExchangeMealTemplatesMutation, EditExchangeMealTemplatesMutationVariables>) {
        return ApolloReactHooks.useMutation<EditExchangeMealTemplatesMutation, EditExchangeMealTemplatesMutationVariables>(EditExchangeMealTemplatesDocument, baseOptions);
      }
export type EditExchangeMealTemplatesMutationHookResult = ReturnType<typeof useEditExchangeMealTemplatesMutation>;
export type EditExchangeMealTemplatesMutationResult = ApolloReactCommon.MutationResult<EditExchangeMealTemplatesMutation>;
export type EditExchangeMealTemplatesMutationOptions = ApolloReactCommon.BaseMutationOptions<EditExchangeMealTemplatesMutation, EditExchangeMealTemplatesMutationVariables>;
export const EditFoodServingDocument = gql`
    mutation EditFoodServing($input: EditFoodServingInput!) {
  editFoodServing(input: $input) {
    serving {
      ...Serving
    }
  }
}
    ${ServingFragmentDoc}`;
export type EditFoodServingMutationFn = ApolloReactCommon.MutationFunction<EditFoodServingMutation, EditFoodServingMutationVariables>;

/**
 * __useEditFoodServingMutation__
 *
 * To run a mutation, you first call `useEditFoodServingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditFoodServingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editFoodServingMutation, { data, loading, error }] = useEditFoodServingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditFoodServingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditFoodServingMutation, EditFoodServingMutationVariables>) {
        return ApolloReactHooks.useMutation<EditFoodServingMutation, EditFoodServingMutationVariables>(EditFoodServingDocument, baseOptions);
      }
export type EditFoodServingMutationHookResult = ReturnType<typeof useEditFoodServingMutation>;
export type EditFoodServingMutationResult = ApolloReactCommon.MutationResult<EditFoodServingMutation>;
export type EditFoodServingMutationOptions = ApolloReactCommon.BaseMutationOptions<EditFoodServingMutation, EditFoodServingMutationVariables>;
export const EditMacroMealTemplateDocument = gql`
    mutation EditMacroMealTemplate($input: EditMacroMealTemplateInput!) {
  editMacroMealTemplate(input: $input) {
    macroMealTemplate {
      ...MacroMealTemplate
    }
  }
}
    ${MacroMealTemplateFragmentDoc}`;
export type EditMacroMealTemplateMutationFn = ApolloReactCommon.MutationFunction<EditMacroMealTemplateMutation, EditMacroMealTemplateMutationVariables>;

/**
 * __useEditMacroMealTemplateMutation__
 *
 * To run a mutation, you first call `useEditMacroMealTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMacroMealTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMacroMealTemplateMutation, { data, loading, error }] = useEditMacroMealTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMacroMealTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMacroMealTemplateMutation, EditMacroMealTemplateMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMacroMealTemplateMutation, EditMacroMealTemplateMutationVariables>(EditMacroMealTemplateDocument, baseOptions);
      }
export type EditMacroMealTemplateMutationHookResult = ReturnType<typeof useEditMacroMealTemplateMutation>;
export type EditMacroMealTemplateMutationResult = ApolloReactCommon.MutationResult<EditMacroMealTemplateMutation>;
export type EditMacroMealTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMacroMealTemplateMutation, EditMacroMealTemplateMutationVariables>;
export const EditMacroMealTemplatesDocument = gql`
    mutation EditMacroMealTemplates($input: [EditMacroMealTemplateInput!]!) {
  editMacroMealTemplates(input: $input) {
    macroMealTemplates {
      ...MacroMealTemplate
    }
  }
}
    ${MacroMealTemplateFragmentDoc}`;
export type EditMacroMealTemplatesMutationFn = ApolloReactCommon.MutationFunction<EditMacroMealTemplatesMutation, EditMacroMealTemplatesMutationVariables>;

/**
 * __useEditMacroMealTemplatesMutation__
 *
 * To run a mutation, you first call `useEditMacroMealTemplatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMacroMealTemplatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMacroMealTemplatesMutation, { data, loading, error }] = useEditMacroMealTemplatesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMacroMealTemplatesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMacroMealTemplatesMutation, EditMacroMealTemplatesMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMacroMealTemplatesMutation, EditMacroMealTemplatesMutationVariables>(EditMacroMealTemplatesDocument, baseOptions);
      }
export type EditMacroMealTemplatesMutationHookResult = ReturnType<typeof useEditMacroMealTemplatesMutation>;
export type EditMacroMealTemplatesMutationResult = ApolloReactCommon.MutationResult<EditMacroMealTemplatesMutation>;
export type EditMacroMealTemplatesMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMacroMealTemplatesMutation, EditMacroMealTemplatesMutationVariables>;
export const EditMacroProtocolDocument = gql`
    mutation EditMacroProtocol($input: EditMacroProtocolInput!) {
  editMacroProtocol(input: $input) {
    macroProtocol {
      ...FullMacroProtocol
    }
  }
}
    ${FullMacroProtocolFragmentDoc}`;
export type EditMacroProtocolMutationFn = ApolloReactCommon.MutationFunction<EditMacroProtocolMutation, EditMacroProtocolMutationVariables>;

/**
 * __useEditMacroProtocolMutation__
 *
 * To run a mutation, you first call `useEditMacroProtocolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMacroProtocolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMacroProtocolMutation, { data, loading, error }] = useEditMacroProtocolMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMacroProtocolMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMacroProtocolMutation, EditMacroProtocolMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMacroProtocolMutation, EditMacroProtocolMutationVariables>(EditMacroProtocolDocument, baseOptions);
      }
export type EditMacroProtocolMutationHookResult = ReturnType<typeof useEditMacroProtocolMutation>;
export type EditMacroProtocolMutationResult = ApolloReactCommon.MutationResult<EditMacroProtocolMutation>;
export type EditMacroProtocolMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMacroProtocolMutation, EditMacroProtocolMutationVariables>;
export const EditMacroProtocolWithNewGoalDocument = gql`
    mutation EditMacroProtocolWithNewGoal($input: EditMacroProtocolWithNewGoalInput!) {
  editMacroProtocolWithNewGoal(input: $input) {
    macroProtocol {
      ...FullMacroProtocol
    }
  }
}
    ${FullMacroProtocolFragmentDoc}`;
export type EditMacroProtocolWithNewGoalMutationFn = ApolloReactCommon.MutationFunction<EditMacroProtocolWithNewGoalMutation, EditMacroProtocolWithNewGoalMutationVariables>;

/**
 * __useEditMacroProtocolWithNewGoalMutation__
 *
 * To run a mutation, you first call `useEditMacroProtocolWithNewGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMacroProtocolWithNewGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMacroProtocolWithNewGoalMutation, { data, loading, error }] = useEditMacroProtocolWithNewGoalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMacroProtocolWithNewGoalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMacroProtocolWithNewGoalMutation, EditMacroProtocolWithNewGoalMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMacroProtocolWithNewGoalMutation, EditMacroProtocolWithNewGoalMutationVariables>(EditMacroProtocolWithNewGoalDocument, baseOptions);
      }
export type EditMacroProtocolWithNewGoalMutationHookResult = ReturnType<typeof useEditMacroProtocolWithNewGoalMutation>;
export type EditMacroProtocolWithNewGoalMutationResult = ApolloReactCommon.MutationResult<EditMacroProtocolWithNewGoalMutation>;
export type EditMacroProtocolWithNewGoalMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMacroProtocolWithNewGoalMutation, EditMacroProtocolWithNewGoalMutationVariables>;
export const EditMacroProtocolWithOldGoalDocument = gql`
    mutation EditMacroProtocolWithOldGoal($input: EditMacroProtocolWithOldGoalInput!) {
  editMacroProtocolWithOldGoal(input: $input) {
    macroProtocol {
      ...FullMacroProtocol
    }
  }
}
    ${FullMacroProtocolFragmentDoc}`;
export type EditMacroProtocolWithOldGoalMutationFn = ApolloReactCommon.MutationFunction<EditMacroProtocolWithOldGoalMutation, EditMacroProtocolWithOldGoalMutationVariables>;

/**
 * __useEditMacroProtocolWithOldGoalMutation__
 *
 * To run a mutation, you first call `useEditMacroProtocolWithOldGoalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMacroProtocolWithOldGoalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMacroProtocolWithOldGoalMutation, { data, loading, error }] = useEditMacroProtocolWithOldGoalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMacroProtocolWithOldGoalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMacroProtocolWithOldGoalMutation, EditMacroProtocolWithOldGoalMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMacroProtocolWithOldGoalMutation, EditMacroProtocolWithOldGoalMutationVariables>(EditMacroProtocolWithOldGoalDocument, baseOptions);
      }
export type EditMacroProtocolWithOldGoalMutationHookResult = ReturnType<typeof useEditMacroProtocolWithOldGoalMutation>;
export type EditMacroProtocolWithOldGoalMutationResult = ApolloReactCommon.MutationResult<EditMacroProtocolWithOldGoalMutation>;
export type EditMacroProtocolWithOldGoalMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMacroProtocolWithOldGoalMutation, EditMacroProtocolWithOldGoalMutationVariables>;
export const EditMealOptionDocument = gql`
    mutation EditMealOption($input: EditMealOptionInput!) {
  editMealOption(input: $input) {
    mealOption {
      ...FullMealOption
    }
  }
}
    ${FullMealOptionFragmentDoc}`;
export type EditMealOptionMutationFn = ApolloReactCommon.MutationFunction<EditMealOptionMutation, EditMealOptionMutationVariables>;

/**
 * __useEditMealOptionMutation__
 *
 * To run a mutation, you first call `useEditMealOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMealOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMealOptionMutation, { data, loading, error }] = useEditMealOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMealOptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMealOptionMutation, EditMealOptionMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMealOptionMutation, EditMealOptionMutationVariables>(EditMealOptionDocument, baseOptions);
      }
export type EditMealOptionMutationHookResult = ReturnType<typeof useEditMealOptionMutation>;
export type EditMealOptionMutationResult = ApolloReactCommon.MutationResult<EditMealOptionMutation>;
export type EditMealOptionMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMealOptionMutation, EditMealOptionMutationVariables>;
export const EditMealOptionPendingDocument = gql`
    mutation EditMealOptionPending($input: EditMealOptionPendingInput!) {
  editMealOptionPending(input: $input) {
    mealOptionPending {
      ...FullMealOptionPending
    }
  }
}
    ${FullMealOptionPendingFragmentDoc}`;
export type EditMealOptionPendingMutationFn = ApolloReactCommon.MutationFunction<EditMealOptionPendingMutation, EditMealOptionPendingMutationVariables>;

/**
 * __useEditMealOptionPendingMutation__
 *
 * To run a mutation, you first call `useEditMealOptionPendingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMealOptionPendingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMealOptionPendingMutation, { data, loading, error }] = useEditMealOptionPendingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMealOptionPendingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMealOptionPendingMutation, EditMealOptionPendingMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMealOptionPendingMutation, EditMealOptionPendingMutationVariables>(EditMealOptionPendingDocument, baseOptions);
      }
export type EditMealOptionPendingMutationHookResult = ReturnType<typeof useEditMealOptionPendingMutation>;
export type EditMealOptionPendingMutationResult = ApolloReactCommon.MutationResult<EditMealOptionPendingMutation>;
export type EditMealOptionPendingMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMealOptionPendingMutation, EditMealOptionPendingMutationVariables>;
export const EditMealPlanExchangeTargetsDocument = gql`
    mutation EditMealPlanExchangeTargets($input: EditMealPlanExchangeTargetsInput!) {
  editMealPlanExchangeTargets(input: $input) {
    mealPlan {
      id
      exchangeTargets {
        ...EditExchangeAmount
      }
    }
  }
}
    ${EditExchangeAmountFragmentDoc}`;
export type EditMealPlanExchangeTargetsMutationFn = ApolloReactCommon.MutationFunction<EditMealPlanExchangeTargetsMutation, EditMealPlanExchangeTargetsMutationVariables>;

/**
 * __useEditMealPlanExchangeTargetsMutation__
 *
 * To run a mutation, you first call `useEditMealPlanExchangeTargetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMealPlanExchangeTargetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMealPlanExchangeTargetsMutation, { data, loading, error }] = useEditMealPlanExchangeTargetsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMealPlanExchangeTargetsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMealPlanExchangeTargetsMutation, EditMealPlanExchangeTargetsMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMealPlanExchangeTargetsMutation, EditMealPlanExchangeTargetsMutationVariables>(EditMealPlanExchangeTargetsDocument, baseOptions);
      }
export type EditMealPlanExchangeTargetsMutationHookResult = ReturnType<typeof useEditMealPlanExchangeTargetsMutation>;
export type EditMealPlanExchangeTargetsMutationResult = ApolloReactCommon.MutationResult<EditMealPlanExchangeTargetsMutation>;
export type EditMealPlanExchangeTargetsMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMealPlanExchangeTargetsMutation, EditMealPlanExchangeTargetsMutationVariables>;
export const EditMealPlanIsSharedDocument = gql`
    mutation EditMealPlanIsShared($input: EditMealPlanIsSharedInput!) {
  editMealPlanIsShared(input: $input) {
    mealPlan {
      ...BasicMealPlan
    }
  }
}
    ${BasicMealPlanFragmentDoc}`;
export type EditMealPlanIsSharedMutationFn = ApolloReactCommon.MutationFunction<EditMealPlanIsSharedMutation, EditMealPlanIsSharedMutationVariables>;

/**
 * __useEditMealPlanIsSharedMutation__
 *
 * To run a mutation, you first call `useEditMealPlanIsSharedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMealPlanIsSharedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMealPlanIsSharedMutation, { data, loading, error }] = useEditMealPlanIsSharedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMealPlanIsSharedMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMealPlanIsSharedMutation, EditMealPlanIsSharedMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMealPlanIsSharedMutation, EditMealPlanIsSharedMutationVariables>(EditMealPlanIsSharedDocument, baseOptions);
      }
export type EditMealPlanIsSharedMutationHookResult = ReturnType<typeof useEditMealPlanIsSharedMutation>;
export type EditMealPlanIsSharedMutationResult = ApolloReactCommon.MutationResult<EditMealPlanIsSharedMutation>;
export type EditMealPlanIsSharedMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMealPlanIsSharedMutation, EditMealPlanIsSharedMutationVariables>;
export const EditMealPlansIsSharedDocument = gql`
    mutation EditMealPlansIsShared($input: EditMealPlansIsSharedInput!) {
  editMealPlansIsShared(input: $input) {
    mealPlans {
      id
      isShared
    }
  }
}
    `;
export type EditMealPlansIsSharedMutationFn = ApolloReactCommon.MutationFunction<EditMealPlansIsSharedMutation, EditMealPlansIsSharedMutationVariables>;

/**
 * __useEditMealPlansIsSharedMutation__
 *
 * To run a mutation, you first call `useEditMealPlansIsSharedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMealPlansIsSharedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMealPlansIsSharedMutation, { data, loading, error }] = useEditMealPlansIsSharedMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMealPlansIsSharedMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMealPlansIsSharedMutation, EditMealPlansIsSharedMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMealPlansIsSharedMutation, EditMealPlansIsSharedMutationVariables>(EditMealPlansIsSharedDocument, baseOptions);
      }
export type EditMealPlansIsSharedMutationHookResult = ReturnType<typeof useEditMealPlansIsSharedMutation>;
export type EditMealPlansIsSharedMutationResult = ApolloReactCommon.MutationResult<EditMealPlansIsSharedMutation>;
export type EditMealPlansIsSharedMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMealPlansIsSharedMutation, EditMealPlansIsSharedMutationVariables>;
export const EditMetricAnthropometryEntriesDocument = gql`
    mutation EditMetricAnthropometryEntries($input: [EditMetricAnthropometryEntryInput!]!) {
  editMetricAnthropometryEntries(input: $input) {
    anthropometryEntries {
      ...AnthropometryEntry
    }
  }
}
    ${AnthropometryEntryFragmentDoc}`;
export type EditMetricAnthropometryEntriesMutationFn = ApolloReactCommon.MutationFunction<EditMetricAnthropometryEntriesMutation, EditMetricAnthropometryEntriesMutationVariables>;

/**
 * __useEditMetricAnthropometryEntriesMutation__
 *
 * To run a mutation, you first call `useEditMetricAnthropometryEntriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMetricAnthropometryEntriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMetricAnthropometryEntriesMutation, { data, loading, error }] = useEditMetricAnthropometryEntriesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMetricAnthropometryEntriesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMetricAnthropometryEntriesMutation, EditMetricAnthropometryEntriesMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMetricAnthropometryEntriesMutation, EditMetricAnthropometryEntriesMutationVariables>(EditMetricAnthropometryEntriesDocument, baseOptions);
      }
export type EditMetricAnthropometryEntriesMutationHookResult = ReturnType<typeof useEditMetricAnthropometryEntriesMutation>;
export type EditMetricAnthropometryEntriesMutationResult = ApolloReactCommon.MutationResult<EditMetricAnthropometryEntriesMutation>;
export type EditMetricAnthropometryEntriesMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMetricAnthropometryEntriesMutation, EditMetricAnthropometryEntriesMutationVariables>;
export const EditMetricAnthropometryEntryDocument = gql`
    mutation EditMetricAnthropometryEntry($input: EditMetricAnthropometryEntryInput!) {
  editMetricAnthropometryEntry(input: $input) {
    anthropometryEntry {
      ...AnthropometryEntry
    }
  }
}
    ${AnthropometryEntryFragmentDoc}`;
export type EditMetricAnthropometryEntryMutationFn = ApolloReactCommon.MutationFunction<EditMetricAnthropometryEntryMutation, EditMetricAnthropometryEntryMutationVariables>;

/**
 * __useEditMetricAnthropometryEntryMutation__
 *
 * To run a mutation, you first call `useEditMetricAnthropometryEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMetricAnthropometryEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMetricAnthropometryEntryMutation, { data, loading, error }] = useEditMetricAnthropometryEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMetricAnthropometryEntryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMetricAnthropometryEntryMutation, EditMetricAnthropometryEntryMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMetricAnthropometryEntryMutation, EditMetricAnthropometryEntryMutationVariables>(EditMetricAnthropometryEntryDocument, baseOptions);
      }
export type EditMetricAnthropometryEntryMutationHookResult = ReturnType<typeof useEditMetricAnthropometryEntryMutation>;
export type EditMetricAnthropometryEntryMutationResult = ApolloReactCommon.MutationResult<EditMetricAnthropometryEntryMutation>;
export type EditMetricAnthropometryEntryMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMetricAnthropometryEntryMutation, EditMetricAnthropometryEntryMutationVariables>;
export const EditMetricAthletesDocument = gql`
    mutation EditMetricAthletes($input: [EditAthleteAndMetricAnthroInput!]!) {
  editMetricAthletes(input: $input) {
    athletes {
      ...Athlete
    }
  }
}
    ${AthleteFragmentDoc}`;
export type EditMetricAthletesMutationFn = ApolloReactCommon.MutationFunction<EditMetricAthletesMutation, EditMetricAthletesMutationVariables>;

/**
 * __useEditMetricAthletesMutation__
 *
 * To run a mutation, you first call `useEditMetricAthletesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMetricAthletesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMetricAthletesMutation, { data, loading, error }] = useEditMetricAthletesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMetricAthletesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMetricAthletesMutation, EditMetricAthletesMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMetricAthletesMutation, EditMetricAthletesMutationVariables>(EditMetricAthletesDocument, baseOptions);
      }
export type EditMetricAthletesMutationHookResult = ReturnType<typeof useEditMetricAthletesMutation>;
export type EditMetricAthletesMutationResult = ApolloReactCommon.MutationResult<EditMetricAthletesMutation>;
export type EditMetricAthletesMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMetricAthletesMutation, EditMetricAthletesMutationVariables>;
export const EditMetricMacroProtocolDocument = gql`
    mutation EditMetricMacroProtocol($input: EditMetricMacroProtocolInput!) {
  editMetricMacroProtocol(input: $input) {
    macroProtocol {
      ...FullMacroProtocol
    }
  }
}
    ${FullMacroProtocolFragmentDoc}`;
export type EditMetricMacroProtocolMutationFn = ApolloReactCommon.MutationFunction<EditMetricMacroProtocolMutation, EditMetricMacroProtocolMutationVariables>;

/**
 * __useEditMetricMacroProtocolMutation__
 *
 * To run a mutation, you first call `useEditMetricMacroProtocolMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMetricMacroProtocolMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMetricMacroProtocolMutation, { data, loading, error }] = useEditMetricMacroProtocolMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMetricMacroProtocolMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMetricMacroProtocolMutation, EditMetricMacroProtocolMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMetricMacroProtocolMutation, EditMetricMacroProtocolMutationVariables>(EditMetricMacroProtocolDocument, baseOptions);
      }
export type EditMetricMacroProtocolMutationHookResult = ReturnType<typeof useEditMetricMacroProtocolMutation>;
export type EditMetricMacroProtocolMutationResult = ApolloReactCommon.MutationResult<EditMetricMacroProtocolMutation>;
export type EditMetricMacroProtocolMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMetricMacroProtocolMutation, EditMetricMacroProtocolMutationVariables>;
export const EditNoteDocument = gql`
    mutation EditNote($input: EditNoteInput!) {
  editNote(input: $input) {
    note {
      ...NoteWithType
    }
  }
}
    ${NoteWithTypeFragmentDoc}`;
export type EditNoteMutationFn = ApolloReactCommon.MutationFunction<EditNoteMutation, EditNoteMutationVariables>;

/**
 * __useEditNoteMutation__
 *
 * To run a mutation, you first call `useEditNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editNoteMutation, { data, loading, error }] = useEditNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditNoteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditNoteMutation, EditNoteMutationVariables>) {
        return ApolloReactHooks.useMutation<EditNoteMutation, EditNoteMutationVariables>(EditNoteDocument, baseOptions);
      }
export type EditNoteMutationHookResult = ReturnType<typeof useEditNoteMutation>;
export type EditNoteMutationResult = ApolloReactCommon.MutationResult<EditNoteMutation>;
export type EditNoteMutationOptions = ApolloReactCommon.BaseMutationOptions<EditNoteMutation, EditNoteMutationVariables>;
export const EditNoteTypeDocument = gql`
    mutation EditNoteType($input: EditNoteTypeInput!) {
  editNoteType(input: $input) {
    noteType {
      ...NoteType
    }
  }
}
    ${NoteTypeFragmentDoc}`;
export type EditNoteTypeMutationFn = ApolloReactCommon.MutationFunction<EditNoteTypeMutation, EditNoteTypeMutationVariables>;

/**
 * __useEditNoteTypeMutation__
 *
 * To run a mutation, you first call `useEditNoteTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditNoteTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editNoteTypeMutation, { data, loading, error }] = useEditNoteTypeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditNoteTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditNoteTypeMutation, EditNoteTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<EditNoteTypeMutation, EditNoteTypeMutationVariables>(EditNoteTypeDocument, baseOptions);
      }
export type EditNoteTypeMutationHookResult = ReturnType<typeof useEditNoteTypeMutation>;
export type EditNoteTypeMutationResult = ApolloReactCommon.MutationResult<EditNoteTypeMutation>;
export type EditNoteTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<EditNoteTypeMutation, EditNoteTypeMutationVariables>;
export const EditOrgFoodGroupDocument = gql`
    mutation EditOrgFoodGroup($input: EditFoodGroupInput!) {
  editOrgFoodGroup(input: $input) {
    foodGroup {
      ...FullFoodGroup
    }
  }
}
    ${FullFoodGroupFragmentDoc}`;
export type EditOrgFoodGroupMutationFn = ApolloReactCommon.MutationFunction<EditOrgFoodGroupMutation, EditOrgFoodGroupMutationVariables>;

/**
 * __useEditOrgFoodGroupMutation__
 *
 * To run a mutation, you first call `useEditOrgFoodGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrgFoodGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrgFoodGroupMutation, { data, loading, error }] = useEditOrgFoodGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditOrgFoodGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditOrgFoodGroupMutation, EditOrgFoodGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<EditOrgFoodGroupMutation, EditOrgFoodGroupMutationVariables>(EditOrgFoodGroupDocument, baseOptions);
      }
export type EditOrgFoodGroupMutationHookResult = ReturnType<typeof useEditOrgFoodGroupMutation>;
export type EditOrgFoodGroupMutationResult = ApolloReactCommon.MutationResult<EditOrgFoodGroupMutation>;
export type EditOrgFoodGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<EditOrgFoodGroupMutation, EditOrgFoodGroupMutationVariables>;
export const EditOrgFoodServingDocument = gql`
    mutation EditOrgFoodServing($input: EditFoodServingInput!) {
  editOrgFoodServing(input: $input) {
    serving {
      ...Serving
    }
  }
}
    ${ServingFragmentDoc}`;
export type EditOrgFoodServingMutationFn = ApolloReactCommon.MutationFunction<EditOrgFoodServingMutation, EditOrgFoodServingMutationVariables>;

/**
 * __useEditOrgFoodServingMutation__
 *
 * To run a mutation, you first call `useEditOrgFoodServingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditOrgFoodServingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editOrgFoodServingMutation, { data, loading, error }] = useEditOrgFoodServingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditOrgFoodServingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditOrgFoodServingMutation, EditOrgFoodServingMutationVariables>) {
        return ApolloReactHooks.useMutation<EditOrgFoodServingMutation, EditOrgFoodServingMutationVariables>(EditOrgFoodServingDocument, baseOptions);
      }
export type EditOrgFoodServingMutationHookResult = ReturnType<typeof useEditOrgFoodServingMutation>;
export type EditOrgFoodServingMutationResult = ApolloReactCommon.MutationResult<EditOrgFoodServingMutation>;
export type EditOrgFoodServingMutationOptions = ApolloReactCommon.BaseMutationOptions<EditOrgFoodServingMutation, EditOrgFoodServingMutationVariables>;
export const EditSharedFoodGroupDocument = gql`
    mutation EditSharedFoodGroup($input: EditFoodGroupInput!) {
  editSharedFoodGroup(input: $input) {
    foodGroup {
      ...FullFoodGroup
    }
  }
}
    ${FullFoodGroupFragmentDoc}`;
export type EditSharedFoodGroupMutationFn = ApolloReactCommon.MutationFunction<EditSharedFoodGroupMutation, EditSharedFoodGroupMutationVariables>;

/**
 * __useEditSharedFoodGroupMutation__
 *
 * To run a mutation, you first call `useEditSharedFoodGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditSharedFoodGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editSharedFoodGroupMutation, { data, loading, error }] = useEditSharedFoodGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditSharedFoodGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditSharedFoodGroupMutation, EditSharedFoodGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<EditSharedFoodGroupMutation, EditSharedFoodGroupMutationVariables>(EditSharedFoodGroupDocument, baseOptions);
      }
export type EditSharedFoodGroupMutationHookResult = ReturnType<typeof useEditSharedFoodGroupMutation>;
export type EditSharedFoodGroupMutationResult = ApolloReactCommon.MutationResult<EditSharedFoodGroupMutation>;
export type EditSharedFoodGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<EditSharedFoodGroupMutation, EditSharedFoodGroupMutationVariables>;
export const CreateExchangeSetDocument = gql`
    mutation CreateExchangeSet($input: CreateExchangeSetInput!) {
  createExchangeSet(input: $input) {
    exchangeSet {
      ...FullExchangeSet
    }
  }
}
    ${FullExchangeSetFragmentDoc}`;
export type CreateExchangeSetMutationFn = ApolloReactCommon.MutationFunction<CreateExchangeSetMutation, CreateExchangeSetMutationVariables>;

/**
 * __useCreateExchangeSetMutation__
 *
 * To run a mutation, you first call `useCreateExchangeSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExchangeSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExchangeSetMutation, { data, loading, error }] = useCreateExchangeSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExchangeSetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateExchangeSetMutation, CreateExchangeSetMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateExchangeSetMutation, CreateExchangeSetMutationVariables>(CreateExchangeSetDocument, baseOptions);
      }
export type CreateExchangeSetMutationHookResult = ReturnType<typeof useCreateExchangeSetMutation>;
export type CreateExchangeSetMutationResult = ApolloReactCommon.MutationResult<CreateExchangeSetMutation>;
export type CreateExchangeSetMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateExchangeSetMutation, CreateExchangeSetMutationVariables>;
export const EditExchangeSetDocument = gql`
    mutation EditExchangeSet($input: EditExchangeSetInput!) {
  editExchangeSet(input: $input) {
    exchangeSet {
      ...FullExchangeSet
    }
  }
}
    ${FullExchangeSetFragmentDoc}`;
export type EditExchangeSetMutationFn = ApolloReactCommon.MutationFunction<EditExchangeSetMutation, EditExchangeSetMutationVariables>;

/**
 * __useEditExchangeSetMutation__
 *
 * To run a mutation, you first call `useEditExchangeSetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditExchangeSetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editExchangeSetMutation, { data, loading, error }] = useEditExchangeSetMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditExchangeSetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditExchangeSetMutation, EditExchangeSetMutationVariables>) {
        return ApolloReactHooks.useMutation<EditExchangeSetMutation, EditExchangeSetMutationVariables>(EditExchangeSetDocument, baseOptions);
      }
export type EditExchangeSetMutationHookResult = ReturnType<typeof useEditExchangeSetMutation>;
export type EditExchangeSetMutationResult = ApolloReactCommon.MutationResult<EditExchangeSetMutation>;
export type EditExchangeSetMutationOptions = ApolloReactCommon.BaseMutationOptions<EditExchangeSetMutation, EditExchangeSetMutationVariables>;
export const AddNixCommonFoodDocument = gql`
    mutation AddNixCommonFood($input: AddNixCommonFoodInput!) {
  addNixCommonFood(input: $input) {
    food {
      ...FoodWithDefaultServing
    }
  }
}
    ${FoodWithDefaultServingFragmentDoc}`;
export type AddNixCommonFoodMutationFn = ApolloReactCommon.MutationFunction<AddNixCommonFoodMutation, AddNixCommonFoodMutationVariables>;

/**
 * __useAddNixCommonFoodMutation__
 *
 * To run a mutation, you first call `useAddNixCommonFoodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNixCommonFoodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNixCommonFoodMutation, { data, loading, error }] = useAddNixCommonFoodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddNixCommonFoodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddNixCommonFoodMutation, AddNixCommonFoodMutationVariables>) {
        return ApolloReactHooks.useMutation<AddNixCommonFoodMutation, AddNixCommonFoodMutationVariables>(AddNixCommonFoodDocument, baseOptions);
      }
export type AddNixCommonFoodMutationHookResult = ReturnType<typeof useAddNixCommonFoodMutation>;
export type AddNixCommonFoodMutationResult = ApolloReactCommon.MutationResult<AddNixCommonFoodMutation>;
export type AddNixCommonFoodMutationOptions = ApolloReactCommon.BaseMutationOptions<AddNixCommonFoodMutation, AddNixCommonFoodMutationVariables>;
export const AddNixBrandedFoodDocument = gql`
    mutation AddNixBrandedFood($input: AddNixBrandedFoodInput!) {
  addNixBrandedFood(input: $input) {
    food {
      ...FoodWithDefaultServing
    }
  }
}
    ${FoodWithDefaultServingFragmentDoc}`;
export type AddNixBrandedFoodMutationFn = ApolloReactCommon.MutationFunction<AddNixBrandedFoodMutation, AddNixBrandedFoodMutationVariables>;

/**
 * __useAddNixBrandedFoodMutation__
 *
 * To run a mutation, you first call `useAddNixBrandedFoodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNixBrandedFoodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNixBrandedFoodMutation, { data, loading, error }] = useAddNixBrandedFoodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddNixBrandedFoodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddNixBrandedFoodMutation, AddNixBrandedFoodMutationVariables>) {
        return ApolloReactHooks.useMutation<AddNixBrandedFoodMutation, AddNixBrandedFoodMutationVariables>(AddNixBrandedFoodDocument, baseOptions);
      }
export type AddNixBrandedFoodMutationHookResult = ReturnType<typeof useAddNixBrandedFoodMutation>;
export type AddNixBrandedFoodMutationResult = ApolloReactCommon.MutationResult<AddNixBrandedFoodMutation>;
export type AddNixBrandedFoodMutationOptions = ApolloReactCommon.BaseMutationOptions<AddNixBrandedFoodMutation, AddNixBrandedFoodMutationVariables>;
export const RemoveAthleteDislikedFoodDocument = gql`
    mutation RemoveAthleteDislikedFood($input: AthleteFoodPreferenceInput!) {
  removeAthleteDislikedFood(input: $input) {
    athlete {
      id
      dislikedFoods {
        ...Food
      }
    }
  }
}
    ${FoodFragmentDoc}`;
export type RemoveAthleteDislikedFoodMutationFn = ApolloReactCommon.MutationFunction<RemoveAthleteDislikedFoodMutation, RemoveAthleteDislikedFoodMutationVariables>;

/**
 * __useRemoveAthleteDislikedFoodMutation__
 *
 * To run a mutation, you first call `useRemoveAthleteDislikedFoodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAthleteDislikedFoodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAthleteDislikedFoodMutation, { data, loading, error }] = useRemoveAthleteDislikedFoodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveAthleteDislikedFoodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveAthleteDislikedFoodMutation, RemoveAthleteDislikedFoodMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveAthleteDislikedFoodMutation, RemoveAthleteDislikedFoodMutationVariables>(RemoveAthleteDislikedFoodDocument, baseOptions);
      }
export type RemoveAthleteDislikedFoodMutationHookResult = ReturnType<typeof useRemoveAthleteDislikedFoodMutation>;
export type RemoveAthleteDislikedFoodMutationResult = ApolloReactCommon.MutationResult<RemoveAthleteDislikedFoodMutation>;
export type RemoveAthleteDislikedFoodMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveAthleteDislikedFoodMutation, RemoveAthleteDislikedFoodMutationVariables>;
export const RemoveAthleteDislikedFoodGroupDocument = gql`
    mutation RemoveAthleteDislikedFoodGroup($input: AthleteFoodGroupPreferenceInput!) {
  removeAthleteDislikedFoodGroup(input: $input) {
    athlete {
      id
      dislikedFoodGroups {
        ...FullFoodGroup
      }
    }
  }
}
    ${FullFoodGroupFragmentDoc}`;
export type RemoveAthleteDislikedFoodGroupMutationFn = ApolloReactCommon.MutationFunction<RemoveAthleteDislikedFoodGroupMutation, RemoveAthleteDislikedFoodGroupMutationVariables>;

/**
 * __useRemoveAthleteDislikedFoodGroupMutation__
 *
 * To run a mutation, you first call `useRemoveAthleteDislikedFoodGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAthleteDislikedFoodGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAthleteDislikedFoodGroupMutation, { data, loading, error }] = useRemoveAthleteDislikedFoodGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveAthleteDislikedFoodGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveAthleteDislikedFoodGroupMutation, RemoveAthleteDislikedFoodGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveAthleteDislikedFoodGroupMutation, RemoveAthleteDislikedFoodGroupMutationVariables>(RemoveAthleteDislikedFoodGroupDocument, baseOptions);
      }
export type RemoveAthleteDislikedFoodGroupMutationHookResult = ReturnType<typeof useRemoveAthleteDislikedFoodGroupMutation>;
export type RemoveAthleteDislikedFoodGroupMutationResult = ApolloReactCommon.MutationResult<RemoveAthleteDislikedFoodGroupMutation>;
export type RemoveAthleteDislikedFoodGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveAthleteDislikedFoodGroupMutation, RemoveAthleteDislikedFoodGroupMutationVariables>;
export const RemoveAthleteLikedFoodDocument = gql`
    mutation RemoveAthleteLikedFood($input: AthleteFoodPreferenceInput!) {
  removeAthleteLikedFood(input: $input) {
    athlete {
      id
      likedFoods {
        ...Food
      }
    }
  }
}
    ${FoodFragmentDoc}`;
export type RemoveAthleteLikedFoodMutationFn = ApolloReactCommon.MutationFunction<RemoveAthleteLikedFoodMutation, RemoveAthleteLikedFoodMutationVariables>;

/**
 * __useRemoveAthleteLikedFoodMutation__
 *
 * To run a mutation, you first call `useRemoveAthleteLikedFoodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAthleteLikedFoodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAthleteLikedFoodMutation, { data, loading, error }] = useRemoveAthleteLikedFoodMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveAthleteLikedFoodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveAthleteLikedFoodMutation, RemoveAthleteLikedFoodMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveAthleteLikedFoodMutation, RemoveAthleteLikedFoodMutationVariables>(RemoveAthleteLikedFoodDocument, baseOptions);
      }
export type RemoveAthleteLikedFoodMutationHookResult = ReturnType<typeof useRemoveAthleteLikedFoodMutation>;
export type RemoveAthleteLikedFoodMutationResult = ApolloReactCommon.MutationResult<RemoveAthleteLikedFoodMutation>;
export type RemoveAthleteLikedFoodMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveAthleteLikedFoodMutation, RemoveAthleteLikedFoodMutationVariables>;
export const RemoveAthleteLikedFoodGroupDocument = gql`
    mutation RemoveAthleteLikedFoodGroup($input: AthleteFoodGroupPreferenceInput!) {
  removeAthleteLikedFoodGroup(input: $input) {
    athlete {
      id
      likedFoodGroups {
        ...FullFoodGroup
      }
    }
  }
}
    ${FullFoodGroupFragmentDoc}`;
export type RemoveAthleteLikedFoodGroupMutationFn = ApolloReactCommon.MutationFunction<RemoveAthleteLikedFoodGroupMutation, RemoveAthleteLikedFoodGroupMutationVariables>;

/**
 * __useRemoveAthleteLikedFoodGroupMutation__
 *
 * To run a mutation, you first call `useRemoveAthleteLikedFoodGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAthleteLikedFoodGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAthleteLikedFoodGroupMutation, { data, loading, error }] = useRemoveAthleteLikedFoodGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveAthleteLikedFoodGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveAthleteLikedFoodGroupMutation, RemoveAthleteLikedFoodGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveAthleteLikedFoodGroupMutation, RemoveAthleteLikedFoodGroupMutationVariables>(RemoveAthleteLikedFoodGroupDocument, baseOptions);
      }
export type RemoveAthleteLikedFoodGroupMutationHookResult = ReturnType<typeof useRemoveAthleteLikedFoodGroupMutation>;
export type RemoveAthleteLikedFoodGroupMutationResult = ApolloReactCommon.MutationResult<RemoveAthleteLikedFoodGroupMutation>;
export type RemoveAthleteLikedFoodGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveAthleteLikedFoodGroupMutation, RemoveAthleteLikedFoodGroupMutationVariables>;
export const RemoveMealOptionDocument = gql`
    mutation RemoveMealOption($input: RemoveMealOptionInput!) {
  removeMealOption(input: $input) {
    mealOptionId
  }
}
    `;
export type RemoveMealOptionMutationFn = ApolloReactCommon.MutationFunction<RemoveMealOptionMutation, RemoveMealOptionMutationVariables>;

/**
 * __useRemoveMealOptionMutation__
 *
 * To run a mutation, you first call `useRemoveMealOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMealOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMealOptionMutation, { data, loading, error }] = useRemoveMealOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMealOptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveMealOptionMutation, RemoveMealOptionMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveMealOptionMutation, RemoveMealOptionMutationVariables>(RemoveMealOptionDocument, baseOptions);
      }
export type RemoveMealOptionMutationHookResult = ReturnType<typeof useRemoveMealOptionMutation>;
export type RemoveMealOptionMutationResult = ApolloReactCommon.MutationResult<RemoveMealOptionMutation>;
export type RemoveMealOptionMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveMealOptionMutation, RemoveMealOptionMutationVariables>;
export const RemoveMealOptionPendingDocument = gql`
    mutation RemoveMealOptionPending($input: RemoveMealOptionPendingInput!) {
  removeMealOptionPending(input: $input) {
    mealOptionPendingId
  }
}
    `;
export type RemoveMealOptionPendingMutationFn = ApolloReactCommon.MutationFunction<RemoveMealOptionPendingMutation, RemoveMealOptionPendingMutationVariables>;

/**
 * __useRemoveMealOptionPendingMutation__
 *
 * To run a mutation, you first call `useRemoveMealOptionPendingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMealOptionPendingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMealOptionPendingMutation, { data, loading, error }] = useRemoveMealOptionPendingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveMealOptionPendingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveMealOptionPendingMutation, RemoveMealOptionPendingMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveMealOptionPendingMutation, RemoveMealOptionPendingMutationVariables>(RemoveMealOptionPendingDocument, baseOptions);
      }
export type RemoveMealOptionPendingMutationHookResult = ReturnType<typeof useRemoveMealOptionPendingMutation>;
export type RemoveMealOptionPendingMutationResult = ApolloReactCommon.MutationResult<RemoveMealOptionPendingMutation>;
export type RemoveMealOptionPendingMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveMealOptionPendingMutation, RemoveMealOptionPendingMutationVariables>;
export const ShareMealOptionPendingDocument = gql`
    mutation ShareMealOptionPending($input: ShareMealOptionPendingInput!) {
  shareMealOptionPending(input: $input) {
    mealOption {
      ...FullMealOption
    }
  }
}
    ${FullMealOptionFragmentDoc}`;
export type ShareMealOptionPendingMutationFn = ApolloReactCommon.MutationFunction<ShareMealOptionPendingMutation, ShareMealOptionPendingMutationVariables>;

/**
 * __useShareMealOptionPendingMutation__
 *
 * To run a mutation, you first call `useShareMealOptionPendingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareMealOptionPendingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareMealOptionPendingMutation, { data, loading, error }] = useShareMealOptionPendingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useShareMealOptionPendingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ShareMealOptionPendingMutation, ShareMealOptionPendingMutationVariables>) {
        return ApolloReactHooks.useMutation<ShareMealOptionPendingMutation, ShareMealOptionPendingMutationVariables>(ShareMealOptionPendingDocument, baseOptions);
      }
export type ShareMealOptionPendingMutationHookResult = ReturnType<typeof useShareMealOptionPendingMutation>;
export type ShareMealOptionPendingMutationResult = ApolloReactCommon.MutationResult<ShareMealOptionPendingMutation>;
export type ShareMealOptionPendingMutationOptions = ApolloReactCommon.BaseMutationOptions<ShareMealOptionPendingMutation, ShareMealOptionPendingMutationVariables>;
export const TextMealPlanToAthleteDocument = gql`
    mutation TextMealPlanToAthlete($input: TextMealPlanToAthleteInput!) {
  textMealPlanToAthlete(input: $input) {
    result
  }
}
    `;
export type TextMealPlanToAthleteMutationFn = ApolloReactCommon.MutationFunction<TextMealPlanToAthleteMutation, TextMealPlanToAthleteMutationVariables>;

/**
 * __useTextMealPlanToAthleteMutation__
 *
 * To run a mutation, you first call `useTextMealPlanToAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTextMealPlanToAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [textMealPlanToAthleteMutation, { data, loading, error }] = useTextMealPlanToAthleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTextMealPlanToAthleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TextMealPlanToAthleteMutation, TextMealPlanToAthleteMutationVariables>) {
        return ApolloReactHooks.useMutation<TextMealPlanToAthleteMutation, TextMealPlanToAthleteMutationVariables>(TextMealPlanToAthleteDocument, baseOptions);
      }
export type TextMealPlanToAthleteMutationHookResult = ReturnType<typeof useTextMealPlanToAthleteMutation>;
export type TextMealPlanToAthleteMutationResult = ApolloReactCommon.MutationResult<TextMealPlanToAthleteMutation>;
export type TextMealPlanToAthleteMutationOptions = ApolloReactCommon.BaseMutationOptions<TextMealPlanToAthleteMutation, TextMealPlanToAthleteMutationVariables>;
export const TextMealPlansToAthletesDocument = gql`
    mutation TextMealPlansToAthletes($input: TextMealPlansToAthletesInput!) {
  textMealPlansToAthletes(input: $input) {
    result
  }
}
    `;
export type TextMealPlansToAthletesMutationFn = ApolloReactCommon.MutationFunction<TextMealPlansToAthletesMutation, TextMealPlansToAthletesMutationVariables>;

/**
 * __useTextMealPlansToAthletesMutation__
 *
 * To run a mutation, you first call `useTextMealPlansToAthletesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTextMealPlansToAthletesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [textMealPlansToAthletesMutation, { data, loading, error }] = useTextMealPlansToAthletesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTextMealPlansToAthletesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TextMealPlansToAthletesMutation, TextMealPlansToAthletesMutationVariables>) {
        return ApolloReactHooks.useMutation<TextMealPlansToAthletesMutation, TextMealPlansToAthletesMutationVariables>(TextMealPlansToAthletesDocument, baseOptions);
      }
export type TextMealPlansToAthletesMutationHookResult = ReturnType<typeof useTextMealPlansToAthletesMutation>;
export type TextMealPlansToAthletesMutationResult = ApolloReactCommon.MutationResult<TextMealPlansToAthletesMutation>;
export type TextMealPlansToAthletesMutationOptions = ApolloReactCommon.BaseMutationOptions<TextMealPlansToAthletesMutation, TextMealPlansToAthletesMutationVariables>;
export const UnarchiveAthleteDocument = gql`
    mutation UnarchiveAthlete($input: UnarchiveAthleteInput!) {
  unarchiveAthlete(input: $input) {
    athlete {
      ...AthleteWithGoalAndAnthro
      anthropometryEntries {
        ...AnthropometryEntry
      }
      orgMembership {
        id
        isActive
      }
    }
  }
}
    ${AthleteWithGoalAndAnthroFragmentDoc}
${AnthropometryEntryFragmentDoc}`;
export type UnarchiveAthleteMutationFn = ApolloReactCommon.MutationFunction<UnarchiveAthleteMutation, UnarchiveAthleteMutationVariables>;

/**
 * __useUnarchiveAthleteMutation__
 *
 * To run a mutation, you first call `useUnarchiveAthleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnarchiveAthleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unarchiveAthleteMutation, { data, loading, error }] = useUnarchiveAthleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUnarchiveAthleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnarchiveAthleteMutation, UnarchiveAthleteMutationVariables>) {
        return ApolloReactHooks.useMutation<UnarchiveAthleteMutation, UnarchiveAthleteMutationVariables>(UnarchiveAthleteDocument, baseOptions);
      }
export type UnarchiveAthleteMutationHookResult = ReturnType<typeof useUnarchiveAthleteMutation>;
export type UnarchiveAthleteMutationResult = ApolloReactCommon.MutationResult<UnarchiveAthleteMutation>;
export type UnarchiveAthleteMutationOptions = ApolloReactCommon.BaseMutationOptions<UnarchiveAthleteMutation, UnarchiveAthleteMutationVariables>;
export const AddMealOptionLocalDocument = gql`
    mutation AddMealOptionLocal($mealTemplateId: ID!, $type: String!) {
  addMealOptionLocal(mealTemplateId: $mealTemplateId, type: $type) @client
}
    `;
export type AddMealOptionLocalMutationFn = ApolloReactCommon.MutationFunction<AddMealOptionLocalMutation, AddMealOptionLocalMutationVariables>;

/**
 * __useAddMealOptionLocalMutation__
 *
 * To run a mutation, you first call `useAddMealOptionLocalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMealOptionLocalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMealOptionLocalMutation, { data, loading, error }] = useAddMealOptionLocalMutation({
 *   variables: {
 *      mealTemplateId: // value for 'mealTemplateId'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useAddMealOptionLocalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddMealOptionLocalMutation, AddMealOptionLocalMutationVariables>) {
        return ApolloReactHooks.useMutation<AddMealOptionLocalMutation, AddMealOptionLocalMutationVariables>(AddMealOptionLocalDocument, baseOptions);
      }
export type AddMealOptionLocalMutationHookResult = ReturnType<typeof useAddMealOptionLocalMutation>;
export type AddMealOptionLocalMutationResult = ApolloReactCommon.MutationResult<AddMealOptionLocalMutation>;
export type AddMealOptionLocalMutationOptions = ApolloReactCommon.BaseMutationOptions<AddMealOptionLocalMutation, AddMealOptionLocalMutationVariables>;
export const CalculateExchangeTotalsDocument = gql`
    mutation CalculateExchangeTotals($mealPlanId: ID!) {
  calculateExchangeTotals(mealPlanId: $mealPlanId) @client
}
    `;
export type CalculateExchangeTotalsMutationFn = ApolloReactCommon.MutationFunction<CalculateExchangeTotalsMutation, CalculateExchangeTotalsMutationVariables>;

/**
 * __useCalculateExchangeTotalsMutation__
 *
 * To run a mutation, you first call `useCalculateExchangeTotalsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalculateExchangeTotalsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calculateExchangeTotalsMutation, { data, loading, error }] = useCalculateExchangeTotalsMutation({
 *   variables: {
 *      mealPlanId: // value for 'mealPlanId'
 *   },
 * });
 */
export function useCalculateExchangeTotalsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CalculateExchangeTotalsMutation, CalculateExchangeTotalsMutationVariables>) {
        return ApolloReactHooks.useMutation<CalculateExchangeTotalsMutation, CalculateExchangeTotalsMutationVariables>(CalculateExchangeTotalsDocument, baseOptions);
      }
export type CalculateExchangeTotalsMutationHookResult = ReturnType<typeof useCalculateExchangeTotalsMutation>;
export type CalculateExchangeTotalsMutationResult = ApolloReactCommon.MutationResult<CalculateExchangeTotalsMutation>;
export type CalculateExchangeTotalsMutationOptions = ApolloReactCommon.BaseMutationOptions<CalculateExchangeTotalsMutation, CalculateExchangeTotalsMutationVariables>;
export const ChangeExchangeTargetDocument = gql`
    mutation ChangeExchangeTarget($mealTemplateId: ID!, $exchangeId: ID!, $target: String!, $pickListServingIds: [ID!]) {
  changeExchangeTarget(
    mealTemplateId: $mealTemplateId
    exchangeId: $exchangeId
    target: $target
    pickListServingIds: $pickListServingIds
  ) @client
}
    `;
export type ChangeExchangeTargetMutationFn = ApolloReactCommon.MutationFunction<ChangeExchangeTargetMutation, ChangeExchangeTargetMutationVariables>;

/**
 * __useChangeExchangeTargetMutation__
 *
 * To run a mutation, you first call `useChangeExchangeTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeExchangeTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeExchangeTargetMutation, { data, loading, error }] = useChangeExchangeTargetMutation({
 *   variables: {
 *      mealTemplateId: // value for 'mealTemplateId'
 *      exchangeId: // value for 'exchangeId'
 *      target: // value for 'target'
 *      pickListServingIds: // value for 'pickListServingIds'
 *   },
 * });
 */
export function useChangeExchangeTargetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeExchangeTargetMutation, ChangeExchangeTargetMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeExchangeTargetMutation, ChangeExchangeTargetMutationVariables>(ChangeExchangeTargetDocument, baseOptions);
      }
export type ChangeExchangeTargetMutationHookResult = ReturnType<typeof useChangeExchangeTargetMutation>;
export type ChangeExchangeTargetMutationResult = ApolloReactCommon.MutationResult<ChangeExchangeTargetMutation>;
export type ChangeExchangeTargetMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeExchangeTargetMutation, ChangeExchangeTargetMutationVariables>;
export const ChangeExchangeTargetTotalDocument = gql`
    mutation ChangeExchangeTargetTotal($mealPlanId: ID!, $exchangeId: ID!, $target: String!) {
  changeExchangeTargetTotal(
    mealPlanId: $mealPlanId
    exchangeId: $exchangeId
    target: $target
  ) @client
}
    `;
export type ChangeExchangeTargetTotalMutationFn = ApolloReactCommon.MutationFunction<ChangeExchangeTargetTotalMutation, ChangeExchangeTargetTotalMutationVariables>;

/**
 * __useChangeExchangeTargetTotalMutation__
 *
 * To run a mutation, you first call `useChangeExchangeTargetTotalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeExchangeTargetTotalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeExchangeTargetTotalMutation, { data, loading, error }] = useChangeExchangeTargetTotalMutation({
 *   variables: {
 *      mealPlanId: // value for 'mealPlanId'
 *      exchangeId: // value for 'exchangeId'
 *      target: // value for 'target'
 *   },
 * });
 */
export function useChangeExchangeTargetTotalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeExchangeTargetTotalMutation, ChangeExchangeTargetTotalMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeExchangeTargetTotalMutation, ChangeExchangeTargetTotalMutationVariables>(ChangeExchangeTargetTotalDocument, baseOptions);
      }
export type ChangeExchangeTargetTotalMutationHookResult = ReturnType<typeof useChangeExchangeTargetTotalMutation>;
export type ChangeExchangeTargetTotalMutationResult = ApolloReactCommon.MutationResult<ChangeExchangeTargetTotalMutation>;
export type ChangeExchangeTargetTotalMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeExchangeTargetTotalMutation, ChangeExchangeTargetTotalMutationVariables>;
export const ChangeMacroTargetDocument = gql`
    mutation ChangeMacroTarget($mealTemplateId: ID!, $macro: MacroType!, $target: String!) {
  changeMacroTarget(
    mealTemplateId: $mealTemplateId
    macro: $macro
    target: $target
  ) @client
}
    `;
export type ChangeMacroTargetMutationFn = ApolloReactCommon.MutationFunction<ChangeMacroTargetMutation, ChangeMacroTargetMutationVariables>;

/**
 * __useChangeMacroTargetMutation__
 *
 * To run a mutation, you first call `useChangeMacroTargetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeMacroTargetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeMacroTargetMutation, { data, loading, error }] = useChangeMacroTargetMutation({
 *   variables: {
 *      mealTemplateId: // value for 'mealTemplateId'
 *      macro: // value for 'macro'
 *      target: // value for 'target'
 *   },
 * });
 */
export function useChangeMacroTargetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ChangeMacroTargetMutation, ChangeMacroTargetMutationVariables>) {
        return ApolloReactHooks.useMutation<ChangeMacroTargetMutation, ChangeMacroTargetMutationVariables>(ChangeMacroTargetDocument, baseOptions);
      }
export type ChangeMacroTargetMutationHookResult = ReturnType<typeof useChangeMacroTargetMutation>;
export type ChangeMacroTargetMutationResult = ApolloReactCommon.MutationResult<ChangeMacroTargetMutation>;
export type ChangeMacroTargetMutationOptions = ApolloReactCommon.BaseMutationOptions<ChangeMacroTargetMutation, ChangeMacroTargetMutationVariables>;
export const DeselectNegativeFoodsDocument = gql`
    mutation DeselectNegativeFoods($mealPlanId: ID!) {
  deselectNegativeFoods(mealPlanId: $mealPlanId) @client
}
    `;
export type DeselectNegativeFoodsMutationFn = ApolloReactCommon.MutationFunction<DeselectNegativeFoodsMutation, DeselectNegativeFoodsMutationVariables>;

/**
 * __useDeselectNegativeFoodsMutation__
 *
 * To run a mutation, you first call `useDeselectNegativeFoodsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeselectNegativeFoodsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deselectNegativeFoodsMutation, { data, loading, error }] = useDeselectNegativeFoodsMutation({
 *   variables: {
 *      mealPlanId: // value for 'mealPlanId'
 *   },
 * });
 */
export function useDeselectNegativeFoodsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeselectNegativeFoodsMutation, DeselectNegativeFoodsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeselectNegativeFoodsMutation, DeselectNegativeFoodsMutationVariables>(DeselectNegativeFoodsDocument, baseOptions);
      }
export type DeselectNegativeFoodsMutationHookResult = ReturnType<typeof useDeselectNegativeFoodsMutation>;
export type DeselectNegativeFoodsMutationResult = ApolloReactCommon.MutationResult<DeselectNegativeFoodsMutation>;
export type DeselectNegativeFoodsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeselectNegativeFoodsMutation, DeselectNegativeFoodsMutationVariables>;
export const DistributeExchangeTargetsDocument = gql`
    mutation DistributeExchangeTargets($mealPlanId: ID!) {
  distributeExchangeTargets(mealPlanId: $mealPlanId) @client
}
    `;
export type DistributeExchangeTargetsMutationFn = ApolloReactCommon.MutationFunction<DistributeExchangeTargetsMutation, DistributeExchangeTargetsMutationVariables>;

/**
 * __useDistributeExchangeTargetsMutation__
 *
 * To run a mutation, you first call `useDistributeExchangeTargetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDistributeExchangeTargetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [distributeExchangeTargetsMutation, { data, loading, error }] = useDistributeExchangeTargetsMutation({
 *   variables: {
 *      mealPlanId: // value for 'mealPlanId'
 *   },
 * });
 */
export function useDistributeExchangeTargetsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DistributeExchangeTargetsMutation, DistributeExchangeTargetsMutationVariables>) {
        return ApolloReactHooks.useMutation<DistributeExchangeTargetsMutation, DistributeExchangeTargetsMutationVariables>(DistributeExchangeTargetsDocument, baseOptions);
      }
export type DistributeExchangeTargetsMutationHookResult = ReturnType<typeof useDistributeExchangeTargetsMutation>;
export type DistributeExchangeTargetsMutationResult = ApolloReactCommon.MutationResult<DistributeExchangeTargetsMutation>;
export type DistributeExchangeTargetsMutationOptions = ApolloReactCommon.BaseMutationOptions<DistributeExchangeTargetsMutation, DistributeExchangeTargetsMutationVariables>;
export const DistributeMacroTargetsDocument = gql`
    mutation DistributeMacroTargets($mealPlanId: ID!) {
  distributeMacroTargets(mealPlanId: $mealPlanId) @client
}
    `;
export type DistributeMacroTargetsMutationFn = ApolloReactCommon.MutationFunction<DistributeMacroTargetsMutation, DistributeMacroTargetsMutationVariables>;

/**
 * __useDistributeMacroTargetsMutation__
 *
 * To run a mutation, you first call `useDistributeMacroTargetsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDistributeMacroTargetsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [distributeMacroTargetsMutation, { data, loading, error }] = useDistributeMacroTargetsMutation({
 *   variables: {
 *      mealPlanId: // value for 'mealPlanId'
 *   },
 * });
 */
export function useDistributeMacroTargetsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DistributeMacroTargetsMutation, DistributeMacroTargetsMutationVariables>) {
        return ApolloReactHooks.useMutation<DistributeMacroTargetsMutation, DistributeMacroTargetsMutationVariables>(DistributeMacroTargetsDocument, baseOptions);
      }
export type DistributeMacroTargetsMutationHookResult = ReturnType<typeof useDistributeMacroTargetsMutation>;
export type DistributeMacroTargetsMutationResult = ApolloReactCommon.MutationResult<DistributeMacroTargetsMutation>;
export type DistributeMacroTargetsMutationOptions = ApolloReactCommon.BaseMutationOptions<DistributeMacroTargetsMutation, DistributeMacroTargetsMutationVariables>;
export const EditMealOptionLocalDocument = gql`
    mutation EditMealOptionLocal($input: EditMealOptionLocalInput!) {
  editMealOptionLocal(input: $input) @client
}
    `;
export type EditMealOptionLocalMutationFn = ApolloReactCommon.MutationFunction<EditMealOptionLocalMutation, EditMealOptionLocalMutationVariables>;

/**
 * __useEditMealOptionLocalMutation__
 *
 * To run a mutation, you first call `useEditMealOptionLocalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMealOptionLocalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMealOptionLocalMutation, { data, loading, error }] = useEditMealOptionLocalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMealOptionLocalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMealOptionLocalMutation, EditMealOptionLocalMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMealOptionLocalMutation, EditMealOptionLocalMutationVariables>(EditMealOptionLocalDocument, baseOptions);
      }
export type EditMealOptionLocalMutationHookResult = ReturnType<typeof useEditMealOptionLocalMutation>;
export type EditMealOptionLocalMutationResult = ApolloReactCommon.MutationResult<EditMealOptionLocalMutation>;
export type EditMealOptionLocalMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMealOptionLocalMutation, EditMealOptionLocalMutationVariables>;
export const EditMealOptionPendingLocalDocument = gql`
    mutation EditMealOptionPendingLocal($input: EditMealOptionPendingLocalInput!) {
  editMealOptionPendingLocal(input: $input) @client
}
    `;
export type EditMealOptionPendingLocalMutationFn = ApolloReactCommon.MutationFunction<EditMealOptionPendingLocalMutation, EditMealOptionPendingLocalMutationVariables>;

/**
 * __useEditMealOptionPendingLocalMutation__
 *
 * To run a mutation, you first call `useEditMealOptionPendingLocalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditMealOptionPendingLocalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editMealOptionPendingLocalMutation, { data, loading, error }] = useEditMealOptionPendingLocalMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEditMealOptionPendingLocalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EditMealOptionPendingLocalMutation, EditMealOptionPendingLocalMutationVariables>) {
        return ApolloReactHooks.useMutation<EditMealOptionPendingLocalMutation, EditMealOptionPendingLocalMutationVariables>(EditMealOptionPendingLocalDocument, baseOptions);
      }
export type EditMealOptionPendingLocalMutationHookResult = ReturnType<typeof useEditMealOptionPendingLocalMutation>;
export type EditMealOptionPendingLocalMutationResult = ApolloReactCommon.MutationResult<EditMealOptionPendingLocalMutation>;
export type EditMealOptionPendingLocalMutationOptions = ApolloReactCommon.BaseMutationOptions<EditMealOptionPendingLocalMutation, EditMealOptionPendingLocalMutationVariables>;
export const RemoveMealOptionLocalDocument = gql`
    mutation RemoveMealOptionLocal($mealTemplateId: ID!, $type: String!, $mealOptionId: ID!) {
  removeMealOptionLocal(
    mealTemplateId: $mealTemplateId
    type: $type
    mealOptionId: $mealOptionId
  ) @client
}
    `;
export type RemoveMealOptionLocalMutationFn = ApolloReactCommon.MutationFunction<RemoveMealOptionLocalMutation, RemoveMealOptionLocalMutationVariables>;

/**
 * __useRemoveMealOptionLocalMutation__
 *
 * To run a mutation, you first call `useRemoveMealOptionLocalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMealOptionLocalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMealOptionLocalMutation, { data, loading, error }] = useRemoveMealOptionLocalMutation({
 *   variables: {
 *      mealTemplateId: // value for 'mealTemplateId'
 *      type: // value for 'type'
 *      mealOptionId: // value for 'mealOptionId'
 *   },
 * });
 */
export function useRemoveMealOptionLocalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveMealOptionLocalMutation, RemoveMealOptionLocalMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveMealOptionLocalMutation, RemoveMealOptionLocalMutationVariables>(RemoveMealOptionLocalDocument, baseOptions);
      }
export type RemoveMealOptionLocalMutationHookResult = ReturnType<typeof useRemoveMealOptionLocalMutation>;
export type RemoveMealOptionLocalMutationResult = ApolloReactCommon.MutationResult<RemoveMealOptionLocalMutation>;
export type RemoveMealOptionLocalMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveMealOptionLocalMutation, RemoveMealOptionLocalMutationVariables>;
export const RemoveMealOptionPendingLocalDocument = gql`
    mutation RemoveMealOptionPendingLocal($mealTemplateId: ID!, $type: String!, $mealOptionPendingId: ID!) {
  removeMealOptionPendingLocal(
    mealTemplateId: $mealTemplateId
    type: $type
    mealOptionPendingId: $mealOptionPendingId
  ) @client
}
    `;
export type RemoveMealOptionPendingLocalMutationFn = ApolloReactCommon.MutationFunction<RemoveMealOptionPendingLocalMutation, RemoveMealOptionPendingLocalMutationVariables>;

/**
 * __useRemoveMealOptionPendingLocalMutation__
 *
 * To run a mutation, you first call `useRemoveMealOptionPendingLocalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveMealOptionPendingLocalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeMealOptionPendingLocalMutation, { data, loading, error }] = useRemoveMealOptionPendingLocalMutation({
 *   variables: {
 *      mealTemplateId: // value for 'mealTemplateId'
 *      type: // value for 'type'
 *      mealOptionPendingId: // value for 'mealOptionPendingId'
 *   },
 * });
 */
export function useRemoveMealOptionPendingLocalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveMealOptionPendingLocalMutation, RemoveMealOptionPendingLocalMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveMealOptionPendingLocalMutation, RemoveMealOptionPendingLocalMutationVariables>(RemoveMealOptionPendingLocalDocument, baseOptions);
      }
export type RemoveMealOptionPendingLocalMutationHookResult = ReturnType<typeof useRemoveMealOptionPendingLocalMutation>;
export type RemoveMealOptionPendingLocalMutationResult = ApolloReactCommon.MutationResult<RemoveMealOptionPendingLocalMutation>;
export type RemoveMealOptionPendingLocalMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveMealOptionPendingLocalMutation, RemoveMealOptionPendingLocalMutationVariables>;
export const SelectMealOptionDocument = gql`
    mutation SelectMealOption($mealTemplateId: ID!, $type: String!, $mealOptionId: ID!) {
  selectMealOption(
    mealTemplateId: $mealTemplateId
    type: $type
    mealOptionId: $mealOptionId
  ) @client
}
    `;
export type SelectMealOptionMutationFn = ApolloReactCommon.MutationFunction<SelectMealOptionMutation, SelectMealOptionMutationVariables>;

/**
 * __useSelectMealOptionMutation__
 *
 * To run a mutation, you first call `useSelectMealOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelectMealOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selectMealOptionMutation, { data, loading, error }] = useSelectMealOptionMutation({
 *   variables: {
 *      mealTemplateId: // value for 'mealTemplateId'
 *      type: // value for 'type'
 *      mealOptionId: // value for 'mealOptionId'
 *   },
 * });
 */
export function useSelectMealOptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SelectMealOptionMutation, SelectMealOptionMutationVariables>) {
        return ApolloReactHooks.useMutation<SelectMealOptionMutation, SelectMealOptionMutationVariables>(SelectMealOptionDocument, baseOptions);
      }
export type SelectMealOptionMutationHookResult = ReturnType<typeof useSelectMealOptionMutation>;
export type SelectMealOptionMutationResult = ApolloReactCommon.MutationResult<SelectMealOptionMutation>;
export type SelectMealOptionMutationOptions = ApolloReactCommon.BaseMutationOptions<SelectMealOptionMutation, SelectMealOptionMutationVariables>;
export const ShareMealOptionPendingLocalDocument = gql`
    mutation ShareMealOptionPendingLocal($mealTemplateId: ID!, $mealOptionPendingId: ID!) {
  shareMealOptionPendingLocal(
    mealTemplateId: $mealTemplateId
    mealOptionPendingId: $mealOptionPendingId
  ) @client
}
    `;
export type ShareMealOptionPendingLocalMutationFn = ApolloReactCommon.MutationFunction<ShareMealOptionPendingLocalMutation, ShareMealOptionPendingLocalMutationVariables>;

/**
 * __useShareMealOptionPendingLocalMutation__
 *
 * To run a mutation, you first call `useShareMealOptionPendingLocalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useShareMealOptionPendingLocalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [shareMealOptionPendingLocalMutation, { data, loading, error }] = useShareMealOptionPendingLocalMutation({
 *   variables: {
 *      mealTemplateId: // value for 'mealTemplateId'
 *      mealOptionPendingId: // value for 'mealOptionPendingId'
 *   },
 * });
 */
export function useShareMealOptionPendingLocalMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ShareMealOptionPendingLocalMutation, ShareMealOptionPendingLocalMutationVariables>) {
        return ApolloReactHooks.useMutation<ShareMealOptionPendingLocalMutation, ShareMealOptionPendingLocalMutationVariables>(ShareMealOptionPendingLocalDocument, baseOptions);
      }
export type ShareMealOptionPendingLocalMutationHookResult = ReturnType<typeof useShareMealOptionPendingLocalMutation>;
export type ShareMealOptionPendingLocalMutationResult = ApolloReactCommon.MutationResult<ShareMealOptionPendingLocalMutation>;
export type ShareMealOptionPendingLocalMutationOptions = ApolloReactCommon.BaseMutationOptions<ShareMealOptionPendingLocalMutation, ShareMealOptionPendingLocalMutationVariables>;
export const AllAthletesDocument = gql`
    query AllAthletes {
  athletes {
    ...AthleteWithAnthro
    team {
      ...TeamWithSport
    }
    position {
      ...Position
    }
  }
}
    ${AthleteWithAnthroFragmentDoc}
${TeamWithSportFragmentDoc}
${PositionFragmentDoc}`;

/**
 * __useAllAthletesQuery__
 *
 * To run a query within a React component, call `useAllAthletesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAthletesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAthletesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllAthletesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllAthletesQuery, AllAthletesQueryVariables>) {
        return ApolloReactHooks.useQuery<AllAthletesQuery, AllAthletesQueryVariables>(AllAthletesDocument, baseOptions);
      }
export function useAllAthletesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllAthletesQuery, AllAthletesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllAthletesQuery, AllAthletesQueryVariables>(AllAthletesDocument, baseOptions);
        }
export type AllAthletesQueryHookResult = ReturnType<typeof useAllAthletesQuery>;
export type AllAthletesLazyQueryHookResult = ReturnType<typeof useAllAthletesLazyQuery>;
export type AllAthletesQueryResult = ApolloReactCommon.QueryResult<AllAthletesQuery, AllAthletesQueryVariables>;
export const AllAthletesAndAnthropometryEntriesDocument = gql`
    query AllAthletesAndAnthropometryEntries {
  athletes {
    ...Athlete
    mostRecentAnthropometryEntry {
      id
      height
    }
    team {
      ...TeamWithSport
    }
    position {
      ...Position
    }
    anthropometryEntries {
      ...AnthropometryEntry
    }
  }
}
    ${AthleteFragmentDoc}
${TeamWithSportFragmentDoc}
${PositionFragmentDoc}
${AnthropometryEntryFragmentDoc}`;

/**
 * __useAllAthletesAndAnthropometryEntriesQuery__
 *
 * To run a query within a React component, call `useAllAthletesAndAnthropometryEntriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAthletesAndAnthropometryEntriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAthletesAndAnthropometryEntriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllAthletesAndAnthropometryEntriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllAthletesAndAnthropometryEntriesQuery, AllAthletesAndAnthropometryEntriesQueryVariables>) {
        return ApolloReactHooks.useQuery<AllAthletesAndAnthropometryEntriesQuery, AllAthletesAndAnthropometryEntriesQueryVariables>(AllAthletesAndAnthropometryEntriesDocument, baseOptions);
      }
export function useAllAthletesAndAnthropometryEntriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllAthletesAndAnthropometryEntriesQuery, AllAthletesAndAnthropometryEntriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllAthletesAndAnthropometryEntriesQuery, AllAthletesAndAnthropometryEntriesQueryVariables>(AllAthletesAndAnthropometryEntriesDocument, baseOptions);
        }
export type AllAthletesAndAnthropometryEntriesQueryHookResult = ReturnType<typeof useAllAthletesAndAnthropometryEntriesQuery>;
export type AllAthletesAndAnthropometryEntriesLazyQueryHookResult = ReturnType<typeof useAllAthletesAndAnthropometryEntriesLazyQuery>;
export type AllAthletesAndAnthropometryEntriesQueryResult = ApolloReactCommon.QueryResult<AllAthletesAndAnthropometryEntriesQuery, AllAthletesAndAnthropometryEntriesQueryVariables>;
export const AthleteAnthropometryDocument = gql`
    query AthleteAnthropometry($id: ID!) {
  athlete(id: $id) {
    ...Athlete
    anthropometryEntries {
      ...AnthropometryEntry
    }
  }
}
    ${AthleteFragmentDoc}
${AnthropometryEntryFragmentDoc}`;

/**
 * __useAthleteAnthropometryQuery__
 *
 * To run a query within a React component, call `useAthleteAnthropometryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteAnthropometryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteAnthropometryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAthleteAnthropometryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthleteAnthropometryQuery, AthleteAnthropometryQueryVariables>) {
        return ApolloReactHooks.useQuery<AthleteAnthropometryQuery, AthleteAnthropometryQueryVariables>(AthleteAnthropometryDocument, baseOptions);
      }
export function useAthleteAnthropometryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthleteAnthropometryQuery, AthleteAnthropometryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthleteAnthropometryQuery, AthleteAnthropometryQueryVariables>(AthleteAnthropometryDocument, baseOptions);
        }
export type AthleteAnthropometryQueryHookResult = ReturnType<typeof useAthleteAnthropometryQuery>;
export type AthleteAnthropometryLazyQueryHookResult = ReturnType<typeof useAthleteAnthropometryLazyQuery>;
export type AthleteAnthropometryQueryResult = ApolloReactCommon.QueryResult<AthleteAnthropometryQuery, AthleteAnthropometryQueryVariables>;
export const AthleteNotesDocument = gql`
    query AthleteNotes($id: ID!) {
  athlete(id: $id) {
    ...Athlete
    notes {
      ...NoteWithType
    }
  }
}
    ${AthleteFragmentDoc}
${NoteWithTypeFragmentDoc}`;

/**
 * __useAthleteNotesQuery__
 *
 * To run a query within a React component, call `useAthleteNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteNotesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAthleteNotesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthleteNotesQuery, AthleteNotesQueryVariables>) {
        return ApolloReactHooks.useQuery<AthleteNotesQuery, AthleteNotesQueryVariables>(AthleteNotesDocument, baseOptions);
      }
export function useAthleteNotesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthleteNotesQuery, AthleteNotesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthleteNotesQuery, AthleteNotesQueryVariables>(AthleteNotesDocument, baseOptions);
        }
export type AthleteNotesQueryHookResult = ReturnType<typeof useAthleteNotesQuery>;
export type AthleteNotesLazyQueryHookResult = ReturnType<typeof useAthleteNotesLazyQuery>;
export type AthleteNotesQueryResult = ApolloReactCommon.QueryResult<AthleteNotesQuery, AthleteNotesQueryVariables>;
export const AthletesMealPlansDocument = gql`
    query AthletesMealPlans {
  athletes {
    ...AthleteWithMealPlans
  }
}
    ${AthleteWithMealPlansFragmentDoc}`;

/**
 * __useAthletesMealPlansQuery__
 *
 * To run a query within a React component, call `useAthletesMealPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthletesMealPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthletesMealPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useAthletesMealPlansQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthletesMealPlansQuery, AthletesMealPlansQueryVariables>) {
        return ApolloReactHooks.useQuery<AthletesMealPlansQuery, AthletesMealPlansQueryVariables>(AthletesMealPlansDocument, baseOptions);
      }
export function useAthletesMealPlansLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthletesMealPlansQuery, AthletesMealPlansQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthletesMealPlansQuery, AthletesMealPlansQueryVariables>(AthletesMealPlansDocument, baseOptions);
        }
export type AthletesMealPlansQueryHookResult = ReturnType<typeof useAthletesMealPlansQuery>;
export type AthletesMealPlansLazyQueryHookResult = ReturnType<typeof useAthletesMealPlansLazyQuery>;
export type AthletesMealPlansQueryResult = ApolloReactCommon.QueryResult<AthletesMealPlansQuery, AthletesMealPlansQueryVariables>;
export const MealPlanForCopyCursorConnectionDocument = gql`
    query MealPlanForCopyCursorConnection($pagination: CursorPaginationInput!, $athleteName: String, $mealPlanName: String) {
  mealPlanForCopyCursorConnection(
    input: $pagination
    athleteName: $athleteName
    mealPlanName: $mealPlanName
  ) {
    edges {
      ...MealPlanWithAthlete
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    ${MealPlanWithAthleteFragmentDoc}`;

/**
 * __useMealPlanForCopyCursorConnectionQuery__
 *
 * To run a query within a React component, call `useMealPlanForCopyCursorConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealPlanForCopyCursorConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealPlanForCopyCursorConnectionQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      athleteName: // value for 'athleteName'
 *      mealPlanName: // value for 'mealPlanName'
 *   },
 * });
 */
export function useMealPlanForCopyCursorConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealPlanForCopyCursorConnectionQuery, MealPlanForCopyCursorConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<MealPlanForCopyCursorConnectionQuery, MealPlanForCopyCursorConnectionQueryVariables>(MealPlanForCopyCursorConnectionDocument, baseOptions);
      }
export function useMealPlanForCopyCursorConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealPlanForCopyCursorConnectionQuery, MealPlanForCopyCursorConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealPlanForCopyCursorConnectionQuery, MealPlanForCopyCursorConnectionQueryVariables>(MealPlanForCopyCursorConnectionDocument, baseOptions);
        }
export type MealPlanForCopyCursorConnectionQueryHookResult = ReturnType<typeof useMealPlanForCopyCursorConnectionQuery>;
export type MealPlanForCopyCursorConnectionLazyQueryHookResult = ReturnType<typeof useMealPlanForCopyCursorConnectionLazyQuery>;
export type MealPlanForCopyCursorConnectionQueryResult = ApolloReactCommon.QueryResult<MealPlanForCopyCursorConnectionQuery, MealPlanForCopyCursorConnectionQueryVariables>;
export const AthletesPreviewDocument = gql`
    query AthletesPreview {
  athletes {
    ...Athlete
  }
}
    ${AthleteFragmentDoc}`;

/**
 * __useAthletesPreviewQuery__
 *
 * To run a query within a React component, call `useAthletesPreviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthletesPreviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthletesPreviewQuery({
 *   variables: {
 *   },
 * });
 */
export function useAthletesPreviewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthletesPreviewQuery, AthletesPreviewQueryVariables>) {
        return ApolloReactHooks.useQuery<AthletesPreviewQuery, AthletesPreviewQueryVariables>(AthletesPreviewDocument, baseOptions);
      }
export function useAthletesPreviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthletesPreviewQuery, AthletesPreviewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthletesPreviewQuery, AthletesPreviewQueryVariables>(AthletesPreviewDocument, baseOptions);
        }
export type AthletesPreviewQueryHookResult = ReturnType<typeof useAthletesPreviewQuery>;
export type AthletesPreviewLazyQueryHookResult = ReturnType<typeof useAthletesPreviewLazyQuery>;
export type AthletesPreviewQueryResult = ApolloReactCommon.QueryResult<AthletesPreviewQuery, AthletesPreviewQueryVariables>;
export const AthleteSearchDocument = gql`
    query AthleteSearch($query: String!, $limit: Int) {
  athleteSearch(query: $query, limit: $limit) {
    ...Athlete
  }
}
    ${AthleteFragmentDoc}`;

/**
 * __useAthleteSearchQuery__
 *
 * To run a query within a React component, call `useAthleteSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useAthleteSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAthleteSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useAthleteSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AthleteSearchQuery, AthleteSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<AthleteSearchQuery, AthleteSearchQueryVariables>(AthleteSearchDocument, baseOptions);
      }
export function useAthleteSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AthleteSearchQuery, AthleteSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AthleteSearchQuery, AthleteSearchQueryVariables>(AthleteSearchDocument, baseOptions);
        }
export type AthleteSearchQueryHookResult = ReturnType<typeof useAthleteSearchQuery>;
export type AthleteSearchLazyQueryHookResult = ReturnType<typeof useAthleteSearchLazyQuery>;
export type AthleteSearchQueryResult = ApolloReactCommon.QueryResult<AthleteSearchQuery, AthleteSearchQueryVariables>;
export const TeamAthletesSelectFieldDocument = gql`
    query TeamAthletesSelectField($teamId: ID!) {
  team(id: $teamId) {
    athletes {
      ...Athlete
    }
  }
}
    ${AthleteFragmentDoc}`;

/**
 * __useTeamAthletesSelectFieldQuery__
 *
 * To run a query within a React component, call `useTeamAthletesSelectFieldQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamAthletesSelectFieldQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamAthletesSelectFieldQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useTeamAthletesSelectFieldQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamAthletesSelectFieldQuery, TeamAthletesSelectFieldQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamAthletesSelectFieldQuery, TeamAthletesSelectFieldQueryVariables>(TeamAthletesSelectFieldDocument, baseOptions);
      }
export function useTeamAthletesSelectFieldLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamAthletesSelectFieldQuery, TeamAthletesSelectFieldQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamAthletesSelectFieldQuery, TeamAthletesSelectFieldQueryVariables>(TeamAthletesSelectFieldDocument, baseOptions);
        }
export type TeamAthletesSelectFieldQueryHookResult = ReturnType<typeof useTeamAthletesSelectFieldQuery>;
export type TeamAthletesSelectFieldLazyQueryHookResult = ReturnType<typeof useTeamAthletesSelectFieldLazyQuery>;
export type TeamAthletesSelectFieldQueryResult = ApolloReactCommon.QueryResult<TeamAthletesSelectFieldQuery, TeamAthletesSelectFieldQueryVariables>;
export const BrandsDocument = gql`
    query Brands($searchTerm: String!, $limit: Int) {
  brands(searchTerm: $searchTerm, limit: $limit) {
    ...Brand
  }
}
    ${BrandFragmentDoc}`;

/**
 * __useBrandsQuery__
 *
 * To run a query within a React component, call `useBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useBrandsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BrandsQuery, BrandsQueryVariables>) {
        return ApolloReactHooks.useQuery<BrandsQuery, BrandsQueryVariables>(BrandsDocument, baseOptions);
      }
export function useBrandsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BrandsQuery, BrandsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BrandsQuery, BrandsQueryVariables>(BrandsDocument, baseOptions);
        }
export type BrandsQueryHookResult = ReturnType<typeof useBrandsQuery>;
export type BrandsLazyQueryHookResult = ReturnType<typeof useBrandsLazyQuery>;
export type BrandsQueryResult = ApolloReactCommon.QueryResult<BrandsQuery, BrandsQueryVariables>;
export const GetEditMealPlanDocument = gql`
    query GetEditMealPlan($mealPlanId: ID!) {
  mealPlan(id: $mealPlanId) {
    ...EditFullMealPlan
  }
}
    ${EditFullMealPlanFragmentDoc}`;

/**
 * __useGetEditMealPlanQuery__
 *
 * To run a query within a React component, call `useGetEditMealPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEditMealPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEditMealPlanQuery({
 *   variables: {
 *      mealPlanId: // value for 'mealPlanId'
 *   },
 * });
 */
export function useGetEditMealPlanQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEditMealPlanQuery, GetEditMealPlanQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEditMealPlanQuery, GetEditMealPlanQueryVariables>(GetEditMealPlanDocument, baseOptions);
      }
export function useGetEditMealPlanLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEditMealPlanQuery, GetEditMealPlanQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEditMealPlanQuery, GetEditMealPlanQueryVariables>(GetEditMealPlanDocument, baseOptions);
        }
export type GetEditMealPlanQueryHookResult = ReturnType<typeof useGetEditMealPlanQuery>;
export type GetEditMealPlanLazyQueryHookResult = ReturnType<typeof useGetEditMealPlanLazyQuery>;
export type GetEditMealPlanQueryResult = ApolloReactCommon.QueryResult<GetEditMealPlanQuery, GetEditMealPlanQueryVariables>;
export const ExchangeSetDocument = gql`
    query ExchangeSet($id: ID!) {
  exchangeSet(id: $id) {
    ...FullExchangeSet
  }
}
    ${FullExchangeSetFragmentDoc}`;

/**
 * __useExchangeSetQuery__
 *
 * To run a query within a React component, call `useExchangeSetQuery` and pass it any options that fit your needs.
 * When your component renders, `useExchangeSetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExchangeSetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useExchangeSetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExchangeSetQuery, ExchangeSetQueryVariables>) {
        return ApolloReactHooks.useQuery<ExchangeSetQuery, ExchangeSetQueryVariables>(ExchangeSetDocument, baseOptions);
      }
export function useExchangeSetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExchangeSetQuery, ExchangeSetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExchangeSetQuery, ExchangeSetQueryVariables>(ExchangeSetDocument, baseOptions);
        }
export type ExchangeSetQueryHookResult = ReturnType<typeof useExchangeSetQuery>;
export type ExchangeSetLazyQueryHookResult = ReturnType<typeof useExchangeSetLazyQuery>;
export type ExchangeSetQueryResult = ApolloReactCommon.QueryResult<ExchangeSetQuery, ExchangeSetQueryVariables>;
export const ExchangeSetsDocument = gql`
    query ExchangeSets {
  exchangeSets {
    ...ExchangeSetWithExchanges
  }
}
    ${ExchangeSetWithExchangesFragmentDoc}`;

/**
 * __useExchangeSetsQuery__
 *
 * To run a query within a React component, call `useExchangeSetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useExchangeSetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExchangeSetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useExchangeSetsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ExchangeSetsQuery, ExchangeSetsQueryVariables>) {
        return ApolloReactHooks.useQuery<ExchangeSetsQuery, ExchangeSetsQueryVariables>(ExchangeSetsDocument, baseOptions);
      }
export function useExchangeSetsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ExchangeSetsQuery, ExchangeSetsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ExchangeSetsQuery, ExchangeSetsQueryVariables>(ExchangeSetsDocument, baseOptions);
        }
export type ExchangeSetsQueryHookResult = ReturnType<typeof useExchangeSetsQuery>;
export type ExchangeSetsLazyQueryHookResult = ReturnType<typeof useExchangeSetsLazyQuery>;
export type ExchangeSetsQueryResult = ApolloReactCommon.QueryResult<ExchangeSetsQuery, ExchangeSetsQueryVariables>;
export const FoodGroupDocument = gql`
    query FoodGroup($id: ID!) {
  foodGroup(id: $id) {
    ...FullFoodGroupWithFoods
  }
}
    ${FullFoodGroupWithFoodsFragmentDoc}`;

/**
 * __useFoodGroupQuery__
 *
 * To run a query within a React component, call `useFoodGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodGroupQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFoodGroupQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FoodGroupQuery, FoodGroupQueryVariables>) {
        return ApolloReactHooks.useQuery<FoodGroupQuery, FoodGroupQueryVariables>(FoodGroupDocument, baseOptions);
      }
export function useFoodGroupLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FoodGroupQuery, FoodGroupQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FoodGroupQuery, FoodGroupQueryVariables>(FoodGroupDocument, baseOptions);
        }
export type FoodGroupQueryHookResult = ReturnType<typeof useFoodGroupQuery>;
export type FoodGroupLazyQueryHookResult = ReturnType<typeof useFoodGroupLazyQuery>;
export type FoodGroupQueryResult = ApolloReactCommon.QueryResult<FoodGroupQuery, FoodGroupQueryVariables>;
export const FoodGroupsDocument = gql`
    query FoodGroups($searchTerm: String!, $limit: Int) {
  foodGroups(searchTerm: $searchTerm, limit: $limit) {
    ...SimpleFoodGroup
  }
}
    ${SimpleFoodGroupFragmentDoc}`;

/**
 * __useFoodGroupsQuery__
 *
 * To run a query within a React component, call `useFoodGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodGroupsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useFoodGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FoodGroupsQuery, FoodGroupsQueryVariables>) {
        return ApolloReactHooks.useQuery<FoodGroupsQuery, FoodGroupsQueryVariables>(FoodGroupsDocument, baseOptions);
      }
export function useFoodGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FoodGroupsQuery, FoodGroupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FoodGroupsQuery, FoodGroupsQueryVariables>(FoodGroupsDocument, baseOptions);
        }
export type FoodGroupsQueryHookResult = ReturnType<typeof useFoodGroupsQuery>;
export type FoodGroupsLazyQueryHookResult = ReturnType<typeof useFoodGroupsLazyQuery>;
export type FoodGroupsQueryResult = ApolloReactCommon.QueryResult<FoodGroupsQuery, FoodGroupsQueryVariables>;
export const SharedFoodGroupsTableDocument = gql`
    query SharedFoodGroupsTable($query: String, $input: OffsetPaginationInput!, $sortOrder: SortOrder!) {
  sharedFoodGroupsOffsetConnection(
    query: $query
    input: $input
    sortOrder: $sortOrder
  ) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...FullFoodGroup
    }
  }
}
    ${FullFoodGroupFragmentDoc}`;

/**
 * __useSharedFoodGroupsTableQuery__
 *
 * To run a query within a React component, call `useSharedFoodGroupsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharedFoodGroupsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharedFoodGroupsTableQuery({
 *   variables: {
 *      query: // value for 'query'
 *      input: // value for 'input'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useSharedFoodGroupsTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SharedFoodGroupsTableQuery, SharedFoodGroupsTableQueryVariables>) {
        return ApolloReactHooks.useQuery<SharedFoodGroupsTableQuery, SharedFoodGroupsTableQueryVariables>(SharedFoodGroupsTableDocument, baseOptions);
      }
export function useSharedFoodGroupsTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SharedFoodGroupsTableQuery, SharedFoodGroupsTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SharedFoodGroupsTableQuery, SharedFoodGroupsTableQueryVariables>(SharedFoodGroupsTableDocument, baseOptions);
        }
export type SharedFoodGroupsTableQueryHookResult = ReturnType<typeof useSharedFoodGroupsTableQuery>;
export type SharedFoodGroupsTableLazyQueryHookResult = ReturnType<typeof useSharedFoodGroupsTableLazyQuery>;
export type SharedFoodGroupsTableQueryResult = ApolloReactCommon.QueryResult<SharedFoodGroupsTableQuery, SharedFoodGroupsTableQueryVariables>;
export const OrgFoodGroupsTableDocument = gql`
    query OrgFoodGroupsTable($query: String, $input: OffsetPaginationInput!, $sortOrder: SortOrder!) {
  orgFoodGroupsOffsetConnection(
    query: $query
    input: $input
    sortOrder: $sortOrder
  ) {
    pageInfo {
      hasNextPage
      total
      endOffset
    }
    edges {
      ...FullFoodGroup
    }
  }
}
    ${FullFoodGroupFragmentDoc}`;

/**
 * __useOrgFoodGroupsTableQuery__
 *
 * To run a query within a React component, call `useOrgFoodGroupsTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgFoodGroupsTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgFoodGroupsTableQuery({
 *   variables: {
 *      query: // value for 'query'
 *      input: // value for 'input'
 *      sortOrder: // value for 'sortOrder'
 *   },
 * });
 */
export function useOrgFoodGroupsTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrgFoodGroupsTableQuery, OrgFoodGroupsTableQueryVariables>) {
        return ApolloReactHooks.useQuery<OrgFoodGroupsTableQuery, OrgFoodGroupsTableQueryVariables>(OrgFoodGroupsTableDocument, baseOptions);
      }
export function useOrgFoodGroupsTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrgFoodGroupsTableQuery, OrgFoodGroupsTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrgFoodGroupsTableQuery, OrgFoodGroupsTableQueryVariables>(OrgFoodGroupsTableDocument, baseOptions);
        }
export type OrgFoodGroupsTableQueryHookResult = ReturnType<typeof useOrgFoodGroupsTableQuery>;
export type OrgFoodGroupsTableLazyQueryHookResult = ReturnType<typeof useOrgFoodGroupsTableLazyQuery>;
export type OrgFoodGroupsTableQueryResult = ApolloReactCommon.QueryResult<OrgFoodGroupsTableQuery, OrgFoodGroupsTableQueryVariables>;
export const SharedFoodGroupsCursorConnectionDocument = gql`
    query SharedFoodGroupsCursorConnection($searchText: String!, $pagination: CursorPaginationInput!) {
  sharedFoodGroupsCursorConnection(
    searchText: $searchText
    pagination: $pagination
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      ...SimpleFoodGroup
    }
  }
}
    ${SimpleFoodGroupFragmentDoc}`;

/**
 * __useSharedFoodGroupsCursorConnectionQuery__
 *
 * To run a query within a React component, call `useSharedFoodGroupsCursorConnectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharedFoodGroupsCursorConnectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharedFoodGroupsCursorConnectionQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useSharedFoodGroupsCursorConnectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SharedFoodGroupsCursorConnectionQuery, SharedFoodGroupsCursorConnectionQueryVariables>) {
        return ApolloReactHooks.useQuery<SharedFoodGroupsCursorConnectionQuery, SharedFoodGroupsCursorConnectionQueryVariables>(SharedFoodGroupsCursorConnectionDocument, baseOptions);
      }
export function useSharedFoodGroupsCursorConnectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SharedFoodGroupsCursorConnectionQuery, SharedFoodGroupsCursorConnectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SharedFoodGroupsCursorConnectionQuery, SharedFoodGroupsCursorConnectionQueryVariables>(SharedFoodGroupsCursorConnectionDocument, baseOptions);
        }
export type SharedFoodGroupsCursorConnectionQueryHookResult = ReturnType<typeof useSharedFoodGroupsCursorConnectionQuery>;
export type SharedFoodGroupsCursorConnectionLazyQueryHookResult = ReturnType<typeof useSharedFoodGroupsCursorConnectionLazyQuery>;
export type SharedFoodGroupsCursorConnectionQueryResult = ApolloReactCommon.QueryResult<SharedFoodGroupsCursorConnectionQuery, SharedFoodGroupsCursorConnectionQueryVariables>;
export const FoodOrRecipeDocument = gql`
    query FoodOrRecipe($id: ID!) {
  foodOrRecipe(id: $id) {
    ... on Food {
      ...FoodWithServings
    }
    ... on Recipe {
      ...RecipeWithServings
    }
  }
}
    ${FoodWithServingsFragmentDoc}
${RecipeWithServingsFragmentDoc}`;

/**
 * __useFoodOrRecipeQuery__
 *
 * To run a query within a React component, call `useFoodOrRecipeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodOrRecipeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodOrRecipeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFoodOrRecipeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FoodOrRecipeQuery, FoodOrRecipeQueryVariables>) {
        return ApolloReactHooks.useQuery<FoodOrRecipeQuery, FoodOrRecipeQueryVariables>(FoodOrRecipeDocument, baseOptions);
      }
export function useFoodOrRecipeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FoodOrRecipeQuery, FoodOrRecipeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FoodOrRecipeQuery, FoodOrRecipeQueryVariables>(FoodOrRecipeDocument, baseOptions);
        }
export type FoodOrRecipeQueryHookResult = ReturnType<typeof useFoodOrRecipeQuery>;
export type FoodOrRecipeLazyQueryHookResult = ReturnType<typeof useFoodOrRecipeLazyQuery>;
export type FoodOrRecipeQueryResult = ApolloReactCommon.QueryResult<FoodOrRecipeQuery, FoodOrRecipeQueryVariables>;
export const FoodsWithDefaultServingOnlyDocument = gql`
    query FoodsWithDefaultServingOnly($searchTerm: String!, $limit: Int, $localeCodes: [String!], $dataSources: [FoodDataSource!]) {
  foods(
    searchTerm: $searchTerm
    limit: $limit
    localeCodes: $localeCodes
    dataSources: $dataSources
  ) {
    ...FoodWithDefaultServing
  }
}
    ${FoodWithDefaultServingFragmentDoc}`;

/**
 * __useFoodsWithDefaultServingOnlyQuery__
 *
 * To run a query within a React component, call `useFoodsWithDefaultServingOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodsWithDefaultServingOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodsWithDefaultServingOnlyQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      limit: // value for 'limit'
 *      localeCodes: // value for 'localeCodes'
 *      dataSources: // value for 'dataSources'
 *   },
 * });
 */
export function useFoodsWithDefaultServingOnlyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FoodsWithDefaultServingOnlyQuery, FoodsWithDefaultServingOnlyQueryVariables>) {
        return ApolloReactHooks.useQuery<FoodsWithDefaultServingOnlyQuery, FoodsWithDefaultServingOnlyQueryVariables>(FoodsWithDefaultServingOnlyDocument, baseOptions);
      }
export function useFoodsWithDefaultServingOnlyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FoodsWithDefaultServingOnlyQuery, FoodsWithDefaultServingOnlyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FoodsWithDefaultServingOnlyQuery, FoodsWithDefaultServingOnlyQueryVariables>(FoodsWithDefaultServingOnlyDocument, baseOptions);
        }
export type FoodsWithDefaultServingOnlyQueryHookResult = ReturnType<typeof useFoodsWithDefaultServingOnlyQuery>;
export type FoodsWithDefaultServingOnlyLazyQueryHookResult = ReturnType<typeof useFoodsWithDefaultServingOnlyLazyQuery>;
export type FoodsWithDefaultServingOnlyQueryResult = ApolloReactCommon.QueryResult<FoodsWithDefaultServingOnlyQuery, FoodsWithDefaultServingOnlyQueryVariables>;
export const RestaurantFoodsWithDefaultServingOnlyDocument = gql`
    query RestaurantFoodsWithDefaultServingOnly($searchTerm: String!, $limit: Int, $restaurantId: ID!) {
  restaurantFoods(
    searchTerm: $searchTerm
    limit: $limit
    restaurantId: $restaurantId
  ) {
    ...FoodWithDefaultServing
  }
}
    ${FoodWithDefaultServingFragmentDoc}`;

/**
 * __useRestaurantFoodsWithDefaultServingOnlyQuery__
 *
 * To run a query within a React component, call `useRestaurantFoodsWithDefaultServingOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useRestaurantFoodsWithDefaultServingOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRestaurantFoodsWithDefaultServingOnlyQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      limit: // value for 'limit'
 *      restaurantId: // value for 'restaurantId'
 *   },
 * });
 */
export function useRestaurantFoodsWithDefaultServingOnlyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RestaurantFoodsWithDefaultServingOnlyQuery, RestaurantFoodsWithDefaultServingOnlyQueryVariables>) {
        return ApolloReactHooks.useQuery<RestaurantFoodsWithDefaultServingOnlyQuery, RestaurantFoodsWithDefaultServingOnlyQueryVariables>(RestaurantFoodsWithDefaultServingOnlyDocument, baseOptions);
      }
export function useRestaurantFoodsWithDefaultServingOnlyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RestaurantFoodsWithDefaultServingOnlyQuery, RestaurantFoodsWithDefaultServingOnlyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RestaurantFoodsWithDefaultServingOnlyQuery, RestaurantFoodsWithDefaultServingOnlyQueryVariables>(RestaurantFoodsWithDefaultServingOnlyDocument, baseOptions);
        }
export type RestaurantFoodsWithDefaultServingOnlyQueryHookResult = ReturnType<typeof useRestaurantFoodsWithDefaultServingOnlyQuery>;
export type RestaurantFoodsWithDefaultServingOnlyLazyQueryHookResult = ReturnType<typeof useRestaurantFoodsWithDefaultServingOnlyLazyQuery>;
export type RestaurantFoodsWithDefaultServingOnlyQueryResult = ApolloReactCommon.QueryResult<RestaurantFoodsWithDefaultServingOnlyQuery, RestaurantFoodsWithDefaultServingOnlyQueryVariables>;
export const GenericFoodsDocument = gql`
    query GenericFoods($searchTerm: String!, $limit: Int) {
  genericFoods(searchTerm: $searchTerm, limit: $limit) {
    ...Food
  }
}
    ${FoodFragmentDoc}`;

/**
 * __useGenericFoodsQuery__
 *
 * To run a query within a React component, call `useGenericFoodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGenericFoodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGenericFoodsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGenericFoodsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GenericFoodsQuery, GenericFoodsQueryVariables>) {
        return ApolloReactHooks.useQuery<GenericFoodsQuery, GenericFoodsQueryVariables>(GenericFoodsDocument, baseOptions);
      }
export function useGenericFoodsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GenericFoodsQuery, GenericFoodsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GenericFoodsQuery, GenericFoodsQueryVariables>(GenericFoodsDocument, baseOptions);
        }
export type GenericFoodsQueryHookResult = ReturnType<typeof useGenericFoodsQuery>;
export type GenericFoodsLazyQueryHookResult = ReturnType<typeof useGenericFoodsLazyQuery>;
export type GenericFoodsQueryResult = ApolloReactCommon.QueryResult<GenericFoodsQuery, GenericFoodsQueryVariables>;
export const GoalTypesDocument = gql`
    query GoalTypes {
  goalTypes {
    ...GoalType
  }
}
    ${GoalTypeFragmentDoc}`;

/**
 * __useGoalTypesQuery__
 *
 * To run a query within a React component, call `useGoalTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGoalTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGoalTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGoalTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GoalTypesQuery, GoalTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<GoalTypesQuery, GoalTypesQueryVariables>(GoalTypesDocument, baseOptions);
      }
export function useGoalTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GoalTypesQuery, GoalTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GoalTypesQuery, GoalTypesQueryVariables>(GoalTypesDocument, baseOptions);
        }
export type GoalTypesQueryHookResult = ReturnType<typeof useGoalTypesQuery>;
export type GoalTypesLazyQueryHookResult = ReturnType<typeof useGoalTypesLazyQuery>;
export type GoalTypesQueryResult = ApolloReactCommon.QueryResult<GoalTypesQuery, GoalTypesQueryVariables>;
export const GroceryListCategoriesDocument = gql`
    query GroceryListCategories {
  groceryListCategories {
    id
    name
  }
}
    `;

/**
 * __useGroceryListCategoriesQuery__
 *
 * To run a query within a React component, call `useGroceryListCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroceryListCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroceryListCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGroceryListCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GroceryListCategoriesQuery, GroceryListCategoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<GroceryListCategoriesQuery, GroceryListCategoriesQueryVariables>(GroceryListCategoriesDocument, baseOptions);
      }
export function useGroceryListCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GroceryListCategoriesQuery, GroceryListCategoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GroceryListCategoriesQuery, GroceryListCategoriesQueryVariables>(GroceryListCategoriesDocument, baseOptions);
        }
export type GroceryListCategoriesQueryHookResult = ReturnType<typeof useGroceryListCategoriesQuery>;
export type GroceryListCategoriesLazyQueryHookResult = ReturnType<typeof useGroceryListCategoriesLazyQuery>;
export type GroceryListCategoriesQueryResult = ApolloReactCommon.QueryResult<GroceryListCategoriesQuery, GroceryListCategoriesQueryVariables>;
export const MealPlanBulkCopyChooseAthletesContainerDocument = gql`
    query MealPlanBulkCopyChooseAthletesContainer($teamId: ID!) {
  team(id: $teamId) {
    ...Team
    athletes {
      ...AthleteWithGoalAndAnthro
    }
  }
  exchangeSets {
    ...ExchangeSet
  }
}
    ${TeamFragmentDoc}
${AthleteWithGoalAndAnthroFragmentDoc}
${ExchangeSetFragmentDoc}`;

/**
 * __useMealPlanBulkCopyChooseAthletesContainerQuery__
 *
 * To run a query within a React component, call `useMealPlanBulkCopyChooseAthletesContainerQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealPlanBulkCopyChooseAthletesContainerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealPlanBulkCopyChooseAthletesContainerQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useMealPlanBulkCopyChooseAthletesContainerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealPlanBulkCopyChooseAthletesContainerQuery, MealPlanBulkCopyChooseAthletesContainerQueryVariables>) {
        return ApolloReactHooks.useQuery<MealPlanBulkCopyChooseAthletesContainerQuery, MealPlanBulkCopyChooseAthletesContainerQueryVariables>(MealPlanBulkCopyChooseAthletesContainerDocument, baseOptions);
      }
export function useMealPlanBulkCopyChooseAthletesContainerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealPlanBulkCopyChooseAthletesContainerQuery, MealPlanBulkCopyChooseAthletesContainerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealPlanBulkCopyChooseAthletesContainerQuery, MealPlanBulkCopyChooseAthletesContainerQueryVariables>(MealPlanBulkCopyChooseAthletesContainerDocument, baseOptions);
        }
export type MealPlanBulkCopyChooseAthletesContainerQueryHookResult = ReturnType<typeof useMealPlanBulkCopyChooseAthletesContainerQuery>;
export type MealPlanBulkCopyChooseAthletesContainerLazyQueryHookResult = ReturnType<typeof useMealPlanBulkCopyChooseAthletesContainerLazyQuery>;
export type MealPlanBulkCopyChooseAthletesContainerQueryResult = ApolloReactCommon.QueryResult<MealPlanBulkCopyChooseAthletesContainerQuery, MealPlanBulkCopyChooseAthletesContainerQueryVariables>;
export const MealPlanBulkCopyDocument = gql`
    query MealPlanBulkCopy {
  teams {
    ...MealPlanBulkCopyTeamPreview
  }
  exchangeSets {
    ...ExchangeSet
  }
}
    ${MealPlanBulkCopyTeamPreviewFragmentDoc}
${ExchangeSetFragmentDoc}`;

/**
 * __useMealPlanBulkCopyQuery__
 *
 * To run a query within a React component, call `useMealPlanBulkCopyQuery` and pass it any options that fit your needs.
 * When your component renders, `useMealPlanBulkCopyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMealPlanBulkCopyQuery({
 *   variables: {
 *   },
 * });
 */
export function useMealPlanBulkCopyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MealPlanBulkCopyQuery, MealPlanBulkCopyQueryVariables>) {
        return ApolloReactHooks.useQuery<MealPlanBulkCopyQuery, MealPlanBulkCopyQueryVariables>(MealPlanBulkCopyDocument, baseOptions);
      }
export function useMealPlanBulkCopyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MealPlanBulkCopyQuery, MealPlanBulkCopyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MealPlanBulkCopyQuery, MealPlanBulkCopyQueryVariables>(MealPlanBulkCopyDocument, baseOptions);
        }
export type MealPlanBulkCopyQueryHookResult = ReturnType<typeof useMealPlanBulkCopyQuery>;
export type MealPlanBulkCopyLazyQueryHookResult = ReturnType<typeof useMealPlanBulkCopyLazyQuery>;
export type MealPlanBulkCopyQueryResult = ApolloReactCommon.QueryResult<MealPlanBulkCopyQuery, MealPlanBulkCopyQueryVariables>;
export const NoteTypesDocument = gql`
    query NoteTypes {
  noteTypes {
    ...NoteType
  }
}
    ${NoteTypeFragmentDoc}`;

/**
 * __useNoteTypesQuery__
 *
 * To run a query within a React component, call `useNoteTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNoteTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNoteTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useNoteTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NoteTypesQuery, NoteTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<NoteTypesQuery, NoteTypesQueryVariables>(NoteTypesDocument, baseOptions);
      }
export function useNoteTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NoteTypesQuery, NoteTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NoteTypesQuery, NoteTypesQueryVariables>(NoteTypesDocument, baseOptions);
        }
export type NoteTypesQueryHookResult = ReturnType<typeof useNoteTypesQuery>;
export type NoteTypesLazyQueryHookResult = ReturnType<typeof useNoteTypesLazyQuery>;
export type NoteTypesQueryResult = ApolloReactCommon.QueryResult<NoteTypesQuery, NoteTypesQueryVariables>;
export const OrgFoodGroupsDocument = gql`
    query OrgFoodGroups($searchTerm: String!, $limit: Int) {
  orgFoodGroups(searchTerm: $searchTerm, limit: $limit) {
    ...FullFoodGroup
  }
}
    ${FullFoodGroupFragmentDoc}`;

/**
 * __useOrgFoodGroupsQuery__
 *
 * To run a query within a React component, call `useOrgFoodGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgFoodGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgFoodGroupsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useOrgFoodGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrgFoodGroupsQuery, OrgFoodGroupsQueryVariables>) {
        return ApolloReactHooks.useQuery<OrgFoodGroupsQuery, OrgFoodGroupsQueryVariables>(OrgFoodGroupsDocument, baseOptions);
      }
export function useOrgFoodGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrgFoodGroupsQuery, OrgFoodGroupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrgFoodGroupsQuery, OrgFoodGroupsQueryVariables>(OrgFoodGroupsDocument, baseOptions);
        }
export type OrgFoodGroupsQueryHookResult = ReturnType<typeof useOrgFoodGroupsQuery>;
export type OrgFoodGroupsLazyQueryHookResult = ReturnType<typeof useOrgFoodGroupsLazyQuery>;
export type OrgFoodGroupsQueryResult = ApolloReactCommon.QueryResult<OrgFoodGroupsQuery, OrgFoodGroupsQueryVariables>;
export const PingDocument = gql`
    query Ping {
  ping
}
    `;

/**
 * __usePingQuery__
 *
 * To run a query within a React component, call `usePingQuery` and pass it any options that fit your needs.
 * When your component renders, `usePingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePingQuery({
 *   variables: {
 *   },
 * });
 */
export function usePingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PingQuery, PingQueryVariables>) {
        return ApolloReactHooks.useQuery<PingQuery, PingQueryVariables>(PingDocument, baseOptions);
      }
export function usePingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PingQuery, PingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PingQuery, PingQueryVariables>(PingDocument, baseOptions);
        }
export type PingQueryHookResult = ReturnType<typeof usePingQuery>;
export type PingLazyQueryHookResult = ReturnType<typeof usePingLazyQuery>;
export type PingQueryResult = ApolloReactCommon.QueryResult<PingQuery, PingQueryVariables>;
export const RecipesDocument = gql`
    query Recipes($searchTerm: String!, $limit: Int) {
  recipes(searchTerm: $searchTerm, limit: $limit) {
    ...RecipeWithServings
    hasFullAccess
    org {
      id
      name
    }
    descendantFoods {
      ...Food
    }
  }
  orgGroupRecipes(searchTerm: $searchTerm, limit: $limit) {
    ...RecipeWithServings
    hasFullAccess
    org {
      id
      name
    }
    descendantFoods {
      ...Food
    }
    orgGroup {
      name
    }
  }
}
    ${RecipeWithServingsFragmentDoc}
${FoodFragmentDoc}`;

/**
 * __useRecipesQuery__
 *
 * To run a query within a React component, call `useRecipesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecipesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecipesQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useRecipesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RecipesQuery, RecipesQueryVariables>) {
        return ApolloReactHooks.useQuery<RecipesQuery, RecipesQueryVariables>(RecipesDocument, baseOptions);
      }
export function useRecipesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RecipesQuery, RecipesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RecipesQuery, RecipesQueryVariables>(RecipesDocument, baseOptions);
        }
export type RecipesQueryHookResult = ReturnType<typeof useRecipesQuery>;
export type RecipesLazyQueryHookResult = ReturnType<typeof useRecipesLazyQuery>;
export type RecipesQueryResult = ApolloReactCommon.QueryResult<RecipesQuery, RecipesQueryVariables>;
export const SchedulesDefaultAndTeamDocument = gql`
    query SchedulesDefaultAndTeam($teamId: ID!) {
  schedules {
    ...FullSchedule
  }
  schedulesForTeam(teamId: $teamId) {
    ...FullSchedule
  }
}
    ${FullScheduleFragmentDoc}`;

/**
 * __useSchedulesDefaultAndTeamQuery__
 *
 * To run a query within a React component, call `useSchedulesDefaultAndTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchedulesDefaultAndTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchedulesDefaultAndTeamQuery({
 *   variables: {
 *      teamId: // value for 'teamId'
 *   },
 * });
 */
export function useSchedulesDefaultAndTeamQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SchedulesDefaultAndTeamQuery, SchedulesDefaultAndTeamQueryVariables>) {
        return ApolloReactHooks.useQuery<SchedulesDefaultAndTeamQuery, SchedulesDefaultAndTeamQueryVariables>(SchedulesDefaultAndTeamDocument, baseOptions);
      }
export function useSchedulesDefaultAndTeamLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SchedulesDefaultAndTeamQuery, SchedulesDefaultAndTeamQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SchedulesDefaultAndTeamQuery, SchedulesDefaultAndTeamQueryVariables>(SchedulesDefaultAndTeamDocument, baseOptions);
        }
export type SchedulesDefaultAndTeamQueryHookResult = ReturnType<typeof useSchedulesDefaultAndTeamQuery>;
export type SchedulesDefaultAndTeamLazyQueryHookResult = ReturnType<typeof useSchedulesDefaultAndTeamLazyQuery>;
export type SchedulesDefaultAndTeamQueryResult = ApolloReactCommon.QueryResult<SchedulesDefaultAndTeamQuery, SchedulesDefaultAndTeamQueryVariables>;
export const SharedFoodGroupsDocument = gql`
    query SharedFoodGroups($searchTerm: String!, $limit: Int) {
  sharedFoodGroups(searchTerm: $searchTerm, limit: $limit) {
    ...FullFoodGroup
  }
}
    ${FullFoodGroupFragmentDoc}`;

/**
 * __useSharedFoodGroupsQuery__
 *
 * To run a query within a React component, call `useSharedFoodGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSharedFoodGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSharedFoodGroupsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSharedFoodGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SharedFoodGroupsQuery, SharedFoodGroupsQueryVariables>) {
        return ApolloReactHooks.useQuery<SharedFoodGroupsQuery, SharedFoodGroupsQueryVariables>(SharedFoodGroupsDocument, baseOptions);
      }
export function useSharedFoodGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SharedFoodGroupsQuery, SharedFoodGroupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SharedFoodGroupsQuery, SharedFoodGroupsQueryVariables>(SharedFoodGroupsDocument, baseOptions);
        }
export type SharedFoodGroupsQueryHookResult = ReturnType<typeof useSharedFoodGroupsQuery>;
export type SharedFoodGroupsLazyQueryHookResult = ReturnType<typeof useSharedFoodGroupsLazyQuery>;
export type SharedFoodGroupsQueryResult = ApolloReactCommon.QueryResult<SharedFoodGroupsQuery, SharedFoodGroupsQueryVariables>;
export const SimpleFoodsDocument = gql`
    query SimpleFoods($searchTerm: String!, $limit: Int, $localeCodes: [String!], $dataSources: [FoodDataSource!]) {
  foods(
    searchTerm: $searchTerm
    limit: $limit
    localeCodes: $localeCodes
    dataSources: $dataSources
  ) {
    ...Food
    ... on BrandedFood {
      __typename
    }
    ... on GenericFood {
      __typename
    }
  }
}
    ${FoodFragmentDoc}`;

/**
 * __useSimpleFoodsQuery__
 *
 * To run a query within a React component, call `useSimpleFoodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleFoodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleFoodsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      limit: // value for 'limit'
 *      localeCodes: // value for 'localeCodes'
 *      dataSources: // value for 'dataSources'
 *   },
 * });
 */
export function useSimpleFoodsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SimpleFoodsQuery, SimpleFoodsQueryVariables>) {
        return ApolloReactHooks.useQuery<SimpleFoodsQuery, SimpleFoodsQueryVariables>(SimpleFoodsDocument, baseOptions);
      }
export function useSimpleFoodsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SimpleFoodsQuery, SimpleFoodsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SimpleFoodsQuery, SimpleFoodsQueryVariables>(SimpleFoodsDocument, baseOptions);
        }
export type SimpleFoodsQueryHookResult = ReturnType<typeof useSimpleFoodsQuery>;
export type SimpleFoodsLazyQueryHookResult = ReturnType<typeof useSimpleFoodsLazyQuery>;
export type SimpleFoodsQueryResult = ApolloReactCommon.QueryResult<SimpleFoodsQuery, SimpleFoodsQueryVariables>;
export const SlackFoodRequestsUrlDocument = gql`
    query SlackFoodRequestsUrl {
  slackFoodRequestsUrl
}
    `;

/**
 * __useSlackFoodRequestsUrlQuery__
 *
 * To run a query within a React component, call `useSlackFoodRequestsUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useSlackFoodRequestsUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSlackFoodRequestsUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useSlackFoodRequestsUrlQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SlackFoodRequestsUrlQuery, SlackFoodRequestsUrlQueryVariables>) {
        return ApolloReactHooks.useQuery<SlackFoodRequestsUrlQuery, SlackFoodRequestsUrlQueryVariables>(SlackFoodRequestsUrlDocument, baseOptions);
      }
export function useSlackFoodRequestsUrlLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SlackFoodRequestsUrlQuery, SlackFoodRequestsUrlQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SlackFoodRequestsUrlQuery, SlackFoodRequestsUrlQueryVariables>(SlackFoodRequestsUrlDocument, baseOptions);
        }
export type SlackFoodRequestsUrlQueryHookResult = ReturnType<typeof useSlackFoodRequestsUrlQuery>;
export type SlackFoodRequestsUrlLazyQueryHookResult = ReturnType<typeof useSlackFoodRequestsUrlLazyQuery>;
export type SlackFoodRequestsUrlQueryResult = ApolloReactCommon.QueryResult<SlackFoodRequestsUrlQuery, SlackFoodRequestsUrlQueryVariables>;
export const SportsDocument = gql`
    query Sports {
  sports {
    ...FullSport
  }
}
    ${FullSportFragmentDoc}`;

/**
 * __useSportsQuery__
 *
 * To run a query within a React component, call `useSportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSportsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SportsQuery, SportsQueryVariables>) {
        return ApolloReactHooks.useQuery<SportsQuery, SportsQueryVariables>(SportsDocument, baseOptions);
      }
export function useSportsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SportsQuery, SportsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SportsQuery, SportsQueryVariables>(SportsDocument, baseOptions);
        }
export type SportsQueryHookResult = ReturnType<typeof useSportsQuery>;
export type SportsLazyQueryHookResult = ReturnType<typeof useSportsLazyQuery>;
export type SportsQueryResult = ApolloReactCommon.QueryResult<SportsQuery, SportsQueryVariables>;
export const TeamAnthropometryDocument = gql`
    query TeamAnthropometry($id: ID!) {
  team(id: $id) {
    ...TeamWithSport
    athletes {
      ...Athlete
      position {
        ...Position
      }
      anthropometryEntries {
        ...AnthropometryEntry
      }
    }
  }
}
    ${TeamWithSportFragmentDoc}
${AthleteFragmentDoc}
${PositionFragmentDoc}
${AnthropometryEntryFragmentDoc}`;

/**
 * __useTeamAnthropometryQuery__
 *
 * To run a query within a React component, call `useTeamAnthropometryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamAnthropometryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamAnthropometryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamAnthropometryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamAnthropometryQuery, TeamAnthropometryQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamAnthropometryQuery, TeamAnthropometryQueryVariables>(TeamAnthropometryDocument, baseOptions);
      }
export function useTeamAnthropometryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamAnthropometryQuery, TeamAnthropometryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamAnthropometryQuery, TeamAnthropometryQueryVariables>(TeamAnthropometryDocument, baseOptions);
        }
export type TeamAnthropometryQueryHookResult = ReturnType<typeof useTeamAnthropometryQuery>;
export type TeamAnthropometryLazyQueryHookResult = ReturnType<typeof useTeamAnthropometryLazyQuery>;
export type TeamAnthropometryQueryResult = ApolloReactCommon.QueryResult<TeamAnthropometryQuery, TeamAnthropometryQueryVariables>;
export const TeamRosterDocument = gql`
    query TeamRoster($id: ID!) {
  team(id: $id) {
    ...TeamWithSport
    athletes {
      ...Athlete
      position {
        ...Position
      }
    }
  }
}
    ${TeamWithSportFragmentDoc}
${AthleteFragmentDoc}
${PositionFragmentDoc}`;

/**
 * __useTeamRosterQuery__
 *
 * To run a query within a React component, call `useTeamRosterQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamRosterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamRosterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTeamRosterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamRosterQuery, TeamRosterQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamRosterQuery, TeamRosterQueryVariables>(TeamRosterDocument, baseOptions);
      }
export function useTeamRosterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamRosterQuery, TeamRosterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamRosterQuery, TeamRosterQueryVariables>(TeamRosterDocument, baseOptions);
        }
export type TeamRosterQueryHookResult = ReturnType<typeof useTeamRosterQuery>;
export type TeamRosterLazyQueryHookResult = ReturnType<typeof useTeamRosterLazyQuery>;
export type TeamRosterQueryResult = ApolloReactCommon.QueryResult<TeamRosterQuery, TeamRosterQueryVariables>;
export const NutritionixInstantSearchDocument = gql`
    query NutritionixInstantSearch($query: String!, $region: Int!, $localeCode: String!) {
  nutritionixBrandedInstantSearch(query: $query, region: $region) @rest(type: "NutritionixBrandedInstantSearchPayload", method: "GET", path: "/search/instant?query={args.query}&branded_region={args.region}&common=false", endpoint: "v2") {
    branded {
      ...NutritionixBrandedFood
    }
  }
  nutritionixCommonInstantSearch(query: $query, localeCode: $localeCode) @rest(type: "NutritionixCommonInstantSearchPayload", method: "GET", path: "/search/instant?query={args.query}&locale={args.localeCode}&branded=false", endpoint: "v2") {
    common {
      ...NutritionixCommonFood
    }
  }
}
    ${NutritionixBrandedFoodFragmentDoc}
${NutritionixCommonFoodFragmentDoc}`;

/**
 * __useNutritionixInstantSearchQuery__
 *
 * To run a query within a React component, call `useNutritionixInstantSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useNutritionixInstantSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNutritionixInstantSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      region: // value for 'region'
 *      localeCode: // value for 'localeCode'
 *   },
 * });
 */
export function useNutritionixInstantSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NutritionixInstantSearchQuery, NutritionixInstantSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<NutritionixInstantSearchQuery, NutritionixInstantSearchQueryVariables>(NutritionixInstantSearchDocument, baseOptions);
      }
export function useNutritionixInstantSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NutritionixInstantSearchQuery, NutritionixInstantSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NutritionixInstantSearchQuery, NutritionixInstantSearchQueryVariables>(NutritionixInstantSearchDocument, baseOptions);
        }
export type NutritionixInstantSearchQueryHookResult = ReturnType<typeof useNutritionixInstantSearchQuery>;
export type NutritionixInstantSearchLazyQueryHookResult = ReturnType<typeof useNutritionixInstantSearchLazyQuery>;
export type NutritionixInstantSearchQueryResult = ApolloReactCommon.QueryResult<NutritionixInstantSearchQuery, NutritionixInstantSearchQueryVariables>;
export const NutritionixBrandedInstantSearchDocument = gql`
    query NutritionixBrandedInstantSearch($query: String!, $region: Int!) {
  nutritionixBrandedInstantSearch(query: $query, region: $region) @rest(type: "NutritionixBrandedInstantSearchPayload", method: "GET", path: "/search/instant?query={args.query}&branded_region={args.region}&common=false", endpoint: "v2") {
    branded {
      ...NutritionixBrandedFood
    }
  }
}
    ${NutritionixBrandedFoodFragmentDoc}`;

/**
 * __useNutritionixBrandedInstantSearchQuery__
 *
 * To run a query within a React component, call `useNutritionixBrandedInstantSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useNutritionixBrandedInstantSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNutritionixBrandedInstantSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *      region: // value for 'region'
 *   },
 * });
 */
export function useNutritionixBrandedInstantSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NutritionixBrandedInstantSearchQuery, NutritionixBrandedInstantSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<NutritionixBrandedInstantSearchQuery, NutritionixBrandedInstantSearchQueryVariables>(NutritionixBrandedInstantSearchDocument, baseOptions);
      }
export function useNutritionixBrandedInstantSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NutritionixBrandedInstantSearchQuery, NutritionixBrandedInstantSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NutritionixBrandedInstantSearchQuery, NutritionixBrandedInstantSearchQueryVariables>(NutritionixBrandedInstantSearchDocument, baseOptions);
        }
export type NutritionixBrandedInstantSearchQueryHookResult = ReturnType<typeof useNutritionixBrandedInstantSearchQuery>;
export type NutritionixBrandedInstantSearchLazyQueryHookResult = ReturnType<typeof useNutritionixBrandedInstantSearchLazyQuery>;
export type NutritionixBrandedInstantSearchQueryResult = ApolloReactCommon.QueryResult<NutritionixBrandedInstantSearchQuery, NutritionixBrandedInstantSearchQueryVariables>;
export const NutritionixV1RestaurantSearchDocument = gql`
    query NutritionixV1RestaurantSearch($input: NutritionixV1RestaurantSearchInput!) {
  nutritionixV1RestaurantSearch(input: $input) @rest(type: "NutritionixV1RestaurantSearchPayload", method: "POST", path: "/", endpoint: "v1_search") {
    total
    branded {
      ...NutritionixBrandedFood
    }
  }
}
    ${NutritionixBrandedFoodFragmentDoc}`;

/**
 * __useNutritionixV1RestaurantSearchQuery__
 *
 * To run a query within a React component, call `useNutritionixV1RestaurantSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useNutritionixV1RestaurantSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNutritionixV1RestaurantSearchQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useNutritionixV1RestaurantSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NutritionixV1RestaurantSearchQuery, NutritionixV1RestaurantSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<NutritionixV1RestaurantSearchQuery, NutritionixV1RestaurantSearchQueryVariables>(NutritionixV1RestaurantSearchDocument, baseOptions);
      }
export function useNutritionixV1RestaurantSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NutritionixV1RestaurantSearchQuery, NutritionixV1RestaurantSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NutritionixV1RestaurantSearchQuery, NutritionixV1RestaurantSearchQueryVariables>(NutritionixV1RestaurantSearchDocument, baseOptions);
        }
export type NutritionixV1RestaurantSearchQueryHookResult = ReturnType<typeof useNutritionixV1RestaurantSearchQuery>;
export type NutritionixV1RestaurantSearchLazyQueryHookResult = ReturnType<typeof useNutritionixV1RestaurantSearchLazyQuery>;
export type NutritionixV1RestaurantSearchQueryResult = ApolloReactCommon.QueryResult<NutritionixV1RestaurantSearchQuery, NutritionixV1RestaurantSearchQueryVariables>;
export const UsdaFoodDetailsDocument = gql`
    query USDAFoodDetails($usdaFdcId: ID!, $apiKey: String!) {
  usdaFoodDetail(usdaFdcId: $usdaFdcId, apiKey: $apiKey) @rest(type: "USDAFoodDetail", method: "GET", path: "/{args.usdaFdcId}?api_key={args.apiKey}", endpoint: "v1") {
    ...USDAFoodDetail
  }
}
    ${UsdaFoodDetailFragmentDoc}`;

/**
 * __useUsdaFoodDetailsQuery__
 *
 * To run a query within a React component, call `useUsdaFoodDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsdaFoodDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsdaFoodDetailsQuery({
 *   variables: {
 *      usdaFdcId: // value for 'usdaFdcId'
 *      apiKey: // value for 'apiKey'
 *   },
 * });
 */
export function useUsdaFoodDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsdaFoodDetailsQuery, UsdaFoodDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<UsdaFoodDetailsQuery, UsdaFoodDetailsQueryVariables>(UsdaFoodDetailsDocument, baseOptions);
      }
export function useUsdaFoodDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsdaFoodDetailsQuery, UsdaFoodDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsdaFoodDetailsQuery, UsdaFoodDetailsQueryVariables>(UsdaFoodDetailsDocument, baseOptions);
        }
export type UsdaFoodDetailsQueryHookResult = ReturnType<typeof useUsdaFoodDetailsQuery>;
export type UsdaFoodDetailsLazyQueryHookResult = ReturnType<typeof useUsdaFoodDetailsLazyQuery>;
export type UsdaFoodDetailsQueryResult = ApolloReactCommon.QueryResult<UsdaFoodDetailsQuery, UsdaFoodDetailsQueryVariables>;
export const UsdaFoodsDocument = gql`
    query USDAFoods($searchTerm: String!, $apiKey: String!, $brandOwner: String!, $includedDataTypes: String!) {
  usdaFoods(
    searchTerm: $searchTerm
    apiKey: $apiKey
    brandOwner: $brandOwner
    includedDataTypes: $includedDataTypes
  ) @rest(type: "[USDAFood]", method: "GET", path: "/search?generalSearchInput={args.searchTerm}&api_key={args.apiKey}&brandOwner={args.brandOwner}&includeDataTypeList={args.includedDataTypes}", endpoint: "v1") {
    ...USDAFood
  }
}
    ${UsdaFoodFragmentDoc}`;

/**
 * __useUsdaFoodsQuery__
 *
 * To run a query within a React component, call `useUsdaFoodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsdaFoodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsdaFoodsQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      apiKey: // value for 'apiKey'
 *      brandOwner: // value for 'brandOwner'
 *      includedDataTypes: // value for 'includedDataTypes'
 *   },
 * });
 */
export function useUsdaFoodsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsdaFoodsQuery, UsdaFoodsQueryVariables>) {
        return ApolloReactHooks.useQuery<UsdaFoodsQuery, UsdaFoodsQueryVariables>(UsdaFoodsDocument, baseOptions);
      }
export function useUsdaFoodsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsdaFoodsQuery, UsdaFoodsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsdaFoodsQuery, UsdaFoodsQueryVariables>(UsdaFoodsDocument, baseOptions);
        }
export type UsdaFoodsQueryHookResult = ReturnType<typeof useUsdaFoodsQuery>;
export type UsdaFoodsLazyQueryHookResult = ReturnType<typeof useUsdaFoodsLazyQuery>;
export type UsdaFoodsQueryResult = ApolloReactCommon.QueryResult<UsdaFoodsQuery, UsdaFoodsQueryVariables>;
export const AgreementFormFragmentFactory = (): AgreementFormFragment =>  {
  return {
    id: faker.datatype.uuid(),
    title: faker.internet.password(10, false, /[0-9A-Z]/),
    url: faker.internet.password(10, false, /[0-9A-Z]/),
    acceptAgreementText: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const CommentWithNotificationFragmentFactory = (): CommentWithNotificationFragment =>  {
  return {
    id: faker.datatype.uuid(),
    notification: {
      id: faker.datatype.uuid(),
      viewedAt: faker.datatype.datetime().toISOString(),
    },
  };
}

export const MacroMealPlanDisplaySettingsFragmentFactory = (): MacroMealPlanDisplaySettingsFragment =>  {
  return {
    __typename: "MacroMealPlanDisplaySettings",
    dailyProgressViews: faker.helpers.arrayElement(["all", "hideNumbers", "none"]),
    mealProgressViews: faker.helpers.arrayElement(["all", "hideNumbers", "none"]),
  };
}

export const ExchangeMealPlanDisplaySettingsFragmentFactory = (): ExchangeMealPlanDisplaySettingsFragment =>  {
  return {
    __typename: "ExchangeMealPlanDisplaySettings",
    dailyProgressViewsForExchanges: faker.helpers.arrayElement(["all", "none"]),
    mealProgressViewsForExchanges: faker.helpers.arrayElement(["all", "none"]),
    dailyProgressViewsForMacros: faker.helpers.arrayElement(["all", "hideNumbers", "none"]),
    mealProgressViewsForMacros: faker.helpers.arrayElement(["all", "hideNumbers", "none"]),
  };
}

export const NoTargetsDisplaySettingsFragmentFactory = (): NoTargetsDisplaySettingsFragment =>  {
  return {
    dailyProgressViewsForMacros: faker.helpers.arrayElement(["all", "hideNumbers", "none"]),
    mealProgressViewsForMacros: faker.helpers.arrayElement(["all", "hideNumbers", "none"]),
  };
}

export const ExchangeSummaryLabelFragmentFactory = (): ExchangeSummaryLabelFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "ExchangeAmount",
    amount: faker.datatype.float(),
    exchange: {
      __typename: "Exchange",
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      shortName: faker.internet.password(10, false, /[0-9A-Z]/),
      type: faker.helpers.arrayElement(["starch", "fruit", "vegetable", "protein", "dairy", "fat"]),
      cho: faker.datatype.float(),
      pro: faker.datatype.float(),
      fat: faker.datatype.float(),
      kcal: faker.datatype.float(),
    },
  };
}

export const FeatureFlagsFragmentFactory = (): FeatureFlagsFragment =>  {
  return {
    athleteMenuSuggestions: faker.datatype.boolean(),
    clientCredentials: faker.datatype.boolean(),
    hasRestaurantMenus: faker.datatype.boolean(),
    restaurantLogging: faker.datatype.boolean(),
    teamworksSchedules: faker.datatype.boolean(),
    mealPlanSuggestions: faker.datatype.boolean(),
    prepPlan: faker.datatype.boolean(),
    foodCategory: faker.datatype.boolean(),
    pk2MenuBuilder: faker.datatype.boolean(),
    orgGroupRecipes: faker.datatype.boolean(),
    orgGroupFoods: faker.datatype.boolean(),
    performanceKitchen: faker.datatype.boolean(),
    printableTags: faker.datatype.boolean(),
    hubMenuCheckIn: faker.datatype.boolean(),
    goalTracker: faker.datatype.boolean(),
    dietitianOnlyMenus: faker.datatype.boolean(),
    bulkOrdering: faker.datatype.boolean(),
    ukMealPlanOptionSuggestions: faker.datatype.boolean(),
    digitalDisplays: faker.datatype.boolean(),
    selfServiceOnboarding: faker.datatype.boolean(),
    messaging: faker.datatype.boolean(),
  };
}

export const RecipeFoodCategoryFragmentFactory = (): RecipeFoodCategoryFragment =>  {
  return {
    category: faker.helpers.arrayElement(["carbohydrate", "protein", "fats", "combination"]),
  };
}

export const MenuItemFoodCategoryFragmentFactory = (): MenuItemFoodCategoryFragment =>  {
  return {
    category: faker.helpers.arrayElement(["carbohydrate", "protein", "fats", "combination"]),
  };
}

export const MealMenuWithTimesFragmentFactory = (): MealMenuWithTimesFragment =>  {
  return {
    id: faker.datatype.uuid(),
    timezone: "America/New_York",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    start: faker.datatype.datetime().toISOString(),
    durationInMinutes: faker.datatype.number(),
    prepTimeInMinutes: faker.datatype.number(),
    lastOrderTimeBeforeEndInMinutes: faker.datatype.number(),
    isViewOnlyForMe: faker.datatype.boolean(),
    theme: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const MealMenuDiningStationWithItemPreviewsFragmentFactory = (): MealMenuDiningStationWithItemPreviewsFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    maxAmount: faker.datatype.number(),
    position: faker.datatype.number(),
    menuItemAppearances: [{
      ...MenuItemAppearancePreviewFragmentFactory(),
    }],
  };
}

export const MenuItemAppearancePreviewFragmentFactory = (): MenuItemAppearancePreviewFragment =>  {
  return {
    id: faker.datatype.uuid(),
    position: faker.datatype.number(),
    maxAmount: faker.datatype.number(),
    availableForOrder: faker.datatype.boolean(),
    allowSpecialRequests: faker.datatype.boolean(),
    menuItem: {
      ...MenuItemPreviewFragmentFactory(),
    },
  };
}

export const MenuItemPreviewFragmentFactory = (): MenuItemPreviewFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "MenuItem",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    servingName: faker.internet.password(10, false, /[0-9A-Z]/),
    description: faker.internet.password(10, false, /[0-9A-Z]/),
    imageUrl: faker.internet.password(10, false, /[0-9A-Z]/),
    isOneOff: faker.datatype.boolean(),
    defaultAvailableForOrder: faker.datatype.boolean(),
    defaultAllowSpecialRequests: faker.datatype.boolean(),
    defaultMaxAmount: faker.datatype.number(),
    isDeleted: faker.datatype.boolean(),
    servingAmounts: [{
      id: faker.datatype.uuid(),
      serving: {
        id: faker.datatype.uuid(),
        foodOrRecipe: {
          ...FoodOrRecipePreferencesFragmentFactory(),
        },
      },
    }],
    score: {
      ...ScoreAssignmentFragmentFactory(),
    },
    foodCategory: {
      ...FoodCategoryNameFragmentFactory(),
    },
  };
}

export const MenuOrderRateLimitRemainderFragmentFactory = (): MenuOrderRateLimitRemainderFragment =>  {
  return {
    start: faker.datatype.datetime().toISOString(),
    remaining: faker.datatype.number(),
  };
}

export const MealMenuLogItemFormFragmentFactory = (): MealMenuLogItemFormFragment =>  {
  return {
    id: faker.datatype.uuid(),
    ...MenuSelectionItemFormFragmentFactory(),
  };
}

export const ViewMealOptionFragmentFactory = (): ViewMealOptionFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "MealOption",
    note: faker.internet.password(10, false, /[0-9A-Z]/),
    position: faker.datatype.number(),
    servingAmounts: [{
      ...FullServingAmountFragmentFactory(),
    }],
  };
}

export const MealTemplateForTargetsFragmentFactory = (): MealTemplateForTargetsFragment =>  {
  return {
    id: faker.datatype.uuid(),
    ...({
      __typename: "ExchangeMealTemplate",
      exchangeTargets: [{
        ...ExchangeAmountFragmentFactory(),
      }],
    }),
  };
}

export const ExchangeMealTemplateViewFragmentFactory = (): ExchangeMealTemplateViewFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "ExchangeMealTemplate",
    mealOptions: [{
      ...ViewMealOptionFragmentFactory(),
    }],
    exchangeTargets: [{
      ...ExchangeAmountFragmentFactory(),
    }],
    meal: {
      ...MealFragmentFactory(),
    },
  };
}

export const MacroMealTemplateViewFragmentFactory = (): MacroMealTemplateViewFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "MacroMealTemplate",
    mealOptions: [{
      ...ViewMealOptionFragmentFactory(),
    }],
    meal: {
      ...MealFragmentFactory(),
    },
    cho: faker.datatype.float(),
    pro: faker.datatype.float(),
    fat: faker.datatype.float(),
    kcal: faker.datatype.float(),
  };
}

export const MenuItemWithIdsFragmentFactory = (): MenuItemWithIdsFragment =>  {
  return {
    id: faker.datatype.uuid(),
    choices: [{
      id: faker.datatype.uuid(),
      options: [{
        id: faker.datatype.uuid(),
      }],
    }],
  };
}

export const MenuSelectionItemOptionWithIdsFragmentFactory = (): MenuSelectionItemOptionWithIdsFragment =>  {
  return {
    amount: faker.datatype.float(),
    menuItemChoiceOption: {
      id: faker.datatype.uuid(),
    },
    percentConsumed: faker.datatype.float(),
  };
}

export const MenuItemFormFragmentFactory = (): MenuItemFormFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "MenuItem",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    servingName: faker.internet.password(10, false, /[0-9A-Z]/),
    description: faker.internet.password(10, false, /[0-9A-Z]/),
    isOneOff: faker.datatype.boolean(),
    servingAmounts: [{
      ...FullServingAmountFragmentFactory(),
    }],
    choices: [{
      ...MenuItemChoiceFormFragmentFactory(),
    }],
    imageUrl: faker.internet.password(10, false, /[0-9A-Z]/),
    suggestionCategory: faker.helpers.arrayElement(["combo", "main", "protein", "starch", "vegetable", "fruit", "other"]),
    defaultMaxAmount: faker.datatype.number(),
    defaultAvailableForOrder: faker.datatype.boolean(),
    defaultAllowSpecialRequests: faker.datatype.boolean(),
    isDeleted: faker.datatype.boolean(),
    score: {
      ...ScoreAssignmentFragmentFactory(),
    },
    foodCategory: {
      ...FoodCategoryNameFragmentFactory(),
    },
  };
}

export const MenuItemChoiceFormFragmentFactory = (): MenuItemChoiceFormFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    position: faker.datatype.number(),
    required: faker.datatype.boolean(),
    maxOptionsCount: faker.datatype.number(),
    options: [{
      ...MenuItemChoiceOptionFormFragmentFactory(),
    }],
  };
}

export const MenuItemChoiceOptionFormFragmentFactory = (): MenuItemChoiceOptionFormFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    position: faker.datatype.number(),
    canEditAmount: faker.datatype.boolean(),
    defaultAmount: faker.datatype.float(),
    maxAmount: faker.datatype.float(),
    servingAmounts: [{
      ...FullServingAmountFragmentFactory(),
    }],
  };
}

export const FoodCategoryNameFragmentFactory = (): FoodCategoryNameFragment =>  {
  return {
    category: faker.helpers.arrayElement(["carbohydrate", "protein", "fats", "combination"]),
  };
}

export const MenuOrderItemFormFragmentFactory = (): MenuOrderItemFormFragment =>  {
  return {
    id: faker.datatype.uuid(),
    forOrder: faker.datatype.boolean(),
    status: faker.helpers.arrayElement(["new", "cooking", "done", "cancelled"]),
    specialRequests: faker.internet.password(10, false, /[0-9A-Z]/),
    rating: {
      ...RatingFragmentFactory(),
    },
    ...MenuSelectionItemFormFragmentFactory(),
  };
}

export const RatingFragmentFactory = (): RatingFragment =>  {
  return {
    id: faker.datatype.uuid(),
    review: faker.internet.password(10, false, /[0-9A-Z]/),
    value: faker.helpers.arrayElement(["one", "two", "three", "four", "five"]),
  };
}

export const MenuSelectionFormFragmentFactory = (): MenuSelectionFormFragment =>  {
  return {
    items: [{
      ...MenuSelectionItemFormFragmentFactory(),
    }],
  };
}

export const MenuSelectionItemWithMacrosFragmentFactory = (): MenuSelectionItemWithMacrosFragment =>  {
  return {
    amount: faker.datatype.float(),
    percentConsumed: faker.datatype.float(),
    menuItem: {
      id: faker.datatype.uuid(),
      servingAmounts: [{
        ...FullServingAmountFragmentFactory(),
      }],
    },
    options: [{
      ...MenuSelectItemOptionWithMacrosFragmentFactory(),
    }],
  };
}

export const MenuSelectItemOptionWithMacrosFragmentFactory = (): MenuSelectItemOptionWithMacrosFragment =>  {
  return {
    amount: faker.datatype.float(),
    percentConsumed: faker.datatype.float(),
    menuItemChoiceOption: {
      id: faker.datatype.uuid(),
      servingAmounts: [{
        ...FullServingAmountFragmentFactory(),
      }],
    },
  };
}

export const MenuSelectionItemFormFragmentFactory = (): MenuSelectionItemFormFragment =>  {
  return {
    amount: faker.datatype.float(),
    percentConsumed: faker.datatype.float(),
    menuItem: {
      ...MenuItemFormFragmentFactory(),
    },
    options: [{
      ...MenuSelectionItemOptionFormFragmentFactory(),
    }],
  };
}

export const MenuSelectionItemOptionFormFragmentFactory = (): MenuSelectionItemOptionFormFragment =>  {
  return {
    amount: faker.datatype.float(),
    percentConsumed: faker.datatype.float(),
    menuItemChoiceOption: {
      ...MenuItemChoiceOptionFormFragmentFactory(),
    },
  };
}

export const MenuOrderSuggestionFragmentFactory = (): MenuOrderSuggestionFragment =>  {
  return {
    items: [{
      amount: faker.datatype.float(),
      percentConsumed: faker.datatype.float(),
      menuItem: {
        ...MenuItemFormFragmentFactory(),
      },
      options: [{
        amount: faker.datatype.float(),
        percentConsumed: faker.datatype.float(),
        menuItemChoiceOption: {
          ...MenuItemChoiceOptionFormFragmentFactory(),
        },
      }],
    }],
  };
}

export const ServingWithNutrientAmountsFragmentFactory = (): ServingWithNutrientAmountsFragment =>  {
  return {
    id: faker.datatype.uuid(),
    nutrientAmounts: [{
      ...NutrientAmountFragmentFactory(),
    }],
    foodOrRecipe: {
      ...({
        id: faker.datatype.uuid(),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
      }),
    },
  };
}

export const NutrientAmountFragmentFactory = (): NutrientAmountFragment =>  {
  return {
    amount: faker.datatype.float(),
    nutrient: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
  };
}

export const NutrientWithDriFragmentFactory = (): NutrientWithDriFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    unitName: faker.internet.password(10, false, /[0-9A-Z]/),
    defaultDietaryReferenceIntake: {
      id: faker.datatype.uuid(),
      type: faker.helpers.arrayElement(["RDA", "UL", "AI", "DGA", "unitsPerKcal"]),
      amount: faker.datatype.float(),
    },
  };
}

export const RestaurantMenuWithItemPreviewsFragmentFactory = (): RestaurantMenuWithItemPreviewsFragment =>  {
  return {
    id: faker.datatype.uuid(),
    sections: [{
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      position: faker.datatype.number(),
      menuItemAppearances: [{
        ...MenuItemAppearancePreviewFragmentFactory(),
      }],
    }],
  };
}

export const RestaurantMenuLinkWithMyOrdersFragmentFactory = (): RestaurantMenuLinkWithMyOrdersFragment =>  {
  return {
    id: faker.datatype.uuid(),
    allowCustomOrders: faker.datatype.boolean(),
    allowPlateOrders: faker.datatype.boolean(),
    restaurant: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    order: {
      ...RestaurantMenuLinkOrderFragmentFactory(),
    },
    plateOrder: {
      ...RestaurantMenuLinkPlateOrderFragmentFactory(),
    },
  };
}

export const RestaurantMenuLinkWithOrdersForAthleteFragmentFactory = (): RestaurantMenuLinkWithOrdersForAthleteFragment =>  {
  return {
    id: faker.datatype.uuid(),
    allowCustomOrders: faker.datatype.boolean(),
    allowPlateOrders: faker.datatype.boolean(),
    restaurant: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    order: {
      ...RestaurantMenuLinkOrderFragmentFactory(),
    },
    plateOrder: {
      ...RestaurantMenuLinkPlateOrderFragmentFactory(),
    },
  };
}

export const RestaurantMenuLinkWithItemPreviewsFragmentFactory = (): RestaurantMenuLinkWithItemPreviewsFragment =>  {
  return {
    id: faker.datatype.uuid(),
    allowCustomOrders: faker.datatype.boolean(),
    allowPlateOrders: faker.datatype.boolean(),
    restaurant: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    sections: [{
      restaurantMenuSection: {
        id: faker.datatype.uuid(),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
        position: faker.datatype.number(),
      },
      maxAmount: faker.datatype.number(),
      menuItemAppearances: [{
        ...MenuItemAppearancePreviewFragmentFactory(),
      }],
    }],
    plates: [{
      ...RestaurantMenuLinkPlateFragmentFactory(),
    }],
  };
}

export const RestaurantMenuLinkPlateFragmentFactory = (): RestaurantMenuLinkPlateFragment =>  {
  return {
    id: faker.datatype.uuid(),
    items: [{
      ...RestaurantMenuLinkPlateItemFormFragmentFactory(),
    }],
    position: faker.datatype.number(),
  };
}

export const RestaurantMenuLinkOrderFragmentFactory = (): RestaurantMenuLinkOrderFragment =>  {
  return {
    id: faker.datatype.uuid(),
    items: [{
      ...RestaurantMenuLinkOrderItemFormFragmentFactory(),
    }],
  };
}

export const RestaurantMenuLinkOrderItemFormFragmentFactory = (): RestaurantMenuLinkOrderItemFormFragment =>  {
  return {
    id: faker.datatype.uuid(),
    specialRequests: faker.internet.password(10, false, /[0-9A-Z]/),
    ...MenuSelectionItemFormFragmentFactory(),
  };
}

export const RestaurantMenuLinkPlateItemFormFragmentFactory = (): RestaurantMenuLinkPlateItemFormFragment =>  {
  return {
    id: faker.datatype.uuid(),
    position: faker.datatype.number(),
    specialRequests: faker.internet.password(10, false, /[0-9A-Z]/),
    ...MenuSelectionItemFormFragmentFactory(),
  };
}

export const RestaurantMenuLinkPlateOrderFragmentFactory = (): RestaurantMenuLinkPlateOrderFragment =>  {
  return {
    id: faker.datatype.uuid(),
    userFullName: faker.internet.password(10, false, /[0-9A-Z]/),
    items: [{
      ...RestaurantMenuLinkPlateOrderItemFormFragmentFactory(),
    }],
    plate: {
      id: faker.datatype.uuid(),
      items: [{
        ...RestaurantMenuLinkPlateItemFormFragmentFactory(),
      }],
      position: faker.datatype.number(),
    },
    restaurantMenuLink: {
      id: faker.datatype.uuid(),
    },
  };
}

export const RestaurantMenuLinkPlateOrderItemFormFragmentFactory = (): RestaurantMenuLinkPlateOrderItemFormFragment =>  {
  return {
    amount: faker.datatype.float(),
    percentConsumed: faker.datatype.float(),
    plateItem: {
      id: faker.datatype.uuid(),
    },
    menuItem: {
      ...MenuItemFormFragmentFactory(),
    },
    options: [{
      ...RestaurantMenuLinkPlateOrderItemOptionFormFragmentFactory(),
    }],
  };
}

export const RestaurantMenuLinkPlateOrderItemOptionFormFragmentFactory = (): RestaurantMenuLinkPlateOrderItemOptionFormFragment =>  {
  return {
    amount: faker.datatype.float(),
    percentConsumed: faker.datatype.float(),
    menuItemChoiceOption: {
      ...MenuItemChoiceOptionFormFragmentFactory(),
    },
  };
}

export const RestaurantMenuLogFragmentFactory = (): RestaurantMenuLogFragment =>  {
  return {
    id: faker.datatype.uuid(),
    mealTemplate: {
      id: faker.datatype.uuid(),
    },
    items: [{
      ...RestaurantMenuLogItemFormFragmentFactory(),
    }],
    restaurantMenu: {
      id: faker.datatype.uuid(),
    },
    dateTime: faker.datatype.datetime().toISOString(),
    timezone: "America/New_York",
    type: faker.helpers.arrayElement(["breakfast", "lunch", "dinner", "snack", "meal"]),
  };
}

export const RestaurantMenuLogItemFormFragmentFactory = (): RestaurantMenuLogItemFormFragment =>  {
  return {
    id: faker.datatype.uuid(),
    ...MenuSelectionItemFormFragmentFactory(),
  };
}

export const ScoreAssignmentFragmentFactory = (): ScoreAssignmentFragment =>  {
  return {
    value: faker.datatype.float(),
    scoringSystem: {
      ...ScoringSystemAssignmentFragmentFactory(),
    },
  };
}

export const ScoringSystemAssignmentFragmentFactory = (): ScoringSystemAssignmentFragment =>  {
  return {
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    id: faker.datatype.uuid(),
  };
}

export const ScoringSystemFragmentFactory = (): ScoringSystemFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const ServingWithFoodOrRecipeMacrosFragmentFactory = (): ServingWithFoodOrRecipeMacrosFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Serving",
    macros: {
      cho: faker.datatype.float(),
      pro: faker.datatype.float(),
      fat: faker.datatype.float(),
      kcal: faker.datatype.float(),
    },
    foodOrRecipe: {
      __typename: "BrandedFood",
      ...({
        id: faker.datatype.uuid(),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
        usdaFdcDescription: faker.internet.password(10, false, /[0-9A-Z]/),
      }),
    },
  };
}

export const RecipeIngredientServingAmountFragmentFactory = (): RecipeIngredientServingAmountFragment =>  {
  return {
    id: faker.datatype.uuid(),
    amount: faker.datatype.float(),
    position: faker.datatype.number(),
    serving: {
      ...RecipeIngredientServingFragmentFactory(),
    },
  };
}

export const TimelineItemForGroupByDateFragmentFactory = (): TimelineItemForGroupByDateFragment =>  {
  return {
    ...({
      id: faker.datatype.uuid(),
      __typename: "FoodLogMeal",
    }),
  };
}

export const TimelineMealForGroupByDateFragmentFactory = (): TimelineMealForGroupByDateFragment =>  {
  return {
    id: faker.datatype.uuid(),
    start: faker.datatype.datetime().toISOString(),
    timezone: "America/New_York",
    notifications: [{
      ...NotificationTimelineFragmentFactory(),
    }],
  };
}

export const NotificationTimelineFragmentFactory = (): NotificationTimelineFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "AthleteSelfOnboardedNotification",
    viewedAt: faker.datatype.datetime().toISOString(),
  };
}

export const MealMenuSharedTimelineFragmentFactory = (): MealMenuSharedTimelineFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "StandaloneMealMenu",
    mealType: faker.helpers.arrayElement(["breakfast", "lunch", "dinner", "snack", "meal"]),
    timezone: "America/New_York",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    start: faker.datatype.datetime().toISOString(),
    durationInMinutes: faker.datatype.number(),
    prepTimeInMinutes: faker.datatype.number(),
    lastOrderTimeBeforeEndInMinutes: faker.datatype.number(),
    isLogOnly: faker.datatype.boolean(),
    isInHouseMenuOrderable: faker.datatype.boolean(),
    isRestaurantMenuOrderable: faker.datatype.boolean(),
    isViewOnlyForMe: faker.datatype.boolean(),
    isBulkOrderable: faker.datatype.boolean(),
    restaurantMenuLinks: [{
      id: faker.datatype.uuid(),
      allowCustomOrders: faker.datatype.boolean(),
      allowPlateOrders: faker.datatype.boolean(),
      restaurant: {
        id: faker.datatype.uuid(),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
      },
    }],
  };
}

export const RestaurantMenuLinkOrderSharedTimelineFragmentFactory = (): RestaurantMenuLinkOrderSharedTimelineFragment =>  {
  return {
    ...RestaurantMenuLinkOrderFragmentFactory(),
    mealMenu: {
      id: faker.datatype.uuid(),
    },
    restaurantMenuLink: {
      id: faker.datatype.uuid(),
      restaurant: {
        id: faker.datatype.uuid(),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
      },
    },
  };
}

export const RestaurantMenuLinkPlateOrderSharedTimelineFragmentFactory = (): RestaurantMenuLinkPlateOrderSharedTimelineFragment =>  {
  return {
    ...RestaurantMenuLinkPlateOrderFragmentFactory(),
    mealMenu: {
      ...MealMenuWithTimesFragmentFactory(),
    },
    restaurantMenuLink: {
      id: faker.datatype.uuid(),
      restaurant: {
        id: faker.datatype.uuid(),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
      },
    },
  };
}

export const MenuOrderSharedTimelineFragmentFactory = (): MenuOrderSharedTimelineFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "MenuOrder",
    mealMenu: {
      id: faker.datatype.uuid(),
    },
  };
}

export const MealMenuLogSharedTimelineFragmentFactory = (): MealMenuLogSharedTimelineFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "MealMenuLog",
    mealMenu: {
      id: faker.datatype.uuid(),
    },
  };
}

export const MealPlanDateSharedTimelineFragmentFactory = (): MealPlanDateSharedTimelineFragment =>  {
  return {
    __typename: "MealPlanDate",
    date: faker.datatype.datetime().toISOString().split("T")[0],
    timezone: "America/New_York",
    mealPlan: {
      ...MealPlanSharedTimelineFragmentFactory(),
    },
  };
}

export const MealPlanSharedTimelineFragmentFactory = (): MealPlanSharedTimelineFragment =>  {
  return {
    id: faker.datatype.uuid(),
    mealTemplates: [{
      ...MealTemplateSharedTimelineFragmentFactory(),
    }],
    activityTemplates: [{
      ...ActivityTemplateSharedTimelineFragmentFactory(),
    }],
  };
}

export const MealTemplateSharedTimelineFragmentFactory = (): MealTemplateSharedTimelineFragment =>  {
  return {
    id: faker.datatype.uuid(),
    meal: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      start: faker.datatype.datetime().toISOString().split("T")[1],
      end: faker.datatype.datetime().toISOString().split("T")[1],
      type: faker.helpers.arrayElement(["breakfast", "lunch", "dinner", "snack", "meal"]),
    },
    ...({
      __typename: "ExchangeMealTemplate",
    }),
  };
}

export const ActivityTemplateSharedTimelineFragmentFactory = (): ActivityTemplateSharedTimelineFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "ActivityTemplate",
    activity: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      start: faker.datatype.datetime().toISOString().split("T")[1],
      end: faker.datatype.datetime().toISOString().split("T")[1],
      type: faker.helpers.arrayElement(["practice", "lift", "conditioning", "competition", "class"]),
    },
  };
}

export const TimelineMealSharedTimelineFragmentFactory = (): TimelineMealSharedTimelineFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "TimelineMeal",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    type: faker.helpers.arrayElement(["breakfast", "lunch", "dinner", "snack", "meal"]),
    timezone: "America/New_York",
    start: faker.datatype.datetime().toISOString(),
    durationInMinutes: faker.datatype.number(),
    mealTemplate: {
      id: faker.datatype.uuid(),
    },
    mealMenus: [{
      ...MealMenuSharedTimelineFragmentFactory(),
    }],
    servingAmounts: [{
      ...FullServingAmountFragmentFactory(),
    }],
    restaurantMenuLogs: [{
      ...RestaurantMenuLogSharedTimelineFragmentFactory(),
    }],
    restaurantMenuLinkPlateOrders: [{
      ...RestaurantMenuLinkPlateOrderSharedTimelineFragmentFactory(),
    }],
    restaurantMenuLinkOrders: [{
      ...RestaurantMenuLinkOrderSharedTimelineFragmentFactory(),
    }],
    menuOrders: [{
      ...MenuOrderSharedTimelineFragmentFactory(),
    }],
    mealMenuLogs: [{
      ...MealMenuLogSharedTimelineFragmentFactory(),
    }],
  };
}

export const FoodLogActivitySharedTimelineFragmentFactory = (): FoodLogActivitySharedTimelineFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "FoodLogActivity",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    activityType: faker.helpers.arrayElement(["practice", "lift", "conditioning", "competition", "class"]),
    startTime: faker.datatype.datetime().toISOString().split("T")[1],
    endTime: faker.datatype.datetime().toISOString().split("T")[1],
    timezone: "America/New_York",
    activityTemplate: {
      id: faker.datatype.uuid(),
    },
  };
}

export const RestaurantMenuLogSharedTimelineFragmentFactory = (): RestaurantMenuLogSharedTimelineFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "RestaurantMenuLog",
    mealType: faker.helpers.arrayElement(["breakfast", "lunch", "dinner", "snack", "meal"]),
    dateTime: faker.datatype.datetime().toISOString(),
    timezone: "America/New_York",
    items: [{
      id: faker.datatype.uuid(),
      ...MenuSelectionItemFormFragmentFactory(),
    }],
  };
}

export const UnitFragmentFactory = (): UnitFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const UnitWithConversionsFragmentFactory = (): UnitWithConversionsFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    conversionsFrom: [{
      id: faker.datatype.uuid(),
      fromQuantity: faker.datatype.float(),
      toQuantity: faker.datatype.float(),
      toUnit: {
        ...UnitFragmentFactory(),
        isProductionOnly: faker.datatype.boolean(),
      },
    }],
  };
}

export const ActivityFragmentFactory = (): ActivityFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Activity",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    start: faker.datatype.datetime().toISOString().split("T")[1],
    end: faker.datatype.datetime().toISOString().split("T")[1],
    type: faker.helpers.arrayElement(["practice", "lift", "conditioning", "competition", "class"]),
  };
}

export const ActivityTemplateFragmentFactory = (): ActivityTemplateFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "ActivityTemplate",
    activity: {
      ...ActivityFragmentFactory(),
    },
  };
}

export const AnthropometryEntryFragmentFactory = (): AnthropometryEntryFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "AnthropometryEntry",
    height: faker.datatype.float(),
    weight: faker.datatype.float(),
    leanBodyMass: faker.datatype.float(),
    bodyFatMass: faker.datatype.float(),
    percentBodyFat: faker.datatype.float(),
    datetime: faker.datatype.datetime().toISOString(),
    type: faker.helpers.arrayElement(["estimate", "dexa", "bodpod", "bioelectricalImpedance", "isak", "weighIn"]),
    sex: faker.helpers.arrayElement(["male", "female"]),
    comment: faker.internet.password(10, false, /[0-9A-Z]/),
    boneMineralDensity: faker.datatype.float(),
    boneMineralDensityZScore: faker.datatype.float(),
    boneMineralDensityTScore: faker.datatype.float(),
    boneMineralContent: faker.datatype.float(),
    boneArea: faker.datatype.float(),
    boneMass: faker.datatype.float(),
    dryLeanMass: faker.datatype.float(),
    skeletalMuscleMass: faker.datatype.float(),
    trunkFat: faker.datatype.float(),
    visceralFatArea: faker.datatype.float(),
    heightInCm: faker.datatype.float(),
    weightInKg: faker.datatype.float(),
    leanBodyMassInKg: faker.datatype.float(),
    bodyFatMassInKg: faker.datatype.float(),
    skeletalMuscleMassInKg: faker.datatype.float(),
    trunkFatInKg: faker.datatype.float(),
    dryLeanMassInKg: faker.datatype.float(),
  };
}

export const AthleteFragmentFactory = (): AthleteFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Athlete",
    firstName: faker.internet.password(10, false, /[0-9A-Z]/),
    lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    sex: faker.helpers.arrayElement(["male", "female"]),
    birthDate: faker.datatype.datetime().toISOString().split("T")[0],
    phoneNumber: faker.internet.password(10, false, /[0-9A-Z]/),
    email: faker.internet.password(10, false, /[0-9A-Z]/),
    headshotUrl: faker.internet.password(10, false, /[0-9A-Z]/),
    secaUid: faker.internet.password(10, false, /[0-9A-Z]/),
    inbodyUid: faker.internet.password(10, false, /[0-9A-Z]/),
    isArchived: faker.datatype.boolean(),
    activationLinkSent: faker.datatype.datetime().toISOString(),
    jerseyNumber: faker.internet.password(10, false, /[0-9A-Z]/),
    isProfileNotemealOnly: faker.datatype.boolean(),
  };
}

export const AthleteWithMealPlansFragmentFactory = (): AthleteWithMealPlansFragment =>  {
  return {
    ...AthleteFragmentFactory(),
    mealPlans: [{
      ...MealPlanSearchFormFragmentFactory(),
    }],
  };
}

export const AthleteWithGoalAndAnthroFragmentFactory = (): AthleteWithGoalAndAnthroFragment =>  {
  return {
    ...AthleteFragmentFactory(),
    position: {
      ...PositionFragmentFactory(),
    },
    mostRecentAnthropometryEntry: {
      ...AnthropometryEntryFragmentFactory(),
    },
    currentGoal: {
      ...FullGoalFragmentFactory(),
    },
  };
}

export const AthleteWithFoodPrefsFragmentFactory = (): AthleteWithFoodPrefsFragment =>  {
  return {
    ...AthleteFragmentFactory(),
    likedFoods: [{
      ...FoodFragmentFactory(),
    }],
    dislikedFoods: [{
      ...FoodFragmentFactory(),
    }],
    likedFoodGroups: [{
      ...FullFoodGroupFragmentFactory(),
    }],
    dislikedFoodGroups: [{
      ...FullFoodGroupFragmentFactory(),
    }],
  };
}

export const BrandFragmentFactory = (): BrandFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Brand",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    usdaManufacturerName: faker.internet.password(10, false, /[0-9A-Z]/),
    foodCount: faker.datatype.number(),
  };
}

export const CalorieBudgetFragmentFactory = (): CalorieBudgetFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "CalorieBudget",
    rmrMethod: faker.helpers.arrayElement(["cunningham", "mifflin", "harrisBenedict", "average"]),
    rmrCalories: faker.datatype.number(),
    activityFactor: faker.datatype.float(),
    kcalOffset: faker.datatype.number(),
  };
}

export const FullCalorieBudgetFragmentFactory = (): FullCalorieBudgetFragment =>  {
  return {
    ...CalorieBudgetFragmentFactory(),
    goalSnapshot: {
      ...FullGoalFragmentFactory(),
    },
  };
}

export const ExchangeFragmentFactory = (): ExchangeFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Exchange",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    shortName: faker.internet.password(10, false, /[0-9A-Z]/),
    type: faker.helpers.arrayElement(["starch", "fruit", "vegetable", "protein", "dairy", "fat"]),
    cho: faker.datatype.float(),
    pro: faker.datatype.float(),
    fat: faker.datatype.float(),
    kcal: faker.datatype.float(),
  };
}

export const FullExchangeFragmentFactory = (): FullExchangeFragment =>  {
  return {
    ...ExchangeFragmentFactory(),
    exchangeServingList: {
      ...FullExchangeServingListFragmentFactory(),
    },
  };
}

export const ExchangeAmountFragmentFactory = (): ExchangeAmountFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "ExchangeAmount",
    amount: faker.datatype.float(),
    pickListServingIds: [faker.datatype.uuid()],
    exchange: {
      ...ExchangeFragmentFactory(),
    },
  };
}

export const EditExchangeAmountFragmentFactory = (): EditExchangeAmountFragment =>  {
  return {
    ...ExchangeAmountFragmentFactory(),
    amountInput: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const ExchangeMealTemplateFragmentFactory = (): ExchangeMealTemplateFragment =>  {
  return {
    ...MealTemplateFragmentFactory(),
    __typename: "ExchangeMealTemplate",
  };
}

export const FullExchangeMealTemplateFragmentFactory = (): FullExchangeMealTemplateFragment =>  {
  return {
    ...FullMealTemplateFragmentFactory(),
    ...ExchangeMealTemplateFragmentFactory(),
    exchangeTargets: [{
      ...ExchangeAmountFragmentFactory(),
    }],
  };
}

export const EditExchangeMealTemplateFragmentFactory = (): EditExchangeMealTemplateFragment =>  {
  return {
    ...EditMealTemplateFragmentFactory(),
    exchangeTargets: [{
      ...EditExchangeAmountFragmentFactory(),
    }],
  };
}

export const EditFullExchangeMealTemplateFragmentFactory = (): EditFullExchangeMealTemplateFragment =>  {
  return {
    ...EditFullMealTemplateFragmentFactory(),
    ...ExchangeMealTemplateFragmentFactory(),
    ...EditExchangeMealTemplateFragmentFactory(),
  };
}

export const ExchangeRatioFragmentFactory = (): ExchangeRatioFragment =>  {
  return {
    __typename: "ExchangeRatio",
    ratio: faker.datatype.float(),
    exchange: {
      ...ExchangeFragmentFactory(),
    },
  };
}

export const FoodOrRecipeExchangeRatiosFragmentFactory = (): FoodOrRecipeExchangeRatiosFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "FoodOrRecipeExchangeRatios",
    foodOrRecipeId: faker.datatype.uuid(),
    exchangeRatios: [{
      ...ExchangeRatioFragmentFactory(),
    }],
  };
}

export const ExchangeServingListFragmentFactory = (): ExchangeServingListFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "ExchangeServingList",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const FullExchangeServingListFragmentFactory = (): FullExchangeServingListFragment =>  {
  return {
    ...ExchangeServingListFragmentFactory(),
    servingAmounts: [{
      ...FullServingAmountFragmentFactory(),
    }],
  };
}

export const ExchangeSetFragmentFactory = (): ExchangeSetFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "ExchangeSet",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    createdBy: {
      id: faker.datatype.uuid(),
      firstName: faker.internet.password(10, false, /[0-9A-Z]/),
      lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    },
  };
}

export const ExchangeSetWithExchangesFragmentFactory = (): ExchangeSetWithExchangesFragment =>  {
  return {
    ...ExchangeSetFragmentFactory(),
    exchanges: [{
      ...ExchangeFragmentFactory(),
    }],
  };
}

export const FullExchangeSetWithoutExchangeRatiosFragmentFactory = (): FullExchangeSetWithoutExchangeRatiosFragment =>  {
  return {
    ...ExchangeSetFragmentFactory(),
    exchanges: [{
      ...FullExchangeFragmentFactory(),
    }],
  };
}

export const FullExchangeSetFragmentFactory = (): FullExchangeSetFragment =>  {
  return {
    ...FullExchangeSetWithoutExchangeRatiosFragmentFactory(),
    foodOrRecipeExchangeRatios: [{
      id: faker.datatype.uuid(),
      foodOrRecipeId: faker.datatype.uuid(),
      __typename: "FoodOrRecipeExchangeRatios",
      exchangeRatios: [{
        __typename: "ExchangeRatio",
        ratio: faker.datatype.float(),
        exchange: {
          ...ExchangeFragmentFactory(),
        },
      }],
    }],
  };
}

export const FoodFragmentFactory = (): FoodFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "BrandedFood",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    exchangeTypes: [faker.helpers.arrayElement(["starch", "fruit", "vegetable", "protein", "dairy", "fat"])],
    nixItemId: faker.internet.password(10, false, /[0-9A-Z]/),
    nixTagId: faker.internet.password(10, false, /[0-9A-Z]/),
    usdaFdcDescription: faker.internet.password(10, false, /[0-9A-Z]/),
    usdaFdcFoodCategoryId: faker.datatype.number(),
    usdaFdcDataType: faker.internet.password(10, false, /[0-9A-Z]/),
    usdaFdcId: faker.datatype.number(),
    isRecipeIngredientOnly: faker.datatype.boolean(),
    choPer100g: faker.datatype.float(),
    proPer100g: faker.datatype.float(),
    fatPer100g: faker.datatype.float(),
    kcalPer100g: faker.datatype.float(),
    thumbnailUrl: faker.internet.password(10, false, /[0-9A-Z]/),
    locales: [faker.internet.password(10, false, /[0-9A-Z]/)],
  };
}

export const FoodLimitedAccessFragmentFactory = (): FoodLimitedAccessFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "BrandedFood",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    usdaFdcDescription: faker.internet.password(10, false, /[0-9A-Z]/),
    exchangeTypes: [faker.helpers.arrayElement(["starch", "fruit", "vegetable", "protein", "dairy", "fat"])],
    thumbnailUrl: faker.internet.password(10, false, /[0-9A-Z]/),
    hasFullAccess: faker.datatype.boolean(),
    nixItemId: faker.internet.password(10, false, /[0-9A-Z]/),
    nixTagId: faker.internet.password(10, false, /[0-9A-Z]/),
    org: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    ...({
      brand: {
        id: faker.datatype.uuid(),
        name: faker.internet.password(10, false, /[0-9A-Z]/),
      },
    }),
  };
}

export const FoodPreviewFragmentFactory = (): FoodPreviewFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const BrandedFoodFragmentFactory = (): BrandedFoodFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "BrandedFood",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    exchangeTypes: [faker.helpers.arrayElement(["starch", "fruit", "vegetable", "protein", "dairy", "fat"])],
    usdaFdcDescription: faker.internet.password(10, false, /[0-9A-Z]/),
    usdaFdcFoodCategoryId: faker.datatype.number(),
    usdaFdcDataType: faker.internet.password(10, false, /[0-9A-Z]/),
    usdaFdcId: faker.datatype.number(),
    isRecipeIngredientOnly: faker.datatype.boolean(),
    nixItemId: faker.internet.password(10, false, /[0-9A-Z]/),
    nixTagId: faker.internet.password(10, false, /[0-9A-Z]/),
    choPer100g: faker.datatype.float(),
    proPer100g: faker.datatype.float(),
    fatPer100g: faker.datatype.float(),
    kcalPer100g: faker.datatype.float(),
    thumbnailUrl: faker.internet.password(10, false, /[0-9A-Z]/),
    groceryListCategory: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
  };
}

export const GenericFoodFragmentFactory = (): GenericFoodFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "GenericFood",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    exchangeTypes: [faker.helpers.arrayElement(["starch", "fruit", "vegetable", "protein", "dairy", "fat"])],
    nixItemId: faker.internet.password(10, false, /[0-9A-Z]/),
    nixTagId: faker.internet.password(10, false, /[0-9A-Z]/),
    usdaFdcDescription: faker.internet.password(10, false, /[0-9A-Z]/),
    usdaFdcFoodCategoryId: faker.datatype.number(),
    usdaFdcDataType: faker.internet.password(10, false, /[0-9A-Z]/),
    usdaFdcId: faker.datatype.number(),
    isRecipeIngredientOnly: faker.datatype.boolean(),
    choPer100g: faker.datatype.float(),
    proPer100g: faker.datatype.float(),
    fatPer100g: faker.datatype.float(),
    kcalPer100g: faker.datatype.float(),
    thumbnailUrl: faker.internet.password(10, false, /[0-9A-Z]/),
    groceryListCategory: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    owner: {
      id: faker.datatype.uuid(),
      firstName: faker.internet.password(10, false, /[0-9A-Z]/),
      lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    orgGroup: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
  };
}

export const FoodWithDefaultServingFragmentFactory = (): FoodWithDefaultServingFragment =>  {
  return {
    ...FoodFragmentFactory(),
    defaultServing: {
      ...FullServingFragmentFactory(),
    },
    hasFullAccess: faker.datatype.boolean(),
    org: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    ...({
      __typename: "BrandedFood",
      brand: {
        ...BrandFragmentFactory(),
      },
    }),
  };
}

export const FoodWithServingsFragmentFactory = (): FoodWithServingsFragment =>  {
  return {
    ...FoodFragmentFactory(),
    showGramServing: faker.datatype.boolean(),
    showOunceServing: faker.datatype.boolean(),
    servings: [{
      ...ServingWithFoodOrRecipeFragmentFactory(),
    }],
  };
}

export const FullFoodGroupFragmentFactory = (): FullFoodGroupFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "FoodGroup",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    source: faker.internet.password(10, false, /[0-9A-Z]/),
    foodsCount: faker.datatype.number(),
  };
}

export const FullFoodGroupWithFoodsFragmentFactory = (): FullFoodGroupWithFoodsFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "FoodGroup",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    source: faker.internet.password(10, false, /[0-9A-Z]/),
    foodsCount: faker.datatype.number(),
    foods: [{
      ...FoodLimitedAccessFragmentFactory(),
    }],
  };
}

export const FoodLogNotificationSettingsFragmentFactory = (): FoodLogNotificationSettingsFragment =>  {
  return {
    edits: faker.datatype.boolean(),
    imageUploads: faker.datatype.boolean(),
    messages: faker.datatype.boolean(),
  };
}

export const GoalFragmentFactory = (): GoalFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Goal",
    kcalOffset: faker.datatype.number(),
    start: faker.datatype.datetime().toISOString().split("T")[0],
    end: faker.datatype.datetime().toISOString().split("T")[0],
  };
}

export const FullGoalFragmentFactory = (): FullGoalFragment =>  {
  return {
    ...GoalFragmentFactory(),
    type: {
      ...GoalTypeFragmentFactory(),
    },
  };
}

export const GoalTypeFragmentFactory = (): GoalTypeFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "GoalType",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    defaultKcalOffset: faker.datatype.number(),
  };
}

export const ImageFragmentFactory = (): ImageFragment =>  {
  return {
    id: faker.datatype.uuid(),
    position: faker.datatype.number(),
    url: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const MacroMathFragmentFactory = (): MacroMathFragment =>  {
  return {
    gPerKg: faker.datatype.float(),
    leftOverRatio: faker.datatype.float(),
  };
}

export const MacroMealTemplateFragmentFactory = (): MacroMealTemplateFragment =>  {
  return {
    ...MealTemplateFragmentFactory(),
    __typename: "MacroMealTemplate",
    cho: faker.datatype.float(),
    pro: faker.datatype.float(),
    fat: faker.datatype.float(),
    kcal: faker.datatype.float(),
  };
}

export const FullMacroMealTemplateFragmentFactory = (): FullMacroMealTemplateFragment =>  {
  return {
    ...FullMealTemplateFragmentFactory(),
    ...MacroMealTemplateFragmentFactory(),
  };
}

export const EditMacroMealTemplateFragmentFactory = (): EditMacroMealTemplateFragment =>  {
  return {
    ...EditMealTemplateFragmentFactory(),
    choInput: faker.internet.password(10, false, /[0-9A-Z]/),
    proInput: faker.internet.password(10, false, /[0-9A-Z]/),
    fatInput: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const EditFullMacroMealTemplateFragmentFactory = (): EditFullMacroMealTemplateFragment =>  {
  return {
    ...EditFullMealTemplateFragmentFactory(),
    ...MacroMealTemplateFragmentFactory(),
    ...EditMacroMealTemplateFragmentFactory(),
  };
}

export const MacroProtocolFragmentFactory = (): MacroProtocolFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "MacroProtocol",
    cho: {
      ...MacroMathFragmentFactory(),
    },
    pro: {
      ...MacroMathFragmentFactory(),
    },
    fat: {
      ...MacroMathFragmentFactory(),
    },
    weightTarget: faker.datatype.float(),
    weightTargetInKg: faker.datatype.float(),
  };
}

export const FullMacroProtocolFragmentFactory = (): FullMacroProtocolFragment =>  {
  return {
    ...MacroProtocolFragmentFactory(),
    anthropometrySnapshot: {
      ...AnthropometryEntryFragmentFactory(),
    },
    calorieBudget: {
      ...FullCalorieBudgetFragmentFactory(),
    },
  };
}

export const MealFragmentFactory = (): MealFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Meal",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    start: faker.datatype.datetime().toISOString().split("T")[1],
    end: faker.datatype.datetime().toISOString().split("T")[1],
    type: faker.helpers.arrayElement(["breakfast", "lunch", "dinner", "snack", "meal"]),
  };
}

export const MealOptionFragmentFactory = (): MealOptionFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "MealOption",
    note: faker.internet.password(10, false, /[0-9A-Z]/),
    position: faker.datatype.number(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const EditMealOptionFragmentFactory = (): EditMealOptionFragment =>  {
  return {
    id: faker.datatype.uuid(),
    isAutosaving: faker.datatype.boolean(),
  };
}

export const FullMealOptionFragmentFactory = (): FullMealOptionFragment =>  {
  return {
    ...MealOptionFragmentFactory(),
    servingAmounts: [{
      ...FullServingAmountFragmentFactory(),
    }],
  };
}

export const EditFullMealOptionFragmentFactory = (): EditFullMealOptionFragment =>  {
  return {
    ...FullMealOptionFragmentFactory(),
    ...EditMealOptionFragmentFactory(),
  };
}

export const MealOptionPendingFragmentFactory = (): MealOptionPendingFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "MealOptionPending",
    note: faker.internet.password(10, false, /[0-9A-Z]/),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const EditMealOptionPendingFragmentFactory = (): EditMealOptionPendingFragment =>  {
  return {
    id: faker.datatype.uuid(),
    isAutosaving: faker.datatype.boolean(),
  };
}

export const FullMealOptionPendingFragmentFactory = (): FullMealOptionPendingFragment =>  {
  return {
    ...MealOptionPendingFragmentFactory(),
    servingAmounts: [{
      ...FullServingAmountFragmentFactory(),
    }],
  };
}

export const EditFullMealOptionPendingFragmentFactory = (): EditFullMealOptionPendingFragment =>  {
  return {
    ...FullMealOptionPendingFragmentFactory(),
    ...EditMealOptionPendingFragmentFactory(),
  };
}

export const MealPlanFragmentFactory = (): MealPlanFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "MealPlan",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    isShared: faker.datatype.boolean(),
    isAutoSuggestionsEnabled: faker.datatype.boolean(),
    description: faker.internet.password(10, false, /[0-9A-Z]/),
    usesDateRange: faker.datatype.boolean(),
    dates: [faker.datatype.datetime().toISOString().split("T")[0]],
    startDate: faker.datatype.datetime().toISOString().split("T")[0],
    endDate: faker.datatype.datetime().toISOString().split("T")[0],
    type: faker.helpers.arrayElement(["macro", "exchange"]),
    createdAt: faker.datatype.datetime().toISOString(),
    updatedAt: faker.datatype.datetime().toISOString(),
    exchangeSet: {
      id: faker.datatype.uuid(),
    },
  };
}

export const EditMealPlanFragmentFactory = (): EditMealPlanFragment =>  {
  return {
    id: faker.datatype.uuid(),
    isAutosaving: faker.datatype.boolean(),
    exchangeTargets: [{
      ...EditExchangeAmountFragmentFactory(),
    }],
  };
}

export const MealPlanSearchFormFragmentFactory = (): MealPlanSearchFormFragment =>  {
  return {
    ...MealPlanFragmentFactory(),
    macroProtocol: {
      ...FullMacroProtocolFragmentFactory(),
    },
  };
}

export const BasicMealPlanFragmentFactory = (): BasicMealPlanFragment =>  {
  return {
    ...MealPlanFragmentFactory(),
    exchangeSet: {
      ...ExchangeSetWithExchangesFragmentFactory(),
    },
  };
}

export const PreviewMealPlanFragmentFactory = (): PreviewMealPlanFragment =>  {
  return {
    ...BasicMealPlanFragmentFactory(),
    macroProtocol: {
      ...FullMacroProtocolFragmentFactory(),
    },
    schedule: {
      ...ScheduleFragmentFactory(),
    },
    avoidedFoodGroups: [{
      ...FullFoodGroupWithFoodsFragmentFactory(),
    }],
    promotedFoodGroups: [{
      ...FullFoodGroupWithFoodsFragmentFactory(),
    }],
    avoidedFoods: [{
      ...FoodLimitedAccessFragmentFactory(),
    }],
    promotedFoods: [{
      ...FoodLimitedAccessFragmentFactory(),
    }],
    mostRecentEvent: {
      ...EventFragmentFactory(),
    },
    exchangeTargets: [{
      ...EditExchangeAmountFragmentFactory(),
    }],
  };
}

export const CopyableMealPlanFragmentFactory = (): CopyableMealPlanFragment =>  {
  return {
    ...BasicMealPlanFragmentFactory(),
    macroProtocol: {
      ...MacroProtocolFragmentFactory(),
      calorieBudget: {
        ...CalorieBudgetFragmentFactory(),
      },
    },
  };
}

export const EditFullMealPlanFragmentFactory = (): EditFullMealPlanFragment =>  {
  return {
    ...PreviewMealPlanFragmentFactory(),
    ...EditMealPlanFragmentFactory(),
    athlete: {
      ...AthleteWithFoodPrefsFragmentFactory(),
      macroDisplaySettings: {
        ...MacroMealPlanDisplaySettingsFragmentFactory(),
      },
      exchangeDisplaySettings: {
        ...ExchangeMealPlanDisplaySettingsFragmentFactory(),
      },
    },
    exchangeSet: {
      ...FullExchangeSetFragmentFactory(),
    },
    activityTemplates: [{
      ...ActivityTemplateFragmentFactory(),
    }],
    events: [{
      ...EventFragmentFactory(),
    }],
    mealTemplates: [{
      ...({
        ...EditFullExchangeMealTemplateFragmentFactory(),
      }),
    }],
  };
}

export const FullMealPlanFragmentFactory = (): FullMealPlanFragment =>  {
  return {
    ...PreviewMealPlanFragmentFactory(),
    athlete: {
      ...AthleteWithFoodPrefsFragmentFactory(),
      macroDisplaySettings: {
        ...MacroMealPlanDisplaySettingsFragmentFactory(),
      },
      exchangeDisplaySettings: {
        ...ExchangeMealPlanDisplaySettingsFragmentFactory(),
      },
    },
    exchangeSet: {
      ...FullExchangeSetFragmentFactory(),
    },
    activityTemplates: [{
      ...ActivityTemplateFragmentFactory(),
    }],
    events: [{
      ...EventFragmentFactory(),
    }],
    mealTemplates: [{
      ...({
        ...FullExchangeMealTemplateFragmentFactory(),
      }),
    }],
  };
}

export const EventFragmentFactory = (): EventFragment =>  {
  return {
    __typename: "Event",
    type: faker.internet.password(10, false, /[0-9A-Z]/),
    datetime: faker.datatype.datetime().toISOString(),
  };
}

export const MealTemplateFragmentFactory = (): MealTemplateFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "ExchangeMealTemplate",
    meal: {
      ...MealFragmentFactory(),
    },
  };
}

export const EditMealTemplateFragmentFactory = (): EditMealTemplateFragment =>  {
  return {
    id: faker.datatype.uuid(),
    isAutosaving: faker.datatype.boolean(),
    selectedMealOptionId: faker.datatype.uuid(),
    newMealOptionIds: [faker.datatype.uuid()],
  };
}

export const FullMealTemplateFragmentFactory = (): FullMealTemplateFragment =>  {
  return {
    ...MealTemplateFragmentFactory(),
    mealOptions: [{
      ...FullMealOptionFragmentFactory(),
    }],
    pendingMealOptions: [{
      ...FullMealOptionPendingFragmentFactory(),
    }],
  };
}

export const ExportMealTemplateFragmentFactory = (): ExportMealTemplateFragment =>  {
  return {
    ...MealTemplateFragmentFactory(),
    mealOptions: [{
      ...ViewMealOptionFragmentFactory(),
    }],
  };
}

export const EditFullMealTemplateFragmentFactory = (): EditFullMealTemplateFragment =>  {
  return {
    ...MealTemplateFragmentFactory(),
    ...EditMealTemplateFragmentFactory(),
    mealOptions: [{
      ...EditFullMealOptionFragmentFactory(),
    }],
    pendingMealOptions: [{
      ...EditFullMealOptionPendingFragmentFactory(),
    }],
  };
}

export const NoteFragmentFactory = (): NoteFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Note",
    datetime: faker.datatype.datetime().toISOString(),
    content: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const NoteWithTypeFragmentFactory = (): NoteWithTypeFragment =>  {
  return {
    ...NoteFragmentFactory(),
    type: {
      ...NoteTypeFragmentFactory(),
    },
  };
}

export const FullNoteFragmentFactory = (): FullNoteFragment =>  {
  return {
    ...NoteWithTypeFragmentFactory(),
    athlete: {
      ...AthleteFragmentFactory(),
    },
  };
}

export const NoteTypeFragmentFactory = (): NoteTypeFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "NoteType",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const NutrientDietaryReferenceIntakeFragmentFactory = (): NutrientDietaryReferenceIntakeFragment =>  {
  return {
    id: faker.datatype.uuid(),
    type: faker.helpers.arrayElement(["RDA", "UL", "AI", "DGA", "unitsPerKcal"]),
  };
}

export const PositionFragmentFactory = (): PositionFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Position",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const RecipeFragmentFactory = (): RecipeFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Recipe",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    steps: [faker.internet.password(10, false, /[0-9A-Z]/)],
    note: faker.internet.password(10, false, /[0-9A-Z]/),
    exchangeTypes: [faker.helpers.arrayElement(["starch", "fruit", "vegetable", "protein", "dairy", "fat"])],
    cho: faker.datatype.float(),
    pro: faker.datatype.float(),
    fat: faker.datatype.float(),
    prepTimeInMinutes: faker.datatype.number(),
    cookTimeInMinutes: faker.datatype.number(),
    isCurrent: faker.datatype.boolean(),
    images: [{
      ...ImageFragmentFactory(),
    }],
    isShared: faker.datatype.boolean(),
    createdBy: {
      id: faker.datatype.uuid(),
      firstName: faker.internet.password(10, false, /[0-9A-Z]/),
      lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    mealTypes: [faker.helpers.arrayElement(["breakfast", "lunch", "dinner", "snack", "meal"])],
    score: {
      ...ScoreAssignmentFragmentFactory(),
    },
    foodCategory: {
      ...RecipeFoodCategoryFragmentFactory(),
    },
  };
}

export const RecipeLimitedAccessFragmentFactory = (): RecipeLimitedAccessFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Recipe",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    exchangeTypes: [faker.helpers.arrayElement(["starch", "fruit", "vegetable", "protein", "dairy", "fat"])],
    isCurrent: faker.datatype.boolean(),
    images: [{
      ...ImageFragmentFactory(),
    }],
    descendantFoods: [{
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      nixItemId: faker.internet.password(10, false, /[0-9A-Z]/),
      nixTagId: faker.internet.password(10, false, /[0-9A-Z]/),
    }],
    createdBy: {
      id: faker.datatype.uuid(),
      firstName: faker.internet.password(10, false, /[0-9A-Z]/),
      lastName: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    hasFullAccess: faker.datatype.boolean(),
    org: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    score: {
      ...ScoreAssignmentFragmentFactory(),
    },
    ingredients: [{
      ...RecipeIngredientServingAmountFragmentFactory(),
    }],
  };
}

export const RecipeWithIngredientsFragmentFactory = (): RecipeWithIngredientsFragment =>  {
  return {
    ...RecipeFragmentFactory(),
    ingredients: [{
      ...ServingAmountWithFoodOrRecipeFragmentFactory(),
    }],
  };
}

export const RecipeWithServingsFragmentFactory = (): RecipeWithServingsFragment =>  {
  return {
    ...RecipeWithIngredientsFragmentFactory(),
    hasFullAccess: faker.datatype.boolean(),
    descendantRecipes: [{
      ...RecipeWithIngredientsFragmentFactory(),
    }],
    servings: [{
      ...ServingWithFoodOrRecipeFragmentFactory(),
    }],
  };
}

export const ScheduleFragmentFactory = (): ScheduleFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Schedule",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    activityFactor: faker.datatype.float(),
  };
}

export const FullScheduleFragmentFactory = (): FullScheduleFragment =>  {
  return {
    ...ScheduleFragmentFactory(),
    meals: [{
      ...MealFragmentFactory(),
    }],
    activities: [{
      ...ActivityFragmentFactory(),
    }],
    mealPlans: [{
      ...MealPlanFragmentFactory(),
    }],
  };
}

export const ServingFragmentFactory = (): ServingFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Serving",
    units: faker.internet.password(10, false, /[0-9A-Z]/),
    defaultAmount: faker.datatype.float(),
    isDefault: faker.datatype.boolean(),
    weight: faker.datatype.float(),
    usdaWeightSeq: faker.internet.password(10, false, /[0-9A-Z]/),
    perRecipeYield: faker.datatype.float(),
    isRecipeServingOnly: faker.datatype.boolean(),
    hasOwnNutrients: faker.datatype.boolean(),
  };
}

export const ServingWithMacrosFragmentFactory = (): ServingWithMacrosFragment =>  {
  return {
    ...ServingFragmentFactory(),
    macros: {
      cho: faker.datatype.float(),
      pro: faker.datatype.float(),
      fat: faker.datatype.float(),
      kcal: faker.datatype.float(),
    },
  };
}

export const ServingWithFoodOrRecipeFragmentFactory = (): ServingWithFoodOrRecipeFragment =>  {
  return {
    ...ServingFragmentFactory(),
    macros: {
      cho: faker.datatype.float(),
      pro: faker.datatype.float(),
      fat: faker.datatype.float(),
      kcal: faker.datatype.float(),
    },
    customUnits: faker.internet.password(10, false, /[0-9A-Z]/),
    unitPrefix: faker.internet.password(10, false, /[0-9A-Z]/),
    unitSuffix: faker.internet.password(10, false, /[0-9A-Z]/),
    unit: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    foodOrRecipe: {
      ...({
        ...FoodFragmentFactory(),
        hasFullAccess: faker.datatype.boolean(),
        org: {
          id: faker.datatype.uuid(),
          name: faker.internet.password(10, false, /[0-9A-Z]/),
        },
        ...({
          brand: {
            id: faker.datatype.uuid(),
            name: faker.internet.password(10, false, /[0-9A-Z]/),
          },
        }),
      }),
    },
  };
}

export const RecipeIngredientServingFragmentFactory = (): RecipeIngredientServingFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Serving",
    defaultAmount: faker.datatype.float(),
    isDefault: faker.datatype.boolean(),
    weight: faker.datatype.float(),
    perRecipeYield: faker.datatype.float(),
    hasOwnNutrients: faker.datatype.boolean(),
    macros: {
      cho: faker.datatype.float(),
      pro: faker.datatype.float(),
      fat: faker.datatype.float(),
      kcal: faker.datatype.float(),
    },
    units: faker.internet.password(10, false, /[0-9A-Z]/),
    unit: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    foodOrRecipe: {
      ...({
        ...FoodLimitedAccessFragmentFactory(),
      }),
    },
  };
}

export const FullServingFragmentFactory = (): FullServingFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Serving",
    units: faker.internet.password(10, false, /[0-9A-Z]/),
    defaultAmount: faker.datatype.float(),
    isDefault: faker.datatype.boolean(),
    weight: faker.datatype.float(),
    perRecipeYield: faker.datatype.float(),
    hasOwnNutrients: faker.datatype.boolean(),
    macros: {
      cho: faker.datatype.float(),
      pro: faker.datatype.float(),
      fat: faker.datatype.float(),
      kcal: faker.datatype.float(),
    },
    unit: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    foodOrRecipe: {
      ...({
        ...FoodLimitedAccessFragmentFactory(),
      }),
    },
  };
}

export const ExcessiveServingFragmentFactory = (): ExcessiveServingFragment =>  {
  return {
    ...ServingFragmentFactory(),
    customUnits: faker.internet.password(10, false, /[0-9A-Z]/),
    unitPrefix: faker.internet.password(10, false, /[0-9A-Z]/),
    unitSuffix: faker.internet.password(10, false, /[0-9A-Z]/),
    unit: {
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    },
    macros: {
      cho: faker.datatype.float(),
      pro: faker.datatype.float(),
      fat: faker.datatype.float(),
      kcal: faker.datatype.float(),
    },
    foodOrRecipe: {
      ...({
        ...FoodFragmentFactory(),
        ...({
          brand: {
            id: faker.datatype.uuid(),
            name: faker.internet.password(10, false, /[0-9A-Z]/),
          },
        }),
      }),
    },
  };
}

export const ServingAmountWithFoodOrRecipeFragmentFactory = (): ServingAmountWithFoodOrRecipeFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "ServingAmount",
    amount: faker.datatype.float(),
    position: faker.datatype.number(),
    serving: {
      ...ServingWithFoodOrRecipeFragmentFactory(),
    },
  };
}

export const FullServingAmountFragmentFactory = (): FullServingAmountFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "ServingAmount",
    amount: faker.datatype.float(),
    position: faker.datatype.number(),
    serving: {
      ...FullServingFragmentFactory(),
    },
  };
}

export const ExcessiveServingAmountFragmentFactory = (): ExcessiveServingAmountFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "ServingAmount",
    amount: faker.datatype.float(),
    position: faker.datatype.number(),
    serving: {
      ...ExcessiveServingFragmentFactory(),
    },
  };
}

export const SportFragmentFactory = (): SportFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Sport",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const FullSportFragmentFactory = (): FullSportFragment =>  {
  return {
    ...SportFragmentFactory(),
    positions: [{
      ...PositionFragmentFactory(),
    }],
  };
}

export const TeamFragmentFactory = (): TeamFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "Team",
    gender: faker.internet.password(10, false, /[0-9A-Z]/),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const TeamWithSportFragmentFactory = (): TeamWithSportFragment =>  {
  return {
    ...TeamFragmentFactory(),
    sport: {
      ...FullSportFragmentFactory(),
    },
  };
}

export const UsdaFoodFragmentFactory = (): UsdaFoodFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "USDAFood",
    allHighlightFields: [faker.internet.password(10, false, /[0-9A-Z]/)],
    brandOwner: faker.internet.password(10, false, /[0-9A-Z]/),
    dataType: faker.helpers.arrayElement(["Branded", "Survey", "Legacy", "Foundation"]),
    description: faker.internet.password(10, false, /[0-9A-Z]/),
    gtinUpc: faker.internet.password(10, false, /[0-9A-Z]/),
    ingredients: [faker.internet.password(10, false, /[0-9A-Z]/)],
    publishedDate: faker.datatype.datetime().toISOString().split("T")[0],
    score: faker.datatype.float(),
  };
}

export const UsdaFoodDetailFragmentFactory = (): UsdaFoodDetailFragment =>  {
  return {
    __typename: "USDAFoodDetail",
    id: faker.datatype.uuid(),
    usdaFdcFoodCategoryId: faker.datatype.number(),
    usdaFdcDataType: faker.internet.password(10, false, /[0-9A-Z]/),
    cho: {
      ...UsdaFoodNutrientFragmentFactory(),
    },
    pro: {
      ...UsdaFoodNutrientFragmentFactory(),
    },
    fat: {
      ...UsdaFoodNutrientFragmentFactory(),
    },
    foodNutrients: [{
      ...UsdaFoodNutrientFragmentFactory(),
    }],
    servings: [{
      ...UsdaFoodServingFragmentFactory(),
    }],
  };
}

export const UsdaFoodNutrientFragmentFactory = (): UsdaFoodNutrientFragment =>  {
  return {
    id: faker.datatype.uuid(),
    amount: faker.datatype.float(),
    dataPoints: faker.datatype.number(),
    unitName: faker.internet.password(10, false, /[0-9A-Z]/),
    max: faker.datatype.float(),
    min: faker.datatype.float(),
    median: faker.datatype.float(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    footnote: faker.internet.password(10, false, /[0-9A-Z]/),
    __typename: "USDAFoodNutrient",
  };
}

export const UsdaFoodServingFragmentFactory = (): UsdaFoodServingFragment =>  {
  return {
    __typename: "USDAFoodServing",
    idx: faker.datatype.number(),
    cho: faker.datatype.float(),
    pro: faker.datatype.float(),
    fat: faker.datatype.float(),
    weight: faker.datatype.float(),
    defaultAmount: faker.datatype.float(),
    units: faker.internet.password(10, false, /[0-9A-Z]/),
    isDefault: faker.datatype.boolean(),
    usdaWeightSeq: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const AthleteWithAnthroFragmentFactory = (): AthleteWithAnthroFragment =>  {
  return {
    ...AthleteFragmentFactory(),
    position: {
      ...PositionFragmentFactory(),
    },
    mostRecentAnthropometryEntry: {
      ...AnthropometryEntryFragmentFactory(),
    },
  };
}

export const MealPlanWithAthleteFragmentFactory = (): MealPlanWithAthleteFragment =>  {
  return {
    ...MealPlanSearchFormFragmentFactory(),
    athlete: {
      ...AthleteFragmentFactory(),
    },
  };
}

export const SimpleFoodGroupFragmentFactory = (): SimpleFoodGroupFragment =>  {
  return {
    id: faker.datatype.uuid(),
    name: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const MealPlanBulkCopyTeamPreviewFragmentFactory = (): MealPlanBulkCopyTeamPreviewFragment =>  {
  return {
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    id: faker.datatype.uuid(),
  };
}

export const NutritionixCommonFoodFragmentFactory = (): NutritionixCommonFoodFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "NutritionixCommonFood",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    nixTagId: faker.internet.password(10, false, /[0-9A-Z]/),
    thumbnailUrl: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const NutritionixBrandedFoodFragmentFactory = (): NutritionixBrandedFoodFragment =>  {
  return {
    id: faker.datatype.uuid(),
    __typename: "NutritionixBrandedFood",
    name: faker.internet.password(10, false, /[0-9A-Z]/),
    nixItemId: faker.internet.password(10, false, /[0-9A-Z]/),
    brand: {
      id: faker.datatype.uuid(),
      __typename: "NutritionixBrand",
      name: faker.internet.password(10, false, /[0-9A-Z]/),
      type: faker.datatype.number(),
    },
    thumbnailUrl: faker.internet.password(10, false, /[0-9A-Z]/),
  };
}

export const FoodOrRecipePreferencesFragmentFactory = (): FoodOrRecipePreferencesFragment =>  {
  return {
    ...({
      __typename: "BrandedFood",
      id: faker.datatype.uuid(),
      name: faker.internet.password(10, false, /[0-9A-Z]/),
    }),
  };
}
