export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: Date;
  RRuleSet: any;
  Time: string;
  Timezone: string;
};




export enum AccountVerificationMethod {
  email = 'email',
  txt = 'txt',
  selfServiceLink = 'selfServiceLink',
  selfServiceQr = 'selfServiceQr'
}

export type ActiveAthleteTransferOffsetConnection = {
  __typename?: 'ActiveAthleteTransferOffsetConnection';
  edges: Array<AthleteTransfer>;
  pageInfo: OffsetPageInfo;
};

export type Activity = {
  __typename?: 'Activity';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: ActivityType;
  start: Scalars['Time'];
  end: Scalars['Time'];
};

export type ActivityTemplate = {
  __typename?: 'ActivityTemplate';
  id: Scalars['ID'];
  activity: Activity;
  hasDateModifications: Scalars['Boolean'];
};


export type ActivityTemplateHasDateModificationsArgs = {
  after: Scalars['Date'];
};

/**
 * Modification of an ActivityTemplate, tied to either an individual date or a day of the week
 * Currently only time edits (start and end) are supported
 */
export type ActivityTemplateModification = {
  __typename?: 'ActivityTemplateModification';
  activityTemplate: ActivityTemplate;
  start: Scalars['Time'];
  end: Scalars['Time'];
};

export enum ActivityType {
  practice = 'practice',
  lift = 'lift',
  conditioning = 'conditioning',
  competition = 'competition',
  class = 'class'
}

export type AddActivityInput = {
  name: Scalars['String'];
  start: Scalars['Time'];
  end: Scalars['Time'];
  type: ActivityType;
};

export type AddActivityTemplateCalendarInput = {
  activity: AddActivityInput;
  dateModifications: Array<MealPlanDateModificationInput>;
  dayOfWeekModifications: Array<MealPlanDayOfWeekModificationInput>;
};

export type AddAgreementFormInput = {
  title: Scalars['String'];
  url: Scalars['String'];
  acceptAgreementText: Scalars['String'];
};

export type AddAgreementFormPayload = {
  __typename?: 'AddAgreementFormPayload';
  agreementForm: AgreementForm;
};

export type AddAgreementFormSubmissionInput = {
  agreementFormId: Scalars['ID'];
};

export type AddAgreementFormSubmissionPayload = {
  __typename?: 'AddAgreementFormSubmissionPayload';
  agreementForm: AgreementForm;
};

export type AddAndLinkTeamworksTeamsInput = {
  id: Scalars['ID'];
  linkedTeams: Array<LinkTeamworksTeamInput>;
  newTeams: Array<CreateTeamworksTeamInput>;
  addedSportsMappings: Array<SportsMappingInput>;
  addPositionsMappings: Array<PositionsMappingInput>;
};

export type AddAndLinkTeamworksTeamsPayload = {
  __typename?: 'AddAndLinkTeamworksTeamsPayload';
  org: Org;
};

export type AddAnthropometryEntryInput = {
  athleteId: Scalars['ID'];
  anthropometryEntry: AnthropometryEntryFormInput;
};

export type AddAthleteDislikedFoodGroupPayload = {
  __typename?: 'AddAthleteDislikedFoodGroupPayload';
  athlete: Athlete;
};

export type AddAthleteDislikedFoodPayload = {
  __typename?: 'AddAthleteDislikedFoodPayload';
  athlete: Athlete;
};

export type AddAthleteEmailInput = {
  athleteId: Scalars['ID'];
  email: Scalars['String'];
};

export type AddAthleteEmailPayload = {
  __typename?: 'AddAthleteEmailPayload';
  athlete: Athlete;
};

export type AddAthleteLikedFoodGroupPayload = {
  __typename?: 'AddAthleteLikedFoodGroupPayload';
  athlete: Athlete;
};

export type AddAthleteLikedFoodPayload = {
  __typename?: 'AddAthleteLikedFoodPayload';
  athlete: Athlete;
};

export type AddAthletePhoneNumberInput = {
  athleteId: Scalars['ID'];
  phoneNumber: Scalars['String'];
};

export type AddAthletePhoneNumberPayload = {
  __typename?: 'AddAthletePhoneNumberPayload';
  athlete: Athlete;
};

export type AddBulkOrderInput = {
  mealMenuId: Scalars['ID'];
  pickupTime: Scalars['DateTime'];
  teamId: Scalars['ID'];
  deliveryLocation: Scalars['String'];
  childMealMenuPlates: Array<AddMealMenuPlateInput>;
};

export type AddBulkOrderPayload = {
  __typename?: 'AddBulkOrderPayload';
  bulkOrder: BulkOrder;
  skippedUsers: Array<SkippedUserPayload>;
  skippedPlates: Array<SkippedPlatePayload>;
};

export type AddDeliveryLocationInput = {
  name: Scalars['String'];
  deliveryInstructions: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  teamLabelIds: Array<Scalars['ID']>;
  googlePlaceId: Scalars['String'];
  lat: Scalars['Float'];
  long: Scalars['Float'];
};

export type AddDeliveryLocationPayload = {
  __typename?: 'AddDeliveryLocationPayload';
  deliveryLocation: DeliveryLocation;
};

export type AddDeviceInput = {
  deviceType: DeviceType;
  deviceId: Scalars['String'];
};

export type AddDevicePayload = {
  __typename?: 'AddDevicePayload';
  result: Scalars['Boolean'];
};

export type AddEventInput = {
  entityId: Scalars['ID'];
  entityType: Scalars['String'];
  datetime: Scalars['DateTime'];
  type: Scalars['String'];
};

export type AddEventPayload = {
  __typename?: 'AddEventPayload';
  event: Event;
};

export type AddFoodItemToGroceryListInput = {
  groceryListId: Scalars['ID'];
  servingId: Scalars['ID'];
  amount: Scalars['Int'];
};

export type AddFoodLogActivityWithTemplateForAthleteInput = {
  date: Scalars['Date'];
  athleteId: Scalars['ID'];
  timezone: Scalars['Timezone'];
  rpe: Maybe<Scalars['Int']>;
  activityTemplateId: Scalars['ID'];
  activity: AddActivityInput;
};

export type AddFoodLogActivityWithTemplateForAthletePayload = {
  __typename?: 'AddFoodLogActivityWithTemplateForAthletePayload';
  foodLogActivity: FoodLogActivity;
};

export type AddFoodLogActivityWithTemplateInput = {
  date: Scalars['Date'];
  timezone: Scalars['Timezone'];
  rpe: Maybe<Scalars['Int']>;
  activityTemplateId: Scalars['ID'];
  activity: AddActivityInput;
};

export type AddFoodLogActivityWithTemplatePayload = {
  __typename?: 'AddFoodLogActivityWithTemplatePayload';
  foodLogActivity: FoodLogActivity;
};

export type AddFoodLogActivityWithoutTemplateForAthleteInput = {
  date: Scalars['Date'];
  athleteId: Scalars['ID'];
  timezone: Scalars['Timezone'];
  rpe: Maybe<Scalars['Int']>;
  activity: AddActivityInput;
};

export type AddFoodLogActivityWithoutTemplateForAthletePayload = {
  __typename?: 'AddFoodLogActivityWithoutTemplateForAthletePayload';
  foodLogActivity: FoodLogActivity;
};

export type AddFoodLogActivityWithoutTemplateInput = {
  date: Scalars['Date'];
  timezone: Scalars['Timezone'];
  rpe: Maybe<Scalars['Int']>;
  activity: AddActivityInput;
};

export type AddFoodLogActivityWithoutTemplatePayload = {
  __typename?: 'AddFoodLogActivityWithoutTemplatePayload';
  foodLogActivity: FoodLogActivity;
};

export type AddFoodLogMealCommentInput = {
  foodLogMealId: Scalars['ID'];
  comment: CommentInput;
};

export type AddFoodLogMealCommentPayload = {
  __typename?: 'AddFoodLogMealCommentPayload';
  foodLogMeal: FoodLogMeal;
  comment: Comment;
};

export type AddFoodLogMealImageInput = {
  foodLogMealId: Scalars['ID'];
  imageUrl: Scalars['String'];
  position: Scalars['Int'];
};

export type AddFoodLogMealImagePayload = {
  __typename?: 'AddFoodLogMealImagePayload';
  foodLogMeal: FoodLogMeal;
};

export type AddFoodLogMealPayload = {
  __typename?: 'AddFoodLogMealPayload';
  foodLogMeal: FoodLogMeal;
};

export type AddFoodLogMealWithTemplateForAthleteInput = {
  date: Scalars['Date'];
  athleteId: Scalars['ID'];
  timezone: Scalars['Timezone'];
  mealTemplateId: Scalars['ID'];
  meal: AddMealInput;
};

export type AddFoodLogMealWithTemplateForAthletePayload = {
  __typename?: 'AddFoodLogMealWithTemplateForAthletePayload';
  foodLogMeal: FoodLogMeal;
};

export type AddFoodLogMealWithTemplateInput = {
  date: Scalars['Date'];
  timezone: Scalars['Timezone'];
  mealTemplateId: Scalars['ID'];
  meal: AddMealInput;
};

export type AddFoodLogMealWithTemplatePayload = {
  __typename?: 'AddFoodLogMealWithTemplatePayload';
  foodLogMeal: FoodLogMeal;
};

export type AddFoodLogMealWithoutTemplateForAthleteInput = {
  date: Scalars['Date'];
  athleteId: Scalars['ID'];
  timezone: Scalars['Timezone'];
  meal: AddMealInput;
};

export type AddFoodLogMealWithoutTemplateForAthletePayload = {
  __typename?: 'AddFoodLogMealWithoutTemplateForAthletePayload';
  foodLogMeal: FoodLogMeal;
};

export type AddFoodLogMealWithoutTemplateInput = {
  date: Scalars['Date'];
  timezone: Scalars['Timezone'];
  meal: AddMealInput;
};

export type AddFoodLogMealWithoutTemplatePayload = {
  __typename?: 'AddFoodLogMealWithoutTemplatePayload';
  foodLogMeal: FoodLogMeal;
};

export type AddMealInput = {
  name: Scalars['String'];
  type: MealType;
  start: Scalars['Time'];
  end: Scalars['Time'];
};

export type AddMealMenuDiningStationInput = {
  name: Scalars['String'];
  position: Scalars['Int'];
  maxAmount: Maybe<Scalars['Int']>;
  diningStationTemplateId: Maybe<Scalars['ID']>;
  createMenuItems: Array<CreateMenuItemInput>;
  addMenuItems: Array<AddMenuItemInput>;
  editMenuItems: Array<EditMenuItemInput>;
};

export type AddMealMenuLogItemForAthleteInput = {
  athleteId: Scalars['ID'];
  mealMenuId: Scalars['ID'];
  timelineMeal: TimelineMealInput;
  item: MealMenuLogItemInput;
};

export type AddMealMenuLogItemInput = {
  mealMenuId: Scalars['ID'];
  timelineMeal: TimelineMealInput;
  item: MealMenuLogItemInput;
};

export type AddMealMenuLogItemPayload = {
  __typename?: 'AddMealMenuLogItemPayload';
  timelineMeal: TimelineMeal;
  mealMenuLogItem: MealMenuLogItem;
};

export type AddMealMenuLogItemsForAthleteInput = {
  athleteId: Scalars['ID'];
  mealMenuId: Scalars['ID'];
  timelineMeal: TimelineMealInput;
  items: Array<MealMenuLogItemInput>;
};

export type AddMealMenuLogItemsInput = {
  mealMenuId: Scalars['ID'];
  timelineMeal: TimelineMealInput;
  items: Array<MealMenuLogItemInput>;
};

export type AddMealMenuLogItemsPayload = {
  __typename?: 'AddMealMenuLogItemsPayload';
  timelineMeal: TimelineMeal;
  mealMenuLogItems: Array<MealMenuLogItem>;
};

export type AddMealMenuPlateInput = {
  name: Scalars['String'];
  athleteIds: Array<Scalars['ID']>;
  items: Array<AddMealMenuPlateItemInput>;
};

export type AddMealMenuPlateItemInput = {
  menuItemId: Scalars['ID'];
  amount: Scalars['Float'];
  options: Array<MealMenuPlateItemOptionInput>;
  specialRequests: Maybe<Scalars['String']>;
};

export type AddMealOptionInput = {
  mealTemplateId: Scalars['ID'];
  mealOption: MealOptionInput;
};

export type AddMealOptionPayload = {
  __typename?: 'AddMealOptionPayload';
  mealOption: MealOption;
};

export type AddMealTemplateCalendarInput = {
  meal: AddMealInput;
  dateModifications: Array<MealPlanDateModificationInput>;
  dayOfWeekModifications: Array<MealPlanDayOfWeekModificationInput>;
};

export type AddMenuItemChoiceInput = {
  name: Scalars['String'];
  position: Scalars['Int'];
  required: Scalars['Boolean'];
  maxOptionsCount: Maybe<Scalars['Int']>;
  options: Array<AddMenuItemChoiceOptionInput>;
};

export type AddMenuItemChoiceOptionInput = {
  name: Scalars['String'];
  position: Scalars['Int'];
  canEditAmount: Scalars['Boolean'];
  defaultAmount: Scalars['Float'];
  maxAmount: Scalars['Float'];
  servingAmounts: Array<CreateServingAmountInput>;
};

export type AddMenuItemHistoryMissingIngredientsInput = {
  menuItemHistoryId: Scalars['ID'];
  menuItems: Array<MenuItemMissingIngredientsInput>;
};

export type AddMenuItemHistoryMissingIngredientsPayload = {
  __typename?: 'AddMenuItemHistoryMissingIngredientsPayload';
  menuItemHistory: MenuItemHistory;
};

export type AddMenuItemInput = {
  menuItemId: Scalars['ID'];
  appearance: MenuItemAppearanceInput;
};

export type AddMenuOrderCommentInput = {
  menuOrderId: Scalars['ID'];
  comment: CommentInput;
};

export type AddMenuOrderCommentPayload = {
  __typename?: 'AddMenuOrderCommentPayload';
  menuOrder: MenuOrder;
  comment: Comment;
};

export type AddMenuOrderForAthleteInput = {
  athleteId: Scalars['ID'];
  mealMenuId: Scalars['ID'];
  mealTemplateId: Maybe<Scalars['ID']>;
  pickupTime: Scalars['DateTime'];
  items: Maybe<Array<MenuOrderItemInput>>;
  orderItems: Maybe<Array<MenuOrderItemInput>>;
  logItems: Maybe<Array<MenuOrderItemInput>>;
  images: Maybe<Array<ImageInput>>;
  timelineMeal: Maybe<TimelineMealInput>;
  expectedOrderCount: Maybe<Scalars['Int']>;
};

export type AddMenuOrderForAthletePayload = {
  __typename?: 'AddMenuOrderForAthletePayload';
  menuOrder: MenuOrder;
};

export type AddMenuOrderImageInput = {
  menuOrderId: Scalars['ID'];
  imageUrl: Scalars['String'];
  position: Scalars['Int'];
};

export type AddMenuOrderImagePayload = {
  __typename?: 'AddMenuOrderImagePayload';
  menuOrder: MenuOrder;
};

export type AddMenuOrderItemRatingInput = {
  menuItemId: Scalars['ID'];
  menuOrderItemId: Scalars['ID'];
  value: RatingValue;
  review: Maybe<Scalars['String']>;
};

export type AddMenuOrderItemRatingsInput = {
  ratings: Array<AddMenuOrderItemRatingInput>;
};

export type AddMenuOrderItemRatingsPayload = {
  __typename?: 'AddMenuOrderItemRatingsPayload';
  menuOrderItems: Array<MenuOrderItem>;
};

export type AddMenuOrderPayload = {
  __typename?: 'AddMenuOrderPayload';
  menuOrder: MenuOrder;
};

export type AddMenuOrderWithTemplateInput = {
  mealMenuId: Scalars['ID'];
  mealTemplateId: Scalars['ID'];
  pickupTime: Scalars['DateTime'];
  items: Maybe<Array<MenuOrderItemInput>>;
  orderItems: Maybe<Array<MenuOrderItemInput>>;
  logItems: Maybe<Array<MenuOrderItemInput>>;
  images: Maybe<Array<ImageInput>>;
  timelineMeal: Maybe<TimelineMealInput>;
  expectedOrderCount: Maybe<Scalars['Int']>;
};

export type AddMenuOrderWithTemplatePayload = {
  __typename?: 'AddMenuOrderWithTemplatePayload';
  menuOrder: MenuOrder;
};

export type AddMenuOrderWithoutTemplateInput = {
  mealMenuId: Scalars['ID'];
  pickupTime: Scalars['DateTime'];
  items: Maybe<Array<MenuOrderItemInput>>;
  orderItems: Maybe<Array<MenuOrderItemInput>>;
  logItems: Maybe<Array<MenuOrderItemInput>>;
  images: Maybe<Array<ImageInput>>;
  timelineMeal: Maybe<TimelineMealInput>;
  expectedOrderCount: Maybe<Scalars['Int']>;
};

export type AddMenuOrderWithoutTemplatePayload = {
  __typename?: 'AddMenuOrderWithoutTemplatePayload';
  menuOrder: MenuOrder;
};

export type AddNixBrandedFoodByUpcInput = {
  upc: Scalars['String'];
};

export type AddNixBrandedFoodByUpcPayload = {
  __typename?: 'AddNixBrandedFoodByUpcPayload';
  food: Maybe<Food>;
};

export type AddNixBrandedFoodInput = {
  nixBrandType: Scalars['Int'];
  nixItemId: Scalars['ID'];
};

export type AddNixBrandedFoodPayload = {
  __typename?: 'AddNixBrandedFoodPayload';
  food: Maybe<Food>;
};

export type AddNixCommonFoodInput = {
  name: Scalars['String'];
  nixTagId: Scalars['String'];
};

export type AddNixCommonFoodPayload = {
  __typename?: 'AddNixCommonFoodPayload';
  food: Maybe<Food>;
};

export type AddPositionInput = {
  sportId: Scalars['ID'];
  name: Scalars['String'];
};

export type AddPositionPayload = {
  __typename?: 'AddPositionPayload';
  position: Position;
};

export type AddRecipeImageInput = {
  recipeId: Scalars['ID'];
  image: ImageInput;
};

export type AddRecipeImagePayload = {
  __typename?: 'AddRecipeImagePayload';
  recipe: Recipe;
};

export type AddRestaurantLocationContactInput = {
  locationId: Scalars['ID'];
  restaurantLocationContact: RestaurantLocationContactInput;
};

export type AddRestaurantLocationContactPayload = {
  __typename?: 'AddRestaurantLocationContactPayload';
  restaurantLocationContact: RestaurantLocationContact;
};

export type AddRestaurantLocationInput = {
  restaurantId: Scalars['String'];
  lat: Scalars['Float'];
  long: Scalars['Float'];
  googlePlaceId: Scalars['String'];
  notes: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  phoneNumber: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  deliveryLocationIds: Array<Scalars['ID']>;
};

export type AddRestaurantLocationPayload = {
  __typename?: 'AddRestaurantLocationPayload';
  restaurantLocation: RestaurantLocation;
};

export type AddRestaurantMenuLinkInput = {
  mealMenuId: Scalars['ID'];
  restaurantId: Scalars['ID'];
  restaurantMenuId: Scalars['ID'];
  deliveryLocationId: Maybe<Scalars['ID']>;
  restaurantLocationId: Maybe<Scalars['ID']>;
  allowCustomOrders: Scalars['Boolean'];
  allowPlateOrders: Scalars['Boolean'];
  sections: Array<RestaurantMenuSectionLinkInput>;
  plates: Array<AddRestaurantMenuLinkPlateInput>;
};

export type AddRestaurantMenuLinkOrderForAthleteInput = {
  athleteId: Scalars['ID'];
  restaurantMenuLinkId: Scalars['ID'];
  mealTemplateId: Maybe<Scalars['ID']>;
  items: Array<RestaurantMenuLinkOrderItemInput>;
  timelineMeal: Maybe<TimelineMealInput>;
};

export type AddRestaurantMenuLinkOrderForAthletePayload = {
  __typename?: 'AddRestaurantMenuLinkOrderForAthletePayload';
  restaurantMenuLinkOrder: RestaurantMenuLinkOrder;
  timelineMeal: TimelineMeal;
};

export type AddRestaurantMenuLinkOrderInput = {
  restaurantMenuLinkId: Scalars['ID'];
  mealTemplateId: Maybe<Scalars['ID']>;
  items: Array<RestaurantMenuLinkOrderItemInput>;
  timelineMeal: Maybe<TimelineMealInput>;
};

export type AddRestaurantMenuLinkOrderPayload = {
  __typename?: 'AddRestaurantMenuLinkOrderPayload';
  restaurantMenuLinkOrder: RestaurantMenuLinkOrder;
  timelineMeal: TimelineMeal;
};

export type AddRestaurantMenuLinkPayload = {
  __typename?: 'AddRestaurantMenuLinkPayload';
  restaurantMenuLink: RestaurantMenuLink;
};

export type AddRestaurantMenuLinkPlateInput = {
  position: Scalars['Int'];
  bulkOrderAmount: Maybe<Scalars['Int']>;
  items: Array<AddRestaurantMenuLinkPlateItemInput>;
  isDefault: Maybe<Scalars['Boolean']>;
};

export type AddRestaurantMenuLinkPlateItemInput = {
  menuItemId: Scalars['ID'];
  position: Scalars['Int'];
  amount: Scalars['Float'];
  options: Array<RestaurantMenuLinkPlateItemOptionInput>;
  specialRequests: Maybe<Scalars['String']>;
};

export type AddRestaurantMenuLinkPlateOrderForAthleteInput = {
  athleteId: Scalars['ID'];
  restaurantMenuLinkPlateId: Scalars['ID'];
  mealTemplateId: Maybe<Scalars['ID']>;
  timelineMeal: Maybe<TimelineMealInput>;
};

export type AddRestaurantMenuLinkPlateOrderForAthletePayload = {
  __typename?: 'AddRestaurantMenuLinkPlateOrderForAthletePayload';
  restaurantMenuLinkPlateOrder: RestaurantMenuLinkPlateOrder;
  timelineMeal: TimelineMeal;
};

export type AddRestaurantMenuLinkPlateOrderInput = {
  restaurantMenuLinkPlateId: Scalars['ID'];
  mealTemplateId: Maybe<Scalars['ID']>;
  timelineMeal: Maybe<TimelineMealInput>;
};

export type AddRestaurantMenuLinkPlateOrderPayload = {
  __typename?: 'AddRestaurantMenuLinkPlateOrderPayload';
  restaurantMenuLinkPlateOrder: RestaurantMenuLinkPlateOrder;
  timelineMeal: TimelineMeal;
};

export type AddRestaurantMenuLogForAthleteInput = {
  dateTime: Scalars['DateTime'];
  timezone: Scalars['Timezone'];
  athleteId: Scalars['ID'];
  restaurantMenuId: Scalars['ID'];
  restaurantLocationId: Maybe<Scalars['ID']>;
  mealTemplateId: Maybe<Scalars['ID']>;
  items: Array<RestaurantMenuLogItemInput>;
  type: MealType;
};

export type AddRestaurantMenuLogForAthletePayload = {
  __typename?: 'AddRestaurantMenuLogForAthletePayload';
  restaurantMenuLog: RestaurantMenuLog;
};

export type AddRestaurantMenuLogInput = {
  dateTime: Scalars['DateTime'];
  timezone: Scalars['Timezone'];
  restaurantMenuId: Scalars['ID'];
  mealTemplateId: Maybe<Scalars['ID']>;
  restaurantLocationId: Maybe<Scalars['ID']>;
  items: Array<RestaurantMenuLogItemInput>;
  type: MealType;
};

export type AddRestaurantMenuLogItemForAthleteInput = {
  athleteId: Scalars['ID'];
  restaurantMenuId: Scalars['ID'];
  restaurantLocationId: Maybe<Scalars['ID']>;
  timelineMeal: TimelineMealInput;
  item: RestaurantMenuLogItemInput;
};

export type AddRestaurantMenuLogItemInput = {
  restaurantMenuId: Scalars['ID'];
  restaurantLocationId: Maybe<Scalars['ID']>;
  timelineMeal: TimelineMealInput;
  item: RestaurantMenuLogItemInput;
};

export type AddRestaurantMenuLogItemPayload = {
  __typename?: 'AddRestaurantMenuLogItemPayload';
  timelineMeal: TimelineMeal;
  restaurantMenuLogItem: RestaurantMenuLogItem;
};

export type AddRestaurantMenuLogItemsForAthleteInput = {
  athleteId: Scalars['ID'];
  restaurantMenuId: Scalars['ID'];
  restaurantLocationId: Maybe<Scalars['ID']>;
  timelineMeal: TimelineMealInput;
  items: Array<RestaurantMenuLogItemInput>;
};

export type AddRestaurantMenuLogItemsInput = {
  restaurantMenuId: Scalars['ID'];
  restaurantLocationId: Maybe<Scalars['ID']>;
  timelineMeal: TimelineMealInput;
  items: Array<RestaurantMenuLogItemInput>;
};

export type AddRestaurantMenuLogItemsPayload = {
  __typename?: 'AddRestaurantMenuLogItemsPayload';
  timelineMeal: TimelineMeal;
  restaurantMenuLogItems: Array<RestaurantMenuLogItem>;
};

export type AddRestaurantMenuLogPayload = {
  __typename?: 'AddRestaurantMenuLogPayload';
  restaurantMenuLog: RestaurantMenuLog;
};

export type AddRestaurantMenuSectionInput = {
  name: Scalars['String'];
  position: Scalars['Int'];
  createMenuItems: Array<CreateMenuItemInput>;
  addMenuItems: Array<AddMenuItemInput>;
  editMenuItems: Array<EditMenuItemInput>;
};

export type AddSportInput = {
  name: Scalars['String'];
};

export type AddSportPayload = {
  __typename?: 'AddSportPayload';
  sport: Sport;
};

export type AddTeamLabelInput = {
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type AddTeamLabelPayload = {
  __typename?: 'AddTeamLabelPayload';
  teamLabel: TeamLabel;
};

export type AddTimelineMealCommentForAthleteInput = {
  athleteId: Scalars['ID'];
  timelineMeal: TimelineMealInput;
  text: Scalars['String'];
};

export type AddTimelineMealCommentForMeInput = {
  timelineMeal: TimelineMealInput;
  text: Scalars['String'];
};

export type AddTimelineMealCommentPayload = {
  __typename?: 'AddTimelineMealCommentPayload';
  timelineMeal: TimelineMeal;
  comment: Comment;
};

export type AddTimelineMealForAthleteInput = {
  athleteId: Scalars['ID'];
  name: Scalars['String'];
  start: Scalars['DateTime'];
  timezone: Scalars['Timezone'];
  durationInMinutes: Scalars['Int'];
  type: MealType;
  mealTemplateId: Maybe<Scalars['ID']>;
};

export type AddTimelineMealImageForAthleteInput = {
  athleteId: Scalars['ID'];
  timelineMeal: TimelineMealInput;
  url: Scalars['String'];
  position: Scalars['Int'];
};

export type AddTimelineMealImageInput = {
  timelineMeal: TimelineMealInput;
  url: Scalars['String'];
  position: Scalars['Int'];
};

export type AddTimelineMealImagePayload = {
  __typename?: 'AddTimelineMealImagePayload';
  timelineMeal: TimelineMeal;
};

export type AddTimelineMealInput = {
  name: Scalars['String'];
  start: Scalars['DateTime'];
  timezone: Scalars['Timezone'];
  durationInMinutes: Scalars['Int'];
  type: MealType;
  mealTemplateId: Maybe<Scalars['ID']>;
};

export type AddTimelineMealPayload = {
  __typename?: 'AddTimelineMealPayload';
  timelineMeal: TimelineMeal;
};

export type AddTimelineMealServingAmountForAthleteInput = {
  athleteId: Scalars['ID'];
  timelineMeal: TimelineMealInput;
  servingAmount: CreateServingAmountInput;
};

export type AddTimelineMealServingAmountInput = {
  timelineMeal: TimelineMealInput;
  servingAmount: CreateServingAmountInput;
};

export type AddTimelineMealServingAmountPayload = {
  __typename?: 'AddTimelineMealServingAmountPayload';
  timelineMeal: TimelineMeal;
};

export type Address = {
  __typename?: 'Address';
  displayName: Scalars['String'];
  lat: Scalars['Float'];
  long: Scalars['Float'];
};

export type AdvancedSelectionGroup = {
  __typename?: 'AdvancedSelectionGroup';
  conditions: Array<TagCondition>;
};

export type AdvancedSelectionGroupInput = {
  conditions: Array<AssignTagConditionInput>;
};

export type AdvancedSelectionInput = {
  groups: Array<AdvancedSelectionGroupInput>;
  includeAthleteIds: Array<Scalars['ID']>;
  excludeAthleteIds: Array<Scalars['ID']>;
};

export type AdvancedSelectionType = {
  __typename?: 'AdvancedSelectionType';
  id: Scalars['ID'];
  groups: Array<AdvancedSelectionGroup>;
  includeAthletes: Array<Athlete>;
  excludeAthletes: Array<Athlete>;
};

export type AgreementForm = {
  __typename?: 'AgreementForm';
  id: Scalars['ID'];
  title: Scalars['String'];
  url: Scalars['String'];
  acceptAgreementText: Scalars['String'];
};

export type AgreementFormOffsetConnection = {
  __typename?: 'AgreementFormOffsetConnection';
  edges: Array<AgreementForm>;
  pageInfo: OffsetPageInfo;
};

export type AiAugmented = {
  __typename?: 'AiAugmented';
  isAiGenerated: Scalars['Boolean'];
  isAiTranscribed: Scalars['Boolean'];
};

export enum AiType {
  generated = 'generated',
  transcribed = 'transcribed'
}

export type AnthropometryEntry = {
  __typename?: 'AnthropometryEntry';
  id: Scalars['ID'];
  heightInCm: Maybe<Scalars['Float']>;
  weightInKg: Scalars['Float'];
  leanBodyMassInKg: Maybe<Scalars['Float']>;
  bodyFatMassInKg: Maybe<Scalars['Float']>;
  skeletalMuscleMassInKg: Maybe<Scalars['Float']>;
  trunkFatInKg: Maybe<Scalars['Float']>;
  dryLeanMassInKg: Maybe<Scalars['Float']>;
  visceralFatArea: Maybe<Scalars['Float']>;
  percentBodyFat: Maybe<Scalars['Float']>;
  datetime: Scalars['DateTime'];
  sex: SexType;
  type: AnthropometryEntryType;
  comment: Maybe<Scalars['String']>;
  boneMineralDensity: Maybe<Scalars['Float']>;
  boneMineralContent: Maybe<Scalars['Float']>;
  boneArea: Maybe<Scalars['Float']>;
  boneMass: Maybe<Scalars['Float']>;
  boneMineralDensityZScore: Maybe<Scalars['Float']>;
  boneMineralDensityTScore: Maybe<Scalars['Float']>;
  height: Maybe<Scalars['Float']>;
  weight: Scalars['Float'];
  leanBodyMass: Maybe<Scalars['Float']>;
  bodyFatMass: Maybe<Scalars['Float']>;
  dryLeanMass: Maybe<Scalars['Float']>;
  skeletalMuscleMass: Maybe<Scalars['Float']>;
  trunkFat: Maybe<Scalars['Float']>;
};

export enum AnthropometryEntryField {
  percentBodyFat = 'percentBodyFat',
  height = 'height',
  weight = 'weight',
  leanBodyMass = 'leanBodyMass'
}

export type AnthropometryEntryFormInput = {
  height: Maybe<Scalars['Float']>;
  weight: Scalars['Float'];
  leanBodyMass: Maybe<Scalars['Float']>;
  bodyFatMass: Maybe<Scalars['Float']>;
  percentBodyFat: Maybe<Scalars['Float']>;
  datetime: Scalars['DateTime'];
  sex: SexType;
  type: AnthropometryEntryType;
  comment: Maybe<Scalars['String']>;
  boneMineralDensity: Maybe<Scalars['Float']>;
  boneMass: Maybe<Scalars['Float']>;
  boneMineralContent: Maybe<Scalars['Float']>;
  boneArea: Maybe<Scalars['Float']>;
  boneMineralDensityZScore: Maybe<Scalars['Float']>;
  boneMineralDensityTScore: Maybe<Scalars['Float']>;
  dryLeanMass: Maybe<Scalars['Float']>;
  skeletalMuscleMass: Maybe<Scalars['Float']>;
  trunkFat: Maybe<Scalars['Float']>;
  visceralFatArea: Maybe<Scalars['Float']>;
};

export enum AnthropometryEntryMetricField {
  percentBodyFat = 'percentBodyFat',
  heightInCm = 'heightInCm',
  weightInKg = 'weightInKg',
  leanBodyMass = 'leanBodyMass'
}

export enum AnthropometryEntryType {
  estimate = 'estimate',
  dexa = 'dexa',
  bodpod = 'bodpod',
  bioelectricalImpedance = 'bioelectricalImpedance',
  isak = 'isak',
  weighIn = 'weighIn'
}

export type AnthropometryForm = {
  __typename?: 'AnthropometryForm';
  questions: Array<AnthropometryFormQuestion>;
};

export enum AnthropometryFormField {
  height = 'height',
  weight = 'weight',
  percentBodyFat = 'percentBodyFat'
}

export type AnthropometryFormInput = {
  height: Maybe<Scalars['Float']>;
  weight: Scalars['Float'];
  percentBodyFat: Maybe<Scalars['Float']>;
};

export type AnthropometryFormQuestion = {
  __typename?: 'AnthropometryFormQuestion';
  field: AnthropometryFormField;
};

export type AnthropometryTemplate = {
  __typename?: 'AnthropometryTemplate';
  id: Scalars['ID'];
  height: Maybe<Scalars['Float']>;
  weight: Scalars['Float'];
  heightInCm: Maybe<Scalars['Float']>;
  weightInKg: Maybe<Scalars['Float']>;
  percentBodyFat: Maybe<Scalars['Float']>;
};

export type AnthropometryTemplateInput = {
  height: Maybe<Scalars['Float']>;
  weight: Scalars['Float'];
  percentBodyFat: Maybe<Scalars['Float']>;
};

export type AppModeRoles = {
  __typename?: 'AppModeRoles';
  athlete: Array<Role>;
  dietitian: Array<Role>;
  chef: Array<Role>;
  nonRdAdmin: Array<Role>;
};

export type ArchiveAthleteInput = {
  id: Scalars['ID'];
};

export type ArchiveAthletePayload = {
  __typename?: 'ArchiveAthletePayload';
  athlete: Athlete;
};

export type ArchiveGoalConfigurationInput = {
  id: Scalars['ID'];
};

export type ArchiveGoalConfigurationPayload = {
  __typename?: 'ArchiveGoalConfigurationPayload';
  goalConfigurationId: Scalars['ID'];
};

export type AssignMealPlanTemplateToAthleteInput = {
  athleteId: Scalars['ID'];
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  dateAssignment: OverrideMealPlanDateAssignmentInput;
  mealPlanTemplateId: Scalars['ID'];
  anthropometry: MealPlanFromTemplateAnthropometryInput;
  foodPreferences: Maybe<FoodPreferenceInput>;
  mealPlanTemplateGoalId: Maybe<Scalars['ID']>;
  birthDate: Maybe<Scalars['Date']>;
  isAutoSuggestionsEnabled: Scalars['Boolean'];
};

export type AssignMealPlanTemplateToAthletePayload = {
  __typename?: 'AssignMealPlanTemplateToAthletePayload';
  mealPlan: MealPlan;
};

export type AssignMetricMealPlanTemplateToAthleteInput = {
  athleteId: Scalars['ID'];
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  dateAssignment: OverrideMealPlanDateAssignmentInput;
  mealPlanTemplateId: Scalars['ID'];
  anthropometry: MealPlanFromTemplateMetricAnthropometryInput;
  foodPreferences: Maybe<FoodPreferenceInput>;
  mealPlanTemplateGoalId: Maybe<Scalars['ID']>;
  birthDate: Maybe<Scalars['Date']>;
  isAutoSuggestionsEnabled: Scalars['Boolean'];
};

export type AssignTagConditionInput = {
  isComplement: Scalars['Boolean'];
  type: TagConditionType;
  entityIds: Array<Scalars['ID']>;
};

export type Athlete = {
  __typename?: 'Athlete';
  id: Scalars['ID'];
  activationLinkSent: Maybe<Scalars['DateTime']>;
  isNotemealOnly: Scalars['Boolean'];
  isProfileNotemealOnly: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  sex: SexType;
  birthDate: Maybe<Scalars['Date']>;
  phoneNumber: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  headshotUrl: Maybe<Scalars['String']>;
  secaUid: Maybe<Scalars['String']>;
  inbodyUid: Maybe<Scalars['String']>;
  jerseyNumber: Maybe<Scalars['String']>;
  team: Team;
  position: Maybe<Position>;
  likedFoods: Array<Food>;
  dislikedFoods: Array<Food>;
  likedFoodGroups: Array<FoodGroup>;
  dislikedFoodGroups: Array<FoodGroup>;
  currentGoal: Maybe<Goal>;
  goals: Array<Goal>;
  mostRecentNote: Maybe<Note>;
  notes: Array<Note>;
  mealPlans: Array<MealPlan>;
  sharedMealPlans: Array<MealPlan>;
  sharedMealPlanTemplates: Array<MealPlanTemplate>;
  priorityMealPlanDaysOfWeekInRange: Array<MealPlanDayOfWeekAssignment>;
  mealPlanIndividualDatesInRange: Array<MealPlanIndividualDateAssignment>;
  /** @deprecated Pending web notifications overhaul */
  mealPlanNotifications: Array<Notification>;
  /** @deprecated Pending web notifications overhaul */
  foodLogNotifications: Array<Notification>;
  timelineMealNotifications: Array<Notification>;
  defaultMacroDisplaySettings: Maybe<MacroMealPlanDisplaySettings>;
  defaultExchangeDisplaySettings: Maybe<ExchangeMealPlanDisplaySettings>;
  defaultNoTargetsDisplaySettings: Maybe<NoTargetsDisplaySettings>;
  defaultHideAnthropometry: Maybe<Scalars['Boolean']>;
  macroDisplaySettings: MacroMealPlanDisplaySettings;
  exchangeDisplaySettings: ExchangeMealPlanDisplaySettings;
  noTargetsDisplaySettings: NoTargetsDisplaySettings;
  hideAnthropometry: Scalars['Boolean'];
  mostRecentAnthropometryEntry: Maybe<AnthropometryEntry>;
  mostRecentAnthropometryEntryWith: Maybe<AnthropometryEntry>;
  mostRecentAnthropometryEntryForRmrMethod: Maybe<AnthropometryEntry>;
  anthropometryEntries: Array<AnthropometryEntry>;
  isArchived: Scalars['Boolean'];
  foodLogNotificationSettings: Maybe<FoodLogNotificationSettings>;
  /** @deprecated Remove when getting rid of FoodLogMeal */
  foodLogMealCount: Scalars['Int'];
  imageUploadCount: Scalars['Int'];
  mealPlanViewCount: Scalars['Int'];
  timelineMealCount: Scalars['Int'];
  user: User;
  orgMembership: Maybe<OrgMembership>;
  timelineItemsInRange: Array<TimelineItem>;
  /** @deprecated pending forced update for multiple MenuOrders per menu */
  orderForMenu: Maybe<MenuOrder>;
  ordersForMenu: Array<MenuOrder>;
  ordersForMenus: Array<MenuOrder>;
  kioskPreviewOrderInfo: Array<MenuOrderInfo>;
  checkInsForMealMenus: Array<MealMenuCheckIn>;
  myNamedTagsPreview: MyNamedTagsPreviewPayload;
  myNamedTagsOffsetConnection: NamedTagOffsetConnection;
  allOrderedNamedTags: Array<NamedTag>;
  printableTagsForAthlete: Array<NamedTag>;
  timelineMealsInRange: Array<TimelineMeal>;
  timelineDate: TimelineDate;
  timelineDatesInRange: Array<TimelineDate>;
  recentTimelineCalendarDatesWithActivity: Array<Scalars['String']>;
  mealMenusOnDate: Array<MealMenu>;
  mealPlanDateAssignmentsInRange: Array<MealPlanDateAssignment>;
  upcomingMealMenus: Array<MealMenu>;
  mealMenusCursorConnection: MealMenusCursorConnection;
  activeTransfer: Maybe<AthleteTransfer>;
  hasEverHadGoalTrackerGoals: Scalars['Boolean'];
  goalTrackerGoals: Array<GoalTrackerGoal>;
  assignedToMealMenu: Scalars['Boolean'];
};


export type AthletePriorityMealPlanDaysOfWeekInRangeArgs = {
  start: Scalars['Date'];
  end: Maybe<Scalars['Date']>;
};


export type AthleteMealPlanIndividualDatesInRangeArgs = {
  start: Scalars['Date'];
  end: Maybe<Scalars['Date']>;
};


export type AthleteMostRecentAnthropometryEntryWithArgs = {
  fields: Array<AnthropometryEntryField>;
};


export type AthleteMostRecentAnthropometryEntryForRmrMethodArgs = {
  rmrMethod: Maybe<RmrMethod>;
};


export type AthleteFoodLogMealCountArgs = {
  inLastDays: Maybe<Scalars['Int']>;
};


export type AthleteImageUploadCountArgs = {
  inLastDays: Maybe<Scalars['Int']>;
};


export type AthleteMealPlanViewCountArgs = {
  inLastDays: Maybe<Scalars['Int']>;
};


export type AthleteTimelineMealCountArgs = {
  inLastDays: Maybe<Scalars['Int']>;
  requireOrder: Maybe<Scalars['Boolean']>;
  requireLog: Maybe<Scalars['Boolean']>;
};


export type AthleteTimelineItemsInRangeArgs = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};


export type AthleteOrderForMenuArgs = {
  mealMenuId: Scalars['ID'];
};


export type AthleteOrdersForMenuArgs = {
  mealMenuId: Scalars['ID'];
};


export type AthleteOrdersForMenusArgs = {
  mealMenuIds: Array<Scalars['ID']>;
};


export type AthleteKioskPreviewOrderInfoArgs = {
  mealMenuIds: Array<Scalars['ID']>;
};


export type AthleteCheckInsForMealMenusArgs = {
  mealMenuIds: Array<Scalars['ID']>;
};


export type AthleteMyNamedTagsPreviewArgs = {
  limit: Scalars['Int'];
  priorityTagIds: Array<Scalars['ID']>;
  smartTagsFirst: Maybe<Scalars['Boolean']>;
};


export type AthleteMyNamedTagsOffsetConnectionArgs = {
  input: OffsetPaginationInput;
};


export type AthleteTimelineMealsInRangeArgs = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};


export type AthleteTimelineDateArgs = {
  date: Scalars['Date'];
  timezone: Scalars['Timezone'];
};


export type AthleteTimelineDatesInRangeArgs = {
  start: Scalars['Date'];
  end: Scalars['Date'];
  timezone: Scalars['Timezone'];
};


export type AthleteRecentTimelineCalendarDatesWithActivityArgs = {
  limit: Scalars['Int'];
  timezone: Scalars['Timezone'];
};


export type AthleteMealMenusOnDateArgs = {
  date: Scalars['Date'];
  timezone: Scalars['Timezone'];
};


export type AthleteMealPlanDateAssignmentsInRangeArgs = {
  start: Scalars['Date'];
  end: Scalars['Date'];
};


export type AthleteUpcomingMealMenusArgs = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};


export type AthleteMealMenusCursorConnectionArgs = {
  anchorDate: Scalars['DateTime'];
  input: CursorPaginationInput;
};


export type AthleteGoalTrackerGoalsArgs = {
  date: Scalars['Date'];
};


export type AthleteAssignedToMealMenuArgs = {
  mealMenuId: Scalars['ID'];
};

export type AthleteAttendeePayload = {
  __typename?: 'AthleteAttendeePayload';
  athlete: Athlete;
  checkedIn: Scalars['Boolean'];
};

export type AthleteCursorConnection = {
  __typename?: 'AthleteCursorConnection';
  edges: Array<Athlete>;
  pageInfo: CursorPageInfo;
};

export type AthleteCursorConnectionWithSelectAll = {
  __typename?: 'AthleteCursorConnectionWithSelectAll';
  edges: Array<Athlete>;
  pageInfo: CursorPageInfo;
  selectAllInfo: SelectAllInfo;
};

export enum AthleteFilter {
  active = 'active',
  noLinkSent = 'no_link_sent',
  linkExpired = 'link_expired',
  archived = 'archived'
}

export type AthleteFoodGroupPreferenceInput = {
  athleteId: Scalars['ID'];
  foodGroupId: Scalars['ID'];
};

export type AthleteFoodPreferenceInput = {
  athleteId: Scalars['ID'];
  foodId: Scalars['ID'];
};

export type AthleteFormInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  sex: SexType;
  birthDate: Maybe<Scalars['Date']>;
  phoneNumber: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  positionId: Maybe<Scalars['ID']>;
  inbodyUid: Maybe<Scalars['String']>;
  secaUid: Maybe<Scalars['String']>;
  jerseyNumber: Maybe<Scalars['String']>;
  removedTagIds: Array<Scalars['ID']>;
  addedTagIds: Array<Scalars['ID']>;
};

export type AthleteGoalConfigurationAndTracker = {
  __typename?: 'AthleteGoalConfigurationAndTracker';
  id: Scalars['ID'];
  goalTitle: Scalars['String'];
  goalCategory: Scalars['String'];
  goalCompletionType: Scalars['String'];
  goalTarget: Maybe<Scalars['Int']>;
  goalTargetUnit: Maybe<Scalars['String']>;
  startDate: Maybe<Scalars['DateTime']>;
  endDate: Maybe<Scalars['DateTime']>;
  goalCompletionSummary: GoalCompletionSummary;
  dailyGoalTracker: Array<DailyGoalTrackerGoal>;
};

export type AthleteOffsetConnection = {
  __typename?: 'AthleteOffsetConnection';
  edges: Array<Athlete>;
  pageInfo: OffsetPageInfo;
};

export type AthleteSelfOnboardedNotification = Notification & {
  __typename?: 'AthleteSelfOnboardedNotification';
  id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  type: Scalars['String'];
  createdAt: Scalars['DateTime'];
  viewedAt: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  message: Scalars['String'];
};

export type AthleteTag = Tag & {
  __typename?: 'AthleteTag';
  id: Scalars['ID'];
  tagName: Maybe<TagName>;
  athleteCount: Scalars['Int'];
  isComplement: Scalars['Boolean'];
  createdBy: Maybe<User>;
  athletes: Array<Athlete>;
};

export type AthleteTransfer = {
  __typename?: 'AthleteTransfer';
  note: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  athlete: Athlete;
  releasedByOrg: Org;
  receivedByOrg: Maybe<Org>;
  orgGroup: OrgGroup;
};

export type AuthInput = {
  allow: Array<Role>;
  allowStaff: Maybe<Scalars['Boolean']>;
};

export type BonAppetitCafe = {
  __typename?: 'BonAppetitCafe';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type BonAppetitMenuIdentity = MealMenuIdentity & {
  __typename?: 'BonAppetitMenuIdentity';
  id: Scalars['ID'];
  isOverridden: Scalars['Boolean'];
};

export type BonAppetitMenuInput = {
  date: Scalars['Date'];
  mealId: Scalars['String'];
};

export type BonAppetitMenusPreview = {
  __typename?: 'BonAppetitMenusPreview';
  cafeId: Scalars['String'];
  name: Scalars['String'];
  days: Array<BonAppetitMenusPreviewDay>;
};

export type BonAppetitMenusPreviewDay = {
  __typename?: 'BonAppetitMenusPreviewDay';
  date: Scalars['String'];
  dayParts: Array<BonAppetitMenusPreviewDayPart>;
};

export type BonAppetitMenusPreviewDayPart = {
  __typename?: 'BonAppetitMenusPreviewDayPart';
  id: Scalars['String'];
  label: Scalars['String'];
  startTime: Scalars['String'];
  endTime: Scalars['String'];
};

export type Brand = {
  __typename?: 'Brand';
  id: Scalars['ID'];
  name: Scalars['String'];
  usdaManufacturerName: Maybe<Scalars['String']>;
  foodCount: Scalars['Int'];
};

export type BrandInputForm = {
  name: Scalars['String'];
  usdaManufacturerName: Maybe<Scalars['String']>;
};

export type BrandedFood = Food & {
  __typename?: 'BrandedFood';
  id: Scalars['ID'];
  name: Scalars['String'];
  choPer100g: Scalars['Float'];
  proPer100g: Scalars['Float'];
  fatPer100g: Scalars['Float'];
  kcalPer100g: Scalars['Float'];
  exchangeTypes: Maybe<Array<ExchangeType>>;
  nixItemId: Maybe<Scalars['String']>;
  nixTagId: Maybe<Scalars['String']>;
  usdaFdcId: Maybe<Scalars['Int']>;
  usdaFdcDescription: Maybe<Scalars['String']>;
  usdaFdcFoodCategoryId: Maybe<Scalars['Int']>;
  usdaFdcDataType: Maybe<Scalars['String']>;
  isRecipeIngredientOnly: Scalars['Boolean'];
  excludeFromSuggestions: Scalars['Boolean'];
  thumbnailUrl: Maybe<Scalars['String']>;
  hasFullAccess: Scalars['Boolean'];
  org: Maybe<Org>;
  groceryListCategory: Maybe<GroceryListCategory>;
  servings: Array<Serving>;
  defaultServing: Serving;
  foodGroups: Array<FoodGroup>;
  source: FoodDataSource;
  locales: Maybe<Array<Scalars['String']>>;
  nutrientAmounts: Array<NutrientAmount>;
  /** @deprecated Field no longer supported */
  genericFood: Maybe<GenericFood>;
  /** @deprecated Field no longer supported */
  genericFoodId: Maybe<Scalars['String']>;
  brand: Maybe<Brand>;
  createdById: Maybe<Scalars['ID']>;
  createdBy: Maybe<User>;
  updatedAt: Scalars['DateTime'];
  showGramServing: Scalars['Boolean'];
  showOunceServing: Scalars['Boolean'];
};

export type BrandedFoodCursorConnection = {
  __typename?: 'BrandedFoodCursorConnection';
  edges: Array<BrandedFood>;
  pageInfo: CursorPageInfo;
};

export type BrandedFoodFormInput = {
  name: Scalars['String'];
  choPer100g: Scalars['Float'];
  proPer100g: Scalars['Float'];
  fatPer100g: Scalars['Float'];
  exchangeTypes: Array<ExchangeType>;
  isRecipeIngredientOnly: Scalars['Boolean'];
  excludeFromSuggestions: Scalars['Boolean'];
  thumbnailUrl: Maybe<Scalars['String']>;
  usdaFdcId: Maybe<Scalars['Int']>;
  usdaFdcDescription: Maybe<Scalars['String']>;
  usdaFdcFoodCategoryId: Maybe<Scalars['Int']>;
  usdaFdcDataType: Maybe<Scalars['String']>;
  groceryListCategoryId: Maybe<Scalars['String']>;
  brandId: Maybe<Scalars['String']>;
  source: FoodDataSource;
  locales: Maybe<Array<Scalars['String']>>;
  foodGroupIds: Maybe<Array<Scalars['ID']>>;
  mccanceId: Maybe<Scalars['String']>;
  mccanceName: Maybe<Scalars['String']>;
};

export type BrandedFoodOffsetConnection = {
  __typename?: 'BrandedFoodOffsetConnection';
  edges: Array<BrandedFood>;
  pageInfo: OffsetPageInfo;
};

export type BulkCopyMealPlanInput = {
  athleteIds: Array<Scalars['ID']>;
  mealPlanId: Scalars['ID'];
  mealPlan: MealPlanEditFormInput;
  copyFoodPreferences: Scalars['Boolean'];
  copyPickListServingIds: Scalars['Boolean'];
};

export type BulkCopyMealPlanNewInput = {
  athleteIds: Array<Scalars['ID']>;
  mealPlanId: Scalars['ID'];
  dateAssignment: OverrideMealPlanDateAssignmentInput;
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  copyFoodPreferences: Scalars['Boolean'];
  copyPickListServingIds: Scalars['Boolean'];
  isShared: Scalars['Boolean'];
  isAutoSuggestionsEnabled: Scalars['Boolean'];
};

export type BulkCopyMealPlanPayload = {
  __typename?: 'BulkCopyMealPlanPayload';
  mealPlans: Array<MealPlan>;
};

export type BulkDeleteMealMenusInput = {
  mealMenuIds: Array<Scalars['ID']>;
};

export type BulkDeleteMealMenusPayload = {
  __typename?: 'BulkDeleteMealMenusPayload';
  mealMenuIds: Array<Scalars['ID']>;
};

export type BulkEditMealMenusInput = {
  mealMenuIds: Array<Scalars['ID']>;
  hasTimingEdit: Maybe<Scalars['Boolean']>;
  hasNotificationEdit: Maybe<Scalars['Boolean']>;
  hasSharingEdit: Maybe<Scalars['Boolean']>;
  teamIds: Maybe<Array<Scalars['ID']>>;
  advancedSelectionInput: Maybe<AdvancedSelectionInput>;
  notificationSentBeforeOrderDueInMinutes: Maybe<Scalars['Int']>;
  startTime: Maybe<Scalars['Time']>;
  timezone: Maybe<Scalars['Timezone']>;
  durationInMinutes: Maybe<Scalars['Int']>;
  lastOrderTimeBeforeEndInMinutes: Maybe<Scalars['Int']>;
  prepTimeInMinutes: Maybe<Scalars['Int']>;
};

export type BulkEditMealMenusPayload = {
  __typename?: 'BulkEditMealMenusPayload';
  mealMenus: Array<StandaloneMealMenu>;
};

export type BulkImportCreateAndDeprecateGenericFoodInput = {
  id: Scalars['ID'];
  food: GenericFoodFormInput;
  servings: Array<FoodServingInput>;
  nutrientAmounts: Array<FoodNutrientAmountFormInput>;
  nutrientAmountsManualEntry: Maybe<Array<CreateFoodNutrientAmountFormInput>>;
};

export type BulkImportCreateAndDeprecateGenericFoodsInput = {
  foods: Array<BulkImportCreateAndDeprecateGenericFoodInput>;
};

export type BulkImportCreateAndDeprecateGenericFoodsPayload = {
  __typename?: 'BulkImportCreateAndDeprecateGenericFoodsPayload';
  genericFoods: Array<GenericFood>;
};

export type BulkImportCreateGenericFoodsInput = {
  foods: Array<CreateGenericFoodInput>;
};

export type BulkImportCreateGenericFoodsPayload = {
  __typename?: 'BulkImportCreateGenericFoodsPayload';
  genericFoods: Array<GenericFood>;
};

export type BulkImportCreateRestautantBrandedFoodsInput = {
  foods: Array<CreateRestaurantBrandedFoodInput>;
};

export type BulkImportCreateRestautantBrandedFoodsPayload = {
  __typename?: 'BulkImportCreateRestautantBrandedFoodsPayload';
  brandedFoods: Array<BrandedFood>;
};

export type BulkImportEditRestautantBrandedFoodsInput = {
  foods: Array<EditRestaurantBrandedFoodInput>;
};

export type BulkImportEditRestautantBrandedFoodsPayload = {
  __typename?: 'BulkImportEditRestautantBrandedFoodsPayload';
  brandedFoods: Array<BrandedFood>;
};

export type BulkOrder = {
  __typename?: 'BulkOrder';
  id: Scalars['ID'];
  createdBy: User;
  createdAt: Scalars['DateTime'];
  pickupTime: Scalars['DateTime'];
  deliveryLocation: Scalars['String'];
  team: Team;
  code: Scalars['String'];
  mealMenu: MealMenu;
  childMealMenuPlates: Array<MealMenuPlate>;
  mealMenuPlateOrders: Array<MealMenuPlateOrder>;
  updateCount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  status: MenuOrderItemStatus;
};

export type BulkOrderPublication = {
  __typename?: 'BulkOrderPublication';
  scope: BulkOrderPublisherScope;
  payload: BulkOrderPublicationPayload;
};

export type BulkOrderPublicationPayload = AddBulkOrderPayload | EditBulkOrderPayload | RemoveBulkOrderPayload;

export type BulkOrderPublisherScope = {
  __typename?: 'BulkOrderPublisherScope';
  mealMenuId: Scalars['ID'];
};

export type BulkOrderStatusNotification = Notification & {
  __typename?: 'BulkOrderStatusNotification';
  id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  type: Scalars['String'];
  title: Scalars['String'];
  message: Scalars['String'];
  createdAt: Scalars['DateTime'];
  viewedAt: Maybe<Scalars['DateTime']>;
  entityId: Scalars['ID'];
  bulkOrder: BulkOrder;
};

export type BulkOrderStatusPublication = {
  __typename?: 'BulkOrderStatusPublication';
  scope: BulkOrderPublisherScope;
  payload: UpdateBulkOrderStatusPayload;
};

export type BulkOrderSubscriberScope = {
  mealMenuIds: Maybe<Array<Scalars['ID']>>;
};

export type CalorieBudget = {
  __typename?: 'CalorieBudget';
  id: Scalars['ID'];
  rmrMethod: Maybe<RmrMethod>;
  rmrCalories: Maybe<Scalars['Int']>;
  activityFactor: Scalars['Float'];
  kcalOffset: Scalars['Int'];
  goalSnapshot: Goal;
};

export type CalorieBudgetInput = {
  rmrMethod: Maybe<RmrMethod>;
  rmrCalories: Maybe<Scalars['Int']>;
  activityFactor: Scalars['Float'];
  kcalOffset: Scalars['Int'];
};

export type CbordBusinessUnit = {
  oid: Scalars['Int'];
  name: Scalars['String'];
};

export type CbordFacility = CbordBusinessUnit & {
  __typename?: 'CbordFacility';
  oid: Scalars['Int'];
  name: Scalars['String'];
  businessUnits: Maybe<Array<CbordBusinessUnit>>;
};

export type CbordMenuGroupInput = {
  serviceMenuOids: Array<Scalars['Int']>;
  baseServiceMenuOid: Maybe<Scalars['Int']>;
};

export type CbordMenuIdentity = MealMenuIdentity & {
  __typename?: 'CbordMenuIdentity';
  id: Scalars['ID'];
  isOverridden: Scalars['Boolean'];
};

export type CbordMenuItem = {
  __typename?: 'CbordMenuItem';
  itemOid: Scalars['Int'];
  serviceName: Scalars['String'];
  servedUofmName: Scalars['String'];
  serviceStationOid: Maybe<Scalars['Int']>;
  serviceStationSort: Maybe<Scalars['String']>;
  serviceStationName: Maybe<Scalars['String']>;
};

export type CbordServiceMenu = {
  __typename?: 'CbordServiceMenu';
  oid: Scalars['Int'];
  serviceDate: Scalars['DateTime'];
  meal: Scalars['String'];
  menuItems: Array<CbordMenuItem>;
};

export type CbordServiceUnit = CbordBusinessUnit & {
  __typename?: 'CbordServiceUnit';
  oid: Scalars['Int'];
  name: Scalars['String'];
  facilityOid: Maybe<Scalars['Int']>;
};

export type CheckGroceryListCustomItemInput = {
  id: Scalars['ID'];
};

export type CheckGroceryListCustomItemPayload = {
  __typename?: 'CheckGroceryListCustomItemPayload';
  id: Scalars['ID'];
};

export type CheckGroceryListFoodItemInput = {
  id: Scalars['ID'];
};

export type CheckGroceryListFoodItemPayload = {
  __typename?: 'CheckGroceryListFoodItemPayload';
  id: Scalars['ID'];
};

export type ClientCredential = {
  __typename?: 'ClientCredential';
  id: Scalars['ID'];
  name: Scalars['String'];
  clientId: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type ClientCredentialOffsetConnection = {
  __typename?: 'ClientCredentialOffsetConnection';
  edges: Array<ClientCredential>;
  pageInfo: OffsetPageInfo;
};

export type ComboTag = Tag & {
  __typename?: 'ComboTag';
  id: Scalars['ID'];
  tagName: Maybe<TagName>;
  athleteCount: Scalars['Int'];
  isComplement: Scalars['Boolean'];
  createdBy: Maybe<User>;
  combinationMethod: TagCombinationMethod;
  childTags: Array<Tag>;
  namedChildTags: Array<NamedTag>;
};

export type Comment = {
  __typename?: 'Comment';
  id: Scalars['ID'];
  text: Scalars['String'];
  by: User;
  at: Scalars['DateTime'];
  notification: Maybe<Notification>;
};

export type CommentInput = {
  text: Scalars['String'];
};

export type CompassMenuIdentity = MealMenuIdentity & {
  __typename?: 'CompassMenuIdentity';
  id: Scalars['ID'];
  isOverridden: Scalars['Boolean'];
};

export type CompassMenuInput = {
  date: Scalars['Date'];
  mealPeriodId: Scalars['Int'];
};

export type CompassLocation = {
  __typename?: 'Compass_Location';
  locationId: Scalars['Int'];
  name: Scalars['String'];
  mealPeriods: Array<CompassMealPeriod>;
};

export type CompassMealPeriod = {
  __typename?: 'Compass_MealPeriod';
  displayOrder: Scalars['Int'];
  mealPeriodId: Scalars['Int'];
  name: Scalars['String'];
  stations: Array<CompassStation>;
};

export type CompassMenuItem = {
  __typename?: 'Compass_MenuItem';
  id: Scalars['Int'];
  date: Scalars['String'];
  displayOrder: Scalars['Int'];
  stationId: Scalars['Int'];
  mealPeriodId: Scalars['Int'];
  locationId: Scalars['Int'];
  portion: Scalars['String'];
  name: Scalars['String'];
};

export type CompassRecipe = {
  __typename?: 'Compass_Recipe';
  name: Scalars['String'];
  shortName: Scalars['String'];
  masterRefNumber: Scalars['String'];
};

export type CompassStation = {
  __typename?: 'Compass_Station';
  displayOrder: Scalars['Int'];
  stationId: Scalars['Int'];
  name: Scalars['String'];
};

export type ConvertMenuItemInput = {
  menuItemId: Scalars['ID'];
  name: Scalars['String'];
  servingName: Scalars['String'];
  isOneOff: Scalars['Boolean'];
  description: Maybe<Scalars['String']>;
  appearance: MenuItemAppearanceInput;
  suggestionCategory: Maybe<MenuItemSuggestionCategory>;
  servingAmounts: Array<CreateServingAmountInput>;
  addChoices: Array<AddMenuItemChoiceInput>;
  editChoices: Array<EditMenuItemChoiceInput>;
  removeChoices: Array<RemoveMenuItemChoiceInput>;
  imageUrl: Maybe<Scalars['String']>;
  scoreValue: Maybe<Scalars['Float']>;
  foodCategory: Maybe<FoodCategoryType>;
};

export type ConvertedServingInput = {
  servingId: Scalars['ID'];
  toUnitId: Scalars['ID'];
};

export type CopyExchangeSetInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CopyExchangeSetPayload = {
  __typename?: 'CopyExchangeSetPayload';
  exchangeSet: ExchangeSet;
};

export type CopyMacroMealPlanTemplateInput = {
  macroMealPlanTemplateId: Scalars['ID'];
};

export type CopyMacroMealPlanTemplatePayload = {
  __typename?: 'CopyMacroMealPlanTemplatePayload';
  macroMealPlanTemplate: MacroMealPlanTemplate;
};

export type CopyMealMenuInput = {
  mealMenuId: Scalars['ID'];
  newStartDay: Scalars['Date'];
};

export type CopyMealMenusInput = {
  mealMenuInputs: Array<CopyMealMenuInput>;
};

export type CopyMealMenusPayload = {
  __typename?: 'CopyMealMenusPayload';
  newMealMenus: Array<MealMenu>;
};

export type CopyMealPlanInput = {
  athleteId: Scalars['ID'];
  mealPlanId: Scalars['ID'];
  mealPlan: MealPlanEditFormInput;
  copyFoodPreferences: Scalars['Boolean'];
  copyPickListServingIds: Scalars['Boolean'];
};

export type CopyMealPlanNewInput = {
  athleteId: Scalars['ID'];
  mealPlanId: Scalars['ID'];
  dateAssignment: OverrideMealPlanDateAssignmentInput;
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  copyFoodPreferences: Scalars['Boolean'];
  copyPickListServingIds: Scalars['Boolean'];
  isShared: Scalars['Boolean'];
  isAutoSuggestionsEnabled: Scalars['Boolean'];
};

export type CopyMealPlanPayload = {
  __typename?: 'CopyMealPlanPayload';
  mealPlan: MealPlan;
};

export type CopyPrepPlanInput = {
  newStart: Scalars['Date'];
  newEnd: Scalars['Date'];
  prepPlanId: Scalars['ID'];
};

export type CopyPrepPlanPayload = {
  __typename?: 'CopyPrepPlanPayload';
  prepPlan: PrepPlan;
};

export type CreateAnthropometryEntriesPayload = {
  __typename?: 'CreateAnthropometryEntriesPayload';
  anthropometryEntries: Array<AnthropometryEntry>;
};

export type CreateAnthropometryEntryInput = {
  athleteId: Scalars['ID'];
  anthropometryEntry: AnthropometryEntryFormInput;
};

export type CreateAnthropometryEntryPayload = {
  __typename?: 'CreateAnthropometryEntryPayload';
  anthropometryEntry: AnthropometryEntry;
};

export type CreateAthleteInput = {
  teamId: Scalars['ID'];
  athlete: AthleteFormInput;
  goal: Maybe<GoalFormInput>;
  anthropometryEntries: Maybe<Array<AnthropometryEntryFormInput>>;
  accountVerificationMethod: Maybe<AccountVerificationMethod>;
  isNotemealOnly: Scalars['Boolean'];
};

export type CreateAthletePayload = {
  __typename?: 'CreateAthletePayload';
  athlete: Athlete;
};

export type CreateAthleteTransferInput = {
  athleteId: Scalars['ID'];
  orgGroupId: Scalars['ID'];
  note: Scalars['String'];
};

export type CreateAthleteTransferPayload = {
  __typename?: 'CreateAthleteTransferPayload';
  athleteTransfer: AthleteTransfer;
};

export type CreateAthletesPayload = {
  __typename?: 'CreateAthletesPayload';
  athletes: Array<Athlete>;
};

export type CreateBrandInput = {
  name: Scalars['String'];
  usdaManufacturerName: Maybe<Scalars['String']>;
};

export type CreateBrandPayload = {
  __typename?: 'CreateBrandPayload';
  brand: Brand;
};

export type CreateBrandedFoodInput = {
  food: BrandedFoodFormInput;
  servings: Array<FoodServingInput>;
  nutrientAmounts: Array<FoodNutrientAmountFormInput>;
  nutrientAmountsManualEntry: Maybe<Array<CreateFoodNutrientAmountFormInput>>;
};

export type CreateBrandedFoodPayload = {
  __typename?: 'CreateBrandedFoodPayload';
  brandedFood: BrandedFood;
};

export type CreateClientCredentialInput = {
  name: Scalars['String'];
};

export type CreateClientCredentialPayload = {
  __typename?: 'CreateClientCredentialPayload';
  clientCredential: ClientCredential;
  clientSecret: Scalars['String'];
};

export type CreateCuisineInput = {
  name: Scalars['String'];
};

export type CreateCuisinePayload = {
  __typename?: 'CreateCuisinePayload';
  cuisine: Cuisine;
};

export type CreateCustomTagInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  athleteIds: Array<Scalars['ID']>;
  isPrintable: Maybe<Scalars['Boolean']>;
};

export type CreateDietInput = {
  name: Scalars['String'];
};

export type CreateDietPayload = {
  __typename?: 'CreateDietPayload';
  diet: Diet;
};

export type CreateDiningStationTemplateInput = {
  name: Scalars['String'];
  mealTypes: Array<MealType>;
  createMenuItems: Array<CreateMenuItemInput>;
  addMenuItems: Array<AddMenuItemInput>;
  editMenuItems: Array<EditMenuItemInput>;
  defaultMaxAmount: Maybe<Scalars['Int']>;
};

export type CreateDiningStationTemplatePayload = {
  __typename?: 'CreateDiningStationTemplatePayload';
  diningStationTemplate: DiningStationTemplate;
};

export type CreateExchangeInput = {
  exchange: ExchangeInput;
  exchangeServingList: ExchangeServingListInput;
};

export type CreateExchangeSetInput = {
  name: Scalars['String'];
  exchanges: Array<CreateExchangeInput>;
};

export type CreateExchangeSetPayload = {
  __typename?: 'CreateExchangeSetPayload';
  exchangeSet: ExchangeSet;
};

export type CreateFoodGroupInput = {
  name: Scalars['String'];
  source: Maybe<Scalars['String']>;
  foodIds: Array<Scalars['ID']>;
};

export type CreateFoodGroupPayload = {
  __typename?: 'CreateFoodGroupPayload';
  foodGroup: FoodGroup;
};

export type CreateFoodNutrientAmountFormInput = {
  nutrientName: Scalars['String'];
  amount: Scalars['Float'];
};

export type CreateFoodServingsInput = {
  foodId: Scalars['ID'];
  servings: Array<FoodServingInput>;
};

export type CreateFoodServingsPayload = {
  __typename?: 'CreateFoodServingsPayload';
  servings: Array<Serving>;
};

export type CreateGenericFoodInput = {
  food: GenericFoodFormInput;
  servings: Array<FoodServingInput>;
  nutrientAmounts: Array<FoodNutrientAmountFormInput>;
  nutrientAmountsManualEntry: Maybe<Array<CreateFoodNutrientAmountFormInput>>;
};

export type CreateGenericFoodPayload = {
  __typename?: 'CreateGenericFoodPayload';
  genericFood: GenericFood;
};

export type CreateGoalConfigurationBaseInput = {
  athleteId: Scalars['ID'];
  name: Scalars['String'];
  emoji: Scalars['String'];
  categoryId: Scalars['ID'];
  timezone: Scalars['String'];
  reminders: Array<SetGoalReminderConfigurationInput>;
  target: Maybe<CreateGoalQuantityInput>;
};

export type CreateGoalConfigurationPayload = {
  __typename?: 'CreateGoalConfigurationPayload';
  goalConfiguration: GoalConfiguration;
};

export type CreateGoalConfigurationWithDaysOfWeekInput = {
  input: CreateGoalConfigurationBaseInput;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  dayOfWeekAssignments: Array<DayOfWeek>;
};

export type CreateGoalConfigurationWithIndividualDatesInput = {
  input: CreateGoalConfigurationBaseInput;
  individualDates: Array<Scalars['Date']>;
};

export type CreateGoalInput = {
  athleteId: Scalars['ID'];
  goal: GoalFormInput;
};

export type CreateGoalPayload = {
  __typename?: 'CreateGoalPayload';
  goal: Goal;
};

export type CreateGoalQuantityInput = {
  value: Scalars['Int'];
  unit: Scalars['String'];
};

export type CreateGroceryListCustomItemInput = {
  name: Scalars['String'];
  groceryListId: Scalars['String'];
};

export type CreateGroceryListCustomItemPayload = {
  __typename?: 'CreateGroceryListCustomItemPayload';
  groceryListId: Scalars['String'];
};

export type CreateMacroMealPlanTemplateInput = {
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  additionalState: Maybe<Scalars['String']>;
  macroProtocol: MealPlanTemplateMacroProtocolInput;
  meals: Array<MacroMealPlanTemplateMealInput>;
  foodPreferences: FoodPreferenceInput;
  activities: Array<MealPlanTemplateActivityInput>;
};

export type CreateMacroMealPlanTemplatePayload = {
  __typename?: 'CreateMacroMealPlanTemplatePayload';
  macroMealPlanTemplate: MacroMealPlanTemplate;
};

export type CreateMealMenusInput = {
  starts: Array<Scalars['DateTime']>;
  timezone: Scalars['Timezone'];
  teamIds: Maybe<Array<Scalars['ID']>>;
  advancedSelectionInput: Maybe<AdvancedSelectionInput>;
  name: Scalars['String'];
  type: MealType;
  durationInMinutes: Scalars['Int'];
  prepTimeInMinutes: Scalars['Int'];
  lastOrderTimeBeforeEndInMinutes: Scalars['Int'];
  notificationSentBeforeOrderDueInMinutes: Maybe<Scalars['Int']>;
  orderRateLimit: Maybe<MenuOrderRateLimitInput>;
  diningStations: Array<AddMealMenuDiningStationInput>;
  restaurantMenuLinks: Array<RestaurantMenuLinkInput>;
  userOrderLimit: Maybe<MealMenuUserOrderLimitInput>;
  isHubCheckInEnabled: Maybe<Scalars['Boolean']>;
  isOrderAndLogRestricted: Maybe<Scalars['Boolean']>;
  theme: Maybe<Scalars['String']>;
};

export type CreateMealMenusPayload = {
  __typename?: 'CreateMealMenusPayload';
  mealMenus: Array<StandaloneMealMenu>;
};

export type CreateMealPlanCalendarInput = {
  scheduleId: Scalars['ID'];
  isAutoSuggestionsEnabled: Scalars['Boolean'];
  individualDates: Maybe<Array<Scalars['Date']>>;
  startDate: Maybe<Scalars['Date']>;
  endDate: Maybe<Scalars['Date']>;
  removeConflictingIndividualDates: Maybe<Array<Scalars['Date']>>;
  dayOfWeekPriorities: Maybe<Array<DayOfWeekPriorityInput>>;
  mealTemplates: Array<AddMealTemplateCalendarInput>;
  activityTemplates: Array<AddActivityTemplateCalendarInput>;
};

export type CreateMealPlanFromFoodLogsNewInput = {
  athleteId: Scalars['ID'];
  foodLogMealTemplates: Array<FoodLogMealTemplateInput>;
  foodLogActivityTemplates: Array<AddActivityInput>;
  dateAssignment: OverrideMealPlanDateAssignmentInput;
  type: MealPlanType;
  exchangeSetId: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  isShared: Scalars['Boolean'];
  isAutoSuggestionsEnabled: Scalars['Boolean'];
  macroProtocol: MacroProtocolNewInput;
  foodPreferences: FoodPreferenceInput;
};

export type CreateMealPlanFromFoodLogsPayload = {
  __typename?: 'CreateMealPlanFromFoodLogsPayload';
  mealPlan: MealPlan;
};

export type CreateMealPlanFromFoodLogsWithNewGoalPayload = {
  __typename?: 'CreateMealPlanFromFoodLogsWithNewGoalPayload';
  mealPlan: MealPlan;
};

export type CreateMealPlanFromFoodLogsWithOldGoalPayload = {
  __typename?: 'CreateMealPlanFromFoodLogsWithOldGoalPayload';
  mealPlan: MealPlan;
};

export type CreateMealPlanFromMealPlanTemplateInput = {
  mealPlanTemplateId: Scalars['ID'];
  anthropometry: MealPlanFromTemplateAnthropometryInput;
  foodPreferences: Maybe<FoodPreferenceInput>;
  mealPlanTemplateGoalId: Maybe<Scalars['ID']>;
  birthDate: Maybe<Scalars['Date']>;
};

export type CreateMealPlanFromMealPlanTemplatePayload = {
  __typename?: 'CreateMealPlanFromMealPlanTemplatePayload';
  mealPlan: MealPlan;
};

export type CreateMealPlanNewInput = {
  athleteId: Scalars['ID'];
  calendar: CreateMealPlanCalendarInput;
  type: MealPlanType;
  exchangeSetId: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  isShared: Scalars['Boolean'];
  isAutoSuggestionsEnabled: Scalars['Boolean'];
  macroProtocol: MacroProtocolNewInput;
  foodPreferences: FoodPreferenceInput;
};

export type CreateMealPlanPayload = {
  __typename?: 'CreateMealPlanPayload';
  mealPlan: MealPlan;
};

export type CreateMealPlanWithNewGoalPayload = {
  __typename?: 'CreateMealPlanWithNewGoalPayload';
  mealPlan: MealPlan;
};

export type CreateMealPlanWithOldGoalPayload = {
  __typename?: 'CreateMealPlanWithOldGoalPayload';
  mealPlan: MealPlan;
};

export type CreateMenuItemInput = {
  isOneOff: Scalars['Boolean'];
  name: Scalars['String'];
  servingName: Scalars['String'];
  description: Maybe<Scalars['String']>;
  appearance: MenuItemAppearanceInput;
  suggestionCategory: Maybe<MenuItemSuggestionCategory>;
  servingAmounts: Array<CreateServingAmountInput>;
  choices: Array<AddMenuItemChoiceInput>;
  imageUrl: Maybe<Scalars['String']>;
  scoreValue: Maybe<Scalars['Float']>;
  foodCategory: Maybe<FoodCategoryType>;
};

export type CreateMetricAnthropometryEntryInput = {
  athleteId: Scalars['ID'];
  anthropometryEntry: MetricAnthropometryEntryFormInput;
};

export type CreateMetricAthleteInput = {
  teamId: Scalars['ID'];
  athlete: AthleteFormInput;
  goal: Maybe<GoalFormInput>;
  anthropometryEntries: Maybe<Array<MetricAnthropometryEntryFormInput>>;
  accountVerificationMethod: Maybe<AccountVerificationMethod>;
  isNotemealOnly: Scalars['Boolean'];
};

export type CreateMetricMealPlanFromFoodLogsInput = {
  athleteId: Scalars['ID'];
  foodLogMealTemplates: Array<FoodLogMealTemplateInput>;
  foodLogActivityTemplates: Array<AddActivityInput>;
  dateAssignment: OverrideMealPlanDateAssignmentInput;
  type: MealPlanType;
  exchangeSetId: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  isShared: Scalars['Boolean'];
  isAutoSuggestionsEnabled: Scalars['Boolean'];
  macroProtocol: MetricMacroProtocolInput;
  foodPreferences: FoodPreferenceInput;
};

export type CreateMetricMealPlanFromMealPlanTemplateInput = {
  mealPlanTemplateId: Scalars['ID'];
  anthropometry: MealPlanFromTemplateMetricAnthropometryInput;
  foodPreferences: Maybe<FoodPreferenceInput>;
  mealPlanTemplateGoalId: Maybe<Scalars['ID']>;
  birthDate: Maybe<Scalars['Date']>;
};

export type CreateMetricMealPlanInput = {
  athleteId: Scalars['ID'];
  calendar: CreateMealPlanCalendarInput;
  type: MealPlanType;
  exchangeSetId: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  isShared: Scalars['Boolean'];
  isAutoSuggestionsEnabled: Scalars['Boolean'];
  macroProtocol: MetricMacroProtocolInput;
  foodPreferences: FoodPreferenceInput;
};

export type CreateNoteInput = {
  athleteId: Scalars['ID'];
  note: NoteFormInput;
};

export type CreateNotePayload = {
  __typename?: 'CreateNotePayload';
  note: Note;
};

export type CreateNoteTypeInput = {
  name: Scalars['String'];
};

export type CreateNoteTypePayload = {
  __typename?: 'CreateNoteTypePayload';
  noteType: NoteType;
};

export type CreateOrgFromTeamworksOrgInput = {
  teamworksId: Scalars['Int'];
  name: Scalars['String'];
};

export type CreateOrgFromTeamworksOrgPayload = {
  __typename?: 'CreateOrgFromTeamworksOrgPayload';
  org: Org;
};

export type CreateOrgGroupInput = {
  name: Scalars['String'];
  allowsResourceSharing: Scalars['Boolean'];
  allowsAthleteTransfer: Scalars['Boolean'];
  parentOrgGroupId: Maybe<Scalars['ID']>;
  orgIds: Array<Scalars['ID']>;
};

export type CreateOrgGroupPayload = {
  __typename?: 'CreateOrgGroupPayload';
  orgGroup: OrgGroup;
};

export type CreateOrgInput = {
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
  allowsPublicAccess: Scalars['Boolean'];
  memberships: Array<CreateOrgMembershipAsStaffInput>;
  localeCode: Scalars['String'];
  territoryName: Maybe<Scalars['String']>;
};

export type CreateOrgMembershipAsStaffInput = {
  method: AccountVerificationMethod;
  phoneNumber: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  teamName: Scalars['String'];
  roles: Array<Role>;
  isAdmin: Scalars['Boolean'];
  isNotemealOnly: Scalars['Boolean'];
};

export type CreateOrgMembershipForAthleteInput = {
  athlete: CreateOrgMembershipForAthleteObjectInput;
};

export type CreateOrgMembershipForAthleteObjectInput = {
  athleteId: Scalars['ID'];
  teamName: Scalars['String'];
  method: AccountVerificationMethod;
  roles: Array<Role>;
};

export type CreateOrgMembershipForAthletePayload = {
  __typename?: 'CreateOrgMembershipForAthletePayload';
  athlete: Athlete;
  generatedSelfServiceActivationLink: Maybe<Scalars['String']>;
};

export type CreateOrgMembershipInput = {
  method: AccountVerificationMethod;
  phoneNumber: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  teamName: Scalars['String'];
  roles: Array<Role>;
};

export type CreateOrgMembershipsAsStaffInput = {
  memberships: Array<CreateOrgMembershipAsStaffInput>;
  orgId: Scalars['ID'];
};

export type CreateOrgMembershipsInput = {
  memberships: Array<CreateOrgMembershipInput>;
};

export type CreateOrgMembershipsPayload = {
  __typename?: 'CreateOrgMembershipsPayload';
  memberships: Array<OrgMembership>;
};

export type CreateOrgPayload = {
  __typename?: 'CreateOrgPayload';
  org: Org;
};

export type CreatePaymentMethodInput = {
  name: Scalars['String'];
};

export type CreatePaymentMethodPayload = {
  __typename?: 'CreatePaymentMethodPayload';
  paymentMethod: PaymentMethod;
};

export type CreatePlannedMenuInput = {
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  occurrence: PlannedMenuOccurrence;
  timezone: Scalars['String'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};

export type CreatePlannedMenuPayload = {
  __typename?: 'CreatePlannedMenuPayload';
  plannedMenu: PlannedMenu;
};

export type CreatePrepPlanInput = {
  start: Scalars['Date'];
  end: Scalars['Date'];
};

export type CreatePrepPlanMealInput = {
  prepPlanId: Scalars['ID'];
  name: Scalars['String'];
  priority: Scalars['Int'];
  mealTypes: Maybe<Array<MealType>>;
  servingAmounts: Array<CreateServingAmountInput>;
  recipeId: Maybe<Scalars['ID']>;
  mealOptionId: Maybe<Scalars['ID']>;
  edited: Maybe<Scalars['Boolean']>;
  prepPlanMealAssignments: Maybe<Array<PrepPlanMealAssignmentInput>>;
  prepPlanMealSlotAssignments: Maybe<Array<PrepPlanMealSlotAssignmentInput>>;
};

export type CreatePrepPlanMealPayload = {
  __typename?: 'CreatePrepPlanMealPayload';
  prepPlanMeal: PrepPlanMeal;
};

export type CreatePrepPlanPayload = {
  __typename?: 'CreatePrepPlanPayload';
  prepPlan: PrepPlan;
};

export type CreateRecipeFullInput = {
  recipe: RecipeFullInput;
  servings: Array<RecipeServingFullInput>;
  copiedFromId: Maybe<Scalars['ID']>;
};

export type CreateRecipeFullPayload = {
  __typename?: 'CreateRecipeFullPayload';
  recipe: Recipe;
};

export type CreateRecipeSimpleInput = {
  recipe: RecipeSimpleInput;
  serving: RecipeServingSimpleInput;
};

export type CreateRecipeSimplePayload = {
  __typename?: 'CreateRecipeSimplePayload';
  recipe: Recipe;
};

export type CreateRestaurantBrandedFoodInput = {
  food: BrandedFoodFormInput;
  servings: Array<FoodServingInput>;
  nutrientAmounts: Array<FoodNutrientAmountFormInput>;
  nutrientAmountsManualEntry: Maybe<Array<CreateFoodNutrientAmountFormInput>>;
  restaurantId: Scalars['ID'];
};

export type CreateRestaurantBrandedFoodPayload = {
  __typename?: 'CreateRestaurantBrandedFoodPayload';
  brandedFood: BrandedFood;
};

export type CreateRestaurantInput = {
  name: Scalars['String'];
  nutritionixId: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  website: Maybe<Scalars['String']>;
  proteinPortionSizeInOunces: Maybe<Scalars['Int']>;
  firstPartyDelivery: Scalars['Boolean'];
  athleteVisible: Scalars['Boolean'];
  refundPolicy: Maybe<RefundPolicyType>;
  refundDueBeforeInMinutes: Maybe<Scalars['Int']>;
  restaurantTypeId: Maybe<Scalars['ID']>;
  paymentMethodIds: Array<Scalars['ID']>;
  cuisineIds: Array<Scalars['ID']>;
};

export type CreateRestaurantMenuInput = {
  restaurantId: Scalars['ID'];
  sections: Array<AddRestaurantMenuSectionInput>;
  supportedDietIds: Array<Scalars['ID']>;
};

export type CreateRestaurantMenuPayload = {
  __typename?: 'CreateRestaurantMenuPayload';
  restaurantMenu: RestaurantMenu;
};

export type CreateRestaurantPayload = {
  __typename?: 'CreateRestaurantPayload';
  restaurant: Restaurant;
};

export type CreateRestaurantTypeInput = {
  name: Scalars['String'];
};

export type CreateRestaurantTypePayload = {
  __typename?: 'CreateRestaurantTypePayload';
  restaurantType: RestaurantType;
};

export type CreateReusableMenuItemInput = {
  name: Scalars['String'];
  servingName: Scalars['String'];
  description: Maybe<Scalars['String']>;
  defaultAvailableForOrder: Scalars['Boolean'];
  defaultMaxAmount: Maybe<Scalars['Int']>;
  suggestionCategory: Maybe<MenuItemSuggestionCategory>;
  servingAmounts: Array<CreateServingAmountInput>;
  choices: Array<AddMenuItemChoiceInput>;
  imageUrl: Maybe<Scalars['String']>;
  defaultAllowSpecialRequests: Scalars['Boolean'];
  scoreValue: Maybe<Scalars['Float']>;
  foodCategory: Maybe<FoodCategoryType>;
};

export type CreateReusableMenuItemPayload = {
  __typename?: 'CreateReusableMenuItemPayload';
  menuItem: MenuItem;
};

export type CreateScheduleInput = {
  teamId: Scalars['ID'];
  meals: Array<AddMealInput>;
  activities: Array<AddActivityInput>;
  activityFactor: Maybe<Scalars['Float']>;
  name: Scalars['String'];
};

export type CreateSchedulePayload = {
  __typename?: 'CreateSchedulePayload';
  schedule: Schedule;
};

export type CreateServingAmountInput = {
  servingId: Scalars['ID'];
  amount: Scalars['Float'];
  position: Scalars['Int'];
};

export type CreateSimpleBrandedFoodInput = {
  name: Scalars['String'];
  cho: Scalars['Float'];
  pro: Scalars['Float'];
  fat: Scalars['Float'];
  defaultAmount: Scalars['Float'];
  units: ServingUnitsInput;
  weight: Scalars['Float'];
  exchangeTypes: Array<ExchangeType>;
  isRecipeIngredientOnly: Scalars['Boolean'];
  usdaFdcId: Maybe<Scalars['Int']>;
  usdaFdcDescription: Maybe<Scalars['String']>;
  usdaFdcFoodCategoryId: Maybe<Scalars['Int']>;
  usdaFdcDataType: Maybe<Scalars['String']>;
  nutrientAmounts: Maybe<Array<FoodNutrientAmountFormInput>>;
  foodGroupIds: Maybe<Array<Scalars['ID']>>;
};

export type CreateSimpleBrandedFoodPayload = {
  __typename?: 'CreateSimpleBrandedFoodPayload';
  brandedFood: BrandedFood;
};

export type CreateSmartTagInput = {
  name: Scalars['String'];
  description: Scalars['String'];
  condition: AssignTagConditionInput;
  isPrintable: Maybe<Scalars['Boolean']>;
};

export type CreateTeamInput = {
  sportId: Maybe<Scalars['ID']>;
  gender: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CreateTeamPayload = {
  __typename?: 'CreateTeamPayload';
  team: Team;
};

export type CreateTeamworksPositionInput = {
  teamworksId: Scalars['Int'];
  teamworksTeamId: Scalars['Int'];
  name: Scalars['String'];
};

export type CreateTeamworksSportInput = {
  name: Scalars['String'];
  newPositions: Array<CreateTeamworksPositionInput>;
};

export type CreateTeamworksTeamInput = {
  teamworksId: Scalars['Int'];
  name: Scalars['String'];
  gender: Maybe<Scalars['String']>;
  linkedSport: Maybe<LinkedSportInput>;
  newSport: Maybe<CreateTeamworksSportInput>;
};

export type CreateTerritoryInput = {
  name: Scalars['String'];
  orgIds: Array<Scalars['ID']>;
};

export type CreateUserBrandedFoodFormInput = {
  name: Scalars['String'];
  cho: Scalars['Float'];
  pro: Scalars['Float'];
  fat: Scalars['Float'];
  defaultAmount: Scalars['Float'];
  units: ServingUnitsInput;
  nutrientAmounts: Array<CreateFoodNutrientAmountFormInput>;
};

export type CreateWeightUnitServingForFoodInput = {
  foodId: Scalars['ID'];
  unitId: Scalars['ID'];
};

export type CreateWeightUnitServingForFoodPayload = {
  __typename?: 'CreateWeightUnitServingForFoodPayload';
  serving: Serving;
  amount: Scalars['Float'];
};

export type Cuisine = {
  __typename?: 'Cuisine';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type CuisineCursorConnection = {
  __typename?: 'CuisineCursorConnection';
  edges: Array<Cuisine>;
  pageInfo: CursorPageInfo;
};

export type CuisineOffsetConnection = {
  __typename?: 'CuisineOffsetConnection';
  edges: Array<Cuisine>;
  pageInfo: OffsetPageInfo;
};

export type CursorPageInfo = {
  __typename?: 'CursorPageInfo';
  hasNextPage: Scalars['Boolean'];
  endCursor: Scalars['String'];
};

export type CursorPaginationInput = {
  cursor: Maybe<Scalars['String']>;
  limit: Maybe<Scalars['Int']>;
};

export type DailyGoalTrackerGoal = {
  __typename?: 'DailyGoalTrackerGoal';
  id: Scalars['ID'];
  date: Scalars['DateTime'];
  completed: Scalars['Boolean'];
  completedAtInUTC: Maybe<Scalars['DateTime']>;
  loggedValue: Maybe<Scalars['Int']>;
};



export enum DayOfWeek {
  monday = 'monday',
  tuesday = 'tuesday',
  wednesday = 'wednesday',
  thursday = 'thursday',
  friday = 'friday',
  saturday = 'saturday',
  sunday = 'sunday'
}

export type DayOfWeekPriority = {
  __typename?: 'DayOfWeekPriority';
  dayOfWeek: DayOfWeek;
  priority: Scalars['Int'];
};

export type DayOfWeekPriorityInput = {
  dayOfWeek: DayOfWeek;
  priority: Scalars['Int'];
};

export type DaySummary = {
  __typename?: 'DaySummary';
  datetime: Scalars['DateTime'];
  targetMacrosForDay: MacrosAndKcals;
  totalMacrosLogged: MacrosAndKcals;
};

export type DeleteAnthropometryEntryInput = {
  id: Scalars['ID'];
};

export type DeleteAnthropometryEntryPayload = {
  __typename?: 'DeleteAnthropometryEntryPayload';
  anthropometryEntryId: Scalars['ID'];
};

export type DeleteAthleteInput = {
  id: Scalars['ID'];
};

export type DeleteAthletePayload = {
  __typename?: 'DeleteAthletePayload';
  athleteId: Scalars['ID'];
};

export type DeleteCuisineInput = {
  id: Scalars['ID'];
};

export type DeleteCuisinePayload = {
  __typename?: 'DeleteCuisinePayload';
  id: Scalars['ID'];
};

export type DeleteDietInput = {
  id: Scalars['ID'];
};

export type DeleteDietPayload = {
  __typename?: 'DeleteDietPayload';
  id: Scalars['ID'];
};

export type DeleteDiningStationTemplateInput = {
  diningStationTemplateId: Scalars['ID'];
};

export type DeleteDiningStationTemplatePayload = {
  __typename?: 'DeleteDiningStationTemplatePayload';
  diningStationTemplateId: Scalars['ID'];
};

export type DeleteExchangeSetInput = {
  id: Scalars['ID'];
};

export type DeleteExchangeSetPayload = {
  __typename?: 'DeleteExchangeSetPayload';
  id: Scalars['ID'];
};

export type DeleteFoodInput = {
  id: Scalars['ID'];
};

export type DeleteFoodPayload = {
  __typename?: 'DeleteFoodPayload';
  foodId: Scalars['ID'];
};

export type DeleteGoalConfigurationInput = {
  id: Scalars['ID'];
};

export type DeleteGoalConfigurationPayload = {
  __typename?: 'DeleteGoalConfigurationPayload';
  goalConfigurationId: Scalars['ID'];
};

export type DeleteGroceryListCustomItemInput = {
  id: Scalars['ID'];
};

export type DeleteGroceryListCustomItemPayload = {
  __typename?: 'DeleteGroceryListCustomItemPayload';
  id: Scalars['ID'];
};

export type DeleteGroceryListFoodItemInput = {
  id: Scalars['ID'];
};

export type DeleteInProgressPrepPlanInput = {
  prepPlanId: Scalars['ID'];
};

export type DeleteInProgressPrepPlanPayload = {
  __typename?: 'DeleteInProgressPrepPlanPayload';
  prepPlanId: Scalars['ID'];
};

export type DeleteMacroMealPlanTemplateInput = {
  macroMealPlanTemplateId: Scalars['ID'];
};

export type DeleteMacroMealPlanTemplatePayload = {
  __typename?: 'DeleteMacroMealPlanTemplatePayload';
  macroMealPlanTemplateId: Scalars['ID'];
};

export type DeleteMacroMealPlanTemplatePendingStateInput = {
  macroMealPlanTemplateId: Scalars['ID'];
};

export type DeleteMacroMealPlanTemplatePendingStatePayload = {
  __typename?: 'DeleteMacroMealPlanTemplatePendingStatePayload';
  macroMealPlanTemplate: MealPlanTemplate;
};

export type DeleteMealMenuInput = {
  mealMenuId: Scalars['ID'];
};

export type DeleteMealMenuPayload = {
  __typename?: 'DeleteMealMenuPayload';
  mealMenuId: Scalars['ID'];
};

export type DeleteMealPlanInput = {
  id: Scalars['ID'];
};

export type DeleteMealPlanPayload = {
  __typename?: 'DeleteMealPlanPayload';
  mealPlanId: Scalars['ID'];
  athlete: Athlete;
};

export type DeleteMealPlansInput = {
  ids: Array<Scalars['ID']>;
};

export type DeleteMealPlansPayload = {
  __typename?: 'DeleteMealPlansPayload';
  mealPlanIds: Array<Scalars['ID']>;
};

export type DeleteNoteInput = {
  id: Scalars['ID'];
};

export type DeleteNotePayload = {
  __typename?: 'DeleteNotePayload';
  noteId: Scalars['ID'];
};

export type DeleteNoteTypeInput = {
  id: Scalars['ID'];
};

export type DeleteNoteTypePayload = {
  __typename?: 'DeleteNoteTypePayload';
  noteTypeId: Scalars['ID'];
};

export type DeletePaymentMethodInput = {
  id: Scalars['ID'];
};

export type DeletePaymentMethodPayload = {
  __typename?: 'DeletePaymentMethodPayload';
  id: Scalars['ID'];
};

export type DeletePlannedMenuInput = {
  id: Scalars['ID'];
};

export type DeletePlannedMenuPayload = {
  __typename?: 'DeletePlannedMenuPayload';
  plannedMenuId: Scalars['ID'];
};

export type DeleteRecipeInput = {
  recipeId: Scalars['ID'];
};

export type DeleteRecipePayload = {
  __typename?: 'DeleteRecipePayload';
  recipeId: Scalars['ID'];
};

export type DeleteRestaurantTypeInput = {
  id: Scalars['ID'];
};

export type DeleteRestaurantTypePayload = {
  __typename?: 'DeleteRestaurantTypePayload';
  id: Scalars['ID'];
};

export type DeleteReusableMenuItemInput = {
  after: Scalars['DateTime'];
  menuItemId: Scalars['ID'];
};

export type DeleteReusableMenuItemPayload = {
  __typename?: 'DeleteReusableMenuItemPayload';
  menuItemId: Scalars['ID'];
};

export type DeleteTagPayload = {
  __typename?: 'DeleteTagPayload';
  tagId: Scalars['ID'];
};

export type DeleteTeamInput = {
  teamId: Scalars['ID'];
};

export type DeleteTeamPayload = {
  __typename?: 'DeleteTeamPayload';
  teamId: Scalars['ID'];
};

export type DeleteUserBrandedFoodInput = {
  id: Scalars['ID'];
};

export type DeleteUserBrandedFoodPayload = {
  __typename?: 'DeleteUserBrandedFoodPayload';
  id: Scalars['ID'];
};

export type DeliveryLocation = {
  __typename?: 'DeliveryLocation';
  id: Scalars['ID'];
  name: Scalars['String'];
  deliveryInstructions: Maybe<Scalars['String']>;
  googlePlaceId: Scalars['String'];
  isActive: Scalars['Boolean'];
  cityInfo: Maybe<Scalars['String']>;
  address: Maybe<Address>;
  teamLabels: Array<TeamLabel>;
};

export type DeliveryLocationCursorConnection = {
  __typename?: 'DeliveryLocationCursorConnection';
  edges: Array<DeliveryLocation>;
  pageInfo: CursorPageInfo;
};

export type DeliveryLocationOffsetConnection = {
  __typename?: 'DeliveryLocationOffsetConnection';
  edges: Array<DeliveryLocation>;
  pageInfo: OffsetPageInfo;
};

export type DeprecateFoodInput = {
  id: Scalars['ID'];
};

export type DeprecateFoodPayload = {
  __typename?: 'DeprecateFoodPayload';
  foodId: Scalars['ID'];
};

export type DeprecateRestaurantBrandedFoodInput = {
  id: Scalars['ID'];
  restaurantId: Scalars['ID'];
};

export type DeprecateRestaurantBrandedFoodPayload = {
  __typename?: 'DeprecateRestaurantBrandedFoodPayload';
  foodId: Scalars['ID'];
};

export enum DeviceType {
  ios = 'IOS',
  android = 'ANDROID'
}

export type Diet = {
  __typename?: 'Diet';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DietCursorConnection = {
  __typename?: 'DietCursorConnection';
  edges: Array<Diet>;
  pageInfo: CursorPageInfo;
};

export type DietOffsetConnection = {
  __typename?: 'DietOffsetConnection';
  edges: Array<Diet>;
  pageInfo: OffsetPageInfo;
};

export type DiningStationTemplate = {
  __typename?: 'DiningStationTemplate';
  id: Scalars['ID'];
  name: Scalars['String'];
  mealTypes: Array<MealType>;
  menuItemAppearances: Array<MenuItemAppearance>;
  defaultMaxAmount: Maybe<Scalars['Int']>;
};

export type DiningStationTemplateCursorConnection = {
  __typename?: 'DiningStationTemplateCursorConnection';
  edges: Array<DiningStationTemplate>;
  pageInfo: CursorPageInfo;
};

export type DiningStationTemplateOffsetConnection = {
  __typename?: 'DiningStationTemplateOffsetConnection';
  edges: Array<DiningStationTemplate>;
  pageInfo: OffsetPageInfo;
};

export type DuplicatePlannedMenuInput = {
  originalMenuId: Scalars['ID'];
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  timezone: Scalars['String'];
};

export type EditActivityInput = {
  activity: AddActivityInput;
  activityId: Scalars['ID'];
};

export type EditActivityTemplateCalendarInput = {
  activityTemplateId: Scalars['ID'];
  activity: Maybe<AddActivityInput>;
  resetModifications: Scalars['Boolean'];
  dateModifications: Array<MealPlanDateModificationInput>;
  dayOfWeekModifications: Array<MealPlanDayOfWeekModificationInput>;
};

export type EditAgreementFormInput = {
  id: Scalars['ID'];
  title: Scalars['String'];
  url: Scalars['String'];
  acceptAgreementText: Scalars['String'];
};

export type EditAgreementFormPayload = {
  __typename?: 'EditAgreementFormPayload';
  agreementForm: AgreementForm;
};

export type EditAnthropometryEntriesPayload = {
  __typename?: 'EditAnthropometryEntriesPayload';
  anthropometryEntries: Array<AnthropometryEntry>;
};

export type EditAnthropometryEntryCommentInput = {
  id: Scalars['ID'];
  comment: Scalars['String'];
};

export type EditAnthropometryEntryCommentPayload = {
  __typename?: 'EditAnthropometryEntryCommentPayload';
  anthropometryEntry: AnthropometryEntry;
};

export type EditAnthropometryEntryInput = {
  id: Scalars['ID'];
  anthropometryEntry: AnthropometryEntryFormInput;
};

export type EditAnthropometryEntryPayload = {
  __typename?: 'EditAnthropometryEntryPayload';
  anthropometryEntry: AnthropometryEntry;
};

export type EditAnthropometryEntryWeighInForDateForAthleteInput = {
  athleteId: Scalars['ID'];
  weight: Scalars['Float'];
  date: Scalars['Date'];
};

export type EditAnthropometryEntryWeighInForDateForAthletePayload = {
  __typename?: 'EditAnthropometryEntryWeighInForDateForAthletePayload';
  anthropometryEntry: AnthropometryEntry;
};

export type EditAnthropometryEntryWeighInForDateInput = {
  weight: Scalars['Float'];
  date: Scalars['Date'];
};

export type EditAnthropometryEntryWeighInForDatePayload = {
  __typename?: 'EditAnthropometryEntryWeighInForDatePayload';
  anthropometryEntry: AnthropometryEntry;
};

export type EditAthleteAndAnthroInput = {
  athleteId: Scalars['ID'];
  teamId: Scalars['ID'];
  athlete: AthleteFormInput;
  anthropometryEntry: Maybe<AnthropometryEntryFormInput>;
};

export type EditAthleteAndMetricAnthroInput = {
  athleteId: Scalars['ID'];
  teamId: Scalars['ID'];
  athlete: AthleteFormInput;
  anthropometryEntry: Maybe<MetricAnthropometryEntryFormInput>;
};

export type EditAthleteDisplaySettingsInput = {
  athleteId: Scalars['ID'];
  macroDisplaySettings: Maybe<MacroMealPlanDisplaySettingsInput>;
  exchangeDisplaySettings: Maybe<ExchangeMealPlanDisplaySettingsInput>;
  noTargetsDisplaySettings: Maybe<NoTargetsDisplaySettingsInput>;
  hideAnthropometry: Maybe<Scalars['Boolean']>;
};

export type EditAthleteDisplaySettingsPayload = {
  __typename?: 'EditAthleteDisplaySettingsPayload';
  athlete: Athlete;
};

export type EditAthleteFoodLogNotificationSettingsInput = {
  athleteId: Scalars['ID'];
  settings: Maybe<FoodLogNotificationSettingsInput>;
};

export type EditAthleteFoodLogNotificationSettingsPayload = {
  __typename?: 'EditAthleteFoodLogNotificationSettingsPayload';
  athlete: Athlete;
};

export type EditAthleteInput = {
  athleteId: Scalars['ID'];
  teamId: Scalars['ID'];
  athlete: AthleteFormInput;
};

export type EditAthletePayload = {
  __typename?: 'EditAthletePayload';
  athlete: Athlete;
};

export type EditAthletesPayload = {
  __typename?: 'EditAthletesPayload';
  athletes: Array<Athlete>;
};

export type EditBrandInput = {
  id: Scalars['ID'];
  brand: BrandInputForm;
};

export type EditBrandPayload = {
  __typename?: 'EditBrandPayload';
  brand: Brand;
};

export type EditBrandedFoodInput = {
  id: Scalars['ID'];
  food: BrandedFoodFormInput;
  nutrientAmounts: Maybe<Array<FoodNutrientAmountFormInput>>;
  nutrientAmountsManualEntry: Maybe<Array<CreateFoodNutrientAmountFormInput>>;
};

export type EditBrandedFoodPayload = {
  __typename?: 'EditBrandedFoodPayload';
  brandedFood: BrandedFood;
};

export type EditBulkOrderInput = {
  bulkOrderId: Scalars['ID'];
  pickupTime: Scalars['DateTime'];
  teamId: Scalars['ID'];
  deliveryLocation: Scalars['String'];
  addPlates: Maybe<Array<AddMealMenuPlateInput>>;
  editPlates: Maybe<Array<EditMealMenuPlateInput>>;
  removePlateIds: Maybe<Array<Scalars['ID']>>;
};

export type EditBulkOrderPayload = {
  __typename?: 'EditBulkOrderPayload';
  bulkOrder: BulkOrder;
  addedMealMenuPlateOrders: Array<MealMenuPlateOrder>;
  editedMealMenuPlateOrders: Array<MealMenuPlateOrder>;
  removedMealMenuPlateOrderIds: Array<Scalars['ID']>;
};

export type EditClientCredentialInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type EditClientCredentialPayload = {
  __typename?: 'EditClientCredentialPayload';
  clientCredential: ClientCredential;
};

export type EditCuisineInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type EditCuisinePayload = {
  __typename?: 'EditCuisinePayload';
  cuisine: Cuisine;
};

export type EditCustomTagInput = {
  id: Scalars['ID'];
  tagInput: CreateCustomTagInput;
};

export type EditCustomizedPrepPlanMealInput = {
  prepPlanMealId: Scalars['ID'];
  name: Scalars['String'];
  mealTypes: Array<MealType>;
  servingAmounts: Array<CreateServingAmountInput>;
};

export type EditCustomizedPrepPlanMealPayload = {
  __typename?: 'EditCustomizedPrepPlanMealPayload';
  prepPlanMeal: PrepPlanMeal;
};

export type EditDeliveryLocationInput = {
  deliveryLocationId: Scalars['ID'];
  name: Scalars['String'];
  deliveryInstructions: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  teamLabelIds: Array<Scalars['ID']>;
  cityInfo: Maybe<Scalars['String']>;
};

export type EditDeliveryLocationPayload = {
  __typename?: 'EditDeliveryLocationPayload';
  deliveryLocation: DeliveryLocation;
};

export type EditDietInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type EditDietPayload = {
  __typename?: 'EditDietPayload';
  diet: Diet;
};

export type EditDiningStationTemplateInput = {
  after: Scalars['DateTime'];
  diningStationTemplateId: Scalars['ID'];
  name: Scalars['String'];
  mealTypes: Array<MealType>;
  createMenuItems: Array<CreateMenuItemInput>;
  addMenuItems: Array<AddMenuItemInput>;
  editMenuItems: Array<EditMenuItemInput>;
  moveMenuItems: Array<MoveMenuItemInput>;
  convertMenuItems: Array<ConvertMenuItemInput>;
  removeMenuItems: Array<RemoveMenuItemInput>;
  defaultMaxAmount: Maybe<Scalars['Int']>;
};

export type EditDiningStationTemplatePayload = {
  __typename?: 'EditDiningStationTemplatePayload';
  diningStationTemplate: DiningStationTemplate;
};

export type EditExchangeInput = {
  id: Scalars['ID'];
  exchange: ExchangeInput;
  exchangeServingList: Maybe<ExchangeServingListInput>;
};

export type EditExchangeMealTemplateInput = {
  mealTemplateId: Scalars['ID'];
  exchangeTargets: Array<ExchangeAmountInput>;
};

export type EditExchangeMealTemplatePayload = {
  __typename?: 'EditExchangeMealTemplatePayload';
  exchangeMealTemplate: ExchangeMealTemplate;
};

export type EditExchangeMealTemplatesPayload = {
  __typename?: 'EditExchangeMealTemplatesPayload';
  exchangeMealTemplates: Array<ExchangeMealTemplate>;
};

export type EditExchangeServingListInput = {
  id: Scalars['ID'];
  exchangeServingList: ExchangeServingListInput;
};

export type EditExchangeSetInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  addExchanges: Array<CreateExchangeInput>;
  editExchanges: Array<EditExchangeInput>;
  removeExchanges: Array<Scalars['ID']>;
};

export type EditExchangeSetPayload = {
  __typename?: 'EditExchangeSetPayload';
  exchangeSet: ExchangeSet;
};

export type EditFoodGroupInput = {
  id: Scalars['ID'];
  foodGroup: CreateFoodGroupInput;
};

export type EditFoodGroupPayload = {
  __typename?: 'EditFoodGroupPayload';
  foodGroup: FoodGroup;
};

export type EditFoodLogActivityRpeInput = {
  foodLogActivityId: Scalars['String'];
  rpe: Scalars['Int'];
};

export type EditFoodLogActivityRpePayload = {
  __typename?: 'EditFoodLogActivityRpePayload';
  foodLogActivity: FoodLogActivity;
};

export type EditFoodLogActivityTemplateInput = {
  foodLogActivityId: Scalars['ID'];
  activityTemplateId: Scalars['ID'];
};

export type EditFoodLogActivityTemplatePayload = {
  __typename?: 'EditFoodLogActivityTemplatePayload';
  foodLogActivity: FoodLogActivity;
};

export type EditFoodLogActivityTimeWithTimezoneInput = {
  foodLogActivityId: Scalars['String'];
  activity: AddActivityInput;
  timezone: Scalars['Timezone'];
};

export type EditFoodLogActivityTimeWithTimezonePayload = {
  __typename?: 'EditFoodLogActivityTimeWithTimezonePayload';
  foodLogActivity: FoodLogActivity;
};

export type EditFoodLogMealImageInput = {
  foodLogMealId: Scalars['ID'];
  imageUrl: Scalars['String'];
};

export type EditFoodLogMealImagePayload = {
  __typename?: 'EditFoodLogMealImagePayload';
  foodLogMeal: FoodLogMeal;
};

export type EditFoodLogMealServingAmountsInput = {
  foodLogMealId: Scalars['ID'];
  servingAmounts: Array<CreateServingAmountInput>;
};

export type EditFoodLogMealServingAmountsPayload = {
  __typename?: 'EditFoodLogMealServingAmountsPayload';
  foodLogMeal: FoodLogMeal;
};

export type EditFoodLogMealTemplateInput = {
  foodLogMealId: Scalars['ID'];
  mealTemplateId: Scalars['ID'];
};

export type EditFoodLogMealTemplatePayload = {
  __typename?: 'EditFoodLogMealTemplatePayload';
  foodLogMeal: FoodLogMeal;
};

export type EditFoodLogMealTimePayload = {
  __typename?: 'EditFoodLogMealTimePayload';
  foodLogMeal: FoodLogMeal;
};

export type EditFoodLogMealTimeWithTimezoneInput = {
  foodLogMealId: Scalars['String'];
  meal: AddMealInput;
  timezone: Scalars['Timezone'];
};

export type EditFoodLogMealTimeWithTimezonePayload = {
  __typename?: 'EditFoodLogMealTimeWithTimezonePayload';
  foodLogMeal: FoodLogMeal;
};

export type EditFoodServingInput = {
  id: Scalars['ID'];
  serving: FoodServingInput;
};

export type EditFoodServingPayload = {
  __typename?: 'EditFoodServingPayload';
  serving: Serving;
};

export type EditGenericFoodInput = {
  id: Scalars['ID'];
  food: GenericFoodFormInput;
  nutrientAmounts: Maybe<Array<FoodNutrientAmountFormInput>>;
  nutrientAmountsManualEntry: Maybe<Array<CreateFoodNutrientAmountFormInput>>;
};

export type EditGenericFoodPayload = {
  __typename?: 'EditGenericFoodPayload';
  genericFood: GenericFood;
};

export type EditGoalConfigurationBaseInput = {
  id: Scalars['ID'];
  name: Maybe<Scalars['String']>;
  target: Maybe<CreateGoalQuantityInput>;
  removeTarget: Scalars['Boolean'];
  timezone: Scalars['String'];
  reminders: Array<SetGoalReminderConfigurationInput>;
};

export type EditGoalConfigurationPayload = {
  __typename?: 'EditGoalConfigurationPayload';
  goalConfiguration: GoalConfiguration;
};

export type EditGoalConfigurationWithDaysOfWeekInput = {
  input: EditGoalConfigurationBaseInput;
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  dayOfWeekAssignments: Array<DayOfWeek>;
};

export type EditGoalConfigurationWithIndividualDatesInput = {
  input: EditGoalConfigurationBaseInput;
  addIndividualDates: Array<Scalars['Date']>;
  removeIndividualDates: Array<Scalars['Date']>;
};

export type EditGroceryListCustomItemInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type EditGroceryListCustomItemPayload = {
  __typename?: 'EditGroceryListCustomItemPayload';
  id: Scalars['ID'];
};

export type EditGroceryListFoodItemInput = {
  id: Scalars['ID'];
  groceryListCategoryId: Maybe<Scalars['ID']>;
  amount: Maybe<Scalars['Int']>;
  adjustment: Maybe<Scalars['Float']>;
  servingId: Scalars['ID'];
};

export type EditGroceryListFoodItemPayload = {
  __typename?: 'EditGroceryListFoodItemPayload';
  id: Scalars['ID'];
};

export type EditGroceryListInput = {
  id: Scalars['ID'];
  notes: Maybe<Scalars['String']>;
};

export type EditGroceryListPayload = {
  __typename?: 'EditGroceryListPayload';
  id: Scalars['ID'];
};

export type EditMacroMealPlanTemplateInput = {
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  additionalState: Maybe<Scalars['String']>;
  macroProtocol: MealPlanTemplateMacroProtocolInput;
  meals: Array<MacroMealPlanTemplateMealInput>;
  foodPreferences: FoodPreferenceInput;
  activities: Array<MealPlanTemplateActivityInput>;
};

export type EditMacroMealPlanTemplatePayload = {
  __typename?: 'EditMacroMealPlanTemplatePayload';
  macroMealPlanTemplate: MacroMealPlanTemplate;
};

export type EditMacroMealTemplateInput = {
  mealTemplateId: Scalars['ID'];
  cho: Maybe<Scalars['Float']>;
  pro: Maybe<Scalars['Float']>;
  fat: Maybe<Scalars['Float']>;
};

export type EditMacroMealTemplatePayload = {
  __typename?: 'EditMacroMealTemplatePayload';
  macroMealTemplate: MacroMealTemplate;
};

export type EditMacroMealTemplatesPayload = {
  __typename?: 'EditMacroMealTemplatesPayload';
  macroMealTemplates: Array<MacroMealTemplate>;
};

export type EditMacroProtocolInput = {
  macroProtocolId: Scalars['String'];
  macroProtocol: MacroProtocolInput;
};

export type EditMacroProtocolPayload = {
  __typename?: 'EditMacroProtocolPayload';
  macroProtocol: MacroProtocol;
};

export type EditMacroProtocolWithNewGoalInput = {
  macroProtocolId: Scalars['String'];
  macroProtocol: MacroProtocolWithNewGoalInput;
};

export type EditMacroProtocolWithNewGoalPayload = {
  __typename?: 'EditMacroProtocolWithNewGoalPayload';
  macroProtocol: MacroProtocol;
};

export type EditMacroProtocolWithOldGoalInput = {
  macroProtocolId: Scalars['String'];
  macroProtocol: MacroProtocolWithOldGoalInput;
};

export type EditMacroProtocolWithOldGoalPayload = {
  __typename?: 'EditMacroProtocolWithOldGoalPayload';
  macroProtocol: MacroProtocol;
};

export type EditMealInput = {
  mealId: Scalars['ID'];
  meal: AddMealInput;
};

export type EditMealMenuDiningStationInput = {
  mealMenuDiningStationId: Scalars['ID'];
  name: Scalars['String'];
  position: Scalars['Int'];
  maxAmount: Maybe<Scalars['Int']>;
  createMenuItems: Array<CreateMenuItemInput>;
  addMenuItems: Array<AddMenuItemInput>;
  editMenuItems: Array<EditMenuItemInput>;
  moveMenuItems: Array<MoveMenuItemInput>;
  convertMenuItems: Array<ConvertMenuItemInput>;
  removeMenuItems: Array<RemoveMenuItemInput>;
};

export type EditMealMenuInput = {
  mealMenuId: Scalars['ID'];
  teamIds: Maybe<Array<Scalars['ID']>>;
  advancedSelectionInput: Maybe<AdvancedSelectionInput>;
  name: Scalars['String'];
  type: MealType;
  start: Scalars['DateTime'];
  timezone: Scalars['Timezone'];
  durationInMinutes: Scalars['Int'];
  prepTimeInMinutes: Scalars['Int'];
  lastOrderTimeBeforeEndInMinutes: Scalars['Int'];
  notificationSentBeforeOrderDueInMinutes: Maybe<Scalars['Int']>;
  orderRateLimit: Maybe<MenuOrderRateLimitInput>;
  userOrderLimit: Maybe<MealMenuUserOrderLimitInput>;
  isHubCheckInEnabled: Maybe<Scalars['Boolean']>;
  isOrderAndLogRestricted: Maybe<Scalars['Boolean']>;
  theme: Maybe<Scalars['String']>;
  addDiningStations: Array<AddMealMenuDiningStationInput>;
  editDiningStations: Array<EditMealMenuDiningStationInput>;
  removeDiningStations: Array<RemoveMealMenuDiningStationInput>;
  addRestaurantMenuLinks: Array<RestaurantMenuLinkInput>;
  editRestaurantMenuLinks: Array<EditRestaurantMenuLinkInput>;
  removeRestaurantMenuLinks: Array<RemoveRestaurantMenuLinkInput>;
};

export type EditMealMenuLogItemInput = {
  mealMenuLogItemId: Scalars['ID'];
  amount: Scalars['Float'];
  options: Array<MealMenuLogItemOptionInput>;
};

export type EditMealMenuLogItemPayload = {
  __typename?: 'EditMealMenuLogItemPayload';
  mealMenuLogItem: MealMenuLogItem;
};

export type EditMealMenuPayload = {
  __typename?: 'EditMealMenuPayload';
  mealMenu: StandaloneMealMenu;
};

export type EditMealMenuPlateInput = {
  mealMenuPlateId: Scalars['ID'];
  parentBulkOrderId: Scalars['ID'];
  addItems: Maybe<Array<AddMealMenuPlateItemInput>>;
  editItems: Maybe<Array<EditMealMenuPlateItemInput>>;
  removePlateItemIds: Array<Scalars['ID']>;
};

export type EditMealMenuPlateItemInput = {
  mealMenuPlateItemId: Scalars['ID'];
  amount: Scalars['Float'];
  editMealMenuPlate: EditMealMenuPlateInput;
  options: Array<MealMenuPlateItemOptionInput>;
  specialRequests: Maybe<Scalars['String']>;
};

export type EditMealMenuWithIdentityInput = {
  mealMenuId: Scalars['ID'];
  teamIds: Maybe<Array<Scalars['ID']>>;
  advancedSelectionInput: Maybe<AdvancedSelectionInput>;
  name: Scalars['String'];
  type: MealType;
  start: Scalars['DateTime'];
  timezone: Scalars['Timezone'];
  durationInMinutes: Scalars['Int'];
  prepTimeInMinutes: Scalars['Int'];
  lastOrderTimeBeforeEndInMinutes: Scalars['Int'];
  notificationSentBeforeOrderDueInMinutes: Maybe<Scalars['Int']>;
  orderRateLimit: Maybe<MenuOrderRateLimitInput>;
  userOrderLimit: Maybe<MealMenuUserOrderLimitInput>;
  isHubCheckInEnabled: Maybe<Scalars['Boolean']>;
  isOrderAndLogRestricted: Maybe<Scalars['Boolean']>;
  availableForOrder: Scalars['Boolean'];
  allowSpecialRequests: Scalars['Boolean'];
  theme: Maybe<Scalars['String']>;
};

export type EditMealMenuWithIdentityPayload = {
  __typename?: 'EditMealMenuWithIdentityPayload';
  mealMenu: StandaloneMealMenu;
};

export type EditMealOptionInput = {
  mealOptionId: Scalars['ID'];
  mealOption: MealOptionInput;
};

export type EditMealOptionPayload = {
  __typename?: 'EditMealOptionPayload';
  mealOption: MealOption;
};

export type EditMealOptionPendingInput = {
  mealOptionPendingId: Scalars['ID'];
  mealOptionPending: MealOptionPendingInput;
};

export type EditMealOptionPendingPayload = {
  __typename?: 'EditMealOptionPendingPayload';
  mealOptionPending: MealOptionPending;
};

export type EditMealPlanCalendarInput = {
  mealPlanId: Scalars['ID'];
  isAutoSuggestionsEnabled: Scalars['Boolean'];
  addIndividualDates: Maybe<Array<Scalars['Date']>>;
  removeIndividualDates: Maybe<Array<Scalars['Date']>>;
  startDate: Maybe<Scalars['Date']>;
  endDate: Maybe<Scalars['Date']>;
  dayOfWeekPriorities: Maybe<Array<DayOfWeekPriorityInput>>;
  removeConflictingIndividualDates: Maybe<Array<Scalars['Date']>>;
  addMealTemplates: Array<AddMealTemplateCalendarInput>;
  editMealTemplates: Array<EditMealTemplateCalendarInput>;
  removeMealTemplateIds: Array<Scalars['ID']>;
  addActivityTemplates: Array<AddActivityTemplateCalendarInput>;
  editActivityTemplates: Array<EditActivityTemplateCalendarInput>;
  removeActivityTemplateIds: Array<Scalars['ID']>;
};

export type EditMealPlanCalendarPayload = {
  __typename?: 'EditMealPlanCalendarPayload';
  mealPlan: MealPlan;
};

export type EditMealPlanDateForAthleteInput = {
  date: Scalars['Date'];
  athleteId: Scalars['ID'];
  timezone: Scalars['Timezone'];
  mealPlanId: Scalars['ID'];
};

export type EditMealPlanDateForAthletePayload = {
  __typename?: 'EditMealPlanDateForAthletePayload';
  mealPlanDate: MealPlanDate;
};

export type EditMealPlanDateInput = {
  date: Scalars['Date'];
  timezone: Scalars['Timezone'];
  mealPlanId: Scalars['ID'];
};

export type EditMealPlanDatePayload = {
  __typename?: 'EditMealPlanDatePayload';
  mealPlanDate: MealPlanDate;
};

export type EditMealPlanExchangeTargetsInput = {
  mealPlanId: Scalars['ID'];
  exchangeTargets: Array<ExchangeAmountInput>;
};

export type EditMealPlanExchangeTargetsPayload = {
  __typename?: 'EditMealPlanExchangeTargetsPayload';
  mealPlan: MealPlan;
};

export type EditMealPlanInput = {
  mealPlanId: Scalars['ID'];
  mealPlan: MealPlanEditFormInput;
  foodPreferences: FoodPreferenceInput;
  exchangeSetId: Maybe<Scalars['ID']>;
};

export type EditMealPlanIsSharedInput = {
  mealPlanId: Scalars['ID'];
  isShared: Scalars['Boolean'];
};

export type EditMealPlanIsSharedPayload = {
  __typename?: 'EditMealPlanIsSharedPayload';
  mealPlan: MealPlan;
};

export type EditMealPlanNewInput = {
  mealPlanId: Scalars['ID'];
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  isShared: Scalars['Boolean'];
  exchangeSetId: Maybe<Scalars['String']>;
  foodPreferences: FoodPreferenceInput;
};

export type EditMealPlanPayload = {
  __typename?: 'EditMealPlanPayload';
  mealPlan: MealPlan;
};

export type EditMealPlanScheduleInput = {
  mealPlanId: Scalars['ID'];
  addMeals: Array<AddMealInput>;
  editMeals: Array<EditMealInput>;
  removeMeals: Array<RemoveMealInput>;
  addActivities: Array<AddActivityInput>;
  editActivities: Array<EditActivityInput>;
  removeActivities: Array<RemoveActivityInput>;
};

export type EditMealPlanSchedulePayload = {
  __typename?: 'EditMealPlanSchedulePayload';
  mealPlan: MealPlan;
};

export type EditMealPlansIsSharedInput = {
  mealPlanIds: Array<Scalars['ID']>;
  isShared: Scalars['Boolean'];
};

export type EditMealPlansIsSharedPayload = {
  __typename?: 'EditMealPlansIsSharedPayload';
  mealPlans: Array<MealPlan>;
};

export type EditMealTemplateCalendarInput = {
  mealTemplateId: Scalars['ID'];
  meal: Maybe<AddMealInput>;
  resetModifications: Scalars['Boolean'];
  dateModifications: Array<MealPlanDateModificationInput>;
  dayOfWeekModifications: Array<MealPlanDayOfWeekModificationInput>;
};

export type EditMenuItemChoiceInput = {
  menuItemChoiceId: Scalars['ID'];
  name: Scalars['String'];
  position: Scalars['Int'];
  required: Scalars['Boolean'];
  maxOptionsCount: Maybe<Scalars['Int']>;
  addOptions: Array<AddMenuItemChoiceOptionInput>;
  editOptions: Array<EditMenuItemChoiceOptionInput>;
  removeOptions: Array<RemoveMenuItemChoiceOptionInput>;
};

export type EditMenuItemChoiceOptionInput = {
  menuItemChoiceOptionId: Scalars['ID'];
  menuItemChoiceOption: AddMenuItemChoiceOptionInput;
};

export type EditMenuItemImageInput = {
  menuItemId: Scalars['ID'];
  imageUrl: Maybe<Scalars['String']>;
};

export type EditMenuItemImagePayload = {
  __typename?: 'EditMenuItemImagePayload';
  menuItem: MenuItem;
};

export type EditMenuItemInput = {
  menuItemId: Scalars['ID'];
  name: Scalars['String'];
  servingName: Scalars['String'];
  description: Maybe<Scalars['String']>;
  appearance: MenuItemAppearanceInput;
  suggestionCategory: Maybe<MenuItemSuggestionCategory>;
  servingAmounts: Array<CreateServingAmountInput>;
  addChoices: Array<AddMenuItemChoiceInput>;
  editChoices: Array<EditMenuItemChoiceInput>;
  removeChoices: Array<RemoveMenuItemChoiceInput>;
  imageUrl: Maybe<Scalars['String']>;
  scoreValue: Maybe<Scalars['Float']>;
  foodCategory: Maybe<FoodCategoryType>;
};

export type EditMenuOrderInput = {
  menuOrderId: Scalars['ID'];
  pickupTime: Scalars['DateTime'];
  items: Maybe<Array<MenuOrderItemInput>>;
  addOrderItems: Maybe<Array<MenuOrderItemInput>>;
  addLogItems: Maybe<Array<MenuOrderItemInput>>;
  editItems: Maybe<Array<EditMenuOrderItemInput>>;
  removeItemIds: Maybe<Array<Scalars['ID']>>;
};

export type EditMenuOrderItemInput = {
  menuOrderItemId: Scalars['ID'];
  forOrder: Scalars['Boolean'];
  amount: Scalars['Float'];
  specialRequests: Maybe<Scalars['String']>;
  options: Array<MenuOrderItemOptionInput>;
};

export type EditMenuOrderMealTemplateInput = {
  menuOrderId: Scalars['ID'];
  mealTemplateId: Scalars['ID'];
};

export type EditMenuOrderMealTemplatePayload = {
  __typename?: 'EditMenuOrderMealTemplatePayload';
  menuOrder: MenuOrder;
};

export type EditMenuOrderPayload = {
  __typename?: 'EditMenuOrderPayload';
  menuOrder: MenuOrder;
};

export type EditMetricAnthropometryEntryInput = {
  id: Scalars['ID'];
  anthropometryEntry: MetricAnthropometryEntryFormInput;
};

export type EditMetricAnthropometryEntryWeighInForDateForAthleteInput = {
  athleteId: Scalars['ID'];
  weightInKg: Scalars['Float'];
  date: Scalars['Date'];
};

export type EditMetricAnthropometryEntryWeighInForDateInput = {
  weightInKg: Scalars['Float'];
  date: Scalars['Date'];
};

export type EditMetricMacroProtocolInput = {
  macroProtocolId: Scalars['String'];
  macroProtocol: MetricMacroProtocolInput;
};

export type EditNoteInput = {
  id: Scalars['ID'];
  note: NoteFormInput;
};

export type EditNotePayload = {
  __typename?: 'EditNotePayload';
  note: Note;
};

export type EditNoteTypeInput = {
  id: Scalars['ID'];
  noteType: NoteTypeFormInput;
};

export type EditNoteTypePayload = {
  __typename?: 'EditNoteTypePayload';
  noteType: NoteType;
};

export type EditOrgAutoShareNewNotemealRecipesInput = {
  autoShareNewNotemealRecipes: Scalars['Boolean'];
  shareCurrent: Scalars['Boolean'];
};

export type EditOrgAutoShareNewNotemealRecipesPayload = {
  __typename?: 'EditOrgAutoShareNewNotemealRecipesPayload';
  org: Org;
};

export type EditOrgFeatureFlagsInput = {
  orgId: Scalars['ID'];
  featureFlags: FeatureFlagsInput;
};

export type EditOrgFeatureFlagsPayload = {
  __typename?: 'EditOrgFeatureFlagsPayload';
  featureFlags: FeatureFlags;
};

export type EditOrgGroupInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  allowsResourceSharing: Scalars['Boolean'];
  allowsAthleteTransfer: Scalars['Boolean'];
  parentOrgGroupId: Maybe<Scalars['ID']>;
  orgIds: Array<Scalars['ID']>;
};

export type EditOrgGroupPayload = {
  __typename?: 'EditOrgGroupPayload';
  orgGroup: OrgGroup;
};

export type EditOrgInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
  allowsPublicAccess: Scalars['Boolean'];
  editMemberships: Array<EditOrgMembershipAsStaffInput>;
  createMemberships: Array<CreateOrgMembershipAsStaffInput>;
  teamworksId: Maybe<Scalars['Int']>;
  integrations: Array<IntegrationInput>;
  agreementFormId: Maybe<Scalars['ID']>;
  scoringSystemId: Maybe<Scalars['ID']>;
  localeCode: Scalars['String'];
  territoryName: Maybe<Scalars['String']>;
};

export type EditOrgMembershipAsStaffInput = {
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  roles: Array<Role>;
  isAdmin: Scalars['Boolean'];
  phoneNumber: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
};

export type EditOrgMembershipInput = {
  id: Scalars['ID'];
  isActive: Scalars['Boolean'];
  roles: Array<Role>;
};

export type EditOrgMembershipSettingsInput = {
  isSubscribedToDigest: Scalars['Boolean'];
};

export type EditOrgMembershipSettingsPayload = {
  __typename?: 'EditOrgMembershipSettingsPayload';
  orgMembership: OrgMembership;
};

export type EditOrgMembershipsAsStaffInput = {
  memberships: Array<EditOrgMembershipAsStaffInput>;
};

export type EditOrgMembershipsInput = {
  memberships: Array<EditOrgMembershipInput>;
};

export type EditOrgMembershipsPayload = {
  __typename?: 'EditOrgMembershipsPayload';
  memberships: Array<OrgMembership>;
};

export type EditOrgPayload = {
  __typename?: 'EditOrgPayload';
  org: Org;
};

export type EditOrgTeamworksProfilesInput = {
  orgId: Scalars['ID'];
  profileSyncRules: Array<TeamworksProfileSyncRuleInput>;
  profileLinks: Array<TeamworksProfileLinkInput>;
  deactivateTeamworksProfileIds: Array<Scalars['Int']>;
  deactivateOrgMembershipIds: Array<Scalars['ID']>;
  notemealOnlyOrgMembershipIds: Array<Scalars['ID']>;
  removeNotemealOnlyOrgMembershipIds: Array<Scalars['ID']>;
  archiveAthleteIds: Array<Scalars['ID']>;
  notemealOnlyAthleteIds: Array<Scalars['ID']>;
  removeNotemealOnlyAthleteIds: Array<Scalars['ID']>;
};

export type EditOrgTeamworksProfilesPayload = {
  __typename?: 'EditOrgTeamworksProfilesPayload';
  org: Org;
  skippedProfiles: Array<EditOrgTeamworksSkippedProfile>;
};

export type EditOrgTeamworksSkippedProfile = {
  __typename?: 'EditOrgTeamworksSkippedProfile';
  teamworksUserId: Scalars['Int'];
  name: Scalars['String'];
  reason: Scalars['String'];
  conflictedUserIds: Array<Scalars['String']>;
};

export type EditPaymentMethodInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type EditPaymentMethodPayload = {
  __typename?: 'EditPaymentMethodPayload';
  paymentMethod: PaymentMethod;
};

export type EditPlannedMenuInput = {
  plannedMenuId: Scalars['ID'];
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  timezone: Scalars['String'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};

export type EditPlannedMenuPayload = {
  __typename?: 'EditPlannedMenuPayload';
  plannedMenu: PlannedMenu;
};

export type EditPrepPlanInput = {
  prepPlanId: Scalars['ID'];
  start: Scalars['Date'];
  end: Scalars['Date'];
};

export type EditPrepPlanMealInput = {
  prepPlanMealId: Scalars['ID'];
  name: Scalars['String'];
  priority: Scalars['Int'];
  prepPlanMealAssignments: Maybe<Array<PrepPlanMealAssignmentInput>>;
  prepPlanMealSlotAssignments: Maybe<Array<PrepPlanMealSlotAssignmentInput>>;
};

export type EditPrepPlanMealPayload = {
  __typename?: 'EditPrepPlanMealPayload';
  prepPlanMeal: PrepPlanMeal;
};

export type EditPrepPlanPayload = {
  __typename?: 'EditPrepPlanPayload';
  prepPlan: PrepPlan;
};

export type EditRecipeFullInput = {
  id: Scalars['ID'];
  recipe: RecipeFullInput;
  createServings: Array<RecipeServingFullInput>;
  editServings: Array<EditRecipeServingFullInput>;
};

export type EditRecipeFullPayload = {
  __typename?: 'EditRecipeFullPayload';
  recipe: Recipe;
};

export type EditRecipeIsSharedInput = {
  id: Scalars['ID'];
  isShared: Scalars['Boolean'];
};

export type EditRecipeIsSharedPayload = {
  __typename?: 'EditRecipeIsSharedPayload';
  recipe: Recipe;
};

export type EditRecipePartialInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  prepTimeInMinutes: Maybe<Scalars['Int']>;
  cookTimeInMinutes: Maybe<Scalars['Int']>;
  steps: Maybe<Array<Scalars['String']>>;
  note: Maybe<Scalars['String']>;
  images: Array<ImageInput>;
  isShared: Maybe<Scalars['Boolean']>;
  mealTypes: Maybe<Array<MealType>>;
  scoreValue: Maybe<Scalars['Float']>;
  foodCategory: Maybe<FoodCategoryType>;
};

export type EditRecipePartialPayload = {
  __typename?: 'EditRecipePartialPayload';
  recipe: Recipe;
};

export type EditRecipeServingFullInput = {
  id: Scalars['ID'];
  serving: RecipeServingFullInput;
};

export type EditRecipeServingInput = {
  servingId: Scalars['ID'];
  serving: RecipeServingFormInput;
};

export type EditRecipeSimpleInput = {
  id: Scalars['ID'];
  recipe: RecipeSimpleInput;
  serving: RecipeServingSimpleInput;
};

export type EditRecipeSimplePayload = {
  __typename?: 'EditRecipeSimplePayload';
  recipe: Recipe;
};

export type EditRestaurantBrandedFoodInput = {
  id: Scalars['ID'];
  food: BrandedFoodFormInput;
  nutrientAmounts: Maybe<Array<FoodNutrientAmountFormInput>>;
  nutrientAmountsManualEntry: Maybe<Array<CreateFoodNutrientAmountFormInput>>;
  restaurantId: Scalars['ID'];
};

export type EditRestaurantBrandedFoodPayload = {
  __typename?: 'EditRestaurantBrandedFoodPayload';
  brandedFood: BrandedFood;
};

export type EditRestaurantInput = {
  restaurantId: Scalars['ID'];
  name: Scalars['String'];
  nutritionixId: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  website: Maybe<Scalars['String']>;
  proteinPortionSizeInOunces: Maybe<Scalars['Int']>;
  firstPartyDelivery: Scalars['Boolean'];
  athleteVisible: Scalars['Boolean'];
  refundPolicy: Maybe<RefundPolicyType>;
  refundDueBeforeInMinutes: Maybe<Scalars['Int']>;
  restaurantTypeId: Maybe<Scalars['ID']>;
  paymentMethodIds: Array<Scalars['ID']>;
  cuisineIds: Array<Scalars['ID']>;
};

export type EditRestaurantLocationContactInput = {
  id: Scalars['ID'];
  restaurantLocationContact: RestaurantLocationContactInput;
};

export type EditRestaurantLocationContactPayload = {
  __typename?: 'EditRestaurantLocationContactPayload';
  restaurantLocationContact: RestaurantLocationContact;
};

export type EditRestaurantLocationInput = {
  restaurantLocationId: Scalars['ID'];
  cityInfo: Maybe<Scalars['String']>;
  notes: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  phoneNumber: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  deliveryLocationIds: Array<Scalars['ID']>;
};

export type EditRestaurantLocationPayload = {
  __typename?: 'EditRestaurantLocationPayload';
  restaurantLocation: RestaurantLocation;
};

export type EditRestaurantMenuInput = {
  restaurantMenuId: Scalars['ID'];
  addSections: Array<AddRestaurantMenuSectionInput>;
  editSections: Array<EditRestaurantMenuSectionInput>;
  removeSections: Array<RemoveRestaurantMenuSectionInput>;
  supportedDietIds: Array<Scalars['ID']>;
};

export type EditRestaurantMenuLinkInput = {
  restaurantMenuLinkId: Scalars['ID'];
  sections: Array<RestaurantMenuSectionLinkInput>;
  addPlates: Array<AddRestaurantMenuLinkPlateInput>;
  editPlates: Array<EditRestaurantMenuLinkPlateInput>;
  removePlateIds: Array<Scalars['ID']>;
  allowCustomOrders: Scalars['Boolean'];
  allowPlateOrders: Scalars['Boolean'];
};

export type EditRestaurantMenuLinkOrderInput = {
  restaurantMenuLinkOrderId: Scalars['ID'];
  addItems: Array<RestaurantMenuLinkOrderItemInput>;
  editItems: Array<EditRestaurantMenuLinkOrderItemInput>;
  removeItemIds: Array<Scalars['ID']>;
};

export type EditRestaurantMenuLinkOrderItemInput = {
  restaurantMenuLinkOrderItemId: Scalars['ID'];
  amount: Scalars['Float'];
  specialRequests: Maybe<Scalars['String']>;
  options: Array<RestaurantMenuLinkOrderItemOptionInput>;
};

export type EditRestaurantMenuLinkOrderMealTemplateInput = {
  restaurantMenuLinkOrderId: Scalars['ID'];
  mealTemplateId: Scalars['ID'];
};

export type EditRestaurantMenuLinkOrderMealTemplatePayload = {
  __typename?: 'EditRestaurantMenuLinkOrderMealTemplatePayload';
  restaurantMenuLinkOrder: RestaurantMenuLinkOrder;
};

export type EditRestaurantMenuLinkOrderPayload = {
  __typename?: 'EditRestaurantMenuLinkOrderPayload';
  restaurantMenuLinkOrder: RestaurantMenuLinkOrder;
};

export type EditRestaurantMenuLinkPayload = {
  __typename?: 'EditRestaurantMenuLinkPayload';
  restaurantMenuLink: RestaurantMenuLink;
};

export type EditRestaurantMenuLinkPlateInput = {
  restaurantMenuLinkPlateId: Scalars['ID'];
  position: Scalars['Int'];
  bulkOrderAmount: Maybe<Scalars['Int']>;
  addItems: Array<AddRestaurantMenuLinkPlateItemInput>;
  editItems: Array<EditRestaurantMenuLinkPlateItemInput>;
  removeItemIds: Array<Scalars['ID']>;
  isDefault: Maybe<Scalars['Boolean']>;
};

export type EditRestaurantMenuLinkPlateItemInput = {
  restaurantMenuLinkPlateItemId: Scalars['ID'];
  position: Scalars['Int'];
  amount: Scalars['Float'];
  options: Array<RestaurantMenuLinkPlateItemOptionInput>;
  specialRequests: Maybe<Scalars['String']>;
};

export type EditRestaurantMenuLinkPlateOrderMealTemplateInput = {
  restaurantMenuLinkPlateOrderId: Scalars['ID'];
  mealTemplateId: Scalars['ID'];
};

export type EditRestaurantMenuLinkPlateOrderMealTemplatePayload = {
  __typename?: 'EditRestaurantMenuLinkPlateOrderMealTemplatePayload';
  restaurantMenuLinkPlateOrder: RestaurantMenuLinkPlateOrder;
};

export type EditRestaurantMenuLogInput = {
  dateTime: Scalars['DateTime'];
  timezone: Scalars['Timezone'];
  restaurantMenuLogId: Scalars['ID'];
  addItems: Array<RestaurantMenuLogItemInput>;
  editItems: Array<EditRestaurantMenuLogItemInput>;
  removeItemIds: Array<Scalars['ID']>;
  type: MealType;
};

export type EditRestaurantMenuLogItemInput = {
  restaurantMenuLogItemId: Scalars['ID'];
  amount: Scalars['Float'];
  options: Array<RestaurantMenuLogItemOptionInput>;
};

export type EditRestaurantMenuLogItemPayload = {
  __typename?: 'EditRestaurantMenuLogItemPayload';
  restaurantMenuLogItem: RestaurantMenuLogItem;
};

export type EditRestaurantMenuLogMealTemplateInput = {
  restaurantMenuLogId: Scalars['ID'];
  mealTemplateId: Scalars['ID'];
};

export type EditRestaurantMenuLogMealTemplatePayload = {
  __typename?: 'EditRestaurantMenuLogMealTemplatePayload';
  restaurantMenuLog: RestaurantMenuLog;
};

export type EditRestaurantMenuLogPayload = {
  __typename?: 'EditRestaurantMenuLogPayload';
  restaurantMenuLog: RestaurantMenuLog;
};

export type EditRestaurantMenuPayload = {
  __typename?: 'EditRestaurantMenuPayload';
  restaurantMenu: RestaurantMenu;
};

export type EditRestaurantMenuSectionInput = {
  restaurantMenuSectionId: Scalars['ID'];
  name: Scalars['String'];
  position: Scalars['Int'];
  createMenuItems: Array<CreateMenuItemInput>;
  addMenuItems: Array<AddMenuItemInput>;
  editMenuItems: Array<EditMenuItemInput>;
  moveMenuItems: Array<MoveMenuItemInput>;
  convertMenuItems: Array<ConvertMenuItemInput>;
  removeMenuItems: Array<RemoveMenuItemInput>;
};

export type EditRestaurantPayload = {
  __typename?: 'EditRestaurantPayload';
  restaurant: Restaurant;
};

export type EditRestaurantTypeInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type EditRestaurantTypePayload = {
  __typename?: 'EditRestaurantTypePayload';
  restaurantType: RestaurantType;
};

export type EditReusableMenuItemInput = {
  after: Scalars['DateTime'];
  menuItemId: Scalars['ID'];
  name: Scalars['String'];
  servingName: Scalars['String'];
  description: Maybe<Scalars['String']>;
  defaultAvailableForOrder: Scalars['Boolean'];
  defaultMaxAmount: Maybe<Scalars['Int']>;
  suggestionCategory: Maybe<MenuItemSuggestionCategory>;
  servingAmounts: Array<CreateServingAmountInput>;
  addChoices: Array<AddMenuItemChoiceInput>;
  editChoices: Array<EditMenuItemChoiceInput>;
  removeChoices: Array<RemoveMenuItemChoiceInput>;
  imageUrl: Maybe<Scalars['String']>;
  defaultAllowSpecialRequests: Scalars['Boolean'];
  scoreValue: Maybe<Scalars['Float']>;
  foodCategory: Maybe<FoodCategoryType>;
};

export type EditReusableMenuItemPartialInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  servingName: Scalars['String'];
  description: Maybe<Scalars['String']>;
  defaultAvailableForOrder: Scalars['Boolean'];
  defaultMaxAmount: Maybe<Scalars['Int']>;
  suggestionCategory: Maybe<MenuItemSuggestionCategory>;
  imageUrl: Maybe<Scalars['String']>;
  defaultAllowSpecialRequests: Scalars['Boolean'];
  scoreValue: Maybe<Scalars['Float']>;
  foodCategory: Maybe<FoodCategoryType>;
};

export type EditReusableMenuItemPartialPayload = {
  __typename?: 'EditReusableMenuItemPartialPayload';
  menuItem: MenuItem;
};

export type EditReusableMenuItemPayload = {
  __typename?: 'EditReusableMenuItemPayload';
  menuItem: MenuItem;
};

export type EditScheduleInput = {
  scheduleId: Scalars['ID'];
  addMeals: Array<AddMealInput>;
  editMeals: Array<EditMealInput>;
  removeMeals: Array<RemoveMealInput>;
  addActivities: Array<AddActivityInput>;
  editActivities: Array<EditActivityInput>;
  removeActivities: Array<RemoveActivityInput>;
  activityFactor: Maybe<Scalars['Float']>;
  name: Maybe<Scalars['String']>;
};

export type EditSchedulePayload = {
  __typename?: 'EditSchedulePayload';
  schedule: Schedule;
};

export type EditSmartTagInput = {
  id: Scalars['ID'];
  tagInput: CreateSmartTagInput;
};

export type EditSyncedAthleteAndAnthroInput = {
  athleteId: Scalars['ID'];
  athlete: SyncedAthleteFormInput;
  anthropometryEntry: Maybe<AnthropometryEntryFormInput>;
};

export type EditSyncedAthleteAndMetricAnthroInput = {
  athleteId: Scalars['ID'];
  athlete: SyncedAthleteFormInput;
  anthropometryEntry: Maybe<MetricAnthropometryEntryFormInput>;
};

export type EditSyncedAthleteInput = {
  athleteId: Scalars['ID'];
  athlete: SyncedAthleteFormInput;
};

export type EditTeamDisplaySettingsInput = {
  teamId: Scalars['ID'];
  macroDisplaySettings: MacroMealPlanDisplaySettingsInput;
  exchangeDisplaySettings: ExchangeMealPlanDisplaySettingsInput;
  noTargetsDisplaySettings: NoTargetsDisplaySettingsInput;
  hideAnthropometry: Scalars['Boolean'];
};

export type EditTeamDisplaySettingsPayload = {
  __typename?: 'EditTeamDisplaySettingsPayload';
  team: Team;
};

export type EditTeamFoodLogNotificationSettingsInput = {
  teamId: Scalars['ID'];
  settings: Maybe<FoodLogNotificationSettingsInput>;
};

export type EditTeamFoodLogNotificationSettingsPayload = {
  __typename?: 'EditTeamFoodLogNotificationSettingsPayload';
  team: Team;
};

export type EditTeamForStaffInput = {
  id: Scalars['ID'];
  orgId: Scalars['ID'];
  team: CreateTeamInput;
};

export type EditTeamInput = {
  id: Scalars['ID'];
  team: CreateTeamInput;
};

export type EditTeamLabelInput = {
  teamLabelId: Scalars['ID'];
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type EditTeamLabelPayload = {
  __typename?: 'EditTeamLabelPayload';
  teamLabel: TeamLabel;
};

export type EditTeamPayload = {
  __typename?: 'EditTeamPayload';
  team: Team;
};

export type EditTerritoryInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  orgIds: Array<Scalars['ID']>;
  removeOrgIds: Array<Scalars['ID']>;
};

export type EditTimelineMealDetailsInput = {
  timelineMealId: Scalars['ID'];
  name: Scalars['String'];
  start: Scalars['DateTime'];
  timezone: Scalars['Timezone'];
  durationInMinutes: Scalars['Int'];
  type: MealType;
};

export type EditTimelineMealDetailsPayload = {
  __typename?: 'EditTimelineMealDetailsPayload';
  timelineMeal: TimelineMeal;
};

export type EditTimelineMealMealTemplateInput = {
  timelineMealId: Scalars['ID'];
  mealTemplateId: Scalars['ID'];
};

export type EditTimelineMealMealTemplatePayload = {
  __typename?: 'EditTimelineMealMealTemplatePayload';
  timelineMeal: TimelineMeal;
};

export type EditTimelineMealServingAmountInput = {
  timelineMealId: Scalars['ID'];
  removeServingAmountId: Scalars['ID'];
  servingAmount: CreateServingAmountInput;
};

export type EditTimelineMealServingAmountPayload = {
  __typename?: 'EditTimelineMealServingAmountPayload';
  timelineMeal: TimelineMeal;
};

export type EditUserBrandedFoodFormInput = {
  id: Scalars['ID'];
  input: CreateUserBrandedFoodFormInput;
};

export type EditUserBrandedFoodPayload = {
  __typename?: 'EditUserBrandedFoodPayload';
  brandedFood: BrandedFood;
};

export type EditUserTeamworksIdToOverwriteInput = {
  userIds: Array<Scalars['ID']>;
  teamworksIdToOverwrite: Scalars['Int'];
};

export type EditUserTeamworksIdToOverwritePayload = {
  __typename?: 'EditUserTeamworksIdToOverwritePayload';
  userIds: Array<Scalars['ID']>;
};

export type EmailMealPlanToAthletePayload = {
  __typename?: 'EmailMealPlanToAthletePayload';
  result: Scalars['Boolean'];
};

export type Event = {
  __typename?: 'Event';
  datetime: Scalars['DateTime'];
  type: Scalars['String'];
};

export type Exchange = {
  __typename?: 'Exchange';
  id: Scalars['ID'];
  name: Scalars['String'];
  shortName: Scalars['String'];
  type: ExchangeType;
  cho: Scalars['Float'];
  pro: Scalars['Float'];
  fat: Scalars['Float'];
  kcal: Scalars['Float'];
  exchangeServingList: ExchangeServingList;
};

export type ExchangeAmount = {
  __typename?: 'ExchangeAmount';
  id: Scalars['ID'];
  amount: Scalars['Float'];
  pickListServingIds: Maybe<Array<Scalars['ID']>>;
  exchange: Exchange;
};

export type ExchangeAmountInput = {
  exchangeId: Scalars['ID'];
  amount: Scalars['Float'];
  pickListServingIds: Maybe<Array<Scalars['ID']>>;
};

export type ExchangeAmountTemplate = {
  __typename?: 'ExchangeAmountTemplate';
  id: Scalars['ID'];
  pickListServingIds: Maybe<Array<Scalars['ID']>>;
  exchange: Exchange;
};

export type ExchangeInput = {
  name: Scalars['String'];
  shortName: Scalars['String'];
  type: ExchangeType;
  cho: Scalars['Float'];
  pro: Scalars['Float'];
  fat: Scalars['Float'];
};

export type ExchangeMealPlanDisplaySettings = {
  __typename?: 'ExchangeMealPlanDisplaySettings';
  dailyProgressViewsForExchanges: ExchangeProgressViews;
  mealProgressViewsForExchanges: ExchangeProgressViews;
  dailyProgressViewsForMacros: MacroProgressViews;
  mealProgressViewsForMacros: MacroProgressViews;
};

export type ExchangeMealPlanDisplaySettingsInput = {
  dailyProgressViewsForExchanges: ExchangeProgressViews;
  mealProgressViewsForExchanges: ExchangeProgressViews;
  dailyProgressViewsForMacros: MacroProgressViews;
  mealProgressViewsForMacros: MacroProgressViews;
};

export type ExchangeMealPlanTemplate = MealPlanTemplate & {
  __typename?: 'ExchangeMealPlanTemplate';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  owner: User;
  /** @deprecated Field no longer supported */
  orgId: Maybe<Scalars['ID']>;
  /** @deprecated Field no longer supported */
  orgGroupId: Maybe<Scalars['ID']>;
  teams: Maybe<Array<Team>>;
  advancedSelection: Maybe<AdvancedSelectionType>;
  hasAdvancedSelection: Scalars['Boolean'];
  isShared: Scalars['Boolean'];
  copiedCount: Scalars['Int'];
  avoidedFoodGroups: Array<FoodGroup>;
  promotedFoodGroups: Array<FoodGroup>;
  avoidedFoods: Array<Food>;
  promotedFoods: Array<Food>;
  macroProtocol: MealPlanTemplateMacroProtocol;
  meals: Array<ExchangeMealPlanTemplateMeal>;
  activities: Array<MealPlanTemplateActivity>;
  exchangeTargets: Maybe<Array<ExchangeAmountTemplate>>;
  exchangeSet: Maybe<ExchangeSet>;
  pendingState: Maybe<Scalars['String']>;
  additionalState: Maybe<Scalars['String']>;
};

export type ExchangeMealPlanTemplateMeal = MealPlanTemplateMeal & {
  __typename?: 'ExchangeMealPlanTemplateMeal';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: MealType;
  start: Scalars['Time'];
  end: Scalars['Time'];
  mealOptions: Array<MealPlanTemplateMealOption>;
  exchangeTargets: Array<ExchangeAmount>;
};

export type ExchangeMealTemplate = MealTemplate & {
  __typename?: 'ExchangeMealTemplate';
  id: Scalars['ID'];
  meal: Meal;
  mealOptionCount: Scalars['Int'];
  mealOptions: Array<MealOption>;
  pendingMealOptions: Array<MealOptionPending>;
  hasDateModifications: Scalars['Boolean'];
  exchangeTargets: Array<ExchangeAmount>;
  mealPlan: MealPlan;
};


export type ExchangeMealTemplateHasDateModificationsArgs = {
  after: Scalars['Date'];
};

export enum ExchangeProgressViews {
  all = 'all',
  none = 'none'
}

export type ExchangeServingList = {
  __typename?: 'ExchangeServingList';
  id: Scalars['ID'];
  name: Scalars['String'];
  servingAmounts: Array<ServingAmount>;
};

export type ExchangeServingListInput = {
  name: Scalars['String'];
  servingAmounts: Array<CreateServingAmountInput>;
};

export type ExchangeSet = {
  __typename?: 'ExchangeSet';
  id: Scalars['ID'];
  name: Scalars['String'];
  exchanges: Array<Exchange>;
  createdBy: Maybe<User>;
};

export type ExchangeSetOffsetConnection = {
  __typename?: 'ExchangeSetOffsetConnection';
  edges: Array<ExchangeSet>;
  pageInfo: OffsetPageInfo;
};

export enum ExchangeType {
  starch = 'starch',
  fruit = 'fruit',
  vegetable = 'vegetable',
  protein = 'protein',
  dairy = 'dairy',
  fat = 'fat'
}

export type ExternalEvent = {
  __typename?: 'ExternalEvent';
  id: Scalars['String'];
  name: Scalars['String'];
  timezone: Scalars['Timezone'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  isAllDay: Scalars['Boolean'];
  eventType: Scalars['String'];
  location: Maybe<Scalars['String']>;
};

export enum FeatureFlag {
  dietitianMenuSuggestions = 'dietitianMenuSuggestions',
  athleteMenuSuggestions = 'athleteMenuSuggestions'
}

export type FeatureFlags = {
  __typename?: 'FeatureFlags';
  athleteMenuSuggestions: Scalars['Boolean'];
  clientCredentials: Scalars['Boolean'];
  hasRestaurantMenus: Scalars['Boolean'];
  restaurantLogging: Scalars['Boolean'];
  foodCategory: Scalars['Boolean'];
  orgGroupRecipes: Scalars['Boolean'];
  orgGroupFoods: Scalars['Boolean'];
  performanceKitchen: Scalars['Boolean'];
  printableTags: Scalars['Boolean'];
  hubMenuCheckIn: Scalars['Boolean'];
  dietitianOnlyMenus: Scalars['Boolean'];
  bulkOrdering: Scalars['Boolean'];
  ukMealPlanOptionSuggestions: Scalars['Boolean'];
  digitalDisplays: Scalars['Boolean'];
  selfServiceOnboarding: Scalars['Boolean'];
  messaging: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  recipeScaling: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  selfServiceActivationLink: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  goalTracker: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  pk2MenuBuilder: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  aiRecipes: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  aiTranscriber: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  rebranding: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  teamworksSchedules: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  mealPlanSuggestions: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  mealPlanTemplates: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  tags: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  multipleMenuOrders: Scalars['Boolean'];
  /** @deprecated Field no longer supported */
  prepPlan: Scalars['Boolean'];
};

export type FeatureFlagsInput = {
  athleteMenuSuggestions: Maybe<Scalars['Boolean']>;
  clientCredentials: Maybe<Scalars['Boolean']>;
  hasRestaurantMenus: Maybe<Scalars['Boolean']>;
  restaurantLogging: Maybe<Scalars['Boolean']>;
  foodCategory: Maybe<Scalars['Boolean']>;
  orgGroupRecipes: Maybe<Scalars['Boolean']>;
  orgGroupFoods: Maybe<Scalars['Boolean']>;
  performanceKitchen: Maybe<Scalars['Boolean']>;
  printableTags: Maybe<Scalars['Boolean']>;
  hubMenuCheckIn: Maybe<Scalars['Boolean']>;
  dietitianOnlyMenus: Maybe<Scalars['Boolean']>;
  bulkOrdering: Maybe<Scalars['Boolean']>;
  ukMealPlanOptionSuggestions: Maybe<Scalars['Boolean']>;
  digitalDisplays: Maybe<Scalars['Boolean']>;
  selfServiceOnboarding: Maybe<Scalars['Boolean']>;
  messaging: Maybe<Scalars['Boolean']>;
};

export type FinalizePrepPlanInput = {
  prepPlanId: Scalars['ID'];
};

export type FinalizePrepPlanPayload = {
  __typename?: 'FinalizePrepPlanPayload';
  prepPlan: PrepPlan;
};

export type Food = {
  id: Scalars['ID'];
  name: Scalars['String'];
  choPer100g: Scalars['Float'];
  proPer100g: Scalars['Float'];
  fatPer100g: Scalars['Float'];
  kcalPer100g: Scalars['Float'];
  exchangeTypes: Maybe<Array<ExchangeType>>;
  nixItemId: Maybe<Scalars['String']>;
  nixTagId: Maybe<Scalars['String']>;
  usdaFdcId: Maybe<Scalars['Int']>;
  usdaFdcDescription: Maybe<Scalars['String']>;
  usdaFdcFoodCategoryId: Maybe<Scalars['Int']>;
  usdaFdcDataType: Maybe<Scalars['String']>;
  isRecipeIngredientOnly: Scalars['Boolean'];
  excludeFromSuggestions: Scalars['Boolean'];
  thumbnailUrl: Maybe<Scalars['String']>;
  hasFullAccess: Scalars['Boolean'];
  org: Maybe<Org>;
  groceryListCategory: Maybe<GroceryListCategory>;
  servings: Array<Serving>;
  defaultServing: Serving;
  foodGroups: Array<FoodGroup>;
  source: FoodDataSource;
  locales: Maybe<Array<Scalars['String']>>;
  nutrientAmounts: Array<NutrientAmount>;
  createdById: Maybe<Scalars['ID']>;
  createdBy: Maybe<User>;
  updatedAt: Scalars['DateTime'];
  showGramServing: Scalars['Boolean'];
  showOunceServing: Scalars['Boolean'];
};

export enum FoodCategoryType {
  carbohydrate = 'carbohydrate',
  protein = 'protein',
  fats = 'fats',
  combination = 'combination'
}

export type FoodCursorConnection = {
  __typename?: 'FoodCursorConnection';
  edges: Array<Food>;
  pageInfo: CursorPageInfo;
};

export enum FoodDataSource {
  manual = 'manual',
  usda = 'usda',
  nutritionix = 'nutritionix',
  bonAppetit = 'bonAppetit',
  compass = 'compass',
  sodexo = 'sodexo',
  cbord = 'cbord',
  restaurant = 'restaurant',
  mccance = 'mccance'
}

export type FoodGroup = {
  __typename?: 'FoodGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  source: Maybe<Scalars['String']>;
  foodsCount: Scalars['Int'];
  foods: Array<Food>;
};

export type FoodGroupsCursorConnection = {
  __typename?: 'FoodGroupsCursorConnection';
  edges: Array<FoodGroup>;
  pageInfo: CursorPageInfo;
};

export type FoodGroupsOffsetConnection = {
  __typename?: 'FoodGroupsOffsetConnection';
  edges: Array<FoodGroup>;
  pageInfo: OffsetPageInfo;
};

export type FoodLogActivity = {
  __typename?: 'FoodLogActivity';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: ActivityType;
  date: Scalars['Date'];
  startTime: Scalars['Time'];
  endTime: Scalars['Time'];
  timezone: Scalars['Timezone'];
  rpe: Maybe<Scalars['Int']>;
  activityTemplate: Maybe<ActivityTemplate>;
};

export type FoodLogMeal = {
  __typename?: 'FoodLogMeal';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: MealType;
  date: Scalars['Date'];
  startTime: Scalars['Time'];
  endTime: Scalars['Time'];
  timezone: Scalars['Timezone'];
  images: Array<Image>;
  athlete: Maybe<Athlete>;
  mealTemplate: Maybe<MealTemplate>;
  servingAmounts: Array<ServingAmount>;
  comments: Array<Comment>;
  notifications: Array<Notification>;
};

export type FoodLogMealCommentNotification = Notification & {
  __typename?: 'FoodLogMealCommentNotification';
  id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  type: Scalars['String'];
  createdAt: Scalars['DateTime'];
  viewedAt: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  message: Scalars['String'];
  foodLogMeal: Maybe<FoodLogMeal>;
  timelineMeal: Maybe<TimelineMeal>;
};

export type FoodLogMealEditNotification = Notification & {
  __typename?: 'FoodLogMealEditNotification';
  id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  type: Scalars['String'];
  createdAt: Scalars['DateTime'];
  viewedAt: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  message: Scalars['String'];
  foodLogMeal: Maybe<FoodLogMeal>;
  timelineMeal: Maybe<TimelineMeal>;
};

export type FoodLogMealImageNotification = Notification & {
  __typename?: 'FoodLogMealImageNotification';
  id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  type: Scalars['String'];
  createdAt: Scalars['DateTime'];
  viewedAt: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  message: Scalars['String'];
  foodLogMeal: Maybe<FoodLogMeal>;
  timelineMeal: Maybe<TimelineMeal>;
  image: Maybe<Image>;
};

export type FoodLogMealPublication = {
  __typename?: 'FoodLogMealPublication';
  payload: FoodLogMealPublicationPayload;
};

export type FoodLogMealPublicationPayload = AddFoodLogMealPayload | EditFoodLogMealTimePayload | RemoveFoodLogMealPayload | EditFoodLogMealServingAmountsPayload | EditFoodLogMealImagePayload | AddFoodLogMealCommentPayload | AddFoodLogMealImagePayload;

export type FoodLogMealSubscriberScope = {
  foodLogMealId: Maybe<Scalars['ID']>;
};

export type FoodLogMealTemplateInput = {
  timelineMealIds: Array<Scalars['ID']>;
  meal: AddMealInput;
};

export type FoodLogNotificationSettings = {
  __typename?: 'FoodLogNotificationSettings';
  edits: Scalars['Boolean'];
  imageUploads: Scalars['Boolean'];
  messages: Scalars['Boolean'];
};

export type FoodLogNotificationSettingsInput = {
  edits: Scalars['Boolean'];
  imageUploads: Scalars['Boolean'];
  messages: Scalars['Boolean'];
};

export type FoodNutrientAmountFormInput = {
  fdcNutrientId: Scalars['Int'];
  amount: Scalars['Float'];
  footnote: Maybe<Scalars['String']>;
  dataPoints: Maybe<Scalars['Int']>;
  min: Maybe<Scalars['Float']>;
  max: Maybe<Scalars['Float']>;
  median: Maybe<Scalars['Float']>;
};

export type FoodOffsetConnection = {
  __typename?: 'FoodOffsetConnection';
  edges: Array<Food>;
  pageInfo: OffsetPageInfo;
};

export type FoodOrRecipe = BrandedFood | GenericFood | Recipe;

export type FoodPreferenceInput = {
  avoidedFoodGroupIds: Array<Scalars['ID']>;
  promotedFoodGroupIds: Array<Scalars['ID']>;
  avoidedFoodIds: Array<Scalars['ID']>;
  promotedFoodIds: Array<Scalars['ID']>;
};

export type FoodServingInput = {
  units: ServingUnitsInput;
  weight: Scalars['Float'];
  isDefault: Scalars['Boolean'];
  isRecipeServingOnly: Scalars['Boolean'];
  defaultAmount: Scalars['Float'];
  usdaWeightSeq: Maybe<Scalars['String']>;
};

export type ForceLockPlannedMenuInput = {
  plannedMenuId: Scalars['ID'];
};

export type ForceLockPlannedMenuPayload = {
  __typename?: 'ForceLockPlannedMenuPayload';
  plannedMenuId: Scalars['ID'];
};

export type GptRecipeByNameInput = {
  name: Scalars['String'];
  servingYield: Scalars['Float'];
};

export type GptRecipeFromImageInput = {
  presignedGetImageUrl: Scalars['String'];
};

export type GptRecipeFromUrlInput = {
  url: Scalars['String'];
};

export type GptRecipePayload = {
  __typename?: 'GPTRecipePayload';
  name: Scalars['String'];
  servingYield: Maybe<GptServingYield>;
  steps: Array<Scalars['String']>;
  ingredients: Array<GptServingAmount>;
  unmatchedIngredients: UnmatchedIngredients;
  aiType: AiType;
};

export type GptServingAmount = {
  __typename?: 'GPTServingAmount';
  serving: Serving;
  position: Scalars['Int'];
  amount: Scalars['Float'];
};

export type GptServingYield = {
  __typename?: 'GPTServingYield';
  amount: Scalars['Float'];
  name: Scalars['String'];
};

export type GenericFood = Food & {
  __typename?: 'GenericFood';
  id: Scalars['ID'];
  name: Scalars['String'];
  choPer100g: Scalars['Float'];
  proPer100g: Scalars['Float'];
  fatPer100g: Scalars['Float'];
  kcalPer100g: Scalars['Float'];
  exchangeTypes: Maybe<Array<ExchangeType>>;
  nixItemId: Maybe<Scalars['String']>;
  nixTagId: Maybe<Scalars['String']>;
  usdaFdcDescription: Maybe<Scalars['String']>;
  usdaFdcId: Maybe<Scalars['Int']>;
  usdaFdcFoodCategoryId: Maybe<Scalars['Int']>;
  usdaFdcDataType: Maybe<Scalars['String']>;
  isRecipeIngredientOnly: Scalars['Boolean'];
  excludeFromSuggestions: Scalars['Boolean'];
  thumbnailUrl: Maybe<Scalars['String']>;
  hasFullAccess: Scalars['Boolean'];
  org: Maybe<Org>;
  orgGroup: Maybe<OrgGroup>;
  groceryListCategory: Maybe<GroceryListCategory>;
  servings: Array<Serving>;
  defaultServing: Serving;
  foodGroups: Array<FoodGroup>;
  nutrientAmounts: Array<NutrientAmount>;
  /** @deprecated Field no longer supported */
  brandedFoods: Array<BrandedFood>;
  createdById: Maybe<Scalars['ID']>;
  createdBy: Maybe<User>;
  owner: Maybe<User>;
  updatedAt: Scalars['DateTime'];
  source: FoodDataSource;
  locales: Maybe<Array<Scalars['String']>>;
  showGramServing: Scalars['Boolean'];
  showOunceServing: Scalars['Boolean'];
};

export type GenericFoodFormInput = {
  name: Scalars['String'];
  thumbnailUrl: Maybe<Scalars['String']>;
  choPer100g: Scalars['Float'];
  proPer100g: Scalars['Float'];
  fatPer100g: Scalars['Float'];
  exchangeTypes: Array<ExchangeType>;
  isRecipeIngredientOnly: Scalars['Boolean'];
  excludeFromSuggestions: Scalars['Boolean'];
  groceryListCategoryId: Maybe<Scalars['String']>;
  source: FoodDataSource;
  locales: Maybe<Array<Scalars['String']>>;
  foodGroupIds: Maybe<Array<Scalars['ID']>>;
  usdaFdcDescription: Maybe<Scalars['String']>;
  usdaFdcId: Maybe<Scalars['Int']>;
  usdaFdcFoodCategoryId: Maybe<Scalars['Int']>;
  usdaFdcDataType: Maybe<Scalars['String']>;
  mccanceId: Maybe<Scalars['String']>;
  mccanceName: Maybe<Scalars['String']>;
};

export type Goal = {
  __typename?: 'Goal';
  id: Scalars['ID'];
  kcalOffset: Scalars['Int'];
  start: Scalars['Date'];
  end: Maybe<Scalars['Date']>;
  type: GoalType;
};

export type GoalCategory = {
  __typename?: 'GoalCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  defaultEmoji: Scalars['String'];
};

export type GoalCompletionSummary = {
  __typename?: 'GoalCompletionSummary';
  goalCompletedDayCount: Scalars['Int'];
  totalGoalDayCount: Scalars['Int'];
};

export type GoalConfiguration = {
  __typename?: 'GoalConfiguration';
  id: Scalars['ID'];
  name: Scalars['String'];
  emoji: Scalars['String'];
  category: GoalCategory;
  isArchived: Scalars['Boolean'];
  timezone: Scalars['String'];
  reminders: Array<GoalReminderConfiguration>;
  startDate: Maybe<Scalars['Date']>;
  endDate: Maybe<Scalars['Date']>;
  dayOfWeekAssignments: Array<DayOfWeek>;
  individualDates: Array<Scalars['Date']>;
  target: Maybe<GoalQuantity>;
  athlete: Maybe<Athlete>;
  goals: Array<GoalTrackerGoal>;
  completedGoalCount: Scalars['Int'];
  totalGoalCount: Scalars['Int'];
};


export type GoalConfigurationCompletedGoalCountArgs = {
  startDate: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
};


export type GoalConfigurationTotalGoalCountArgs = {
  startDate: Maybe<Scalars['String']>;
  endDate: Scalars['String'];
};

export type GoalConfigurationHistory = {
  __typename?: 'GoalConfigurationHistory';
  id: Scalars['ID'];
  current: GoalConfiguration;
  all: Array<GoalConfiguration>;
};

export type GoalConfigurationOffsetConnection = {
  __typename?: 'GoalConfigurationOffsetConnection';
  edges: Array<GoalConfiguration>;
  pageInfo: OffsetPageInfo;
};

export type GoalDayOfWeekAssignment = {
  __typename?: 'GoalDayOfWeekAssignment';
  id: Scalars['ID'];
  dayOfWeek: DayOfWeek;
};

export type GoalFormInput = {
  goalTypeId: Scalars['ID'];
  kcalOffset: Scalars['Int'];
  start: Scalars['Date'];
  end: Maybe<Scalars['Date']>;
};

export type GoalQuantity = {
  __typename?: 'GoalQuantity';
  id: Scalars['ID'];
  value: Scalars['Int'];
  unit: Scalars['String'];
};

export type GoalReminderConfiguration = {
  __typename?: 'GoalReminderConfiguration';
  id: Scalars['ID'];
  time: Scalars['Time'];
  message: Scalars['String'];
};

export type GoalReminderNotification = Notification & {
  __typename?: 'GoalReminderNotification';
  createdAt: Scalars['DateTime'];
  id: Scalars['ID'];
  message: Scalars['String'];
  title: Scalars['String'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  type: Scalars['String'];
  viewedAt: Maybe<Scalars['DateTime']>;
  goalTrackerGoal: Maybe<GoalTrackerGoal>;
};

export type GoalTrackerGoal = {
  __typename?: 'GoalTrackerGoal';
  id: Scalars['ID'];
  goalConfiguration: GoalConfiguration;
  date: Scalars['Date'];
  completedAtInUTC: Maybe<Scalars['String']>;
  athlete: Athlete;
  value: Maybe<Scalars['Int']>;
};

export type GoalTrackerGoalOffsetConnection = {
  __typename?: 'GoalTrackerGoalOffsetConnection';
  edges: Array<GoalTrackerGoal>;
  pageInfo: OffsetPageInfo;
};

export type GoalType = {
  __typename?: 'GoalType';
  id: Scalars['ID'];
  name: Scalars['String'];
  defaultKcalOffset: Scalars['Int'];
};

export type GroceryList = {
  __typename?: 'GroceryList';
  id: Scalars['ID'];
  prepPlan: PrepPlan;
  notes: Maybe<Scalars['String']>;
  foodItems: Array<GroceryListFoodItem>;
  customItems: Array<GroceryListCustomItem>;
};

export type GroceryListCategory = {
  __typename?: 'GroceryListCategory';
  id: Scalars['ID'];
  position: Scalars['Int'];
  name: Scalars['String'];
};

export type GroceryListCustomItem = {
  __typename?: 'GroceryListCustomItem';
  id: Scalars['ID'];
  name: Scalars['String'];
  groceryList: GroceryList;
  checked: Scalars['Boolean'];
};

export type GroceryListFoodItem = {
  __typename?: 'GroceryListFoodItem';
  id: Scalars['ID'];
  checked: Scalars['Boolean'];
  serving: Serving;
  amount: Scalars['Float'];
  adjustment: Scalars['Float'];
  groceryList: GroceryList;
  groceryListCategory: Maybe<GroceryListCategory>;
};

export type Image = {
  __typename?: 'Image';
  id: Scalars['ID'];
  url: Scalars['String'];
  position: Scalars['Int'];
};

export type ImageInput = {
  url: Scalars['String'];
  position: Scalars['Int'];
};

export type ImportBonAppetitMenusInput = {
  cafeId: Scalars['String'];
  menus: Array<BonAppetitMenuInput>;
  options: ImportMenuOptionsInput;
};

export type ImportBonAppetitMenusPayload = {
  __typename?: 'ImportBonAppetitMenusPayload';
  mealMenus: Array<MealMenu>;
  skippedMealMenus: Array<MealMenu>;
};

export type ImportCbordMenusInput = {
  serviceUnitOid: Scalars['Int'];
  facilityOid: Maybe<Scalars['Int']>;
  menuGroups: Array<CbordMenuGroupInput>;
  options: ImportMenuOptionsInput;
};

export type ImportCbordMenusPayload = {
  __typename?: 'ImportCbordMenusPayload';
  mealMenus: Array<MealMenu>;
  skippedMealMenus: Array<MealMenu>;
};

export type ImportCompassMenusInput = {
  locationId: Scalars['Int'];
  menus: Array<CompassMenuInput>;
  options: ImportMenuOptionsInput;
};

export type ImportCompassMenusPayload = {
  __typename?: 'ImportCompassMenusPayload';
  mealMenus: Array<MealMenu>;
  skippedMealMenus: Array<MealMenu>;
};

export type ImportCompassRecipeInput = {
  masterRefNumber: Scalars['String'];
};

export type ImportCompassRecipeInputPayload = {
  __typename?: 'ImportCompassRecipeInputPayload';
  recipe: Recipe;
};

export type ImportDiningStationInput = {
  name: Scalars['String'];
  position: Scalars['Int'];
  menuItems: Array<ImportMenuItemInput>;
};

export type ImportMealMenuInput = {
  name: Scalars['String'];
  timezone: Scalars['Timezone'];
  startDateTime: Scalars['DateTime'];
  durationInMinutes: Scalars['Int'];
  type: MealType;
  lastOrderTimeBeforeEndInMinutes: Scalars['Int'];
  prepTimeInMinutes: Scalars['Int'];
  diningStations: Array<ImportDiningStationInput>;
  notificationSentBeforeOrderDueInMinutes: Maybe<Scalars['Int']>;
  orderRateLimit: Maybe<MenuOrderRateLimitInput>;
  userOrderLimit: Maybe<MealMenuUserOrderLimitInput>;
  theme: Maybe<Scalars['String']>;
  isHubCheckInEnabled: Maybe<Scalars['Boolean']>;
};

export type ImportMenuItemInput = {
  menuItemId: Scalars['ID'];
  position: Scalars['Int'];
  availableForOrder: Scalars['Boolean'];
  maxAmount: Maybe<Scalars['Int']>;
  allowSpecialRequests: Scalars['Boolean'];
};

export type ImportMenuOptionsInput = {
  menuTimes: Array<ImportMenuTimeInput>;
  timezone: Scalars['Timezone'];
  teamIds: Maybe<Array<Scalars['ID']>>;
  advancedSelectionInput: Maybe<AdvancedSelectionInput>;
  prepTimeInMinutes: Scalars['Int'];
  lastOrderTimeBeforeEndInMinutes: Scalars['Int'];
  notificationSentBeforeOrderDueInMinutes: Maybe<Scalars['Int']>;
  orderRateLimit: Maybe<MenuOrderRateLimitInput>;
  availableForOrder: Scalars['Boolean'];
  allowSpecialRequests: Scalars['Boolean'];
  overrideIdentity: Scalars['Boolean'];
  userOrderLimit: Maybe<MealMenuUserOrderLimitInput>;
  isHubCheckInEnabled: Maybe<Scalars['Boolean']>;
  isOrderAndLogRestricted: Maybe<Scalars['Boolean']>;
  theme: Maybe<Scalars['String']>;
};

export type ImportMenuTimeInput = {
  menuName: Scalars['String'];
  mealType: MealType;
  startTime: Scalars['Time'];
  durationInMinutes: Scalars['Int'];
};

export type ImportPlannedMenuInput = {
  teamIds: Maybe<Array<Scalars['ID']>>;
  advancedSelectionInput: Maybe<AdvancedSelectionInput>;
  meals: Array<ImportMealMenuInput>;
};

export type ImportPlannedMenuPayload = {
  __typename?: 'ImportPlannedMenuPayload';
  mealMenus: Array<StandaloneMealMenu>;
};

export type ImportSodexoMenusInput = {
  locationId: Scalars['String'];
  menuSourceKey: Scalars['String'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  mealTimes: Array<SodexoMealTimeInput>;
  options: ImportMenuOptionsInput;
};

export type ImportSodexoMenusPayload = {
  __typename?: 'ImportSodexoMenusPayload';
  mealMenus: Array<MealMenu>;
  skippedMealMenus: Array<MealMenu>;
};

export type Integration = {
  __typename?: 'Integration';
  type: IntegrationType;
  externalOrgId: Scalars['String'];
};

export type IntegrationInput = {
  externalOrgId: Scalars['String'];
  type: IntegrationType;
};

export enum IntegrationType {
  compass = 'compass',
  cbord = 'cbord',
  bonAppetit = 'bonAppetit',
  sodexo = 'sodexo'
}

export type IsTagNameUniquePayload = {
  __typename?: 'IsTagNameUniquePayload';
  isUnique: Scalars['Boolean'];
};

export type LinkTeamworksOrgInput = {
  id: Scalars['ID'];
  teamworksId: Scalars['Int'];
  name: Scalars['String'];
};

export type LinkTeamworksOrgPayload = {
  __typename?: 'LinkTeamworksOrgPayload';
  org: Org;
};

export type LinkTeamworksPositionInput = {
  teamworksId: Scalars['Int'];
  teamworksTeamId: Scalars['Int'];
  positionId: Scalars['ID'];
};

export type LinkTeamworksTeamInput = {
  id: Scalars['ID'];
  teamworksId: Scalars['Int'];
  name: Scalars['String'];
  gender: Maybe<Scalars['String']>;
  linkedSport: Maybe<LinkedSportInput>;
  newSport: Maybe<CreateTeamworksSportInput>;
};

export type LinkedSportInput = {
  sportId: Scalars['ID'];
  newPositions: Array<CreateTeamworksPositionInput>;
  linkedPositions: Array<LinkTeamworksPositionInput>;
};

export type Locale = {
  __typename?: 'Locale';
  id: Scalars['ID'];
  code: Scalars['String'];
};

export type LockPlannedMenuInput = {
  plannedMenuId: Scalars['ID'];
};

export type LockPlannedMenuPayload = {
  __typename?: 'LockPlannedMenuPayload';
  lockAcquired: Scalars['Boolean'];
  plannedMenu: PlannedMenu;
};

export type MacroMath = {
  __typename?: 'MacroMath';
  gPerKg: Scalars['Float'];
  leftOverRatio: Scalars['Float'];
};

export type MacroMathInput = {
  gPerKg: Scalars['Float'];
  leftOverRatio: Scalars['Float'];
};

export type MacroMealPlanDisplaySettings = {
  __typename?: 'MacroMealPlanDisplaySettings';
  dailyProgressViews: MacroProgressViews;
  mealProgressViews: MacroProgressViews;
};

export type MacroMealPlanDisplaySettingsInput = {
  dailyProgressViews: MacroProgressViews;
  mealProgressViews: MacroProgressViews;
};

export type MacroMealPlanTemplate = MealPlanTemplate & {
  __typename?: 'MacroMealPlanTemplate';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  owner: User;
  /** @deprecated Field no longer supported */
  orgId: Maybe<Scalars['ID']>;
  /** @deprecated Field no longer supported */
  orgGroupId: Maybe<Scalars['ID']>;
  teams: Maybe<Array<Team>>;
  advancedSelection: Maybe<AdvancedSelectionType>;
  hasAdvancedSelection: Scalars['Boolean'];
  isShared: Scalars['Boolean'];
  copiedCount: Scalars['Int'];
  avoidedFoodGroups: Array<FoodGroup>;
  promotedFoodGroups: Array<FoodGroup>;
  avoidedFoods: Array<Food>;
  promotedFoods: Array<Food>;
  macroProtocol: MealPlanTemplateMacroProtocol;
  meals: Array<MacroMealPlanTemplateMeal>;
  activities: Array<MealPlanTemplateActivity>;
  pendingState: Maybe<Scalars['String']>;
  additionalState: Maybe<Scalars['String']>;
};

export type MacroMealPlanTemplateMeal = MealPlanTemplateMeal & {
  __typename?: 'MacroMealPlanTemplateMeal';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: MealType;
  start: Scalars['Time'];
  end: Scalars['Time'];
  mealOptions: Array<MealPlanTemplateMealOption>;
  choRatio: Scalars['Float'];
  proRatio: Scalars['Float'];
  fatRatio: Scalars['Float'];
};

export type MacroMealPlanTemplateMealInput = {
  meal: AddMealInput;
  mealOptions: Array<MealOptionInput>;
  choRatio: Scalars['Float'];
  proRatio: Scalars['Float'];
  fatRatio: Scalars['Float'];
};

export type MacroMealPlanTemplatePendingStateInput = {
  macroMealPlanTemplateId: Scalars['ID'];
  pendingState: Scalars['String'];
};

export type MacroMealTemplate = MealTemplate & {
  __typename?: 'MacroMealTemplate';
  id: Scalars['ID'];
  meal: Meal;
  mealOptionCount: Scalars['Int'];
  mealOptions: Array<MealOption>;
  pendingMealOptions: Array<MealOptionPending>;
  hasDateModifications: Scalars['Boolean'];
  cho: Maybe<Scalars['Float']>;
  pro: Maybe<Scalars['Float']>;
  fat: Maybe<Scalars['Float']>;
  kcal: Maybe<Scalars['Float']>;
  mealPlan: MealPlan;
};


export type MacroMealTemplateHasDateModificationsArgs = {
  after: Scalars['Date'];
};

export enum MacroProgressViews {
  all = 'all',
  hideNumbers = 'hideNumbers',
  none = 'none'
}

export type MacroProtocol = {
  __typename?: 'MacroProtocol';
  id: Scalars['ID'];
  cho: MacroMath;
  pro: MacroMath;
  fat: MacroMath;
  weightTarget: Maybe<Scalars['Float']>;
  weightTargetInKg: Maybe<Scalars['Float']>;
  anthropometrySnapshot: AnthropometryEntry;
  calorieBudget: Maybe<CalorieBudget>;
};

export type MacroProtocolInput = {
  cho: MacroMathInput;
  pro: MacroMathInput;
  fat: MacroMathInput;
  weightTarget: Maybe<Scalars['Float']>;
  anthropometryEntryId: Scalars['ID'];
};

export type MacroProtocolNewInput = {
  cho: MacroMathInput;
  pro: MacroMathInput;
  fat: MacroMathInput;
  weightTarget: Maybe<Scalars['Float']>;
  anthropometryEntryId: Scalars['ID'];
  calorieBudget: Maybe<CalorieBudgetInput>;
  goalId: Maybe<Scalars['ID']>;
  goal: Maybe<GoalFormInput>;
};

export type MacroProtocolWithNewGoalInput = {
  cho: MacroMathInput;
  pro: MacroMathInput;
  fat: MacroMathInput;
  weightTarget: Maybe<Scalars['Float']>;
  anthropometryEntryId: Scalars['ID'];
  calorieBudget: CalorieBudgetInput;
  goal: GoalFormInput;
};

export type MacroProtocolWithOldGoalInput = {
  cho: MacroMathInput;
  pro: MacroMathInput;
  fat: MacroMathInput;
  weightTarget: Maybe<Scalars['Float']>;
  anthropometryEntryId: Scalars['ID'];
  calorieBudget: CalorieBudgetInput;
  goalId: Scalars['ID'];
};

export enum MacroType {
  cho = 'cho',
  pro = 'pro',
  fat = 'fat'
}

export type Macros = {
  __typename?: 'Macros';
  cho: Scalars['Float'];
  pro: Scalars['Float'];
  fat: Scalars['Float'];
  kcal: Scalars['Float'];
};

export type MacrosAndKcals = {
  __typename?: 'MacrosAndKcals';
  cho: Scalars['Float'];
  choKcal: Scalars['Float'];
  pro: Scalars['Float'];
  proKcal: Scalars['Float'];
  fat: Scalars['Float'];
  fatKcal: Scalars['Float'];
  kcal: Scalars['Float'];
};

export type MacrosInput = {
  cho: Scalars['Float'];
  pro: Scalars['Float'];
  fat: Scalars['Float'];
};

export type MarkAllNotificationsAsViewedPayload = {
  __typename?: 'MarkAllNotificationsAsViewedPayload';
  notifications: Array<Notification>;
};

export type MarkNotificationsAsViewedBeforeInput = {
  before: Scalars['DateTime'];
};

export type MarkNotificationsAsViewedBeforePayload = {
  __typename?: 'MarkNotificationsAsViewedBeforePayload';
  notifications: Array<Notification>;
};

export type MarkNotificationsAsViewedInput = {
  notificationIds: Array<Scalars['ID']>;
};

export type MarkNotificationsAsViewedPayload = {
  __typename?: 'MarkNotificationsAsViewedPayload';
  notifications: Array<Notification>;
};

export type Meal = {
  __typename?: 'Meal';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: MealType;
  start: Scalars['Time'];
  end: Scalars['Time'];
};

export type MealEvent = {
  __typename?: 'MealEvent';
  id: Scalars['ID'];
  datetime: Scalars['DateTime'];
  timezone: Scalars['String'];
  mealType: MealType;
  totalMacros: Macros;
  targetMacros: Macros;
  nutrientAmounts: Array<NutrientAmount>;
  mealMenuInfo: Array<MealMenuInfo>;
};

export type MealMenu = {
  id: Scalars['ID'];
  name: Scalars['String'];
  type: MealType;
  start: Scalars['DateTime'];
  timezone: Scalars['Timezone'];
  durationInMinutes: Scalars['Int'];
  prepTimeInMinutes: Scalars['Int'];
  lastOrderTimeBeforeEndInMinutes: Scalars['Int'];
  notificationSentBeforeOrderDueInMinutes: Maybe<Scalars['Int']>;
  mealMenuDiningStations: Array<MealMenuDiningStation>;
  orderRateLimit: Maybe<MenuOrderRateLimit>;
  userOrderLimit: Maybe<Scalars['Int']>;
  isLogOnly: Scalars['Boolean'];
  isInHouseMenuOrderable: Scalars['Boolean'];
  isRestaurantMenuOrderable: Scalars['Boolean'];
  isBulkOrderable: Scalars['Boolean'];
  isOrderable: Scalars['Boolean'];
  identity: Maybe<MealMenuIdentity>;
  restaurantMenuLinks: Array<RestaurantMenuLink>;
  isHubCheckInEnabled: Scalars['Boolean'];
  hubEventUrl: Maybe<Scalars['String']>;
  isOrderAndLogRestricted: Scalars['Boolean'];
  isViewOnlyForMe: Scalars['Boolean'];
  teams: Array<Team>;
  advancedSelection: Maybe<AdvancedSelectionType>;
  hasAdvancedSelection: Scalars['Boolean'];
  athleteCount: Scalars['Int'];
  athletes: Array<Athlete>;
  allOrders: Array<MenuOrder>;
  allMealMenuPlateOrders: Array<MealMenuPlateOrder>;
  /** @deprecated pending forced update for multiple MenuOrders per menu */
  order: Maybe<MenuOrder>;
  orders: Array<MenuOrder>;
  ordersForUser: Array<MenuOrder>;
  ordersForAthlete: Array<MenuOrder>;
  logs: Array<MealMenuLog>;
  logsForAthlete: Array<MealMenuLog>;
  theme: Maybe<Scalars['String']>;
};


export type MealMenuOrdersForUserArgs = {
  userId: Scalars['ID'];
};


export type MealMenuOrdersForAthleteArgs = {
  athleteId: Scalars['ID'];
};


export type MealMenuLogsForAthleteArgs = {
  athleteId: Scalars['ID'];
};

export type MealMenuAttendeeNonNotemealAthlete = {
  __typename?: 'MealMenuAttendeeNonNotemealAthlete';
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  checkedIn: Scalars['Boolean'];
  checkInDisplayDate: Maybe<Scalars['String']>;
  hasAccess: Maybe<Scalars['Boolean']>;
};

export type MealMenuAttendeeNotemealAthlete = {
  __typename?: 'MealMenuAttendeeNotemealAthlete';
  athlete: Athlete;
  checkedIn: Scalars['Boolean'];
  checkInDisplayDate: Maybe<Scalars['String']>;
  hasAccess: Maybe<Scalars['Boolean']>;
};

export type MealMenuAttendeePayload = {
  __typename?: 'MealMenuAttendeePayload';
  notemealAttendees: Array<MealMenuAttendeeNotemealAthlete>;
  nonNotemealAttendees: Array<MealMenuAttendeeNonNotemealAthlete>;
  queryCompletedAt: Scalars['DateTime'];
};

export type MealMenuAvailableNotification = Notification & {
  __typename?: 'MealMenuAvailableNotification';
  id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  type: Scalars['String'];
  title: Scalars['String'];
  message: Scalars['String'];
  createdAt: Scalars['DateTime'];
  viewedAt: Maybe<Scalars['DateTime']>;
  mealMenu: Maybe<StandaloneMealMenu>;
};

export type MealMenuCheckIn = {
  __typename?: 'MealMenuCheckIn';
  id: Scalars['ID'];
  mealMenuId: Scalars['ID'];
  athleteId: Scalars['ID'];
};

export type MealMenuDiningStation = {
  __typename?: 'MealMenuDiningStation';
  id: Scalars['ID'];
  name: Scalars['String'];
  position: Scalars['Int'];
  maxAmount: Maybe<Scalars['Int']>;
  diningStationTemplate: Maybe<DiningStationTemplate>;
  /** @deprecated includeLogOnlyItems not used on back-end anymore, can remove on next mobile force update */
  menuItemAppearances: Array<MenuItemAppearance>;
};


export type MealMenuDiningStationMenuItemAppearancesArgs = {
  includeLogOnlyItems: Maybe<Scalars['Boolean']>;
};

export type MealMenuIdentity = {
  id: Scalars['ID'];
  isOverridden: Scalars['Boolean'];
};

export type MealMenuInfo = {
  __typename?: 'MealMenuInfo';
  name: Maybe<Scalars['String']>;
  usedToOrder: Scalars['Boolean'];
};

export type MealMenuIsSharedNotification = Notification & {
  __typename?: 'MealMenuIsSharedNotification';
  id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  type: Scalars['String'];
  title: Scalars['String'];
  message: Scalars['String'];
  createdAt: Scalars['DateTime'];
  viewedAt: Maybe<Scalars['DateTime']>;
  mealPlan: Maybe<MealPlan>;
};

export type MealMenuLog = MenuSelection & {
  __typename?: 'MealMenuLog';
  id: Scalars['ID'];
  user: User;
  mealMenu: MealMenu;
  items: Array<MealMenuLogItem>;
};

export type MealMenuLogItem = MenuSelectionItem & {
  __typename?: 'MealMenuLogItem';
  id: Scalars['ID'];
  amount: Scalars['Float'];
  percentConsumed: Maybe<Scalars['Float']>;
  menuItem: MenuItem;
  options: Array<MealMenuLogItemOption>;
  log: MealMenuLog;
};

export type MealMenuLogItemInput = {
  menuItemId: Scalars['ID'];
  amount: Scalars['Float'];
  options: Array<MealMenuLogItemOptionInput>;
};

export type MealMenuLogItemOption = MenuSelectionItemOption & {
  __typename?: 'MealMenuLogItemOption';
  amount: Scalars['Float'];
  percentConsumed: Maybe<Scalars['Float']>;
  menuItemChoiceOption: MenuItemChoiceOption;
};

export type MealMenuLogItemOptionInput = {
  amount: Scalars['Float'];
  menuItemChoiceOptionId: Scalars['ID'];
};

export type MealMenuOrderDueNotification = Notification & {
  __typename?: 'MealMenuOrderDueNotification';
  id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  type: Scalars['String'];
  title: Scalars['String'];
  message: Scalars['String'];
  createdAt: Scalars['DateTime'];
  viewedAt: Maybe<Scalars['DateTime']>;
  mealMenu: Maybe<StandaloneMealMenu>;
};

export type MealMenuPlate = {
  __typename?: 'MealMenuPlate';
  id: Scalars['ID'];
  name: Scalars['String'];
  parentBulkOrder: BulkOrder;
  mealMenuPlateOrders: Array<MealMenuPlateOrder>;
  orderCount: Scalars['Int'];
  plateItems: Array<MealMenuPlateItem>;
};

export type MealMenuPlateItem = {
  __typename?: 'MealMenuPlateItem';
  id: Scalars['ID'];
  amount: Scalars['Float'];
  menuItem: MenuItem;
  options: Array<MealMenuPlateItemOption>;
  mealMenuPlate: MealMenuPlate;
  specialRequests: Maybe<Scalars['String']>;
};

export type MealMenuPlateItemOption = {
  __typename?: 'MealMenuPlateItemOption';
  amount: Scalars['Float'];
  mealMenuPlateItem: MealMenuPlateItem;
  menuItemChoiceOption: MenuItemChoiceOption;
};

export type MealMenuPlateItemOptionInput = {
  amount: Scalars['Float'];
  menuItemChoiceOptionId: Scalars['ID'];
};

export type MealMenuPlateItemOrderStatus = {
  __typename?: 'MealMenuPlateItemOrderStatus';
  status: MenuOrderItemStatus;
  mealMenuPlateOrder: MealMenuPlateOrder;
  mealMenuPlateItem: MealMenuPlateItem;
};

export type MealMenuPlateItemOrderStatusPublication = {
  __typename?: 'MealMenuPlateItemOrderStatusPublication';
  scope: MealMenuPlateItemOrderStatusPublisherScope;
  payload: UpdateMultipleMealMenuPlateItemOrderStatusesPayload;
};

export type MealMenuPlateItemOrderStatusPublisherScope = {
  __typename?: 'MealMenuPlateItemOrderStatusPublisherScope';
  mealMenuId: Scalars['ID'];
};

export type MealMenuPlateItemOrderStatusSubscriberScope = {
  mealMenuIds: Array<Scalars['ID']>;
};

export type MealMenuPlateOrder = MenuSelection & {
  __typename?: 'MealMenuPlateOrder';
  id: Scalars['ID'];
  user: User;
  userFullName: Scalars['String'];
  createdAt: Scalars['DateTime'];
  code: Scalars['String'];
  parentBulkOrder: BulkOrder;
  plate: MealMenuPlate;
  items: Array<MealMenuPlateOrderItem>;
  itemCount: Scalars['Int'];
  itemsByDiningStation: Array<MealMenuPlateOrderItem>;
  timelineMeal: Maybe<TimelineMeal>;
  updateCount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  status: Scalars['String'];
  athlete: Maybe<Athlete>;
  mealMenu: MealMenu;
};


export type MealMenuPlateOrderItemsByDiningStationArgs = {
  diningStationIds: Array<Scalars['ID']>;
};

export type MealMenuPlateOrderCursorConnection = {
  __typename?: 'MealMenuPlateOrderCursorConnection';
  edges: Array<MealMenuPlateOrder>;
  pageInfo: CursorPageInfo;
};

export type MealMenuPlateOrderItem = MenuSelectionItem & {
  __typename?: 'MealMenuPlateOrderItem';
  id: Scalars['String'];
  amount: Scalars['Float'];
  percentConsumed: Maybe<Scalars['Float']>;
  menuItem: MenuItem;
  options: Array<MealMenuPlateOrderItemOption>;
  mealMenuPlate: MealMenuPlate;
  specialRequests: Maybe<Scalars['String']>;
  updateCount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  status: MenuOrderItemStatus;
  rating: Maybe<Rating>;
};

export type MealMenuPlateOrderItemOption = MenuSelectionItemOption & {
  __typename?: 'MealMenuPlateOrderItemOption';
  amount: Scalars['Float'];
  percentConsumed: Maybe<Scalars['Float']>;
  mealMenuPlateItem: MealMenuPlateItem;
  menuItemChoiceOption: MenuItemChoiceOption;
};

export type MealMenuUserOrderLimitInput = {
  limit: Maybe<Scalars['Int']>;
};

export type MealMenusCursorConnection = {
  __typename?: 'MealMenusCursorConnection';
  edges: Array<MealMenu>;
  pageInfo: CursorPageInfo;
};

export type MealMenusWithCheckinPayload = {
  __typename?: 'MealMenusWithCheckinPayload';
  mealMenu: StandaloneMealMenu;
  mealMenuExpectedAttendeeCount: Scalars['Int'];
  mealMenuActualAttendeeCount: Scalars['Int'];
  attendeeUnlistedCount: Scalars['Int'];
  attendeeListedCount: Scalars['Int'];
};

export type MealOption = {
  __typename?: 'MealOption';
  id: Scalars['ID'];
  servingAmounts: Array<ServingAmount>;
  position: Scalars['Int'];
  note: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  mealTemplate: Maybe<MealTemplate>;
  ingredientText: Scalars['String'];
};

export type MealOptionCursorConnection = {
  __typename?: 'MealOptionCursorConnection';
  edges: Array<MealOption>;
  pageInfo: CursorPageInfo;
};

export type MealOptionInput = {
  servingAmounts: Array<CreateServingAmountInput>;
  position: Scalars['Int'];
  note: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
};

export type MealOptionPending = {
  __typename?: 'MealOptionPending';
  id: Scalars['ID'];
  servingAmounts: Array<ServingAmount>;
  position: Scalars['Int'];
  note: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
  mealTemplate: Maybe<MealTemplate>;
  ingredientText: Scalars['String'];
};

export type MealOptionPendingInput = {
  servingAmounts: Array<CreateServingAmountInput>;
  note: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
};

export type MealPlan = {
  __typename?: 'MealPlan';
  id: Scalars['ID'];
  type: MealPlanType;
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  dates: Maybe<Array<Scalars['Date']>>;
  startDate: Maybe<Scalars['Date']>;
  endDate: Maybe<Scalars['Date']>;
  /** @deprecated Field no longer supported */
  usesDateRange: Scalars['Boolean'];
  isAutoSuggestionsEnabled: Scalars['Boolean'];
  isShared: Scalars['Boolean'];
  wasCreatedFromMealPlanTemplate: Scalars['Boolean'];
  daysOfWeek: Array<MealPlanDayOfWeekAssignment>;
  individualDatesInRange: Array<Scalars['Date']>;
  hasIndividualDateAssignments: Scalars['Boolean'];
  modificationsInRange: Array<MealPlanDateModifications>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  avoidedFoodGroups: Array<FoodGroup>;
  promotedFoodGroups: Array<FoodGroup>;
  avoidedFoods: Array<Food>;
  promotedFoods: Array<Food>;
  schedule: Maybe<Schedule>;
  athlete: Athlete;
  macroProtocol: MacroProtocol;
  mealTemplates: Array<MealTemplate>;
  activityTemplates: Array<ActivityTemplate>;
  exchangeTargets: Maybe<Array<ExchangeAmount>>;
  exchangeSet: Maybe<ExchangeSet>;
  mostRecentEvent: Maybe<Event>;
  events: Array<Event>;
};


export type MealPlanIndividualDatesInRangeArgs = {
  start: Scalars['Date'];
  end: Scalars['Date'];
};


export type MealPlanModificationsInRangeArgs = {
  start: Scalars['Date'];
  end: Scalars['Date'];
};

export type MealPlanCreateFormInput = {
  type: MealPlanType;
  exchangeSetId: Maybe<Scalars['String']>;
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  usesDateRange: Scalars['Boolean'];
  dates: Maybe<Array<Scalars['Date']>>;
  startDate: Maybe<Scalars['Date']>;
  endDate: Maybe<Scalars['Date']>;
  handleDatesConflict: Scalars['Boolean'];
  isShared: Scalars['Boolean'];
};

export type MealPlanCursorConnection = {
  __typename?: 'MealPlanCursorConnection';
  edges: Array<MealPlan>;
  pageInfo: CursorPageInfo;
};

export type MealPlanDate = {
  __typename?: 'MealPlanDate';
  mealPlan: MealPlan;
  date: Scalars['Date'];
  timezone: Scalars['Timezone'];
};

/**
 * Entity that combines individual date and day of week assignments to answer questions
 * 1. What MealPlan is assigned to a date?
 * 2. How is the MealPlan assigned to a date (via individual date or day of week)?
 * Without performing all of the resolution logic in on the frontend
 */
export type MealPlanDateAssignment = {
  __typename?: 'MealPlanDateAssignment';
  date: Scalars['Date'];
  mealPlan: MealPlan;
  dayOfWeekPriority: Maybe<DayOfWeekPriority>;
  mealTemplateModifications: Array<MealTemplateModification>;
  activityTemplateModifications: Array<ActivityTemplateModification>;
};

export type MealPlanDateModificationInput = {
  date: Scalars['Date'];
  start: Scalars['Time'];
  end: Scalars['Time'];
};

export type MealPlanDateModifications = {
  __typename?: 'MealPlanDateModifications';
  date: Scalars['Date'];
  mealTemplateModifications: Array<MealTemplateModification>;
  activityTemplateModifications: Array<ActivityTemplateModification>;
};

export type MealPlanDayOfWeekAssignment = {
  __typename?: 'MealPlanDayOfWeekAssignment';
  mealPlan: MealPlan;
  dayOfWeekPriority: DayOfWeekPriority;
  mealTemplateModifications: Array<MealTemplateModification>;
  activityTemplateModifications: Array<ActivityTemplateModification>;
};

export type MealPlanDayOfWeekModificationInput = {
  dayOfWeek: DayOfWeek;
  start: Scalars['Time'];
  end: Scalars['Time'];
};

export type MealPlanEditFormInput = {
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  usesDateRange: Scalars['Boolean'];
  dates: Maybe<Array<Scalars['Date']>>;
  startDate: Maybe<Scalars['Date']>;
  endDate: Maybe<Scalars['Date']>;
  handleDatesConflict: Scalars['Boolean'];
  isShared: Scalars['Boolean'];
  type: MealPlanType;
};

export type MealPlanFromTemplateAnthropometryInput = {
  existingAnthropometryEntryId: Maybe<Scalars['ID']>;
  anthropometryEntryInput: Maybe<AnthropometryEntryFormInput>;
};

export type MealPlanFromTemplateMetricAnthropometryInput = {
  existingAnthropometryEntryId: Maybe<Scalars['ID']>;
  anthropometryEntryInput: Maybe<MetricAnthropometryEntryFormInput>;
};

export type MealPlanIndividualDateAssignment = {
  __typename?: 'MealPlanIndividualDateAssignment';
  date: Scalars['Date'];
  mealPlan: MealPlan;
};

export type MealPlanOffsetConnection = {
  __typename?: 'MealPlanOffsetConnection';
  edges: Array<MealPlan>;
  pageInfo: OffsetPageInfo;
};

export type MealPlanSharedNotification = Notification & {
  __typename?: 'MealPlanSharedNotification';
  id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  type: Scalars['String'];
  title: Scalars['String'];
  message: Scalars['String'];
  createdAt: Scalars['DateTime'];
  viewedAt: Maybe<Scalars['DateTime']>;
  mealPlan: Maybe<MealPlan>;
};

export type MealPlanTemplate = {
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
  owner: User;
  /** @deprecated Field no longer supported */
  orgId: Maybe<Scalars['ID']>;
  /** @deprecated Field no longer supported */
  orgGroupId: Maybe<Scalars['ID']>;
  teams: Maybe<Array<Team>>;
  advancedSelection: Maybe<AdvancedSelectionType>;
  hasAdvancedSelection: Scalars['Boolean'];
  isShared: Scalars['Boolean'];
  copiedCount: Scalars['Int'];
  avoidedFoodGroups: Array<FoodGroup>;
  promotedFoodGroups: Array<FoodGroup>;
  avoidedFoods: Array<Food>;
  promotedFoods: Array<Food>;
  macroProtocol: MealPlanTemplateMacroProtocol;
  meals: Array<MealPlanTemplateMeal>;
  activities: Array<MealPlanTemplateActivity>;
  pendingState: Maybe<Scalars['String']>;
  /**
   * This field stores values the user entered in the UI but not required for creating a meal plan from a meal plan template.
   * The sample data the user used is stored.  When the user turns off calorie budget we also save it here so things feel familiar
   * if they decide to turn it back on.
   */
  additionalState: Maybe<Scalars['String']>;
};

export type MealPlanTemplateActivity = {
  __typename?: 'MealPlanTemplateActivity';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: ActivityType;
  start: Scalars['Time'];
  end: Scalars['Time'];
};

export type MealPlanTemplateActivityInput = {
  name: Scalars['String'];
  type: ActivityType;
  start: Scalars['Time'];
  end: Scalars['Time'];
};

export type MealPlanTemplateAnthropometry = AnthropometryTemplate | AnthropometryForm;

export type MealPlanTemplateAnthropometryInput = {
  formFields: Maybe<Array<AnthropometryFormField>>;
  template: Maybe<AnthropometryTemplateInput>;
};

export type MealPlanTemplateCalorieBudget = {
  __typename?: 'MealPlanTemplateCalorieBudget';
  id: Scalars['ID'];
  rmrMethod: RmrMethod;
  activityFactor: Scalars['Float'];
  goals: Array<MealPlanTemplateGoal>;
};

export type MealPlanTemplateCalorieBudgetInput = {
  rmrMethod: RmrMethod;
  activityFactor: Scalars['Float'];
  goals: Array<MealPlanTemplateGoalInput>;
};

export type MealPlanTemplateCursorConnection = {
  __typename?: 'MealPlanTemplateCursorConnection';
  edges: Array<MealPlanTemplate>;
  pageInfo: CursorPageInfo;
};

export type MealPlanTemplateGoal = {
  __typename?: 'MealPlanTemplateGoal';
  id: Scalars['ID'];
  name: Scalars['String'];
  kcalOffset: Scalars['Int'];
  goalType: GoalType;
};

export type MealPlanTemplateGoalInput = {
  name: Scalars['String'];
  kcalOffset: Scalars['Int'];
  goalTypeId: Scalars['ID'];
};

export type MealPlanTemplateMacroProtocol = {
  __typename?: 'MealPlanTemplateMacroProtocol';
  id: Scalars['ID'];
  cho: MacroMath;
  pro: MacroMath;
  fat: MacroMath;
  useExistingAnthropometry: Scalars['Boolean'];
  anthropometry: MealPlanTemplateAnthropometry;
  calorieBudget: Maybe<MealPlanTemplateCalorieBudget>;
};

export type MealPlanTemplateMacroProtocolInput = {
  cho: MacroMathInput;
  pro: MacroMathInput;
  fat: MacroMathInput;
  useExistingAnthropometry: Scalars['Boolean'];
  anthropometry: MealPlanTemplateAnthropometryInput;
  calorieBudget: Maybe<MealPlanTemplateCalorieBudgetInput>;
};

export type MealPlanTemplateMeal = {
  id: Scalars['ID'];
  name: Scalars['String'];
  type: MealType;
  start: Scalars['Time'];
  end: Scalars['Time'];
  mealOptions: Array<MealPlanTemplateMealOption>;
};

export type MealPlanTemplateMealOption = {
  __typename?: 'MealPlanTemplateMealOption';
  id: Scalars['ID'];
  position: Scalars['Int'];
  note: Maybe<Scalars['String']>;
  servingAmounts: Array<ServingAmount>;
  name: Maybe<Scalars['String']>;
};

export type MealPlanTemplateMealOptionInput = {
  servingAmounts: Array<CreateServingAmountInput>;
  position: Scalars['Int'];
  note: Maybe<Scalars['String']>;
  name: Maybe<Scalars['String']>;
};

export type MealPlanTemplateOffsetConnection = {
  __typename?: 'MealPlanTemplateOffsetConnection';
  edges: Array<MealPlanTemplate>;
  pageInfo: OffsetPageInfo;
};

export enum MealPlanType {
  macro = 'macro',
  exchange = 'exchange'
}

export type MealTemplate = {
  id: Scalars['ID'];
  meal: Meal;
  mealOptionCount: Scalars['Int'];
  mealOptions: Array<MealOption>;
  pendingMealOptions: Array<MealOptionPending>;
  hasDateModifications: Scalars['Boolean'];
  mealPlan: MealPlan;
};


export type MealTemplateHasDateModificationsArgs = {
  after: Scalars['Date'];
};

/**
 * Modification of a MealTemplate, tied to either an individual date or a day of the week
 * Currently only time edits (start and end) are supported
 */
export type MealTemplateModification = {
  __typename?: 'MealTemplateModification';
  mealTemplate: MealTemplate;
  start: Scalars['Time'];
  end: Scalars['Time'];
};

export enum MealType {
  breakfast = 'breakfast',
  lunch = 'lunch',
  dinner = 'dinner',
  snack = 'snack',
  meal = 'meal'
}

export type MenuItem = {
  __typename?: 'MenuItem';
  id: Scalars['ID'];
  name: Scalars['String'];
  servingName: Scalars['String'];
  description: Maybe<Scalars['String']>;
  isOneOff: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  defaultAvailableForOrder: Scalars['Boolean'];
  defaultMaxAmount: Maybe<Scalars['Int']>;
  suggestionCategory: Maybe<MenuItemSuggestionCategory>;
  ratingCounts: RatingCounts;
  isCurrent: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  orderItemCount: Scalars['Int'];
  isMissingIngredients: Scalars['Boolean'];
  servingAmounts: Array<ServingAmount>;
  servingAmountsCount: Scalars['Int'];
  choices: Array<MenuItemChoice>;
  history: MenuItemHistory;
  imageUrl: Maybe<Scalars['String']>;
  defaultAllowSpecialRequests: Scalars['Boolean'];
  score: Maybe<Score>;
  foodCategory: Maybe<MenuItemFoodCategory>;
  /** @deprecated Field no longer supported */
  isAiGenerated: Scalars['Boolean'];
  aiAugmented: AiAugmented;
};

export type MenuItemAmount = MenuSelectionItem & {
  __typename?: 'MenuItemAmount';
  menuItem: MenuItem;
  amount: Scalars['Float'];
  options: Array<MenuItemChoiceOptionAmount>;
  percentConsumed: Maybe<Scalars['Float']>;
};

export type MenuItemAmountInput = {
  menuItemId: Scalars['ID'];
  amount: Scalars['Int'];
  options: Array<MenuItemChoiceOptionAmountInput>;
};

export type MenuItemAppearance = {
  __typename?: 'MenuItemAppearance';
  id: Scalars['ID'];
  menuItem: MenuItem;
  position: Scalars['Int'];
  availableForOrder: Scalars['Boolean'];
  maxAmount: Maybe<Scalars['Int']>;
  allowSpecialRequests: Scalars['Boolean'];
};

export type MenuItemAppearanceInput = {
  position: Scalars['Int'];
  availableForOrder: Scalars['Boolean'];
  maxAmount: Maybe<Scalars['Int']>;
  allowSpecialRequests: Scalars['Boolean'];
};

export type MenuItemChoice = {
  __typename?: 'MenuItemChoice';
  id: Scalars['ID'];
  name: Scalars['String'];
  position: Scalars['Int'];
  maxOptionsCount: Maybe<Scalars['Int']>;
  required: Scalars['Boolean'];
  options: Array<MenuItemChoiceOption>;
};

export type MenuItemChoiceOption = {
  __typename?: 'MenuItemChoiceOption';
  id: Scalars['ID'];
  name: Scalars['String'];
  position: Scalars['Int'];
  canEditAmount: Scalars['Boolean'];
  defaultAmount: Scalars['Float'];
  maxAmount: Scalars['Float'];
  servingAmounts: Array<ServingAmount>;
};

export type MenuItemChoiceOptionAmount = MenuSelectionItemOption & {
  __typename?: 'MenuItemChoiceOptionAmount';
  menuItemChoiceOption: MenuItemChoiceOption;
  amount: Scalars['Float'];
  percentConsumed: Maybe<Scalars['Float']>;
};

export type MenuItemChoiceOptionAmountInput = {
  menuItemChoiceOptionId: Scalars['ID'];
  amount: Scalars['Int'];
};

export type MenuItemChoiceOptionMissingIngredientsInput = {
  menuItemChoiceOptionId: Scalars['ID'];
  servingAmounts: Array<CreateServingAmountInput>;
};

export type MenuItemCursorConnection = {
  __typename?: 'MenuItemCursorConnection';
  edges: Array<MenuItem>;
  pageInfo: CursorPageInfo;
};

export type MenuItemFoodCategory = {
  __typename?: 'MenuItemFoodCategory';
  category: FoodCategoryType;
};

export type MenuItemHistory = {
  __typename?: 'MenuItemHistory';
  id: Scalars['ID'];
  current: MenuItem;
  all: Array<MenuItem>;
  ratingCounts: RatingCounts;
};

export type MenuItemMissingIngredientsInput = {
  menuItemId: Scalars['ID'];
  servingAmounts: Array<CreateServingAmountInput>;
  menuItemChoiceOptions: Array<MenuItemChoiceOptionMissingIngredientsInput>;
};

export type MenuItemOffsetConnection = {
  __typename?: 'MenuItemOffsetConnection';
  edges: Array<MenuItem>;
  pageInfo: OffsetPageInfo;
};

export type MenuItemRating = Rating & {
  __typename?: 'MenuItemRating';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  value: RatingValue;
  review: Maybe<Scalars['String']>;
  createdBy: Maybe<User>;
  menuOrderItem: Maybe<MenuOrderItem>;
  menuItem: MenuItem;
};

export type MenuItemRatingOffsetConnection = {
  __typename?: 'MenuItemRatingOffsetConnection';
  edges: Array<Rating>;
  pageInfo: OffsetPageInfo;
};

export enum MenuItemSuggestionCategory {
  combo = 'combo',
  main = 'main',
  protein = 'protein',
  starch = 'starch',
  vegetable = 'vegetable',
  fruit = 'fruit',
  other = 'other'
}

export type MenuOrder = MenuSelection & {
  __typename?: 'MenuOrder';
  id: Scalars['ID'];
  user: User;
  pickupTime: Scalars['DateTime'];
  code: Scalars['String'];
  createdAt: Scalars['DateTime'];
  mealMenu: MealMenu;
  items: Array<MenuOrderItem>;
  itemCount: Scalars['Int'];
  itemsByDiningStation: Array<MenuOrderItem>;
  athlete: Maybe<Athlete>;
  userFullName: Scalars['String'];
  mealTemplate: Maybe<MealTemplate>;
  comments: Array<Comment>;
  images: Array<Image>;
  updateCount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  timelineMeal: Maybe<TimelineMeal>;
};


export type MenuOrderItemsByDiningStationArgs = {
  diningStationIds: Array<Scalars['ID']>;
};

export type MenuOrderCommentNotification = Notification & {
  __typename?: 'MenuOrderCommentNotification';
  id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  type: Scalars['String'];
  createdAt: Scalars['DateTime'];
  viewedAt: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  message: Scalars['String'];
  menuOrder: Maybe<MenuOrder>;
  timelineMeal: Maybe<TimelineMeal>;
};

export type MenuOrderCursorConnection = {
  __typename?: 'MenuOrderCursorConnection';
  edges: Array<MenuOrder>;
  pageInfo: CursorPageInfo;
};

export type MenuOrderImageNotification = Notification & {
  __typename?: 'MenuOrderImageNotification';
  id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  type: Scalars['String'];
  createdAt: Scalars['DateTime'];
  viewedAt: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  message: Scalars['String'];
  menuOrder: Maybe<MenuOrder>;
  timelineMeal: Maybe<TimelineMeal>;
  image: Maybe<Image>;
};

export type MenuOrderInfo = {
  __typename?: 'MenuOrderInfo';
  mealMenuId: Scalars['ID'];
  menuOrderCount: Scalars['Int'];
  mostRecentEditableMenuOrderId: Maybe<Scalars['ID']>;
};

export type MenuOrderItem = MenuSelectionItem & {
  __typename?: 'MenuOrderItem';
  id: Scalars['ID'];
  amount: Scalars['Float'];
  percentConsumed: Maybe<Scalars['Float']>;
  forOrder: Scalars['Boolean'];
  excludeLogItems: Maybe<Scalars['Boolean']>;
  status: MenuOrderItemStatus;
  menuItem: MenuItem;
  options: Array<MenuOrderItemOption>;
  order: MenuOrder;
  updateCount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  rating: Maybe<Rating>;
  specialRequests: Maybe<Scalars['String']>;
};

export type MenuOrderItemInput = {
  menuItemId: Scalars['ID'];
  amount: Scalars['Float'];
  specialRequests: Maybe<Scalars['String']>;
  options: Array<MenuOrderItemOptionInput>;
};

export type MenuOrderItemOption = MenuSelectionItemOption & {
  __typename?: 'MenuOrderItemOption';
  amount: Scalars['Float'];
  percentConsumed: Maybe<Scalars['Float']>;
  menuItemChoiceOption: MenuItemChoiceOption;
};

export type MenuOrderItemOptionInput = {
  amount: Scalars['Float'];
  menuItemChoiceOptionId: Scalars['ID'];
};

export enum MenuOrderItemStatus {
  new = 'new',
  cooking = 'cooking',
  done = 'done',
  cancelled = 'cancelled'
}

export type MenuOrderItemStatusesPublication = {
  __typename?: 'MenuOrderItemStatusesPublication';
  scope: MenuOrderItemStatusesPublisherScope;
  payload: UpdateMenuOrderItemStatusesPayload;
};

export type MenuOrderItemStatusesPublisherScope = {
  __typename?: 'MenuOrderItemStatusesPublisherScope';
  mealMenuId: Scalars['ID'];
};

export type MenuOrderItemStatusesSubscriberScope = {
  mealMenuIds: Maybe<Array<Scalars['ID']>>;
};

export type MenuOrderPublication = {
  __typename?: 'MenuOrderPublication';
  scope: MenuOrderPublisherScope;
  payload: MenuOrderPublicationPayload;
};

export type MenuOrderPublicationPayload = AddMenuOrderPayload | EditMenuOrderPayload | RemoveMenuOrderPayload | AddMenuOrderCommentPayload;

export type MenuOrderPublisherScope = {
  __typename?: 'MenuOrderPublisherScope';
  mealMenuId: Scalars['ID'];
};

export type MenuOrderRateLimit = {
  __typename?: 'MenuOrderRateLimit';
  limit: Scalars['Int'];
  excludedTeamIds: Array<Scalars['ID']>;
};

export type MenuOrderRateLimitInput = {
  limit: Scalars['Int'];
  excludedTeamIds: Array<Scalars['ID']>;
};

export type MenuOrderRateLimitRemainder = {
  __typename?: 'MenuOrderRateLimitRemainder';
  remaining: Scalars['Int'];
  start: Scalars['DateTime'];
};

export type MenuOrderStatusNotification = Notification & {
  __typename?: 'MenuOrderStatusNotification';
  id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  type: Scalars['String'];
  title: Scalars['String'];
  message: Scalars['String'];
  createdAt: Scalars['DateTime'];
  viewedAt: Maybe<Scalars['DateTime']>;
  menuOrder: Maybe<MenuOrder>;
  timelineMeal: Maybe<TimelineMeal>;
};

export type MenuOrderSubscriberScope = {
  mealMenuIds: Maybe<Array<Scalars['ID']>>;
};

export type MenuOrderSuggestion = MenuSelection & {
  __typename?: 'MenuOrderSuggestion';
  items: Array<MenuItemAmount>;
};

export type MenuOrderSuggestionInput = {
  items: Array<MenuItemAmountInput>;
};

export type MenuSelection = {
  items: Array<MenuSelectionItem>;
};

export type MenuSelectionItem = {
  amount: Scalars['Float'];
  percentConsumed: Maybe<Scalars['Float']>;
  menuItem: MenuItem;
  options: Array<MenuSelectionItemOption>;
};

export type MenuSelectionItemOption = {
  amount: Scalars['Float'];
  percentConsumed: Maybe<Scalars['Float']>;
  menuItemChoiceOption: MenuItemChoiceOption;
};

export type MessageOption = {
  __typename?: 'MessageOption';
  id: Scalars['ID'];
  userId: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  teamName: Scalars['String'];
};

export type MessageOptionCursorConnection = {
  __typename?: 'MessageOptionCursorConnection';
  edges: Array<MessageOption>;
  pageInfo: CursorPageInfo;
};

export type MetricAnthropometryEntryFormInput = {
  heightInCm: Maybe<Scalars['Float']>;
  weightInKg: Scalars['Float'];
  leanBodyMassInKg: Maybe<Scalars['Float']>;
  bodyFatMassInKg: Maybe<Scalars['Float']>;
  skeletalMuscleMassInKg: Maybe<Scalars['Float']>;
  trunkFatInKg: Maybe<Scalars['Float']>;
  dryLeanMassInKg: Maybe<Scalars['Float']>;
  percentBodyFat: Maybe<Scalars['Float']>;
  visceralFatArea: Maybe<Scalars['Float']>;
  datetime: Scalars['DateTime'];
  sex: SexType;
  type: AnthropometryEntryType;
  comment: Maybe<Scalars['String']>;
  boneMineralDensity: Maybe<Scalars['Float']>;
  boneMass: Maybe<Scalars['Float']>;
  boneMineralContent: Maybe<Scalars['Float']>;
  boneArea: Maybe<Scalars['Float']>;
  boneMineralDensityZScore: Maybe<Scalars['Float']>;
  boneMineralDensityTScore: Maybe<Scalars['Float']>;
};

export type MetricMacroProtocolInput = {
  cho: MacroMathInput;
  pro: MacroMathInput;
  fat: MacroMathInput;
  weightTargetInKg: Maybe<Scalars['Float']>;
  anthropometryEntryId: Scalars['ID'];
  calorieBudget: Maybe<CalorieBudgetInput>;
  goalId: Maybe<Scalars['ID']>;
  goal: Maybe<GoalFormInput>;
};

export enum MobileUpdateType {
  force = 'force',
  suggest = 'suggest'
}

export type MoveMacroMealPlanTemplateToOrgGroupInput = {
  macroMealPlanTemplateId: Scalars['ID'];
  orgGroupId: Scalars['ID'];
};

export type MoveMacroMealPlanTemplateToOrgGroupPayload = {
  __typename?: 'MoveMacroMealPlanTemplateToOrgGroupPayload';
  macroMealPlanTemplate: MacroMealPlanTemplate;
};

export type MoveMenuItemInput = {
  menuItemId: Scalars['ID'];
  appearance: MenuItemAppearanceInput;
};

export type MoveOrgFoodToOrgGroupInput = {
  id: Scalars['ID'];
  orgGroupId: Scalars['ID'];
};

export type MoveOrgFoodToOrgGroupPayload = {
  __typename?: 'MoveOrgFoodToOrgGroupPayload';
  food: Food;
};

export type MoveOrgGroupFoodToMyOrgInput = {
  id: Scalars['ID'];
};

export type MoveOrgGroupFoodToMyOrgPayload = {
  __typename?: 'MoveOrgGroupFoodToMyOrgPayload';
  food: Food;
};

export type MoveOrgGroupMacroMealPlanTemplateToOrgInput = {
  macroMealPlanTemplateId: Scalars['ID'];
};

export type MoveOrgGroupMacroMealPlanTemplateToOrgPayload = {
  __typename?: 'MoveOrgGroupMacroMealPlanTemplateToOrgPayload';
  macroMealPlanTemplate: MacroMealPlanTemplate;
};

export type MoveOrgRecipeToOrgGroupInput = {
  id: Scalars['ID'];
  orgGroupId: Scalars['ID'];
};

export type MoveOrgRecipeToOrgGroupPayload = {
  __typename?: 'MoveOrgRecipeToOrgGroupPayload';
  recipe: Recipe;
};

export type Mutation = {
  __typename?: 'Mutation';
  addAgreementForm: AddAgreementFormPayload;
  addAgreementFormSubmission: AddAgreementFormSubmissionPayload;
  addAndLinkTeamworksTeams: AddAndLinkTeamworksTeamsPayload;
  addAthleteDislikedFood: AddAthleteDislikedFoodPayload;
  addAthleteDislikedFoodGroup: AddAthleteDislikedFoodGroupPayload;
  addAthleteEmail: AddAthleteEmailPayload;
  addAthleteLikedFood: AddAthleteLikedFoodPayload;
  addAthleteLikedFoodGroup: AddAthleteLikedFoodGroupPayload;
  addAthletePhoneNumber: AddAthletePhoneNumberPayload;
  addBulkOrder: AddBulkOrderPayload;
  addDeliveryLocation: AddDeliveryLocationPayload;
  addDevice: AddDevicePayload;
  addEvent: AddEventPayload;
  addFoodItemToGroceryList: EditGroceryListFoodItemPayload;
  addFoodLogActivityWithTemplate: AddFoodLogActivityWithTemplatePayload;
  addFoodLogActivityWithTemplateForAthlete: AddFoodLogActivityWithTemplateForAthletePayload;
  addFoodLogActivityWithoutTemplate: AddFoodLogActivityWithoutTemplatePayload;
  addFoodLogActivityWithoutTemplateForAthlete: AddFoodLogActivityWithoutTemplateForAthletePayload;
  addFoodLogMealComment: AddFoodLogMealCommentPayload;
  addFoodLogMealImage: AddFoodLogMealImagePayload;
  addFoodLogMealWithTemplate: AddFoodLogMealWithTemplatePayload;
  addFoodLogMealWithTemplateForAthlete: AddFoodLogMealWithTemplateForAthletePayload;
  addFoodLogMealWithoutTemplate: AddFoodLogMealWithoutTemplatePayload;
  addFoodLogMealWithoutTemplateForAthlete: AddFoodLogMealWithoutTemplateForAthletePayload;
  addMealMenuLogItem: AddMealMenuLogItemPayload;
  addMealMenuLogItemForAthlete: AddMealMenuLogItemPayload;
  addMealMenuLogItems: AddMealMenuLogItemsPayload;
  addMealMenuLogItemsForAthlete: AddMealMenuLogItemsPayload;
  addMealOption: AddMealOptionPayload;
  addMenuItemHistoryMissingIngredients: AddMenuItemHistoryMissingIngredientsPayload;
  addMenuOrderComment: AddMenuOrderCommentPayload;
  addMenuOrderForAthlete: AddMenuOrderForAthletePayload;
  addMenuOrderImage: AddMenuOrderImagePayload;
  addMenuOrderItemRatings: AddMenuOrderItemRatingsPayload;
  addMenuOrderWithTemplate: AddMenuOrderWithTemplatePayload;
  addMenuOrderWithoutTemplate: AddMenuOrderWithoutTemplatePayload;
  addMyDislikedFood: AddAthleteDislikedFoodPayload;
  addMyDislikedFoodGroup: AddAthleteDislikedFoodGroupPayload;
  addMyLikedFood: AddAthleteLikedFoodPayload;
  addMyLikedFoodGroup: AddAthleteLikedFoodGroupPayload;
  addNixBrandedFood: AddNixBrandedFoodPayload;
  addNixBrandedFoodByUpc: AddNixBrandedFoodByUpcPayload;
  addNixCommonFood: AddNixCommonFoodPayload;
  addPosition: AddPositionPayload;
  addRecipeImage: AddRecipeImagePayload;
  addRestaurantLocation: AddRestaurantLocationPayload;
  addRestaurantLocationContact: AddRestaurantLocationContactPayload;
  addRestaurantMenuLink: AddRestaurantMenuLinkPayload;
  addRestaurantMenuLinkOrder: AddRestaurantMenuLinkOrderPayload;
  addRestaurantMenuLinkOrderForAthlete: AddRestaurantMenuLinkOrderForAthletePayload;
  addRestaurantMenuLinkPlateOrder: AddRestaurantMenuLinkPlateOrderPayload;
  addRestaurantMenuLinkPlateOrderForAthlete: AddRestaurantMenuLinkPlateOrderForAthletePayload;
  addRestaurantMenuLog: AddRestaurantMenuLogPayload;
  addRestaurantMenuLogForAthlete: AddRestaurantMenuLogForAthletePayload;
  addRestaurantMenuLogItem: AddRestaurantMenuLogItemPayload;
  addRestaurantMenuLogItemForAthlete: AddRestaurantMenuLogItemPayload;
  addRestaurantMenuLogItems: AddRestaurantMenuLogItemsPayload;
  addRestaurantMenuLogItemsForAthlete: AddRestaurantMenuLogItemsPayload;
  addSport: AddSportPayload;
  addTeamLabel: AddTeamLabelPayload;
  addTimelineMealCommentForAthlete: AddTimelineMealCommentPayload;
  addTimelineMealCommentForMe: AddTimelineMealCommentPayload;
  addTimelineMealImageForAthlete: AddTimelineMealImagePayload;
  addTimelineMealImageForMe: AddTimelineMealImagePayload;
  addTimelineMealServingAmountForAthlete: AddTimelineMealServingAmountPayload;
  addTimelineMealServingAmountForMe: AddTimelineMealServingAmountPayload;
  archiveAthlete: ArchiveAthletePayload;
  archiveGoalConfiguration: ArchiveGoalConfigurationPayload;
  /** @deprecated Field no longer supported */
  assignMealPlanTemplateToAthlete: AssignMealPlanTemplateToAthletePayload;
  assignMetricMealPlanTemplateToAthlete: AssignMealPlanTemplateToAthletePayload;
  bulkCopyMealPlanNew: BulkCopyMealPlanPayload;
  bulkDeleteMealMenus: BulkDeleteMealMenusPayload;
  bulkEditMealMenus: BulkEditMealMenusPayload;
  bulkImportCreateAndDeprecateGenericFoods: BulkImportCreateAndDeprecateGenericFoodsPayload;
  bulkImportCreateGenericFoods: BulkImportCreateGenericFoodsPayload;
  bulkImportCreateRestaurantBrandedFood: BulkImportCreateRestautantBrandedFoodsPayload;
  bulkImportEditRestaurantBrandedFood: BulkImportEditRestautantBrandedFoodsPayload;
  checkGroceryListCustomItem: CheckGroceryListCustomItemPayload;
  checkGroceryListFoodItem: CheckGroceryListFoodItemPayload;
  copyExchangeSet: CopyExchangeSetPayload;
  copyMacroMealPlanTemplate: CopyMacroMealPlanTemplatePayload;
  copyMealMenus: CopyMealMenusPayload;
  copyMealPlanNew: CopyMealPlanPayload;
  copyPrepPlan: CopyPrepPlanPayload;
  copySharedExchangeSet: CopyExchangeSetPayload;
  copySharedMacroMealPlanTemplate: CopyMacroMealPlanTemplatePayload;
  /** @deprecated Field no longer supported */
  createAthlete: CreateAthletePayload;
  createAthleteTransfer: CreateAthleteTransferPayload;
  /** @deprecated Field no longer supported */
  createAthletes: CreateAthletesPayload;
  createBrand: CreateBrandPayload;
  createBrandedFood: CreateBrandedFoodPayload;
  createClientCredential: CreateClientCredentialPayload;
  createCuisine: CreateCuisinePayload;
  createCustomTag: NamedTag;
  createDiet: CreateDietPayload;
  createDiningStationTemplate: CreateDiningStationTemplatePayload;
  createExchangeSet: CreateExchangeSetPayload;
  createFoodServings: CreateFoodServingsPayload;
  createGenericFood: CreateGenericFoodPayload;
  createGoal: CreateGoalPayload;
  createGoalConfigurationWithDaysOfWeek: CreateGoalConfigurationPayload;
  createGoalConfigurationWithIndividualDates: CreateGoalConfigurationPayload;
  createGroceryListCustomItem: CreateGroceryListCustomItemPayload;
  createMacroMealPlanTemplate: CreateMacroMealPlanTemplatePayload;
  createMealMenus: CreateMealMenusPayload;
  /** @deprecated Field no longer supported */
  createMealPlanFromFoodLogsNew: CreateMealPlanFromFoodLogsPayload;
  /** @deprecated Field no longer supported */
  createMealPlanFromMealPlanTemplate: CreateMealPlanFromMealPlanTemplatePayload;
  /** @deprecated Field no longer supported */
  createMealPlanNew: CreateMealPlanPayload;
  createMemberships: CreateOrgMembershipsPayload;
  createMetricAnthropometryEntries: CreateAnthropometryEntriesPayload;
  createMetricAnthropometryEntry: CreateAnthropometryEntryPayload;
  createMetricAthlete: CreateAthletePayload;
  createMetricAthletes: CreateAthletesPayload;
  createMetricMealPlan: CreateMealPlanPayload;
  createMetricMealPlanFromFoodLogs: CreateMealPlanFromFoodLogsPayload;
  createMetricMealPlanFromMealPlanTemplate: CreateMealPlanFromMealPlanTemplatePayload;
  createNote: CreateNotePayload;
  createNoteType: CreateNoteTypePayload;
  createOrg: CreateOrgPayload;
  createOrgFoodGroup: CreateFoodGroupPayload;
  createOrgFoodServings: CreateFoodServingsPayload;
  createOrgFromTeamworksOrg: CreateOrgFromTeamworksOrgPayload;
  createOrgGenericFood: CreateGenericFoodPayload;
  createOrgGroup: CreateOrgGroupPayload;
  createOrgMembershipForAthlete: CreateOrgMembershipForAthletePayload;
  createPaymentMethod: CreatePaymentMethodPayload;
  createPlannedMenu: CreatePlannedMenuPayload;
  createPrepPlan: CreatePrepPlanPayload;
  createPrepPlanMeal: CreatePrepPlanMealPayload;
  createRecipeFull: CreateRecipeFullPayload;
  createRecipeSimple: CreateRecipeSimplePayload;
  createRestaurant: CreateRestaurantPayload;
  createRestaurantBrandedFood: CreateRestaurantBrandedFoodPayload;
  createRestaurantMenu: CreateRestaurantMenuPayload;
  createRestaurantType: CreateRestaurantTypePayload;
  createReusableMenuItem: CreateReusableMenuItemPayload;
  createSchedule: CreateSchedulePayload;
  createSharedExchangeSet: CreateExchangeSetPayload;
  createSharedFoodGroup: CreateFoodGroupPayload;
  createSharedMacroMealPlanTemplate: CreateMacroMealPlanTemplatePayload;
  createSmartTag: NamedTag;
  createTeam: CreateTeamPayload;
  createTerritory: Territory;
  createUserBrandedFood: CreateBrandedFoodPayload;
  createWeightUnitServingForFood: Maybe<CreateWeightUnitServingForFoodPayload>;
  deleteAnthropometryEntry: DeleteAnthropometryEntryPayload;
  deleteAthlete: DeleteAthletePayload;
  deleteCuisine: DeleteCuisinePayload;
  deleteDiet: DeleteDietPayload;
  deleteDiningStationTemplate: DeleteDiningStationTemplatePayload;
  deleteExchangeSet: DeleteExchangeSetPayload;
  deleteGoalConfiguration: DeleteGoalConfigurationPayload;
  deleteGroceryListCustomItem: DeleteGroceryListCustomItemPayload;
  deleteGroceryListFoodItem: EditGroceryListFoodItemPayload;
  deleteInProgressPrepPlan: DeleteInProgressPrepPlanPayload;
  deleteMacroMealPlanTemplate: DeleteMacroMealPlanTemplatePayload;
  deleteMacroMealPlanTemplatePendingState: DeleteMacroMealPlanTemplatePendingStatePayload;
  deleteMealMenu: DeleteMealMenuPayload;
  deleteMealPlan: DeleteMealPlanPayload;
  deleteMealPlans: DeleteMealPlansPayload;
  deleteNote: DeleteNotePayload;
  deleteNoteType: DeleteNoteTypePayload;
  deleteOrgFood: DeleteFoodPayload;
  deleteOrgGroupMacroMealPlanTemplate: DeleteMacroMealPlanTemplatePayload;
  deleteOrgGroupMacroMealPlanTemplatePendingState: DeleteMacroMealPlanTemplatePendingStatePayload;
  deletePaymentMethod: DeletePaymentMethodPayload;
  deletePlannedMenu: DeletePlannedMenuPayload;
  deleteRecipe: DeleteRecipePayload;
  deleteRestaurantType: DeleteRestaurantTypePayload;
  deleteReusableMenuItem: DeleteReusableMenuItemPayload;
  deleteSharedExchangeSet: DeleteExchangeSetPayload;
  deleteSharedMacroMealPlanTemplate: DeleteMacroMealPlanTemplatePayload;
  deleteSharedMacroMealPlanTemplatePendingState: DeleteMacroMealPlanTemplatePendingStatePayload;
  deleteTag: DeleteTagPayload;
  deleteTeam: DeleteTeamPayload;
  deleteUserBrandedFood: DeleteUserBrandedFoodPayload;
  deprecateRestaurantBrandedFood: DeprecateRestaurantBrandedFoodPayload;
  deprecateSharedFood: DeprecateFoodPayload;
  duplicatePlannedMenu: CreatePlannedMenuPayload;
  editAgreementForm: EditAgreementFormPayload;
  /** @deprecated Field no longer supported */
  editAnthropometryEntry: EditAnthropometryEntryPayload;
  editAnthropometryEntryComment: EditAnthropometryEntryCommentPayload;
  editAthlete: EditAthletePayload;
  editAthleteDisplaySettings: EditAthleteDisplaySettingsPayload;
  editAthleteFoodLogNotificationSettings: EditAthleteFoodLogNotificationSettingsPayload;
  /** @deprecated Field no longer supported */
  editAthletes: EditAthletesPayload;
  editBrand: EditBrandPayload;
  editBrandedFood: EditBrandedFoodPayload;
  editBulkOrder: EditBulkOrderPayload;
  editClientCredential: EditClientCredentialPayload;
  editCuisine: EditCuisinePayload;
  editCustomTag: NamedTag;
  editCustomizedPrepPlanMeal: EditCustomizedPrepPlanMealPayload;
  editDeliveryLocation: EditDeliveryLocationPayload;
  editDiet: EditDietPayload;
  editDiningStationTemplate: EditDiningStationTemplatePayload;
  editExchangeMealTemplate: EditExchangeMealTemplatePayload;
  editExchangeMealTemplates: EditExchangeMealTemplatesPayload;
  editExchangeSet: EditExchangeSetPayload;
  editFoodLogActivityRpe: EditFoodLogActivityRpePayload;
  editFoodLogActivityTemplate: EditFoodLogActivityTemplatePayload;
  editFoodLogActivityTimeWithTimezone: EditFoodLogActivityTimeWithTimezonePayload;
  editFoodLogMealServingAmounts: EditFoodLogMealServingAmountsPayload;
  editFoodLogMealTemplate: EditFoodLogMealTemplatePayload;
  editFoodLogMealTimeWithTimezone: EditFoodLogMealTimeWithTimezonePayload;
  editFoodServing: EditFoodServingPayload;
  editGenericFood: EditGenericFoodPayload;
  editGoalConfigurationWithDaysOfWeek: EditGoalConfigurationPayload;
  editGoalConfigurationWithIndividualDates: EditGoalConfigurationPayload;
  editGroceryList: Maybe<EditGroceryListPayload>;
  editGroceryListCustomItem: EditGroceryListCustomItemPayload;
  editGroceryListFoodItem: EditGroceryListFoodItemPayload;
  editMacroMealPlanTemplate: EditMacroMealPlanTemplatePayload;
  editMacroMealTemplate: EditMacroMealTemplatePayload;
  editMacroMealTemplates: EditMacroMealTemplatesPayload;
  editMacroProtocol: EditMacroProtocolPayload;
  editMacroProtocolWithNewGoal: EditMacroProtocolWithNewGoalPayload;
  editMacroProtocolWithOldGoal: EditMacroProtocolWithOldGoalPayload;
  editMealMenu: EditMealMenuPayload;
  editMealMenuLogItem: EditMealMenuLogItemPayload;
  editMealMenuWithIdentity: EditMealMenuWithIdentityPayload;
  editMealOption: EditMealOptionPayload;
  editMealOptionPending: EditMealOptionPendingPayload;
  editMealPlanCalendar: EditMealPlanCalendarPayload;
  editMealPlanDate: EditMealPlanDatePayload;
  editMealPlanDateForAthlete: EditMealPlanDateForAthletePayload;
  editMealPlanExchangeTargets: EditMealPlanExchangeTargetsPayload;
  editMealPlanIsShared: EditMealPlanIsSharedPayload;
  editMealPlanNew: EditMealPlanPayload;
  editMealPlansIsShared: EditMealPlansIsSharedPayload;
  editMemberships: EditOrgMembershipsPayload;
  editMenuItemImage: EditMenuItemImagePayload;
  editMenuOrder: EditMenuOrderPayload;
  editMenuOrderMealTemplate: EditMenuOrderMealTemplatePayload;
  editMetricAnthropometryEntries: EditAnthropometryEntriesPayload;
  editMetricAnthropometryEntry: EditAnthropometryEntryPayload;
  editMetricAnthropometryEntryWeighInForDate: EditAnthropometryEntryWeighInForDatePayload;
  editMetricAnthropometryEntryWeighInForDateForAthlete: EditAnthropometryEntryWeighInForDateForAthletePayload;
  editMetricAthletes: EditAthletesPayload;
  editMetricMacroProtocol: EditMacroProtocolPayload;
  editNote: EditNotePayload;
  editNoteType: EditNoteTypePayload;
  editOrg: EditOrgPayload;
  editOrgAutoShareNewNotemealRecipes: EditOrgAutoShareNewNotemealRecipesPayload;
  editOrgFeatureFlags: EditOrgFeatureFlagsPayload;
  editOrgFoodGroup: EditFoodGroupPayload;
  editOrgFoodServing: EditFoodServingPayload;
  editOrgGenericFood: EditGenericFoodPayload;
  editOrgGroup: EditOrgGroupPayload;
  editOrgGroupMacroMealPlanTemplate: EditMacroMealPlanTemplatePayload;
  editOrgTeamworksProfiles: EditOrgTeamworksProfilesPayload;
  editPaymentMethod: EditPaymentMethodPayload;
  editPlannedMenu: EditPlannedMenuPayload;
  editPrepPlan: EditPrepPlanPayload;
  editPrepPlanMeal: EditPrepPlanMealPayload;
  editRecipeFull: EditRecipeFullPayload;
  editRecipeIsShared: EditRecipeIsSharedPayload;
  editRecipePartial: EditRecipePartialPayload;
  editRecipeSimple: EditRecipeSimplePayload;
  editRestaurant: EditRestaurantPayload;
  editRestaurantBrandedFood: EditRestaurantBrandedFoodPayload;
  editRestaurantLocation: EditRestaurantLocationPayload;
  editRestaurantLocationContact: EditRestaurantLocationContactPayload;
  editRestaurantMenu: EditRestaurantMenuPayload;
  editRestaurantMenuLink: EditRestaurantMenuLinkPayload;
  editRestaurantMenuLinkOrder: EditRestaurantMenuLinkOrderPayload;
  editRestaurantMenuLinkOrderMealTemplate: EditRestaurantMenuLinkOrderMealTemplatePayload;
  editRestaurantMenuLinkPlateOrderMealTemplate: EditRestaurantMenuLinkPlateOrderMealTemplatePayload;
  editRestaurantMenuLog: EditRestaurantMenuLogPayload;
  editRestaurantMenuLogItem: EditRestaurantMenuLogItemPayload;
  editRestaurantMenuLogMealTemplate: EditRestaurantMenuLogMealTemplatePayload;
  editRestaurantType: EditRestaurantTypePayload;
  editReusableMenuItem: EditReusableMenuItemPayload;
  editReusableMenuItemPartial: EditReusableMenuItemPartialPayload;
  editSchedule: EditSchedulePayload;
  editSharedExchangeSet: EditExchangeSetPayload;
  editSharedFoodGroup: EditFoodGroupPayload;
  editSharedMacroMealPlanTemplate: EditMacroMealPlanTemplatePayload;
  editSmartTag: NamedTag;
  editSyncedAthlete: EditAthletePayload;
  /** @deprecated Field no longer supported */
  editSyncedAthletes: EditAthletesPayload;
  editSyncedMetricAthletes: EditAthletesPayload;
  editTeam: EditTeamPayload;
  editTeamDisplaySettings: EditTeamDisplaySettingsPayload;
  editTeamFoodLogNotificationSettings: EditTeamFoodLogNotificationSettingsPayload;
  editTeamForStaff: EditTeamPayload;
  editTeamLabel: EditTeamLabelPayload;
  editTerritory: Territory;
  editTimelineMealDetails: EditTimelineMealDetailsPayload;
  editTimelineMealMealTemplate: EditTimelineMealMealTemplatePayload;
  editTimelineMealServingAmount: EditTimelineMealServingAmountPayload;
  editUserBrandedFood: EditUserBrandedFoodPayload;
  editUserTeamworksIdToOverwrite: EditUserTeamworksIdToOverwritePayload;
  finalizePrepPlan: FinalizePrepPlanPayload;
  forceLockPlannedMenu: ForceLockPlannedMenuPayload;
  getPossibleGPTRecipeByName: GptRecipePayload;
  getPossibleGPTRecipeFromImage: GptRecipePayload;
  /** @deprecated mobile-use only; remove once force-update available in react-native app */
  getPossibleGPTRecipeFromUrl: GptRecipePayload;
  importBonAppetitMenus: ImportBonAppetitMenusPayload;
  importCbordMenus: ImportCbordMenusPayload;
  importCompassMenus: ImportCompassMenusPayload;
  importCompassRecipe: ImportCompassRecipeInputPayload;
  importPlannedMenu: ImportPlannedMenuPayload;
  importSodexoMenus: ImportSodexoMenusPayload;
  linkTeamworksOrg: LinkTeamworksOrgPayload;
  lockPlannedMenu: LockPlannedMenuPayload;
  markAllNotificationsAsViewed: MarkAllNotificationsAsViewedPayload;
  markNotificationsAsViewed: MarkNotificationsAsViewedPayload;
  markNotificationsAsViewedBefore: MarkNotificationsAsViewedBeforePayload;
  moveMacroMealPlanTemplateToOrgGroup: MoveMacroMealPlanTemplateToOrgGroupPayload;
  moveOrgFoodToOrgGroup: MoveOrgFoodToOrgGroupPayload;
  moveOrgGroupFoodToMyOrg: MoveOrgGroupFoodToMyOrgPayload;
  moveOrgGroupMacroMealPlanTemplateToOrg: MoveOrgGroupMacroMealPlanTemplateToOrgPayload;
  moveOrgRecipeToOrgGroup: MoveOrgRecipeToOrgGroupPayload;
  overrideMealMenuIdentity: OverrideMealMenuIdentityPayload;
  publishMacroMealPlanTemplate: PublishMacroMealPlanTemplatePayload;
  publishOrgGroupMacroMealPlanTemplate: PublishMacroMealPlanTemplatePayload;
  publishSharedMacroMealPlanTemplate: PublishMacroMealPlanTemplatePayload;
  recieveAthleteTransfer: RecieveAthleteTransferPayload;
  reclaimAthleteTransfer: ReclaimAthleteTransferPayload;
  recordMenuOrderSuggestionDecision: Scalars['Boolean'];
  recordMenuOrderSuggestionsView: Scalars['Boolean'];
  refreshClientCredentialSecret: RefreshClientCredentialSecretPayload;
  refreshTeamworksSyncStatus: RefreshTeamworksSyncStatusPayload;
  removeAthleteDislikedFood: RemoveAthleteDislikedFoodPayload;
  removeAthleteDislikedFoodGroup: RemoveAthleteDislikedFoodGroupPayload;
  removeAthleteLikedFood: RemoveAthleteLikedFoodPayload;
  removeAthleteLikedFoodGroup: RemoveAthleteLikedFoodGroupPayload;
  removeBulkOrder: RemoveBulkOrderPayload;
  removeFoodLogActivity: RemoveFoodLogActivityPayload;
  removeFoodLogActivityTemplate: RemoveFoodLogActivityTemplatePayload;
  removeFoodLogMeal: RemoveFoodLogMealPayload;
  removeFoodLogMealImage: RemoveFoodLogMealImagePayload;
  removeFoodLogMealTemplate: RemoveFoodLogMealTemplatePayload;
  removeMealMenuLogItem: RemoveMealMenuLogItemPayload;
  removeMealOption: RemoveMealOptionPayload;
  removeMealOptionPending: RemoveMealOptionPendingPayload;
  removeMealPlanDate: RemoveMealPlanDatePayload;
  removeMealPlanDateForAthlete: RemoveMealPlanDateForAthletePayload;
  removeMenuOrder: RemoveMenuOrderPayload;
  removeMenuOrderImage: RemoveMenuOrderImagePayload;
  removeMenuOrderMealTemplate: RemoveMenuOrderMealTemplatePayload;
  removeMyDislikedFood: RemoveAthleteDislikedFoodPayload;
  removeMyDislikedFoodGroup: RemoveAthleteDislikedFoodGroupPayload;
  removeMyLikedFood: RemoveAthleteLikedFoodPayload;
  removeMyLikedFoodGroup: RemoveAthleteLikedFoodGroupPayload;
  removePrepPlanMeal: RemovePrepPlanMealPayload;
  removeRecipeImage: RemoveRecipeImagePayload;
  removeRestaurantMenuLink: RemoveRestaurantMenuLinkPayload;
  removeRestaurantMenuLinkOrder: RemoveRestaurantMenuLinkOrderPayload;
  removeRestaurantMenuLinkOrderMealTemplate: RemoveRestaurantMenuLinkOrderMealTemplatePayload;
  removeRestaurantMenuLinkPlateOrder: RemoveRestaurantMenuLinkPlateOrderPayload;
  removeRestaurantMenuLinkPlateOrderMealTemplate: RemoveRestaurantMenuLinkPlateOrderMealTemplatePayload;
  removeRestaurantMenuLog: RemoveRestaurantMenuLogPayload;
  removeRestaurantMenuLogItem: RemoveRestaurantMenuLogItemPayload;
  removeRestaurantMenuLogMealTemplate: RemoveRestaurantMenuLogMealTemplatePayload;
  removeTimelineMealImage: RemoveTimelineMealImagePayload;
  removeTimelineMealServingAmount: RemoveTimelineMealServingAmountPayload;
  runMaintainTeamworksFullySyncedJob: RefreshTeamworksSyncStatusPayload;
  savePlannedMeals: SavePlannedMealsPayload;
  sendMealMenuNotification: Maybe<Scalars['Boolean']>;
  setGoalTrackerGoal: SetGoalTrackerGoalPayload;
  setMealMenuCheckInsForAthlete: SetMealMenuCheckInsPayload;
  setMealMenuLogItemPercentConsumed: SetMealMenuLogItemPercentConsumedPayload;
  setMealOptionsForMealTemplates: SetMealOptionsForMealTemplatesPayload;
  setMenuOrderItemPercentConsumed: SetMenuOrderItemPercentConsumedPayload;
  setPrepPlanGuidedBuilder: SetPrepPlanGuidedBuilderPayload;
  setPrepPlanMealAssignmentSlots: SetPrepPlanMealAssignmentSlotsPayload;
  setRestaurantMenuLinkOrderItemPercentConsumed: SetRestaurantMenuLinkOrderItemPercentConsumedPayload;
  setRestaurantMenuLinkPlateOrderItemPercentConsumed: SetRestaurantMenuLinkPlateOrderItemPercentConsumedPayload;
  setRestaurantMenuLogItemPercentConsumed: SetRestaurantMenuLogItemPercentConsumedPayload;
  setTimelineMealServingAmountsForAthlete: SetTimelineMealServingAmountsPayload;
  setTimelineMealServingAmountsForMe: SetTimelineMealServingAmountsPayload;
  shareMealOptionPending: ShareMealOptionPendingPayload;
  syncStreamChatDevices: Maybe<Scalars['Boolean']>;
  textMealPlanToAthlete: TextMealPlanToAthletePayload;
  textMealPlansToAthletes: TextMealPlansToAthletesPayload;
  unarchiveAthlete: UnarchiveAthletePayload;
  unlockPlannedMenu: UnlockPlannedMenuPayload;
  updateApnCountForUser: UpdateApnCountForUserPayload;
  updateBulkOrderStatus: UpdateBulkOrderStatusPayload;
  updateMacroMealPlanTemplateAssignScope: UpdateMealPlanTemplateAssignScopePayload;
  updateMacroMealPlanTemplateSharingScope: UpdateMacroMealPlanTemplateSharingScopePayload;
  updateMealMenuPlateItemOrderStatus: UpdateMealMenuPlateItemOrderStatusPayload;
  updateMealMenuPlateOrderStatus: UpdateMealMenuPlateOrderStatusPayload;
  updateMealMenuPlateOrderStatuses: Array<UpdateMealMenuPlateOrderStatusPayload>;
  updateMenuOrderItemStatuses: UpdateMenuOrderItemStatusesPayload;
  updateMultipleMealMenuPlateItemOrderStatuses: UpdateMultipleMealMenuPlateItemOrderStatusesPayload;
  updateMultipleMenuOrderItemStatuses: UpdateMultipleMenuOrderItemStatusesPayload;
  updateSharedMacroMealPlanTemplateSharingScope: UpdateMacroMealPlanTemplateSharingScopePayload;
};


export type MutationAddAgreementFormArgs = {
  input: AddAgreementFormInput;
};


export type MutationAddAgreementFormSubmissionArgs = {
  input: AddAgreementFormSubmissionInput;
};


export type MutationAddAndLinkTeamworksTeamsArgs = {
  input: AddAndLinkTeamworksTeamsInput;
};


export type MutationAddAthleteDislikedFoodArgs = {
  input: AthleteFoodPreferenceInput;
};


export type MutationAddAthleteDislikedFoodGroupArgs = {
  input: AthleteFoodGroupPreferenceInput;
};


export type MutationAddAthleteEmailArgs = {
  input: AddAthleteEmailInput;
};


export type MutationAddAthleteLikedFoodArgs = {
  input: AthleteFoodPreferenceInput;
};


export type MutationAddAthleteLikedFoodGroupArgs = {
  input: AthleteFoodGroupPreferenceInput;
};


export type MutationAddAthletePhoneNumberArgs = {
  input: AddAthletePhoneNumberInput;
};


export type MutationAddBulkOrderArgs = {
  input: AddBulkOrderInput;
};


export type MutationAddDeliveryLocationArgs = {
  input: AddDeliveryLocationInput;
};


export type MutationAddDeviceArgs = {
  input: AddDeviceInput;
};


export type MutationAddEventArgs = {
  input: AddEventInput;
};


export type MutationAddFoodItemToGroceryListArgs = {
  input: AddFoodItemToGroceryListInput;
};


export type MutationAddFoodLogActivityWithTemplateArgs = {
  input: AddFoodLogActivityWithTemplateInput;
};


export type MutationAddFoodLogActivityWithTemplateForAthleteArgs = {
  input: AddFoodLogActivityWithTemplateForAthleteInput;
};


export type MutationAddFoodLogActivityWithoutTemplateArgs = {
  input: AddFoodLogActivityWithoutTemplateInput;
};


export type MutationAddFoodLogActivityWithoutTemplateForAthleteArgs = {
  input: AddFoodLogActivityWithoutTemplateForAthleteInput;
};


export type MutationAddFoodLogMealCommentArgs = {
  input: AddFoodLogMealCommentInput;
};


export type MutationAddFoodLogMealImageArgs = {
  input: AddFoodLogMealImageInput;
};


export type MutationAddFoodLogMealWithTemplateArgs = {
  input: AddFoodLogMealWithTemplateInput;
};


export type MutationAddFoodLogMealWithTemplateForAthleteArgs = {
  input: AddFoodLogMealWithTemplateForAthleteInput;
};


export type MutationAddFoodLogMealWithoutTemplateArgs = {
  input: AddFoodLogMealWithoutTemplateInput;
};


export type MutationAddFoodLogMealWithoutTemplateForAthleteArgs = {
  input: AddFoodLogMealWithoutTemplateForAthleteInput;
};


export type MutationAddMealMenuLogItemArgs = {
  input: AddMealMenuLogItemInput;
};


export type MutationAddMealMenuLogItemForAthleteArgs = {
  input: AddMealMenuLogItemForAthleteInput;
};


export type MutationAddMealMenuLogItemsArgs = {
  input: AddMealMenuLogItemsInput;
};


export type MutationAddMealMenuLogItemsForAthleteArgs = {
  input: AddMealMenuLogItemsForAthleteInput;
};


export type MutationAddMealOptionArgs = {
  input: AddMealOptionInput;
};


export type MutationAddMenuItemHistoryMissingIngredientsArgs = {
  input: AddMenuItemHistoryMissingIngredientsInput;
};


export type MutationAddMenuOrderCommentArgs = {
  input: AddMenuOrderCommentInput;
};


export type MutationAddMenuOrderForAthleteArgs = {
  input: AddMenuOrderForAthleteInput;
};


export type MutationAddMenuOrderImageArgs = {
  input: AddMenuOrderImageInput;
};


export type MutationAddMenuOrderItemRatingsArgs = {
  input: AddMenuOrderItemRatingsInput;
};


export type MutationAddMenuOrderWithTemplateArgs = {
  input: AddMenuOrderWithTemplateInput;
};


export type MutationAddMenuOrderWithoutTemplateArgs = {
  input: AddMenuOrderWithoutTemplateInput;
};


export type MutationAddMyDislikedFoodArgs = {
  input: MyFoodPreferenceInput;
};


export type MutationAddMyDislikedFoodGroupArgs = {
  input: MyFoodGroupPreferenceInput;
};


export type MutationAddMyLikedFoodArgs = {
  input: MyFoodPreferenceInput;
};


export type MutationAddMyLikedFoodGroupArgs = {
  input: MyFoodGroupPreferenceInput;
};


export type MutationAddNixBrandedFoodArgs = {
  input: AddNixBrandedFoodInput;
};


export type MutationAddNixBrandedFoodByUpcArgs = {
  input: AddNixBrandedFoodByUpcInput;
};


export type MutationAddNixCommonFoodArgs = {
  input: AddNixCommonFoodInput;
};


export type MutationAddPositionArgs = {
  input: AddPositionInput;
};


export type MutationAddRecipeImageArgs = {
  input: AddRecipeImageInput;
};


export type MutationAddRestaurantLocationArgs = {
  input: AddRestaurantLocationInput;
};


export type MutationAddRestaurantLocationContactArgs = {
  input: AddRestaurantLocationContactInput;
};


export type MutationAddRestaurantMenuLinkArgs = {
  input: AddRestaurantMenuLinkInput;
};


export type MutationAddRestaurantMenuLinkOrderArgs = {
  input: AddRestaurantMenuLinkOrderInput;
};


export type MutationAddRestaurantMenuLinkOrderForAthleteArgs = {
  input: AddRestaurantMenuLinkOrderForAthleteInput;
};


export type MutationAddRestaurantMenuLinkPlateOrderArgs = {
  input: AddRestaurantMenuLinkPlateOrderInput;
};


export type MutationAddRestaurantMenuLinkPlateOrderForAthleteArgs = {
  input: AddRestaurantMenuLinkPlateOrderForAthleteInput;
};


export type MutationAddRestaurantMenuLogArgs = {
  input: AddRestaurantMenuLogInput;
};


export type MutationAddRestaurantMenuLogForAthleteArgs = {
  input: AddRestaurantMenuLogForAthleteInput;
};


export type MutationAddRestaurantMenuLogItemArgs = {
  input: AddRestaurantMenuLogItemInput;
};


export type MutationAddRestaurantMenuLogItemForAthleteArgs = {
  input: AddRestaurantMenuLogItemForAthleteInput;
};


export type MutationAddRestaurantMenuLogItemsArgs = {
  input: AddRestaurantMenuLogItemsInput;
};


export type MutationAddRestaurantMenuLogItemsForAthleteArgs = {
  input: AddRestaurantMenuLogItemsForAthleteInput;
};


export type MutationAddSportArgs = {
  input: AddSportInput;
};


export type MutationAddTeamLabelArgs = {
  input: AddTeamLabelInput;
};


export type MutationAddTimelineMealCommentForAthleteArgs = {
  input: AddTimelineMealCommentForAthleteInput;
};


export type MutationAddTimelineMealCommentForMeArgs = {
  input: AddTimelineMealCommentForMeInput;
};


export type MutationAddTimelineMealImageForAthleteArgs = {
  input: AddTimelineMealImageForAthleteInput;
};


export type MutationAddTimelineMealImageForMeArgs = {
  input: AddTimelineMealImageInput;
};


export type MutationAddTimelineMealServingAmountForAthleteArgs = {
  input: AddTimelineMealServingAmountForAthleteInput;
};


export type MutationAddTimelineMealServingAmountForMeArgs = {
  input: AddTimelineMealServingAmountInput;
};


export type MutationArchiveAthleteArgs = {
  input: ArchiveAthleteInput;
};


export type MutationArchiveGoalConfigurationArgs = {
  input: ArchiveGoalConfigurationInput;
};


export type MutationAssignMealPlanTemplateToAthleteArgs = {
  input: AssignMealPlanTemplateToAthleteInput;
};


export type MutationAssignMetricMealPlanTemplateToAthleteArgs = {
  input: AssignMetricMealPlanTemplateToAthleteInput;
};


export type MutationBulkCopyMealPlanNewArgs = {
  input: BulkCopyMealPlanNewInput;
};


export type MutationBulkDeleteMealMenusArgs = {
  input: BulkDeleteMealMenusInput;
};


export type MutationBulkEditMealMenusArgs = {
  input: BulkEditMealMenusInput;
};


export type MutationBulkImportCreateAndDeprecateGenericFoodsArgs = {
  input: BulkImportCreateAndDeprecateGenericFoodsInput;
};


export type MutationBulkImportCreateGenericFoodsArgs = {
  input: BulkImportCreateGenericFoodsInput;
};


export type MutationBulkImportCreateRestaurantBrandedFoodArgs = {
  input: BulkImportCreateRestautantBrandedFoodsInput;
};


export type MutationBulkImportEditRestaurantBrandedFoodArgs = {
  input: BulkImportEditRestautantBrandedFoodsInput;
};


export type MutationCheckGroceryListCustomItemArgs = {
  input: CheckGroceryListCustomItemInput;
};


export type MutationCheckGroceryListFoodItemArgs = {
  input: CheckGroceryListFoodItemInput;
};


export type MutationCopyExchangeSetArgs = {
  input: CopyExchangeSetInput;
};


export type MutationCopyMacroMealPlanTemplateArgs = {
  input: CopyMacroMealPlanTemplateInput;
};


export type MutationCopyMealMenusArgs = {
  input: CopyMealMenusInput;
};


export type MutationCopyMealPlanNewArgs = {
  input: CopyMealPlanNewInput;
};


export type MutationCopyPrepPlanArgs = {
  input: CopyPrepPlanInput;
};


export type MutationCopySharedExchangeSetArgs = {
  input: CopyExchangeSetInput;
};


export type MutationCopySharedMacroMealPlanTemplateArgs = {
  input: CopyMacroMealPlanTemplateInput;
};


export type MutationCreateAthleteArgs = {
  input: CreateAthleteInput;
};


export type MutationCreateAthleteTransferArgs = {
  input: CreateAthleteTransferInput;
};


export type MutationCreateAthletesArgs = {
  input: Array<CreateAthleteInput>;
};


export type MutationCreateBrandArgs = {
  input: CreateBrandInput;
};


export type MutationCreateBrandedFoodArgs = {
  input: CreateBrandedFoodInput;
};


export type MutationCreateClientCredentialArgs = {
  input: CreateClientCredentialInput;
};


export type MutationCreateCuisineArgs = {
  input: CreateCuisineInput;
};


export type MutationCreateCustomTagArgs = {
  input: CreateCustomTagInput;
};


export type MutationCreateDietArgs = {
  input: CreateDietInput;
};


export type MutationCreateDiningStationTemplateArgs = {
  input: CreateDiningStationTemplateInput;
};


export type MutationCreateExchangeSetArgs = {
  input: CreateExchangeSetInput;
};


export type MutationCreateFoodServingsArgs = {
  input: CreateFoodServingsInput;
};


export type MutationCreateGenericFoodArgs = {
  input: CreateGenericFoodInput;
};


export type MutationCreateGoalArgs = {
  input: CreateGoalInput;
};


export type MutationCreateGoalConfigurationWithDaysOfWeekArgs = {
  input: CreateGoalConfigurationWithDaysOfWeekInput;
};


export type MutationCreateGoalConfigurationWithIndividualDatesArgs = {
  input: CreateGoalConfigurationWithIndividualDatesInput;
};


export type MutationCreateGroceryListCustomItemArgs = {
  input: CreateGroceryListCustomItemInput;
};


export type MutationCreateMacroMealPlanTemplateArgs = {
  input: CreateMacroMealPlanTemplateInput;
};


export type MutationCreateMealMenusArgs = {
  input: CreateMealMenusInput;
};


export type MutationCreateMealPlanFromFoodLogsNewArgs = {
  input: CreateMealPlanFromFoodLogsNewInput;
};


export type MutationCreateMealPlanFromMealPlanTemplateArgs = {
  input: CreateMealPlanFromMealPlanTemplateInput;
};


export type MutationCreateMealPlanNewArgs = {
  input: CreateMealPlanNewInput;
};


export type MutationCreateMembershipsArgs = {
  input: CreateOrgMembershipsInput;
};


export type MutationCreateMetricAnthropometryEntriesArgs = {
  input: Array<CreateMetricAnthropometryEntryInput>;
};


export type MutationCreateMetricAnthropometryEntryArgs = {
  input: CreateMetricAnthropometryEntryInput;
};


export type MutationCreateMetricAthleteArgs = {
  input: CreateMetricAthleteInput;
};


export type MutationCreateMetricAthletesArgs = {
  input: Array<CreateMetricAthleteInput>;
};


export type MutationCreateMetricMealPlanArgs = {
  input: CreateMetricMealPlanInput;
};


export type MutationCreateMetricMealPlanFromFoodLogsArgs = {
  input: CreateMetricMealPlanFromFoodLogsInput;
};


export type MutationCreateMetricMealPlanFromMealPlanTemplateArgs = {
  input: CreateMetricMealPlanFromMealPlanTemplateInput;
};


export type MutationCreateNoteArgs = {
  input: CreateNoteInput;
};


export type MutationCreateNoteTypeArgs = {
  input: CreateNoteTypeInput;
};


export type MutationCreateOrgArgs = {
  input: CreateOrgInput;
};


export type MutationCreateOrgFoodGroupArgs = {
  input: CreateFoodGroupInput;
};


export type MutationCreateOrgFoodServingsArgs = {
  input: CreateFoodServingsInput;
};


export type MutationCreateOrgFromTeamworksOrgArgs = {
  input: CreateOrgFromTeamworksOrgInput;
};


export type MutationCreateOrgGenericFoodArgs = {
  input: CreateGenericFoodInput;
};


export type MutationCreateOrgGroupArgs = {
  input: CreateOrgGroupInput;
};


export type MutationCreateOrgMembershipForAthleteArgs = {
  input: CreateOrgMembershipForAthleteInput;
};


export type MutationCreatePaymentMethodArgs = {
  input: CreatePaymentMethodInput;
};


export type MutationCreatePlannedMenuArgs = {
  input: CreatePlannedMenuInput;
};


export type MutationCreatePrepPlanArgs = {
  input: CreatePrepPlanInput;
};


export type MutationCreatePrepPlanMealArgs = {
  input: CreatePrepPlanMealInput;
};


export type MutationCreateRecipeFullArgs = {
  input: CreateRecipeFullInput;
};


export type MutationCreateRecipeSimpleArgs = {
  input: CreateRecipeSimpleInput;
};


export type MutationCreateRestaurantArgs = {
  input: CreateRestaurantInput;
};


export type MutationCreateRestaurantBrandedFoodArgs = {
  input: CreateRestaurantBrandedFoodInput;
};


export type MutationCreateRestaurantMenuArgs = {
  input: CreateRestaurantMenuInput;
};


export type MutationCreateRestaurantTypeArgs = {
  input: CreateRestaurantTypeInput;
};


export type MutationCreateReusableMenuItemArgs = {
  input: CreateReusableMenuItemInput;
};


export type MutationCreateScheduleArgs = {
  input: CreateScheduleInput;
};


export type MutationCreateSharedExchangeSetArgs = {
  input: CreateExchangeSetInput;
};


export type MutationCreateSharedFoodGroupArgs = {
  input: CreateFoodGroupInput;
};


export type MutationCreateSharedMacroMealPlanTemplateArgs = {
  input: CreateMacroMealPlanTemplateInput;
};


export type MutationCreateSmartTagArgs = {
  input: CreateSmartTagInput;
};


export type MutationCreateTeamArgs = {
  input: CreateTeamInput;
};


export type MutationCreateTerritoryArgs = {
  input: CreateTerritoryInput;
};


export type MutationCreateUserBrandedFoodArgs = {
  input: CreateUserBrandedFoodFormInput;
};


export type MutationCreateWeightUnitServingForFoodArgs = {
  input: CreateWeightUnitServingForFoodInput;
};


export type MutationDeleteAnthropometryEntryArgs = {
  input: DeleteAnthropometryEntryInput;
};


export type MutationDeleteAthleteArgs = {
  input: DeleteAthleteInput;
};


export type MutationDeleteCuisineArgs = {
  input: DeleteCuisineInput;
};


export type MutationDeleteDietArgs = {
  input: DeleteDietInput;
};


export type MutationDeleteDiningStationTemplateArgs = {
  input: DeleteDiningStationTemplateInput;
};


export type MutationDeleteExchangeSetArgs = {
  input: DeleteExchangeSetInput;
};


export type MutationDeleteGoalConfigurationArgs = {
  input: DeleteGoalConfigurationInput;
};


export type MutationDeleteGroceryListCustomItemArgs = {
  input: DeleteGroceryListCustomItemInput;
};


export type MutationDeleteGroceryListFoodItemArgs = {
  input: DeleteGroceryListFoodItemInput;
};


export type MutationDeleteInProgressPrepPlanArgs = {
  input: DeleteInProgressPrepPlanInput;
};


export type MutationDeleteMacroMealPlanTemplateArgs = {
  input: DeleteMacroMealPlanTemplateInput;
};


export type MutationDeleteMacroMealPlanTemplatePendingStateArgs = {
  input: DeleteMacroMealPlanTemplatePendingStateInput;
};


export type MutationDeleteMealMenuArgs = {
  input: DeleteMealMenuInput;
};


export type MutationDeleteMealPlanArgs = {
  input: DeleteMealPlanInput;
};


export type MutationDeleteMealPlansArgs = {
  input: DeleteMealPlansInput;
};


export type MutationDeleteNoteArgs = {
  input: DeleteNoteInput;
};


export type MutationDeleteNoteTypeArgs = {
  input: DeleteNoteTypeInput;
};


export type MutationDeleteOrgFoodArgs = {
  input: DeleteFoodInput;
};


export type MutationDeleteOrgGroupMacroMealPlanTemplateArgs = {
  input: DeleteMacroMealPlanTemplateInput;
};


export type MutationDeleteOrgGroupMacroMealPlanTemplatePendingStateArgs = {
  input: DeleteMacroMealPlanTemplatePendingStateInput;
};


export type MutationDeletePaymentMethodArgs = {
  input: DeletePaymentMethodInput;
};


export type MutationDeletePlannedMenuArgs = {
  input: DeletePlannedMenuInput;
};


export type MutationDeleteRecipeArgs = {
  input: DeleteRecipeInput;
};


export type MutationDeleteRestaurantTypeArgs = {
  input: DeleteRestaurantTypeInput;
};


export type MutationDeleteReusableMenuItemArgs = {
  input: DeleteReusableMenuItemInput;
};


export type MutationDeleteSharedExchangeSetArgs = {
  input: DeleteExchangeSetInput;
};


export type MutationDeleteSharedMacroMealPlanTemplateArgs = {
  input: DeleteMacroMealPlanTemplateInput;
};


export type MutationDeleteSharedMacroMealPlanTemplatePendingStateArgs = {
  input: DeleteMacroMealPlanTemplatePendingStateInput;
};


export type MutationDeleteTagArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteTeamArgs = {
  input: DeleteTeamInput;
};


export type MutationDeleteUserBrandedFoodArgs = {
  input: DeleteUserBrandedFoodInput;
};


export type MutationDeprecateRestaurantBrandedFoodArgs = {
  input: DeprecateRestaurantBrandedFoodInput;
};


export type MutationDeprecateSharedFoodArgs = {
  input: DeprecateFoodInput;
};


export type MutationDuplicatePlannedMenuArgs = {
  input: DuplicatePlannedMenuInput;
};


export type MutationEditAgreementFormArgs = {
  input: EditAgreementFormInput;
};


export type MutationEditAnthropometryEntryArgs = {
  input: EditAnthropometryEntryInput;
};


export type MutationEditAnthropometryEntryCommentArgs = {
  input: EditAnthropometryEntryCommentInput;
};


export type MutationEditAthleteArgs = {
  input: EditAthleteInput;
};


export type MutationEditAthleteDisplaySettingsArgs = {
  input: EditAthleteDisplaySettingsInput;
};


export type MutationEditAthleteFoodLogNotificationSettingsArgs = {
  input: EditAthleteFoodLogNotificationSettingsInput;
};


export type MutationEditAthletesArgs = {
  input: Array<EditAthleteAndAnthroInput>;
};


export type MutationEditBrandArgs = {
  input: EditBrandInput;
};


export type MutationEditBrandedFoodArgs = {
  input: EditBrandedFoodInput;
};


export type MutationEditBulkOrderArgs = {
  input: EditBulkOrderInput;
};


export type MutationEditClientCredentialArgs = {
  input: EditClientCredentialInput;
};


export type MutationEditCuisineArgs = {
  input: EditCuisineInput;
};


export type MutationEditCustomTagArgs = {
  input: EditCustomTagInput;
};


export type MutationEditCustomizedPrepPlanMealArgs = {
  input: EditCustomizedPrepPlanMealInput;
};


export type MutationEditDeliveryLocationArgs = {
  input: EditDeliveryLocationInput;
};


export type MutationEditDietArgs = {
  input: EditDietInput;
};


export type MutationEditDiningStationTemplateArgs = {
  input: EditDiningStationTemplateInput;
};


export type MutationEditExchangeMealTemplateArgs = {
  input: EditExchangeMealTemplateInput;
};


export type MutationEditExchangeMealTemplatesArgs = {
  input: Array<EditExchangeMealTemplateInput>;
};


export type MutationEditExchangeSetArgs = {
  input: EditExchangeSetInput;
};


export type MutationEditFoodLogActivityRpeArgs = {
  input: EditFoodLogActivityRpeInput;
};


export type MutationEditFoodLogActivityTemplateArgs = {
  input: EditFoodLogActivityTemplateInput;
};


export type MutationEditFoodLogActivityTimeWithTimezoneArgs = {
  input: EditFoodLogActivityTimeWithTimezoneInput;
};


export type MutationEditFoodLogMealServingAmountsArgs = {
  input: EditFoodLogMealServingAmountsInput;
};


export type MutationEditFoodLogMealTemplateArgs = {
  input: EditFoodLogMealTemplateInput;
};


export type MutationEditFoodLogMealTimeWithTimezoneArgs = {
  input: EditFoodLogMealTimeWithTimezoneInput;
};


export type MutationEditFoodServingArgs = {
  input: EditFoodServingInput;
};


export type MutationEditGenericFoodArgs = {
  input: EditGenericFoodInput;
};


export type MutationEditGoalConfigurationWithDaysOfWeekArgs = {
  input: EditGoalConfigurationWithDaysOfWeekInput;
};


export type MutationEditGoalConfigurationWithIndividualDatesArgs = {
  input: EditGoalConfigurationWithIndividualDatesInput;
};


export type MutationEditGroceryListArgs = {
  input: EditGroceryListInput;
};


export type MutationEditGroceryListCustomItemArgs = {
  input: EditGroceryListCustomItemInput;
};


export type MutationEditGroceryListFoodItemArgs = {
  input: EditGroceryListFoodItemInput;
};


export type MutationEditMacroMealPlanTemplateArgs = {
  input: MacroMealPlanTemplatePendingStateInput;
};


export type MutationEditMacroMealTemplateArgs = {
  input: EditMacroMealTemplateInput;
};


export type MutationEditMacroMealTemplatesArgs = {
  input: Array<EditMacroMealTemplateInput>;
};


export type MutationEditMacroProtocolArgs = {
  input: EditMacroProtocolInput;
};


export type MutationEditMacroProtocolWithNewGoalArgs = {
  input: EditMacroProtocolWithNewGoalInput;
};


export type MutationEditMacroProtocolWithOldGoalArgs = {
  input: EditMacroProtocolWithOldGoalInput;
};


export type MutationEditMealMenuArgs = {
  input: EditMealMenuInput;
};


export type MutationEditMealMenuLogItemArgs = {
  input: EditMealMenuLogItemInput;
};


export type MutationEditMealMenuWithIdentityArgs = {
  input: EditMealMenuWithIdentityInput;
};


export type MutationEditMealOptionArgs = {
  input: EditMealOptionInput;
};


export type MutationEditMealOptionPendingArgs = {
  input: EditMealOptionPendingInput;
};


export type MutationEditMealPlanCalendarArgs = {
  input: EditMealPlanCalendarInput;
};


export type MutationEditMealPlanDateArgs = {
  input: EditMealPlanDateInput;
};


export type MutationEditMealPlanDateForAthleteArgs = {
  input: EditMealPlanDateForAthleteInput;
};


export type MutationEditMealPlanExchangeTargetsArgs = {
  input: EditMealPlanExchangeTargetsInput;
};


export type MutationEditMealPlanIsSharedArgs = {
  input: EditMealPlanIsSharedInput;
};


export type MutationEditMealPlanNewArgs = {
  input: EditMealPlanNewInput;
};


export type MutationEditMealPlansIsSharedArgs = {
  input: EditMealPlansIsSharedInput;
};


export type MutationEditMembershipsArgs = {
  input: EditOrgMembershipsInput;
};


export type MutationEditMenuItemImageArgs = {
  input: EditMenuItemImageInput;
};


export type MutationEditMenuOrderArgs = {
  input: EditMenuOrderInput;
};


export type MutationEditMenuOrderMealTemplateArgs = {
  input: EditMenuOrderMealTemplateInput;
};


export type MutationEditMetricAnthropometryEntriesArgs = {
  input: Array<EditMetricAnthropometryEntryInput>;
};


export type MutationEditMetricAnthropometryEntryArgs = {
  input: EditMetricAnthropometryEntryInput;
};


export type MutationEditMetricAnthropometryEntryWeighInForDateArgs = {
  input: EditMetricAnthropometryEntryWeighInForDateInput;
};


export type MutationEditMetricAnthropometryEntryWeighInForDateForAthleteArgs = {
  input: EditMetricAnthropometryEntryWeighInForDateForAthleteInput;
};


export type MutationEditMetricAthletesArgs = {
  input: Array<EditAthleteAndMetricAnthroInput>;
};


export type MutationEditMetricMacroProtocolArgs = {
  input: EditMetricMacroProtocolInput;
};


export type MutationEditNoteArgs = {
  input: EditNoteInput;
};


export type MutationEditNoteTypeArgs = {
  input: EditNoteTypeInput;
};


export type MutationEditOrgArgs = {
  input: EditOrgInput;
};


export type MutationEditOrgAutoShareNewNotemealRecipesArgs = {
  input: EditOrgAutoShareNewNotemealRecipesInput;
};


export type MutationEditOrgFeatureFlagsArgs = {
  input: EditOrgFeatureFlagsInput;
};


export type MutationEditOrgFoodGroupArgs = {
  input: EditFoodGroupInput;
};


export type MutationEditOrgFoodServingArgs = {
  input: EditFoodServingInput;
};


export type MutationEditOrgGenericFoodArgs = {
  input: EditGenericFoodInput;
};


export type MutationEditOrgGroupArgs = {
  input: EditOrgGroupInput;
};


export type MutationEditOrgGroupMacroMealPlanTemplateArgs = {
  input: MacroMealPlanTemplatePendingStateInput;
};


export type MutationEditOrgTeamworksProfilesArgs = {
  input: EditOrgTeamworksProfilesInput;
};


export type MutationEditPaymentMethodArgs = {
  input: EditPaymentMethodInput;
};


export type MutationEditPlannedMenuArgs = {
  input: EditPlannedMenuInput;
};


export type MutationEditPrepPlanArgs = {
  input: EditPrepPlanInput;
};


export type MutationEditPrepPlanMealArgs = {
  input: EditPrepPlanMealInput;
};


export type MutationEditRecipeFullArgs = {
  input: EditRecipeFullInput;
};


export type MutationEditRecipeIsSharedArgs = {
  input: EditRecipeIsSharedInput;
};


export type MutationEditRecipePartialArgs = {
  input: EditRecipePartialInput;
};


export type MutationEditRecipeSimpleArgs = {
  input: EditRecipeSimpleInput;
};


export type MutationEditRestaurantArgs = {
  input: EditRestaurantInput;
};


export type MutationEditRestaurantBrandedFoodArgs = {
  input: EditRestaurantBrandedFoodInput;
};


export type MutationEditRestaurantLocationArgs = {
  input: EditRestaurantLocationInput;
};


export type MutationEditRestaurantLocationContactArgs = {
  input: EditRestaurantLocationContactInput;
};


export type MutationEditRestaurantMenuArgs = {
  input: EditRestaurantMenuInput;
};


export type MutationEditRestaurantMenuLinkArgs = {
  input: EditRestaurantMenuLinkInput;
};


export type MutationEditRestaurantMenuLinkOrderArgs = {
  input: EditRestaurantMenuLinkOrderInput;
};


export type MutationEditRestaurantMenuLinkOrderMealTemplateArgs = {
  input: EditRestaurantMenuLinkOrderMealTemplateInput;
};


export type MutationEditRestaurantMenuLinkPlateOrderMealTemplateArgs = {
  input: EditRestaurantMenuLinkPlateOrderMealTemplateInput;
};


export type MutationEditRestaurantMenuLogArgs = {
  input: EditRestaurantMenuLogInput;
};


export type MutationEditRestaurantMenuLogItemArgs = {
  input: EditRestaurantMenuLogItemInput;
};


export type MutationEditRestaurantMenuLogMealTemplateArgs = {
  input: EditRestaurantMenuLogMealTemplateInput;
};


export type MutationEditRestaurantTypeArgs = {
  input: EditRestaurantTypeInput;
};


export type MutationEditReusableMenuItemArgs = {
  input: EditReusableMenuItemInput;
};


export type MutationEditReusableMenuItemPartialArgs = {
  input: EditReusableMenuItemPartialInput;
};


export type MutationEditScheduleArgs = {
  input: EditScheduleInput;
};


export type MutationEditSharedExchangeSetArgs = {
  input: EditExchangeSetInput;
};


export type MutationEditSharedFoodGroupArgs = {
  input: EditFoodGroupInput;
};


export type MutationEditSharedMacroMealPlanTemplateArgs = {
  input: MacroMealPlanTemplatePendingStateInput;
};


export type MutationEditSmartTagArgs = {
  input: EditSmartTagInput;
};


export type MutationEditSyncedAthleteArgs = {
  input: EditSyncedAthleteInput;
};


export type MutationEditSyncedAthletesArgs = {
  input: Array<EditSyncedAthleteAndAnthroInput>;
};


export type MutationEditSyncedMetricAthletesArgs = {
  input: Array<EditSyncedAthleteAndMetricAnthroInput>;
};


export type MutationEditTeamArgs = {
  input: EditTeamInput;
};


export type MutationEditTeamDisplaySettingsArgs = {
  input: EditTeamDisplaySettingsInput;
};


export type MutationEditTeamFoodLogNotificationSettingsArgs = {
  input: EditTeamFoodLogNotificationSettingsInput;
};


export type MutationEditTeamForStaffArgs = {
  input: EditTeamForStaffInput;
};


export type MutationEditTeamLabelArgs = {
  input: EditTeamLabelInput;
};


export type MutationEditTerritoryArgs = {
  input: EditTerritoryInput;
};


export type MutationEditTimelineMealDetailsArgs = {
  input: EditTimelineMealDetailsInput;
};


export type MutationEditTimelineMealMealTemplateArgs = {
  input: EditTimelineMealMealTemplateInput;
};


export type MutationEditTimelineMealServingAmountArgs = {
  input: EditTimelineMealServingAmountInput;
};


export type MutationEditUserBrandedFoodArgs = {
  input: EditUserBrandedFoodFormInput;
};


export type MutationEditUserTeamworksIdToOverwriteArgs = {
  input: EditUserTeamworksIdToOverwriteInput;
};


export type MutationFinalizePrepPlanArgs = {
  input: FinalizePrepPlanInput;
};


export type MutationForceLockPlannedMenuArgs = {
  input: ForceLockPlannedMenuInput;
};


export type MutationGetPossibleGptRecipeByNameArgs = {
  input: GptRecipeByNameInput;
};


export type MutationGetPossibleGptRecipeFromImageArgs = {
  input: GptRecipeFromImageInput;
};


export type MutationGetPossibleGptRecipeFromUrlArgs = {
  input: GptRecipeFromUrlInput;
};


export type MutationImportBonAppetitMenusArgs = {
  input: ImportBonAppetitMenusInput;
};


export type MutationImportCbordMenusArgs = {
  input: ImportCbordMenusInput;
};


export type MutationImportCompassMenusArgs = {
  input: ImportCompassMenusInput;
};


export type MutationImportCompassRecipeArgs = {
  input: ImportCompassRecipeInput;
};


export type MutationImportPlannedMenuArgs = {
  input: ImportPlannedMenuInput;
};


export type MutationImportSodexoMenusArgs = {
  input: ImportSodexoMenusInput;
};


export type MutationLinkTeamworksOrgArgs = {
  input: LinkTeamworksOrgInput;
};


export type MutationLockPlannedMenuArgs = {
  input: LockPlannedMenuInput;
};


export type MutationMarkNotificationsAsViewedArgs = {
  input: MarkNotificationsAsViewedInput;
};


export type MutationMarkNotificationsAsViewedBeforeArgs = {
  input: MarkNotificationsAsViewedBeforeInput;
};


export type MutationMoveMacroMealPlanTemplateToOrgGroupArgs = {
  input: MoveMacroMealPlanTemplateToOrgGroupInput;
};


export type MutationMoveOrgFoodToOrgGroupArgs = {
  input: MoveOrgFoodToOrgGroupInput;
};


export type MutationMoveOrgGroupFoodToMyOrgArgs = {
  input: MoveOrgGroupFoodToMyOrgInput;
};


export type MutationMoveOrgGroupMacroMealPlanTemplateToOrgArgs = {
  input: MoveOrgGroupMacroMealPlanTemplateToOrgInput;
};


export type MutationMoveOrgRecipeToOrgGroupArgs = {
  input: MoveOrgRecipeToOrgGroupInput;
};


export type MutationOverrideMealMenuIdentityArgs = {
  input: OverrideMealMenuIdentityInput;
};


export type MutationPublishMacroMealPlanTemplateArgs = {
  input: PublishMacroMealPlanTemplateInput;
};


export type MutationPublishOrgGroupMacroMealPlanTemplateArgs = {
  input: PublishMacroMealPlanTemplateInput;
};


export type MutationPublishSharedMacroMealPlanTemplateArgs = {
  input: PublishMacroMealPlanTemplateInput;
};


export type MutationRecieveAthleteTransferArgs = {
  input: RecieveAthleteTransferInput;
};


export type MutationReclaimAthleteTransferArgs = {
  input: ReclaimAthleteTransferInput;
};


export type MutationRecordMenuOrderSuggestionDecisionArgs = {
  input: RecordMenuOrderSuggestionDecisionInput;
};


export type MutationRecordMenuOrderSuggestionsViewArgs = {
  input: RecordMenuOrderSuggestionsViewInput;
};


export type MutationRefreshClientCredentialSecretArgs = {
  input: RefreshClientCredentialSecretInput;
};


export type MutationRefreshTeamworksSyncStatusArgs = {
  input: RefreshTeamworksSyncStatusInput;
};


export type MutationRemoveAthleteDislikedFoodArgs = {
  input: AthleteFoodPreferenceInput;
};


export type MutationRemoveAthleteDislikedFoodGroupArgs = {
  input: AthleteFoodGroupPreferenceInput;
};


export type MutationRemoveAthleteLikedFoodArgs = {
  input: AthleteFoodPreferenceInput;
};


export type MutationRemoveAthleteLikedFoodGroupArgs = {
  input: AthleteFoodGroupPreferenceInput;
};


export type MutationRemoveBulkOrderArgs = {
  input: RemoveBulkOrderInput;
};


export type MutationRemoveFoodLogActivityArgs = {
  input: RemoveFoodLogActivityInput;
};


export type MutationRemoveFoodLogActivityTemplateArgs = {
  input: RemoveFoodLogActivityTemplateInput;
};


export type MutationRemoveFoodLogMealArgs = {
  input: RemoveFoodLogMealInput;
};


export type MutationRemoveFoodLogMealImageArgs = {
  input: RemoveFoodLogMealImageInput;
};


export type MutationRemoveFoodLogMealTemplateArgs = {
  input: RemoveFoodLogMealTemplateInput;
};


export type MutationRemoveMealMenuLogItemArgs = {
  input: RemoveMealMenuLogItemInput;
};


export type MutationRemoveMealOptionArgs = {
  input: RemoveMealOptionInput;
};


export type MutationRemoveMealOptionPendingArgs = {
  input: RemoveMealOptionPendingInput;
};


export type MutationRemoveMealPlanDateArgs = {
  input: RemoveMealPlanDateInput;
};


export type MutationRemoveMealPlanDateForAthleteArgs = {
  input: RemoveMealPlanDateForAthleteInput;
};


export type MutationRemoveMenuOrderArgs = {
  input: RemoveMenuOrderInput;
};


export type MutationRemoveMenuOrderImageArgs = {
  input: RemoveMenuOrderImageInput;
};


export type MutationRemoveMenuOrderMealTemplateArgs = {
  input: RemoveMenuOrderMealTemplateInput;
};


export type MutationRemoveMyDislikedFoodArgs = {
  input: MyFoodPreferenceInput;
};


export type MutationRemoveMyDislikedFoodGroupArgs = {
  input: MyFoodGroupPreferenceInput;
};


export type MutationRemoveMyLikedFoodArgs = {
  input: MyFoodPreferenceInput;
};


export type MutationRemoveMyLikedFoodGroupArgs = {
  input: MyFoodGroupPreferenceInput;
};


export type MutationRemovePrepPlanMealArgs = {
  input: RemovePrepPlanMealInput;
};


export type MutationRemoveRecipeImageArgs = {
  input: RemoveRecipeImageInput;
};


export type MutationRemoveRestaurantMenuLinkArgs = {
  input: RemoveRestaurantMenuLinkInput;
};


export type MutationRemoveRestaurantMenuLinkOrderArgs = {
  input: RemoveRestaurantMenuLinkOrderInput;
};


export type MutationRemoveRestaurantMenuLinkOrderMealTemplateArgs = {
  input: RemoveRestaurantMenuLinkOrderMealTemplateInput;
};


export type MutationRemoveRestaurantMenuLinkPlateOrderArgs = {
  input: RemoveRestaurantMenuLinkPlateOrderInput;
};


export type MutationRemoveRestaurantMenuLinkPlateOrderMealTemplateArgs = {
  input: RemoveRestaurantMenuLinkPlateOrderMealTemplateInput;
};


export type MutationRemoveRestaurantMenuLogArgs = {
  input: RemoveRestaurantMenuLogInput;
};


export type MutationRemoveRestaurantMenuLogItemArgs = {
  input: RemoveRestaurantMenuLogItemInput;
};


export type MutationRemoveRestaurantMenuLogMealTemplateArgs = {
  input: RemoveRestaurantMenuLogMealTemplateInput;
};


export type MutationRemoveTimelineMealImageArgs = {
  input: RemoveTimelineMealImageInput;
};


export type MutationRemoveTimelineMealServingAmountArgs = {
  input: RemoveTimelineMealServingAmountInput;
};


export type MutationRunMaintainTeamworksFullySyncedJobArgs = {
  input: RefreshTeamworksSyncStatusInput;
};


export type MutationSavePlannedMealsArgs = {
  input: SavePlannedMealsInput;
};


export type MutationSendMealMenuNotificationArgs = {
  mealMenuId: Scalars['ID'];
};


export type MutationSetGoalTrackerGoalArgs = {
  input: SetGoalTrackerGoalInput;
};


export type MutationSetMealMenuCheckInsForAthleteArgs = {
  input: SetMealMenuCheckInsInput;
};


export type MutationSetMealMenuLogItemPercentConsumedArgs = {
  input: SetMenuSelectionItemPercentConsumedInput;
};


export type MutationSetMealOptionsForMealTemplatesArgs = {
  input: SetMealOptionsForMealTemplatesInput;
};


export type MutationSetMenuOrderItemPercentConsumedArgs = {
  input: SetMenuSelectionItemPercentConsumedInput;
};


export type MutationSetPrepPlanGuidedBuilderArgs = {
  input: SetPrepPlanGuidedBuilderInput;
};


export type MutationSetPrepPlanMealAssignmentSlotsArgs = {
  input: SetPrepPlanMealAssignmentSlotsInput;
};


export type MutationSetRestaurantMenuLinkOrderItemPercentConsumedArgs = {
  input: SetMenuSelectionItemPercentConsumedInput;
};


export type MutationSetRestaurantMenuLinkPlateOrderItemPercentConsumedArgs = {
  input: SetRestaurantMenuLinkPlateOrderItemPercentConsumedInput;
};


export type MutationSetRestaurantMenuLogItemPercentConsumedArgs = {
  input: SetMenuSelectionItemPercentConsumedInput;
};


export type MutationSetTimelineMealServingAmountsForAthleteArgs = {
  input: SetTimelineMealServingAmountsForAthleteInput;
};


export type MutationSetTimelineMealServingAmountsForMeArgs = {
  input: SetTimelineMealServingAmountsInput;
};


export type MutationShareMealOptionPendingArgs = {
  input: ShareMealOptionPendingInput;
};


export type MutationTextMealPlanToAthleteArgs = {
  input: TextMealPlanToAthleteInput;
};


export type MutationTextMealPlansToAthletesArgs = {
  input: TextMealPlansToAthletesInput;
};


export type MutationUnarchiveAthleteArgs = {
  input: UnarchiveAthleteInput;
};


export type MutationUnlockPlannedMenuArgs = {
  input: UnlockPlannedMenuInput;
};


export type MutationUpdateBulkOrderStatusArgs = {
  bulkOrderId: Scalars['ID'];
  status: MenuOrderItemStatus;
};


export type MutationUpdateMacroMealPlanTemplateAssignScopeArgs = {
  input: UpdateMealPlanTemplateAssignScopeInput;
};


export type MutationUpdateMacroMealPlanTemplateSharingScopeArgs = {
  input: UpdateMacroMealPlanTemplateSharingScopeInput;
};


export type MutationUpdateMealMenuPlateItemOrderStatusArgs = {
  input: UpdateMealMenuPlateItemOrderStatusInput;
};


export type MutationUpdateMealMenuPlateOrderStatusArgs = {
  mealMenuPlateOrderId: Scalars['String'];
  status: MenuOrderItemStatus;
};


export type MutationUpdateMealMenuPlateOrderStatusesArgs = {
  mealMenuPlateOrderIds: Array<Scalars['String']>;
  status: MenuOrderItemStatus;
};


export type MutationUpdateMenuOrderItemStatusesArgs = {
  input: UpdateMenuOrderItemStatusesInput;
};


export type MutationUpdateMultipleMealMenuPlateItemOrderStatusesArgs = {
  input: UpdateMultipleMealMenuPlateItemOrderStatusesInput;
};


export type MutationUpdateMultipleMenuOrderItemStatusesArgs = {
  input: UpdateMultipleMenuOrderItemStatusesInput;
};


export type MutationUpdateSharedMacroMealPlanTemplateSharingScopeArgs = {
  input: UpdateMacroMealPlanTemplateSharingScopeInput;
};

export type MyFoodGroupPreferenceInput = {
  foodGroupId: Scalars['ID'];
};

export type MyFoodPreferenceInput = {
  foodId: Scalars['ID'];
};

export type MyNamedTagsPreviewPayload = {
  __typename?: 'MyNamedTagsPreviewPayload';
  previewNamedTags: Array<NamedTag>;
  total: Scalars['Int'];
  hiddenPreviewText: Maybe<Scalars['String']>;
};

export type MyRecipeCursorConnection = {
  __typename?: 'MyRecipeCursorConnection';
  edges: Array<Recipe>;
  pageInfo: CursorPageInfo;
};

export type NamedTag = {
  __typename?: 'NamedTag';
  tag: Tag;
  tagName: TagName;
};

export type NamedTagCursorConnection = {
  __typename?: 'NamedTagCursorConnection';
  edges: Array<NamedTag>;
  pageInfo: CursorPageInfo;
};

export type NamedTagOffsetConnection = {
  __typename?: 'NamedTagOffsetConnection';
  edges: Array<NamedTag>;
  pageInfo: OffsetPageInfo;
};

export type NoTargetsDisplaySettings = {
  __typename?: 'NoTargetsDisplaySettings';
  dailyProgressViewsForMacros: MacroProgressViews;
  mealProgressViewsForMacros: MacroProgressViews;
};

export type NoTargetsDisplaySettingsInput = {
  dailyProgressViewsForMacros: MacroProgressViews;
  mealProgressViewsForMacros: MacroProgressViews;
};

export type NotSharedRecipeCursorConnection = {
  __typename?: 'NotSharedRecipeCursorConnection';
  edges: Array<Recipe>;
  pageInfo: CursorPageInfo;
};

export type Note = {
  __typename?: 'Note';
  id: Scalars['ID'];
  datetime: Scalars['DateTime'];
  content: Scalars['String'];
  type: Maybe<NoteType>;
  athlete: Athlete;
};

export type NoteFormInput = {
  datetime: Scalars['DateTime'];
  content: Scalars['String'];
  noteTypeId: Maybe<Scalars['ID']>;
};

export type NoteType = {
  __typename?: 'NoteType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type NoteTypeFormInput = {
  name: Scalars['String'];
};

export enum NotemealAccountType {
  athlete = 'athlete',
  dietitian = 'dietitian',
  chef = 'chef'
}

export type Notification = {
  id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  type: Scalars['String'];
  createdAt: Scalars['DateTime'];
  viewedAt: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  message: Scalars['String'];
};

export type NotificationCursorConnection = {
  __typename?: 'NotificationCursorConnection';
  edges: Array<Notification>;
  pageInfo: CursorPageInfo;
};

export type NotificationPublication = {
  __typename?: 'NotificationPublication';
  payload: NotificationPublicationPayload;
  /** @deprecated Pending web notifications overhaul */
  scope: NotificationPublisherScope;
};

export type NotificationPublicationPayload = {
  __typename?: 'NotificationPublicationPayload';
  notification: Notification;
};

export type NotificationPublisherScope = {
  __typename?: 'NotificationPublisherScope';
  teamId: Maybe<Scalars['ID']>;
  athleteId: Maybe<Scalars['ID']>;
  foodLogMealId: Maybe<Scalars['ID']>;
};

export type Nutrient = {
  __typename?: 'Nutrient';
  id: Scalars['ID'];
  fdcNutrientId: Scalars['Int'];
  name: Scalars['String'];
  commonName: Maybe<Scalars['String']>;
  unitName: Scalars['String'];
  category: Scalars['String'];
  defaultDietaryReferenceIntake: Maybe<NutrientDietaryReferenceIntake>;
};


export type NutrientDefaultDietaryReferenceIntakeArgs = {
  age: Maybe<Scalars['Int']>;
  sex: Maybe<SexType>;
};

export type NutrientAmount = {
  __typename?: 'NutrientAmount';
  /** @deprecated Field no longer supported */
  id: Maybe<Scalars['ID']>;
  /** @deprecated Field no longer supported */
  footnote: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  dataPoints: Maybe<Scalars['Int']>;
  /** @deprecated Field no longer supported */
  min: Maybe<Scalars['Float']>;
  /** @deprecated Field no longer supported */
  max: Maybe<Scalars['Float']>;
  /** @deprecated Field no longer supported */
  median: Maybe<Scalars['Float']>;
  /** @deprecated Field no longer supported */
  foodId: Maybe<Scalars['ID']>;
  nutrient: Nutrient;
  amount: Scalars['Float'];
};

export type NutrientDietaryReferenceIntake = {
  __typename?: 'NutrientDietaryReferenceIntake';
  id: Scalars['ID'];
  amount: Maybe<Scalars['Float']>;
  type: NutrientDietaryReferenceIntakeType;
};


export type NutrientDietaryReferenceIntakeAmountArgs = {
  kcal: Maybe<Scalars['Float']>;
};

export enum NutrientDietaryReferenceIntakeType {
  rda = 'RDA',
  ul = 'UL',
  ai = 'AI',
  dga = 'DGA',
  unitsPerKcal = 'unitsPerKcal'
}

export type OffsetPageInfo = {
  __typename?: 'OffsetPageInfo';
  hasNextPage: Scalars['Boolean'];
  endOffset: Scalars['Int'];
  total: Scalars['Int'];
};

export type OffsetPaginationInput = {
  offset: Scalars['Int'];
  limit: Scalars['Int'];
};

export type Org = {
  __typename?: 'Org';
  id: Scalars['ID'];
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
  allowsPublicAccess: Scalars['Boolean'];
  memberships: Array<OrgMembership>;
  featureFlags: FeatureFlags;
  teamworksId: Maybe<Scalars['Int']>;
  teams: Array<Team>;
  teamworksProfileSyncRules: Array<TeamworksProfileSyncRule>;
  teamworksSyncStatus: Maybe<TeamworksSyncStatus>;
  athletes: Array<Athlete>;
  integrations: Array<Integration>;
  isFullySynced: Scalars['Boolean'];
  isTeamworksLinked: Scalars['Boolean'];
  isNotemealLinked: Scalars['Boolean'];
  agreementForm: Maybe<AgreementForm>;
  scoringSystem: Maybe<ScoringSystem>;
  autoShareNewNotemealRecipes: Scalars['Boolean'];
  localeCode: Scalars['String'];
  hasTwSuperuserPersonId: Maybe<Scalars['Boolean']>;
  territoryName: Maybe<Scalars['String']>;
};


export type OrgMembershipsArgs = {
  filterInactive: Maybe<Scalars['Boolean']>;
};


export type OrgAthletesArgs = {
  isArchived: Maybe<Scalars['Boolean']>;
};

export type OrgForReporting = {
  __typename?: 'OrgForReporting';
  id: Scalars['ID'];
  name: Scalars['String'];
  teamworksId: Maybe<Scalars['Int']>;
  territoryName: Maybe<Scalars['String']>;
  performanceKitchen: Maybe<Scalars['Boolean']>;
};

export type OrgGroup = {
  __typename?: 'OrgGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  allowsResourceSharing: Scalars['Boolean'];
  allowsAthleteTransfer: Scalars['Boolean'];
  orgs: Array<Org>;
  orgsCount: Scalars['Int'];
  parentOrgGroup: Maybe<OrgGroup>;
  childrenOrgGroups: Array<OrgGroup>;
  createdBy: Maybe<User>;
};

export type OrgInfo = {
  __typename?: 'OrgInfo';
  org: OrgResult;
  orgMembership: Maybe<OrgMembership>;
  athlete: Maybe<Athlete>;
};

export type OrgMembership = {
  __typename?: 'OrgMembership';
  id: Scalars['ID'];
  teamworksId: Maybe<Scalars['Int']>;
  athlete: Maybe<Athlete>;
  isActive: Scalars['Boolean'];
  isAdmin: Scalars['Boolean'];
  isNotemealOnly: Scalars['Boolean'];
  lastRefresh: Maybe<Scalars['DateTime']>;
  isSubscribedToDigest: Scalars['Boolean'];
  user: User;
  roles: Array<Role>;
};

export type OrgResult = {
  __typename?: 'OrgResult';
  name: Scalars['String'];
  id: Scalars['ID'];
};

export type OverrideMealMenuIdentityInput = {
  mealMenuIdentityId: Scalars['ID'];
};

export type OverrideMealMenuIdentityPayload = {
  __typename?: 'OverrideMealMenuIdentityPayload';
  mealMenuIdentity: MealMenuIdentity;
};

export type OverrideMealPlanDateAssignmentInput = {
  individualDates: Maybe<Array<Scalars['Date']>>;
  startDate: Maybe<Scalars['Date']>;
  endDate: Maybe<Scalars['Date']>;
  daysOfWeek: Maybe<Array<DayOfWeek>>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type PaymentMethodCursorConnection = {
  __typename?: 'PaymentMethodCursorConnection';
  edges: Array<PaymentMethod>;
  pageInfo: CursorPageInfo;
};

export type PaymentMethodOffsetConnection = {
  __typename?: 'PaymentMethodOffsetConnection';
  edges: Array<PaymentMethod>;
  pageInfo: OffsetPageInfo;
};

export type PlannedMenu = {
  __typename?: 'PlannedMenu';
  id: Scalars['ID'];
  name: Scalars['String'];
  description: Maybe<Scalars['String']>;
  firstOccurrence: Scalars['Date'];
  lastOccurrence: Scalars['Date'];
  occurrence: PlannedMenuOccurrence;
  timezone: Scalars['String'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
  weeks: Array<PlannedMenuWeek>;
  mealTypes: Array<MealType>;
  createdById: Maybe<Scalars['ID']>;
  createdBy: Maybe<User>;
  lastEditedAt: Maybe<Scalars['DateTime']>;
  lastEditedBy: Maybe<User>;
  athletesWithBirthdaysInRange: Array<Athlete>;
  lockCreatedAt: Maybe<Scalars['DateTime']>;
  lockCreatedBy: Maybe<User>;
};

export type PlannedMenuDisabledDaysInput = {
  plannedMenuWeekId: Scalars['ID'];
  disabledDays: Array<DayOfWeek>;
};

export type PlannedMenuLockTakenPayload = {
  __typename?: 'PlannedMenuLockTakenPayload';
  plannedMenu: PlannedMenu;
};

export type PlannedMenuLockTakenPublication = {
  __typename?: 'PlannedMenuLockTakenPublication';
  scope: PlannedMenuLockTakenPublisherScope;
  payload: PlannedMenuLockTakenPayload;
};

export type PlannedMenuLockTakenPublisherScope = {
  __typename?: 'PlannedMenuLockTakenPublisherScope';
  plannedMenuId: Scalars['ID'];
};

export type PlannedMenuLockTakenSubscriberScope = {
  plannedMenuId: Scalars['ID'];
};

export type PlannedMenuMeal = {
  __typename?: 'PlannedMenuMeal';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: MealType;
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  plannedMenuMealRows: Array<PlannedMenuMealRow>;
  themes: Array<Scalars['String']>;
};

export type PlannedMenuMealInput = {
  id: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  type: MealType;
  startTime: Scalars['String'];
  endTime: Scalars['String'];
  plannedMenuWeekId: Scalars['ID'];
  plannedMenuMealRows: Array<PlannedMenuMealRowInput>;
  themes: Maybe<Array<Scalars['String']>>;
};

export type PlannedMenuMealRow = {
  __typename?: 'PlannedMenuMealRow';
  id: Scalars['ID'];
  diningStationName: Scalars['String'];
  position: Scalars['Int'];
  foodType: Maybe<Scalars['String']>;
  plannedMenuMealRowItems: Array<PlannedMenuMealRowItem>;
};

export type PlannedMenuMealRowInput = {
  id: Maybe<Scalars['ID']>;
  diningStationName: Scalars['String'];
  position: Scalars['Int'];
  foodType: Maybe<Scalars['String']>;
  plannedMenuMealRowItems: Array<PlannedMenuMealRowItemInput>;
};

export type PlannedMenuMealRowItem = {
  __typename?: 'PlannedMenuMealRowItem';
  id: Scalars['ID'];
  dayOfWeek: DayOfWeek;
  position: Scalars['Int'];
  menuItem: MenuItem;
};

export type PlannedMenuMealRowItemInput = {
  id: Maybe<Scalars['ID']>;
  dayOfWeek: DayOfWeek;
  position: Scalars['Int'];
  menuItemId: Scalars['ID'];
};

export enum PlannedMenuOccurrence {
  daily = 'daily',
  weekdays = 'weekdays'
}

export type PlannedMenuOffsetConnection = {
  __typename?: 'PlannedMenuOffsetConnection';
  edges: Array<PlannedMenu>;
  pageInfo: OffsetPageInfo;
};

export type PlannedMenuWeek = {
  __typename?: 'PlannedMenuWeek';
  id: Scalars['ID'];
  week: Scalars['Int'];
  meals: Array<PlannedMenuMeal>;
  disabledDays: Array<DayOfWeek>;
};

export type PlateOrderIdItemIdTuple = {
  mealMenuPlateOrderId: Scalars['ID'];
  mealMenuPlateItemId: Scalars['ID'];
};

export type Position = {
  __typename?: 'Position';
  id: Scalars['ID'];
  name: Scalars['String'];
  teamworksMappings: Array<TeamworksPositionMapping>;
  sport: Sport;
};

export type PositionBySportCursorConnection = {
  __typename?: 'PositionBySportCursorConnection';
  edges: Array<Position>;
  pageInfo: CursorPageInfo;
};

export type PositionTag = Tag & {
  __typename?: 'PositionTag';
  id: Scalars['ID'];
  tagName: Maybe<TagName>;
  athleteCount: Scalars['Int'];
  isComplement: Scalars['Boolean'];
  createdBy: Maybe<User>;
  positions: Array<Position>;
};

export type PositionsMappingInput = {
  teamworksName: Scalars['String'];
  positionId: Scalars['ID'];
};

export enum PreferredContactMethodType {
  email = 'email',
  phoneNumber = 'phoneNumber'
}

export type PrepPlan = {
  __typename?: 'PrepPlan';
  id: Scalars['ID'];
  start: Scalars['Date'];
  end: Scalars['Date'];
  inProgress: Scalars['Boolean'];
  meals: Array<PrepPlanMeal>;
  mealSlots: Array<PrepPlanMealAssignment>;
  groceryList: Maybe<GroceryList>;
  seedValue: Scalars['Int'];
};

export type PrepPlanCursorConnection = {
  __typename?: 'PrepPlanCursorConnection';
  edges: Array<PrepPlan>;
  pageInfo: CursorPageInfo;
};

export type PrepPlanMeal = {
  __typename?: 'PrepPlanMeal';
  id: Scalars['ID'];
  name: Scalars['String'];
  priority: Scalars['Int'];
  servingAmounts: Array<ServingAmount>;
  prepPlanMealAssignments: Array<PrepPlanMealAssignment>;
  edited: Scalars['Boolean'];
  mealTypes: Maybe<Array<MealType>>;
  guidedBuilder: Scalars['Boolean'];
  recipe: Maybe<Recipe>;
  mealOption: Maybe<MealOption>;
  primaryImageUrl: Maybe<Scalars['String']>;
  ingredientText: Scalars['String'];
};

export type PrepPlanMealAssignment = {
  __typename?: 'PrepPlanMealAssignment';
  id: Scalars['ID'];
  amount: Scalars['Int'];
  position: Scalars['Int'];
  date: Maybe<Scalars['Date']>;
  type: Maybe<MealType>;
  mealTemplate: Maybe<MealTemplate>;
  prepPlanMeal: Maybe<PrepPlanMeal>;
};

export type PrepPlanMealAssignmentInput = {
  amount: Scalars['Int'];
  position: Scalars['Int'];
  type: Maybe<MealType>;
  date: Maybe<Scalars['Date']>;
  mealTemplateId: Maybe<Scalars['ID']>;
};

export type PrepPlanMealSlotAssignmentInput = {
  id: Scalars['ID'];
  amount: Scalars['Int'];
};

export type PrepPlanMealsForTimelineMealInput = {
  mealType: Maybe<MealType>;
  date: Scalars['Date'];
};

export type PrepPlanMealsForTimelineMealPayload = {
  __typename?: 'PrepPlanMealsForTimelineMealPayload';
  scheduledMeals: Array<PrepPlanMeal>;
  otherMeals: Array<PrepPlanMeal>;
};

export type PublishMacroMealPlanTemplateInput = {
  macroMealPlanTemplateId: Scalars['ID'];
  macroMealPlanTemplate: EditMacroMealPlanTemplateInput;
};

export type PublishMacroMealPlanTemplatePayload = {
  __typename?: 'PublishMacroMealPlanTemplatePayload';
  macroMealPlanTemplate: MacroMealPlanTemplate;
};

export type Query = {
  __typename?: 'Query';
  activeAthleteTransferByAthleteId: Maybe<AthleteTransfer>;
  activeAthleteTransferOffsetConnection: ActiveAthleteTransferOffsetConnection;
  activeAthleteTransfers: Array<AthleteTransfer>;
  agreementFormOffsetConnection: AgreementFormOffsetConnection;
  agreementForms: Array<AgreementForm>;
  allDeliveryLocations: DeliveryLocationOffsetConnection;
  allMealPlanTemplatesForDietitianCursorConnection: MealPlanTemplateCursorConnection;
  allRestaurantLocations: RestaurantLocationOffsetConnection;
  allRestaurants: RestaurantOffsetConnection;
  allSchedules: Array<Schedule>;
  allTeamLabels: TeamLabelOffsetConnection;
  appModeRoles: AppModeRoles;
  athlete: Athlete;
  athleteGoalsInRange: Array<AthleteGoalConfigurationAndTracker>;
  athleteSearch: Array<Athlete>;
  athleteSearchCursorConnection: AthleteCursorConnection;
  athleteTransferOrgGroups: Array<OrgGroup>;
  athletes: Array<Athlete>;
  athletesForBulkOrderCursorConnection: AthleteCursorConnectionWithSelectAll;
  athletesForCustomTagCreation: Array<Athlete>;
  athletesForCustomTagCreationCursorConnection: AthleteCursorConnection;
  athletesForMealMenus: Array<Athlete>;
  athletesForPendingAdvancedSelection: Array<Athlete>;
  athletesForTag: Array<Athlete>;
  athletesForTagCursorConnection: AthleteCursorConnection;
  athletesForTagOffsetConnection: AthleteOffsetConnection;
  athletesForTeam: Array<Athlete>;
  athletesForTeamOffsetConnection: AthleteOffsetConnection;
  bonAppetitCafes: Array<BonAppetitCafe>;
  bonAppetitMenusPreview: BonAppetitMenusPreview;
  brandWithUsdaManufacturerName: Maybe<Brand>;
  brands: Array<Brand>;
  bulkOrder: BulkOrder;
  bulkOrderStatus: MenuOrderItemStatus;
  bulkOrdersForMealMenu: Array<BulkOrder>;
  bulkOrdersForTeam: Array<BulkOrder>;
  bulkOrdersForUser: Array<BulkOrder>;
  canBeStaff: Scalars['Boolean'];
  cateringRestaurantMenuSearch: RestaurantMenuSearchCursorConnection;
  cbordBusinessUnits: Array<CbordBusinessUnit>;
  cbordServiceMenus: Array<CbordServiceMenu>;
  clientCredentialOffsetConnection: ClientCredentialOffsetConnection;
  compass_locations: Array<CompassLocation>;
  compass_menuItems: Array<CompassMenuItem>;
  compass_mrn_recipe: Maybe<CompassRecipe>;
  compass_recipes: Array<CompassRecipe>;
  convertedServing: Serving;
  cuisineCursorConnection: CuisineCursorConnection;
  cuisineOffsetConnection: CuisineOffsetConnection;
  currentMenuItem: MenuItem;
  daySummaries: Array<DaySummary>;
  deliveryLocationCursorConnection: DeliveryLocationCursorConnection;
  dietCursorConnection: DietCursorConnection;
  dietOffsetConnection: DietOffsetConnection;
  dietitiansICanMessageCursorConnection: MessageOptionCursorConnection;
  diningStationTemplate: DiningStationTemplate;
  diningStationTemplateCursorConnection: DiningStationTemplateCursorConnection;
  diningStationTemplateOffsetConnection: DiningStationTemplateOffsetConnection;
  exchangeSet: ExchangeSet;
  exchangeSets: Array<ExchangeSet>;
  food: Food;
  foodGroup: FoodGroup;
  foodGroups: Array<FoodGroup>;
  foodGroupsById: Array<FoodGroup>;
  foodItem: Maybe<GroceryListFoodItem>;
  foodLogMeal: FoodLogMeal;
  foodOffsetConnection: FoodOffsetConnection;
  foodOrRecipe: FoodOrRecipe;
  foods: Array<Food>;
  foodsById: Array<Food>;
  genericFood: GenericFood;
  /** @deprecated Use general foods query instead */
  genericFoods: Array<GenericFood>;
  getOpenAIImage: Maybe<Scalars['String']>;
  goalCategories: Array<GoalCategory>;
  goalConfiguration: GoalConfiguration;
  goalConfigurationsForAthleteOffsetConnection: GoalConfigurationOffsetConnection;
  goalConfigurationsForTeamOffsetConnection: GoalConfigurationOffsetConnection;
  goalTrackerGoalOffsetConnection: GoalTrackerGoalOffsetConnection;
  goalTrackerGoals: Array<GoalTrackerGoal>;
  goalTypes: Array<GoalType>;
  googleMapsApiKey: Scalars['String'];
  gramUnit: Unit;
  groceryList: Maybe<GroceryList>;
  groceryListCategories: Array<GroceryListCategory>;
  integrations: Array<IntegrationType>;
  isTagNameUnique: IsTagNameUniquePayload;
  locales: Array<Locale>;
  loggingRestaurantMenuSearch: RestaurantMenuSearchCursorConnection;
  me: Athlete;
  mealEvents: Array<MealEvent>;
  mealMenu: MealMenu;
  mealMenuAttendees: MealMenuAttendeePayload;
  mealMenuPlate: MealMenuPlate;
  mealMenuPlateOrder: MealMenuPlateOrder;
  mealMenuPlateOrders: Array<MealMenuPlateOrder>;
  mealMenuPlateOrdersForUser: Maybe<Array<MealMenuPlateOrder>>;
  mealMenus: Array<MealMenu>;
  mealMenusCursorConnection: MealMenusCursorConnection;
  mealMenusInDateRange: Array<MealMenu>;
  mealMenusInRange: Array<MealMenu>;
  mealMenusWithCheckInInRange: Array<MealMenusWithCheckinPayload>;
  mealOptionCursorConnectionForMealTemplate: MealOptionCursorConnection;
  mealOptionSuggestionCursorConnection: ServingAmountGroupCursorConnection;
  mealOptionsForMealPlansCursorConnection: MealOptionCursorConnection;
  mealOptionsForMealPlansCursorConnectionWithRandomSeededSort: MealOptionCursorConnection;
  mealOptionsForMealPlansPreview: MealOptionCursorConnection;
  mealOptionsForMealPlansPreviewWithRandomSeededSort: MealOptionCursorConnection;
  mealPlan: MealPlan;
  mealPlanForCopyCursorConnection: MealPlanCursorConnection;
  mealPlanOffsetConnection: MealPlanOffsetConnection;
  mealPlanTemplate: MealPlanTemplate;
  mealPlanTemplateOffsetConnection: MealPlanTemplateOffsetConnection;
  menuItem: MenuItem;
  menuItemCursorConnection: MenuItemCursorConnection;
  menuItemOffsetConnection: MenuItemOffsetConnection;
  menuItemRatingOffsetConnection: MenuItemRatingOffsetConnection;
  menuItems: Array<MenuItem>;
  menuOrder: MenuOrder;
  menuOrderCursorConnectionForItems: MenuOrderCursorConnection;
  menuOrderCursorConnectionForItemsForAthlete: MenuOrderCursorConnection;
  menuOrderRateLimitRemainders: Array<MenuOrderRateLimitRemainder>;
  menuOrderSuggestions: Maybe<Array<MenuOrderSuggestion>>;
  menuOrders: Array<MenuOrder>;
  mobileUpdateForVersion: Maybe<MobileUpdateType>;
  myCurrentGroceryList: Maybe<GroceryList>;
  myCurrentPrepPlan: Maybe<PrepPlan>;
  myFeatureFlags: FeatureFlags;
  myGoalTrackerGoalsForDate: Array<GoalTrackerGoal>;
  myOrg: Org;
  myOrgForReporting: Maybe<OrgForReporting>;
  myPastPrepPlanCursorConnection: PrepPlanCursorConnection;
  myRecipeCursorConnection: MyRecipeCursorConnection;
  myRecipePreview: MyRecipeCursorConnection;
  myScoringSystem: Maybe<ScoringSystem>;
  myStreamChatToken: Scalars['String'];
  myTimelineMealsInRange: Array<TimelineMeal>;
  myUnfinishedPrepPlan: Maybe<PrepPlan>;
  myUser: User;
  namedTag: NamedTag;
  namedTagCursorConnection: NamedTagCursorConnection;
  namedTagOffsetConnection: NamedTagOffsetConnection;
  namedTagSearch: Array<NamedTag>;
  nixAppId: Scalars['String'];
  nixAppKey: Scalars['String'];
  notSharedRecipeCursorConnection: NotSharedRecipeCursorConnection;
  noteTypes: Array<NoteType>;
  notesForTeam: Array<Note>;
  notification: Maybe<Notification>;
  notificationCount: Scalars['Int'];
  notificationCursorConnection: NotificationCursorConnection;
  /** @deprecated Field no longer supported */
  nutrientAmountsForFoods: Array<NutrientAmount>;
  nutrients: Array<Nutrient>;
  org: Org;
  orgFoodGroups: Array<FoodGroup>;
  orgFoodGroupsOffsetConnection: FoodGroupsOffsetConnection;
  orgFoodOffsetConnection: FoodOffsetConnection;
  orgFoods: Array<Food>;
  orgGroup: OrgGroup;
  orgGroupFoodOffsetConnection: FoodOffsetConnection;
  orgGroupFoodOwnerCursorConnection: UserCursorConnection;
  orgGroupFoods: Array<Food>;
  orgGroupMealPlanTemplateOffsetConnection: MealPlanTemplateOffsetConnection;
  orgGroupMealPlanTemplateOwnerCursorConnection: UserCursorConnection;
  orgGroupRecipeOffsetConnection: RecipeOffsetConnection;
  orgGroupRecipeOwnerCursorConnection: UserCursorConnection;
  orgGroupRecipes: Array<Recipe>;
  orgGroups: Array<OrgGroup>;
  orgs: Array<Org>;
  ounceUnit: Unit;
  paymentMethodCursorConnection: PaymentMethodCursorConnection;
  paymentMethodOffsetConnection: PaymentMethodOffsetConnection;
  ping: Scalars['Boolean'];
  plannedMenu: PlannedMenu;
  plannedMenuMealsForWeek: Array<PlannedMenuMeal>;
  plannedMenuOffsetConnection: PlannedMenuOffsetConnection;
  plannedMenuWeeks: Array<PlannedMenuWeek>;
  positionBySportCursorConnection: PositionBySportCursorConnection;
  prepPlan: PrepPlan;
  prepPlanMeal: PrepPlanMeal;
  prepPlanMealAssignments: Array<PrepPlanMealAssignment>;
  prepPlanMealsForTimelineMeal: PrepPlanMealsForTimelineMealPayload;
  profileUsers: Array<User>;
  recentMealCursorConnectionForMealTypes: RecentTimelineMealCursorConnection;
  recentMealsForMealTypesPreview: RecentTimelineMealCursorConnection;
  recipe: Recipe;
  recipeCursorConnection: RecipeCursorConnection;
  recipeOffsetConnection: RecipeOffsetConnection;
  recipes: Array<Recipe>;
  recipesById: Array<Recipe>;
  resourceSharingOrgGroups: Array<OrgGroup>;
  restauranMenuLinkSuggestions: Maybe<Array<MenuOrderSuggestion>>;
  restaurant: Restaurant;
  restaurantFoodCursorConnection: BrandedFoodCursorConnection;
  restaurantFoodOffsetConnection: BrandedFoodOffsetConnection;
  restaurantFoods: Array<BrandedFood>;
  restaurantGeoLocationSearch: RestaurantGeoLocationSearchCursorConnection;
  restaurantLocationSearch: RestaurantLocationSearchCursorConnection;
  restaurantMenu: RestaurantMenu;
  restaurantMenuLink: RestaurantMenuLink;
  restaurantMenuLinkOrder: RestaurantMenuLinkOrder;
  restaurantMenuLinkPlateOrder: RestaurantMenuLinkPlateOrder;
  restaurantMenuLog: RestaurantMenuLog;
  restaurantMenuSuggestions: Maybe<Array<MenuOrderSuggestion>>;
  restaurantTypeCursorConnection: RestaurantTypeCursorConnection;
  restaurantTypeOffsetConnection: RestaurantTypeOffsetConnection;
  schedules: Array<Schedule>;
  schedulesForTeam: Array<Schedule>;
  /** @deprecated Field no longer supported */
  schemaVersion: Scalars['String'];
  scoringSystemOffsetConnection: ScoringSystemOffsetConnection;
  scoringSystems: Array<ScoringSystem>;
  selectedAthletesCursorConnection: AthleteCursorConnection;
  /** @deprecated Field no longer supported */
  servingAmountGroupCursorConnectionForFoodLogMeal: ServingAmountGroupCursorConnection;
  servingAmountGroupCursorConnectionForMealType: ServingAmountGroupCursorConnection;
  servings: Array<Serving>;
  sharedExchangeSetOffsetConnection: ExchangeSetOffsetConnection;
  sharedFoodGroups: Array<FoodGroup>;
  sharedFoodGroupsCursorConnection: FoodGroupsCursorConnection;
  sharedFoodGroupsOffsetConnection: FoodGroupsOffsetConnection;
  sharedMealPlanTemplate: MealPlanTemplate;
  sharedMealPlanTemplateOffsetConnection: MealPlanTemplateOffsetConnection;
  sharedRecipeCursorConnection: SharedRecipeCursorConnection;
  sharedRecipePreview: SharedRecipeCursorConnection;
  slackFoodRequestsUrl: Scalars['String'];
  sodexoMealTimesByDay: SodexoMealTimesByDay;
  sodexoMenuSources: Array<SodexoMenuSource>;
  sport: Sport;
  sports: Array<Sport>;
  staffUserSearchOffsetConnection: UserOffsetConnection;
  tag: Tag;
  team: Team;
  teamCursorConnection: TeamCursorConnection;
  teamGoalConfiguration: Array<GoalConfiguration>;
  teamLabelCursorConnection: TeamLabelCursorConnection;
  teamSearch: Array<Team>;
  teams: Array<Team>;
  teamsById: Array<Team>;
  teamworksCalendarForAthleteInRange: TeamworksCalendarPayload;
  teamworksDisabledProfileIds: Maybe<Array<Scalars['Int']>>;
  teamworksOrg: TeamworksOrg;
  teamworksOrgCursorConnection: TeamworksOrgCursorConnection;
  teamworksOrgGroups: Array<TeamworksGroup>;
  teamworksPositionLinks: Array<TeamworksPositionLink>;
  teamworksProfileCursorConnection: TeamworksProfileCursorConnection;
  /** @deprecated Use teamworksScheduleForAthleteInRangeNew instead due to new return type */
  teamworksScheduleForAthleteInRange: Array<ExternalEvent>;
  teamworksSportMappings: Array<TeamworksSportMapping>;
  territories: Array<Territory>;
  territoryOffsetConnection: TerritoryOffsetConnection;
  timelineMeal: TimelineMeal;
  unit: Unit;
  unitConversions: Array<UnitConversion>;
  units: Array<Unit>;
  unsubmittedAgreementFormForUser: Maybe<AgreementForm>;
  /** @deprecated Field no longer supported */
  upcomingMealMenus: Array<MealMenu>;
  userFoodCursorConnection: FoodCursorConnection;
  userFoods: Array<Food>;
  usersICanMessageCursorConnection: MessageOptionCursorConnection;
  webVersion: Scalars['String'];
};


export type QueryActiveAthleteTransferByAthleteIdArgs = {
  athleteId: Scalars['String'];
};


export type QueryActiveAthleteTransferOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
};


export type QueryAgreementFormOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryAllDeliveryLocationsArgs = {
  query: Maybe<Scalars['String']>;
  teamLabelId: Maybe<Scalars['ID']>;
  input: OffsetPaginationInput;
};


export type QueryAllMealPlanTemplatesForDietitianCursorConnectionArgs = {
  pagination: CursorPaginationInput;
  searchText: Scalars['String'];
  sortAscending: Scalars['Boolean'];
};


export type QueryAllRestaurantLocationsArgs = {
  restaurantId: Scalars['ID'];
  query: Maybe<Scalars['String']>;
  input: OffsetPaginationInput;
};


export type QueryAllRestaurantsArgs = {
  query: Maybe<Scalars['String']>;
  input: OffsetPaginationInput;
};


export type QueryAllTeamLabelsArgs = {
  query: Maybe<Scalars['String']>;
  input: OffsetPaginationInput;
};


export type QueryAthleteArgs = {
  id: Scalars['ID'];
};


export type QueryAthleteGoalsInRangeArgs = {
  athleteId: Scalars['ID'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};


export type QueryAthleteSearchArgs = {
  query: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
};


export type QueryAthleteSearchCursorConnectionArgs = {
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryAthletesForBulkOrderCursorConnectionArgs = {
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
  tagIds: Array<Scalars['ID']>;
  teamId: Scalars['ID'];
  excludeAthleteIds: Array<Scalars['ID']>;
  mealMenuId: Scalars['ID'];
};


export type QueryAthletesForCustomTagCreationArgs = {
  query: Maybe<Scalars['String']>;
  tagIds: Array<Scalars['ID']>;
  teamIds: Array<Scalars['ID']>;
};


export type QueryAthletesForCustomTagCreationCursorConnectionArgs = {
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
  tagIds: Array<Scalars['ID']>;
  teamIds: Array<Scalars['ID']>;
};


export type QueryAthletesForMealMenusArgs = {
  mealMenuIds: Array<Scalars['ID']>;
};


export type QueryAthletesForPendingAdvancedSelectionArgs = {
  input: AdvancedSelectionInput;
};


export type QueryAthletesForTagArgs = {
  tagId: Scalars['ID'];
  query: Maybe<Scalars['String']>;
};


export type QueryAthletesForTagCursorConnectionArgs = {
  tagId: Scalars['ID'];
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryAthletesForTagOffsetConnectionArgs = {
  tagId: Scalars['ID'];
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryAthletesForTeamArgs = {
  teamId: Scalars['ID'];
  tagIds: Array<Scalars['ID']>;
  query: Maybe<Scalars['String']>;
  filter: Maybe<AthleteFilter>;
};


export type QueryAthletesForTeamOffsetConnectionArgs = {
  teamId: Scalars['ID'];
  input: OffsetPaginationInput;
  tagIds: Array<Scalars['ID']>;
  query: Maybe<Scalars['String']>;
  filter: Maybe<AthleteFilter>;
};


export type QueryBonAppetitMenusPreviewArgs = {
  cafeId: Scalars['String'];
  date: Scalars['Date'];
};


export type QueryBrandWithUsdaManufacturerNameArgs = {
  usdaManufacturerName: Scalars['String'];
};


export type QueryBrandsArgs = {
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
};


export type QueryBulkOrderArgs = {
  bulkOrderId: Scalars['ID'];
};


export type QueryBulkOrderStatusArgs = {
  bulkOrderId: Scalars['ID'];
};


export type QueryBulkOrdersForMealMenuArgs = {
  mealMenuId: Scalars['ID'];
};


export type QueryBulkOrdersForTeamArgs = {
  teamId: Scalars['ID'];
};


export type QueryBulkOrdersForUserArgs = {
  userId: Scalars['ID'];
};


export type QueryCateringRestaurantMenuSearchArgs = {
  query: Maybe<Scalars['String']>;
  restaurantTypeIds: Maybe<Array<Scalars['ID']>>;
  paymentMethodIds: Maybe<Array<Scalars['ID']>>;
  cuisineIds: Maybe<Array<Scalars['ID']>>;
  dietIds: Maybe<Array<Scalars['ID']>>;
  pagination: Maybe<CursorPaginationInput>;
};


export type QueryCbordServiceMenusArgs = {
  serviceUnitOid: Scalars['Int'];
  start: Scalars['Date'];
  end: Scalars['Date'];
  facilityOid: Maybe<Scalars['Int']>;
};


export type QueryClientCredentialOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
};


export type QueryCompassMenuItemsArgs = {
  locationId: Scalars['Int'];
  startDate: Scalars['Date'];
  days: Scalars['Int'];
};


export type QueryCompassMrnRecipeArgs = {
  query: Scalars['String'];
};


export type QueryCompassRecipesArgs = {
  query: Scalars['String'];
};


export type QueryConvertedServingArgs = {
  input: ConvertedServingInput;
};


export type QueryCuisineCursorConnectionArgs = {
  query: Maybe<Scalars['String']>;
  input: CursorPaginationInput;
};


export type QueryCuisineOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryCurrentMenuItemArgs = {
  menuItemId: Scalars['ID'];
};


export type QueryDaySummariesArgs = {
  athleteId: Scalars['ID'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};


export type QueryDeliveryLocationCursorConnectionArgs = {
  query: Maybe<Scalars['String']>;
  teamLabelId: Maybe<Scalars['ID']>;
  input: CursorPaginationInput;
};


export type QueryDietCursorConnectionArgs = {
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryDietOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryDietitiansICanMessageCursorConnectionArgs = {
  query: Scalars['String'];
  input: CursorPaginationInput;
};


export type QueryDiningStationTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryDiningStationTemplateCursorConnectionArgs = {
  query: Maybe<Scalars['String']>;
  input: CursorPaginationInput;
};


export type QueryDiningStationTemplateOffsetConnectionArgs = {
  query: Maybe<Scalars['String']>;
  input: OffsetPaginationInput;
};


export type QueryExchangeSetArgs = {
  id: Scalars['ID'];
};


export type QueryFoodArgs = {
  id: Scalars['ID'];
};


export type QueryFoodGroupArgs = {
  id: Scalars['ID'];
};


export type QueryFoodGroupsArgs = {
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
};


export type QueryFoodGroupsByIdArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryFoodItemArgs = {
  id: Scalars['ID'];
};


export type QueryFoodLogMealArgs = {
  id: Scalars['ID'];
};


export type QueryFoodOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
  localeCodes: Maybe<Array<Scalars['String']>>;
  dataSources: Maybe<Array<FoodDataSource>>;
};


export type QueryFoodOrRecipeArgs = {
  id: Scalars['ID'];
};


export type QueryFoodsArgs = {
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
  localeCodes: Maybe<Array<Scalars['String']>>;
  dataSources: Maybe<Array<FoodDataSource>>;
};


export type QueryFoodsByIdArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryGenericFoodArgs = {
  id: Scalars['ID'];
};


export type QueryGenericFoodsArgs = {
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
};


export type QueryGetOpenAiImageArgs = {
  servingAmount: Scalars['Float'];
  servingUnit: Scalars['String'];
  foodName: Scalars['String'];
};


export type QueryGoalConfigurationArgs = {
  id: Scalars['ID'];
};


export type QueryGoalConfigurationsForAthleteOffsetConnectionArgs = {
  athleteId: Scalars['ID'];
  input: OffsetPaginationInput;
  current: Scalars['Boolean'];
};


export type QueryGoalConfigurationsForTeamOffsetConnectionArgs = {
  teamId: Scalars['ID'];
  input: OffsetPaginationInput;
  tagIds: Array<Scalars['ID']>;
  query: Maybe<Scalars['String']>;
  categoryId: Maybe<Scalars['ID']>;
};


export type QueryGoalTrackerGoalOffsetConnectionArgs = {
  goalConfigurationId: Scalars['ID'];
  input: OffsetPaginationInput;
};


export type QueryGoalTrackerGoalsArgs = {
  goalConfigurationId: Scalars['ID'];
};


export type QueryGroceryListArgs = {
  id: Scalars['ID'];
};


export type QueryIsTagNameUniqueArgs = {
  name: Scalars['String'];
};


export type QueryLoggingRestaurantMenuSearchArgs = {
  query: Maybe<Scalars['String']>;
  restaurantTypeIds: Maybe<Array<Scalars['ID']>>;
  paymentMethodIds: Maybe<Array<Scalars['ID']>>;
  cuisineIds: Maybe<Array<Scalars['ID']>>;
  dietIds: Maybe<Array<Scalars['ID']>>;
  pagination: Maybe<CursorPaginationInput>;
};


export type QueryMealEventsArgs = {
  athleteId: Scalars['ID'];
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};


export type QueryMealMenuArgs = {
  mealMenuId: Scalars['ID'];
};


export type QueryMealMenuAttendeesArgs = {
  mealMenuId: Scalars['ID'];
  tagIds: Array<Scalars['ID']>;
};


export type QueryMealMenuPlateArgs = {
  id: Scalars['ID'];
};


export type QueryMealMenuPlateOrderArgs = {
  id: Scalars['ID'];
};


export type QueryMealMenuPlateOrdersArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryMealMenuPlateOrdersForUserArgs = {
  userId: Scalars['ID'];
};


export type QueryMealMenusArgs = {
  mealMenuIds: Array<Scalars['ID']>;
};


export type QueryMealMenusCursorConnectionArgs = {
  anchorDate: Scalars['DateTime'];
  input: CursorPaginationInput;
};


export type QueryMealMenusInDateRangeArgs = {
  after: Scalars['Date'];
  before: Scalars['Date'];
  timezone: Scalars['Timezone'];
};


export type QueryMealMenusInRangeArgs = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};


export type QueryMealMenusWithCheckInInRangeArgs = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  selectedTeamIds: Array<Scalars['ID']>;
};


export type QueryMealOptionCursorConnectionForMealTemplateArgs = {
  mealTemplateId: Scalars['ID'];
  input: Maybe<CursorPaginationInput>;
};


export type QueryMealOptionSuggestionCursorConnectionArgs = {
  macros: MacrosInput;
  mealType: MealType;
  maxErrorPercent: Scalars['Int'];
  minErrorPercent: Maybe<Scalars['Int']>;
  pagination: Maybe<CursorPaginationInput>;
  avoidFoodGroupIds: Maybe<Array<Scalars['ID']>>;
  avoidFoodIds: Maybe<Array<Scalars['ID']>>;
  localeCode: Maybe<Scalars['String']>;
};


export type QueryMealOptionsForMealPlansCursorConnectionArgs = {
  mealPlanIds: Array<Scalars['ID']>;
  mealTypes: Maybe<Array<MealType>>;
  query: Maybe<Scalars['String']>;
  input: Maybe<CursorPaginationInput>;
};


export type QueryMealOptionsForMealPlansCursorConnectionWithRandomSeededSortArgs = {
  mealPlanIds: Array<Scalars['ID']>;
  mealTypes: Maybe<Array<MealType>>;
  query: Maybe<Scalars['String']>;
  input: Maybe<CursorPaginationInput>;
  sortSeedValue: Scalars['Int'];
};


export type QueryMealOptionsForMealPlansPreviewArgs = {
  mealPlanIds: Array<Scalars['ID']>;
  mealTypes: Maybe<Array<MealType>>;
  query: Maybe<Scalars['String']>;
};


export type QueryMealOptionsForMealPlansPreviewWithRandomSeededSortArgs = {
  mealPlanIds: Array<Scalars['ID']>;
  mealTypes: Maybe<Array<MealType>>;
  query: Maybe<Scalars['String']>;
  sortSeedValue: Scalars['Int'];
};


export type QueryMealPlanArgs = {
  id: Scalars['ID'];
};


export type QueryMealPlanForCopyCursorConnectionArgs = {
  input: CursorPaginationInput;
  athleteName: Maybe<Scalars['String']>;
  mealPlanName: Maybe<Scalars['String']>;
};


export type QueryMealPlanOffsetConnectionArgs = {
  teamId: Scalars['ID'];
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryMealPlanTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryMealPlanTemplateOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  searchText: Scalars['String'];
  sortAscending: Scalars['Boolean'];
};


export type QueryMenuItemArgs = {
  menuItemId: Scalars['ID'];
};


export type QueryMenuItemCursorConnectionArgs = {
  query: Maybe<Scalars['String']>;
  input: CursorPaginationInput;
};


export type QueryMenuItemOffsetConnectionArgs = {
  query: Maybe<Scalars['String']>;
  input: OffsetPaginationInput;
};


export type QueryMenuItemRatingOffsetConnectionArgs = {
  menuItemHistoryId: Scalars['ID'];
  currentOnly: Scalars['Boolean'];
  input: OffsetPaginationInput;
};


export type QueryMenuItemsArgs = {
  menuItemIds: Array<Scalars['ID']>;
};


export type QueryMenuOrderArgs = {
  id: Scalars['ID'];
};


export type QueryMenuOrderCursorConnectionForItemsArgs = {
  menuItemIds: Array<Scalars['ID']>;
  input: Maybe<CursorPaginationInput>;
};


export type QueryMenuOrderCursorConnectionForItemsForAthleteArgs = {
  menuItemIds: Array<Scalars['ID']>;
  athleteId: Scalars['ID'];
  input: Maybe<CursorPaginationInput>;
};


export type QueryMenuOrderRateLimitRemaindersArgs = {
  mealMenuId: Scalars['ID'];
};


export type QueryMenuOrderSuggestionsArgs = {
  mealMenuId: Scalars['ID'];
  targetMacros: MacrosInput;
  avoidFoodGroupIds: Maybe<Array<Scalars['ID']>>;
  limit: Maybe<Scalars['Int']>;
};


export type QueryMenuOrdersArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryMobileUpdateForVersionArgs = {
  version: Scalars['Int'];
};


export type QueryMyCurrentGroceryListArgs = {
  date: Scalars['Date'];
};


export type QueryMyCurrentPrepPlanArgs = {
  date: Scalars['Date'];
};


export type QueryMyGoalTrackerGoalsForDateArgs = {
  date: Scalars['String'];
};


export type QueryMyPastPrepPlanCursorConnectionArgs = {
  input: CursorPaginationInput;
  date: Scalars['Date'];
};


export type QueryMyRecipeCursorConnectionArgs = {
  input: Maybe<CursorPaginationInput>;
  query: Maybe<Scalars['String']>;
  mealTypes: Maybe<Array<MealType>>;
  prepTimeInMinutes: Maybe<Scalars['Int']>;
  cookTimeInMinutes: Maybe<Scalars['Int']>;
};


export type QueryMyRecipePreviewArgs = {
  query: Maybe<Scalars['String']>;
  mealTypes: Maybe<Array<MealType>>;
  prepTimeInMinutes: Maybe<Scalars['Int']>;
  cookTimeInMinutes: Maybe<Scalars['Int']>;
};


export type QueryMyTimelineMealsInRangeArgs = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};


export type QueryNamedTagArgs = {
  id: Scalars['ID'];
};


export type QueryNamedTagCursorConnectionArgs = {
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryNamedTagOffsetConnectionArgs = {
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryNamedTagSearchArgs = {
  query: Maybe<Scalars['String']>;
};


export type QueryNotSharedRecipeCursorConnectionArgs = {
  input: Maybe<CursorPaginationInput>;
  query: Maybe<Scalars['String']>;
  mealTypes: Maybe<Array<MealType>>;
};


export type QueryNotesForTeamArgs = {
  teamId: Scalars['ID'];
  noteTypeId: Maybe<Scalars['ID']>;
};


export type QueryNotificationArgs = {
  id: Scalars['ID'];
};


export type QueryNotificationCursorConnectionArgs = {
  input: Maybe<CursorPaginationInput>;
};


export type QueryNutrientAmountsForFoodsArgs = {
  foodIds: Array<Scalars['ID']>;
};


export type QueryOrgArgs = {
  id: Scalars['ID'];
};


export type QueryOrgFoodGroupsArgs = {
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
};


export type QueryOrgFoodGroupsOffsetConnectionArgs = {
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
  sortOrder: SortOrder;
};


export type QueryOrgFoodOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryOrgFoodsArgs = {
  searchTerm: Maybe<Scalars['String']>;
  limit: Maybe<Scalars['Int']>;
  isRecipeIngredientOnly: Maybe<Scalars['Boolean']>;
};


export type QueryOrgGroupArgs = {
  id: Scalars['ID'];
};


export type QueryOrgGroupFoodOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
  ownerIds: Maybe<Array<Scalars['String']>>;
};


export type QueryOrgGroupFoodOwnerCursorConnectionArgs = {
  pagination: CursorPaginationInput;
  searchText: Scalars['String'];
};


export type QueryOrgGroupFoodsArgs = {
  searchTerm: Maybe<Scalars['String']>;
  limit: Maybe<Scalars['Int']>;
  isRecipeIngredientOnly: Maybe<Scalars['Boolean']>;
};


export type QueryOrgGroupMealPlanTemplateOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  searchText: Scalars['String'];
  ownerIds: Maybe<Array<Scalars['ID']>>;
  sortAscending: Scalars['Boolean'];
};


export type QueryOrgGroupMealPlanTemplateOwnerCursorConnectionArgs = {
  pagination: CursorPaginationInput;
  searchText: Scalars['String'];
};


export type QueryOrgGroupRecipeOffsetConnectionArgs = {
  input: OffsetPaginationInput;
  sort: Maybe<RecipeSort>;
  query: Maybe<Scalars['String']>;
  createdById: Maybe<Scalars['ID']>;
  ownerIds: Maybe<Array<Scalars['ID']>>;
  mealTypes: Maybe<Array<MealType>>;
  orgId: Maybe<Scalars['ID']>;
};


export type QueryOrgGroupRecipeOwnerCursorConnectionArgs = {
  pagination: CursorPaginationInput;
  searchText: Scalars['String'];
};


export type QueryOrgGroupRecipesArgs = {
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
  createdById: Maybe<Scalars['ID']>;
};


export type QueryPaymentMethodCursorConnectionArgs = {
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryPaymentMethodOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryPlannedMenuArgs = {
  id: Scalars['ID'];
};


export type QueryPlannedMenuMealsForWeekArgs = {
  plannedMenuWeekId: Scalars['ID'];
};


export type QueryPlannedMenuOffsetConnectionArgs = {
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryPlannedMenuWeeksArgs = {
  plannedMenuWeekIds: Array<Scalars['ID']>;
};


export type QueryPositionBySportCursorConnectionArgs = {
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryPrepPlanArgs = {
  id: Scalars['ID'];
};


export type QueryPrepPlanMealArgs = {
  id: Scalars['ID'];
};


export type QueryPrepPlanMealAssignmentsArgs = {
  prepPlanId: Scalars['ID'];
};


export type QueryPrepPlanMealsForTimelineMealArgs = {
  input: PrepPlanMealsForTimelineMealInput;
};


export type QueryProfileUsersArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryRecentMealCursorConnectionForMealTypesArgs = {
  mealTypes: Maybe<Array<MealType>>;
  query: Maybe<Scalars['String']>;
  input: Maybe<CursorPaginationInput>;
};


export type QueryRecentMealsForMealTypesPreviewArgs = {
  mealTypes: Maybe<Array<MealType>>;
  query: Maybe<Scalars['String']>;
};


export type QueryRecipeArgs = {
  id: Scalars['ID'];
};


export type QueryRecipeCursorConnectionArgs = {
  input: Maybe<CursorPaginationInput>;
  createdById: Maybe<Scalars['ID']>;
};


export type QueryRecipeOffsetConnectionArgs = {
  input: OffsetPaginationInput;
  sort: Maybe<RecipeSort>;
  query: Maybe<Scalars['String']>;
  createdById: Maybe<Scalars['ID']>;
  mealTypes: Maybe<Array<MealType>>;
  orgId: Maybe<Scalars['ID']>;
};


export type QueryRecipesArgs = {
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
  createdById: Maybe<Scalars['ID']>;
};


export type QueryRecipesByIdArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryRestauranMenuLinkSuggestionsArgs = {
  restaurantMenuLinkId: Scalars['ID'];
  targetMacros: MacrosInput;
  avoidFoodGroupIds: Maybe<Array<Scalars['ID']>>;
  limit: Maybe<Scalars['Int']>;
};


export type QueryRestaurantArgs = {
  id: Scalars['ID'];
};


export type QueryRestaurantFoodCursorConnectionArgs = {
  restaurantId: Scalars['ID'];
  input: Maybe<CursorPaginationInput>;
  query: Maybe<Scalars['String']>;
};


export type QueryRestaurantFoodOffsetConnectionArgs = {
  restaurantId: Scalars['ID'];
  pagination: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryRestaurantFoodsArgs = {
  restaurantId: Scalars['ID'];
  searchTerm: Maybe<Scalars['String']>;
  limit: Maybe<Scalars['Int']>;
};


export type QueryRestaurantGeoLocationSearchArgs = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  pagination: Maybe<CursorPaginationInput>;
};


export type QueryRestaurantLocationSearchArgs = {
  deliveryLocationId: Scalars['ID'];
  restaurantIds: Maybe<Array<Scalars['ID']>>;
  restaurantTypeIds: Maybe<Array<Scalars['ID']>>;
  paymentMethodIds: Maybe<Array<Scalars['ID']>>;
  cuisineIds: Maybe<Array<Scalars['ID']>>;
  dietIds: Maybe<Array<Scalars['ID']>>;
  query: Maybe<Scalars['String']>;
  pagination: Maybe<CursorPaginationInput>;
};


export type QueryRestaurantMenuArgs = {
  id: Scalars['ID'];
};


export type QueryRestaurantMenuLinkArgs = {
  id: Scalars['ID'];
};


export type QueryRestaurantMenuLinkOrderArgs = {
  id: Scalars['ID'];
};


export type QueryRestaurantMenuLinkPlateOrderArgs = {
  id: Scalars['ID'];
};


export type QueryRestaurantMenuLogArgs = {
  id: Scalars['ID'];
};


export type QueryRestaurantMenuSuggestionsArgs = {
  restaurantMenuId: Scalars['ID'];
  targetMacros: MacrosInput;
  avoidFoodGroupIds: Maybe<Array<Scalars['ID']>>;
  limit: Maybe<Scalars['Int']>;
};


export type QueryRestaurantTypeCursorConnectionArgs = {
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryRestaurantTypeOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QuerySchedulesForTeamArgs = {
  teamId: Scalars['ID'];
};


export type QueryScoringSystemOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  searchText: Scalars['String'];
};


export type QuerySelectedAthletesCursorConnectionArgs = {
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
  athleteIds: Array<Scalars['ID']>;
};


export type QueryServingAmountGroupCursorConnectionForFoodLogMealArgs = {
  foodLogMealId: Scalars['ID'];
  input: Maybe<CursorPaginationInput>;
};


export type QueryServingAmountGroupCursorConnectionForMealTypeArgs = {
  mealType: MealType;
  input: Maybe<CursorPaginationInput>;
  athleteId: Maybe<Scalars['ID']>;
};


export type QueryServingsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QuerySharedExchangeSetOffsetConnectionArgs = {
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QuerySharedFoodGroupsArgs = {
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
};


export type QuerySharedFoodGroupsCursorConnectionArgs = {
  pagination: CursorPaginationInput;
  searchText: Maybe<Scalars['String']>;
};


export type QuerySharedFoodGroupsOffsetConnectionArgs = {
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
  sortOrder: SortOrder;
};


export type QuerySharedMealPlanTemplateArgs = {
  id: Scalars['ID'];
};


export type QuerySharedMealPlanTemplateOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  searchText: Scalars['String'];
  sortAscending: Scalars['Boolean'];
};


export type QuerySharedRecipeCursorConnectionArgs = {
  input: Maybe<CursorPaginationInput>;
  query: Maybe<Scalars['String']>;
  mealTypes: Maybe<Array<MealType>>;
  prepTimeInMinutes: Maybe<Scalars['Int']>;
  cookTimeInMinutes: Maybe<Scalars['Int']>;
};


export type QuerySharedRecipePreviewArgs = {
  query: Maybe<Scalars['String']>;
  mealTypes: Maybe<Array<MealType>>;
  prepTimeInMinutes: Maybe<Scalars['Int']>;
  cookTimeInMinutes: Maybe<Scalars['Int']>;
};


export type QuerySodexoMealTimesByDayArgs = {
  locationId: Scalars['String'];
  menuSourceKey: Scalars['String'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};


export type QuerySportArgs = {
  id: Scalars['ID'];
};


export type QueryStaffUserSearchOffsetConnectionArgs = {
  pagination: OffsetPaginationInput;
  searchParams: Maybe<Scalars['String']>;
};


export type QueryTagArgs = {
  id: Scalars['ID'];
};


export type QueryTeamArgs = {
  id: Scalars['ID'];
};


export type QueryTeamCursorConnectionArgs = {
  input: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryTeamGoalConfigurationArgs = {
  teamId: Scalars['ID'];
};


export type QueryTeamLabelCursorConnectionArgs = {
  query: Maybe<Scalars['String']>;
  input: CursorPaginationInput;
};


export type QueryTeamSearchArgs = {
  query: Maybe<Scalars['String']>;
};


export type QueryTeamsByIdArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryTeamworksCalendarForAthleteInRangeArgs = {
  athleteId: Scalars['ID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};


export type QueryTeamworksDisabledProfileIdsArgs = {
  teamworksOrgId: Scalars['Int'];
};


export type QueryTeamworksOrgArgs = {
  teamworksId: Scalars['Int'];
};


export type QueryTeamworksOrgCursorConnectionArgs = {
  pagination: CursorPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryTeamworksOrgGroupsArgs = {
  teamworksOrgId: Scalars['Int'];
  type: Maybe<TeamworksGroupType>;
  teamId: Maybe<Scalars['Int']>;
};


export type QueryTeamworksProfileCursorConnectionArgs = {
  teamworksOrgId: Scalars['Int'];
  pagination: CursorPaginationInput;
};


export type QueryTeamworksScheduleForAthleteInRangeArgs = {
  athleteId: Scalars['ID'];
  startDate: Scalars['Date'];
  endDate: Scalars['Date'];
};


export type QueryTeamworksSportMappingsArgs = {
  query: Maybe<Scalars['String']>;
};


export type QueryTerritoryOffsetConnectionArgs = {
  input: OffsetPaginationInput;
  query: Maybe<Scalars['String']>;
};


export type QueryTimelineMealArgs = {
  id: Scalars['ID'];
};


export type QueryUnitArgs = {
  id: Scalars['ID'];
};


export type QueryUnsubmittedAgreementFormForUserArgs = {
  orgId: Scalars['ID'];
};


export type QueryUpcomingMealMenusArgs = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};


export type QueryUserFoodCursorConnectionArgs = {
  input: Maybe<CursorPaginationInput>;
};


export type QueryUserFoodsArgs = {
  searchTerm: Scalars['String'];
  limit: Maybe<Scalars['Int']>;
};


export type QueryUsersICanMessageCursorConnectionArgs = {
  role: Role;
  teamId: Maybe<Scalars['String']>;
  query: Scalars['String'];
  input: CursorPaginationInput;
};

export enum RmrMethod {
  cunningham = 'cunningham',
  mifflin = 'mifflin',
  harrisBenedict = 'harrisBenedict',
  average = 'average'
}


export type Rating = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  value: RatingValue;
  review: Maybe<Scalars['String']>;
};

export type RatingCounts = {
  __typename?: 'RatingCounts';
  one: Scalars['Int'];
  two: Scalars['Int'];
  three: Scalars['Int'];
  four: Scalars['Int'];
  five: Scalars['Int'];
};

export enum RatingValue {
  one = 'one',
  two = 'two',
  three = 'three',
  four = 'four',
  five = 'five'
}

export type RecentTimelineMeal = {
  __typename?: 'RecentTimelineMeal';
  type: MealType;
  servingAmounts: Array<ServingAmount>;
};

export type RecentTimelineMealCursorConnection = {
  __typename?: 'RecentTimelineMealCursorConnection';
  edges: Array<RecentTimelineMeal>;
  pageInfo: CursorPageInfo;
};

export type RecieveAthleteTransferInput = {
  id: Scalars['ID'];
  teamId: Scalars['ID'];
};

export type RecieveAthleteTransferPayload = {
  __typename?: 'RecieveAthleteTransferPayload';
  athleteTransfer: AthleteTransfer;
};

export type Recipe = {
  __typename?: 'Recipe';
  id: Scalars['ID'];
  name: Scalars['String'];
  exchangeTypes: Maybe<Array<ExchangeType>>;
  prepTimeInMinutes: Maybe<Scalars['Int']>;
  cookTimeInMinutes: Maybe<Scalars['Int']>;
  steps: Maybe<Array<Scalars['String']>>;
  note: Maybe<Scalars['String']>;
  cho: Maybe<Scalars['Float']>;
  pro: Maybe<Scalars['Float']>;
  fat: Maybe<Scalars['Float']>;
  createdAt: Scalars['DateTime'];
  isCurrent: Scalars['Boolean'];
  hasFullAccess: Scalars['Boolean'];
  org: Maybe<Org>;
  orgGroup: Maybe<OrgGroup>;
  mealTypes: Maybe<Array<MealType>>;
  ingredients: Array<ServingAmount>;
  servings: Array<Serving>;
  images: Array<Image>;
  isAiGenerated: Scalars['Boolean'];
  isAiTranscribed: Scalars['Boolean'];
  descendantFoods: Array<Food>;
  descendantRecipes: Array<Recipe>;
  createdBy: User;
  owner: User;
  history: RecipeHistory;
  isShared: Scalars['Boolean'];
  score: Maybe<Score>;
  foodCategory: Maybe<RecipeFoodCategory>;
  copiedCount: Scalars['Int'];
};

export type RecipeCursorConnection = {
  __typename?: 'RecipeCursorConnection';
  edges: Array<Recipe>;
  pageInfo: CursorPageInfo;
};

export type RecipeFoodCategory = {
  __typename?: 'RecipeFoodCategory';
  category: FoodCategoryType;
};

export type RecipeFormInput = {
  name: Scalars['String'];
  ingredients: Array<CreateServingAmountInput>;
  exchangeTypes: Maybe<Array<ExchangeType>>;
  steps: Maybe<Array<Scalars['String']>>;
  note: Maybe<Scalars['String']>;
  macros: Maybe<MacrosInput>;
};

export type RecipeFullInput = {
  name: Scalars['String'];
  ingredients: Array<CreateServingAmountInput>;
  exchangeTypes: Maybe<Array<ExchangeType>>;
  prepTimeInMinutes: Maybe<Scalars['Int']>;
  cookTimeInMinutes: Maybe<Scalars['Int']>;
  steps: Maybe<Array<Scalars['String']>>;
  note: Maybe<Scalars['String']>;
  macros: Maybe<MacrosInput>;
  images: Array<ImageInput>;
  isShared: Scalars['Boolean'];
  mealTypes: Maybe<Array<MealType>>;
  scoreValue: Maybe<Scalars['Float']>;
  foodCategory: Maybe<FoodCategoryType>;
  isAiGenerated: Maybe<Scalars['Boolean']>;
  isAiTranscribed: Maybe<Scalars['Boolean']>;
};

export type RecipeHistory = {
  __typename?: 'RecipeHistory';
  id: Scalars['ID'];
  current: Recipe;
  all: Array<Recipe>;
};

export type RecipeOffsetConnection = {
  __typename?: 'RecipeOffsetConnection';
  edges: Array<Recipe>;
  pageInfo: OffsetPageInfo;
};

export type RecipeServingFormInput = {
  units: Scalars['String'];
  perRecipeYield: Scalars['Float'];
  isDefault: Scalars['Boolean'];
  defaultAmount: Scalars['Float'];
};

export type RecipeServingFullInput = {
  units: ServingUnitsInput;
  perRecipeYield: Scalars['Float'];
  isDefault: Scalars['Boolean'];
  defaultAmount: Scalars['Float'];
};

export type RecipeServingSimpleInput = {
  units: ServingUnitsInput;
  perRecipeYield: Scalars['Float'];
};

export type RecipeSimpleInput = {
  name: Scalars['String'];
  ingredients: Array<CreateServingAmountInput>;
};

export type RecipeSort = {
  key: RecipeSortKey;
  direction: RecipeSortDirection;
};

export enum RecipeSortDirection {
  asc = 'ASC',
  desc = 'DESC'
}

export enum RecipeSortKey {
  createdAt = 'createdAt'
}

export type ReclaimAthleteTransferInput = {
  id: Scalars['ID'];
};

export type ReclaimAthleteTransferPayload = {
  __typename?: 'ReclaimAthleteTransferPayload';
  athleteTransfer: AthleteTransfer;
};

export type RecordMenuOrderSuggestionDecisionInput = {
  userId: Scalars['ID'];
  mealMenuId: Maybe<Scalars['ID']>;
  restaurantMenuLinkId: Maybe<Scalars['ID']>;
  restaurantMenuId: Maybe<Scalars['ID']>;
  targetMacros: MacrosInput;
  suggestion: MenuOrderSuggestionInput;
};

export type RecordMenuOrderSuggestionsViewInput = {
  userId: Scalars['ID'];
  mealMenuId: Maybe<Scalars['ID']>;
  restaurantMenuLinkId: Maybe<Scalars['ID']>;
  restaurantMenuId: Maybe<Scalars['ID']>;
  targetMacros: MacrosInput;
  suggestions: Array<MenuOrderSuggestionInput>;
};

export type RefreshClientCredentialSecretInput = {
  id: Scalars['ID'];
};

export type RefreshClientCredentialSecretPayload = {
  __typename?: 'RefreshClientCredentialSecretPayload';
  clientCredential: ClientCredential;
  clientSecret: Scalars['String'];
};

export type RefreshTeamworksSyncStatusInput = {
  orgId: Scalars['ID'];
};

export type RefreshTeamworksSyncStatusPayload = {
  __typename?: 'RefreshTeamworksSyncStatusPayload';
  org: Org;
};

export enum RefundPolicyType {
  full = 'full',
  labor = 'labor'
}

export type RemoveActivityInput = {
  activityId: Scalars['ID'];
};

export type RemoveAthleteDislikedFoodGroupPayload = {
  __typename?: 'RemoveAthleteDislikedFoodGroupPayload';
  athlete: Athlete;
};

export type RemoveAthleteDislikedFoodPayload = {
  __typename?: 'RemoveAthleteDislikedFoodPayload';
  athlete: Athlete;
};

export type RemoveAthleteLikedFoodGroupPayload = {
  __typename?: 'RemoveAthleteLikedFoodGroupPayload';
  athlete: Athlete;
};

export type RemoveAthleteLikedFoodPayload = {
  __typename?: 'RemoveAthleteLikedFoodPayload';
  athlete: Athlete;
};

export type RemoveBulkOrderInput = {
  bulkOrderId: Scalars['ID'];
};

export type RemoveBulkOrderPayload = {
  __typename?: 'RemoveBulkOrderPayload';
  bulkOrderId: Scalars['ID'];
  teamId: Scalars['ID'];
  deliveryLocation: Scalars['String'];
  mealMenuId: Scalars['ID'];
  affectedMealMenuPlateOrderIds: Array<Scalars['ID']>;
};

export type RemoveFoodLogActivityInput = {
  foodLogActivityId: Scalars['ID'];
};

export type RemoveFoodLogActivityPayload = {
  __typename?: 'RemoveFoodLogActivityPayload';
  foodLogActivityId: Scalars['ID'];
};

export type RemoveFoodLogActivityTemplateInput = {
  foodLogActivityId: Scalars['ID'];
};

export type RemoveFoodLogActivityTemplatePayload = {
  __typename?: 'RemoveFoodLogActivityTemplatePayload';
  foodLogActivity: FoodLogActivity;
};

export type RemoveFoodLogMealImageInput = {
  foodLogMealId: Scalars['ID'];
  imageId: Scalars['ID'];
};

export type RemoveFoodLogMealImagePayload = {
  __typename?: 'RemoveFoodLogMealImagePayload';
  foodLogMeal: FoodLogMeal;
};

export type RemoveFoodLogMealInput = {
  foodLogMealId: Scalars['ID'];
};

export type RemoveFoodLogMealPayload = {
  __typename?: 'RemoveFoodLogMealPayload';
  foodLogMealId: Scalars['ID'];
};

export type RemoveFoodLogMealTemplateInput = {
  foodLogMealId: Scalars['ID'];
};

export type RemoveFoodLogMealTemplatePayload = {
  __typename?: 'RemoveFoodLogMealTemplatePayload';
  foodLogMeal: FoodLogMeal;
};

export type RemoveMealInput = {
  mealId: Scalars['ID'];
};

export type RemoveMealMenuDiningStationInput = {
  mealMenuDiningStationId: Scalars['ID'];
};

export type RemoveMealMenuLogItemInput = {
  mealMenuLogItemId: Scalars['ID'];
};

export type RemoveMealMenuLogItemPayload = {
  __typename?: 'RemoveMealMenuLogItemPayload';
  timelineMeal: Maybe<TimelineMeal>;
  removedTimelineMealId: Maybe<Scalars['ID']>;
  mealMenuLogItemId: Scalars['ID'];
};

export type RemoveMealOptionInput = {
  mealOptionId: Scalars['ID'];
};

export type RemoveMealOptionPayload = {
  __typename?: 'RemoveMealOptionPayload';
  mealOptionId: Scalars['ID'];
};

export type RemoveMealOptionPendingInput = {
  mealOptionPendingId: Scalars['ID'];
};

export type RemoveMealOptionPendingPayload = {
  __typename?: 'RemoveMealOptionPendingPayload';
  mealOptionPendingId: Scalars['ID'];
};

export type RemoveMealPlanDateForAthleteInput = {
  date: Scalars['Date'];
  athleteId: Scalars['ID'];
};

export type RemoveMealPlanDateForAthletePayload = {
  __typename?: 'RemoveMealPlanDateForAthletePayload';
  date: Scalars['Date'];
};

export type RemoveMealPlanDateInput = {
  date: Scalars['Date'];
};

export type RemoveMealPlanDatePayload = {
  __typename?: 'RemoveMealPlanDatePayload';
  date: Scalars['Date'];
};

export type RemoveMenuItemChoiceInput = {
  menuItemChoiceId: Scalars['ID'];
};

export type RemoveMenuItemChoiceOptionInput = {
  menuItemChoiceOptionId: Scalars['ID'];
};

export type RemoveMenuItemInput = {
  menuItemId: Scalars['ID'];
};

export type RemoveMenuOrderImageInput = {
  menuOrderId: Scalars['ID'];
  imageId: Scalars['ID'];
};

export type RemoveMenuOrderImagePayload = {
  __typename?: 'RemoveMenuOrderImagePayload';
  menuOrder: MenuOrder;
};

export type RemoveMenuOrderInput = {
  menuOrderId: Scalars['ID'];
};

export type RemoveMenuOrderMealTemplateInput = {
  menuOrderId: Scalars['ID'];
};

export type RemoveMenuOrderMealTemplatePayload = {
  __typename?: 'RemoveMenuOrderMealTemplatePayload';
  menuOrder: MenuOrder;
};

export type RemoveMenuOrderPayload = {
  __typename?: 'RemoveMenuOrderPayload';
  menuOrderId: Scalars['ID'];
  removedTimelineMealId: Maybe<Scalars['ID']>;
  timelineMeal: Maybe<TimelineMeal>;
};

export type RemovePrepPlanMealInput = {
  prepPlanMealId: Scalars['ID'];
  guidedFlow: Maybe<Scalars['Boolean']>;
};

export type RemovePrepPlanMealPayload = {
  __typename?: 'RemovePrepPlanMealPayload';
  removedPrepPlanMealId: Scalars['ID'];
};

export type RemoveRecipeImageInput = {
  recipeId: Scalars['ID'];
  imageId: Scalars['ID'];
};

export type RemoveRecipeImagePayload = {
  __typename?: 'RemoveRecipeImagePayload';
  recipe: Recipe;
};

export type RemoveRestaurantMenuLinkInput = {
  restaurantMenuLinkId: Scalars['ID'];
};

export type RemoveRestaurantMenuLinkOrderInput = {
  restaurantMenuLinkOrderId: Scalars['ID'];
};

export type RemoveRestaurantMenuLinkOrderMealTemplateInput = {
  restaurantMenuLinkOrderId: Scalars['ID'];
};

export type RemoveRestaurantMenuLinkOrderMealTemplatePayload = {
  __typename?: 'RemoveRestaurantMenuLinkOrderMealTemplatePayload';
  restaurantMenuLinkOrder: RestaurantMenuLinkOrder;
};

export type RemoveRestaurantMenuLinkOrderPayload = {
  __typename?: 'RemoveRestaurantMenuLinkOrderPayload';
  restaurantMenuLinkOrderId: Scalars['ID'];
  timelineMeal: Maybe<TimelineMeal>;
  removedTimelineMealId: Maybe<Scalars['ID']>;
};

export type RemoveRestaurantMenuLinkPayload = {
  __typename?: 'RemoveRestaurantMenuLinkPayload';
  restaurantMenuLinkId: Scalars['ID'];
};

export type RemoveRestaurantMenuLinkPlateOrderInput = {
  restaurantMenuLinkPlateOrderId: Scalars['ID'];
};

export type RemoveRestaurantMenuLinkPlateOrderMealTemplateInput = {
  restaurantMenuLinkPlateOrderId: Scalars['ID'];
};

export type RemoveRestaurantMenuLinkPlateOrderMealTemplatePayload = {
  __typename?: 'RemoveRestaurantMenuLinkPlateOrderMealTemplatePayload';
  restaurantMenuLinkPlateOrder: RestaurantMenuLinkPlateOrder;
};

export type RemoveRestaurantMenuLinkPlateOrderPayload = {
  __typename?: 'RemoveRestaurantMenuLinkPlateOrderPayload';
  restaurantMenuLinkPlateOrderId: Scalars['ID'];
  timelineMeal: Maybe<TimelineMeal>;
  removedTimelineMealId: Maybe<Scalars['ID']>;
};

export type RemoveRestaurantMenuLogInput = {
  restaurantMenuLogId: Scalars['ID'];
};

export type RemoveRestaurantMenuLogItemInput = {
  restaurantMenuLogItemId: Scalars['ID'];
};

export type RemoveRestaurantMenuLogItemPayload = {
  __typename?: 'RemoveRestaurantMenuLogItemPayload';
  timelineMeal: Maybe<TimelineMeal>;
  removedTimelineMealId: Maybe<Scalars['ID']>;
  restaurantMenuLogItemId: Scalars['ID'];
};

export type RemoveRestaurantMenuLogMealTemplateInput = {
  restaurantMenuLogId: Scalars['ID'];
};

export type RemoveRestaurantMenuLogMealTemplatePayload = {
  __typename?: 'RemoveRestaurantMenuLogMealTemplatePayload';
  restaurantMenuLog: RestaurantMenuLog;
};

export type RemoveRestaurantMenuLogPayload = {
  __typename?: 'RemoveRestaurantMenuLogPayload';
  restaurantMenuLogId: Scalars['ID'];
  timelineMeal: Maybe<TimelineMeal>;
  removedTimelineMealId: Maybe<Scalars['ID']>;
};

export type RemoveRestaurantMenuSectionInput = {
  restaurantMenuSectionId: Scalars['ID'];
};

export type RemoveTimelineMealImageInput = {
  timelineMealId: Scalars['ID'];
  imageId: Scalars['ID'];
};

export type RemoveTimelineMealImagePayload = {
  __typename?: 'RemoveTimelineMealImagePayload';
  imageId: Scalars['ID'];
  timelineMeal: Maybe<TimelineMeal>;
  removedTimelineMealId: Maybe<Scalars['ID']>;
};

export type RemoveTimelineMealServingAmountInput = {
  timelineMealId: Scalars['ID'];
  removeServingAmountId: Scalars['ID'];
};

export type RemoveTimelineMealServingAmountPayload = {
  __typename?: 'RemoveTimelineMealServingAmountPayload';
  timelineMeal: Maybe<TimelineMeal>;
  removedTimelineMealId: Maybe<Scalars['ID']>;
};

export type Restaurant = {
  __typename?: 'Restaurant';
  id: Scalars['ID'];
  name: Scalars['String'];
  nutritionixId: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  website: Maybe<Scalars['String']>;
  proteinPortionSizeInOunces: Maybe<Scalars['Int']>;
  firstPartyDelivery: Scalars['Boolean'];
  athleteVisible: Scalars['Boolean'];
  refundPolicy: Maybe<RefundPolicyType>;
  refundDueBeforeInMinutes: Maybe<Scalars['Int']>;
  menu: Maybe<RestaurantMenu>;
  restaurantType: Maybe<RestaurantType>;
  paymentMethods: Array<PaymentMethod>;
  cuisines: Array<Cuisine>;
};

export type RestaurantGeoLocationInfo = {
  __typename?: 'RestaurantGeoLocationInfo';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  googlePlaceId: Scalars['String'];
  icon: Maybe<Scalars['String']>;
};

export type RestaurantGeoLocationSearchCursorConnection = {
  __typename?: 'RestaurantGeoLocationSearchCursorConnection';
  edges: Array<RestaurantGeoLocationSearchResult>;
  pageInfo: CursorPageInfo;
};

export type RestaurantGeoLocationSearchResult = {
  __typename?: 'RestaurantGeoLocationSearchResult';
  restaurant: Restaurant;
  restaurantMenu: RestaurantMenu;
  restaurantLocation: Maybe<RestaurantLocation>;
  locationInfo: RestaurantGeoLocationInfo;
};

export type RestaurantLocation = {
  __typename?: 'RestaurantLocation';
  id: Scalars['ID'];
  restaurant: Maybe<Restaurant>;
  address: Maybe<Address>;
  contacts: Array<RestaurantLocationContact>;
  googlePlaceId: Scalars['String'];
  notes: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  phoneNumber: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  cityInfo: Maybe<Scalars['String']>;
  deliveryLocations: Array<DeliveryLocation>;
};

export type RestaurantLocationContact = {
  __typename?: 'RestaurantLocationContact';
  id: Scalars['ID'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  title: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  phoneNumber: Maybe<Scalars['String']>;
  preferredContactMethod: PreferredContactMethodType;
};

export type RestaurantLocationContactInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  title: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  phoneNumber: Maybe<Scalars['String']>;
  preferredContactMethod: PreferredContactMethodType;
};

export type RestaurantLocationOffsetConnection = {
  __typename?: 'RestaurantLocationOffsetConnection';
  edges: Array<RestaurantLocation>;
  pageInfo: OffsetPageInfo;
};

export type RestaurantLocationSearchCursorConnection = {
  __typename?: 'RestaurantLocationSearchCursorConnection';
  edges: Array<RestaurantLocationSearchResult>;
  pageInfo: CursorPageInfo;
};

export type RestaurantLocationSearchResult = {
  __typename?: 'RestaurantLocationSearchResult';
  restaurant: Restaurant;
  restaurantMenu: RestaurantMenu;
  restaurantLocation: RestaurantLocation;
};

export type RestaurantMenu = {
  __typename?: 'RestaurantMenu';
  id: Scalars['ID'];
  sections: Array<RestaurantMenuSection>;
  supportedDiets: Array<Diet>;
};

export type RestaurantMenuLink = {
  __typename?: 'RestaurantMenuLink';
  id: Scalars['ID'];
  deliveryLocation: Maybe<DeliveryLocation>;
  restaurant: Restaurant;
  restaurantMenu: RestaurantMenu;
  restaurantLocation: Maybe<RestaurantLocation>;
  sections: Array<RestaurantMenuSectionLink>;
  plates: Array<RestaurantMenuLinkPlate>;
  allowCustomOrders: Scalars['Boolean'];
  allowPlateOrders: Scalars['Boolean'];
  orders: Array<RestaurantMenuLinkOrder>;
  myOrder: Maybe<RestaurantMenuLinkOrder>;
  orderForAthlete: Maybe<RestaurantMenuLinkOrder>;
  myPlateOrder: Maybe<RestaurantMenuLinkPlateOrder>;
  plateOrderForAthlete: Maybe<RestaurantMenuLinkPlateOrder>;
};


export type RestaurantMenuLinkOrderForAthleteArgs = {
  athleteId: Scalars['ID'];
};


export type RestaurantMenuLinkPlateOrderForAthleteArgs = {
  athleteId: Scalars['ID'];
};

export type RestaurantMenuLinkInput = {
  restaurantId: Scalars['ID'];
  restaurantMenuId: Scalars['ID'];
  deliveryLocationId: Maybe<Scalars['ID']>;
  restaurantLocationId: Maybe<Scalars['ID']>;
  sections: Array<RestaurantMenuSectionLinkInput>;
  plates: Array<AddRestaurantMenuLinkPlateInput>;
  allowCustomOrders: Scalars['Boolean'];
  allowPlateOrders: Scalars['Boolean'];
};

export type RestaurantMenuLinkOrder = MenuSelection & {
  __typename?: 'RestaurantMenuLinkOrder';
  id: Scalars['ID'];
  user: User;
  createdAt: Scalars['DateTime'];
  restaurantMenuLink: RestaurantMenuLink;
  items: Array<RestaurantMenuLinkOrderItem>;
  itemCount: Scalars['Int'];
  athlete: Maybe<Athlete>;
  userFullName: Scalars['String'];
  mealTemplate: Maybe<MealTemplate>;
  mealMenu: MealMenu;
  timelineMeal: Maybe<TimelineMeal>;
};

export type RestaurantMenuLinkOrderItem = MenuSelectionItem & {
  __typename?: 'RestaurantMenuLinkOrderItem';
  id: Scalars['ID'];
  amount: Scalars['Float'];
  percentConsumed: Maybe<Scalars['Float']>;
  menuItem: MenuItem;
  options: Array<RestaurantMenuLinkOrderItemOption>;
  order: RestaurantMenuLinkOrder;
  specialRequests: Maybe<Scalars['String']>;
};

export type RestaurantMenuLinkOrderItemInput = {
  menuItemId: Scalars['ID'];
  amount: Scalars['Float'];
  specialRequests: Maybe<Scalars['String']>;
  options: Array<RestaurantMenuLinkOrderItemOptionInput>;
};

export type RestaurantMenuLinkOrderItemOption = MenuSelectionItemOption & {
  __typename?: 'RestaurantMenuLinkOrderItemOption';
  amount: Scalars['Float'];
  menuItemChoiceOption: MenuItemChoiceOption;
  percentConsumed: Maybe<Scalars['Float']>;
};

export type RestaurantMenuLinkOrderItemOptionInput = {
  amount: Scalars['Float'];
  menuItemChoiceOptionId: Scalars['ID'];
};

export type RestaurantMenuLinkPlate = MenuSelection & {
  __typename?: 'RestaurantMenuLinkPlate';
  id: Scalars['ID'];
  position: Scalars['Int'];
  bulkOrderAmount: Maybe<Scalars['Int']>;
  items: Array<RestaurantMenuLinkPlateItem>;
  orders: Array<RestaurantMenuLinkPlateOrder>;
  isDefault: Scalars['Boolean'];
};

export type RestaurantMenuLinkPlateItem = MenuSelectionItem & {
  __typename?: 'RestaurantMenuLinkPlateItem';
  id: Scalars['ID'];
  position: Scalars['Int'];
  amount: Scalars['Float'];
  menuItem: MenuItem;
  options: Array<RestaurantMenuLinkPlateItemOption>;
  specialRequests: Maybe<Scalars['String']>;
  percentConsumed: Maybe<Scalars['Float']>;
};

export type RestaurantMenuLinkPlateItemOption = MenuSelectionItemOption & {
  __typename?: 'RestaurantMenuLinkPlateItemOption';
  amount: Scalars['Float'];
  menuItemChoiceOption: MenuItemChoiceOption;
  percentConsumed: Maybe<Scalars['Float']>;
};

export type RestaurantMenuLinkPlateItemOptionInput = {
  amount: Scalars['Float'];
  menuItemChoiceOptionId: Scalars['ID'];
};

export type RestaurantMenuLinkPlateOrder = MenuSelection & {
  __typename?: 'RestaurantMenuLinkPlateOrder';
  id: Scalars['ID'];
  user: User;
  createdAt: Scalars['DateTime'];
  restaurantMenuLink: RestaurantMenuLink;
  plate: RestaurantMenuLinkPlate;
  items: Array<RestaurantMenuLinkPlateOrderItem>;
  athlete: Maybe<Athlete>;
  userFullName: Scalars['String'];
  mealTemplate: Maybe<MealTemplate>;
  mealMenu: MealMenu;
  timelineMeal: Maybe<TimelineMeal>;
};

export type RestaurantMenuLinkPlateOrderItem = MenuSelectionItem & {
  __typename?: 'RestaurantMenuLinkPlateOrderItem';
  plateItem: RestaurantMenuLinkPlateItem;
  position: Scalars['Int'];
  amount: Scalars['Float'];
  percentConsumed: Maybe<Scalars['Float']>;
  menuItem: MenuItem;
  options: Array<RestaurantMenuLinkPlateOrderItemOption>;
};

export type RestaurantMenuLinkPlateOrderItemOption = MenuSelectionItemOption & {
  __typename?: 'RestaurantMenuLinkPlateOrderItemOption';
  amount: Scalars['Float'];
  percentConsumed: Maybe<Scalars['Float']>;
  menuItemChoiceOption: MenuItemChoiceOption;
};

export type RestaurantMenuLog = MenuSelection & {
  __typename?: 'RestaurantMenuLog';
  id: Scalars['ID'];
  restaurantMenu: RestaurantMenu;
  items: Array<RestaurantMenuLogItem>;
  athlete: Maybe<Athlete>;
  mealTemplate: Maybe<MealTemplate>;
  dateTime: Scalars['DateTime'];
  timezone: Scalars['Timezone'];
  type: MealType;
  restaurant: Restaurant;
};

export type RestaurantMenuLogItem = MenuSelectionItem & {
  __typename?: 'RestaurantMenuLogItem';
  id: Scalars['ID'];
  amount: Scalars['Float'];
  percentConsumed: Maybe<Scalars['Float']>;
  menuItem: MenuItem;
  options: Array<RestaurantMenuLogItemOption>;
};

export type RestaurantMenuLogItemInput = {
  menuItemId: Scalars['ID'];
  amount: Scalars['Float'];
  options: Array<RestaurantMenuLogItemOptionInput>;
};

export type RestaurantMenuLogItemOption = MenuSelectionItemOption & {
  __typename?: 'RestaurantMenuLogItemOption';
  amount: Scalars['Float'];
  percentConsumed: Maybe<Scalars['Float']>;
  menuItemChoiceOption: MenuItemChoiceOption;
};

export type RestaurantMenuLogItemOptionInput = {
  amount: Scalars['Float'];
  menuItemChoiceOptionId: Scalars['ID'];
};

export type RestaurantMenuSearchCursorConnection = {
  __typename?: 'RestaurantMenuSearchCursorConnection';
  edges: Array<RestaurantMenuSearchResult>;
  pageInfo: CursorPageInfo;
};

export type RestaurantMenuSearchResult = {
  __typename?: 'RestaurantMenuSearchResult';
  restaurant: Restaurant;
  restaurantMenu: RestaurantMenu;
};

export type RestaurantMenuSection = {
  __typename?: 'RestaurantMenuSection';
  id: Scalars['ID'];
  name: Scalars['String'];
  position: Scalars['Int'];
  menuItemAppearances: Array<MenuItemAppearance>;
};

export type RestaurantMenuSectionLink = {
  __typename?: 'RestaurantMenuSectionLink';
  restaurantMenuSection: RestaurantMenuSection;
  maxAmount: Maybe<Scalars['Int']>;
  menuItemAppearances: Array<MenuItemAppearance>;
};

export type RestaurantMenuSectionLinkInput = {
  restaurantMenuSectionId: Scalars['ID'];
  maxAmount: Maybe<Scalars['Int']>;
  menuItems: Array<AddMenuItemInput>;
};

export type RestaurantOffsetConnection = {
  __typename?: 'RestaurantOffsetConnection';
  edges: Array<Restaurant>;
  pageInfo: OffsetPageInfo;
};

export type RestaurantType = {
  __typename?: 'RestaurantType';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type RestaurantTypeCursorConnection = {
  __typename?: 'RestaurantTypeCursorConnection';
  edges: Array<RestaurantType>;
  pageInfo: CursorPageInfo;
};

export type RestaurantTypeOffsetConnection = {
  __typename?: 'RestaurantTypeOffsetConnection';
  edges: Array<RestaurantType>;
  pageInfo: OffsetPageInfo;
};

export enum Role {
  athlete = 'athlete',
  dietitian = 'dietitian',
  chef = 'chef',
  foodmanager = 'foodmanager',
  kiosk = 'kiosk',
  nonRdAdmin = 'nonRdAdmin'
}

export type SavePlannedMealsInput = {
  plannedMenuId: Scalars['ID'];
  rowItemsToDelete: Array<Scalars['ID']>;
  rowsToDelete: Array<Scalars['ID']>;
  mealsToDelete: Array<Scalars['ID']>;
  mealsToUpsert: Array<PlannedMenuMealInput>;
  disabledDays: Maybe<Array<PlannedMenuDisabledDaysInput>>;
};

export type SavePlannedMealsPayload = {
  __typename?: 'SavePlannedMealsPayload';
  plannedMenu: PlannedMenu;
};

export type Schedule = {
  __typename?: 'Schedule';
  id: Scalars['ID'];
  activityFactor: Maybe<Scalars['Float']>;
  name: Scalars['String'];
  meals: Array<Meal>;
  activities: Array<Activity>;
  mealPlans: Array<MealPlan>;
  team: Maybe<Team>;
};

export type Score = {
  __typename?: 'Score';
  value: Scalars['Float'];
  scoringSystem: ScoringSystem;
};

export type ScoringSystem = {
  __typename?: 'ScoringSystem';
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ScoringSystemOffsetConnection = {
  __typename?: 'ScoringSystemOffsetConnection';
  edges: Array<ScoringSystem>;
  pageInfo: OffsetPageInfo;
};

export type SelectAllInfo = {
  __typename?: 'SelectAllInfo';
  allIds: Array<Scalars['ID']>;
  total: Scalars['Int'];
};

export type Serving = {
  __typename?: 'Serving';
  id: Scalars['ID'];
  units: Scalars['String'];
  defaultAmount: Scalars['Float'];
  isDefault: Scalars['Boolean'];
  weight: Maybe<Scalars['Float']>;
  usdaWeightSeq: Maybe<Scalars['String']>;
  perRecipeYield: Maybe<Scalars['Float']>;
  isRecipeServingOnly: Scalars['Boolean'];
  foodOrRecipe: FoodOrRecipe;
  customUnits: Maybe<Scalars['String']>;
  unit: Maybe<Unit>;
  unitPrefix: Maybe<Scalars['String']>;
  unitSuffix: Maybe<Scalars['String']>;
  hasOwnNutrients: Scalars['Boolean'];
  macros: Macros;
  nutrientAmounts: Array<NutrientAmount>;
};

export type ServingAmount = {
  __typename?: 'ServingAmount';
  id: Scalars['ID'];
  serving: Serving;
  amount: Scalars['Float'];
  position: Scalars['Int'];
};

export type ServingAmountGroup = {
  __typename?: 'ServingAmountGroup';
  servingAmounts: Array<ServingAmount>;
};

export type ServingAmountGroupCursorConnection = {
  __typename?: 'ServingAmountGroupCursorConnection';
  edges: Array<ServingAmountGroup>;
  pageInfo: CursorPageInfo;
};

export type ServingUnitsInput = {
  customUnits: Maybe<Scalars['String']>;
  unitId: Maybe<Scalars['ID']>;
  unitPrefix: Maybe<Scalars['String']>;
  unitSuffix: Maybe<Scalars['String']>;
};

export type SetGoalReminderConfigurationInput = {
  time: Scalars['Time'];
  message: Scalars['String'];
};

export type SetGoalTrackerGoalInput = {
  goalId: Scalars['ID'];
  completed: Scalars['Boolean'];
  value: Maybe<Scalars['Int']>;
};

export type SetGoalTrackerGoalPayload = {
  __typename?: 'SetGoalTrackerGoalPayload';
  goalTrackerGoal: GoalTrackerGoal;
};

export type SetMealMenuCheckInsInput = {
  athleteId: Scalars['ID'];
  removeMealMenuIds: Array<Scalars['ID']>;
  addMealMenuIds: Array<Scalars['ID']>;
};

export type SetMealMenuCheckInsPayload = {
  __typename?: 'SetMealMenuCheckInsPayload';
  addedMealMenuCheckIns: Array<MealMenuCheckIn>;
  removedMealMenuCheckInIds: Array<Scalars['ID']>;
};

export type SetMealMenuLogItemPercentConsumedPayload = {
  __typename?: 'SetMealMenuLogItemPercentConsumedPayload';
  mealMenuLogItem: MealMenuLogItem;
};

export type SetMealOptionsForMealTemplateInput = {
  mealTemplateId: Scalars['ID'];
  mealOptions: Array<MealOptionInput>;
};

export type SetMealOptionsForMealTemplatesInput = {
  removeExistingMealOptions: Scalars['Boolean'];
  mealOptionsForMealTemplates: Array<SetMealOptionsForMealTemplateInput>;
};

export type SetMealOptionsForMealTemplatesPayload = {
  __typename?: 'SetMealOptionsForMealTemplatesPayload';
  mealTemplates: Array<MealTemplate>;
};

export type SetMenuOrderItemPercentConsumedPayload = {
  __typename?: 'SetMenuOrderItemPercentConsumedPayload';
  menuOrderItem: MenuOrderItem;
};

export type SetMenuSelectionItemOptionPercentConsumedInput = {
  menuItemChoiceOptionId: Scalars['ID'];
  percentConsumed: Scalars['Float'];
};

export type SetMenuSelectionItemPercentConsumedInput = {
  menuSelectionItemId: Scalars['ID'];
  percentConsumed: Scalars['Float'];
  options: Array<SetMenuSelectionItemOptionPercentConsumedInput>;
};

export type SetPrepPlanGuidedBuilderInput = {
  prepPlanId: Scalars['ID'];
};

export type SetPrepPlanGuidedBuilderPayload = {
  __typename?: 'SetPrepPlanGuidedBuilderPayload';
  prepPlan: PrepPlan;
};

export type SetPrepPlanMealAssignmentSlotsInput = {
  prepPlanId: Scalars['ID'];
  assignmentSlots: Array<PrepPlanMealAssignmentInput>;
};

export type SetPrepPlanMealAssignmentSlotsPayload = {
  __typename?: 'SetPrepPlanMealAssignmentSlotsPayload';
  prepPlanMealAssignments: Array<PrepPlanMealAssignment>;
};

export type SetRestaurantMenuLinkOrderItemPercentConsumedPayload = {
  __typename?: 'SetRestaurantMenuLinkOrderItemPercentConsumedPayload';
  restaurantMenuLinkOrderItem: RestaurantMenuLinkOrderItem;
};

export type SetRestaurantMenuLinkPlateOrderItemPercentConsumedInput = {
  restaurantMenuLinkPlateOrderId: Scalars['ID'];
  restaurantMenuLinkPlateItemId: Scalars['ID'];
  percentConsumed: Scalars['Float'];
  options: Array<SetMenuSelectionItemOptionPercentConsumedInput>;
};

export type SetRestaurantMenuLinkPlateOrderItemPercentConsumedPayload = {
  __typename?: 'SetRestaurantMenuLinkPlateOrderItemPercentConsumedPayload';
  restaurantMenuLinkPlateOrderItem: RestaurantMenuLinkPlateOrderItem;
  restaurantMenuLinkPlateOrder: RestaurantMenuLinkPlateOrder;
};

export type SetRestaurantMenuLogItemPercentConsumedPayload = {
  __typename?: 'SetRestaurantMenuLogItemPercentConsumedPayload';
  restaurantMenuLogItem: RestaurantMenuLogItem;
};

export type SetTimelineMealServingAmountsForAthleteInput = {
  athleteId: Scalars['ID'];
  timelineMeal: TimelineMealInput;
  servingAmounts: Array<CreateServingAmountInput>;
};

export type SetTimelineMealServingAmountsInput = {
  timelineMeal: TimelineMealInput;
  servingAmounts: Array<CreateServingAmountInput>;
};

export type SetTimelineMealServingAmountsPayload = {
  __typename?: 'SetTimelineMealServingAmountsPayload';
  timelineMeal: Maybe<TimelineMeal>;
  removedTimelineMealId: Maybe<Scalars['ID']>;
};

export enum SexType {
  male = 'male',
  female = 'female'
}

export type ShareMealOptionPendingInput = {
  mealOptionPendingId: Scalars['ID'];
};

export type ShareMealOptionPendingPayload = {
  __typename?: 'ShareMealOptionPendingPayload';
  mealOption: MealOption;
};

export type SharedRecipeCursorConnection = {
  __typename?: 'SharedRecipeCursorConnection';
  edges: Array<Recipe>;
  pageInfo: CursorPageInfo;
};

export type SkippedPlatePayload = {
  __typename?: 'SkippedPlatePayload';
  name: Scalars['String'];
  reason: Scalars['String'];
};

export type SkippedUserPayload = {
  __typename?: 'SkippedUserPayload';
  name: Scalars['String'];
  userId: Scalars['ID'];
};

export type SodexoDayMealTimes = {
  __typename?: 'SodexoDayMealTimes';
  date: Scalars['String'];
  mealTimes: Array<SodexoMealTime>;
};

export type SodexoMealTime = {
  __typename?: 'SodexoMealTime';
  name: Scalars['String'];
  start: Scalars['String'];
  end: Scalars['String'];
  sortOrder: Scalars['String'];
};

export type SodexoMealTimeInput = {
  name: Scalars['String'];
  start: Scalars['String'];
  end: Scalars['String'];
};

export type SodexoMealTimesByDay = {
  __typename?: 'SodexoMealTimesByDay';
  locationId: Scalars['String'];
  menuSourceKey: Scalars['String'];
  mealTimesByDay: Array<SodexoDayMealTimes>;
};

export type SodexoMenuIdentity = MealMenuIdentity & {
  __typename?: 'SodexoMenuIdentity';
  id: Scalars['ID'];
  isOverridden: Scalars['Boolean'];
};

export type SodexoMenuSource = {
  __typename?: 'SodexoMenuSource';
  locationId: Scalars['String'];
  locationName: Scalars['String'];
  menuSourceKey: Scalars['String'];
  menuSourceName: Scalars['String'];
};

export enum SortOrder {
  asc = 'ASC',
  desc = 'DESC'
}

export type Sport = {
  __typename?: 'Sport';
  id: Scalars['ID'];
  name: Scalars['String'];
  positions: Array<Position>;
};

export type SportsMappingInput = {
  teamworksName: Scalars['String'];
  sportId: Scalars['ID'];
};

export type StandaloneMealMenu = MealMenu & {
  __typename?: 'StandaloneMealMenu';
  id: Scalars['ID'];
  name: Scalars['String'];
  type: MealType;
  start: Scalars['DateTime'];
  timezone: Scalars['Timezone'];
  durationInMinutes: Scalars['Int'];
  prepTimeInMinutes: Scalars['Int'];
  lastOrderTimeBeforeEndInMinutes: Scalars['Int'];
  notificationSentBeforeOrderDueInMinutes: Maybe<Scalars['Int']>;
  mealMenuDiningStations: Array<MealMenuDiningStation>;
  orderRateLimit: Maybe<MenuOrderRateLimit>;
  userOrderLimit: Maybe<Scalars['Int']>;
  isLogOnly: Scalars['Boolean'];
  isInHouseMenuOrderable: Scalars['Boolean'];
  isRestaurantMenuOrderable: Scalars['Boolean'];
  isOrderable: Scalars['Boolean'];
  isHubCheckInEnabled: Scalars['Boolean'];
  hubEventUrl: Maybe<Scalars['String']>;
  isOrderAndLogRestricted: Scalars['Boolean'];
  isBulkOrderable: Scalars['Boolean'];
  isViewOnlyForMe: Scalars['Boolean'];
  identity: Maybe<MealMenuIdentity>;
  restaurantMenuLinks: Array<RestaurantMenuLink>;
  teams: Array<Team>;
  advancedSelection: Maybe<AdvancedSelectionType>;
  hasAdvancedSelection: Scalars['Boolean'];
  athleteCount: Scalars['Int'];
  athletes: Array<Athlete>;
  allOrders: Array<MenuOrder>;
  allMealMenuPlateOrders: Array<MealMenuPlateOrder>;
  /** @deprecated pending forced update for multiple MenuOrders per menu */
  order: Maybe<MenuOrder>;
  orders: Array<MenuOrder>;
  ordersForUser: Array<MenuOrder>;
  ordersForAthlete: Array<MenuOrder>;
  logs: Array<MealMenuLog>;
  logsForAthlete: Array<MealMenuLog>;
  theme: Maybe<Scalars['String']>;
};


export type StandaloneMealMenuOrdersForUserArgs = {
  userId: Scalars['ID'];
};


export type StandaloneMealMenuOrdersForAthleteArgs = {
  athleteId: Scalars['ID'];
};


export type StandaloneMealMenuLogsForAthleteArgs = {
  athleteId: Scalars['ID'];
};

export type Subscription = {
  __typename?: 'Subscription';
  bulkOrder: BulkOrderPublication;
  bulkOrderStatus: BulkOrderStatusPublication;
  /** @deprecated Remove pending timeline meal cleanup and mobile force update */
  foodLogMeal: FoodLogMealPublication;
  mealMenuPlateItemOrderStatuses: MealMenuPlateItemOrderStatusPublication;
  /** @deprecated Remove 'AddMenuOrderCommentPayload' from 'union MenuOrderPublicationPayload' pending timeline meal cleanup and mobile force update */
  menuOrder: MenuOrderPublication;
  menuOrderItemStatuses: MenuOrderItemStatusesPublication;
  notification: NotificationPublication;
  plannedMenuLockTaken: PlannedMenuLockTakenPublication;
  timelineMeal: TimelineMealPublication;
};


export type SubscriptionBulkOrderArgs = {
  scope: Maybe<BulkOrderSubscriberScope>;
};


export type SubscriptionBulkOrderStatusArgs = {
  scope: Maybe<BulkOrderSubscriberScope>;
};


export type SubscriptionFoodLogMealArgs = {
  scope: Maybe<FoodLogMealSubscriberScope>;
};


export type SubscriptionMealMenuPlateItemOrderStatusesArgs = {
  scope: Maybe<MealMenuPlateItemOrderStatusSubscriberScope>;
};


export type SubscriptionMenuOrderArgs = {
  scope: Maybe<MenuOrderSubscriberScope>;
};


export type SubscriptionMenuOrderItemStatusesArgs = {
  scope: Maybe<MenuOrderItemStatusesSubscriberScope>;
};


export type SubscriptionPlannedMenuLockTakenArgs = {
  scope: PlannedMenuLockTakenSubscriberScope;
};


export type SubscriptionTimelineMealArgs = {
  timelineMealId: Maybe<Scalars['ID']>;
};

export type SyncedAthleteFormInput = {
  sex: Maybe<SexType>;
  inbodyUid: Maybe<Scalars['String']>;
  secaUid: Maybe<Scalars['String']>;
  jerseyNumber: Maybe<Scalars['String']>;
  removedTagIds: Array<Scalars['ID']>;
  addedTagIds: Array<Scalars['ID']>;
  positionId: Maybe<Scalars['ID']>;
  birthDate: Maybe<Scalars['Date']>;
};

export type Tag = {
  id: Scalars['ID'];
  tagName: Maybe<TagName>;
  athleteCount: Scalars['Int'];
  isComplement: Scalars['Boolean'];
  createdBy: Maybe<User>;
};

export enum TagCombinationMethod {
  union = 'union',
  intersection = 'intersection'
}

export type TagCondition = ComboTag | PositionTag | TeamTag;

export enum TagConditionType {
  position = 'position',
  tag = 'tag',
  team = 'team'
}

export type TagName = {
  __typename?: 'TagName';
  name: Scalars['String'];
  description: Scalars['String'];
  color: Scalars['String'];
  isPrintable: Maybe<Scalars['Boolean']>;
};

export type Team = {
  __typename?: 'Team';
  id: Scalars['ID'];
  athletes: Array<Athlete>;
  /** @deprecated Pending web notifications overhaul */
  recentNotifications: Array<Notification>;
  foodLogNotificationSettings: Maybe<FoodLogNotificationSettings>;
  defaultMacroDisplaySettings: Maybe<MacroMealPlanDisplaySettings>;
  defaultExchangeDisplaySettings: Maybe<ExchangeMealPlanDisplaySettings>;
  defaultNoTargetsDisplaySettings: Maybe<NoTargetsDisplaySettings>;
  defaultHideAnthropometry: Maybe<Scalars['Boolean']>;
  schedules: Array<Schedule>;
  gender: Maybe<Scalars['String']>;
  sport: Maybe<Sport>;
  name: Scalars['String'];
  canBeDeleted: Scalars['Boolean'];
  teamworksId: Maybe<Scalars['Int']>;
};

export type TeamCursorConnection = {
  __typename?: 'TeamCursorConnection';
  edges: Array<Team>;
  pageInfo: CursorPageInfo;
};

export type TeamLabel = {
  __typename?: 'TeamLabel';
  id: Scalars['ID'];
  name: Scalars['String'];
  isActive: Scalars['Boolean'];
};

export type TeamLabelCursorConnection = {
  __typename?: 'TeamLabelCursorConnection';
  edges: Array<TeamLabel>;
  pageInfo: CursorPageInfo;
};

export type TeamLabelOffsetConnection = {
  __typename?: 'TeamLabelOffsetConnection';
  edges: Array<TeamLabel>;
  pageInfo: OffsetPageInfo;
};

export type TeamTag = Tag & {
  __typename?: 'TeamTag';
  id: Scalars['ID'];
  tagName: Maybe<TagName>;
  athleteCount: Scalars['Int'];
  isComplement: Scalars['Boolean'];
  createdBy: Maybe<User>;
  teams: Array<Team>;
};

export type TeamworksAthleteStatus = {
  __typename?: 'TeamworksAthleteStatus';
  id: Scalars['Int'];
  name: Scalars['String'];
  active: Maybe<Scalars['Boolean']>;
};

export type TeamworksCalendarPayload = {
  __typename?: 'TeamworksCalendarPayload';
  events: Array<ExternalEvent>;
  isPartialSuccess: Scalars['Boolean'];
};

export enum TeamworksGender {
  m = 'M',
  f = 'F'
}

export type TeamworksGroup = {
  __typename?: 'TeamworksGroup';
  id: Scalars['Int'];
  type: TeamworksGroupType;
  label: Scalars['String'];
  teamId: Maybe<Scalars['Int']>;
};

export enum TeamworksGroupType {
  team = 'team',
  userType = 'user_type',
  athleteStatus = 'athlete_status',
  position = 'position',
  academicYear = 'academic_year'
}

export type TeamworksMembership = {
  __typename?: 'TeamworksMembership';
  teamId: Scalars['Int'];
  teamName: Scalars['String'];
  athleteStatus: Maybe<TeamworksAthleteStatus>;
  userTypes: Maybe<Array<TeamworksUserType>>;
  positions: Maybe<Array<TeamworksPosition>>;
};

export type TeamworksOrg = {
  __typename?: 'TeamworksOrg';
  id: Scalars['Int'];
  name: Scalars['String'];
  shortName: Scalars['String'];
  teams: Array<TeamworksTeam>;
};

export type TeamworksOrgCursorConnection = {
  __typename?: 'TeamworksOrgCursorConnection';
  edges: Array<TeamworksOrg>;
  pageInfo: CursorPageInfo;
};

export type TeamworksPosition = {
  __typename?: 'TeamworksPosition';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type TeamworksPositionLink = {
  __typename?: 'TeamworksPositionLink';
  teamworksId: Scalars['Int'];
  teamworksTeamId: Scalars['Int'];
  position: Position;
};

export type TeamworksPositionMapping = {
  __typename?: 'TeamworksPositionMapping';
  teamworksName: Scalars['String'];
  positionId: Scalars['ID'];
};

export type TeamworksProfile = {
  __typename?: 'TeamworksProfile';
  id: Scalars['Int'];
  userId: Maybe<Scalars['Int']>;
  firstName: Maybe<Scalars['String']>;
  lastName: Maybe<Scalars['String']>;
  email: Maybe<Scalars['String']>;
  cellPhone: Maybe<Scalars['String']>;
  birthDate: Maybe<Scalars['Date']>;
  gender: Maybe<TeamworksGender>;
  hasLoginAccess: Maybe<Scalars['Boolean']>;
  active: Maybe<Scalars['Boolean']>;
  memberships: Maybe<Array<TeamworksMembership>>;
};

export type TeamworksProfileCursorConnection = {
  __typename?: 'TeamworksProfileCursorConnection';
  edges: Array<TeamworksProfile>;
  pageInfo: CursorPageInfo;
};

export type TeamworksProfileLinkInput = {
  orgMembershipId: Maybe<Scalars['ID']>;
  athleteId: Maybe<Scalars['ID']>;
  teamId: Maybe<Scalars['ID']>;
  positionId: Maybe<Scalars['ID']>;
  teamworksUserId: Scalars['Int'];
  teamworksProfileId: Scalars['Int'];
  notemealAccountType: NotemealAccountType;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email: Maybe<Scalars['String']>;
  cellPhone: Maybe<Scalars['String']>;
  sex: Maybe<SexType>;
  birthDate: Maybe<Scalars['Date']>;
  hasLoginAccess: Maybe<Scalars['Boolean']>;
  active: Maybe<Scalars['Boolean']>;
};

export type TeamworksProfileSyncRule = {
  __typename?: 'TeamworksProfileSyncRule';
  id: Scalars['ID'];
  athleteStatusIds: Maybe<Array<Scalars['Int']>>;
  userTypeIds: Maybe<Array<Scalars['Int']>>;
  teamIds: Maybe<Array<Scalars['Int']>>;
  profileIds: Maybe<Array<Scalars['Int']>>;
  notemealAccountType: NotemealAccountType;
  priority: Scalars['Int'];
  positionIds: Maybe<Array<Scalars['Int']>>;
  genders: Maybe<Array<TeamworksGender>>;
  matchNotemealTeamIds: Maybe<Array<Scalars['ID']>>;
};

export type TeamworksProfileSyncRuleInput = {
  athleteStatusIds: Maybe<Array<Scalars['Int']>>;
  userTypeIds: Maybe<Array<Scalars['Int']>>;
  teamIds: Maybe<Array<Scalars['Int']>>;
  profileIds: Maybe<Array<Scalars['Int']>>;
  notemealAccountType: NotemealAccountType;
  priority: Scalars['Int'];
  positionIds: Maybe<Array<Scalars['Int']>>;
  genders: Maybe<Array<TeamworksGender>>;
  matchNotemealTeamIds: Maybe<Array<Scalars['ID']>>;
};

export type TeamworksSportMapping = {
  __typename?: 'TeamworksSportMapping';
  teamworksName: Scalars['String'];
  sportId: Scalars['ID'];
  sport: Sport;
};

export type TeamworksSyncStatus = {
  __typename?: 'TeamworksSyncStatus';
  linkedProfileCount: Scalars['Int'];
  unlinkedTeamworksProfileCount: Scalars['Int'];
  unlinkedNotemealProfileCount: Scalars['Int'];
  toDeactivateProfileCount: Scalars['Int'];
  updatedAt: Scalars['DateTime'];
  isFullySynced: Scalars['Boolean'];
  isTeamworksLinked: Scalars['Boolean'];
  isNotemealLinked: Scalars['Boolean'];
};

export type TeamworksTeam = {
  __typename?: 'TeamworksTeam';
  id: Scalars['Int'];
  name: Scalars['String'];
  sport: Scalars['String'];
  sportMappings: Array<TeamworksSportMapping>;
  notemealTeams: Array<Team>;
};

export type TeamworksUserType = {
  __typename?: 'TeamworksUserType';
  userTypeId: Scalars['Int'];
  userTypeName: Scalars['String'];
};

export type Territory = {
  __typename?: 'Territory';
  id: Scalars['ID'];
  name: Scalars['String'];
  orgs: Array<Org>;
};

export type TerritoryOffsetConnection = {
  __typename?: 'TerritoryOffsetConnection';
  edges: Array<Territory>;
  pageInfo: OffsetPageInfo;
};

export type TextMealPlanToAthleteInput = {
  mealPlanId: Scalars['ID'];
};

export type TextMealPlanToAthletePayload = {
  __typename?: 'TextMealPlanToAthletePayload';
  result: Scalars['Boolean'];
};

export type TextMealPlansToAthletesInput = {
  mealPlanIds: Array<Scalars['ID']>;
};

export type TextMealPlansToAthletesPayload = {
  __typename?: 'TextMealPlansToAthletesPayload';
  result: Scalars['Boolean'];
};


export type TimelineActivityOrTemplate = FoodLogActivity | TimelineActivityTemplate;

export type TimelineActivityTemplate = {
  __typename?: 'TimelineActivityTemplate';
  start: Scalars['DateTime'];
  durationInMinutes: Scalars['Int'];
  timezone: Scalars['Timezone'];
  template: ActivityTemplate;
};

export type TimelineDate = {
  __typename?: 'TimelineDate';
  date: Scalars['Date'];
  timezone: Scalars['Timezone'];
  isMealPlanSelected: Scalars['Boolean'];
  mealPlan: Maybe<MealPlan>;
  activities: Array<TimelineActivityOrTemplate>;
  meals: Array<TimelineMealOrTemplate>;
};

export type TimelineItem = FoodLogMeal | FoodLogActivity | MenuOrder | MealPlanDate | StandaloneMealMenu | RestaurantMenuLog;

export type TimelineMeal = {
  __typename?: 'TimelineMeal';
  id: Scalars['ID'];
  name: Scalars['String'];
  start: Scalars['DateTime'];
  durationInMinutes: Scalars['Int'];
  timezone: Scalars['Timezone'];
  type: MealType;
  mealTemplate: Maybe<MealTemplate>;
  athlete: Maybe<Athlete>;
  mealMenus: Array<MealMenu>;
  restaurantMenuLogs: Array<RestaurantMenuLog>;
  restaurantMenuLinkOrders: Array<RestaurantMenuLinkOrder>;
  restaurantMenuLinkPlateOrders: Array<RestaurantMenuLinkPlateOrder>;
  menuOrders: Array<MenuOrder>;
  mealMenuLogs: Array<MealMenuLog>;
  servingAmounts: Array<ServingAmount>;
  comments: Array<Comment>;
  images: Array<Image>;
  notifications: Array<Notification>;
};

export type TimelineMealCommentNotification = Notification & {
  __typename?: 'TimelineMealCommentNotification';
  id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  type: Scalars['String'];
  createdAt: Scalars['DateTime'];
  viewedAt: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  message: Scalars['String'];
  timelineMeal: Maybe<TimelineMeal>;
};

export type TimelineMealEditNotification = Notification & {
  __typename?: 'TimelineMealEditNotification';
  id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  type: Scalars['String'];
  createdAt: Scalars['DateTime'];
  viewedAt: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  message: Scalars['String'];
  timelineMeal: Maybe<TimelineMeal>;
};

export type TimelineMealImageNotification = Notification & {
  __typename?: 'TimelineMealImageNotification';
  id: Scalars['ID'];
  /** @deprecated Pending web notifications overhaul AND mobile usage removal */
  type: Scalars['String'];
  createdAt: Scalars['DateTime'];
  viewedAt: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  message: Scalars['String'];
  timelineMeal: Maybe<TimelineMeal>;
  image: Maybe<Image>;
};

export type TimelineMealInput = {
  timelineMealId: Scalars['ID'];
  addTimelineMeal: Maybe<AddTimelineMealInput>;
};

export type TimelineMealOrTemplate = TimelineMeal | TimelineMealTemplate;

export type TimelineMealPublication = {
  __typename?: 'TimelineMealPublication';
  payload: TimelineMealPublicationPayload;
};

export type TimelineMealPublicationPayload = AddTimelineMealCommentPayload;

export type TimelineMealTemplate = {
  __typename?: 'TimelineMealTemplate';
  start: Scalars['DateTime'];
  durationInMinutes: Scalars['Int'];
  timezone: Scalars['Timezone'];
  template: MealTemplate;
  mealMenus: Array<MealMenu>;
};


export type UnarchiveAthleteInput = {
  id: Scalars['ID'];
};

export type UnarchiveAthletePayload = {
  __typename?: 'UnarchiveAthletePayload';
  athlete: Athlete;
};

export type Unit = {
  __typename?: 'Unit';
  id: Scalars['ID'];
  name: Scalars['String'];
  isProductionOnly: Scalars['Boolean'];
  conversionsFrom: Array<UnitConversion>;
  conversionsTo: Array<UnitConversion>;
};

export type UnitConversion = {
  __typename?: 'UnitConversion';
  id: Scalars['ID'];
  fromUnit: Unit;
  fromQuantity: Scalars['Float'];
  toUnit: Unit;
  toQuantity: Scalars['Float'];
};

export type UnlockPlannedMenuInput = {
  plannedMenuId: Scalars['ID'];
};

export type UnlockPlannedMenuPayload = {
  __typename?: 'UnlockPlannedMenuPayload';
  lockReleased: Scalars['Boolean'];
  plannedMenu: PlannedMenu;
};

export type UnmatchedIngredient = {
  __typename?: 'UnmatchedIngredient';
  foodName: Scalars['String'];
  amount: Scalars['Float'];
  servingName: Scalars['String'];
};

export type UnmatchedIngredients = {
  __typename?: 'UnmatchedIngredients';
  unmatchedName: Array<UnmatchedIngredient>;
  unmatchedServings: Array<UnmatchedIngredient>;
};

export type UpdateApnCountForUserPayload = {
  __typename?: 'UpdateApnCountForUserPayload';
  notificationCount: Scalars['Int'];
};

export type UpdateBulkOrderStatusPayload = {
  __typename?: 'UpdateBulkOrderStatusPayload';
  bulkOrder: BulkOrder;
  status: Scalars['String'];
};

export type UpdateMacroMealPlanTemplateSharingScopeInput = {
  macroMealPlanTemplateId: Scalars['ID'];
  isShared: Scalars['Boolean'];
};

export type UpdateMacroMealPlanTemplateSharingScopePayload = {
  __typename?: 'UpdateMacroMealPlanTemplateSharingScopePayload';
  macroMealPlanTemplate: MealPlanTemplate;
};

export type UpdateMealMenuPlateItemOrderStatusInput = {
  keyTuple: PlateOrderIdItemIdTuple;
  status: MenuOrderItemStatus;
};

export type UpdateMealMenuPlateItemOrderStatusPayload = {
  __typename?: 'UpdateMealMenuPlateItemOrderStatusPayload';
  mealMenuPlateItemOrderStatus: MealMenuPlateItemOrderStatus;
};

export type UpdateMealMenuPlateOrderStatusPayload = {
  __typename?: 'UpdateMealMenuPlateOrderStatusPayload';
  mealMenuPlateOrderId: Scalars['String'];
  status: MenuOrderItemStatus;
};

export type UpdateMealPlanTemplateAssignScopeInput = {
  mealPlanTemplateId: Scalars['ID'];
  teamIds: Maybe<Array<Scalars['ID']>>;
  advancedSelectionInput: Maybe<AdvancedSelectionInput>;
};

export type UpdateMealPlanTemplateAssignScopePayload = {
  __typename?: 'UpdateMealPlanTemplateAssignScopePayload';
  mealPlanTemplate: MealPlanTemplate;
};

export type UpdateMenuOrderItemStatusesInput = {
  menuOrderId: Scalars['ID'];
  ids: Array<Scalars['ID']>;
  status: MenuOrderItemStatus;
};

export type UpdateMenuOrderItemStatusesPayload = {
  __typename?: 'UpdateMenuOrderItemStatusesPayload';
  menuOrderItems: Array<MenuOrderItem>;
};

export type UpdateMultipleMealMenuPlateItemOrderStatusesInput = {
  keyTuples: Array<PlateOrderIdItemIdTuple>;
  status: MenuOrderItemStatus;
};

export type UpdateMultipleMealMenuPlateItemOrderStatusesPayload = {
  __typename?: 'UpdateMultipleMealMenuPlateItemOrderStatusesPayload';
  mealMenuPlateItemOrderStatuses: Array<MealMenuPlateItemOrderStatus>;
};

export type UpdateMultipleMenuOrderItemStatusesInput = {
  menuOrderIds: Array<Scalars['ID']>;
  ids: Array<Scalars['ID']>;
  status: MenuOrderItemStatus;
};

export type UpdateMultipleMenuOrderItemStatusesPayload = {
  __typename?: 'UpdateMultipleMenuOrderItemStatusesPayload';
  menuOrderItems: Array<MenuOrderItem>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  teamworksId: Maybe<Scalars['Int']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  username: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  email: Maybe<Scalars['String']>;
  phoneNumber: Maybe<Scalars['String']>;
  orgInfo: Array<OrgInfo>;
  usesKg: Scalars['Boolean'];
  lastLogin: Maybe<Scalars['DateTime']>;
  dateJoined: Scalars['DateTime'];
  locale: Locale;
};

export type UserCursorConnection = {
  __typename?: 'UserCursorConnection';
  edges: Array<User>;
  pageInfo: CursorPageInfo;
};

export type UserOffsetConnection = {
  __typename?: 'UserOffsetConnection';
  edges: Array<User>;
  pageInfo: OffsetPageInfo;
};
